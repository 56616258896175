import {
	IonAvatar,
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonDatetime,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonModal,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonSelect,
	IonSelectOption,
	IonSkeletonText,
	IonText,
	IonTitle,
	IonToolbar
} from "@ionic/react"
import {
	analyticsOutline,
	busOutline,
	calendarOutline,
	closeOutline,
	documentAttachOutline,
	medicalOutline,
	medkitOutline,
	peopleOutline,
	person,
	personCircleOutline,
	ribbonOutline,
	timeOutline,
	transgenderOutline
} from "ionicons/icons"
import { useAtom } from "jotai"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useHistory } from "react-router"
import { userCenterIdAssociatedAtomStorage, usernameAtomStorage } from "../../atoms/user"
import useT from "../../hooks/useT"
import {
	Children_Relation,
	Commute_Vehicle,
	Educational_Level,
	useGetUserFilteredQuery
} from "../../services/api-config/generated"
import { eucareApolloClient } from "../../services/api-eucare/client/apollo"
import {
	Vaccine,
	useEditFamilyMemberMutation,
	useGetEditStudentAssociablesListLazyQuery,
	useGetPersonInfoPlainMutation,
	useGetPersonLazyQuery
} from "../../services/api-eucare/generated"
import { isoStringToServerDateConverter } from "../../utils/dateFormatConverter"
import { INaturalInfections, IVaccineDose } from "../FamilyMember/FamilyMember"
import { DummyBottomNavigationBar } from "../Home/BottomNavigationBar/BottomNavigationBar"
import Toolbar from "../Home/Toolbar/Toolbar"

export default function EditProfile() {
	const history = useHistory()
	const [userCenterIdAssociated] = useAtom(userCenterIdAssociatedAtomStorage)

	const [username] = useAtom(usernameAtomStorage)

	const t = useT()

	const [firstName, setFirstName] = useState<string>()
	const [lastName, setLastName] = useState<string>()
	const [fiscalCode, setFiscalCode] = useState<string>()
	const [yearOfBirth, setYearOfBirth] = useState<string>()
	const [gender, setGender] = useState<string>()

	const [childrenRelation, setChildrenRelation] = useState<Children_Relation>()
	const [educationLevel, setEducationLevel] = useState<Educational_Level>()
	const [hasBeenInfected, setHasBeenInfected] = useState<boolean>(false)
	const [vaccinated, setVaccinated] = useState<boolean>(false)
	const [vaccineDoses, setVaccineDoses] = useState<IVaccineDose[]>()
	const [travelMinutes, setTravelMinutes] = useState<number>()
	const [travelKilometres, setTravelKilometres] = useState<number>()
	const [commuteVehicle, setCommuteVehicle] = useState<Commute_Vehicle>()
	const [naturalInfections, setNaturalInfections] = useState<INaturalInfections[]>()
	const [selectedNaturalInfectionDate, setSelectedNaturalInfectionDate] = useState<INaturalInfections | undefined>({
		date: new Date().toISOString(),
		date_res: "YMD"
	})

	const [selectedVaccineDoseDate, setSelectedVaccineDoseDate] = useState<string | undefined>(new Date().toISOString())
	const [selectedVaccineDoseName, setSelectedVaccineDoseName] = useState<string | undefined>()

	const modal = useRef<HTMLIonModalElement>(null)
	const input = useRef<HTMLIonInputElement>(null)

	const infectionModal = useRef<HTMLIonModalElement>(null)
	const infectionInput = useRef<HTMLIonInputElement>(null)

	const { data } = useGetUserFilteredQuery({
		variables: {
			username
		},
		client: eucareApolloClient
	})

	const [getPerson, { loading: isGettingPersonInfo }] = useGetPersonLazyQuery({
		client: eucareApolloClient,
		fetchPolicy: "no-cache"
	})

	const [getAssociablesList] = useGetEditStudentAssociablesListLazyQuery({ client: eucareApolloClient })

	const [getPersonInfoPlain] = useGetPersonInfoPlainMutation({
		client: eucareApolloClient
	})

	const [personUpdate] = useEditFamilyMemberMutation({
		client: eucareApolloClient
	})

	const [childrenRelationTypes, setChildrenRelationTypes] = useState<Children_Relation[]>()
	const [educationLevelTypes, setEducationLevelTypes] = useState<Educational_Level[]>()
	const [commuteVehicleTypes, setCommuteVehicleTypes] = useState<Commute_Vehicle[]>()
	const [vaccineTypes, setVaccineTypes] = useState<Vaccine[]>()

	const selectableYearOfBirth = useMemo(
		() =>
			Array.from({ length: 100 }).map((_, index) => {
				return (new Date().getFullYear() - index).toString()
			}),
		[]
	)

	const onSave = useCallback(() => {
		const personId = data?._User___getPage?.items?.[0].person_?._id

		if (firstName && lastName && fiscalCode && yearOfBirth && gender && personId) {
			personUpdate({
				variables: {
					input: {
						edit_mode: true,
						_id: personId,
						name: firstName,
						surname: lastName,
						gender,
						year_of_birth: yearOfBirth,
						fiscal_code: fiscalCode,
						children_relation_: { set: childrenRelation?._id },
						educational_level_: {
							set: educationLevel?._id
						},
						commute_vehicle_: {
							set: commuteVehicle?._id
						},
						minutes: travelMinutes,
						kilometers: travelKilometres,
						vaccinated,
						vaccine_dose_: {
							deleteAll: true,
							create: vaccineDoses?.map((vax) => ({
								date: vax.date,
								vaccine_: vax.vaccine_?._id,
								date_res: "YMD",
								dose_number: vax.dose_number
							}))
						},
						past_infection: hasBeenInfected,
						natural_infection_: {
							deleteAll: true,
							create: naturalInfections?.map((nI) => ({ ...nI, __typename: undefined }))
						}
					}
				}
			})
				.then(() => {
					history.replace("/home")
				})
				.catch((err) => {
					alert("Error: " + (err.message ?? JSON.stringify(err)))
				})
		}
	}, [
		childrenRelation?._id,
		commuteVehicle?._id,
		data?._User___getPage?.items,
		educationLevel?._id,
		firstName,
		fiscalCode,
		gender,
		hasBeenInfected,
		history,
		lastName,
		naturalInfections,
		personUpdate,
		travelKilometres,
		travelMinutes,
		vaccinated,
		vaccineDoses,
		yearOfBirth
	])

	useEffect(() => {
		if (data?._User___getPage?.items?.[0].person_?._id) {
			const fetchPersonDataPlain = async () => {
				const [personDataPlain, personInfo, associablesListDataDrawer] = await Promise.all([
					getPersonInfoPlain({
						variables: {
							input: data?._User___getPage?.items?.[0].person_?._id!
						}
					}),
					getPerson({
						variables: {
							input: data?._User___getPage?.items?.[0].person_?._id!
						}
					}),
					getAssociablesList({
						variables: {
							input: {
								_id: data?._User___getPage?.items?.[0].person_?._id,
								year_of_birth: 2000,
								gender: "M",
								name: "TestName",
								surname: "TestSurname",
								fiscal_code: "FiscalCode",
								vaccine_dose_: {
									save: [
										{
											date: "01/01/2022",
											date_res: "YMD",
											dose_number: 1,
											vaccine_: "13550"
										}
									]
								}
							}
						}
					})
				])

				console.log(personDataPlain, personInfo, associablesListDataDrawer)

				setFirstName(personDataPlain.data?.Person___formAction___editData?.data?.name ?? undefined)
				setLastName(personDataPlain.data?.Person___formAction___editData?.data?.surname ?? undefined)
				setFiscalCode(personDataPlain.data?.Person___formAction___editData?.data?.fiscal_code ?? undefined)

				setGender(data._User___getPage?.items?.[0].person_?.gender ?? undefined)
				setYearOfBirth(data._User___getPage?.items?.[0].person_?.year_of_birth ?? undefined)

				setChildrenRelation(personInfo.data?.Person___get?.children_relation_ ?? undefined)
				setEducationLevel(personInfo.data?.Person___get?.educational_level_ ?? undefined)
				setCommuteVehicle(personInfo.data?.Person___get?.commute_vehicle_ ?? undefined)
				setTravelMinutes(personInfo.data?.Person___get?.minutes ?? undefined)
				setTravelKilometres(personInfo.data?.Person___get?.kilometers ?? undefined)
				setHasBeenInfected(personInfo.data?.Person___get?.past_infection ?? false)
				setVaccinated(personInfo.data?.Person___get?.vaccinated ?? false)
				setVaccineDoses(personInfo.data?.Person___get?.vaccine_dose_?.items)
				setNaturalInfections(personInfo.data?.Person___get?.natural_infection_?.items)

				setChildrenRelationTypes([
					...(personInfo.data?.Person___get?.children_relation_
						? [personInfo.data?.Person___get?.children_relation_]
						: []),
					...((associablesListDataDrawer.data?.Person___preview?.children_relation____associables?.items ??
						[]) as Children_Relation[])
				])
				setEducationLevelTypes([
					...(personInfo.data?.Person___get?.educational_level_
						? [personInfo.data?.Person___get?.educational_level_]
						: []),
					...((associablesListDataDrawer.data?.Person___preview?.educational_level____associables?.items ??
						[]) as Educational_Level[])
				])
				setCommuteVehicleTypes([
					...(personInfo.data?.Person___get?.commute_vehicle_
						? [personInfo.data?.Person___get?.commute_vehicle_]
						: []),
					...((associablesListDataDrawer.data?.Person___preview?.commute_vehicle____associables?.items ??
						[]) as Commute_Vehicle[])
				])
				setVaccineTypes([
					...((personInfo.data?.Person___get?.vaccine_dose_?.items &&
					personInfo.data?.Person___get?.vaccine_dose_?.items.length !== 0
						? [personInfo.data?.Person___get?.vaccine_dose_?.items?.[0].vaccine_ ?? []]
						: []) as Vaccine[]),
					...((associablesListDataDrawer.data?.Person___preview?.vaccine_dose_?.items?.[0]
						.vaccine____associables?.items ?? []) as Vaccine[])
				])
			}

			fetchPersonDataPlain()
		}
	}, [data?._User___getPage?.items, getAssociablesList, getPerson, getPersonInfoPlain, userCenterIdAssociated])

	console.log("child-rel", childrenRelation?._id)
	console.log("child-rel-types", childrenRelationTypes)

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/home" />
					</IonButtons>

					<IonTitle>{t("tab_profile_main_title")}</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<Toolbar title={t("student_edit_profile_main_title")} />

				{isGettingPersonInfo ? (
					<IonList>
						{Array.from({ length: 10 }).map((_, index) => (
							<IonItem key={`family-member-skeleton-${index}`}>
								<IonSkeletonText className="h-4" animated />
							</IonItem>
						))}
					</IonList>
				) : (
					<IonList>
						<IonItem className="!w-full">
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={person} />
							</IonAvatar>

							<IonLabel className="!w-full">
								<h3 className="!w-full">{t("student_edit_profile_first_name_label")}</h3>
								<IonInput
									type="text"
									className="!pl-0 !w-full"
									placeholder="First Name"
									value={firstName}
									onIonChange={(e) => {
										if (e.detail.value) {
											setFirstName(e.detail.value?.toString())
										}
									}}
								></IonInput>
							</IonLabel>
						</IonItem>

						<IonItem className="!w-full">
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={personCircleOutline} />
							</IonAvatar>

							<IonLabel className="!w-full">
								<h3 className="!w-full">{t("student_edit_profile_last_name_label")}</h3>
								<IonInput
									type="text"
									className="!pl-0 !w-full"
									placeholder="Last Name"
									value={lastName}
									onIonChange={(e) => {
										if (e.detail.value) {
											setLastName(e.detail.value?.toString())
										}
									}}
								></IonInput>
							</IonLabel>
						</IonItem>

						<IonItem className="!w-full">
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={documentAttachOutline} />
							</IonAvatar>

							<IonLabel className="!w-full">
								<h3 className="!w-full">{t("student_edit_profile_fiscal_code_label")}</h3>
								<IonInput
									type="text"
									className="!pl-0 !w-full"
									placeholder={fiscalCode}
									value={fiscalCode}
									onIonChange={(e) => {
										if (e.detail.value) {
											setFiscalCode(e.detail.value?.toString())
										}
									}}
								></IonInput>
							</IonLabel>
						</IonItem>

						{data?._User___getPage?.items?.[0]?.person_?.gender && (
							<IonItem className="!w-full">
								<IonAvatar
									className="flex justify-center items-center bg-[#263444] p-3 text-white"
									slot="start"
								>
									<IonIcon icon={transgenderOutline} />
								</IonAvatar>

								<div className="flex-1 flex-col mt-[10px] mr-[10px] mb-[10px]">
									<IonLabel className="!w-full">
										<h3 className="!w-full">{t("student_edit_profile_gender_label")}</h3>
									</IonLabel>
									<IonSelect
										value={gender}
										placeholder={
											data?._User___getPage?.items?.[0]?.person_?.gender === "M"
												? t("family_attribute_gender_male")
												: t("family_attribute_gender_female")
										}
										onIonChange={(e) => {
											if (e.detail.value) {
												setGender(e.detail.value?.toString())
											}
										}}
									>
										<IonSelectOption value="M">{t("family_attribute_gender_male")}</IonSelectOption>
										<IonSelectOption value="F">
											{t("family_attribute_gender_female")}
										</IonSelectOption>
									</IonSelect>
								</div>
							</IonItem>
						)}

						{data?._User___getPage?.items?.[0]?.person_?.year_of_birth && (
							<IonItem className="!w-full">
								<IonAvatar
									className="flex justify-center items-center bg-[#263444] p-3 text-white"
									slot="start"
								>
									<IonIcon icon={calendarOutline} />
								</IonAvatar>

								<div className="flex-1 flex-col mt-[10px] mr-[10px] mb-[10px]">
									<IonLabel className="!w-full">
										<h3 className="!w-full">{t("student_edit_profile_year_of_birth_label")}</h3>
									</IonLabel>
									<IonSelect
										placeholder={
											yearOfBirth ?? t("family_member_attribute_year_of_birth_placeholder")
										}
										value={yearOfBirth}
										onIonChange={(e) => {
											if (e.detail.value) {
												setYearOfBirth(e.detail.value?.toString())
											}
										}}
									>
										{selectableYearOfBirth?.map((year) => (
											<IonSelectOption key={`year-option-${year}`} value={year}>
												{year.toString()}
											</IonSelectOption>
										))}
									</IonSelect>
								</div>
							</IonItem>
						)}

						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={peopleOutline} />
							</IonAvatar>

							<div className="flex-1 flex-col mt-[10px] mr-[10px] mb-[10px]">
								<IonLabel className="ion-text-wrap">
									{t("family_member_attribute_children_relation")}
								</IonLabel>
								<IonSelect
									className=""
									placeholder={t("family_member_attribute_children_relation_placeholder")}
									value={childrenRelation?._id}
									onIonChange={(e) => {
										if (e.detail.value) {
											setChildrenRelation(
												childrenRelationTypes?.find(
													(relation) => e.detail.value === relation._id
												)
											)
										}
									}}
									key={`children-relation-${childrenRelation?._id}`}
								>
									{childrenRelationTypes?.map((relation) => (
										<IonSelectOption key={`relation-option-${relation._id}`} value={relation._id}>
											{relation.children_relation}
										</IonSelectOption>
									))}
								</IonSelect>
							</div>
						</IonItem>

						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={busOutline} />
							</IonAvatar>

							<div className="flex-1 flex-col mt-[10px] mr-[10px] mb-[10px]">
								<IonLabel className="ion-text-wrap">
									{t("family_member_attribute_commute_vehicle")}
								</IonLabel>
								<IonSelect
									className=""
									placeholder={t("family_member_attribute_commute_vehicle_placeholder")}
									value={commuteVehicle?._id}
									onIonChange={(e) => {
										if (e.detail.value) {
											setCommuteVehicle(
												commuteVehicleTypes?.find((vehicle) => e.detail.value === vehicle._id)
											)
										}
									}}
									key={`commute-vehicle-${educationLevel?._id}`}
								>
									{commuteVehicleTypes?.map((vehicle) => (
										<IonSelectOption key={`relation-option-${vehicle._id}`} value={vehicle._id}>
											{vehicle.commute_vehicle}
										</IonSelectOption>
									))}
								</IonSelect>
							</div>
						</IonItem>

						<IonItem className="w-full">
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={analyticsOutline} />
							</IonAvatar>

							<IonLabel className="!w-full ion-text-wrap flex flex-row">
								<h3 className="!w-full">{t("family_member_attribute_kilometres")}</h3>
								<IonInput
									className="!pl-0 !w-full"
									placeholder={t("family_member_attribute_kilometres_placeholder")}
									value={travelKilometres}
									onIonChange={(e) => {
										if (e.detail.value) {
											setTravelKilometres(Number(e.detail.value))
										}
									}}
									min={1}
									max={150}
									type="number"
								/>
							</IonLabel>
						</IonItem>

						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={timeOutline} />
							</IonAvatar>

							<IonLabel className="ion-text-wrap">
								<h3>{t("family_member_attribute_minutes")}</h3>
								<IonInput
									className="!pl-0 !w-full"
									placeholder={t("family_member_attribute_minutes_placeholder")}
									value={travelMinutes}
									onIonChange={(e) => {
										if (e.detail.value) {
											setTravelMinutes(Number(e.detail.value))
										}
									}}
									min={1}
									max={150}
									type="number"
								/>
							</IonLabel>
						</IonItem>

						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={ribbonOutline} />
							</IonAvatar>

							<div className="flex-1 flex-col mt-[10px] mr-[10px] mb-[10px]">
								<IonLabel className="ion-text-wrap">{t("family_member_education_level")}</IonLabel>
								<IonSelect
									className="text-right !break-normal !text-clip !overflow-visible !whitespace-normal"
									placeholder={t("family_member_education_level_placeholder")}
									value={educationLevel?._id}
									onIonChange={(e) => {
										if (e.detail.value) {
											setEducationLevel(
												educationLevelTypes?.find((level) => e.detail.value === level._id)
											)
										}
									}}
									key={`educational-level-${educationLevel?._id}`}
								>
									{educationLevelTypes?.map((level) => (
										<IonSelectOption key={`education-option-${level._id}`} value={level._id}>
											<p className="!break-normal !text-clip !overflow-visible !whitespace-normal">
												{level.educational_level}
											</p>
										</IonSelectOption>
									))}
								</IonSelect>
							</div>
						</IonItem>

						<IonItem lines={hasBeenInfected ? "none" : "full"}>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={medicalOutline} />
							</IonAvatar>

							<div className="flex-1 flex-col mt-[10px] mr-[10px] mb-[10px]">
								<IonLabel className="ion-text-wrap">
									{t("student_edit_profile_has_been_infected_label")}
								</IonLabel>
								<IonRadioGroup
									className="flex flex-col md:flex-row"
									value={hasBeenInfected}
									onIonChange={(e) => setHasBeenInfected(e.detail.value)}
								>
									<IonItem lines="none">
										<IonLabel className="mr-2">{t("yes")}</IonLabel>
										<IonRadio value={true} />
									</IonItem>

									<IonItem lines="none">
										<IonLabel className="mr-2">{t("no")}</IonLabel>
										<IonRadio value={false} />
									</IonItem>
								</IonRadioGroup>
							</div>
						</IonItem>

						{hasBeenInfected && (
							<IonItem className="mb-8">
								<IonList className="w-full">
									<IonLabel className="ion-text-wrap">
										{t("family_member_natural_infection_title")}:
									</IonLabel>
									{(naturalInfections || []).length > 0 ? (
										naturalInfections?.map((event, index) => {
											return (
												<div
													key={`natural-infections-${index + 1}`}
													className="flex flex-row justify-between w-full"
												>
													<IonText>
														{t("family_member_natural_infection_event")}: {event.date}
													</IonText>

													<IonIcon
														className="mr-3 w-5 h-5"
														color="black"
														icon={closeOutline}
														slot="end"
														onClick={() => {
															let newNaturalInfections = [...naturalInfections]
															newNaturalInfections.splice(index, 1)
															setNaturalInfections(newNaturalInfections)
														}}
													></IonIcon>
												</div>
											)
										})
									) : (
										<IonText>{t("family_member_no_natural_infections_yet")}</IonText>
									)}

									<IonButton
										className="h-[36px]"
										fill="outline"
										id="open-natural-infections-modal"
										expand="block"
									>
										{t("family_member_add_natural_infection_event")}
									</IonButton>
								</IonList>

								<IonModal ref={infectionModal} trigger="open-natural-infections-modal">
									<IonHeader>
										<IonToolbar>
											<IonButtons slot="start">
												<IonButton
													onClick={() => {
														setSelectedNaturalInfectionDate(undefined)
														infectionModal.current?.dismiss()
													}}
												>
													{t("cancel")}
												</IonButton>
											</IonButtons>

											<IonTitle>{t("family_member_add_natural_infection_event")}</IonTitle>

											<IonButtons slot="end">
												<IonButton
													onClick={() => {
														if (selectedNaturalInfectionDate && naturalInfections) {
															const naturalInfection: INaturalInfections = {
																date: isoStringToServerDateConverter(
																	new Date(
																		selectedNaturalInfectionDate.date
																	).toISOString()
																),
																date_res: "YMD"
															}

															setNaturalInfections([
																...(naturalInfections || []),
																naturalInfection
															])
														}

														infectionModal.current?.dismiss(
															infectionInput.current?.value,
															"confirm"
														)
													}}
												>
													{t("confirm")}
												</IonButton>
											</IonButtons>
										</IonToolbar>
									</IonHeader>

									<div className="p-4 text-center">
										<IonText className="text-xl my-3 font-semibold">
											{t("family_member_select_natural_infection_date")}
										</IonText>
										<IonDatetime
											id="natural-infection-date"
											size="cover"
											value={selectedNaturalInfectionDate?.date}
											onIonChange={(e) => {
												if (e.detail.value) {
													setSelectedNaturalInfectionDate({
														date: e.detail.value.toString(),
														date_res: "YMD"
													})
												}
											}}
											presentation="date"
										/>
									</div>
								</IonModal>
							</IonItem>
						)}

						<IonItem lines="none">
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={medkitOutline} />
							</IonAvatar>

							<div className="flex-1 flex-col mt-[10px] mr-[10px] mb-[10px]">
								<IonLabel className="ion-text-wrap">{t("family_member_vaccinated")}</IonLabel>
								<IonRadioGroup
									className="flex flex-col md:flex-row"
									value={vaccinated}
									onIonChange={(e) => setVaccinated(e.detail.value)}
								>
									<IonItem lines="none">
										<IonLabel className="mr-2">{t("yes")}</IonLabel>
										<IonRadio value={true} />
									</IonItem>

									<IonItem lines="none">
										<IonLabel className="mr-2">{t("no")}</IonLabel>
										<IonRadio value={false} />
									</IonItem>
								</IonRadioGroup>
							</div>
						</IonItem>

						{vaccinated && (
							<IonItem lines="full">
								<IonList className="w-full mb-6">
									<IonLabel className="ion-text-wrap">
										{t("family_member_vaccine_dose_title")}:
									</IonLabel>
									{(vaccineDoses || []).length > 0 ? (
										vaccineDoses?.map((dose, index) => {
											return (
												<div
													key={`vaccine-dose-${index + 1}`}
													className="flex flex-col w-full "
												>
													<div className="flex flex-row w-full justify-between">
														<IonText className="font-bold mt-3">
															{t("family_member_dose")} {`#${dose?.dose_number}`}
														</IonText>

														<IonIcon
															className="mr-3 w-5 h-5 mt-[13px]"
															color="black"
															icon={closeOutline}
															slot="end"
															onClick={() => {
																const newVaccineDoses = [...vaccineDoses]
																newVaccineDoses.splice(index, 1)
																setVaccineDoses(
																	newVaccineDoses.map((vax, index) => ({
																		...vax,
																		dose_number: index + 1
																	}))
																)
															}}
														></IonIcon>
													</div>

													<IonText>
														{t("family_member_dose")}: {dose.date}
													</IonText>
													<IonText>
														{t("family_member_vaccine")}: {dose.vaccine_?.name}
													</IonText>
												</div>
											)
										})
									) : (
										<IonText>{t("family_member_no_vaccine_dose_yet")}</IonText>
									)}

									<IonButton
										className="h-[36px] ion-text-wrap"
										fill="outline"
										id="open-vaccine-modal"
										expand="block"
									>
										{t("family_member_add_vaccine_dose")}
									</IonButton>
								</IonList>

								<IonModal ref={modal} trigger="open-vaccine-modal">
									<IonHeader>
										<IonToolbar>
											<IonButtons slot="start">
												<IonButton
													onClick={() => {
														setSelectedVaccineDoseDate(undefined)
														setSelectedVaccineDoseName(undefined)
														modal.current?.dismiss()
													}}
												>
													{t("cancel")}
												</IonButton>
											</IonButtons>

											<IonTitle>{t("family_member_add_vaccine_dose")}</IonTitle>

											<IonButtons slot="end">
												<IonButton
													onClick={() => {
														if (selectedVaccineDoseDate && selectedVaccineDoseName) {
															const vaccineDose: IVaccineDose = {
																date: isoStringToServerDateConverter(
																	selectedVaccineDoseDate
																),
																date_res: "YMD",
																dose_number: (vaccineDoses || []).length + 1 || 1,
																vaccine: vaccineTypes?.find((vax) => {
																	return vax.name === selectedVaccineDoseName
																})?._id,
																vaccine_: {
																	_id: vaccineTypes?.find(
																		(vax) => vax.name === selectedVaccineDoseName
																	)?._id,
																	name: selectedVaccineDoseName
																}
															}

															setVaccineDoses([...(vaccineDoses || []), vaccineDose])
														}

														modal.current?.dismiss(input.current?.value, "confirm")
													}}
												>
													{t("confirm")}
												</IonButton>
											</IonButtons>
										</IonToolbar>
									</IonHeader>

									<div className="p-4 text-center">
										<IonText className="text-xl my-3 font-semibold">
											{t("family_member_select_vaccine_date")}
										</IonText>
										<IonDatetime
											id="vaccine-date"
											size="cover"
											value={selectedVaccineDoseDate}
											onIonChange={(e) => {
												if (e.detail.value) {
													setSelectedVaccineDoseDate(e.detail.value.toString())
												}
											}}
											presentation="date"
										/>

										<IonText className="text-xl my-3 font-semibold">
											{t("family_member_select_vaccine_type")}
										</IonText>
										<IonSelect
											placeholder={t("family_member_select_vaccine_type_placeholder")}
											value={selectedVaccineDoseName}
											onIonChange={(e) => {
												if (e.detail.value) {
													setSelectedVaccineDoseName(e.detail.value?.toString())
												}
											}}
										>
											{vaccineTypes?.map((type) => (
												<IonSelectOption
													key={`type-vaccine-option-${type._id}`}
													value={type.name}
												>
													{type.name}
												</IonSelectOption>
											))}
										</IonSelect>
									</div>
								</IonModal>
							</IonItem>
						)}

						<IonItem className="w-full mt-6 mb-6" lines="none">
							<IonButton className="w-full h-12" onClick={onSave} fill="outline" color="danger">
								{t("student_edit_profile_save_button")}
							</IonButton>
						</IonItem>
					</IonList>
				)}
				<DummyBottomNavigationBar />
			</IonContent>
		</IonPage>
	)
}
