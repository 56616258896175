const SpanishTranslations = {
	tab_surveys_main_title: "Encuestas",
	tab_surveys_questions_label_questionnaires: "Preguntas",
	tab_surveys_started_label_questionnaires: " – Inicio",
	tab_surveys_completed_label_questionnaires: " – Completado",
	tab_surveys_no_questionnaires_availables: "Cuestionario no disponible",
	tab_surveys_family_member: "Miembro de la familia",
	tab_surveys_select_child: "seleccione a su hijo(a)",
	survey_questionnaire_completed_label: "Completado ✅",

	tab_forms_main_title: "Cuestionario Semanal",
	tab_forms_weekly_questionnaires_card_title: "Cuestionario Semanal",
	tab_forms_create_weekly_questionnaires_card_subtitle: "Enviar formulario",
	tab_forms_create_weekly_questionnaires_card_button: "Ver",
	tab_forms_create_weekly_questionnaires_form_history_edit_button: "Ver",
	tab_forms_empty_state_label: "Para enviar el primer formulario, de click en el boton de arriba",
	tab_forms_form_made_on_date: "Fecha de creación",
	tab_forms_completion_date: "Este formulario fue realizado en",
	tab_forms_family_member_symptoms:
		"¿Algún miembro de la familia ha tenido uno o más de los siguientes síntomas en la última semana?",
	tab_forms_form_number_of_antigen_tests_label: "Cantidad de pruebas de ántigeno realizadas durante la semana",
	tab_forms_form_number_of_molecular_tests_label: "Cantidad de pruebas PCR realizadas durante la semana",
	tab_forms_form_any_positive_antigen_tests_label: "¿Alguna de las pruebas de antígeno resulto positiva?",
	tab_forms_form_confirmed_by_molecular_tests_label:
		"¿Alguna de las pruebas de antígeno positivas se confirmó mediante PCR?",
	tab_forms_form_user_dont_remember_exact_number: "no recuerdo la cantidad exacta",
	form_switch_label:
		"¿te haz realizado pruebas para COVID-19? (incluya todas, sin importar si son positivas o negativas)",

	tab_profile_main_title: "Perfil",
	tab_profile_family_card_title: "Familia",
	tab_profile_family_card_subtitle: "Miembro de la familia",
	tab_profile_family_card_button: "Ver",
	tab_profile_user_attribute_label_username: "Usuario",
	tab_profile_user_attribute_label_profile: "Perfil",
	tab_profile_user_attribute_label_first_name: "Nombre(s)",
	tab_profile_user_attribute_label_last_name: "Apellidos",
	tab_profile_user_attribute_label_country: "País",
	tab_profile_user_attribute_label_language: "Idioma",
	tab_profile_user_attribute_label_description: "Descripción",
	tab_profile_user_attribute_label_family: "Familia",
	tab_profile_user_attribute_label_school_address: "Dirección de la Escuela",
	tab_profile_user_attribute_label_school_name: "Nombre de la escuela",
	tab_profile_user_attribute_label_fiscal_code: "RFC sin homoclave",
	tab_profile_user_attribute_label_gender: "Genero",
	tab_profile_user_attribute_label_year_of_birth: "Año de nacimiento",
	tab_profile_student_edit_card_title: "Editar",
	tab_profile_student_edit_card_subtitle: "Editar Perfil",
	tab_profile_student_edit_card_button: "Ver",

	student_edit_profile_main_title: "Editar Perfil",
	student_edit_profile_save_button: "Confirmar",
	student_edit_profile_first_name_label: "Nombre(s)",
	student_edit_profile_last_name_label: "Apellidos",
	student_edit_profile_fiscal_code_label: "RFC sin homoclave",
	student_edit_profile_gender_label: "Genero",
	student_edit_profile_year_of_birth_label: "Año de nacimiento",
	student_edit_profile_has_been_infected_label: "¿Alguna vez ha tenido una infección por Covid?",

	family_main_title: "Familia",
	family_attribute_gender_male: "Masculino",
	family_attribute_gender_female: "Femenino",
	family_button_action_add_member: "Agregar miembro de la familia",

	family_member_main_title_edit: "Editar miembro de la familia",
	family_member_main_title_create: "Agregar miembro de la familia",
	family_member_attribute_first_name: "Nombre(s)",
	family_member_attribute_first_name_placeholder: "Insertar espacio para nombre",
	family_member_attribute_last_name: "Apellidos",
	family_member_attribute_last_name_placeholder: "Insertar apellido",
	family_member_attribute_fiscal_code: "RFC sin homoclave",
	family_member_attribute_fiscal_code_placeholder: "Insertar RFC sin homoclave",
	family_member_attribute_gender: "Genero",
	family_member_attribute_gender_placeholder: "Seleccionar Genero",
	family_member_attribute_year_of_birth: "Año de nacimiento",
	family_member_attribute_year_of_birth_placeholder: "Seleccionar Año",
	family_member_attribute_children_relation: "Parentesco con el alumno",
	family_member_attribute_children_relation_placeholder: "Seleccionar parentesco con el alumno",
	family_member_attribute_center: "Centro",
	family_member_attribute_center_placeholder: "Seleccionar Centro",
	family_member_education_level: "Nivel Educativo",
	family_member_education_level_placeholder: "Seleccionar nivel educativo",
	family_member_has_been_ever_infected: "Este miembro de la familia ha tenido infección por COVID-19 alguna vez",
	family_member_no_infection: "Sin infección",
	family_member_no_infection_placeholder: "Sin Infección",
	family_member_vaccinated: "Vacunado para COVID-19",
	family_member_vaccine_dose_title: "Dosis de vacuna",
	family_member_dose: "Dosis de vacunas para COVID-19",
	family_member_vaccine: "Vacuna",
	family_member_no_vaccine_dose_yet: "Ninguna vacuna (Dosis) para COVID-19",
	family_member_add_vaccine_dose: "Agregar vacunacion COVID-19 (una por cada dosis)",
	family_member_select_vaccine_date: "Seleccione la fecha de vacunación",
	family_member_select_vaccine_type: "Seleccione el tipo de vacuna",
	family_member_select_vaccine_type_placeholder: "seleccione el tipo de vacuna",
	family_member_natural_infection_title: "Infección",
	family_member_natural_infection_event: "Evento de infección",
	family_member_no_natural_infections_yet: "Sin infeccion hasta la fecha",
	family_member_add_natural_infection_event: "Agregar evento de infección",
	family_member_select_natural_infection_date: "Seleccionar fecha de infección",

	submit: "Enviar",
	loading: "Cargando...",
	confirm: "Confirmar",
	cancel: "Cancelar",
	remove: "Eliminar",
	yes: "Si",
	no: "No"
}

export default SpanishTranslations
