import {
	IonAvatar,
	IonButton,
	IonCard,
	IonCardContent,
	IonCardSubtitle,
	IonCardTitle,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	IonText
} from "@ionic/react"
import {
	calendarOutline,
	documentAttachOutline,
	flag,
	information,
	language,
	mail,
	people,
	person,
	personCircleOutline,
	school,
	transgenderOutline,
	peopleOutline,
	ribbonOutline,
	busOutline,
	analyticsOutline,
	timeOutline,
	medkitOutline,
	medicalOutline
} from "ionicons/icons"
import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { userCenterIdAssociatedAtomStorage, usernameAtomStorage } from "../../../../atoms/user"
import useLogout from "../../../../hooks/useLogout"
import useT from "../../../../hooks/useT"
import { useGetUserFilteredQuery } from "../../../../services/api-config/generated"
import { eucareApolloClient } from "../../../../services/api-eucare/client/apollo"
import { useGetPersonInfoPlainMutation, useGetPersonLazyQuery } from "../../../../services/api-eucare/generated"
import { INaturalInfections, IVaccineDose } from "../../../FamilyMember/FamilyMember"
import { DummyBottomNavigationBar } from "../../BottomNavigationBar/BottomNavigationBar"
import Toolbar from "../../Toolbar/Toolbar"

export default function TabProfile() {
	const history = useHistory()
	const location = useLocation()

	const logout = useLogout()

	const [username] = useAtom(usernameAtomStorage)
	const [, setUserCenterIdAssociated] = useAtom(userCenterIdAssociatedAtomStorage)

	const [firstName, setFirstName] = useState<string>()
	const [lastName, setLastName] = useState<string>()
	const [fiscalCode, setFiscalCode] = useState<string>()

	const [childrenRelation, setChildrenRelation] = useState<string>()
	const [educationLevel, setEducationLevel] = useState<string>()
	const [hasBeenInfected, setHasBeenInfected] = useState<boolean>(false)
	const [vaccinated, setVaccinated] = useState<boolean>(false)
	const [vaccineDoses, setVaccineDoses] = useState<IVaccineDose[]>()
	const [travelMinutes, setTravelMinutes] = useState<number>()
	const [travelKilometres, setTravelKilometres] = useState<number>()
	const [commuteVehicle, setCommuteVehicle] = useState<string>()
	const [naturalInfections, setNaturalInfections] = useState<INaturalInfections[]>()

	const t = useT()

	const { data } = useGetUserFilteredQuery({
		variables: {
			username
		},
		client: eucareApolloClient
	})

	const [getPerson] = useGetPersonLazyQuery({ client: eucareApolloClient, fetchPolicy: "no-cache" })

	const [getPersonInfoPlain] = useGetPersonInfoPlainMutation({
		client: eucareApolloClient
	})

	useEffect(() => {
		if (location.pathname !== "/home") {
			return
		}

		if (data) {
			setUserCenterIdAssociated(data._User___getPage?.items[0].center_?._id)
		}

		if (data?._User___getPage?.items?.[0].person_?._id) {
			const fetchPersonDataPlain = async () => {
				const [personDataPlain, personInfo] = await Promise.all([
					getPersonInfoPlain({
						variables: {
							input: data?._User___getPage?.items?.[0].person_?._id!
						}
					}),
					getPerson({
						variables: {
							input: data?._User___getPage?.items?.[0].person_?._id!
						}
					})
				])

				setFirstName(personDataPlain.data?.Person___formAction___editData?.data?.name ?? undefined)
				setLastName(personDataPlain.data?.Person___formAction___editData?.data?.surname ?? undefined)
				setFiscalCode(personDataPlain.data?.Person___formAction___editData?.data?.fiscal_code ?? undefined)

				setChildrenRelation(personInfo.data?.Person___get?.children_relation_?.children_relation ?? undefined)
				setEducationLevel(personInfo.data?.Person___get?.educational_level_?.educational_level ?? undefined)
				setHasBeenInfected(personInfo.data?.Person___get?.past_infection ?? false)
				setTravelMinutes(personInfo.data?.Person___get?.minutes ?? undefined)
				setTravelKilometres(personInfo.data?.Person___get?.kilometers ?? undefined)
				setCommuteVehicle(personInfo.data?.Person___get?.commute_vehicle_?.commute_vehicle ?? undefined)

				setVaccinated(personInfo.data?.Person___get?.vaccinated ?? false)
				setVaccineDoses(personInfo.data?.Person___get?.vaccine_dose_?.items)
				setNaturalInfections(personInfo.data?.Person___get?.natural_infection_?.items)
			}

			fetchPersonDataPlain()
		}
	}, [data, getPerson, getPersonInfoPlain, setUserCenterIdAssociated, location.pathname])

	return (
		<IonPage>
			<IonContent>
				<Toolbar title={t("tab_profile_main_title")} />

				<IonList>
					{data?._User___getPage?.items?.[0]?.profile === "Family" && (
						<IonCard>
							<IonCardContent className="flex flex-row justify-between items-center">
								<div className="mr-2">
									<IonCardTitle slot="">{t("tab_profile_family_card_title")}</IonCardTitle>
									<IonCardSubtitle>{t("tab_profile_family_card_subtitle")}</IonCardSubtitle>
								</div>
								<div>
									<IonButton
										fill="outline"
										slot="end"
										onClick={() => {
											history.push(`/family/${data?._User___getPage?.items?.[0]?.family_?._id}`)
										}}
									>
										{t("tab_profile_family_card_button")}
									</IonButton>
								</div>
							</IonCardContent>
						</IonCard>
					)}

					{data?._User___getPage?.items?.[0]?.profile === "Student" && (
						<IonCard>
							<IonCardContent className="flex flex-row justify-between items-center">
								<div className="mr-2">
									<IonCardTitle slot="">{t("tab_profile_student_edit_card_title")}</IonCardTitle>
									<IonCardSubtitle>{t("tab_profile_student_edit_card_subtitle")}</IonCardSubtitle>
								</div>
								<div>
									<IonButton
										fill="outline"
										slot="end"
										onClick={() => {
											history.push("/editProfile")
										}}
									>
										{t("tab_profile_student_edit_card_button")}
									</IonButton>
								</div>
							</IonCardContent>
						</IonCard>
					)}

					{data?._User___getPage?.items?.[0]?.username && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={person} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_username")}</h3>
								<h4>{data?._User___getPage?.items?.[0]?.username}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.email && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={mail} />
							</IonAvatar>
							<IonLabel>
								<h3>Email</h3>
								<h4>{data?._User___getPage?.items?.[0]?.email}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.profile && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={information} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_profile")}</h3>
								<h4>{data?._User___getPage?.items?.[0]?.profile}</h4>
							</IonLabel>
						</IonItem>
					)}

					{firstName && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={person} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_first_name")}</h3>
								<h4>{firstName}</h4>
							</IonLabel>
						</IonItem>
					)}

					{lastName && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={personCircleOutline} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_last_name")}</h3>
								<h4>{lastName}</h4>
							</IonLabel>
						</IonItem>
					)}

					{fiscalCode && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={documentAttachOutline} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_fiscal_code")}</h3>
								<h4>{fiscalCode}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.person_?.gender && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={transgenderOutline} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_gender")}</h3>
								<h4>
									{data?._User___getPage?.items?.[0]?.person_?.gender === "M"
										? t("family_attribute_gender_male")
										: t("family_attribute_gender_female")}
								</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.person_?.year_of_birth && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={calendarOutline} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_year_of_birth")}</h3>
								<h4>{data?._User___getPage?.items?.[0]?.person_?.year_of_birth}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.country && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={flag} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_country")}</h3>
								<h4>{data?._User___getPage?.items?.[0]?.country}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.lang_?.name && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={language} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_language")}</h3>
								<h4>{data?._User___getPage?.items?.[0]?.lang_?.name}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.description && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={person} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_description")}</h3>
								<h4>{data?._User___getPage?.items?.[0]?.description}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.family_?.username && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={people} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_family")}</h3>
								<h4>{data?._User___getPage?.items?.[0]?.family_?.username}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.school_?.address && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={school} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_school_address")}</h3>
								<h4>{data?._User___getPage?.items?.[0]?.school_?.address}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.school_?.name && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={school} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("tab_profile_user_attribute_label_school_name")}</h3>
								<h4>{data?._User___getPage?.items?.[0]?.school_?.name}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.profile === "Student" && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={peopleOutline} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("family_member_attribute_children_relation")}</h3>
								<h4>{childrenRelation}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.profile === "Student" && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={ribbonOutline} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("family_member_education_level")}</h3>
								<h4>{educationLevel}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.profile === "Student" && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={busOutline} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("family_member_attribute_commute_vehicle")}</h3>
								<h4>{commuteVehicle}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.profile === "Student" && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={analyticsOutline} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("family_member_attribute_kilometres")}</h3>
								<h4>{travelKilometres}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.profile === "Student" && (
						<IonItem>
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={timeOutline} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("family_member_attribute_minutes")}</h3>
								<h4>{travelMinutes}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.profile === "Student" && (
						<IonItem lines="none">
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={medkitOutline} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("family_member_vaccinated")}</h3>
								<h4>{vaccinated === true ? "Yes" : t("family_member_no_vaccine_dose_yet")}</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.profile === "Student" && vaccinated && (
						<IonItem lines="full">
							<IonList className="w-full mt-4 mb-6">
								<IonLabel className="ion-text-wrap">{t("family_member_vaccine_dose_title")}:</IonLabel>
								{(vaccineDoses || []).length > 0 ? (
									vaccineDoses?.map((dose, index) => {
										return (
											<div key={`vaccine-dose-${index + 1}`} className="flex flex-col">
												<IonText className="font-bold mt-3">
													{t("family_member_dose")} {`#${dose?.dose_number}`}
												</IonText>
												<IonText>
													{t("family_member_dose")}: {dose.date}
												</IonText>
												<IonText>
													{t("family_member_vaccine")}: {dose.vaccine_?.name}
												</IonText>
											</div>
										)
									})
								) : (
									<IonText>{t("family_member_no_vaccine_dose_yet")}</IonText>
								)}
							</IonList>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.profile === "Student" && (
						<IonItem lines="none">
							<IonAvatar
								className="flex justify-center items-center bg-[#263444] p-3 text-white"
								slot="start"
							>
								<IonIcon icon={medicalOutline} />
							</IonAvatar>
							<IonLabel>
								<h3>{t("family_member_natural_infection_title")}</h3>
								<h4>
									{hasBeenInfected === true ? "Yes" : t("family_member_no_natural_infections_yet")}
								</h4>
							</IonLabel>
						</IonItem>
					)}

					{data?._User___getPage?.items?.[0]?.profile === "Student" && hasBeenInfected && (
						<IonItem lines="none" className="mt-4 mb-8">
							<IonList className="w-full">
								<IonLabel className="ion-text-wrap">
									{t("family_member_natural_infection_title")}:
								</IonLabel>
								{(naturalInfections || []).length > 0 ? (
									naturalInfections?.map((event, index) => {
										return (
											<div
												key={`natural-infections-${index + 1}`}
												className="flex flex-row justify-between w-full"
											>
												<IonText>
													{t("family_member_natural_infection_event")}: {event.date}
												</IonText>
											</div>
										)
									})
								) : (
									<IonText>{t("family_member_no_natural_infections_yet")}</IonText>
								)}
							</IonList>
						</IonItem>
					)}

					<IonItem className="w-full mt-6 mb-6" lines="none">
						<IonButton className="w-full h-12" onClick={logout} fill="outline" color="danger">
							Logout
						</IonButton>
					</IonItem>
				</IonList>
				<DummyBottomNavigationBar />
			</IonContent>
		</IonPage>
	)
}
