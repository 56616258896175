import {
	IonBackButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonItem,
	IonListHeader,
	IonPage,
	IonRadioGroup,
	IonSkeletonText,
	IonToolbar
} from "@ionic/react"
import { useSafeArea } from "@reactivers/use-safe-area"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router"
import { useAlertMessageResult } from "../../hooks/useAlertMessageResult"
import useT from "../../hooks/useT"
import { configMobileApolloClient } from "../../services/api-configmobile/client/apollo"
import {
	GetQuestionnaireQuestionsListQueryResult,
	useGetQuestionnairePreviousAnswersLazyQuery,
	useGetQuestionnaireQuestionsListLazyQuery
} from "../../services/api-configmobile/generated"
import { eucareApolloClient } from "../../services/api-eucare/client/apollo"
import {
	FormActionCacheDataMutationVariables,
	useFormActionCacheDataMutation,
	useGetNextQuestionMutation,
	useQuestionnaireCompletionCreateMutation
} from "../../services/api-eucare/generated"
import { urlParamsToObject } from "../../utils/urlParams"
import Question from "./Question/Question"

export default function Survey() {
	const { questionnaireId } = useParams<{ questionnaireId: string | undefined }>()

	const { bottom: bottomSafeArea } = useSafeArea()

	const t = useT()

	const [alertMessage] = useAlertMessageResult()

	const params = useMemo(
		() => urlParamsToObject<FormActionCacheDataMutationVariables["input"]>(window.location.search),
		[]
	)

	const [questionsIdList, setQuestionsIdList] = useState<string[]>()
	const [questions, setQuestions] =
		useState<
			Array<
				NonNullable<
					NonNullable<
						NonNullable<
							NonNullable<GetQuestionnaireQuestionsListQueryResult["data"]>["Questionnaire_schema___get"]
						>["question_"]
					>["items"]
				>[number] & { visible?: boolean }
			>
		>()
	const [answers, setAnswers] = useState<{ [questionId: string]: string | null }>({})
	const [nQuestions, setNQuestions] = useState<number>()

	const [ready, setReady] = useState<boolean>(false)

	const [formActionCacheData] = useFormActionCacheDataMutation({ client: eucareApolloClient })

	const [questionnaireCompletionCreate] = useQuestionnaireCompletionCreateMutation({ client: eucareApolloClient })

	const [getNextQuestion] = useGetNextQuestionMutation({
		client: eucareApolloClient
	})

	const [getAnswersList] = useGetQuestionnairePreviousAnswersLazyQuery({
		client: configMobileApolloClient
	})

	const [getQuestionsList] = useGetQuestionnaireQuestionsListLazyQuery({
		client: configMobileApolloClient
	})

	const initSurvey = useCallback(async () => {
		setReady(false)

		const completionDate = new Date().toLocaleDateString("it-IT")

		let questionnaireCompletionId = params._id

		if (!questionnaireCompletionId) {
			try {
				await formActionCacheData({
					fetchPolicy: params.completed ? "no-cache" : undefined,
					variables: {
						input: { ...params, completion_date: completionDate }
					}
				}).then((res) => {
					if (
						alertMessage(
							res.data?.Questionnaire_completion___formAction___cacheData?.result.message?.message,
							["GetQuestionnaires", "GetQuestionnairesCompletionsList"]
						)
					) {
						return Promise.reject()
					}
				})
			} catch (error) {
				//Probabilmente il dialog è stato visualizzato e c'è un messaggio da mostrare
				return
			}

			const { data: questionnaireCompletionData } = await questionnaireCompletionCreate({
				variables: {
					input: {
						family_: params.family_,
						school_: params.school_,
						school_people_: params.school_people_,
						mobile: true,
						questionnaire_schema_: questionnaireId!,
						completion_date: completionDate
					}
				}
			})
			questionnaireCompletionId = questionnaireCompletionData?.Questionnaire_completion___create?._id
		}

		try {
			await formActionCacheData({
				fetchPolicy: params.completed ? "no-cache" : undefined,
				variables: {
					input: {
						...params,
						completion_date: !questionnaireCompletionId ? completionDate : undefined,
						_id: questionnaireCompletionId,
						questionnaire_schema_: questionnaireId
					}
				}
			}).then((res) => {
				if (
					alertMessage(res.data?.Questionnaire_completion___formAction___cacheData?.result.message?.message)
				) {
					return Promise.reject()
				}
			})
		} catch (error) {
			//Probabilmente il dialog è stato visualizzato e c'è un messaggio da mostrare
			return
		}

		setReady(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params, formActionCacheData, questionnaireCompletionCreate, questionnaireId])

	const applyQuestionsFilter = useCallback((newQuestions: typeof questions, newAnswers: typeof answers) => {
		const returnValue = [...(newQuestions ?? [])]

		for (const question of newQuestions ?? []) {
			for (const condition of question.question_enabling_condition_?.items ?? []) {
				for (const disabledCondition of condition.questions_disabled?.items ?? []) {
					// Questa domanda condiziona disabilitando le seguenti domande
					const disabledQuestionId = disabledCondition._id

					const disabledQuestionAnswerId = newAnswers[question._id ?? ""]

					const questionToDisableIndex = returnValue.findIndex((q) => {
						return q._id === disabledQuestionId
					})

					if (disabledQuestionAnswerId) {
						if (condition.possible_response_?._id === disabledQuestionAnswerId) {
							if (questionToDisableIndex !== -1) {
								returnValue[questionToDisableIndex] = {
									...returnValue[questionToDisableIndex],
									visible: false
								}
							}
						} else {
							if (questionToDisableIndex !== -1) {
								returnValue[questionToDisableIndex] = {
									...returnValue[questionToDisableIndex],
									visible: true
								}
							}
						}
					} else {
						if (questionToDisableIndex !== -1) {
							returnValue[questionToDisableIndex] = {
								...returnValue[questionToDisableIndex],
								visible: false
							}
						}
					}
				}

				for (const enabledCondition of condition.questions_enabled?.items ?? []) {
					const enabledQuestionId = enabledCondition._id

					const enabledByQuestionAnswerId = newAnswers[question._id ?? ""]

					const questionToEnableIndex = returnValue.findIndex((q) => {
						return q._id === enabledQuestionId
					})

					if (enabledByQuestionAnswerId) {
						if (condition.possible_response_?._id === enabledByQuestionAnswerId) {
							if (questionToEnableIndex !== -1) {
								returnValue[questionToEnableIndex] = {
									...returnValue[questionToEnableIndex],
									visible: true
								}
							}
						} else {
							if (questionToEnableIndex !== -1) {
								returnValue[questionToEnableIndex] = {
									...returnValue[questionToEnableIndex],
									visible: false
								}
							}
						}
					} else {
						if (questionToEnableIndex !== -1) {
							returnValue[questionToEnableIndex] = {
								...returnValue[questionToEnableIndex],
								visible: true
							}
						}
					}
				}
			}
		}

		return returnValue
	}, [])

	const filteredQuestions = useMemo(() => {
		const newFilteredQuestions = applyQuestionsFilter(questions, answers)
		return newFilteredQuestions
	}, [questions, answers, applyQuestionsFilter])

	const hasCompletedQuestionnaire = useMemo(() => {
		return (
			applyQuestionsFilter(questions, answers).filter(
				(q) => q.visible === true || typeof q.visible === "undefined"
			).length === Object.keys(answers).length
		)
	}, [answers, applyQuestionsFilter, questions])

	const collectAnswer = useCallback(
		(questionId: string, answerIdOrString: string, type: "String" | "Single choice") => {
			const newAnswers = { ...answers, [questionId]: answerIdOrString }
			setAnswers(newAnswers)

			const completed =
				applyQuestionsFilter(questions, newAnswers).filter(
					(q) => q.visible === true || typeof q.visible === "undefined"
				).length === Object.keys(newAnswers).length
			getNextQuestion({
				variables: {
					input: {
						possible_single_response: type === "Single choice" ? answerIdOrString : undefined,
						response_str: type === "String" ? answerIdOrString : undefined,
						question_: questionId,
						completed
					}
				}
			})
		},
		[answers, applyQuestionsFilter, getNextQuestion, questions]
	)

	useEffect(() => {
		initSurvey()
	}, [initSurvey, params])

	useEffect(() => {
		if (ready) {
			const questionnaireCompletionId = params._id

			if (questionnaireCompletionId) {
				getAnswersList({
					variables: {
						input: questionnaireCompletionId
					},
					fetchPolicy: "cache-and-network"
				}).then(({ data: previousAnswers }) => {
					const newAnswers = { ...answers }

					previousAnswers?.Questionnaire_completion___get?.response_?.items.forEach((answer) => {
						if (answer.question_?._id && answer.response_str) {
							newAnswers[answer.question_._id] = answer.response_str
						} else {
							if (answer.question_?._id && answer.possible_single_response?._id) {
								newAnswers[answer.question_._id] = answer.possible_single_response._id
							}
						}
					})

					setAnswers(newAnswers)
				})
			}

			getNextQuestion({
				variables: {
					input: {
						notes: "IDS_REQ"
					}
				}
			}).then(({ data: current }) => {
				setQuestionsIdList(current?.Responses___formAction___successiva?.data?.notes.split(","))
				setNQuestions(current?.Responses___formAction___successiva?.data?.notes.split(",").length)
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params._id, ready])

	useEffect(() => {
		if (questionsIdList && questionnaireId) {
			getQuestionsList({
				variables: {
					input: questionnaireId,
					questionInput: questionsIdList
				}
			}).then(({ data }) => {
				setQuestions(
					[...(data?.Questionnaire_schema___get?.question_?.items || [])].sort(
						(a, b) => Number(a.order) - Number(b.order)
					)
				)
			})
		}
	}, [getQuestionsList, questionnaireId, questionsIdList])

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/home" />
					</IonButtons>
				</IonToolbar>
			</IonHeader>

			<IonContent style={{ paddingBottom: bottomSafeArea + 44 }}>
				{!questions ? (
					Array.from({ length: 30 }).map((_, i) => (
						<div key={`question-${i}`}>
							<IonItem lines="none" key={`skeleton-item-${i}`}>
								<IonSkeletonText animated className="h-8 mt-8 mb-2" key={`skeleton-text-item-${i}`} />
							</IonItem>
							<div className="mt-12" key={`skeleton-item-radio-group-${i}`}>
								<IonRadioGroup key={`skeleton-question-${i}`} className="w-full">
									<IonListHeader key={`skeleton-question-header-${i}`}>
										<IonSkeletonText
											animated
											className="h-8"
											key={`skeleton-text-radio-item-${i}`}
										/>
									</IonListHeader>
									{Array.from({ length: 3 }).map((_, index) => (
										<IonItem key={`skeleton-response-${index}`}>
											<IonSkeletonText animated key={`skeleton-text-response-item-${i}`} />
										</IonItem>
									))}
								</IonRadioGroup>
							</div>
						</div>
					))
				) : (
					<>
						{!params.completed && (
							<IonItem>
								<h2 className="font-bold text-xl mt-8 mb-2 w-full">
									{t("tab_surveys_questions_label_questionnaires")}: {nQuestions}
								</h2>
							</IonItem>
						)}

						{(filteredQuestions ?? [])
							.filter((fQ) => fQ.visible === true || typeof fQ.visible === "undefined")
							.map((question, index) => {
								return (
									<>
										{index === 0 ? (
											<IonItem lines="none">
												<h2 className="font-bold text-2xl mt-8 mb-2">
													{questions[index].questionnaire_section}
												</h2>
											</IonItem>
										) : questions[index].questionnaire_section !==
										  questions[index - 1].questionnaire_section ? (
											<IonItem lines="none">
												<h2 className="font-bold text-2xl mt-8 mb-2">
													{questions[index].questionnaire_section}
												</h2>
											</IonItem>
										) : null}

										<Question
											key={`question-${question._id}`}
											question={question}
											questionNumber={index}
											collectAnswer={collectAnswer}
											questionPreviousAnswer={
												question._id ? answers[question._id] ?? undefined : undefined
											}
										/>
									</>
								)
							})}
					</>
				)}

				<div
					className="fixed z-50 bottom-0 left-0 w-full bg-gray-200"
					style={{
						height: bottomSafeArea + 44,
						opacity: Object.keys(answers).length > 0 && (filteredQuestions?.length ?? 0) > 0 ? 1 : 0
					}}
				>
					<div
						className="h-full bg-green-600 transition-all"
						style={{
							width: `${
								(100 * Object.keys(answers).length) /
								filteredQuestions.filter((q) => q.visible === true || typeof q.visible === "undefined")
									.length
							}%`
						}}
					>
						{hasCompletedQuestionnaire && (
							<p className="w-full h-full flex justify-center items-center text-center text-white font-semibold">
								{t("survey_questionnaire_completed_label")}
							</p>
						)}
					</div>
				</div>
			</IonContent>
		</IonPage>
	)
}
