import { IonItem } from "@ionic/react"
import eucareLogo from "../../../assets/eucare.png"

interface IToolbarProps {
	title: string
}

export default function Toolbar({ title }: IToolbarProps) {
	return (
		<IonItem lines="none">
			<div className="flex flex-col-reverse sm:w-full sm:flex-row sm:justify-between sm:items-center mt-8 mb-5">
				<h1 className="text-5xl font-bold">{title}</h1>
				<img width={180} height={42} src={eucareLogo} alt="Logo" className="ml-[-8px] sm:m-0" />
			</div>
		</IonItem>
	)
}
