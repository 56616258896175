import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardSubtitle,
	IonCardTitle,
	IonContent,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	IonSkeletonText
} from "@ionic/react"
import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { usernameAtomStorage } from "../../../../atoms/user"
import useT from "../../../../hooks/useT"
import { useGetUserFilteredQuery } from "../../../../services/api-config/generated"
import { eucareApolloClient } from "../../../../services/api-eucare/client/apollo"
import { GetFormHistoryQueryResult, useGetFormHistoryLazyQuery } from "../../../../services/api-eucare/generated"
import { DummyBottomNavigationBar } from "../../BottomNavigationBar/BottomNavigationBar"
import Toolbar from "../../Toolbar/Toolbar"

export default function TabForms() {
	const history = useHistory()
	const location = useLocation()

	const [username] = useAtom(usernameAtomStorage)

	const [formHistory, setFormHistory] =
		useState<
			NonNullable<
				NonNullable<GetFormHistoryQueryResult["data"]>["Family_weekly_questionnaire___getPage"]
			>["items"]
		>()

	const t = useT()

	const { data: user } = useGetUserFilteredQuery({
		variables: {
			username
		},
		client: eucareApolloClient
	})

	const [getFamilyFormHistory, { loading: loadingHistory }] = useGetFormHistoryLazyQuery({
		client: eucareApolloClient,
		fetchPolicy: "no-cache"
	})

	useEffect(() => {
		if (user?._User___getPage?.items?.[0]?.email && location.pathname === "/home") {
			getFamilyFormHistory({ fetchPolicy: "no-cache" }).then(({ data }) => {
				const newFormHistory: typeof formHistory = []

				data?.Family_weekly_questionnaire___getPage?.items.forEach((form) => {
					if (form.family_email === user?._User___getPage?.items?.[0]?.email) {
						newFormHistory?.push(form)
					}
				})

				setFormHistory(newFormHistory)
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, location])

	return (
		<IonPage>
			<IonContent>
				<Toolbar title={t("tab_forms_main_title")} />

				<IonCard className="mt-4">
					<IonCardContent className="flex flex-row justify-between items-center">
						<div className="mr-2">
							<IonCardTitle slot="">{t("tab_forms_weekly_questionnaires_card_title")}</IonCardTitle>
							<IonCardSubtitle>
								{t("tab_forms_create_weekly_questionnaires_card_subtitle")}
							</IonCardSubtitle>
						</div>
						<div>
							<IonButton
								fill="outline"
								slot="end"
								onClick={() => {
									history.push(`/family/${user?._User___getPage?.items[0].family_?._id}/form`)
								}}
							>
								{t("tab_forms_create_weekly_questionnaires_card_button")}
							</IonButton>
						</div>
					</IonCardContent>
				</IonCard>

				{loadingHistory ? (
					Array.from({ length: 20 }).map((_, index) => (
						<IonItem key={`forms-history-skeleton-${index}`}>
							<IonSkeletonText className="w-1/2 md:1/3 h-4" animated />
						</IonItem>
					))
				) : formHistory?.length === 0 ? (
					<IonItem lines="none">
						<IonLabel className="font-semibold text-xl break-word !whitespace-normal">
							{t("tab_forms_empty_state_label")}
						</IonLabel>
					</IonItem>
				) : (
					<IonList>
						{formHistory?.map((form) => (
							<IonItem
								key={`form-history-${form._id}`}
								detail
								onClick={() => {
									history.push(
										`/family/${user?._User___getPage?.items[0].family_?._id}/form/${form._id}`
									)
								}}
							>
								<IonLabel>{`${t("tab_forms_form_made_on_date")} ${form.date}`}</IonLabel>
							</IonItem>
						))}
					</IonList>
				)}

				<DummyBottomNavigationBar />
			</IonContent>
		</IonPage>
	)
}
