import { ApolloClient, InMemoryCache, createHttpLink, from } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"

const isDevMode = process.env.NODE_ENV === "development"

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_API_URL_CONFIGMOBILE
})

const createAuthLink = () =>
	setContext((_, { headers }) => {
		const token = localStorage.getItem("credentials")

		return {
			headers: {
				...headers,
				authorization: token ? `Basic ${localStorage.getItem("credentials")}` : ""
			}
		}
	})

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations = [], path = [] }) => {
			isDevMode &&
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${locations.join(" ")}, Path: ${path.join(" ")}`
				)
		})

	if (networkError) {
		isDevMode && console.log(`[Network error]: ${networkError}`)
	}
})

export const configMobileApolloClient = new ApolloClient({
	link: from([createAuthLink(), errorLink, httpLink]),
	cache: new InMemoryCache()
})
