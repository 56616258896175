const PortugueseTranslations = {
	tab_surveys_main_title: "Questionário",
	tab_surveys_questions_label_questionnaires: "Preguntas",
	tab_surveys_to_start_label_questionnaires: " – Para começar",
	tab_surveys_started_label_questionnaires: " – Iniciar",
	tab_surveys_completed_label_questionnaires: " – Completo",
	tab_surveys_no_questionnaires_availables: "Não Existem Questionários",
	tab_surveys_family_member: "Membro da Família",
	tab_surveys_select_child_label: "Selecionar o estudante para o qual irá preencher o questionário",
	tab_surveys_select_child: "Selecione a Criança",
	survey_questionnaire_completed_label: "Completo ✅",

	tab_forms_main_title: "Questionário Semanal",
	tab_forms_weekly_questionnaires_card_title: "Questionário Semanal",
	tab_forms_create_weekly_questionnaires_card_subtitle: "Submeter Formulário",
	tab_forms_create_weekly_questionnaires_card_button: "Ver",
	tab_forms_create_weekly_questionnaires_form_history_edit_button: "Ver",
	tab_forms_empty_state_label: "Submeta o seu primeiro formulário clicando no botão acima",
	tab_forms_form_made_on_date: "Formulário preenchido em",
	tab_forms_completion_date: "Este formulário foi preenchido em",
	tab_forms_family_member_symptoms:
		"Algum membro da família teve um ou mais dos seguintes sintomas durante a semana passada?",
	tab_forms_form_number_of_antigen_tests_label: "Número de Testes Antigénio durante esta semana",
	tab_forms_form_number_of_molecular_tests_label: "Número de Testes Moleculares durante esta semana",
	tab_forms_form_any_positive_antigen_tests_label: "Algum teste de Antigénio teve resultado positivo?",
	tab_forms_form_confirmed_by_molecular_tests_label:
		"Dos testes de Antigénio positivos algum foi confirmado por testes moleculares?",
	tab_forms_form_user_dont_remember_exact_number: "Não me recordo do número exato",
	form_switch_label: "Fez testes de COVID-19 (com resultado positivo e negativo) ...?",

	tab_profile_main_title: "Perfil",
	tab_profile_family_card_title: "Família",
	tab_profile_family_card_subtitle: "Membro da Família",
	tab_profile_family_card_button: "Ver",
	tab_profile_user_attribute_label_username: "Nome do Utilizador",
	tab_profile_user_attribute_label_profile: "Perfil",
	tab_profile_user_attribute_label_first_name: "Primeiro Nome",
	tab_profile_user_attribute_label_last_name: "Último Nome",
	tab_profile_user_attribute_label_country: "País",
	tab_profile_user_attribute_label_language: "Língua",
	tab_profile_user_attribute_label_description: "Descrição",
	tab_profile_user_attribute_label_family: "Família",
	tab_profile_user_attribute_label_school_address: "Morada da Escola",
	tab_profile_user_attribute_label_school_name: "Nome da Escola",
	tab_profile_user_attribute_label_fiscal_code: "Número de Identificação Fiscal",
	tab_profile_user_attribute_label_gender: "Género",
	tab_profile_user_attribute_label_year_of_birth: "Ano de Nascimento",
	tab_profile_student_edit_card_title: "Editar",
	tab_profile_student_edit_card_subtitle: "Editar Perfil",
	tab_profile_student_edit_card_button: "Ver",

	student_edit_profile_main_title: "Editar Perfil",
	student_edit_profile_save_button: "Salvar",
	student_edit_profile_first_name_label: "Primeiro Nome",
	student_edit_profile_last_name_label: "Último Nome",
	student_edit_profile_fiscal_code_label: "Número de Identificação Fiscal",
	student_edit_profile_gender_label: "Género",
	student_edit_profile_year_of_birth_label: "Ano de Nascimento",
	student_edit_profile_has_been_infected_label: "Você já teve uma infecção por Covid?",

	family_main_title: "Família",
	family_attribute_gender_male: "Masculino",
	family_attribute_gender_female: "Feminino",
	family_button_action_add_member: "Adicionar Membro",

	family_member_main_title_edit: "Editar Membro da Família",
	family_member_main_title_create: "Adicionar Membro da Família",
	family_member_attribute_first_name: "Primeiro Nome",
	family_member_attribute_first_name_placeholder: "Inserir Primeiro Nome",
	family_member_attribute_last_name: "Último Nome",
	family_member_attribute_last_name_placeholder: "Inserir Último Nome",
	family_member_attribute_fiscal_code: "Número de Identificação Fiscal",
	family_member_attribute_fiscal_code_placeholder: "Inserir o Número de Identificação Fiscal",
	family_member_attribute_gender: "Género",
	family_member_attribute_gender_placeholder: "Selecionar Género",
	family_member_attribute_year_of_birth: "Ano de Nascimento",
	family_member_attribute_year_of_birth_placeholder: "Selecionar Ano",
	family_member_attribute_children_relation: "Grau de Parentesco com a Criança",
	family_member_attribute_children_relation_placeholder: "Selecionar o Grau de Parentesco",
	family_member_attribute_commute_vehicle: "Veículo",
	family_member_attribute_commute_vehicle_placeholder: "Inserir veículo",
	family_member_attribute_minutes: "Minutos",
	family_member_attribute_minutes_placeholder: "Inserir minutos",
	family_member_attribute_kilometres: "Quilômetros",
	family_member_attribute_kilometres_placeholder: "Inserir quilômetros",
	family_member_attribute_center: "Centro",
	family_member_attribute_center_placeholder: "Selecionar Centro",
	family_member_education_level: "Nível de Escolaridade",
	family_member_education_level_placeholder: "Selecione o Nível de Escolaridade",
	family_member_has_been_ever_infected: "Este familiar já foi infectado?",
	family_member_no_infection: "Sem infeção",
	family_member_no_infection_placeholder: "Sem infeção",
	family_member_vaccinated: "Vacinado (a)",
	family_member_vaccine_dose_title: "Dose da Vacina",
	family_member_dose: "Dose",
	family_member_vaccine: "Vacina",
	family_member_no_vaccine_dose_yet: "Ainda sem Dose da Vacina",
	family_member_add_vaccine_dose: "Adicionar a Dose da Vacina",
	family_member_select_vaccine_date: "Selecionar a Data da Vacina",
	family_member_select_vaccine_type: "Selecionar o Tipo de Vacina",
	family_member_select_vaccine_type_placeholder: "Selecionar o Tipo de Vacina",
	family_member_natural_infection_title: "Infeção por SARS-CoV-2",
	family_member_natural_infection_event: "Infeção por SARS-CoV-2",
	family_member_no_natural_infections_yet: "Ainda não há infecções",
	family_member_add_natural_infection_event: "Adicionar Infeção por SARS-CoV-2",
	family_member_select_natural_infection_date: "Selecionar a Data Infeção por SARS-CoV-2",

	forgot_password: "Esqueceu a sua password?",
	forgot_password_dialogue: `Para restabelecer a sua password, selecione "Ir para a página" e no canto superior direito da p​ágina selecione "Esqueceu a sua password`,
	go_to_page: "Ir para a página",
	submit: "Submeter",
	loading: "Carregar...",
	confirm: "Confirmar",
	cancel: "Cancelar",
	remove: "Remover",
	yes: "Sim",
	no: "Não"
}

export default PortugueseTranslations
