import { ApolloProvider } from "@apollo/client"
import { defineCustomElements } from "@ionic/pwa-elements/loader"
import { SafeAreaProvider } from "@reactivers/use-safe-area"
import React from "react"
import ReactDOM from "react-dom"
import { Redirect, Route } from "react-router-dom"
import Home from "./screens/Home/Home"
import Login from "./screens/Login/Login"
import Surveys from "./screens/Survey/Survey"
import { configApolloClient } from "./services/api-config/client/apollo"
import { configMobileApolloClient } from "./services/api-configmobile/client/apollo"
import { eucareApolloClient } from "./services/api-eucare/client/apollo"

import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"

import "@ionic/react/css/core.css"
import "@ionic/react/css/display.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/padding.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/typography.css"

import Family from "./screens/Family/Family"
import FamilyMember from "./screens/FamilyMember/FamilyMember"
import Form from "./screens/Form/Form"

import { Capacitor } from "@capacitor/core"
import "./theme/root.css"
import EditProfile from "./screens/EditProfile/EditProfile"

setupIonicReact({
	scrollAssist: false,
	swipeBackEnabled: Capacitor.isNativePlatform()
})

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={configApolloClient}>
			<ApolloProvider client={configMobileApolloClient}>
				<ApolloProvider client={eucareApolloClient}>
					<SafeAreaProvider>
						<IonApp>
							<IonReactRouter>
								<IonRouterOutlet>
									<Route path="/home" component={Home} />
									<Route exact path="/login" component={Login} />
									<Route exact path="/questionnaire/:questionnaireId" component={Surveys} />
									<Route exact path="/editProfile" component={EditProfile} />
									<Route exact path="/family/:id/form" component={Form} />
									<Route exact path="/family/:id/form/:formCompletedId" component={Form} />
									<Route exact path="/family/:id" component={Family} />
									<Route exact path="/family/:id/member/:familyMemberId?" component={FamilyMember} />
									<Route exact path="/" render={() => <Redirect to="/login" />} />
								</IonRouterOutlet>
							</IonReactRouter>
						</IonApp>
					</SafeAreaProvider>
				</ApolloProvider>
			</ApolloProvider>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById("root")
)

defineCustomElements(window)
