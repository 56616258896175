import { IonButton, IonInput, IonPage, useIonAlert } from "@ionic/react"
import { useAtom } from "jotai"
import { useCallback, useEffect, useState } from "react"
import Marquee from "react-fast-marquee"
import { useHistory } from "react-router-dom"
import eucareLogo from "../../assets/eucare.png"
import { userCredentialsAtomStorage, usernameAtomStorage, userPlatformLanguageAtomStorage } from "../../atoms/user"
import useDimensions from "../../hooks/useDimensions"
import useSystemUi from "../../hooks/useSystemUi"
import useT from "../../hooks/useT"
import { useGetUserFilteredLazyQuery } from "../../services/api-config/generated"
import { eucareApolloClient } from "../../services/api-eucare/client/apollo"

export default function Login() {
	const history = useHistory()
	const t = useT()
	const [, setCredentials] = useAtom(userCredentialsAtomStorage)
	const [, setUserLanguage] = useAtom(userPlatformLanguageAtomStorage)
	const [username, setUsername] = useAtom(usernameAtomStorage)
	const [password, setPassword] = useState<string>()

	const [isCheckingCredentials, setIsCheckingCredentials] = useState<boolean>(false)
	const [checkCredentials] = useGetUserFilteredLazyQuery({
		client: eucareApolloClient
	})

	const [alert] = useIonAlert()

	const { updateStatusBar } = useSystemUi()

	const { isMobile, width, height } = useDimensions()
	const [showAlert] = useIonAlert()

	const handleLogin = useCallback(() => {
		const newCredentials = btoa(`${username}:${password}`)
		setCredentials(newCredentials)

		setIsCheckingCredentials(true)

		checkCredentials({
			variables: { username }
		})
			.then((res) => {
				if (res.error) {
					throw res.error
				}

				setUserLanguage(res.data?._User___getPage?.items[0].lang_?.name ?? "English")

				history.replace("/home")
			})
			.catch(() => {
				setCredentials(undefined)
				showAlert("Wrong credentials")
			})
			.finally(() => setIsCheckingCredentials(false))
	}, [username, password, setCredentials, checkCredentials, setUserLanguage, history, showAlert])

	useEffect(() => {
		updateStatusBar("dark")

		// Hacky workaround for marquee not auto-starting
		setTimeout(() => {
			window.dispatchEvent(new Event("resize"))
		}, 200)
	}, [updateStatusBar])

	return (
		<IonPage>
			<div className="flex flex-col h-screen">
				<div className="flex flex-col items-center flex-1 w-full bg-[#263444]">
					{height > 676 && (
						<>
							<div className="w-full flex justify-end max-w-6xl">
								<h4
									className={`text-white ${isMobile ? "mr-2" : ""} ${
										height < 676 ? "mt-2" : "mt-36"
									}`}
								>
									EuCARE SCHOOL Studies
								</h4>
							</div>
							<Marquee
								gradient={!isMobile}
								gradientColor={[38, 52, 68]}
								direction="left"
								className="h-48 overflow-y-hidden w-full max-w-6xl"
							>
								<h1 className="text-9xl text-white font-bold">
									{Array.from({ length: Math.round(width / 10) })
										.fill("Welcome")
										.join(" ")
										.trim()}
								</h1>
							</Marquee>

							<Marquee
								gradient={!isMobile}
								gradientColor={[38, 52, 68]}
								direction="right"
								className={`h-24 overflow-y-hidden w-full max-w-6xl ${isMobile ? "-mt-3" : "mt-2"}`}
							>
								<h3 className="text-7xl text-white font-bold">
									{Array.from({ length: Math.round(width / 10) })
										.fill("School Study Survey")
										.join(" ")
										.trim()}
								</h3>
							</Marquee>
						</>
					)}

					<div
						className="bg-white flex flex-col justify-center items-center z-50 rounded-t-3xl px-16 -mt-1 w-full max-w-6xl h-full"
						style={{
							boxShadow: "0px -10px 33px 0px rgba(0,0,0,0.5)",
							borderRadius: height < 676 ? 0 : undefined
						}}
					>
						<div className="flex flex-col w-full">
							<img className="self-center md:w-60" src={eucareLogo} alt="EuCARE Logo" />

							<p className="self-center mb-8">SCHOOL Studies</p>

							<IonInput
								type="text"
								inputmode="text"
								className="bg-gray-200 h-12 rounded-xl w-full max-h-12 mb-3 px-6 text-black"
								placeholder="Username"
								value={username}
								onIonChange={(e) => {
									setUsername(e.detail.value ?? undefined)
								}}
							/>

							<IonInput
								type="password"
								inputmode="text"
								className="bg-gray-200 h-12 rounded-xl w-full max-h-12 mb-3 px-6 text-black"
								placeholder="Password"
								value={password}
								onIonChange={(e) => {
									setPassword(e.detail.value ?? undefined)
								}}
							/>

							<IonButton
								type="submit"
								className="w-full h-14 rounded-xl mb-3 font-bold"
								onClick={handleLogin}
								color="primary"
							>
								{isCheckingCredentials ? "Loading..." : "Sign In →"}
							</IonButton>

							<div style={{ textAlign: "center" }}>
								<p
									style={{ textDecoration: "underline", cursor: "pointer" }}
									onClick={() => {
										alert(t("forgot_password_dialogue"), [
											{
												text: t("go_to_page"),
												handler: () => {
													window.open(
														"https://ecrf.eucareresearch.eu/eucare/EuCARE_DB/Index.jsp?locale=en#!homePage",
														"_blank"
													)
												}
											},
											{
												text: t("cancel")
											}
										])
									}}
								>
									{t("forgot_password")}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</IonPage>
	)
}
