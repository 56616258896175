import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardTitle,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonList,
	IonPage,
	IonSkeletonText,
	IonText,
	IonTitle,
	IonToolbar
} from "@ionic/react"
import { useSafeArea } from "@reactivers/use-safe-area"
import { add, chevronForward, link, maleFemaleOutline, schoolOutline } from "ionicons/icons"
import { useEffect } from "react"
import { useHistory, useParams } from "react-router"
import { useAlertMessageResult } from "../../hooks/useAlertMessageResult"
import useT from "../../hooks/useT"
import { eucareApolloClient } from "../../services/api-eucare/client/apollo"
import {
	useGetFamilyComponentsInformationQuery,
	useGetFamilyMemberFormMutation
} from "../../services/api-eucare/generated"

export default function Family() {
	const history = useHistory()

	const params = useParams<{ id: string }>()

	const safeArea = useSafeArea()

	const t = useT()

	const [alertMessage] = useAlertMessageResult()

	const familyData = useGetFamilyComponentsInformationQuery({
		client: eucareApolloClient,
		fetchPolicy: "cache-and-network",
		variables: { input: params.id }
	})

	const [getFamilyMemberFormMutation, { data, loading }] = useGetFamilyMemberFormMutation({
		client: eucareApolloClient
	})

	useEffect(() => {
		if (params.id) {
			getFamilyMemberFormMutation({
				variables: {
					input: {
						family_: params.id,
						date: "11/11/1911"
					}
				}
			}).then((res) => {
				alertMessage(res.data?.Weekly_questionnaire___formAction___initData?.result.message?.message)
			})
		}
	}, [params.id, getFamilyMemberFormMutation, alertMessage])

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/home" />
					</IonButtons>

					<IonTitle>{t("family_main_title")}</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<IonList>
					{!familyData.data?.Family___get?.person_?.items || loading ? (
						<div>
							{Array.from({ length: 4 }).map((_, index) => (
								<IonCard key={`family-member-skeleton-${index}`}>
									<IonCardContent>
										<IonCardTitle>
											<IonSkeletonText className="w-[80%] md:w-[35%] h-8" animated />
										</IonCardTitle>
										<IonSkeletonText className="w-[85%] md:w-[40%] mt-2 h-5" animated />
										<IonSkeletonText className="w-[75%] md:w-[30%] mt-2 h-5" animated />
										<IonSkeletonText className="w-[80%] md:w-[35%] mt-2 h-5" animated />
									</IonCardContent>
								</IonCard>
							))}
						</div>
					) : (
						familyData.data?.Family___get?.person_?.items.map((person) => {
							return (
								<IonCard
									key={`family-member-${person._id}`}
									className="cursor-pointer"
									onClick={() => {
										history.push(`/family/${params.id}/member/${person._id}`)
									}}
								>
									<IonCardContent>
										<div className="flex flex-row justify-between items-center">
											<div>
												<IonCardTitle slot="start" className="font-bold">
													{
														data?.Weekly_questionnaire___formAction___initData?.data?.covid_test_?.items?.find(
															(p) => {
																return p.person_?._id === person._id
															}
														)?.name
													}{" "}
													{
														data?.Weekly_questionnaire___formAction___initData?.data?.covid_test_?.items?.find(
															(p) => {
																return p.person_?._id === person._id
															}
														)?.surname
													}
												</IonCardTitle>

												{person.gender && (
													<div className="flex flex-row items-center mt-4">
														<IonIcon
															className="mr-3 w-5 h-5"
															color="black"
															icon={maleFemaleOutline}
															slot="start"
														/>
														<IonText className="text-black text-xl">
															{person.gender === "M"
																? t("family_attribute_gender_male")
																: t("family_attribute_gender_female")}
														</IonText>
													</div>
												)}

												{person.educational_level_?.educational_level && (
													<div className="flex flex-row items-center mt-4">
														<IonIcon
															className="mr-3 w-5 h-5"
															color="black"
															icon={schoolOutline}
															slot="start"
														/>
														<IonText className="text-black text-xl">
															{person.educational_level_?.educational_level}
														</IonText>
													</div>
												)}

												{person.children_relation && (
													<div className="flex flex-row items-center mt-4">
														<IonIcon
															className="mr-3 w-5 h-5"
															color="black"
															icon={link}
															slot="start"
														/>
														<IonText className="text-black text-xl">
															{person.children_relation}
														</IonText>
													</div>
												)}
											</div>

											<div className="ml-4 w-4">
												<IonIcon icon={chevronForward} />
											</div>
										</div>
									</IonCardContent>
								</IonCard>
							)
						})
					)}

					<IonItem className="mt-4" lines="none" style={{ marginBottom: safeArea.bottom + 16 }}>
						<IonButton
							className="w-full h-14 mt-4"
							onClick={() => history.push(`/family/${params.id}/member/`)}
						>
							{t("family_button_action_add_member")} <IonIcon icon={add} slot="end" />
						</IonButton>
					</IonItem>
				</IonList>
			</IonContent>
		</IonPage>
	)
}
