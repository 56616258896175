import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonContent,
	IonDatetime,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonModal,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonSelect,
	IonSelectOption,
	IonSkeletonText,
	IonText,
	IonTitle,
	IonToolbar
} from "@ionic/react"
import { closeOutline } from "ionicons/icons"
import { useAtom } from "jotai"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useHistory, useParams } from "react-router"
import { userCenterIdAssociatedAtomStorage } from "../../atoms/user"
import useT from "../../hooks/useT"
import { Children_Relation, Educational_Level } from "../../services/api-configmobile/generated"
import { eucareApolloClient } from "../../services/api-eucare/client/apollo"
import {
	Commute_Vehicle,
	GetFamilyComponentsInformationDocument,
	GetPersonQueryResult,
	useCreateFamilyMemberMutation,
	useEditFamilyMemberMutation,
	useGetFamilyComponentsInformationQuery,
	useGetPersonInfoPlainMutation,
	useGetPersonLazyQuery,
	useGetVaccineListQuery
} from "../../services/api-eucare/generated"
import { isoStringToServerDateConverter } from "../../utils/dateFormatConverter"

export type IVaccineDose = NonNullable<
	NonNullable<NonNullable<GetPersonQueryResult["data"]>["Person___get"]>["vaccine_dose_"]
>["items"][number]

export type INaturalInfections = NonNullable<
	NonNullable<NonNullable<GetPersonQueryResult["data"]>["Person___get"]>["natural_infection_"]
>["items"][number]

export default function FamilyMember() {
	const history = useHistory()
	const params = useParams<{ id: string; familyMemberId?: string }>()

	const t = useT()

	const [userCenterIdAssociated] = useAtom(userCenterIdAssociatedAtomStorage)

	const [personType, setPersonType] = useState<string | undefined>(params.familyMemberId ? undefined : "7732")
	const [firstName, setFirstName] = useState<string>()
	const [lastName, setLastName] = useState<string>()
	const [gender, setGender] = useState<string>()
	const [yearOfBirth, setYearOfBirth] = useState<string>()
	const [fiscalCode, setFiscalCode] = useState<string>()
	const [childrenRelation, setChildrenRelation] = useState<Children_Relation>()
	const [educationLevel, setEducationLevel] = useState<Educational_Level>()
	const [hasBeenInfected, setHasBeenInfected] = useState<boolean>(false)
	const [vaccinated, setVaccinated] = useState<boolean>(false)
	const [vaccineDoses, setVaccineDoses] = useState<IVaccineDose[]>()
	const [travelMinutes, setTravelMinutes] = useState<number>()
	const [travelKilometres, setTravelKilometres] = useState<number>()
	const [commuteVehicle, setCommuteVehicle] = useState<Commute_Vehicle>()

	const [naturalInfections, setNaturalInfections] = useState<INaturalInfections[]>()
	const [selectedNaturalInfectionDate, setSelectedNaturalInfectionDate] = useState<INaturalInfections | undefined>({
		date: new Date().toISOString(),
		date_res: "YMD"
	})

	const [selectedVaccineDoseDate, setSelectedVaccineDoseDate] = useState<string | undefined>(new Date().toISOString())
	const [selectedVaccineDoseName, setSelectedVaccineDoseName] = useState<string | undefined>()

	const modal = useRef<HTMLIonModalElement>(null)
	const input = useRef<HTMLIonInputElement>(null)

	const infectionModal = useRef<HTMLIonModalElement>(null)
	const infectionInput = useRef<HTMLIonInputElement>(null)

	const [getPerson, { loading: isGettingPersonInfo }] = useGetPersonLazyQuery({ client: eucareApolloClient })

	const [getPersonInfoPlain, { loading: isGettingPersonInfoPlain }] = useGetPersonInfoPlainMutation({
		client: eucareApolloClient
	})

	const [createFamilyMember, { loading: isCreatingUser }] = useCreateFamilyMemberMutation({
		client: eucareApolloClient,
		refetchQueries: [
			{
				query: GetFamilyComponentsInformationDocument,
				variables: {
					input: params.id
				}
			}
		]
	})

	const [editFamilyMember, { loading: isEditingUser }] = useEditFamilyMemberMutation({
		client: eucareApolloClient,
		refetchQueries: [
			{
				query: GetFamilyComponentsInformationDocument,
				variables: {
					input: params.id
				}
			}
		]
	})

	const familyData = useGetFamilyComponentsInformationQuery({
		client: eucareApolloClient,
		variables: { input: params.id }
	})

	const associablesListData = useGetVaccineListQuery({
		client: eucareApolloClient,
		variables: {
			input: {
				center_: userCenterIdAssociated,
				person_type_: "7732",
				family_: params.id,
				year_of_birth: "2000",
				gender: "M",
				name: "TestName",
				surname: "TestSurname",
				fiscal_code: "FiscalCode",
				vaccine_dose_: [
					{
						date: "01/01/2022",
						date_res: "YMD",
						dose_number: 1,
						vaccine_: "13550"
					}
				]
			}
		}
	})

	const childrenRelationTypes = useMemo(
		() =>
			associablesListData.data?.Person___previewCreate?.children_relation____associables
				?.items as Children_Relation[],
		[associablesListData.data]
	)

	const educationLevelTypes = useMemo(
		() =>
			associablesListData.data?.Person___previewCreate?.educational_level____associables
				?.items as Educational_Level[],
		[associablesListData.data]
	)

	const commuteVehicleTypes = useMemo(
		() =>
			associablesListData.data?.Person___previewCreate?.commute_vehicle____associables
				?.items as Commute_Vehicle[],
		[associablesListData.data]
	)

	const vaccineTypes = useMemo(
		() => associablesListData.data?.Person___previewCreate?.vaccine_dose_?.items[0].vaccine____associables?.items,
		[associablesListData.data]
	)

	const selectableYearOfBirth = useMemo(
		() =>
			Array.from({ length: 100 }).map((_, index) => {
				return (new Date().getFullYear() - index).toString()
			}),
		[]
	)

	const handleSubmit = useCallback(() => {
		if (!params.familyMemberId) {
			if (
				firstName &&
				lastName &&
				gender &&
				personType &&
				userCenterIdAssociated &&
				yearOfBirth &&
				fiscalCode &&
				educationLevel
			) {
				createFamilyMember({
					variables: {
						input: {
							family_: params.id,
							name: firstName,
							surname: lastName,
							gender,
							person_type_: personType,
							edit_mode: true,
							year_of_birth: yearOfBirth,
							fiscal_code: fiscalCode,
							children_relation_: childrenRelation?._id,
							educational_level_: educationLevel?._id,
							center_: userCenterIdAssociated,
							vaccinated,
							vaccine_dose_: vaccineDoses?.map((vax) => ({
								date: vax.date,
								vaccine_: vax.vaccine!,
								date_res: "YMD",
								dose_number: vax.dose_number
							})),
							past_infection: hasBeenInfected,
							natural_infection_: naturalInfections?.map((event) => ({
								date: isoStringToServerDateConverter(new Date(event.date.toString()).toString()),
								date_res: "YMD"
							}))
						}
					}
				})
					.then(() => {
						history.push(`/family/${params.id}`)
					})
					.catch((err) => {
						alert("Error: " + (err.message ?? JSON.stringify(err)))
					})
			}
		} else {
			if (firstName && lastName && gender && personType && userCenterIdAssociated && yearOfBirth && fiscalCode) {
				editFamilyMember({
					variables: {
						input: {
							_id: params.familyMemberId,
							family_: { set: params.id },
							name: firstName,
							surname: lastName,
							minutes: personType === "7731" ? travelMinutes : undefined,
							kilometers: personType === "7731" ? travelKilometres : undefined,
							gender,
							person_type_: { set: personType },
							edit_mode: true,
							year_of_birth: yearOfBirth,
							fiscal_code: fiscalCode,
							children_relation_:
								personType !== "7731"
									? {
											set: childrenRelation?._id
									  }
									: undefined,
							educational_level_: {
								set: educationLevel?._id
							},
							commute_vehicle_:
								personType === "7731"
									? {
											set: commuteVehicle?._id
									  }
									: undefined,
							center_: { set: userCenterIdAssociated },
							vaccinated,
							vaccine_dose_: {
								create: vaccineDoses
									?.filter((vax) => !vax._id)
									.map((vax) => ({
										date: vax.date,
										vaccine_: vax.vaccine!,
										date_res: "YMD",
										dose_number: vax.dose_number
									}))
							},
							past_infection: hasBeenInfected,
							natural_infection_: {
								deleteAll: true,
								create: naturalInfections?.map((nI) => ({ ...nI, __typename: undefined }))
							}
						}
					}
				})
					.then(() => {
						history.push(`/family/${params.id}`)
					})
					.catch((err) => {
						alert("Error: " + (err.message ?? JSON.stringify(err)))
					})
			}
		}
	}, [
		childrenRelation?._id,
		commuteVehicle?._id,
		createFamilyMember,
		editFamilyMember,
		educationLevel,
		firstName,
		fiscalCode,
		gender,
		hasBeenInfected,
		history,
		lastName,
		naturalInfections,
		params.familyMemberId,
		params.id,
		personType,
		travelKilometres,
		travelMinutes,
		userCenterIdAssociated,
		vaccinated,
		vaccineDoses,
		yearOfBirth
	])

	useEffect(() => {
		if (params.familyMemberId) {
			const fetchUserData = async () => {
				const [personInfoPlain, personInfo] = await Promise.all([
					getPersonInfoPlain({
						variables: {
							input: params.familyMemberId!
						}
					}),
					getPerson({
						variables: {
							input: params.familyMemberId!
						}
					})
				])

				setFirstName(personInfoPlain.data?.Person___formAction___editData?.data?.name ?? undefined)
				setLastName(personInfoPlain.data?.Person___formAction___editData?.data?.surname ?? undefined)
				setFiscalCode(personInfoPlain.data?.Person___formAction___editData?.data?.fiscal_code ?? undefined)

				setPersonType(personInfo.data?.Person___get?.person_type === "Student" ? "7731" : "7732")

				setGender(personInfo.data?.Person___get?.gender ?? undefined)
				setYearOfBirth(personInfo.data?.Person___get?.year_of_birth ?? undefined)
				setChildrenRelation(personInfo.data?.Person___get?.children_relation_ ?? undefined)
				setEducationLevel(personInfo.data?.Person___get?.educational_level_ ?? undefined)
				setHasBeenInfected(personInfo.data?.Person___get?.past_infection ?? false)
				setTravelMinutes(personInfo.data?.Person___get?.minutes ?? undefined)
				setTravelKilometres(personInfo.data?.Person___get?.kilometers ?? undefined)
				setCommuteVehicle(personInfo.data?.Person___get?.commute_vehicle_ ?? undefined)

				setVaccinated(personInfo.data?.Person___get?.vaccinated ?? false)
				setVaccineDoses(personInfo.data?.Person___get?.vaccine_dose_?.items)
				setNaturalInfections(personInfo.data?.Person___get?.natural_infection_?.items)
			}

			fetchUserData()
		}
	}, [getPerson, getPersonInfoPlain, params.familyMemberId])

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/home" />
					</IonButtons>

					<IonTitle>
						{params.familyMemberId
							? t("family_member_main_title_edit")
							: t("family_member_main_title_create")}
					</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				{isGettingPersonInfo ||
				isGettingPersonInfoPlain ||
				associablesListData.loading ||
				familyData.loading ? (
					<IonList>
						{Array.from({ length: 10 }).map((_, index) => (
							<IonItem key={`family-member-skeleton-${index}`}>
								<IonSkeletonText className="h-4" animated />
							</IonItem>
						))}
					</IonList>
				) : (
					<IonList>
						<IonItem>
							<IonLabel>{t("family_member_attribute_first_name")}</IonLabel>
							<IonInput
								className="text-right"
								type="text"
								placeholder={t("family_member_attribute_first_name_placeholder")}
								value={firstName}
								onIonChange={(e) => {
									if (e.detail.value) {
										setFirstName(e.detail.value?.toString())
									}
								}}
							/>
						</IonItem>

						<IonItem>
							<IonLabel className="ion-text-wrap">{t("family_member_attribute_last_name")}</IonLabel>
							<IonInput
								className="text-right"
								type="text"
								placeholder={t("family_member_attribute_last_name_placeholder")}
								value={lastName}
								onIonChange={(e) => {
									if (e.detail.value) {
										setLastName(e.detail.value?.toString())
									}
								}}
							/>
						</IonItem>

						<IonItem>
							<IonLabel className="ion-text-wrap">{t("family_member_attribute_fiscal_code")}</IonLabel>
							<IonInput
								className="text-right"
								value={fiscalCode}
								type="text"
								placeholder={t("family_member_attribute_fiscal_code_placeholder")}
								onIonChange={(e) => {
									if (e.detail.value) {
										setFiscalCode(e.detail.value?.toString())
									}
								}}
							/>
						</IonItem>

						<IonItem>
							<IonLabel className="ion-text-wrap">{t("family_member_attribute_gender")}</IonLabel>
							<IonSelect
								value={gender}
								placeholder={t("family_member_attribute_gender_placeholder")}
								onIonChange={(e) => {
									if (e.detail.value) {
										setGender(e.detail.value?.toString())
									}
								}}
							>
								<IonSelectOption value="M">{t("family_attribute_gender_male")}</IonSelectOption>
								<IonSelectOption value="F">{t("family_attribute_gender_female")}</IonSelectOption>
							</IonSelect>
						</IonItem>

						<IonItem>
							<IonLabel className="ion-text-wrap">{t("family_member_attribute_year_of_birth")}</IonLabel>
							<IonSelect
								placeholder={yearOfBirth ?? t("family_member_attribute_year_of_birth_placeholder")}
								value={yearOfBirth}
								onIonChange={(e) => {
									if (e.detail.value) {
										setYearOfBirth(e.detail.value?.toString())
									}
								}}
							>
								{selectableYearOfBirth?.map((year) => (
									<IonSelectOption key={`year-option-${year}`} value={year}>
										{year.toString()}
									</IonSelectOption>
								))}
							</IonSelect>
						</IonItem>

						{personType !== "7731" && (
							<IonItem>
								<IonLabel className="ion-text-wrap">
									{t("family_member_attribute_children_relation")}
								</IonLabel>
								<IonSelect
									className="text-right"
									placeholder={t("family_member_attribute_children_relation_placeholder")}
									value={childrenRelation?._id}
									onIonChange={(e) => {
										if (e.detail.value) {
											setChildrenRelation(
												childrenRelationTypes.find(
													(relation) => e.detail.value === relation._id
												)
											)
										}
									}}
								>
									{childrenRelationTypes?.map((relation) => (
										<IonSelectOption key={`relation-option-${relation._id}`} value={relation._id}>
											{relation.children_relation}
										</IonSelectOption>
									))}
								</IonSelect>
							</IonItem>
						)}

						{personType === "7731" && (
							<IonItem>
								<IonLabel className="ion-text-wrap">{t("family_member_attribute_kilometres")}</IonLabel>
								<IonInput
									className="text-right"
									placeholder={t("family_member_attribute_kilometres_placeholder")}
									value={travelKilometres}
									onIonChange={(e) => {
										if (e.detail.value) {
											setTravelKilometres(Number(e.detail.value))
										}
									}}
									min={1}
									max={150}
									type="number"
								/>
							</IonItem>
						)}

						{personType === "7731" && (
							<IonItem>
								<IonLabel className="ion-text-wrap">{t("family_member_attribute_minutes")}</IonLabel>
								<IonInput
									className="text-right"
									placeholder={t("family_member_attribute_minutes_placeholder")}
									value={travelMinutes}
									onIonChange={(e) => {
										if (e.detail.value) {
											setTravelMinutes(Number(e.detail.value))
										}
									}}
									min={1}
									max={150}
									type="number"
								/>
							</IonItem>
						)}

						{personType === "7731" && (
							<IonItem>
								<IonLabel className="ion-text-wrap">
									{t("family_member_attribute_commute_vehicle")}
								</IonLabel>
								<IonSelect
									className="text-right"
									placeholder={t("family_member_attribute_commute_vehicle_placeholder")}
									value={commuteVehicle?._id}
									onIonChange={(e) => {
										if (e.detail.value) {
											setCommuteVehicle(
												commuteVehicleTypes.find((vehicle) => e.detail.value === vehicle._id)
											)
										}
									}}
								>
									{commuteVehicleTypes?.map((vehicle) => (
										<IonSelectOption key={`relation-option-${vehicle._id}`} value={vehicle._id}>
											{vehicle.commute_vehicle}
										</IonSelectOption>
									))}
								</IonSelect>
							</IonItem>
						)}

						<IonItem>
							<IonLabel className="ion-text-wrap">{t("family_member_education_level")}</IonLabel>
							<IonSelect
								className="text-right !break-normal !text-clip !overflow-visible !whitespace-normal"
								placeholder={t("family_member_education_level_placeholder")}
								value={educationLevel?._id}
								onIonChange={(e) => {
									if (e.detail.value) {
										setEducationLevel(
											educationLevelTypes.find((level) => e.detail.value === level._id)
										)
									}
								}}
							>
								{educationLevelTypes?.map((level) => (
									<IonSelectOption key={`education-option-${level._id}`} value={level._id}>
										<p className="!break-normal !text-clip !overflow-visible !whitespace-normal">
											{level.educational_level}
										</p>
									</IonSelectOption>
								))}
							</IonSelect>
						</IonItem>

						<IonItem>
							<IonLabel className="ion-text-wrap">{t("family_member_has_been_ever_infected")}</IonLabel>
							<IonRadioGroup
								className="flex flex-col md:flex-row"
								value={hasBeenInfected}
								onIonChange={(e) => setHasBeenInfected(e.detail.value)}
							>
								<IonItem lines="none">
									<IonLabel className="mr-2">{t("yes")}</IonLabel>
									<IonRadio value={true} />
								</IonItem>

								<IonItem lines="none">
									<IonLabel className="mr-2">{t("no")}</IonLabel>
									<IonRadio value={false} />
								</IonItem>
							</IonRadioGroup>
						</IonItem>

						<IonItem lines="none">
							<IonLabel className="ion-text-wrap">{t("family_member_vaccinated")}</IonLabel>
							<IonRadioGroup
								className="flex flex-col md:flex-row"
								value={vaccinated}
								onIonChange={(e) => setVaccinated(e.detail.value)}
							>
								<IonItem lines="none">
									<IonLabel className="mr-2">{t("yes")}</IonLabel>
									<IonRadio value={true} />
								</IonItem>

								<IonItem lines="none">
									<IonLabel className="mr-2">{t("no")}</IonLabel>
									<IonRadio value={false} />
								</IonItem>
							</IonRadioGroup>
						</IonItem>

						{vaccinated && (
							<IonItem lines="full">
								<IonList className="w-full mb-6">
									<IonLabel className="ion-text-wrap">
										{t("family_member_vaccine_dose_title")}:
									</IonLabel>
									{(vaccineDoses || []).length > 0 ? (
										vaccineDoses?.map((dose, index) => {
											return (
												<div key={`vaccine-dose-${index + 1}`} className="flex flex-col">
													<IonText className="font-bold mt-3">
														{t("family_member_dose")} {`#${dose?.dose_number}`}
													</IonText>
													<IonText>
														{t("family_member_dose")}: {dose.date}
													</IonText>
													<IonText>
														{t("family_member_vaccine")}: {dose.vaccine_?.name}
													</IonText>

													<IonIcon
														className="mr-3 w-5 h-5"
														color="black"
														icon={closeOutline}
														slot="end"
														onClick={() => {
															const newVaccineDoses = [...vaccineDoses]
															newVaccineDoses.splice(index, 1)
															setVaccineDoses(
																newVaccineDoses.map((vax, index) => ({
																	...vax,
																	dose_number: index + 1
																}))
															)
														}}
													></IonIcon>
												</div>
											)
										})
									) : (
										<IonText>{t("family_member_no_vaccine_dose_yet")}</IonText>
									)}

									<IonButton
										className="h-[36px]"
										fill="outline"
										id="open-vaccine-modal"
										expand="block"
									>
										{t("family_member_add_vaccine_dose")}
									</IonButton>
								</IonList>

								<IonModal ref={modal} trigger="open-vaccine-modal">
									<IonHeader>
										<IonToolbar>
											<IonButtons slot="start">
												<IonButton
													onClick={() => {
														setSelectedVaccineDoseDate(undefined)
														setSelectedVaccineDoseName(undefined)
														modal.current?.dismiss()
													}}
												>
													{t("cancel")}
												</IonButton>
											</IonButtons>

											<IonTitle>{t("family_member_add_vaccine_dose")}</IonTitle>

											<IonButtons slot="end">
												<IonButton
													onClick={() => {
														if (selectedVaccineDoseDate && selectedVaccineDoseName) {
															const vaccineDose: IVaccineDose = {
																date: isoStringToServerDateConverter(
																	selectedVaccineDoseDate
																),
																date_res: "YMD",
																dose_number: (vaccineDoses || []).length + 1 || 1,
																vaccine: vaccineTypes?.find((vax) => {
																	return vax.name === selectedVaccineDoseName
																})?._id,
																vaccine_: {
																	name: selectedVaccineDoseName
																}
															}

															setVaccineDoses([...(vaccineDoses || []), vaccineDose])
														}

														modal.current?.dismiss(input.current?.value, "confirm")
													}}
												>
													{t("confirm")}
												</IonButton>
											</IonButtons>
										</IonToolbar>
									</IonHeader>

									<div className="p-4 text-center">
										<IonText className="text-xl my-3 font-semibold">
											{t("family_member_select_vaccine_date")}
										</IonText>
										<IonDatetime
											id="vaccine-date"
											size="cover"
											value={selectedVaccineDoseDate}
											onIonChange={(e) => {
												if (e.detail.value) {
													setSelectedVaccineDoseDate(e.detail.value.toString())
												}
											}}
											presentation="date"
										/>

										<IonText className="text-xl my-3 font-semibold">
											{t("family_member_select_vaccine_type")}
										</IonText>
										<IonSelect
											placeholder={t("family_member_select_vaccine_type_placeholder")}
											value={selectedVaccineDoseName}
											onIonChange={(e) => {
												if (e.detail.value) {
													setSelectedVaccineDoseName(e.detail.value?.toString())
												}
											}}
										>
											{vaccineTypes?.map((type) => (
												<IonSelectOption
													key={`type-vaccine-option-${type._id}`}
													value={type.name}
												>
													{type.name}
												</IonSelectOption>
											))}
										</IonSelect>
									</div>
								</IonModal>
							</IonItem>
						)}

						{hasBeenInfected && (
							<IonItem lines="none" className="mt-12 mb-8">
								<IonList className="w-full">
									<IonLabel className="ion-text-wrap">
										{t("family_member_natural_infection_title")}:
									</IonLabel>
									{(naturalInfections || []).length > 0 ? (
										naturalInfections?.map((event, index) => {
											return (
												<div
													key={`natural-infections-${index + 1}`}
													className="flex flex-row justify-between w-full"
												>
													<IonText>
														{t("family_member_natural_infection_event")}: {event.date}
													</IonText>

													<IonIcon
														className="mr-3 w-5 h-5"
														color="black"
														icon={closeOutline}
														slot="end"
														onClick={() => {
															let newNaturalInfections = [...naturalInfections]
															newNaturalInfections.splice(index, 1)
															setNaturalInfections(newNaturalInfections)
														}}
													></IonIcon>
												</div>
											)
										})
									) : (
										<IonText>{t("family_member_no_natural_infections_yet")}</IonText>
									)}

									<IonButton
										className="h-[36px]"
										fill="outline"
										id="open-natural-infections-modal"
										expand="block"
									>
										{t("family_member_add_natural_infection_event")}
									</IonButton>
								</IonList>

								<IonModal ref={infectionModal} trigger="open-natural-infections-modal">
									<IonHeader>
										<IonToolbar>
											<IonButtons slot="start">
												<IonButton
													onClick={() => {
														setSelectedNaturalInfectionDate(undefined)
														infectionModal.current?.dismiss()
													}}
												>
													{t("cancel")}
												</IonButton>
											</IonButtons>

											<IonTitle>{t("family_member_add_natural_infection_event")}</IonTitle>

											<IonButtons slot="end">
												<IonButton
													onClick={() => {
														if (selectedNaturalInfectionDate && naturalInfections) {
															const naturalInfection: INaturalInfections = {
																date: isoStringToServerDateConverter(
																	new Date(
																		selectedNaturalInfectionDate.date
																	).toISOString()
																),
																date_res: "YMD"
															}

															setNaturalInfections([
																...(naturalInfections || []),
																naturalInfection
															])
														}

														infectionModal.current?.dismiss(
															infectionInput.current?.value,
															"confirm"
														)
													}}
												>
													{t("confirm")}
												</IonButton>
											</IonButtons>
										</IonToolbar>
									</IonHeader>

									<div className="p-4 text-center">
										<IonText className="text-xl my-3 font-semibold">
											{t("family_member_select_natural_infection_date")}
										</IonText>
										<IonDatetime
											id="natural-infection-date"
											size="cover"
											value={selectedNaturalInfectionDate?.date}
											onIonChange={(e) => {
												if (e.detail.value) {
													setSelectedNaturalInfectionDate({
														date: e.detail.value.toString(),
														date_res: "YMD"
													})
												}
											}}
											presentation="date"
										/>
									</div>
								</IonModal>
							</IonItem>
						)}

						<IonItem lines="none" className="mt-4">
							<IonButton className="w-full h-[56px] text-sm" onClick={handleSubmit}>
								{isCreatingUser || isEditingUser ? t("loading") : t("submit")}
							</IonButton>
						</IonItem>
					</IonList>
				)}
			</IonContent>
		</IonPage>
	)
}
