import { IonIcon, IonLabel, IonTabBar, IonTabButton } from "@ionic/react"
import { useSafeArea } from "@reactivers/use-safe-area"
import { chatbox, document, personCircle } from "ionicons/icons"
import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { tabItemAtom } from "../../../atoms/home"
import { usernameAtomStorage } from "../../../atoms/user"
import { useGetUserFilteredQuery } from "../../../services/api-config/generated"
import { eucareApolloClient } from "../../../services/api-eucare/client/apollo"
import useT from "../../../hooks/useT"

export default function BottomNavigationBar() {
	const [tabItem, setTabItem] = useAtom(tabItemAtom)
	const [username] = useAtom(usernameAtomStorage)

	const t = useT()

	const { data: user } = useGetUserFilteredQuery({
		variables: {
			username
		},
		client: eucareApolloClient
	})

	const location = useLocation()
	const safeArea = useSafeArea()

	const [isVisible, setIsVisible] = useState<boolean>(false)

	useEffect(() => {
		setIsVisible(location.pathname === "/home")
	}, [location.pathname])

	return (
		<div
			className="fixed w-screen bottom-0 transition-all"
			style={{
				zIndex: Number.MAX_SAFE_INTEGER,
				bottom: isVisible ? 0 : -56 - safeArea.bottom
			}}
		>
			<IonTabBar>
				<IonTabButton tab="surveys" selected={tabItem === "surveys"} onClick={() => setTabItem("surveys")}>
					<IonIcon icon={chatbox} />
					<IonLabel>{t("tab_surveys_main_title")}</IonLabel>
				</IonTabButton>

				{user?._User___getPage?.items.at?.(0)?.profile === "Family" && (
					<IonTabButton tab="forms" selected={tabItem === "forms"} onClick={() => setTabItem("forms")}>
						<IonIcon icon={document} />
						<IonLabel>{t("tab_forms_main_title")}</IonLabel>
					</IonTabButton>
				)}

				<IonTabButton tab="profile" selected={tabItem === "profile"} onClick={() => setTabItem("profile")}>
					<IonIcon icon={personCircle} />
					<IonLabel>{t("tab_profile_main_title")}</IonLabel>
				</IonTabButton>
			</IonTabBar>
		</div>
	)
}

export function DummyBottomNavigationBar() {
	const safeArea = useSafeArea()

	return <div style={{ height: safeArea.bottom + 56 }} />
}
