import { useAtom } from "jotai"
import { useCallback } from "react"
import { userCredentialsAtomStorage } from "../atoms/user"
import { configApolloClient } from "../services/api-config/client/apollo"
import { configMobileApolloClient } from "../services/api-configmobile/client/apollo"
import { eucareApolloClient } from "../services/api-eucare/client/apollo"

export default function useLogout() {
	const [, setCredentials] = useAtom(userCredentialsAtomStorage)

	const handleLogout = useCallback(() => {
		setCredentials(undefined)

		configApolloClient.resetStore()
		configMobileApolloClient.resetStore()
		eucareApolloClient.resetStore()

		// history from useHistory hook has a bug with animations
		// when using its "replace" method
		window.location.replace("/login")
	}, [setCredentials])

	return handleLogout
}
