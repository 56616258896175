const ItalianTranslations = {
	tab_surveys_main_title: "Questionari",
	tab_surveys_questions_label_questionnaires: "Domande",
	tab_surveys_to_start_label_questionnaires: " – Da iniziare",
	tab_surveys_started_label_questionnaires: " – Iniziato",
	tab_surveys_completed_label_questionnaires: " – Completato",
	tab_surveys_no_questionnaires_availables: "Non ci sono questionari disponibili",
	tab_surveys_family_member: "Membri della famiglia",
	tab_surveys_select_child_label: "Selezionare lo studente per cui completare il questionario",
	tab_surveys_select_child: "Selezionare figlio/a",
	survey_questionnaire_completed_label: "Completato ✅",

	tab_forms_main_title: "Questionario settimanale",
	tab_forms_weekly_questionnaires_card_title: "Questionario settimanale",
	tab_forms_create_weekly_questionnaires_card_subtitle: "Inviare questionario settimanale",
	tab_forms_create_weekly_questionnaires_card_button: "Visualizza",
	tab_forms_create_weekly_questionnaires_form_history_edit_button: "Visualizza",
	tab_forms_empty_state_label: "Invia il primo questionario cliccando il bottone sopra",
	tab_forms_form_made_on_date: "Questionario creato il",
	tab_forms_completion_date: "Questo questionario si riferisce alla settimana del",
	tab_forms_family_member_symptoms:
		"Qualcuno in famiglia ha avuto uno o più dei seguenti sintomi la settimana scorsa?",
	tab_forms_form_number_of_antigen_tests_label: "Numero di test antigenici rapidi fatti durante la settimana",
	tab_forms_form_number_of_molecular_tests_label: "Numero di test molecolari fatti durante la settimana",
	tab_forms_form_any_positive_antigen_tests_label: "C'è stato un test antigenico rapido con risultato positivo?",
	tab_forms_form_confirmed_by_molecular_tests_label: "Ci sono stati test molecolari positivi?",
	tab_forms_form_user_dont_remember_exact_number: "Non ricordo il numero esatto",
	form_switch_label: "Sono stati effettuati test COVID-19 (risultati sia negativi che positivi) ...?",

	tab_profile_main_title: "Profilo",
	tab_profile_family_card_title: "Famiglia",
	tab_profile_family_card_subtitle: "Membri della famiglia",
	tab_profile_family_card_button: "Visualizza",
	tab_profile_user_attribute_label_username: "Nome utente",
	tab_profile_user_attribute_label_profile: "Profilo",
	tab_profile_user_attribute_label_first_name: "Nome",
	tab_profile_user_attribute_label_last_name: "Cognome",
	tab_profile_user_attribute_label_country: "Paese",
	tab_profile_user_attribute_label_language: "Lingua",
	tab_profile_user_attribute_label_description: "Descrizione",
	tab_profile_user_attribute_label_family: "Famiglia",
	tab_profile_user_attribute_label_school_address: "Indirizzo della scuola",
	tab_profile_user_attribute_label_school_name: "Nome della scuola",
	tab_profile_user_attribute_label_fiscal_code: "Codice Fiscale",
	tab_profile_user_attribute_label_gender: "Genere",
	tab_profile_user_attribute_label_year_of_birth: "Anno di Nascita",
	tab_profile_student_edit_card_title: "Modifica",
	tab_profile_student_edit_card_subtitle: "Modifica Profilo",
	tab_profile_student_edit_card_button: "Visualizza",

	student_edit_profile_main_title: "Modifica Profilo",
	student_edit_profile_save_button: "Salva",
	student_edit_profile_first_name_label: "Nome",
	student_edit_profile_last_name_label: "Cognome",
	student_edit_profile_fiscal_code_label: "Codice Fiscale",
	student_edit_profile_gender_label: "Genere",
	student_edit_profile_year_of_birth_label: "Anno di Nascita",
	student_edit_profile_has_been_infected_label: "Hai mai avuto un'infezione da Covid?",

	family_main_title: "Famiglia",
	family_attribute_gender_male: "Maschio",
	family_attribute_gender_female: "Femmina",
	family_button_action_add_member: "Aggiungi un familiare",

	family_member_main_title_edit: "Modifica un familiare",
	family_member_main_title_create: "Aggiungi un familiare",
	family_member_attribute_first_name: "Nome",
	family_member_attribute_first_name_placeholder: "Inserisci nome",
	family_member_attribute_last_name: "Cognome",
	family_member_attribute_last_name_placeholder: "Inserisci cognome",
	family_member_attribute_fiscal_code: "Codice Fiscale",
	family_member_attribute_fiscal_code_placeholder: "Inserisci Codice Fiscale",
	family_member_attribute_gender: "Genere",
	family_member_attribute_gender_placeholder: "Seleziona il genere",
	family_member_attribute_year_of_birth: "Anno di nascita",
	family_member_attribute_year_of_birth_placeholder: "Seleziona anno",
	family_member_attribute_children_relation: "Relazione di parentela",
	family_member_attribute_children_relation_placeholder: "Seleziona Relazione di parentela",
	family_member_attribute_commute_vehicle: "Mezzo utilizzato per il tragitto",
	family_member_attribute_commute_vehicle_placeholder: "Scegliere un mezzo",
	family_member_attribute_minutes: "Durata tragitto giornaliero casa-scuola (min)",
	family_member_attribute_minutes_placeholder: "Scegliere un numero di minuti",
	family_member_attribute_kilometres: "Lunghezza tragitto giornaliero casa-scuola (Km)",
	family_member_attribute_kilometres_placeholder: "Scegliere un numero di chilometri",
	family_member_attribute_center: "Centro",
	family_member_attribute_center_placeholder: "Seleziona il centro",
	family_member_education_level: "Livello di istruzione",
	family_member_education_level_placeholder: "Seleziona livello di istruzione",
	family_member_has_been_ever_infected: "Questo familiare è stato mai infettato?",
	family_member_no_infection: "Nessuna infezione",
	family_member_no_infection_placeholder: "Nessuna infezione",
	family_member_vaccinated: "Vaccinato/a",
	family_member_vaccine_dose_title: "Dose vaccino",
	family_member_dose: "Dose",
	family_member_vaccine: "Vaccino",
	family_member_no_vaccine_dose_yet: "Non ancora vaccinato/a",
	family_member_add_vaccine_dose: "Aggiungi dose vaccino",
	family_member_select_vaccine_date: "Seleziona data di vaccinazione",
	family_member_select_vaccine_type: "Seleziona tipo di vaccino",
	family_member_select_vaccine_type_placeholder: "Seleziona tipo di vaccino",
	family_member_natural_infection_title: "Infezione SARS-Cov-2",
	family_member_natural_infection_event: "Infezione SARS-Cov-2",
	family_member_no_natural_infections_yet: "Nessuna infezione SARS-Cov-2",
	family_member_add_natural_infection_event: "Aggiungi infezione SARS-Cov-2",
	family_member_select_natural_infection_date: "Seleziona data di infezione SARS-Cov-2",

	forgot_password: "Hai dimenticato la password?",
	forgot_password_dialogue: `Per resettare la tua password clicca su "Vai alla pagina" e poi su "Forgot your password" in alto a destra`,
	go_to_page: "Vai alla pagina",
	submit: "Invia",
	loading: "Caricamento...",
	confirm: "Conferma",
	cancel: "Cancella",
	remove: "Rimuovi",
	yes: "Sì",
	no: "No"
}

export default ItalianTranslations
