import { useAtom } from "jotai"
import { useCallback } from "react"
import { userPlatformLanguageAtomStorage } from "../atoms/user"
import { Language, translations, TranslationStrings } from "../i18n"

const useT = () => {
	const [language] = useAtom(userPlatformLanguageAtomStorage)

	const t = useCallback(
		(key: keyof TranslationStrings) => {
			//@ts-ignore
			return translations[language as Language]?.[key] ?? translations["English"]?.[key] ?? key
		},
		[language]
	)

	return t
}

export default useT
