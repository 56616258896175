import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	IonSelect,
	IonSelectOption,
	IonSkeletonText
} from "@ionic/react"
import { chevronForward } from "ionicons/icons"
import { useAtom } from "jotai"
import { useCallback, useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { selectedSchoolPersonAtom } from "../../../../atoms/home"
import { usernameAtomStorage } from "../../../../atoms/user"
import { useAlertMessageResult } from "../../../../hooks/useAlertMessageResult"
import useT from "../../../../hooks/useT"
import { School_People, useGetUserFilteredQuery } from "../../../../services/api-config/generated"
import { eucareApolloClient } from "../../../../services/api-eucare/client/apollo"
import {
	Completion_Result,
	Completion_SelectorDraft,
	FormActionCacheDataMutationVariables,
	Questionnaire_CompletionDraftCreate,
	useGetQuestionnairesCompletionsListMutation,
	useGetQuestionnairesLazyQuery,
	useGetSchoolPeopleAssociablesByFamilyIdLazyQuery,
	useGetSchoolPeopleNameMutation
} from "../../../../services/api-eucare/generated"
import { objectToUrlParams } from "../../../../utils/urlParams"
import { DummyBottomNavigationBar } from "../../BottomNavigationBar/BottomNavigationBar"
import Toolbar from "../../Toolbar/Toolbar"

export type SchoolPerson = School_People & {
	name: string
}

export default function TabSurveys() {
	const [username] = useAtom(usernameAtomStorage)

	const [selectedSchoolPerson, setSelectedSchoolPerson] = useAtom(selectedSchoolPersonAtom)
	const [schoolPersons, setSchoolPersons] = useState<Array<SchoolPerson>>()
	const [questionnaires, setQuestionnaires] = useState<Completion_Result[]>()

	const history = useHistory()
	const location = useLocation()

	const t = useT()

	const [alertMessage] = useAlertMessageResult()

	const isFetchingQuestionnairesRef = useRef<boolean>()

	const { data: user } = useGetUserFilteredQuery({
		variables: {
			username
		},
		client: eucareApolloClient
	})
	const userType = user?._User___getPage?.items?.at?.(0)?.profile
	const userId = user?._User___getPage?.items?.at?.(0)?._id
	const userSchoolId = user?._User___getPage?.items?.at?.(0)?.school_?._id
	const userFamilyId = user?._User___getPage?.items?.at?.(0)?.family_?._id
	const userSchoolPersonId = user?._User___getPage?.items?.at?.(0)?.school_people_id_current

	const [getSchoolPeople] = useGetSchoolPeopleAssociablesByFamilyIdLazyQuery({
		client: eucareApolloClient
	})

	const [getQuestionnaires] = useGetQuestionnairesLazyQuery({
		client: eucareApolloClient
	})

	const [getQuestionnairesCompletionsList] = useGetQuestionnairesCompletionsListMutation({
		client: eucareApolloClient
	})

	const [getSchoolPeopleName] = useGetSchoolPeopleNameMutation({
		variables: {
			schoolPeopleId: selectedSchoolPerson?._id
		}
	})

	const getFormattedDate = useCallback(() => new Date().toLocaleDateString("it-IT"), [])

	const fetchQuestionnaires = useCallback(
		async (skipCache?: boolean) => {
			if (!userType || !userId) {
				return
			}

			if ((userType === "Family" && !selectedSchoolPerson) || (userType !== "Family" && !userSchoolPersonId)) {
				return
			}

			if (isFetchingQuestionnairesRef.current) {
				return
			}

			isFetchingQuestionnairesRef.current = true

			const inputCompletions: Questionnaire_CompletionDraftCreate =
				userType === "Family"
					? {
							school_people_: selectedSchoolPerson?._id,
							completion_date: getFormattedDate(),
							family_: userFamilyId,
							school_: userSchoolId
					  }
					: {
							school_people_: userSchoolPersonId,
							completion_date: getFormattedDate(),
							school_: userSchoolId
					  }

			const { data } = await getQuestionnaires({
				variables: {
					input: inputCompletions
				},
				fetchPolicy: skipCache ? "no-cache" : undefined
			})

			const newQuestionnairesSchemaIdList: string[] =
				data?.Questionnaire_completion___previewCreate?.questionnaire_schema____associables?.items?.map(
					(q) => q._id as string
				) ?? []

			const inputSchema: Completion_SelectorDraft =
				userType === "Family"
					? {
							school_people_: selectedSchoolPerson?._id,
							questionnaire_schema_: {
								add: newQuestionnairesSchemaIdList ?? []
							}
					  }
					: {
							school_people_: userSchoolPersonId,
							questionnaire_schema_: {
								add: newQuestionnairesSchemaIdList ?? []
							}
					  }

			await getQuestionnairesCompletionsList({
				variables: {
					input: inputSchema
				},
				fetchPolicy: skipCache ? "no-cache" : undefined
			}).then((res) => {
				if (
					alertMessage(res.data?.Completion_selector___formAction___getCompletions?.result.message?.message)
				) {
					return
				}

				setQuestionnaires(
					res.data?.Completion_selector___formAction___getCompletions?.data?.completion_result_?.items
				)
			})
		},
		[
			userType,
			userId,
			selectedSchoolPerson,
			userSchoolPersonId,
			getFormattedDate,
			userFamilyId,
			userSchoolId,
			getQuestionnaires,
			getQuestionnairesCompletionsList,
			alertMessage
		]
	)

	const fetchSchoolPeople = useCallback(async () => {
		if (userType !== "Family") {
			return
		}

		if (!userSchoolId) {
			return // aggiungere gestione errore
		}

		const { data } = await getSchoolPeople({
			variables: {
				date: getFormattedDate(),
				familyId: userFamilyId,
				schoolId: userSchoolId
			}
		})
		const partialSchoolPersons = data?.Questionnaire_completion___previewCreate?.school_people____associables?.items
		if (!partialSchoolPersons) {
			return // aggiungere gestione errore
		}

		if (partialSchoolPersons.length === 0) {
			return // aggiungere gestione errore
		}

		const newSchoolPersons: SchoolPerson[] = []
		for (const partialSchoolPerson of partialSchoolPersons) {
			if (!partialSchoolPerson._id) {
				continue
			}

			const { data } = await getSchoolPeopleName({
				variables: {
					schoolPeopleId: partialSchoolPerson._id
				}
			})

			const schoolPeopleName = data?.Questionnaire_completion___formAction___showName?.result.message?.message

			if (schoolPeopleName) {
				newSchoolPersons.push({
					...partialSchoolPerson,
					_id: partialSchoolPerson._id,
					name: schoolPeopleName
				})
			}
		}

		setSchoolPersons(newSchoolPersons)
		setSelectedSchoolPerson(newSchoolPersons[0])
	}, [
		userType,
		userSchoolId,
		getSchoolPeople,
		getFormattedDate,
		userFamilyId,
		setSelectedSchoolPerson,
		getSchoolPeopleName
	])

	useEffect(() => {
		fetchQuestionnaires().finally(() => {
			isFetchingQuestionnairesRef.current = false
		})
	}, [userType, userSchoolId, userFamilyId, selectedSchoolPerson, userSchoolPersonId, fetchQuestionnaires])

	useEffect(() => {
		if (location.pathname === "/home") {
			fetchQuestionnaires(true).finally(() => {
				isFetchingQuestionnairesRef.current = false
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	useEffect(() => {
		if (userType === "Family") {
			fetchSchoolPeople()
		}
	}, [userType, userSchoolId, userFamilyId, fetchSchoolPeople])

	return (
		<IonPage>
			<IonContent>
				<Toolbar title={t("tab_surveys_main_title")} />

				{userType === "Family" && (
					<IonItem lines="none" class="!text-2xl">
						<IonLabel className="ion-text-wrap font-bold" class="!text-2xl">
							{t("tab_surveys_select_child_label")}
						</IonLabel>
					</IonItem>
				)}

				{schoolPersons && selectedSchoolPerson ? (
					<IonItem lines="none">
						<IonSelect
							class="border-2 border-gray-300 border-solid rounded-md w-full pl-0"
							title={t("tab_surveys_family_member")}
							interface="action-sheet"
							placeholder={t("tab_surveys_select_child")}
							value={selectedSchoolPerson._id}
							onIonChange={(event) => {
								setQuestionnaires(undefined)

								const schoolPersonId = event.detail.value
								setSelectedSchoolPerson(
									schoolPersons?.find((p) => p._id?.toString() === schoolPersonId.toString())
								)
							}}
						>
							{schoolPersons.map(
								(person) =>
									person?._id && (
										<IonSelectOption key={`surveys-school-person-${person._id}`} value={person._id}>
											{person.name}
										</IonSelectOption>
									)
							)}
						</IonSelect>
					</IonItem>
				) : userType === "Family" || !questionnaires ? (
					<div className="px-4 my-2">
						<IonSkeletonText className="h-8" animated />
					</div>
				) : null}

				{questionnaires === undefined ? (
					<IonList>
						{Array.from({ length: 30 }).map((_, index) => (
							<IonCard key={`survey-skeleton-${index}`}>
								<IonCardContent className="p-2">
									<IonCardHeader>
										<IonCardSubtitle>
											<IonSkeletonText className="w-[30%] h-3" animated />
										</IonCardSubtitle>
										<IonCardTitle>
											<IonSkeletonText className="w-[100%] h-20 md:h-8" animated />
										</IonCardTitle>
									</IonCardHeader>
								</IonCardContent>
							</IonCard>
						))}
					</IonList>
				) : questionnaires?.length === 0 ? (
					<h1 className="mt-4 text-2xl text-center">{t("tab_surveys_no_questionnaires_availables")}</h1>
				) : (
					<IonList>
						{questionnaires?.map(
							(
								{
									questionnaire_completion_id,
									completed,
									responses,
									questionnaire_schema_,
									questionnaire_schema_id
								},
								index
							) => (
								<IonCard
									key={`questionnaire-${questionnaire_schema_id}`}
									onClick={() => {
										const urlParams: FormActionCacheDataMutationVariables["input"] =
											userType === "Family"
												? {
														family_: userFamilyId,
														school_: userSchoolId,
														school_people_: selectedSchoolPerson?._id,
														_id: questionnaire_completion_id?.toString(),
														completed
												  }
												: {
														school_: userSchoolId,
														school_people_: userSchoolPersonId,
														_id: questionnaire_completion_id?.toString(),
														completed
												  }

										history.push(
											`/questionnaire/${questionnaire_schema_id}?${objectToUrlParams(urlParams)}`
										)
									}}
								>
									<IonCardContent className="cursor-pointer p-2">
										<IonCardHeader>
											<div className="flex flex-row items-center justify-between">
												<div>
													<IonCardSubtitle>
														{`${
															completed
																? t("tab_surveys_completed_label_questionnaires")
																: responses && responses > 0
																? t("tab_surveys_started_label_questionnaires")
																: t("tab_surveys_to_start_label_questionnaires")
														}`}
													</IonCardSubtitle>
													<IonCardTitle>{questionnaire_schema_?.questionnaire_}</IonCardTitle>
												</div>
												<div className="w-4 ml-4">
													<IonIcon icon={chevronForward} />
												</div>
											</div>
										</IonCardHeader>
									</IonCardContent>
								</IonCard>
							)
						)}
					</IonList>
				)}

				<DummyBottomNavigationBar />
			</IonContent>
		</IonPage>
	)
}
