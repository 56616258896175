export const urlParamsToObject = <T>(search: string) => {
	const urlSearchParams = new URLSearchParams(search)

	const obj: any = {}

	urlSearchParams.forEach((value, key) => {
		obj[key] = value
	})

	return obj as T
}

export const objectToUrlParams = (obj: any) => {
	const returnValue: any = {}

	for (const [k, v] of Object.entries(obj ?? {})) {
		if (v) {
			returnValue[k] = v
		}
	}

	return new URLSearchParams(returnValue).toString()
}
