const EnglishTranslations = {
	tab_surveys_main_title: "Surveys",
	tab_surveys_questions_label_questionnaires: "Questions",
	tab_surveys_to_start_label_questionnaires: " – To start",
	tab_surveys_started_label_questionnaires: " – Started",
	tab_surveys_completed_label_questionnaires: " – Completed",
	tab_surveys_no_questionnaires_availables: "No questionnaires available",
	tab_surveys_family_member: "Family Member",
	tab_surveys_select_child_label: "Select the student to complete the questionnaire for",
	tab_surveys_select_child: "Select Child",
	survey_questionnaire_completed_label: "Completed ✅",

	tab_forms_main_title: "Weekly Questionnaire",
	tab_forms_weekly_questionnaires_card_title: "Weekly Questionnaire",
	tab_forms_create_weekly_questionnaires_card_subtitle: "Submit Form",
	tab_forms_create_weekly_questionnaires_card_button: "View",
	tab_forms_create_weekly_questionnaires_form_history_edit_button: "View",
	tab_forms_empty_state_label: "Submit your first form clicking the button above",
	tab_forms_form_made_on_date: "Form made on",
	tab_forms_completion_date: "This form has been made on",
	tab_forms_family_member_symptoms:
		"Has any family member had one or more of the following symptoms during the last week?",
	tab_forms_form_number_of_antigen_tests_label: "Number of Antigen Test during the week",
	tab_forms_form_number_of_molecular_tests_label: "Number of Molecular Test during the week",
	tab_forms_form_any_positive_antigen_tests_label: "There have been any positive Antigen Tests?",
	tab_forms_form_confirmed_by_molecular_tests_label:
		"Among the positive Antingen Tests, there have been some confirmed by Molecular Tests?",
	tab_forms_form_user_dont_remember_exact_number: "I don't remember the exact number",
	form_switch_label: "Have you made COVID-19 tests (both with positive and negative results) ...?",

	tab_profile_main_title: "Profile",
	tab_profile_family_card_title: "Family",
	tab_profile_family_card_subtitle: "Family Member",
	tab_profile_family_card_button: "View",
	tab_profile_user_attribute_label_username: "Username",
	tab_profile_user_attribute_label_profile: "Profile",
	tab_profile_user_attribute_label_first_name: "First Name",
	tab_profile_user_attribute_label_last_name: "Last Name",
	tab_profile_user_attribute_label_country: "Country",
	tab_profile_user_attribute_label_language: "Language",
	tab_profile_user_attribute_label_description: "Description",
	tab_profile_user_attribute_label_family: "Family",
	tab_profile_user_attribute_label_school_address: "School Address",
	tab_profile_user_attribute_label_school_name: "School Name",
	tab_profile_user_attribute_label_fiscal_code: "Fiscal Code",
	tab_profile_user_attribute_label_gender: "Gender",
	tab_profile_user_attribute_label_year_of_birth: "Year Of Birth",
	tab_profile_student_edit_card_title: "Edit",
	tab_profile_student_edit_card_subtitle: "Edit Profile",
	tab_profile_student_edit_card_button: "View",

	student_edit_profile_main_title: "Edit Profile",
	student_edit_profile_save_button: "Save",
	student_edit_profile_first_name_label: "First Name",
	student_edit_profile_last_name_label: "Last Name",
	student_edit_profile_fiscal_code_label: "Fiscal Code",
	student_edit_profile_gender_label: "Gender",
	student_edit_profile_year_of_birth_label: "Year Of Birth",
	student_edit_profile_has_been_infected_label: "Have you ever had a Covid infection?",

	family_main_title: "Family",
	family_attribute_gender_male: "Male",
	family_attribute_gender_female: "Female",
	family_button_action_add_member: "Add Member",

	family_member_main_title_edit: "Edit Family Member",
	family_member_main_title_create: "Add Family Member",
	family_member_attribute_first_name: "First Name",
	family_member_attribute_first_name_placeholder: "Insert First Name",
	family_member_attribute_last_name: "Last Name",
	family_member_attribute_last_name_placeholder: "Insert Last Name",
	family_member_attribute_fiscal_code: "Fiscal Code",
	family_member_attribute_fiscal_code_placeholder: "Insert Fiscal Code",
	family_member_attribute_gender: "Gender",
	family_member_attribute_gender_placeholder: "Select Gender",
	family_member_attribute_year_of_birth: "Year of Birth",
	family_member_attribute_year_of_birth_placeholder: "Select Year",
	family_member_attribute_children_relation: "Children Relation",
	family_member_attribute_children_relation_placeholder: "Select Children Relation",
	family_member_attribute_commute_vehicle: "Means of transportation used for the commute",
	family_member_attribute_commute_vehicle_placeholder: "Select a means of transportation",
	family_member_attribute_minutes: "House-school commute duration (minutes)",
	family_member_attribute_minutes_placeholder: "Select a number of minutes",
	family_member_attribute_kilometres: "House-school commute length (Km)",
	family_member_attribute_kilometres_placeholder: "Select a number of kilometers",
	family_member_attribute_center: "Center",
	family_member_attribute_center_placeholder: "Select Center",
	family_member_education_level: "Education Level",
	family_member_education_level_placeholder: "Select Education Level",
	family_member_has_been_ever_infected: "Has this family member ever been infected?",
	family_member_no_infection: "No infection",
	family_member_no_infection_placeholder: "No infection",
	family_member_vaccinated: "Vaccinated",
	family_member_vaccine_dose_title: "Vaccine Dose",
	family_member_dose: "Dose",
	family_member_vaccine: "Vaccine",
	family_member_no_vaccine_dose_yet: "No Vaccine Dose Yet",
	family_member_add_vaccine_dose: "Add Vaccine Dose",
	family_member_select_vaccine_date: "Select Vaccine Date",
	family_member_select_vaccine_type: "Select Vaccine Type",
	family_member_select_vaccine_type_placeholder: "Select Vaccine Type",
	family_member_natural_infection_title: "Natural Infection",
	family_member_natural_infection_event: "Natural Infection Event",
	family_member_no_natural_infections_yet: "No Natural Infections Yet",
	family_member_add_natural_infection_event: "Add Natural Infection Event",
	family_member_select_natural_infection_date: "Select Natural Infection Date",

	forgot_password: "Forgot your password?",
	forgot_password_dialogue: `To reset your password click on the "Go to page" button and then on "Forgot your password" up in the right corner of the page`,
	go_to_page: "Go to page",
	submit: "Submit",
	loading: "Loading...",
	confirm: "Confirm",
	cancel: "Cancel",
	remove: "Remove",
	yes: "Yes",
	no: "No"
}

export default EnglishTranslations
