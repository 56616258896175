import EnglishTranslations from "./en"
import GermanTranslations from "./de"
import SpanishTranslations from "./es"
import ItalianTranslations from "./it"
import PortugueseTranslations from "./pt"

export type TranslationStrings = {
	[K in keyof typeof EnglishTranslations]: string
}

export type Language = keyof typeof translations

export const translations = {
	English: EnglishTranslations,
	German: GermanTranslations,
	Spanish: SpanishTranslations,
	Italian: ItalianTranslations,
	Portuguese: PortugueseTranslations
}
