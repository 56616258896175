import { useAtom } from "jotai"
import { useEffect, useMemo } from "react"
import { tabItemAtom } from "../../atoms/home"
import useSystemUi from "../../hooks/useSystemUi"
import BottomNavigationBar from "./BottomNavigationBar/BottomNavigationBar"
import TabForms from "./Tabs/TabForms/TabForms"
import TabProfile from "./Tabs/TabProfile/TabProfile"
import TabSurveys from "./Tabs/TabSurveys/TabSurveys"

export type TabId = "surveys" | "forms" | "profile"

export default function Home() {
	const [tabItem] = useAtom(tabItemAtom)

	const { updateStatusBar } = useSystemUi()

	const tabs = useMemo(() => {
		const tabs: Record<TabId, JSX.Element> = {
			surveys: <TabSurveys />,
			forms: <TabForms />,
			profile: <TabProfile />
		}
		return tabs
	}, [])

	const tab = useMemo(() => tabs[tabItem], [tabs, tabItem])

	useEffect(() => {
		updateStatusBar("light")
	}, [updateStatusBar])

	return (
		<>
			{tab}
			<BottomNavigationBar />
		</>
	)
}
