import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react"
import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import { formCompilationDataAtom } from "../../atoms/form"
import { useAlertMessageResult } from "../../hooks/useAlertMessageResult"
import useT from "../../hooks/useT"
import { eucareApolloClient } from "../../services/api-eucare/client/apollo"
import { useGetPersonInfoPlainMutation } from "../../services/api-eucare/generated"

export interface IFormItemProps {
	_id: string | null | undefined
	name: string | null | undefined
	surname: string | null | undefined
	antigen_tests: number | null | undefined
	molecular_tests: number | null | undefined
	any_positive_antigen_test: boolean | null | undefined
	confirmed_by_molecular: boolean | null | undefined
}

export default function FormItem({
	_id,
	name,
	surname,
	antigen_tests,
	molecular_tests,
	any_positive_antigen_test,
	confirmed_by_molecular
}: IFormItemProps) {
	const [formCompilationData, setFormCompilationData] = useAtom(formCompilationDataAtom)

	const [namePlain, setNamePlain] = useState<string>()
	const [surnamePlain, setSurnamePlain] = useState<string>()

	const t = useT()

	const [alertMessage] = useAlertMessageResult()

	const [getPersonInfoPlain] = useGetPersonInfoPlainMutation({ client: eucareApolloClient })

	useEffect(() => {
		if (_id) {
			getPersonInfoPlain({ variables: { input: _id } }).then(({ data: info }) => {
				if (alertMessage(info?.Person___formAction___editData?.result.message?.message)) {
					return
				}

				if (
					info?.Person___formAction___editData?.data?.name &&
					info?.Person___formAction___editData?.data?.surname
				) {
					setNamePlain(info?.Person___formAction___editData?.data?.name)
					setSurnamePlain(info?.Person___formAction___editData?.data?.surname)
				}
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="mb-8">
			<IonItem lines="none">
				<IonLabel className="font-bold">
					{namePlain && surnamePlain ? `${namePlain} ${surnamePlain}` : t("loading")}
				</IonLabel>
			</IonItem>

			<IonItem>
				<IonLabel className="w-full ion-text-wrap">
					{t("tab_forms_form_number_of_antigen_tests_label")}
				</IonLabel>
				<IonSelect
					value={antigen_tests}
					placeholder="Select Number of Tests"
					onIonChange={(e) => {
						let wasInArray = false

						const newData = [...(formCompilationData ?? [])]
						for (const data of newData) {
							if (data && data?._id === _id) {
								data.antigen_tests = Number(e.detail.value)
								wasInArray = true
							}
						}

						if (!wasInArray) {
							newData.push({
								_id,
								name,
								surname,
								molecular_tests,
								any_positive_antigen_test,
								confirmed_by_molecular,
								antigen_tests: Number(e.detail.value)
							})
						}

						setFormCompilationData(newData)
					}}
				>
					<IonSelectOption value={-1}>{t("tab_forms_form_user_dont_remember_exact_number")}</IonSelectOption>

					{Array.from({ length: 11 }).map((_, index) => {
						return (
							<IonSelectOption key={`antigenici-option-${index}`} value={index}>
								{index}
							</IonSelectOption>
						)
					})}
				</IonSelect>
			</IonItem>

			<IonItem>
				<IonLabel className="w-full ion-text-wrap">
					{t("tab_forms_form_number_of_molecular_tests_label")}
				</IonLabel>
				<IonSelect
					value={molecular_tests}
					placeholder="Select Number of Tests"
					onIonChange={(e) => {
						let wasInArray = false

						const newData = [...(formCompilationData ?? [])]
						for (const data of newData) {
							if (data && data?._id === _id) {
								data.molecular_tests = Number(e.detail.value)
								wasInArray = true
							}
						}

						if (!wasInArray) {
							newData.push({
								_id,
								name,
								surname,
								antigen_tests,
								any_positive_antigen_test,
								confirmed_by_molecular,
								molecular_tests: Number(e.detail.value)
							})
						}

						setFormCompilationData(newData)
					}}
				>
					<IonSelectOption value={-1}>{t("tab_forms_form_user_dont_remember_exact_number")}</IonSelectOption>

					{Array.from({ length: 11 }).map((_, index) => {
						return (
							<IonSelectOption key={`molecolari-option-${index}`} value={index}>
								{index}
							</IonSelectOption>
						)
					})}
				</IonSelect>
			</IonItem>

			{antigen_tests && antigen_tests > 0 ? (
				<IonItem>
					<IonLabel className="w-full ion-text-wrap">
						{t("tab_forms_form_any_positive_antigen_tests_label")}
					</IonLabel>
					<IonSelect
						value={any_positive_antigen_test}
						placeholder="Selezionare Sì o No"
						onIonChange={(e) => {
							let wasInArray = false

							const newData = [...(formCompilationData ?? [])]
							for (const data of newData) {
								if (data && data?._id === _id) {
									data.any_positive_antigen_test = e.detail.value
									wasInArray = true
								}
							}

							if (!wasInArray) {
								newData.push({
									_id,
									name,
									surname,
									antigen_tests,
									any_positive_antigen_test: e.detail.value,
									confirmed_by_molecular,
									molecular_tests
								})
							}

							setFormCompilationData(newData)
						}}
					>
						<IonSelectOption value={true}>{t("yes")}</IonSelectOption>
						<IonSelectOption value={false}>{t("no")}</IonSelectOption>
					</IonSelect>
				</IonItem>
			) : null}

			{molecular_tests && molecular_tests !== 0 ? (
				<IonItem>
					<IonLabel className="w-full ion-text-wrap">
						{t("tab_forms_form_confirmed_by_molecular_tests_label")}
					</IonLabel>
					<IonSelect
						value={confirmed_by_molecular}
						placeholder="Selezionare Sì o No"
						onIonChange={(e) => {
							let wasInArray = false

							const newData = [...(formCompilationData ?? [])]
							for (const data of newData) {
								if (data && data?._id === _id) {
									data.confirmed_by_molecular = e.detail.value
									wasInArray = true
								}
							}

							if (!wasInArray) {
								newData.push({
									_id,
									name,
									surname,
									antigen_tests,
									any_positive_antigen_test,
									confirmed_by_molecular: e.detail.value,
									molecular_tests
								})
							}

							setFormCompilationData(newData)
						}}
					>
						<IonSelectOption value={true}>{t("yes")}</IonSelectOption>
						<IonSelectOption value={false}>{t("no")}</IonSelectOption>
					</IonSelect>
				</IonItem>
			) : null}
		</div>
	)
}
