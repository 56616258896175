import { IonCheckbox, IonItem, IonLabel, IonListHeader, IonText, IonTextarea } from "@ionic/react"
import { useRef, useState } from "react"
import { GetQuestionnaireQuestionsListQueryResult } from "../../../services/api-configmobile/generated"

export interface IQuestionProps {
	question: NonNullable<
		NonNullable<
			NonNullable<
				NonNullable<GetQuestionnaireQuestionsListQueryResult["data"]>["Questionnaire_schema___get"]
			>["question_"]
		>["items"][number]
	>
	questionNumber: number
	collectAnswer: (q: string, a: string, type: "String" | "Single choice") => void
	questionPreviousAnswer?: string
}

export default function QuestionComponent({
	question,
	questionNumber,
	collectAnswer,
	questionPreviousAnswer
}: IQuestionProps) {
	const [checkedResponseId, setCheckedResponseId] = useState<string | null | undefined>(
		questionPreviousAnswer || undefined
	)

	const [responseString, setResponseString] = useState<string | null | undefined>(questionPreviousAnswer)
	const responseStringTimeoutRef = useRef<ReturnType<typeof setTimeout>>()

	return (
		<>
			<div className="mb-12 mt-8">
				<div key={`response-radio-group-${question._id}`} className="w-full">
					<IonListHeader className="pr-4" key={`response-header-${questionNumber}`}>
						<div className="flex flex-row" key={`response-radio-header-${questionNumber}`}>
							<IonText
								className="font-medium text-lg"
								key={`response-radio-question-number-${questionNumber}`}
							>{`${questionNumber + 1})`}</IonText>
							<IonText
								className="font-medium text-lg ml-1.5"
								key={`response-radio-question-${questionNumber}`}
							>
								{question.question_fixed}
							</IonText>
						</div>
					</IonListHeader>

					{question.response_type === "Single choice" ? (
						question.possibile_response_?.items?.map((response) => (
							<IonItem key={`response-${response._id}`}>
								<IonLabel key={`response-label-${response._id}`}>{response.response_}</IonLabel>
								<IonCheckbox
									key={`response-value-${response._id}`}
									checked={checkedResponseId === response._id}
									onClick={() => {
										if (response._id && question._id) {
											setCheckedResponseId(response._id)
											collectAnswer(question._id, response._id, "Single choice")
										}
									}}
								/>
							</IonItem>
						))
					) : question.response_type === "String" ? (
						<IonItem lines="none">
							<IonTextarea
								rows={4}
								value={responseString}
								className="border border-gray-300 rounded-lg px-4"
								onIonChange={(e) => {
									const value = e.target.value ?? null
									setResponseString(value)

									if (responseStringTimeoutRef.current) {
										clearTimeout(responseStringTimeoutRef.current)
									}

									responseStringTimeoutRef.current = setTimeout(() => {
										setResponseString((_str) => {
											if (_str === value) {
												if (question._id && _str) {
													collectAnswer(question._id, _str, "String")
												}
											}

											return _str
										})
									}, 1000)
								}}
							/>
						</IonItem>
					) : null}
				</div>
			</div>
		</>
	)
}
