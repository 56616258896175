import { useIonAlert } from "@ionic/react"
import { useCallback } from "react"
import { useHistory } from "react-router"
import { WORD_TO_SHOW_MESSAGE_FROM_RESULT } from "../const/constants"
import { eucareApolloClient } from "../services/api-eucare/client/apollo"

export const useAlertMessageResult = () => {
	const [ionAlert] = useIonAlert()
	const history = useHistory()

	const alert = useCallback(
		(msg?: string, queriesToRefresh?: string[], onOk?: () => void) => {
			if (msg) {
				if (msg.startsWith(WORD_TO_SHOW_MESSAGE_FROM_RESULT)) {
					ionAlert(`${msg.slice(WORD_TO_SHOW_MESSAGE_FROM_RESULT.length, msg.length)}`, [
						{
							text: "OK",
							handler: () => {
								if (queriesToRefresh) {
									eucareApolloClient.refetchQueries({
										include: queriesToRefresh
									})
								}

								if (!onOk) {
									if (history.length > 1) {
										history.goBack()
									} else {
										history.push("/home")
									}
								} else {
									onOk()
								}
							}
						}
					])
					return true
				}
			}
			return false
		},
		[history, ionAlert]
	)

	return [alert]
}
