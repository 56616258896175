const GermanTranslations = {
	tab_surveys_main_title: "Umfragen",
	tab_surveys_questions_label_questionnaires: "Fragen",
	tab_surveys_started_label_questionnaires: " – Gestartet",
	tab_surveys_completed_label_questionnaires: " – Abgeschlossen",
	tab_surveys_no_questionnaires_availables: "Keine Fragebögen verfügbar",
	tab_surveys_family_member: "Familienmitglied",
	tab_surveys_select_child: "Kind auswählen",
	survey_questionnaire_completed_label: "Abgeschlossen ✅",

	tab_forms_main_title: "Wöchentlicher Fragebogen",
	tab_forms_weekly_questionnaires_card_title: "Wöchentlicher Fragebogen",
	tab_forms_create_weekly_questionnaires_card_subtitle: "Formular senden",
	tab_forms_create_weekly_questionnaires_card_button: "Ansehen",
	tab_forms_create_weekly_questionnaires_form_history_edit_button: "Ansehen",
	tab_forms_empty_state_label: "Senden Sie Ihr erstes Formular ab, indem Sie auf die Schaltfläche oben klicken",
	tab_forms_form_made_on_date: "Formular erstellt am",
	tab_forms_completion_date: "Dieses Formular wurde erstellt am",
	tab_forms_form_number_of_antigen_tests_label: "Anzahl der Antigen-Schnelltests pro Woche",
	tab_forms_form_number_of_molecular_tests_label: "Anzahl der PCR-Tests pro Woche",
	tab_forms_form_any_positive_antigen_tests_label: "Gab es positive Antigen-Schnelltest?",
	tab_forms_form_confirmed_by_molecular_tests_label:
		"Wurde ein positiver Antigen-Schnelltests ebenfalls durch einen positiven PCR-Test bestätigt?",
	tab_forms_form_user_dont_remember_exact_number: "Ich kann mich an die genaue Anzahl nicht erinnern",
	form_switch_label: "Wurden Sie schon auf COVID-19 getestet (mit einem negativen und/oder positiven Ergebnis) ...?",

	tab_profile_main_title: "Profil",
	tab_profile_family_card_title: "Familie",
	tab_profile_family_card_subtitle: "Familienmitglied",
	tab_profile_family_card_button: "Ansehen",
	tab_profile_user_attribute_label_username: "Benutzername",
	tab_profile_user_attribute_label_profile: "Profil",
	tab_profile_user_attribute_label_first_name: "Vorname",
	tab_profile_user_attribute_label_last_name: "Nachname",
	tab_profile_user_attribute_label_country: "Land",
	tab_profile_user_attribute_label_language: "Sprache",
	tab_profile_user_attribute_label_description: "Beschreibung",
	tab_profile_user_attribute_label_family: "Familie",
	tab_profile_user_attribute_label_school_address: "Schuladresse",
	tab_profile_user_attribute_label_school_name: "Name der Schule",
	tab_profile_user_attribute_label_fiscal_code: "Sozialversicherungsnummer",
	tab_profile_user_attribute_label_gender: "Geschlecht",
	tab_profile_user_attribute_label_year_of_birth: "Geburtsjahr",
	tab_profile_student_edit_card_title: "Bearbeiten",
	tab_profile_student_edit_card_subtitle: "Profil bearbeiten",
	tab_profile_student_edit_card_button: "Ansehen",

	student_edit_profile_main_title: "Profil bearbeiten",
	student_edit_profile_save_button: "Bestätigen",
	student_edit_profile_first_name_label: "Vorname",
	student_edit_profile_last_name_label: "Nachname",
	student_edit_profile_fiscal_code_label: "Sozialversicherungsnummer",
	student_edit_profile_gender_label: "Geschlecht",
	student_edit_profile_year_of_birth_label: "Geburtsjahr",
	student_edit_profile_has_been_infected_label: "Hatten Sie schon einmal eine Covid-Infektion?",

	family_main_title: "Familie",
	family_attribute_gender_male: "Männlich",
	family_attribute_gender_female: "Weiblich",
	family_button_action_add_member: "Familienmitglied hinzufügen",

	family_member_main_title_edit: "Familienmitglied bearbeiten",
	family_member_main_title_create: "Familienmitglied hinzufügen",
	family_member_attribute_first_name: "Vorname",
	family_member_attribute_first_name_placeholder: "Vorname einfügen",
	family_member_attribute_last_name: "Nachname",
	family_member_attribute_last_name_placeholder: "Nachname einfügen",
	family_member_attribute_fiscal_code: "Sozialversicherungsnummer",
	family_member_attribute_fiscal_code_placeholder: "Sozialversicherungsnummer eingeben",
	family_member_attribute_gender: "Geschlecht",
	family_member_attribute_gender_placeholder: "Geschlecht wählen",
	family_member_attribute_year_of_birth: "Geburtsjahr",
	family_member_attribute_year_of_birth_placeholder: "Jahr auswählen",
	family_member_attribute_children_relation: "Verwandtschaft",
	family_member_attribute_children_relation_placeholder: "Verwandtschaft auswählen",
	family_member_attribute_center: "WP04_Germany",
	family_member_attribute_center_placeholder: "Studienzentrum auswählen",
	family_member_education_level: "Schulbildung",
	family_member_education_level_placeholder: "Schulbildung wählen",
	family_member_has_been_ever_infected: "Hat sich dieses Familienmitglied jemals angesteckt?",
	family_member_no_infection: "Keine Infektion",
	family_member_no_infection_placeholder: "Keine Infektion",
	family_member_vaccinated: "Geimpft",
	family_member_vaccine_dose_title: "Impfungen",
	family_member_dose: "Anzahl der Impfungen",
	family_member_vaccine: "Impfstoff",
	family_member_no_vaccine_dose_yet: "Bisher noch keine Impfung",
	family_member_add_vaccine_dose: "Anzahlung der Impfungen auswählen",
	family_member_select_vaccine_date: "Datum der Impfung",
	family_member_select_vaccine_type: "Art des Impfstoffs",
	family_member_select_vaccine_type_placeholder: "Art des Impfstoffs auswählen",
	family_member_natural_infection_title: "Ansteckung",
	family_member_natural_infection_event: "Ansteckungssereignis",
	family_member_no_natural_infections_yet: "Bisher noch keine Ansteckung",
	family_member_add_natural_infection_event: "Ansteckung ergänzen",
	family_member_select_natural_infection_date: "Datum der Ansteckung",

	submit: "Absenden",
	loading: "Lädt...",
	confirm: "Bestätigen",
	cancel: "Abbrechen",
	remove: "Entfernen",
	yes: "Ja",
	no: "Nein"
}

export default GermanTranslations
