import { atomWithStorage } from "jotai/utils"

export const usernameAtomStorage = atomWithStorage<string | undefined>("username", undefined)

export const userCredentialsAtomStorage = atomWithStorage<string | undefined>("credentials", undefined)

export const userCenterIdAssociatedAtomStorage = atomWithStorage<string | undefined | null>(
	"userCenterIdAssociated",
	undefined
)

export const userPlatformLanguageAtomStorage = atomWithStorage<string>("userLanguage", "English")
