import { useState, useEffect } from "react"

const MOBILE_BREAKPOINT = 768

export default function useDimensions() {
	const [width, setWidth] = useState<number>(window.innerWidth)
	const [height, setHeight] = useState<number>(window.innerHeight)

	useEffect(() => {
		const onResize = () => {
			setWidth(window.innerWidth)
			setHeight(window.innerHeight)
		}

		window.addEventListener("resize", onResize)

		return () => {
			window.removeEventListener("resize", onResize)
		}
	}, [])

	return {
		isMobile: width < MOBILE_BREAKPOINT,
		width,
		height
	}
}
