/**
 * Convert DD/MM/YYYY date to ISO String using UTC timezone
 */
export function serverDateToIsoStringConverter(date: string | undefined): string | undefined {
	if (!date) {
		return undefined
	}

	const [day, month, year] = date.split("/")
	const newDate = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), 0, 0, 0))

	return newDate.toISOString()
}

export function isoStringToServerDateConverter(date: string | undefined): string | undefined {
	if (!date) {
		return undefined
	}

	const [ymd] = date.split("T")
	const [year, month, day] = ymd.split("-")

	return `${day}/${month}/${year}`
}
