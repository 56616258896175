/* eslint-disable */
// DO NOT MANUALLY EDIT - generated with graphql-codegen
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Cursor: any;
  Date: any;
  Datetime: any;
  Real: any;
  Serial: any;
  Text: any;
  Time: any;
  Year: any;
};

/** Represents an object of class Address, as defined in the model. */
export type Address = {
  __typename?: 'Address';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Address. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute city of Address. It is of type String. Max length: 255. Cannot be null. */
  city?: Maybe<Scalars['String']>;
  /** The attribute contact_info of Address. It is of type String. Path: CONCAT_CS - Concat (comma separated)contact_info_ description.  */
  contact_info?: Maybe<Scalars['String']>;
  /** The associated objects for the role contact_info_. */
  contact_info_?: Maybe<Contact_InfoPage>;
  /** The attribute country of Address. It is of type String. Path: country_ name. Cannot be null. */
  country?: Maybe<Scalars['String']>;
  /** The associated object for the role country_. */
  country_?: Maybe<Country>;
  /** The associable objects for the role country_. */
  country____associables?: Maybe<CountryPage>;
  /** The attribute description of Address. It is of type String. Expression: 'concat(street,", ",zip_code," ",city," - ",province)'. Cannot be null. */
  description?: Maybe<Scalars['String']>;
  /** The associated object for the role hospital_. */
  hospital_?: Maybe<Hospital>;
  /** The associated object for the role laboratory_. */
  laboratory_?: Maybe<Laboratory>;
  /** The attribute province of Address. It is of type String. Max length: 255. Cannot be null. */
  province?: Maybe<Scalars['String']>;
  /** The attribute region of Address. It is of type String. Max length: 255. Cannot be null. */
  region?: Maybe<Scalars['String']>;
  /** The attribute street of Address. It is of type String. Max length: 255. Cannot be null. */
  street?: Maybe<Scalars['String']>;
  /** The attribute zip_code of Address. It is of type String. Max length: 255. Cannot be null. */
  zip_code?: Maybe<Scalars['String']>;
};


/** Represents an object of class Address, as defined in the model. */
export type AddressContact_Info_Args = {
  options?: InputMaybe<Contact_InfoPageOptions>;
};


/** Represents an object of class Address, as defined in the model. */
export type AddressCountry____AssociablesArgs = {
  options?: InputMaybe<CountryPageOptions>;
};

/** A data structure for objects of Address. */
export type AddressCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute city of Address. It is of type String!. Max length: 255. Cannot be null. */
  city: Scalars['String'];
  /** The Composition role address_ ◊--> (0,N)contact_info_. */
  contact_info_?: InputMaybe<Array<InputMaybe<Contact_InfoCreate>>>;
  /** The Association role address_(0,N) --> (1)country_. */
  country_: Scalars['ID'];
  /** The attribute province of Address. It is of type String!. Max length: 255. Cannot be null. */
  province: Scalars['String'];
  /** The attribute region of Address. It is of type String!. Max length: 255. Cannot be null. */
  region: Scalars['String'];
  /** The attribute street of Address. It is of type String!. Max length: 255. Cannot be null. */
  street: Scalars['String'];
  /** The attribute zip_code of Address. It is of type String!. Max length: 255. Cannot be null. */
  zip_code: Scalars['String'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Address.
 */
export type AddressCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  city?: InputMaybe<Scalars['String']>;
  contact_info?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft object of class Address. */
export type AddressDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute city of Address. It is of type String. Max length: 255. Cannot be null. */
  city?: InputMaybe<Scalars['String']>;
  /** The Composition role address_ ◊--> (0,N)contact_info_. */
  contact_info_?: InputMaybe<Contact_InfoDraftRoleObjects>;
  /** The Association role address_(0,N) --> (1)country_. */
  country_?: InputMaybe<Scalars['ID']>;
  /** The attribute province of Address. It is of type String. Max length: 255. Cannot be null. */
  province?: InputMaybe<Scalars['String']>;
  /** The attribute region of Address. It is of type String. Max length: 255. Cannot be null. */
  region?: InputMaybe<Scalars['String']>;
  /** The attribute street of Address. It is of type String. Max length: 255. Cannot be null. */
  street?: InputMaybe<Scalars['String']>;
  /** The attribute zip_code of Address. It is of type String. Max length: 255. Cannot be null. */
  zip_code?: InputMaybe<Scalars['String']>;
};

/** A data structure for draft create objects of class Address. */
export type AddressDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute city of Address. It is of type String. Max length: 255. Cannot be null. */
  city?: InputMaybe<Scalars['String']>;
  /** The Composition role address_ ◊--> (0,N)contact_info_. */
  contact_info_?: InputMaybe<Array<InputMaybe<Contact_InfoDraftCreate>>>;
  /** The Association role address_(0,N) --> (1)country_. */
  country_?: InputMaybe<Scalars['ID']>;
  /** The attribute province of Address. It is of type String. Max length: 255. Cannot be null. */
  province?: InputMaybe<Scalars['String']>;
  /** The attribute region of Address. It is of type String. Max length: 255. Cannot be null. */
  region?: InputMaybe<Scalars['String']>;
  /** The attribute street of Address. It is of type String. Max length: 255. Cannot be null. */
  street?: InputMaybe<Scalars['String']>;
  /** The attribute zip_code of Address. It is of type String. Max length: 255. Cannot be null. */
  zip_code?: InputMaybe<Scalars['String']>;
};

/** A data structure for the part object of class Address */
export type AddressDraftRoleObject = {
  /** An existing part object of class Address to be deleted. */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** The part object of class Address to be created or associated. */
  save?: InputMaybe<AddressDraft>;
};

/** A data structure for a draft update object of class Address. */
export type AddressDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute city of Address. It is of type String. Max length: 255. Cannot be null. */
  city?: InputMaybe<Scalars['String']>;
  /** The Composition role address_ ◊--> (0,N)contact_info_. */
  contact_info_?: InputMaybe<Contact_InfoDraftUpdateRoleObjects>;
  /** The Association role address_(0,N) --> (1)country_. */
  country_?: InputMaybe<CountryRoleRef>;
  /** The attribute province of Address. It is of type String. Max length: 255. Cannot be null. */
  province?: InputMaybe<Scalars['String']>;
  /** The attribute region of Address. It is of type String. Max length: 255. Cannot be null. */
  region?: InputMaybe<Scalars['String']>;
  /** The attribute street of Address. It is of type String. Max length: 255. Cannot be null. */
  street?: InputMaybe<Scalars['String']>;
  /** The attribute zip_code of Address. It is of type String. Max length: 255. Cannot be null. */
  zip_code?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft update objects of class Address. */
export type AddressDraftUpdateBulk = {
  /** The attribute city of Address. It is of type String. Max length: 255. Cannot be null. */
  city?: InputMaybe<Scalars['String']>;
  /** The Composition role address_ ◊--> (0,N)contact_info_. */
  contact_info_?: InputMaybe<Contact_InfoDraftUpdateRoleObjects>;
  /** The Association role address_(0,N) --> (1)country_. */
  country_?: InputMaybe<CountryRoleRef>;
  /** The attribute province of Address. It is of type String. Max length: 255. Cannot be null. */
  province?: InputMaybe<Scalars['String']>;
  /** The attribute region of Address. It is of type String. Max length: 255. Cannot be null. */
  region?: InputMaybe<Scalars['String']>;
  /** The attribute street of Address. It is of type String. Max length: 255. Cannot be null. */
  street?: InputMaybe<Scalars['String']>;
  /** The attribute zip_code of Address. It is of type String. Max length: 255. Cannot be null. */
  zip_code?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part object of class Address */
export type AddressDraftUpdateRoleObject = {
  /** A new part object of class Address to be created. */
  create?: InputMaybe<AddressDraftCreate>;
  /** An existing part object of class Address to be deleted. */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** An existing part object of class Address to be updated. */
  update?: InputMaybe<AddressDraftUpdate>;
};

/**
 * Define a specific filter for objects of type Address.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type AddressFilter = {
  AND?: InputMaybe<Array<AddressFilter>>;
  NOT?: InputMaybe<AddressFilter>;
  OR?: InputMaybe<Array<AddressFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  city___contains?: InputMaybe<Scalars['String']>;
  city___ends_with?: InputMaybe<Scalars['String']>;
  city___eq?: InputMaybe<Scalars['String']>;
  city___gt?: InputMaybe<Scalars['String']>;
  city___gte?: InputMaybe<Scalars['String']>;
  city___in?: InputMaybe<Array<Scalars['String']>>;
  city___lt?: InputMaybe<Scalars['String']>;
  city___lte?: InputMaybe<Scalars['String']>;
  city___ne?: InputMaybe<Scalars['String']>;
  city___not___contains?: InputMaybe<Scalars['String']>;
  city___not___ends_with?: InputMaybe<Scalars['String']>;
  city___not___in?: InputMaybe<Array<Scalars['String']>>;
  city___not___null?: InputMaybe<Scalars['Boolean']>;
  city___not___starts_with?: InputMaybe<Scalars['String']>;
  city___null?: InputMaybe<Scalars['Boolean']>;
  city___starts_with?: InputMaybe<Scalars['String']>;
  contact_info___contains?: InputMaybe<Scalars['String']>;
  contact_info___ends_with?: InputMaybe<Scalars['String']>;
  contact_info___eq?: InputMaybe<Scalars['String']>;
  contact_info___gt?: InputMaybe<Scalars['String']>;
  contact_info___gte?: InputMaybe<Scalars['String']>;
  contact_info___in?: InputMaybe<Array<Scalars['String']>>;
  contact_info___lt?: InputMaybe<Scalars['String']>;
  contact_info___lte?: InputMaybe<Scalars['String']>;
  contact_info___ne?: InputMaybe<Scalars['String']>;
  contact_info___not___contains?: InputMaybe<Scalars['String']>;
  contact_info___not___ends_with?: InputMaybe<Scalars['String']>;
  contact_info___not___in?: InputMaybe<Array<Scalars['String']>>;
  contact_info___not___null?: InputMaybe<Scalars['Boolean']>;
  contact_info___not___starts_with?: InputMaybe<Scalars['String']>;
  contact_info___null?: InputMaybe<Scalars['Boolean']>;
  contact_info___starts_with?: InputMaybe<Scalars['String']>;
  country___contains?: InputMaybe<Scalars['String']>;
  country___ends_with?: InputMaybe<Scalars['String']>;
  country___eq?: InputMaybe<Scalars['String']>;
  country___gt?: InputMaybe<Scalars['String']>;
  country___gte?: InputMaybe<Scalars['String']>;
  country___in?: InputMaybe<Array<Scalars['String']>>;
  country___lt?: InputMaybe<Scalars['String']>;
  country___lte?: InputMaybe<Scalars['String']>;
  country___ne?: InputMaybe<Scalars['String']>;
  country___not___contains?: InputMaybe<Scalars['String']>;
  country___not___ends_with?: InputMaybe<Scalars['String']>;
  country___not___in?: InputMaybe<Array<Scalars['String']>>;
  country___not___null?: InputMaybe<Scalars['Boolean']>;
  country___not___starts_with?: InputMaybe<Scalars['String']>;
  country___null?: InputMaybe<Scalars['Boolean']>;
  country___starts_with?: InputMaybe<Scalars['String']>;
  description___contains?: InputMaybe<Scalars['String']>;
  description___ends_with?: InputMaybe<Scalars['String']>;
  description___eq?: InputMaybe<Scalars['String']>;
  description___gt?: InputMaybe<Scalars['String']>;
  description___gte?: InputMaybe<Scalars['String']>;
  description___in?: InputMaybe<Array<Scalars['String']>>;
  description___lt?: InputMaybe<Scalars['String']>;
  description___lte?: InputMaybe<Scalars['String']>;
  description___ne?: InputMaybe<Scalars['String']>;
  description___not___contains?: InputMaybe<Scalars['String']>;
  description___not___ends_with?: InputMaybe<Scalars['String']>;
  description___not___in?: InputMaybe<Array<Scalars['String']>>;
  description___not___null?: InputMaybe<Scalars['Boolean']>;
  description___not___starts_with?: InputMaybe<Scalars['String']>;
  description___null?: InputMaybe<Scalars['Boolean']>;
  description___starts_with?: InputMaybe<Scalars['String']>;
  province___contains?: InputMaybe<Scalars['String']>;
  province___ends_with?: InputMaybe<Scalars['String']>;
  province___eq?: InputMaybe<Scalars['String']>;
  province___gt?: InputMaybe<Scalars['String']>;
  province___gte?: InputMaybe<Scalars['String']>;
  province___in?: InputMaybe<Array<Scalars['String']>>;
  province___lt?: InputMaybe<Scalars['String']>;
  province___lte?: InputMaybe<Scalars['String']>;
  province___ne?: InputMaybe<Scalars['String']>;
  province___not___contains?: InputMaybe<Scalars['String']>;
  province___not___ends_with?: InputMaybe<Scalars['String']>;
  province___not___in?: InputMaybe<Array<Scalars['String']>>;
  province___not___null?: InputMaybe<Scalars['Boolean']>;
  province___not___starts_with?: InputMaybe<Scalars['String']>;
  province___null?: InputMaybe<Scalars['Boolean']>;
  province___starts_with?: InputMaybe<Scalars['String']>;
  region___contains?: InputMaybe<Scalars['String']>;
  region___ends_with?: InputMaybe<Scalars['String']>;
  region___eq?: InputMaybe<Scalars['String']>;
  region___gt?: InputMaybe<Scalars['String']>;
  region___gte?: InputMaybe<Scalars['String']>;
  region___in?: InputMaybe<Array<Scalars['String']>>;
  region___lt?: InputMaybe<Scalars['String']>;
  region___lte?: InputMaybe<Scalars['String']>;
  region___ne?: InputMaybe<Scalars['String']>;
  region___not___contains?: InputMaybe<Scalars['String']>;
  region___not___ends_with?: InputMaybe<Scalars['String']>;
  region___not___in?: InputMaybe<Array<Scalars['String']>>;
  region___not___null?: InputMaybe<Scalars['Boolean']>;
  region___not___starts_with?: InputMaybe<Scalars['String']>;
  region___null?: InputMaybe<Scalars['Boolean']>;
  region___starts_with?: InputMaybe<Scalars['String']>;
  street___contains?: InputMaybe<Scalars['String']>;
  street___ends_with?: InputMaybe<Scalars['String']>;
  street___eq?: InputMaybe<Scalars['String']>;
  street___gt?: InputMaybe<Scalars['String']>;
  street___gte?: InputMaybe<Scalars['String']>;
  street___in?: InputMaybe<Array<Scalars['String']>>;
  street___lt?: InputMaybe<Scalars['String']>;
  street___lte?: InputMaybe<Scalars['String']>;
  street___ne?: InputMaybe<Scalars['String']>;
  street___not___contains?: InputMaybe<Scalars['String']>;
  street___not___ends_with?: InputMaybe<Scalars['String']>;
  street___not___in?: InputMaybe<Array<Scalars['String']>>;
  street___not___null?: InputMaybe<Scalars['Boolean']>;
  street___not___starts_with?: InputMaybe<Scalars['String']>;
  street___null?: InputMaybe<Scalars['Boolean']>;
  street___starts_with?: InputMaybe<Scalars['String']>;
  zip_code___contains?: InputMaybe<Scalars['String']>;
  zip_code___ends_with?: InputMaybe<Scalars['String']>;
  zip_code___eq?: InputMaybe<Scalars['String']>;
  zip_code___gt?: InputMaybe<Scalars['String']>;
  zip_code___gte?: InputMaybe<Scalars['String']>;
  zip_code___in?: InputMaybe<Array<Scalars['String']>>;
  zip_code___lt?: InputMaybe<Scalars['String']>;
  zip_code___lte?: InputMaybe<Scalars['String']>;
  zip_code___ne?: InputMaybe<Scalars['String']>;
  zip_code___not___contains?: InputMaybe<Scalars['String']>;
  zip_code___not___ends_with?: InputMaybe<Scalars['String']>;
  zip_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  zip_code___not___null?: InputMaybe<Scalars['Boolean']>;
  zip_code___not___starts_with?: InputMaybe<Scalars['String']>;
  zip_code___null?: InputMaybe<Scalars['Boolean']>;
  zip_code___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Address. */
export type AddressPage = {
  __typename?: 'AddressPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Address. */
  items: Array<Address>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Address present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a AddressPage. */
export type AddressPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<AddressFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<AddressCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<AddressSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part object of class Address */
export type AddressRoleObject = {
  /** A new part object of class Address to be created. */
  create?: InputMaybe<AddressCreate>;
  /** An existing part object of class Address to be deleted. */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** An existing part object of class Address to be updated. */
  update?: InputMaybe<AddressUpdate>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum AddressSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  city___ASC = 'city___ASC',
  city___DESC = 'city___DESC',
  contact_info___ASC = 'contact_info___ASC',
  contact_info___DESC = 'contact_info___DESC',
  country___ASC = 'country___ASC',
  country___DESC = 'country___DESC',
  description___ASC = 'description___ASC',
  description___DESC = 'description___DESC',
  province___ASC = 'province___ASC',
  province___DESC = 'province___DESC',
  region___ASC = 'region___ASC',
  region___DESC = 'region___DESC',
  street___ASC = 'street___ASC',
  street___DESC = 'street___DESC',
  zip_code___ASC = 'zip_code___ASC',
  zip_code___DESC = 'zip_code___DESC'
}

/** A data structure for a single object of class Address. */
export type AddressUpdate = {
  _id: Scalars['ID'];
  /** The attribute city of Address. It is of type String. Max length: 255. Cannot be null. */
  city?: InputMaybe<Scalars['String']>;
  /** The Composition role address_ ◊--> (0,N)contact_info_. */
  contact_info_?: InputMaybe<Contact_InfoRoleObjects>;
  /** The Association role address_(0,N) --> (1)country_. */
  country_?: InputMaybe<CountryRoleRef>;
  /** The attribute province of Address. It is of type String. Max length: 255. Cannot be null. */
  province?: InputMaybe<Scalars['String']>;
  /** The attribute region of Address. It is of type String. Max length: 255. Cannot be null. */
  region?: InputMaybe<Scalars['String']>;
  /** The attribute street of Address. It is of type String. Max length: 255. Cannot be null. */
  street?: InputMaybe<Scalars['String']>;
  /** The attribute zip_code of Address. It is of type String. Max length: 255. Cannot be null. */
  zip_code?: InputMaybe<Scalars['String']>;
};

/** A data structure for objects of class Address. */
export type AddressUpdateBulk = {
  /** The attribute city of Address. It is of type String. Max length: 255. Cannot be null. */
  city?: InputMaybe<Scalars['String']>;
  /** The Composition role address_ ◊--> (0,N)contact_info_. */
  contact_info_?: InputMaybe<Contact_InfoRoleObjects>;
  /** The Association role address_(0,N) --> (1)country_. */
  country_?: InputMaybe<CountryRoleRef>;
  /** The attribute province of Address. It is of type String. Max length: 255. Cannot be null. */
  province?: InputMaybe<Scalars['String']>;
  /** The attribute region of Address. It is of type String. Max length: 255. Cannot be null. */
  region?: InputMaybe<Scalars['String']>;
  /** The attribute street of Address. It is of type String. Max length: 255. Cannot be null. */
  street?: InputMaybe<Scalars['String']>;
  /** The attribute zip_code of Address. It is of type String. Max length: 255. Cannot be null. */
  zip_code?: InputMaybe<Scalars['String']>;
};

/** Represents an object of class Center, as defined in the model. */
export type Center = {
  __typename?: 'Center';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Center. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute code of Center. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The attribute country of Center. It is of type String. Path: country_ name. Cannot be null. */
  country?: Maybe<Scalars['String']>;
  /** The associated object for the role country_. */
  country_?: Maybe<Country>;
  /** The associable objects for the role country_. */
  country____associables?: Maybe<CountryPage>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
  /** The attribute lang_default of Center. It is of type String. Path: lang_ name. Cannot be null. */
  lang_default?: Maybe<Scalars['String']>;
  /** The attribute name of Center. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The associated objects for the role person_. */
  person_?: Maybe<PersonPage>;
  /** The associable objects for the role person_. */
  person____associables?: Maybe<PersonPage>;
  /** The associated objects for the role school_. */
  school_?: Maybe<SchoolPage>;
  /** The associable objects for the role school_. */
  school____associables?: Maybe<SchoolPage>;
  /** The associated objects for the role study_. */
  study_?: Maybe<StudyPage>;
  /** The associable objects for the role study_. */
  study____associables?: Maybe<StudyPage>;
  /** The attribute test of Center. It is of type Boolean.  */
  test?: Maybe<Scalars['Boolean']>;
};


/** Represents an object of class Center, as defined in the model. */
export type CenterCountry____AssociablesArgs = {
  options?: InputMaybe<CountryPageOptions>;
};


/** Represents an object of class Center, as defined in the model. */
export type CenterLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};


/** Represents an object of class Center, as defined in the model. */
export type CenterPerson_Args = {
  options?: InputMaybe<PersonPageOptions>;
};


/** Represents an object of class Center, as defined in the model. */
export type CenterPerson____AssociablesArgs = {
  options?: InputMaybe<PersonPageOptions>;
};


/** Represents an object of class Center, as defined in the model. */
export type CenterSchool_Args = {
  options?: InputMaybe<SchoolPageOptions>;
};


/** Represents an object of class Center, as defined in the model. */
export type CenterSchool____AssociablesArgs = {
  options?: InputMaybe<SchoolPageOptions>;
};


/** Represents an object of class Center, as defined in the model. */
export type CenterStudy_Args = {
  options?: InputMaybe<StudyPageOptions>;
};


/** Represents an object of class Center, as defined in the model. */
export type CenterStudy____AssociablesArgs = {
  options?: InputMaybe<StudyPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Center.
 */
export type CenterCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  lang_default?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  test?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft associated object of class Center */
export type CenterDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft associated objects of class Center */
export type CenterDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type Center.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type CenterFilter = {
  AND?: InputMaybe<Array<CenterFilter>>;
  NOT?: InputMaybe<CenterFilter>;
  OR?: InputMaybe<Array<CenterFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  country___contains?: InputMaybe<Scalars['String']>;
  country___ends_with?: InputMaybe<Scalars['String']>;
  country___eq?: InputMaybe<Scalars['String']>;
  country___gt?: InputMaybe<Scalars['String']>;
  country___gte?: InputMaybe<Scalars['String']>;
  country___in?: InputMaybe<Array<Scalars['String']>>;
  country___lt?: InputMaybe<Scalars['String']>;
  country___lte?: InputMaybe<Scalars['String']>;
  country___ne?: InputMaybe<Scalars['String']>;
  country___not___contains?: InputMaybe<Scalars['String']>;
  country___not___ends_with?: InputMaybe<Scalars['String']>;
  country___not___in?: InputMaybe<Array<Scalars['String']>>;
  country___not___null?: InputMaybe<Scalars['Boolean']>;
  country___not___starts_with?: InputMaybe<Scalars['String']>;
  country___null?: InputMaybe<Scalars['Boolean']>;
  country___starts_with?: InputMaybe<Scalars['String']>;
  lang_default___contains?: InputMaybe<Scalars['String']>;
  lang_default___ends_with?: InputMaybe<Scalars['String']>;
  lang_default___eq?: InputMaybe<Scalars['String']>;
  lang_default___gt?: InputMaybe<Scalars['String']>;
  lang_default___gte?: InputMaybe<Scalars['String']>;
  lang_default___in?: InputMaybe<Array<Scalars['String']>>;
  lang_default___lt?: InputMaybe<Scalars['String']>;
  lang_default___lte?: InputMaybe<Scalars['String']>;
  lang_default___ne?: InputMaybe<Scalars['String']>;
  lang_default___not___contains?: InputMaybe<Scalars['String']>;
  lang_default___not___ends_with?: InputMaybe<Scalars['String']>;
  lang_default___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang_default___not___null?: InputMaybe<Scalars['Boolean']>;
  lang_default___not___starts_with?: InputMaybe<Scalars['String']>;
  lang_default___null?: InputMaybe<Scalars['Boolean']>;
  lang_default___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  test___eq?: InputMaybe<Scalars['Boolean']>;
  test___ne?: InputMaybe<Scalars['Boolean']>;
  test___not___null?: InputMaybe<Scalars['Boolean']>;
  test___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Center. */
export type CenterPage = {
  __typename?: 'CenterPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Center. */
  items: Array<Center>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Center present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a CenterPage. */
export type CenterPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<CenterFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<CenterCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<CenterSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Center */
export type CenterRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the associated objects of class Center */
export type CenterRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum CenterSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  country___ASC = 'country___ASC',
  country___DESC = 'country___DESC',
  lang_default___ASC = 'lang_default___ASC',
  lang_default___DESC = 'lang_default___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  test___ASC = 'test___ASC',
  test___DESC = 'test___DESC'
}

/** Represents an object of class Children_relation, as defined in the model. */
export type Children_Relation = {
  __typename?: 'Children_relation';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Children_relation. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute children_relation of Children_relation. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated) [if(isNull(__CurrentUser.lang),Children_relation_lang.lang="English", if(containsCS(Children_relation.langs,__CurrentUser.lang), __CurrentUser.lang= Children_relation_lang.lang,Children_relation_lang.lang="English"))]children_relation_lang_ children_relation. Cannot be null. */
  children_relation?: Maybe<Scalars['String']>;
  /** The associated objects for the role children_relation_lang_. */
  children_relation_lang_?: Maybe<Children_Relation_LangPage>;
  /** The attribute langs of Children_relation. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)children_relation_lang_ lang. Cannot be null. */
  langs?: Maybe<Scalars['String']>;
  /** The attribute name of Children_relation. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
};


/** Represents an object of class Children_relation, as defined in the model. */
export type Children_RelationChildren_Relation_Lang_Args = {
  options?: InputMaybe<Children_Relation_LangPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Children_relation.
 */
export type Children_RelationCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  children_relation?: InputMaybe<Scalars['String']>;
  langs?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class Children_relation */
export type Children_RelationDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Children_relation.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Children_RelationFilter = {
  AND?: InputMaybe<Array<Children_RelationFilter>>;
  NOT?: InputMaybe<Children_RelationFilter>;
  OR?: InputMaybe<Array<Children_RelationFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  children_relation___contains?: InputMaybe<Scalars['String']>;
  children_relation___ends_with?: InputMaybe<Scalars['String']>;
  children_relation___eq?: InputMaybe<Scalars['String']>;
  children_relation___gt?: InputMaybe<Scalars['String']>;
  children_relation___gte?: InputMaybe<Scalars['String']>;
  children_relation___in?: InputMaybe<Array<Scalars['String']>>;
  children_relation___lt?: InputMaybe<Scalars['String']>;
  children_relation___lte?: InputMaybe<Scalars['String']>;
  children_relation___ne?: InputMaybe<Scalars['String']>;
  children_relation___not___contains?: InputMaybe<Scalars['String']>;
  children_relation___not___ends_with?: InputMaybe<Scalars['String']>;
  children_relation___not___in?: InputMaybe<Array<Scalars['String']>>;
  children_relation___not___null?: InputMaybe<Scalars['Boolean']>;
  children_relation___not___starts_with?: InputMaybe<Scalars['String']>;
  children_relation___null?: InputMaybe<Scalars['Boolean']>;
  children_relation___starts_with?: InputMaybe<Scalars['String']>;
  langs___contains?: InputMaybe<Scalars['String']>;
  langs___ends_with?: InputMaybe<Scalars['String']>;
  langs___eq?: InputMaybe<Scalars['String']>;
  langs___gt?: InputMaybe<Scalars['String']>;
  langs___gte?: InputMaybe<Scalars['String']>;
  langs___in?: InputMaybe<Array<Scalars['String']>>;
  langs___lt?: InputMaybe<Scalars['String']>;
  langs___lte?: InputMaybe<Scalars['String']>;
  langs___ne?: InputMaybe<Scalars['String']>;
  langs___not___contains?: InputMaybe<Scalars['String']>;
  langs___not___ends_with?: InputMaybe<Scalars['String']>;
  langs___not___in?: InputMaybe<Array<Scalars['String']>>;
  langs___not___null?: InputMaybe<Scalars['Boolean']>;
  langs___not___starts_with?: InputMaybe<Scalars['String']>;
  langs___null?: InputMaybe<Scalars['Boolean']>;
  langs___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Children_relation. */
export type Children_RelationPage = {
  __typename?: 'Children_relationPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Children_relation. */
  items: Array<Children_Relation>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Children_relation present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Children_relationPage. */
export type Children_RelationPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Children_RelationFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Children_RelationCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Children_RelationSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Children_relation */
export type Children_RelationRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Children_RelationSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  children_relation___ASC = 'children_relation___ASC',
  children_relation___DESC = 'children_relation___DESC',
  langs___ASC = 'langs___ASC',
  langs___DESC = 'langs___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC'
}

/** Represents an object of class Children_relation_lang, as defined in the model. */
export type Children_Relation_Lang = {
  __typename?: 'Children_relation_lang';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Children_relation_lang. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute children_relation of Children_relation_lang. It is of type String. Max length: 255. Cannot be null. */
  children_relation?: Maybe<Scalars['String']>;
  /** The associated object for the role children_relation_. */
  children_relation_?: Maybe<Children_Relation>;
  /** The attribute lang of Children_relation_lang. It is of type String. Path: lang_ name. Cannot be null. */
  lang?: Maybe<Scalars['String']>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
};


/** Represents an object of class Children_relation_lang, as defined in the model. */
export type Children_Relation_LangLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};

/** A data structure for objects of Children_relation_lang. */
export type Children_Relation_LangCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute children_relation of Children_relation_lang. It is of type String!. Max length: 255. Cannot be null. */
  children_relation: Scalars['String'];
  /** The Association role children_relation_lang_(0,N) --> (1)lang_. */
  lang_: Scalars['ID'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Children_relation_lang.
 */
export type Children_Relation_LangCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  children_relation?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft object of class Children_relation_lang. */
export type Children_Relation_LangDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute children_relation of Children_relation_lang. It is of type String. Max length: 255. Cannot be null. */
  children_relation?: InputMaybe<Scalars['String']>;
  /** The Association role children_relation_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for draft create objects of class Children_relation_lang. */
export type Children_Relation_LangDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute children_relation of Children_relation_lang. It is of type String. Max length: 255. Cannot be null. */
  children_relation?: InputMaybe<Scalars['String']>;
  /** The Association role children_relation_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft part objects of class Children_relation_lang */
export type Children_Relation_LangDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Children_relation_lang to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Children_Relation_LangDraft>>>;
};

/** A data structure for a draft update object of class Children_relation_lang. */
export type Children_Relation_LangDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute children_relation of Children_relation_lang. It is of type String. Max length: 255. Cannot be null. */
  children_relation?: InputMaybe<Scalars['String']>;
  /** The Association role children_relation_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
};

/** A data structure for a draft update objects of class Children_relation_lang. */
export type Children_Relation_LangDraftUpdateBulk = {
  /** The attribute children_relation of Children_relation_lang. It is of type String. Max length: 255. Cannot be null. */
  children_relation?: InputMaybe<Scalars['String']>;
  /** The Association role children_relation_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
};

/** A data structure for the draft part objects of class Children_relation_lang */
export type Children_Relation_LangDraftUpdateRoleObjects = {
  /** An array of objects of class Children_relation_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Children_Relation_LangDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Children_relation_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Children_Relation_LangDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Children_relation_lang.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Children_Relation_LangFilter = {
  AND?: InputMaybe<Array<Children_Relation_LangFilter>>;
  NOT?: InputMaybe<Children_Relation_LangFilter>;
  OR?: InputMaybe<Array<Children_Relation_LangFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  children_relation___contains?: InputMaybe<Scalars['String']>;
  children_relation___ends_with?: InputMaybe<Scalars['String']>;
  children_relation___eq?: InputMaybe<Scalars['String']>;
  children_relation___gt?: InputMaybe<Scalars['String']>;
  children_relation___gte?: InputMaybe<Scalars['String']>;
  children_relation___in?: InputMaybe<Array<Scalars['String']>>;
  children_relation___lt?: InputMaybe<Scalars['String']>;
  children_relation___lte?: InputMaybe<Scalars['String']>;
  children_relation___ne?: InputMaybe<Scalars['String']>;
  children_relation___not___contains?: InputMaybe<Scalars['String']>;
  children_relation___not___ends_with?: InputMaybe<Scalars['String']>;
  children_relation___not___in?: InputMaybe<Array<Scalars['String']>>;
  children_relation___not___null?: InputMaybe<Scalars['Boolean']>;
  children_relation___not___starts_with?: InputMaybe<Scalars['String']>;
  children_relation___null?: InputMaybe<Scalars['Boolean']>;
  children_relation___starts_with?: InputMaybe<Scalars['String']>;
  lang___contains?: InputMaybe<Scalars['String']>;
  lang___ends_with?: InputMaybe<Scalars['String']>;
  lang___eq?: InputMaybe<Scalars['String']>;
  lang___gt?: InputMaybe<Scalars['String']>;
  lang___gte?: InputMaybe<Scalars['String']>;
  lang___in?: InputMaybe<Array<Scalars['String']>>;
  lang___lt?: InputMaybe<Scalars['String']>;
  lang___lte?: InputMaybe<Scalars['String']>;
  lang___ne?: InputMaybe<Scalars['String']>;
  lang___not___contains?: InputMaybe<Scalars['String']>;
  lang___not___ends_with?: InputMaybe<Scalars['String']>;
  lang___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang___not___null?: InputMaybe<Scalars['Boolean']>;
  lang___not___starts_with?: InputMaybe<Scalars['String']>;
  lang___null?: InputMaybe<Scalars['Boolean']>;
  lang___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Children_relation_lang. */
export type Children_Relation_LangPage = {
  __typename?: 'Children_relation_langPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Children_relation_lang. */
  items: Array<Children_Relation_Lang>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Children_relation_lang present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Children_relation_langPage. */
export type Children_Relation_LangPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Children_Relation_LangFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Children_Relation_LangCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Children_Relation_LangSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Children_relation_lang */
export type Children_Relation_LangRoleObjects = {
  /** An array of objects of class Children_relation_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Children_Relation_LangCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Children_relation_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Children_Relation_LangUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Children_Relation_LangSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  children_relation___ASC = 'children_relation___ASC',
  children_relation___DESC = 'children_relation___DESC',
  lang___ASC = 'lang___ASC',
  lang___DESC = 'lang___DESC'
}

/** A data structure for a single object of class Children_relation_lang. */
export type Children_Relation_LangUpdate = {
  _id: Scalars['ID'];
  /** The attribute children_relation of Children_relation_lang. It is of type String. Max length: 255. Cannot be null. */
  children_relation?: InputMaybe<Scalars['String']>;
  /** The Association role children_relation_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
};

/** A data structure for objects of class Children_relation_lang. */
export type Children_Relation_LangUpdateBulk = {
  /** The attribute children_relation of Children_relation_lang. It is of type String. Max length: 255. Cannot be null. */
  children_relation?: InputMaybe<Scalars['String']>;
  /** The Association role children_relation_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
};

/** Represents an object of class Commute_vehicle, as defined in the model. */
export type Commute_Vehicle = {
  __typename?: 'Commute_vehicle';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Commute_vehicle. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute commute_vehicle of Commute_vehicle. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated) [if(isNull(__CurrentUser.lang),Commute_vehicle_lang.lang="English", if(containsCS(Commute_vehicle.langs,__CurrentUser.lang), __CurrentUser.lang= Commute_vehicle_lang.lang,Commute_vehicle_lang.lang="English"))]commute_vehicle_lang_ commute_vehicle. Cannot be null. */
  commute_vehicle?: Maybe<Scalars['String']>;
  /** The associated objects for the role commute_vehicle_lang_. */
  commute_vehicle_lang_?: Maybe<Commute_Vehicle_LangPage>;
  /** The attribute langs of Commute_vehicle. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)commute_vehicle_lang_ lang. Cannot be null. */
  langs?: Maybe<Scalars['String']>;
  /** The attribute name of Commute_vehicle. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
};


/** Represents an object of class Commute_vehicle, as defined in the model. */
export type Commute_VehicleCommute_Vehicle_Lang_Args = {
  options?: InputMaybe<Commute_Vehicle_LangPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Commute_vehicle.
 */
export type Commute_VehicleCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  commute_vehicle?: InputMaybe<Scalars['String']>;
  langs?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class Commute_vehicle */
export type Commute_VehicleDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Commute_vehicle.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Commute_VehicleFilter = {
  AND?: InputMaybe<Array<Commute_VehicleFilter>>;
  NOT?: InputMaybe<Commute_VehicleFilter>;
  OR?: InputMaybe<Array<Commute_VehicleFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  commute_vehicle___contains?: InputMaybe<Scalars['String']>;
  commute_vehicle___ends_with?: InputMaybe<Scalars['String']>;
  commute_vehicle___eq?: InputMaybe<Scalars['String']>;
  commute_vehicle___gt?: InputMaybe<Scalars['String']>;
  commute_vehicle___gte?: InputMaybe<Scalars['String']>;
  commute_vehicle___in?: InputMaybe<Array<Scalars['String']>>;
  commute_vehicle___lt?: InputMaybe<Scalars['String']>;
  commute_vehicle___lte?: InputMaybe<Scalars['String']>;
  commute_vehicle___ne?: InputMaybe<Scalars['String']>;
  commute_vehicle___not___contains?: InputMaybe<Scalars['String']>;
  commute_vehicle___not___ends_with?: InputMaybe<Scalars['String']>;
  commute_vehicle___not___in?: InputMaybe<Array<Scalars['String']>>;
  commute_vehicle___not___null?: InputMaybe<Scalars['Boolean']>;
  commute_vehicle___not___starts_with?: InputMaybe<Scalars['String']>;
  commute_vehicle___null?: InputMaybe<Scalars['Boolean']>;
  commute_vehicle___starts_with?: InputMaybe<Scalars['String']>;
  langs___contains?: InputMaybe<Scalars['String']>;
  langs___ends_with?: InputMaybe<Scalars['String']>;
  langs___eq?: InputMaybe<Scalars['String']>;
  langs___gt?: InputMaybe<Scalars['String']>;
  langs___gte?: InputMaybe<Scalars['String']>;
  langs___in?: InputMaybe<Array<Scalars['String']>>;
  langs___lt?: InputMaybe<Scalars['String']>;
  langs___lte?: InputMaybe<Scalars['String']>;
  langs___ne?: InputMaybe<Scalars['String']>;
  langs___not___contains?: InputMaybe<Scalars['String']>;
  langs___not___ends_with?: InputMaybe<Scalars['String']>;
  langs___not___in?: InputMaybe<Array<Scalars['String']>>;
  langs___not___null?: InputMaybe<Scalars['Boolean']>;
  langs___not___starts_with?: InputMaybe<Scalars['String']>;
  langs___null?: InputMaybe<Scalars['Boolean']>;
  langs___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Commute_vehicle. */
export type Commute_VehiclePage = {
  __typename?: 'Commute_vehiclePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Commute_vehicle. */
  items: Array<Commute_Vehicle>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Commute_vehicle present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Commute_vehiclePage. */
export type Commute_VehiclePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Commute_VehicleFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Commute_VehicleCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Commute_VehicleSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Commute_vehicle */
export type Commute_VehicleRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Commute_VehicleSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  commute_vehicle___ASC = 'commute_vehicle___ASC',
  commute_vehicle___DESC = 'commute_vehicle___DESC',
  langs___ASC = 'langs___ASC',
  langs___DESC = 'langs___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC'
}

/** Represents an object of class Commute_vehicle_lang, as defined in the model. */
export type Commute_Vehicle_Lang = {
  __typename?: 'Commute_vehicle_lang';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Commute_vehicle_lang. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute commute_vehicle of Commute_vehicle_lang. It is of type String. Max length: 255. Cannot be null. */
  commute_vehicle?: Maybe<Scalars['String']>;
  /** The associated object for the role commute_vehicle_. */
  commute_vehicle_?: Maybe<Commute_Vehicle>;
  /** The attribute lang of Commute_vehicle_lang. It is of type String. Path: lang_ name. Cannot be null. */
  lang?: Maybe<Scalars['String']>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
};


/** Represents an object of class Commute_vehicle_lang, as defined in the model. */
export type Commute_Vehicle_LangLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};

/** A data structure for objects of Commute_vehicle_lang. */
export type Commute_Vehicle_LangCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute commute_vehicle of Commute_vehicle_lang. It is of type String!. Max length: 255. Cannot be null. */
  commute_vehicle: Scalars['String'];
  /** The Association role commute_vehicle_lang_(0,N) --> (1)lang_. */
  lang_: Scalars['ID'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Commute_vehicle_lang.
 */
export type Commute_Vehicle_LangCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  commute_vehicle?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft object of class Commute_vehicle_lang. */
export type Commute_Vehicle_LangDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute commute_vehicle of Commute_vehicle_lang. It is of type String. Max length: 255. Cannot be null. */
  commute_vehicle?: InputMaybe<Scalars['String']>;
  /** The Association role commute_vehicle_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for draft create objects of class Commute_vehicle_lang. */
export type Commute_Vehicle_LangDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute commute_vehicle of Commute_vehicle_lang. It is of type String. Max length: 255. Cannot be null. */
  commute_vehicle?: InputMaybe<Scalars['String']>;
  /** The Association role commute_vehicle_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft part objects of class Commute_vehicle_lang */
export type Commute_Vehicle_LangDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Commute_vehicle_lang to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Commute_Vehicle_LangDraft>>>;
};

/** A data structure for a draft update object of class Commute_vehicle_lang. */
export type Commute_Vehicle_LangDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute commute_vehicle of Commute_vehicle_lang. It is of type String. Max length: 255. Cannot be null. */
  commute_vehicle?: InputMaybe<Scalars['String']>;
  /** The Association role commute_vehicle_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
};

/** A data structure for a draft update objects of class Commute_vehicle_lang. */
export type Commute_Vehicle_LangDraftUpdateBulk = {
  /** The attribute commute_vehicle of Commute_vehicle_lang. It is of type String. Max length: 255. Cannot be null. */
  commute_vehicle?: InputMaybe<Scalars['String']>;
  /** The Association role commute_vehicle_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
};

/** A data structure for the draft part objects of class Commute_vehicle_lang */
export type Commute_Vehicle_LangDraftUpdateRoleObjects = {
  /** An array of objects of class Commute_vehicle_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Commute_Vehicle_LangDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Commute_vehicle_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Commute_Vehicle_LangDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Commute_vehicle_lang.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Commute_Vehicle_LangFilter = {
  AND?: InputMaybe<Array<Commute_Vehicle_LangFilter>>;
  NOT?: InputMaybe<Commute_Vehicle_LangFilter>;
  OR?: InputMaybe<Array<Commute_Vehicle_LangFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  commute_vehicle___contains?: InputMaybe<Scalars['String']>;
  commute_vehicle___ends_with?: InputMaybe<Scalars['String']>;
  commute_vehicle___eq?: InputMaybe<Scalars['String']>;
  commute_vehicle___gt?: InputMaybe<Scalars['String']>;
  commute_vehicle___gte?: InputMaybe<Scalars['String']>;
  commute_vehicle___in?: InputMaybe<Array<Scalars['String']>>;
  commute_vehicle___lt?: InputMaybe<Scalars['String']>;
  commute_vehicle___lte?: InputMaybe<Scalars['String']>;
  commute_vehicle___ne?: InputMaybe<Scalars['String']>;
  commute_vehicle___not___contains?: InputMaybe<Scalars['String']>;
  commute_vehicle___not___ends_with?: InputMaybe<Scalars['String']>;
  commute_vehicle___not___in?: InputMaybe<Array<Scalars['String']>>;
  commute_vehicle___not___null?: InputMaybe<Scalars['Boolean']>;
  commute_vehicle___not___starts_with?: InputMaybe<Scalars['String']>;
  commute_vehicle___null?: InputMaybe<Scalars['Boolean']>;
  commute_vehicle___starts_with?: InputMaybe<Scalars['String']>;
  lang___contains?: InputMaybe<Scalars['String']>;
  lang___ends_with?: InputMaybe<Scalars['String']>;
  lang___eq?: InputMaybe<Scalars['String']>;
  lang___gt?: InputMaybe<Scalars['String']>;
  lang___gte?: InputMaybe<Scalars['String']>;
  lang___in?: InputMaybe<Array<Scalars['String']>>;
  lang___lt?: InputMaybe<Scalars['String']>;
  lang___lte?: InputMaybe<Scalars['String']>;
  lang___ne?: InputMaybe<Scalars['String']>;
  lang___not___contains?: InputMaybe<Scalars['String']>;
  lang___not___ends_with?: InputMaybe<Scalars['String']>;
  lang___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang___not___null?: InputMaybe<Scalars['Boolean']>;
  lang___not___starts_with?: InputMaybe<Scalars['String']>;
  lang___null?: InputMaybe<Scalars['Boolean']>;
  lang___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Commute_vehicle_lang. */
export type Commute_Vehicle_LangPage = {
  __typename?: 'Commute_vehicle_langPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Commute_vehicle_lang. */
  items: Array<Commute_Vehicle_Lang>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Commute_vehicle_lang present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Commute_vehicle_langPage. */
export type Commute_Vehicle_LangPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Commute_Vehicle_LangFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Commute_Vehicle_LangCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Commute_Vehicle_LangSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Commute_vehicle_lang */
export type Commute_Vehicle_LangRoleObjects = {
  /** An array of objects of class Commute_vehicle_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Commute_Vehicle_LangCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Commute_vehicle_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Commute_Vehicle_LangUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Commute_Vehicle_LangSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  commute_vehicle___ASC = 'commute_vehicle___ASC',
  commute_vehicle___DESC = 'commute_vehicle___DESC',
  lang___ASC = 'lang___ASC',
  lang___DESC = 'lang___DESC'
}

/** A data structure for a single object of class Commute_vehicle_lang. */
export type Commute_Vehicle_LangUpdate = {
  _id: Scalars['ID'];
  /** The attribute commute_vehicle of Commute_vehicle_lang. It is of type String. Max length: 255. Cannot be null. */
  commute_vehicle?: InputMaybe<Scalars['String']>;
  /** The Association role commute_vehicle_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
};

/** A data structure for objects of class Commute_vehicle_lang. */
export type Commute_Vehicle_LangUpdateBulk = {
  /** The attribute commute_vehicle of Commute_vehicle_lang. It is of type String. Max length: 255. Cannot be null. */
  commute_vehicle?: InputMaybe<Scalars['String']>;
  /** The Association role commute_vehicle_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
};

/** Represents an object of class Comorbidity, as defined in the model. */
export type Comorbidity = {
  __typename?: 'Comorbidity';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Comorbidity. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute code of Comorbidity. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The attribute name of Comorbidity. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Comorbidity.
 */
export type ComorbidityCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated objects of class Comorbidity */
export type ComorbidityDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type Comorbidity.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type ComorbidityFilter = {
  AND?: InputMaybe<Array<ComorbidityFilter>>;
  NOT?: InputMaybe<ComorbidityFilter>;
  OR?: InputMaybe<Array<ComorbidityFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Comorbidity. */
export type ComorbidityPage = {
  __typename?: 'ComorbidityPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Comorbidity. */
  items: Array<Comorbidity>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Comorbidity present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a ComorbidityPage. */
export type ComorbidityPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<ComorbidityFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<ComorbidityCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<ComorbiditySort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated objects of class Comorbidity */
export type ComorbidityRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum ComorbiditySort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC'
}

/** Represents an object of form class Completion_result, as defined in the model. */
export type Completion_Result = {
  __typename?: 'Completion_result';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Completion_result. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute completed of Completion_result. It is of type Boolean. Path: questionnaire_completion_ completed.  */
  completed?: Maybe<Scalars['Boolean']>;
  /** The associated object for the role completion_selector_. */
  completion_selector_?: Maybe<Completion_Selector>;
  /** The associated object for the role questionnaire_completion_. */
  questionnaire_completion_?: Maybe<Questionnaire_Completion>;
  /** The associable objects for the role questionnaire_completion_. */
  questionnaire_completion____associables?: Maybe<Questionnaire_CompletionPage>;
  /** The attribute questionnaire_completion_id of Completion_result. It is of type Int. Path: questionnaire_completion_ __id. Cannot be null. */
  questionnaire_completion_id?: Maybe<Scalars['Int']>;
  /** The associated object for the role questionnaire_schema_. */
  questionnaire_schema_?: Maybe<Questionnaire_Schema>;
  /** The associable objects for the role questionnaire_schema_. */
  questionnaire_schema____associables?: Maybe<Questionnaire_SchemaPage>;
  /** The attribute questionnaire_schema_id of Completion_result. It is of type Int. Path: questionnaire_schema_ __id. Cannot be null. */
  questionnaire_schema_id?: Maybe<Scalars['Int']>;
  /** The attribute responses of Completion_result. It is of type Int. Path: questionnaire_completion_ responses. Cannot be null. */
  responses?: Maybe<Scalars['Int']>;
};


/** Represents an object of form class Completion_result, as defined in the model. */
export type Completion_ResultQuestionnaire_Completion____AssociablesArgs = {
  options?: InputMaybe<Questionnaire_CompletionPageOptions>;
};


/** Represents an object of form class Completion_result, as defined in the model. */
export type Completion_ResultQuestionnaire_Schema____AssociablesArgs = {
  options?: InputMaybe<Questionnaire_SchemaPageOptions>;
};

/** A data structure for objects of Completion_result. */
export type Completion_ResultCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role __completion_result_(0,N) --> (0,1)questionnaire_completion_. */
  questionnaire_completion_?: InputMaybe<Scalars['ID']>;
  /** The Association role __completion_result_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_: Scalars['ID'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Completion_result.
 */
export type Completion_ResultCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  questionnaire_completion_id?: InputMaybe<Scalars['Int']>;
  questionnaire_schema_id?: InputMaybe<Scalars['Int']>;
  responses?: InputMaybe<Scalars['Int']>;
};

/** A data structure for a draft object of form class Completion_result. */
export type Completion_ResultDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The Association role __completion_result_(0,N) --> (0,1)questionnaire_completion_. */
  questionnaire_completion_?: InputMaybe<Scalars['ID']>;
  /** The Association role __completion_result_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for draft create objects of form class Completion_result. */
export type Completion_ResultDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role __completion_result_(0,N) --> (0,1)questionnaire_completion_. */
  questionnaire_completion_?: InputMaybe<Scalars['ID']>;
  /** The Association role __completion_result_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft part objects of form class Completion_result */
export type Completion_ResultDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of form class Completion_result to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Completion_ResultDraft>>>;
};

/** A data structure for a draft update object of form class Completion_result. */
export type Completion_ResultDraftUpdate = {
  _id: Scalars['ID'];
  /** The Association role __completion_result_(0,N) --> (0,1)questionnaire_completion_. */
  questionnaire_completion_?: InputMaybe<Questionnaire_CompletionRoleRef>;
  /** The Association role __completion_result_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Questionnaire_SchemaRoleRef>;
};

/** A data structure for a draft update objects of form class Completion_result. */
export type Completion_ResultDraftUpdateBulk = {
  /** The Association role __completion_result_(0,N) --> (0,1)questionnaire_completion_. */
  questionnaire_completion_?: InputMaybe<Questionnaire_CompletionRoleRef>;
  /** The Association role __completion_result_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Questionnaire_SchemaRoleRef>;
};

/** A data structure for the draft part objects of form class Completion_result */
export type Completion_ResultDraftUpdateRoleObjects = {
  /** An array of objects of form class Completion_result to be created. */
  create?: InputMaybe<Array<InputMaybe<Completion_ResultDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of form class Completion_result to be updated. */
  update?: InputMaybe<Array<InputMaybe<Completion_ResultDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Completion_result.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Completion_ResultFilter = {
  AND?: InputMaybe<Array<Completion_ResultFilter>>;
  NOT?: InputMaybe<Completion_ResultFilter>;
  OR?: InputMaybe<Array<Completion_ResultFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  completed___eq?: InputMaybe<Scalars['Boolean']>;
  completed___ne?: InputMaybe<Scalars['Boolean']>;
  completed___not___null?: InputMaybe<Scalars['Boolean']>;
  completed___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_completion_id___eq?: InputMaybe<Scalars['Int']>;
  questionnaire_completion_id___gt?: InputMaybe<Scalars['Int']>;
  questionnaire_completion_id___gte?: InputMaybe<Scalars['Int']>;
  questionnaire_completion_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  questionnaire_completion_id___lt?: InputMaybe<Scalars['Int']>;
  questionnaire_completion_id___lte?: InputMaybe<Scalars['Int']>;
  questionnaire_completion_id___ne?: InputMaybe<Scalars['Int']>;
  questionnaire_completion_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  questionnaire_completion_id___not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_completion_id___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_schema_id___eq?: InputMaybe<Scalars['Int']>;
  questionnaire_schema_id___gt?: InputMaybe<Scalars['Int']>;
  questionnaire_schema_id___gte?: InputMaybe<Scalars['Int']>;
  questionnaire_schema_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  questionnaire_schema_id___lt?: InputMaybe<Scalars['Int']>;
  questionnaire_schema_id___lte?: InputMaybe<Scalars['Int']>;
  questionnaire_schema_id___ne?: InputMaybe<Scalars['Int']>;
  questionnaire_schema_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  questionnaire_schema_id___not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_schema_id___null?: InputMaybe<Scalars['Boolean']>;
  responses___eq?: InputMaybe<Scalars['Int']>;
  responses___gt?: InputMaybe<Scalars['Int']>;
  responses___gte?: InputMaybe<Scalars['Int']>;
  responses___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  responses___lt?: InputMaybe<Scalars['Int']>;
  responses___lte?: InputMaybe<Scalars['Int']>;
  responses___ne?: InputMaybe<Scalars['Int']>;
  responses___not___in?: InputMaybe<Array<Scalars['Int']>>;
  responses___not___null?: InputMaybe<Scalars['Boolean']>;
  responses___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of form class Completion_result. */
export type Completion_ResultPage = {
  __typename?: 'Completion_resultPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of form class Completion_result. */
  items: Array<Completion_Result>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of form class Completion_result present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Completion_resultPage. */
export type Completion_ResultPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Completion_ResultFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Completion_ResultCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Completion_ResultSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of form class Completion_result */
export type Completion_ResultRoleObjects = {
  /** An array of objects of form class Completion_result to be created. */
  create?: InputMaybe<Array<InputMaybe<Completion_ResultCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of form class Completion_result to be updated. */
  update?: InputMaybe<Array<InputMaybe<Completion_ResultUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Completion_ResultSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  completed___ASC = 'completed___ASC',
  completed___DESC = 'completed___DESC',
  questionnaire_completion_id___ASC = 'questionnaire_completion_id___ASC',
  questionnaire_completion_id___DESC = 'questionnaire_completion_id___DESC',
  questionnaire_schema_id___ASC = 'questionnaire_schema_id___ASC',
  questionnaire_schema_id___DESC = 'questionnaire_schema_id___DESC',
  responses___ASC = 'responses___ASC',
  responses___DESC = 'responses___DESC'
}

/** A data structure for a single object of form class Completion_result. */
export type Completion_ResultUpdate = {
  _id: Scalars['ID'];
  /** The Association role __completion_result_(0,N) --> (0,1)questionnaire_completion_. */
  questionnaire_completion_?: InputMaybe<Questionnaire_CompletionRoleRef>;
  /** The Association role __completion_result_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Questionnaire_SchemaRoleRef>;
};

/** A data structure for objects of form class Completion_result. */
export type Completion_ResultUpdateBulk = {
  /** The Association role __completion_result_(0,N) --> (0,1)questionnaire_completion_. */
  questionnaire_completion_?: InputMaybe<Questionnaire_CompletionRoleRef>;
  /** The Association role __completion_result_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Questionnaire_SchemaRoleRef>;
};

/** Represents an object of form class Completion_selector, as defined in the model. */
export type Completion_Selector = {
  __typename?: 'Completion_selector';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Completion_selector. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The associated objects for the role completion_result_. */
  completion_result_?: Maybe<Completion_ResultPage>;
  /** The associated objects for the role questionnaire_schema_. */
  questionnaire_schema_?: Maybe<Questionnaire_SchemaPage>;
  /** The associable objects for the role questionnaire_schema_. */
  questionnaire_schema____associables?: Maybe<Questionnaire_SchemaPage>;
  /** The associated object for the role school_people_. */
  school_people_?: Maybe<School_People>;
  /** The associable objects for the role school_people_. */
  school_people____associables?: Maybe<School_PeoplePage>;
};


/** Represents an object of form class Completion_selector, as defined in the model. */
export type Completion_SelectorCompletion_Result_Args = {
  options?: InputMaybe<Completion_ResultPageOptions>;
};


/** Represents an object of form class Completion_selector, as defined in the model. */
export type Completion_SelectorQuestionnaire_Schema_Args = {
  options?: InputMaybe<Questionnaire_SchemaPageOptions>;
};


/** Represents an object of form class Completion_selector, as defined in the model. */
export type Completion_SelectorQuestionnaire_Schema____AssociablesArgs = {
  options?: InputMaybe<Questionnaire_SchemaPageOptions>;
};


/** Represents an object of form class Completion_selector, as defined in the model. */
export type Completion_SelectorSchool_People____AssociablesArgs = {
  options?: InputMaybe<School_PeoplePageOptions>;
};

/** A data structure for a draft object of form class Completion_selector. */
export type Completion_SelectorDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The Composition role completion_selector_ ◊--> (0,N)completion_result_. */
  completion_result_?: InputMaybe<Completion_ResultDraftRoleObjects>;
  /** The Association role __completion_selector_(0,N) --> (1,N)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Questionnaire_SchemaDraftRoleRefs>;
  /** The Association role __completion_selector_(0,N) --> (1)school_people_. */
  school_people_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for draft create objects of form class Completion_selector. */
export type Completion_SelectorDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Composition role completion_selector_ ◊--> (0,N)completion_result_. */
  completion_result_?: InputMaybe<Array<InputMaybe<Completion_ResultDraftCreate>>>;
  /** The Association role __completion_selector_(0,N) --> (1,N)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role __completion_selector_(0,N) --> (1)school_people_. */
  school_people_?: InputMaybe<Scalars['ID']>;
};

/** The result of the form action invocation on class Completion_selector. */
export type Completion_SelectorFormActionHandlerResult = {
  __typename?: 'Completion_selectorFormActionHandlerResult';
  data?: Maybe<Completion_Selector>;
  result: FormActionHandlerResult;
};

/** Represents an object of class Contact_info, as defined in the model. */
export type Contact_Info = {
  __typename?: 'Contact_info';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Contact_info. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The associated object for the role address_. */
  address_?: Maybe<Address>;
  /**
   * The attribute description of Contact_info. It is of type String. Expression: 'concat(name,": ",
   * if(isNull(email_1),"",concat(email_1,",")),
   * if(isNull(email_2),"",concat(email_2,",")),
   * if(isNull(email_3),"",concat(email_3,",")),
   * if(isNull(phone_1),"",concat(phone_1,",")),
   * if(isNull(phone_2),"",concat(phone_2,",")),
   * if(isNull(phone_3),"",concat(phone_3,",")),
   * if(isNull(other),"",concat(other,","))
   * 	
   * )'.
   */
  description?: Maybe<Scalars['String']>;
  /** The attribute description_ of Contact_info. It is of type String. Max length: 255.  */
  description_?: Maybe<Scalars['String']>;
  /** The attribute email_1 of Contact_info. It is of type String. Max length: 255.  */
  email_1?: Maybe<Scalars['String']>;
  /** The attribute email_2 of Contact_info. It is of type String. Max length: 255.  */
  email_2?: Maybe<Scalars['String']>;
  /** The attribute email_3 of Contact_info. It is of type String. Max length: 255.  */
  email_3?: Maybe<Scalars['String']>;
  /** The attribute name of Contact_info. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The attribute other of Contact_info. It is of type String. Max length: 255.  */
  other?: Maybe<Scalars['String']>;
  /** The attribute phone_1 of Contact_info. It is of type String. Max length: 255.  */
  phone_1?: Maybe<Scalars['String']>;
  /** The attribute phone_2 of Contact_info. It is of type String. Max length: 255.  */
  phone_2?: Maybe<Scalars['String']>;
  /** The attribute phone_3 of Contact_info. It is of type String. Max length: 255.  */
  phone_3?: Maybe<Scalars['String']>;
};

/** A data structure for objects of Contact_info. */
export type Contact_InfoCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute description_ of Contact_info. It is of type String. Max length: 255.  */
  description_?: InputMaybe<Scalars['String']>;
  /** The attribute email_1 of Contact_info. It is of type String. Max length: 255.  */
  email_1?: InputMaybe<Scalars['String']>;
  /** The attribute email_2 of Contact_info. It is of type String. Max length: 255.  */
  email_2?: InputMaybe<Scalars['String']>;
  /** The attribute email_3 of Contact_info. It is of type String. Max length: 255.  */
  email_3?: InputMaybe<Scalars['String']>;
  /** The attribute name of Contact_info. It is of type String!. Max length: 255. Cannot be null. */
  name: Scalars['String'];
  /** The attribute other of Contact_info. It is of type String. Max length: 255.  */
  other?: InputMaybe<Scalars['String']>;
  /** The attribute phone_1 of Contact_info. It is of type String. Max length: 255.  */
  phone_1?: InputMaybe<Scalars['String']>;
  /** The attribute phone_2 of Contact_info. It is of type String. Max length: 255.  */
  phone_2?: InputMaybe<Scalars['String']>;
  /** The attribute phone_3 of Contact_info. It is of type String. Max length: 255.  */
  phone_3?: InputMaybe<Scalars['String']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Contact_info.
 */
export type Contact_InfoCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  description_?: InputMaybe<Scalars['String']>;
  email_1?: InputMaybe<Scalars['String']>;
  email_2?: InputMaybe<Scalars['String']>;
  email_3?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  phone_1?: InputMaybe<Scalars['String']>;
  phone_2?: InputMaybe<Scalars['String']>;
  phone_3?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft object of class Contact_info. */
export type Contact_InfoDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute description_ of Contact_info. It is of type String. Max length: 255.  */
  description_?: InputMaybe<Scalars['String']>;
  /** The attribute email_1 of Contact_info. It is of type String. Max length: 255.  */
  email_1?: InputMaybe<Scalars['String']>;
  /** The attribute email_2 of Contact_info. It is of type String. Max length: 255.  */
  email_2?: InputMaybe<Scalars['String']>;
  /** The attribute email_3 of Contact_info. It is of type String. Max length: 255.  */
  email_3?: InputMaybe<Scalars['String']>;
  /** The attribute name of Contact_info. It is of type String. Max length: 255. Cannot be null. */
  name?: InputMaybe<Scalars['String']>;
  /** The attribute other of Contact_info. It is of type String. Max length: 255.  */
  other?: InputMaybe<Scalars['String']>;
  /** The attribute phone_1 of Contact_info. It is of type String. Max length: 255.  */
  phone_1?: InputMaybe<Scalars['String']>;
  /** The attribute phone_2 of Contact_info. It is of type String. Max length: 255.  */
  phone_2?: InputMaybe<Scalars['String']>;
  /** The attribute phone_3 of Contact_info. It is of type String. Max length: 255.  */
  phone_3?: InputMaybe<Scalars['String']>;
};

/** A data structure for draft create objects of class Contact_info. */
export type Contact_InfoDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute description_ of Contact_info. It is of type String. Max length: 255.  */
  description_?: InputMaybe<Scalars['String']>;
  /** The attribute email_1 of Contact_info. It is of type String. Max length: 255.  */
  email_1?: InputMaybe<Scalars['String']>;
  /** The attribute email_2 of Contact_info. It is of type String. Max length: 255.  */
  email_2?: InputMaybe<Scalars['String']>;
  /** The attribute email_3 of Contact_info. It is of type String. Max length: 255.  */
  email_3?: InputMaybe<Scalars['String']>;
  /** The attribute name of Contact_info. It is of type String. Max length: 255. Cannot be null. */
  name?: InputMaybe<Scalars['String']>;
  /** The attribute other of Contact_info. It is of type String. Max length: 255.  */
  other?: InputMaybe<Scalars['String']>;
  /** The attribute phone_1 of Contact_info. It is of type String. Max length: 255.  */
  phone_1?: InputMaybe<Scalars['String']>;
  /** The attribute phone_2 of Contact_info. It is of type String. Max length: 255.  */
  phone_2?: InputMaybe<Scalars['String']>;
  /** The attribute phone_3 of Contact_info. It is of type String. Max length: 255.  */
  phone_3?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part objects of class Contact_info */
export type Contact_InfoDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Contact_info to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Contact_InfoDraft>>>;
};

/** A data structure for a draft update object of class Contact_info. */
export type Contact_InfoDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute description_ of Contact_info. It is of type String. Max length: 255.  */
  description_?: InputMaybe<Scalars['String']>;
  /** The attribute email_1 of Contact_info. It is of type String. Max length: 255.  */
  email_1?: InputMaybe<Scalars['String']>;
  /** The attribute email_2 of Contact_info. It is of type String. Max length: 255.  */
  email_2?: InputMaybe<Scalars['String']>;
  /** The attribute email_3 of Contact_info. It is of type String. Max length: 255.  */
  email_3?: InputMaybe<Scalars['String']>;
  /** The attribute name of Contact_info. It is of type String. Max length: 255. Cannot be null. */
  name?: InputMaybe<Scalars['String']>;
  /** The attribute other of Contact_info. It is of type String. Max length: 255.  */
  other?: InputMaybe<Scalars['String']>;
  /** The attribute phone_1 of Contact_info. It is of type String. Max length: 255.  */
  phone_1?: InputMaybe<Scalars['String']>;
  /** The attribute phone_2 of Contact_info. It is of type String. Max length: 255.  */
  phone_2?: InputMaybe<Scalars['String']>;
  /** The attribute phone_3 of Contact_info. It is of type String. Max length: 255.  */
  phone_3?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft update objects of class Contact_info. */
export type Contact_InfoDraftUpdateBulk = {
  /** The attribute description_ of Contact_info. It is of type String. Max length: 255.  */
  description_?: InputMaybe<Scalars['String']>;
  /** The attribute email_1 of Contact_info. It is of type String. Max length: 255.  */
  email_1?: InputMaybe<Scalars['String']>;
  /** The attribute email_2 of Contact_info. It is of type String. Max length: 255.  */
  email_2?: InputMaybe<Scalars['String']>;
  /** The attribute email_3 of Contact_info. It is of type String. Max length: 255.  */
  email_3?: InputMaybe<Scalars['String']>;
  /** The attribute name of Contact_info. It is of type String. Max length: 255. Cannot be null. */
  name?: InputMaybe<Scalars['String']>;
  /** The attribute other of Contact_info. It is of type String. Max length: 255.  */
  other?: InputMaybe<Scalars['String']>;
  /** The attribute phone_1 of Contact_info. It is of type String. Max length: 255.  */
  phone_1?: InputMaybe<Scalars['String']>;
  /** The attribute phone_2 of Contact_info. It is of type String. Max length: 255.  */
  phone_2?: InputMaybe<Scalars['String']>;
  /** The attribute phone_3 of Contact_info. It is of type String. Max length: 255.  */
  phone_3?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part objects of class Contact_info */
export type Contact_InfoDraftUpdateRoleObjects = {
  /** An array of objects of class Contact_info to be created. */
  create?: InputMaybe<Array<InputMaybe<Contact_InfoDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Contact_info to be updated. */
  update?: InputMaybe<Array<InputMaybe<Contact_InfoDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Contact_info.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Contact_InfoFilter = {
  AND?: InputMaybe<Array<Contact_InfoFilter>>;
  NOT?: InputMaybe<Contact_InfoFilter>;
  OR?: InputMaybe<Array<Contact_InfoFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  description____contains?: InputMaybe<Scalars['String']>;
  description____ends_with?: InputMaybe<Scalars['String']>;
  description____eq?: InputMaybe<Scalars['String']>;
  description____gt?: InputMaybe<Scalars['String']>;
  description____gte?: InputMaybe<Scalars['String']>;
  description____in?: InputMaybe<Array<Scalars['String']>>;
  description____lt?: InputMaybe<Scalars['String']>;
  description____lte?: InputMaybe<Scalars['String']>;
  description____ne?: InputMaybe<Scalars['String']>;
  description____not___contains?: InputMaybe<Scalars['String']>;
  description____not___ends_with?: InputMaybe<Scalars['String']>;
  description____not___in?: InputMaybe<Array<Scalars['String']>>;
  description____not___null?: InputMaybe<Scalars['Boolean']>;
  description____not___starts_with?: InputMaybe<Scalars['String']>;
  description____null?: InputMaybe<Scalars['Boolean']>;
  description____starts_with?: InputMaybe<Scalars['String']>;
  description___contains?: InputMaybe<Scalars['String']>;
  description___ends_with?: InputMaybe<Scalars['String']>;
  description___eq?: InputMaybe<Scalars['String']>;
  description___gt?: InputMaybe<Scalars['String']>;
  description___gte?: InputMaybe<Scalars['String']>;
  description___in?: InputMaybe<Array<Scalars['String']>>;
  description___lt?: InputMaybe<Scalars['String']>;
  description___lte?: InputMaybe<Scalars['String']>;
  description___ne?: InputMaybe<Scalars['String']>;
  description___not___contains?: InputMaybe<Scalars['String']>;
  description___not___ends_with?: InputMaybe<Scalars['String']>;
  description___not___in?: InputMaybe<Array<Scalars['String']>>;
  description___not___null?: InputMaybe<Scalars['Boolean']>;
  description___not___starts_with?: InputMaybe<Scalars['String']>;
  description___null?: InputMaybe<Scalars['Boolean']>;
  description___starts_with?: InputMaybe<Scalars['String']>;
  email_1___contains?: InputMaybe<Scalars['String']>;
  email_1___ends_with?: InputMaybe<Scalars['String']>;
  email_1___eq?: InputMaybe<Scalars['String']>;
  email_1___gt?: InputMaybe<Scalars['String']>;
  email_1___gte?: InputMaybe<Scalars['String']>;
  email_1___in?: InputMaybe<Array<Scalars['String']>>;
  email_1___lt?: InputMaybe<Scalars['String']>;
  email_1___lte?: InputMaybe<Scalars['String']>;
  email_1___ne?: InputMaybe<Scalars['String']>;
  email_1___not___contains?: InputMaybe<Scalars['String']>;
  email_1___not___ends_with?: InputMaybe<Scalars['String']>;
  email_1___not___in?: InputMaybe<Array<Scalars['String']>>;
  email_1___not___null?: InputMaybe<Scalars['Boolean']>;
  email_1___not___starts_with?: InputMaybe<Scalars['String']>;
  email_1___null?: InputMaybe<Scalars['Boolean']>;
  email_1___starts_with?: InputMaybe<Scalars['String']>;
  email_2___contains?: InputMaybe<Scalars['String']>;
  email_2___ends_with?: InputMaybe<Scalars['String']>;
  email_2___eq?: InputMaybe<Scalars['String']>;
  email_2___gt?: InputMaybe<Scalars['String']>;
  email_2___gte?: InputMaybe<Scalars['String']>;
  email_2___in?: InputMaybe<Array<Scalars['String']>>;
  email_2___lt?: InputMaybe<Scalars['String']>;
  email_2___lte?: InputMaybe<Scalars['String']>;
  email_2___ne?: InputMaybe<Scalars['String']>;
  email_2___not___contains?: InputMaybe<Scalars['String']>;
  email_2___not___ends_with?: InputMaybe<Scalars['String']>;
  email_2___not___in?: InputMaybe<Array<Scalars['String']>>;
  email_2___not___null?: InputMaybe<Scalars['Boolean']>;
  email_2___not___starts_with?: InputMaybe<Scalars['String']>;
  email_2___null?: InputMaybe<Scalars['Boolean']>;
  email_2___starts_with?: InputMaybe<Scalars['String']>;
  email_3___contains?: InputMaybe<Scalars['String']>;
  email_3___ends_with?: InputMaybe<Scalars['String']>;
  email_3___eq?: InputMaybe<Scalars['String']>;
  email_3___gt?: InputMaybe<Scalars['String']>;
  email_3___gte?: InputMaybe<Scalars['String']>;
  email_3___in?: InputMaybe<Array<Scalars['String']>>;
  email_3___lt?: InputMaybe<Scalars['String']>;
  email_3___lte?: InputMaybe<Scalars['String']>;
  email_3___ne?: InputMaybe<Scalars['String']>;
  email_3___not___contains?: InputMaybe<Scalars['String']>;
  email_3___not___ends_with?: InputMaybe<Scalars['String']>;
  email_3___not___in?: InputMaybe<Array<Scalars['String']>>;
  email_3___not___null?: InputMaybe<Scalars['Boolean']>;
  email_3___not___starts_with?: InputMaybe<Scalars['String']>;
  email_3___null?: InputMaybe<Scalars['Boolean']>;
  email_3___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  other___contains?: InputMaybe<Scalars['String']>;
  other___ends_with?: InputMaybe<Scalars['String']>;
  other___eq?: InputMaybe<Scalars['String']>;
  other___gt?: InputMaybe<Scalars['String']>;
  other___gte?: InputMaybe<Scalars['String']>;
  other___in?: InputMaybe<Array<Scalars['String']>>;
  other___lt?: InputMaybe<Scalars['String']>;
  other___lte?: InputMaybe<Scalars['String']>;
  other___ne?: InputMaybe<Scalars['String']>;
  other___not___contains?: InputMaybe<Scalars['String']>;
  other___not___ends_with?: InputMaybe<Scalars['String']>;
  other___not___in?: InputMaybe<Array<Scalars['String']>>;
  other___not___null?: InputMaybe<Scalars['Boolean']>;
  other___not___starts_with?: InputMaybe<Scalars['String']>;
  other___null?: InputMaybe<Scalars['Boolean']>;
  other___starts_with?: InputMaybe<Scalars['String']>;
  phone_1___contains?: InputMaybe<Scalars['String']>;
  phone_1___ends_with?: InputMaybe<Scalars['String']>;
  phone_1___eq?: InputMaybe<Scalars['String']>;
  phone_1___gt?: InputMaybe<Scalars['String']>;
  phone_1___gte?: InputMaybe<Scalars['String']>;
  phone_1___in?: InputMaybe<Array<Scalars['String']>>;
  phone_1___lt?: InputMaybe<Scalars['String']>;
  phone_1___lte?: InputMaybe<Scalars['String']>;
  phone_1___ne?: InputMaybe<Scalars['String']>;
  phone_1___not___contains?: InputMaybe<Scalars['String']>;
  phone_1___not___ends_with?: InputMaybe<Scalars['String']>;
  phone_1___not___in?: InputMaybe<Array<Scalars['String']>>;
  phone_1___not___null?: InputMaybe<Scalars['Boolean']>;
  phone_1___not___starts_with?: InputMaybe<Scalars['String']>;
  phone_1___null?: InputMaybe<Scalars['Boolean']>;
  phone_1___starts_with?: InputMaybe<Scalars['String']>;
  phone_2___contains?: InputMaybe<Scalars['String']>;
  phone_2___ends_with?: InputMaybe<Scalars['String']>;
  phone_2___eq?: InputMaybe<Scalars['String']>;
  phone_2___gt?: InputMaybe<Scalars['String']>;
  phone_2___gte?: InputMaybe<Scalars['String']>;
  phone_2___in?: InputMaybe<Array<Scalars['String']>>;
  phone_2___lt?: InputMaybe<Scalars['String']>;
  phone_2___lte?: InputMaybe<Scalars['String']>;
  phone_2___ne?: InputMaybe<Scalars['String']>;
  phone_2___not___contains?: InputMaybe<Scalars['String']>;
  phone_2___not___ends_with?: InputMaybe<Scalars['String']>;
  phone_2___not___in?: InputMaybe<Array<Scalars['String']>>;
  phone_2___not___null?: InputMaybe<Scalars['Boolean']>;
  phone_2___not___starts_with?: InputMaybe<Scalars['String']>;
  phone_2___null?: InputMaybe<Scalars['Boolean']>;
  phone_2___starts_with?: InputMaybe<Scalars['String']>;
  phone_3___contains?: InputMaybe<Scalars['String']>;
  phone_3___ends_with?: InputMaybe<Scalars['String']>;
  phone_3___eq?: InputMaybe<Scalars['String']>;
  phone_3___gt?: InputMaybe<Scalars['String']>;
  phone_3___gte?: InputMaybe<Scalars['String']>;
  phone_3___in?: InputMaybe<Array<Scalars['String']>>;
  phone_3___lt?: InputMaybe<Scalars['String']>;
  phone_3___lte?: InputMaybe<Scalars['String']>;
  phone_3___ne?: InputMaybe<Scalars['String']>;
  phone_3___not___contains?: InputMaybe<Scalars['String']>;
  phone_3___not___ends_with?: InputMaybe<Scalars['String']>;
  phone_3___not___in?: InputMaybe<Array<Scalars['String']>>;
  phone_3___not___null?: InputMaybe<Scalars['Boolean']>;
  phone_3___not___starts_with?: InputMaybe<Scalars['String']>;
  phone_3___null?: InputMaybe<Scalars['Boolean']>;
  phone_3___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Contact_info. */
export type Contact_InfoPage = {
  __typename?: 'Contact_infoPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Contact_info. */
  items: Array<Contact_Info>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Contact_info present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Contact_infoPage. */
export type Contact_InfoPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Contact_InfoFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Contact_InfoCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Contact_InfoSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Contact_info */
export type Contact_InfoRoleObjects = {
  /** An array of objects of class Contact_info to be created. */
  create?: InputMaybe<Array<InputMaybe<Contact_InfoCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Contact_info to be updated. */
  update?: InputMaybe<Array<InputMaybe<Contact_InfoUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Contact_InfoSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  description___ASC = 'description___ASC',
  description___DESC = 'description___DESC',
  description____ASC = 'description____ASC',
  description____DESC = 'description____DESC',
  email_1___ASC = 'email_1___ASC',
  email_1___DESC = 'email_1___DESC',
  email_2___ASC = 'email_2___ASC',
  email_2___DESC = 'email_2___DESC',
  email_3___ASC = 'email_3___ASC',
  email_3___DESC = 'email_3___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  other___ASC = 'other___ASC',
  other___DESC = 'other___DESC',
  phone_1___ASC = 'phone_1___ASC',
  phone_1___DESC = 'phone_1___DESC',
  phone_2___ASC = 'phone_2___ASC',
  phone_2___DESC = 'phone_2___DESC',
  phone_3___ASC = 'phone_3___ASC',
  phone_3___DESC = 'phone_3___DESC'
}

/** A data structure for a single object of class Contact_info. */
export type Contact_InfoUpdate = {
  _id: Scalars['ID'];
  /** The attribute description_ of Contact_info. It is of type String. Max length: 255.  */
  description_?: InputMaybe<Scalars['String']>;
  /** The attribute email_1 of Contact_info. It is of type String. Max length: 255.  */
  email_1?: InputMaybe<Scalars['String']>;
  /** The attribute email_2 of Contact_info. It is of type String. Max length: 255.  */
  email_2?: InputMaybe<Scalars['String']>;
  /** The attribute email_3 of Contact_info. It is of type String. Max length: 255.  */
  email_3?: InputMaybe<Scalars['String']>;
  /** The attribute name of Contact_info. It is of type String. Max length: 255. Cannot be null. */
  name?: InputMaybe<Scalars['String']>;
  /** The attribute other of Contact_info. It is of type String. Max length: 255.  */
  other?: InputMaybe<Scalars['String']>;
  /** The attribute phone_1 of Contact_info. It is of type String. Max length: 255.  */
  phone_1?: InputMaybe<Scalars['String']>;
  /** The attribute phone_2 of Contact_info. It is of type String. Max length: 255.  */
  phone_2?: InputMaybe<Scalars['String']>;
  /** The attribute phone_3 of Contact_info. It is of type String. Max length: 255.  */
  phone_3?: InputMaybe<Scalars['String']>;
};

/** A data structure for objects of class Contact_info. */
export type Contact_InfoUpdateBulk = {
  /** The attribute description_ of Contact_info. It is of type String. Max length: 255.  */
  description_?: InputMaybe<Scalars['String']>;
  /** The attribute email_1 of Contact_info. It is of type String. Max length: 255.  */
  email_1?: InputMaybe<Scalars['String']>;
  /** The attribute email_2 of Contact_info. It is of type String. Max length: 255.  */
  email_2?: InputMaybe<Scalars['String']>;
  /** The attribute email_3 of Contact_info. It is of type String. Max length: 255.  */
  email_3?: InputMaybe<Scalars['String']>;
  /** The attribute name of Contact_info. It is of type String. Max length: 255. Cannot be null. */
  name?: InputMaybe<Scalars['String']>;
  /** The attribute other of Contact_info. It is of type String. Max length: 255.  */
  other?: InputMaybe<Scalars['String']>;
  /** The attribute phone_1 of Contact_info. It is of type String. Max length: 255.  */
  phone_1?: InputMaybe<Scalars['String']>;
  /** The attribute phone_2 of Contact_info. It is of type String. Max length: 255.  */
  phone_2?: InputMaybe<Scalars['String']>;
  /** The attribute phone_3 of Contact_info. It is of type String. Max length: 255.  */
  phone_3?: InputMaybe<Scalars['String']>;
};

/** Represents an object of class Country, as defined in the model. */
export type Country = {
  __typename?: 'Country';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Country. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute code of Country. It is of type String. Max length: 255. Cannot be null. */
  code?: Maybe<Scalars['String']>;
  /** The attribute name of Country. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Country.
 */
export type CountryCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class Country */
export type CountryDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Country.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type CountryFilter = {
  AND?: InputMaybe<Array<CountryFilter>>;
  NOT?: InputMaybe<CountryFilter>;
  OR?: InputMaybe<Array<CountryFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Country. */
export type CountryPage = {
  __typename?: 'CountryPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Country. */
  items: Array<Country>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Country present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a CountryPage. */
export type CountryPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<CountryFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<CountryCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<CountrySort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Country */
export type CountryRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum CountrySort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC'
}

/** The result of the delte bulk mutation. */
export type DeleteBulkResult = {
  __typename?: 'DeleteBulkResult';
  /** The total count of objects returned by the page options that has been deleted. */
  deleted: Scalars['Int'];
};

/** The result of the delete mutation. */
export type DeleteResult = {
  __typename?: 'DeleteResult';
  /**
   * A boolean indicating if the current operation
   * has caused the deletion of the object.
   * This means that in case of non existing object this value will be false.
   */
  deleted: Scalars['Boolean'];
};

/** Represents an object of class Drug, as defined in the model. */
export type Drug = {
  __typename?: 'Drug';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Drug. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute atc of Drug. It is of type String. Max length: 255.  */
  atc?: Maybe<Scalars['String']>;
  /** The attribute name of Drug. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The attribute other of Drug. It is of type Boolean.  */
  other?: Maybe<Scalars['Boolean']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Drug.
 */
export type DrugCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  atc?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft associated object of class Drug */
export type DrugDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Drug.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type DrugFilter = {
  AND?: InputMaybe<Array<DrugFilter>>;
  NOT?: InputMaybe<DrugFilter>;
  OR?: InputMaybe<Array<DrugFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  atc___contains?: InputMaybe<Scalars['String']>;
  atc___ends_with?: InputMaybe<Scalars['String']>;
  atc___eq?: InputMaybe<Scalars['String']>;
  atc___gt?: InputMaybe<Scalars['String']>;
  atc___gte?: InputMaybe<Scalars['String']>;
  atc___in?: InputMaybe<Array<Scalars['String']>>;
  atc___lt?: InputMaybe<Scalars['String']>;
  atc___lte?: InputMaybe<Scalars['String']>;
  atc___ne?: InputMaybe<Scalars['String']>;
  atc___not___contains?: InputMaybe<Scalars['String']>;
  atc___not___ends_with?: InputMaybe<Scalars['String']>;
  atc___not___in?: InputMaybe<Array<Scalars['String']>>;
  atc___not___null?: InputMaybe<Scalars['Boolean']>;
  atc___not___starts_with?: InputMaybe<Scalars['String']>;
  atc___null?: InputMaybe<Scalars['Boolean']>;
  atc___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  other___eq?: InputMaybe<Scalars['Boolean']>;
  other___ne?: InputMaybe<Scalars['Boolean']>;
  other___not___null?: InputMaybe<Scalars['Boolean']>;
  other___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Drug. */
export type DrugPage = {
  __typename?: 'DrugPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Drug. */
  items: Array<Drug>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Drug present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a DrugPage. */
export type DrugPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<DrugFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<DrugCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<DrugSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Drug */
export type DrugRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum DrugSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  atc___ASC = 'atc___ASC',
  atc___DESC = 'atc___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  other___ASC = 'other___ASC',
  other___DESC = 'other___DESC'
}

/** Represents an object of class Educational_level, as defined in the model. */
export type Educational_Level = {
  __typename?: 'Educational_level';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Educational_level. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute educational_level of Educational_level. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated) [if(isNull(__CurrentUser.lang),Educational_level_lang.lang="English", if(containsCS(Educational_level.langs,__CurrentUser.lang), __CurrentUser.lang= Educational_level_lang.lang,Educational_level_lang.lang="English"))]educational_level_lang_ educational_level. Cannot be null. */
  educational_level?: Maybe<Scalars['String']>;
  /** The associated objects for the role educational_level_lang_. */
  educational_level_lang_?: Maybe<Educational_Level_LangPage>;
  /** The attribute langs of Educational_level. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)educational_level_lang_ lang. Cannot be null. */
  langs?: Maybe<Scalars['String']>;
  /** The attribute name of Educational_level. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The attribute order of Educational_level. It is of type Int.  */
  order?: Maybe<Scalars['Int']>;
};


/** Represents an object of class Educational_level, as defined in the model. */
export type Educational_LevelEducational_Level_Lang_Args = {
  options?: InputMaybe<Educational_Level_LangPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Educational_level.
 */
export type Educational_LevelCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  educational_level?: InputMaybe<Scalars['String']>;
  langs?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the draft associated object of class Educational_level */
export type Educational_LevelDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Educational_level.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Educational_LevelFilter = {
  AND?: InputMaybe<Array<Educational_LevelFilter>>;
  NOT?: InputMaybe<Educational_LevelFilter>;
  OR?: InputMaybe<Array<Educational_LevelFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  educational_level___contains?: InputMaybe<Scalars['String']>;
  educational_level___ends_with?: InputMaybe<Scalars['String']>;
  educational_level___eq?: InputMaybe<Scalars['String']>;
  educational_level___gt?: InputMaybe<Scalars['String']>;
  educational_level___gte?: InputMaybe<Scalars['String']>;
  educational_level___in?: InputMaybe<Array<Scalars['String']>>;
  educational_level___lt?: InputMaybe<Scalars['String']>;
  educational_level___lte?: InputMaybe<Scalars['String']>;
  educational_level___ne?: InputMaybe<Scalars['String']>;
  educational_level___not___contains?: InputMaybe<Scalars['String']>;
  educational_level___not___ends_with?: InputMaybe<Scalars['String']>;
  educational_level___not___in?: InputMaybe<Array<Scalars['String']>>;
  educational_level___not___null?: InputMaybe<Scalars['Boolean']>;
  educational_level___not___starts_with?: InputMaybe<Scalars['String']>;
  educational_level___null?: InputMaybe<Scalars['Boolean']>;
  educational_level___starts_with?: InputMaybe<Scalars['String']>;
  langs___contains?: InputMaybe<Scalars['String']>;
  langs___ends_with?: InputMaybe<Scalars['String']>;
  langs___eq?: InputMaybe<Scalars['String']>;
  langs___gt?: InputMaybe<Scalars['String']>;
  langs___gte?: InputMaybe<Scalars['String']>;
  langs___in?: InputMaybe<Array<Scalars['String']>>;
  langs___lt?: InputMaybe<Scalars['String']>;
  langs___lte?: InputMaybe<Scalars['String']>;
  langs___ne?: InputMaybe<Scalars['String']>;
  langs___not___contains?: InputMaybe<Scalars['String']>;
  langs___not___ends_with?: InputMaybe<Scalars['String']>;
  langs___not___in?: InputMaybe<Array<Scalars['String']>>;
  langs___not___null?: InputMaybe<Scalars['Boolean']>;
  langs___not___starts_with?: InputMaybe<Scalars['String']>;
  langs___null?: InputMaybe<Scalars['Boolean']>;
  langs___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  order___eq?: InputMaybe<Scalars['Int']>;
  order___gt?: InputMaybe<Scalars['Int']>;
  order___gte?: InputMaybe<Scalars['Int']>;
  order___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  order___lt?: InputMaybe<Scalars['Int']>;
  order___lte?: InputMaybe<Scalars['Int']>;
  order___ne?: InputMaybe<Scalars['Int']>;
  order___not___in?: InputMaybe<Array<Scalars['Int']>>;
  order___not___null?: InputMaybe<Scalars['Boolean']>;
  order___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Educational_level. */
export type Educational_LevelPage = {
  __typename?: 'Educational_levelPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Educational_level. */
  items: Array<Educational_Level>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Educational_level present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Educational_levelPage. */
export type Educational_LevelPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Educational_LevelFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Educational_LevelCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Educational_LevelSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Educational_level */
export type Educational_LevelRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Educational_LevelSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  educational_level___ASC = 'educational_level___ASC',
  educational_level___DESC = 'educational_level___DESC',
  langs___ASC = 'langs___ASC',
  langs___DESC = 'langs___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  order___ASC = 'order___ASC',
  order___DESC = 'order___DESC'
}

/** Represents an object of class Educational_level_lang, as defined in the model. */
export type Educational_Level_Lang = {
  __typename?: 'Educational_level_lang';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Educational_level_lang. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute educational_level of Educational_level_lang. It is of type String. Max length: 255. Cannot be null. */
  educational_level?: Maybe<Scalars['String']>;
  /** The associated object for the role educational_level_. */
  educational_level_?: Maybe<Educational_Level>;
  /** The attribute lang of Educational_level_lang. It is of type String. Path: lang_ name. Cannot be null. */
  lang?: Maybe<Scalars['String']>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
};


/** Represents an object of class Educational_level_lang, as defined in the model. */
export type Educational_Level_LangLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};

/** A data structure for objects of Educational_level_lang. */
export type Educational_Level_LangCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute educational_level of Educational_level_lang. It is of type String!. Max length: 255. Cannot be null. */
  educational_level: Scalars['String'];
  /** The Association role educational_level_lang_(0,N) --> (1)lang_. */
  lang_: Scalars['ID'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Educational_level_lang.
 */
export type Educational_Level_LangCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  educational_level?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft object of class Educational_level_lang. */
export type Educational_Level_LangDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute educational_level of Educational_level_lang. It is of type String. Max length: 255. Cannot be null. */
  educational_level?: InputMaybe<Scalars['String']>;
  /** The Association role educational_level_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for draft create objects of class Educational_level_lang. */
export type Educational_Level_LangDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute educational_level of Educational_level_lang. It is of type String. Max length: 255. Cannot be null. */
  educational_level?: InputMaybe<Scalars['String']>;
  /** The Association role educational_level_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft part objects of class Educational_level_lang */
export type Educational_Level_LangDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Educational_level_lang to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Educational_Level_LangDraft>>>;
};

/** A data structure for a draft update object of class Educational_level_lang. */
export type Educational_Level_LangDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute educational_level of Educational_level_lang. It is of type String. Max length: 255. Cannot be null. */
  educational_level?: InputMaybe<Scalars['String']>;
  /** The Association role educational_level_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
};

/** A data structure for a draft update objects of class Educational_level_lang. */
export type Educational_Level_LangDraftUpdateBulk = {
  /** The attribute educational_level of Educational_level_lang. It is of type String. Max length: 255. Cannot be null. */
  educational_level?: InputMaybe<Scalars['String']>;
  /** The Association role educational_level_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
};

/** A data structure for the draft part objects of class Educational_level_lang */
export type Educational_Level_LangDraftUpdateRoleObjects = {
  /** An array of objects of class Educational_level_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Educational_Level_LangDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Educational_level_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Educational_Level_LangDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Educational_level_lang.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Educational_Level_LangFilter = {
  AND?: InputMaybe<Array<Educational_Level_LangFilter>>;
  NOT?: InputMaybe<Educational_Level_LangFilter>;
  OR?: InputMaybe<Array<Educational_Level_LangFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  educational_level___contains?: InputMaybe<Scalars['String']>;
  educational_level___ends_with?: InputMaybe<Scalars['String']>;
  educational_level___eq?: InputMaybe<Scalars['String']>;
  educational_level___gt?: InputMaybe<Scalars['String']>;
  educational_level___gte?: InputMaybe<Scalars['String']>;
  educational_level___in?: InputMaybe<Array<Scalars['String']>>;
  educational_level___lt?: InputMaybe<Scalars['String']>;
  educational_level___lte?: InputMaybe<Scalars['String']>;
  educational_level___ne?: InputMaybe<Scalars['String']>;
  educational_level___not___contains?: InputMaybe<Scalars['String']>;
  educational_level___not___ends_with?: InputMaybe<Scalars['String']>;
  educational_level___not___in?: InputMaybe<Array<Scalars['String']>>;
  educational_level___not___null?: InputMaybe<Scalars['Boolean']>;
  educational_level___not___starts_with?: InputMaybe<Scalars['String']>;
  educational_level___null?: InputMaybe<Scalars['Boolean']>;
  educational_level___starts_with?: InputMaybe<Scalars['String']>;
  lang___contains?: InputMaybe<Scalars['String']>;
  lang___ends_with?: InputMaybe<Scalars['String']>;
  lang___eq?: InputMaybe<Scalars['String']>;
  lang___gt?: InputMaybe<Scalars['String']>;
  lang___gte?: InputMaybe<Scalars['String']>;
  lang___in?: InputMaybe<Array<Scalars['String']>>;
  lang___lt?: InputMaybe<Scalars['String']>;
  lang___lte?: InputMaybe<Scalars['String']>;
  lang___ne?: InputMaybe<Scalars['String']>;
  lang___not___contains?: InputMaybe<Scalars['String']>;
  lang___not___ends_with?: InputMaybe<Scalars['String']>;
  lang___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang___not___null?: InputMaybe<Scalars['Boolean']>;
  lang___not___starts_with?: InputMaybe<Scalars['String']>;
  lang___null?: InputMaybe<Scalars['Boolean']>;
  lang___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Educational_level_lang. */
export type Educational_Level_LangPage = {
  __typename?: 'Educational_level_langPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Educational_level_lang. */
  items: Array<Educational_Level_Lang>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Educational_level_lang present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Educational_level_langPage. */
export type Educational_Level_LangPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Educational_Level_LangFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Educational_Level_LangCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Educational_Level_LangSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Educational_level_lang */
export type Educational_Level_LangRoleObjects = {
  /** An array of objects of class Educational_level_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Educational_Level_LangCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Educational_level_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Educational_Level_LangUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Educational_Level_LangSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  educational_level___ASC = 'educational_level___ASC',
  educational_level___DESC = 'educational_level___DESC',
  lang___ASC = 'lang___ASC',
  lang___DESC = 'lang___DESC'
}

/** A data structure for a single object of class Educational_level_lang. */
export type Educational_Level_LangUpdate = {
  _id: Scalars['ID'];
  /** The attribute educational_level of Educational_level_lang. It is of type String. Max length: 255. Cannot be null. */
  educational_level?: InputMaybe<Scalars['String']>;
  /** The Association role educational_level_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
};

/** A data structure for objects of class Educational_level_lang. */
export type Educational_Level_LangUpdateBulk = {
  /** The attribute educational_level of Educational_level_lang. It is of type String. Max length: 255. Cannot be null. */
  educational_level?: InputMaybe<Scalars['String']>;
  /** The Association role educational_level_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
};

export type EntityId = {
  _id: Scalars['ID'];
  entityName: EntityName;
};

export enum EntityName {
  Address = 'Address',
  Center = 'Center',
  Children_relation = 'Children_relation',
  Children_relation_lang = 'Children_relation_lang',
  Commute_vehicle = 'Commute_vehicle',
  Commute_vehicle_lang = 'Commute_vehicle_lang',
  Comorbidity = 'Comorbidity',
  Contact_info = 'Contact_info',
  Country = 'Country',
  Drug = 'Drug',
  Educational_level = 'Educational_level',
  Educational_level_lang = 'Educational_level_lang',
  Ethnicity = 'Ethnicity',
  Examination_diagnosis = 'Examination_diagnosis',
  Examination_type = 'Examination_type',
  Family = 'Family',
  Family_covid_test = 'Family_covid_test',
  Family_weekly_questionnaire = 'Family_weekly_questionnaire',
  Gender = 'Gender',
  Hospital = 'Hospital',
  Instrumental_examination = 'Instrumental_examination',
  Laboratory = 'Laboratory',
  Lang = 'Lang',
  Level_type = 'Level_type',
  Level_type_lang = 'Level_type_lang',
  Message_lang = 'Message_lang',
  Neighbourhood_type = 'Neighbourhood_type',
  Neighbourhood_type_lang = 'Neighbourhood_type_lang',
  PASC_arm = 'PASC_arm',
  Patient = 'Patient',
  Person = 'Person',
  Person_type = 'Person_type',
  Possible_response = 'Possible_response',
  Possible_response_lang = 'Possible_response_lang',
  Question = 'Question',
  Question_enabling_condition = 'Question_enabling_condition',
  Question_lang = 'Question_lang',
  Questionnaire_completion = 'Questionnaire_completion',
  Questionnaire_config = 'Questionnaire_config',
  Questionnaire_lang = 'Questionnaire_lang',
  Questionnaire_schema = 'Questionnaire_schema',
  Questionnaire_section = 'Questionnaire_section',
  Questionnaire_section_lang = 'Questionnaire_section_lang',
  Response = 'Response',
  Response_type = 'Response_type',
  Risk_factor = 'Risk_factor',
  School = 'School',
  School_class = 'School_class',
  School_level = 'School_level',
  School_people = 'School_people',
  Study = 'Study',
  Symptom = 'Symptom',
  Symptom_lang = 'Symptom_lang',
  Template = 'Template',
  Treatment = 'Treatment',
  User_type = 'User_type',
  Vaccine = 'Vaccine',
  Vaccine_dose = 'Vaccine_dose',
  _User = '_User'
}

/** Represents an object of class Ethnicity, as defined in the model. */
export type Ethnicity = {
  __typename?: 'Ethnicity';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Ethnicity. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute code of Ethnicity. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The attribute name of Ethnicity. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Ethnicity.
 */
export type EthnicityCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class Ethnicity */
export type EthnicityDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Ethnicity.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type EthnicityFilter = {
  AND?: InputMaybe<Array<EthnicityFilter>>;
  NOT?: InputMaybe<EthnicityFilter>;
  OR?: InputMaybe<Array<EthnicityFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Ethnicity. */
export type EthnicityPage = {
  __typename?: 'EthnicityPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Ethnicity. */
  items: Array<Ethnicity>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Ethnicity present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a EthnicityPage. */
export type EthnicityPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<EthnicityFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<EthnicityCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<EthnicitySort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Ethnicity */
export type EthnicityRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum EthnicitySort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC'
}

/** Represents an object of class Examination_diagnosis, as defined in the model. */
export type Examination_Diagnosis = {
  __typename?: 'Examination_diagnosis';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Examination_diagnosis. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute code of Examination_diagnosis. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The associated object for the role examination_type_. */
  examination_type_?: Maybe<Examination_Type>;
  /** The attribute name of Examination_diagnosis. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
};

/** A data structure for objects of Examination_diagnosis. */
export type Examination_DiagnosisCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute code of Examination_diagnosis. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute name of Examination_diagnosis. It is of type String!. Max length: 255. Cannot be null. */
  name: Scalars['String'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Examination_diagnosis.
 */
export type Examination_DiagnosisCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft object of class Examination_diagnosis. */
export type Examination_DiagnosisDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute code of Examination_diagnosis. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute name of Examination_diagnosis. It is of type String. Max length: 255. Cannot be null. */
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for draft create objects of class Examination_diagnosis. */
export type Examination_DiagnosisDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute code of Examination_diagnosis. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute name of Examination_diagnosis. It is of type String. Max length: 255. Cannot be null. */
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part objects of class Examination_diagnosis */
export type Examination_DiagnosisDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Examination_diagnosis to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Examination_DiagnosisDraft>>>;
};

/** A data structure for the draft associated objects of class Examination_diagnosis */
export type Examination_DiagnosisDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for a draft update object of class Examination_diagnosis. */
export type Examination_DiagnosisDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute code of Examination_diagnosis. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute name of Examination_diagnosis. It is of type String. Max length: 255. Cannot be null. */
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft update objects of class Examination_diagnosis. */
export type Examination_DiagnosisDraftUpdateBulk = {
  /** The attribute code of Examination_diagnosis. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute name of Examination_diagnosis. It is of type String. Max length: 255. Cannot be null. */
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part objects of class Examination_diagnosis */
export type Examination_DiagnosisDraftUpdateRoleObjects = {
  /** An array of objects of class Examination_diagnosis to be created. */
  create?: InputMaybe<Array<InputMaybe<Examination_DiagnosisDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Examination_diagnosis to be updated. */
  update?: InputMaybe<Array<InputMaybe<Examination_DiagnosisDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Examination_diagnosis.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Examination_DiagnosisFilter = {
  AND?: InputMaybe<Array<Examination_DiagnosisFilter>>;
  NOT?: InputMaybe<Examination_DiagnosisFilter>;
  OR?: InputMaybe<Array<Examination_DiagnosisFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Examination_diagnosis. */
export type Examination_DiagnosisPage = {
  __typename?: 'Examination_diagnosisPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Examination_diagnosis. */
  items: Array<Examination_Diagnosis>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Examination_diagnosis present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Examination_diagnosisPage. */
export type Examination_DiagnosisPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Examination_DiagnosisFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Examination_DiagnosisCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Examination_DiagnosisSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Examination_diagnosis */
export type Examination_DiagnosisRoleObjects = {
  /** An array of objects of class Examination_diagnosis to be created. */
  create?: InputMaybe<Array<InputMaybe<Examination_DiagnosisCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Examination_diagnosis to be updated. */
  update?: InputMaybe<Array<InputMaybe<Examination_DiagnosisUpdate>>>;
};

/** A data structure for the associated objects of class Examination_diagnosis */
export type Examination_DiagnosisRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Examination_DiagnosisSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC'
}

/** A data structure for a single object of class Examination_diagnosis. */
export type Examination_DiagnosisUpdate = {
  _id: Scalars['ID'];
  /** The attribute code of Examination_diagnosis. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute name of Examination_diagnosis. It is of type String. Max length: 255. Cannot be null. */
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for objects of class Examination_diagnosis. */
export type Examination_DiagnosisUpdateBulk = {
  /** The attribute code of Examination_diagnosis. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute name of Examination_diagnosis. It is of type String. Max length: 255. Cannot be null. */
  name?: InputMaybe<Scalars['String']>;
};

/** Represents an object of class Examination_type, as defined in the model. */
export type Examination_Type = {
  __typename?: 'Examination_type';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Examination_type. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute code of Examination_type. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The attribute echo of Examination_type. It is of type Boolean.  */
  echo?: Maybe<Scalars['Boolean']>;
  /** The associated objects for the role examination_diagnosis_. */
  examination_diagnosis_?: Maybe<Examination_DiagnosisPage>;
  /** The attribute name of Examination_type. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The attribute rehab of Examination_type. It is of type Boolean.  */
  rehab?: Maybe<Scalars['Boolean']>;
  /** The attribute spiro of Examination_type. It is of type Boolean.  */
  spiro?: Maybe<Scalars['Boolean']>;
};


/** Represents an object of class Examination_type, as defined in the model. */
export type Examination_TypeExamination_Diagnosis_Args = {
  options?: InputMaybe<Examination_DiagnosisPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Examination_type.
 */
export type Examination_TypeCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  echo?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  rehab?: InputMaybe<Scalars['Boolean']>;
  spiro?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft associated object of class Examination_type */
export type Examination_TypeDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Examination_type.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Examination_TypeFilter = {
  AND?: InputMaybe<Array<Examination_TypeFilter>>;
  NOT?: InputMaybe<Examination_TypeFilter>;
  OR?: InputMaybe<Array<Examination_TypeFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  echo___eq?: InputMaybe<Scalars['Boolean']>;
  echo___ne?: InputMaybe<Scalars['Boolean']>;
  echo___not___null?: InputMaybe<Scalars['Boolean']>;
  echo___null?: InputMaybe<Scalars['Boolean']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  rehab___eq?: InputMaybe<Scalars['Boolean']>;
  rehab___ne?: InputMaybe<Scalars['Boolean']>;
  rehab___not___null?: InputMaybe<Scalars['Boolean']>;
  rehab___null?: InputMaybe<Scalars['Boolean']>;
  spiro___eq?: InputMaybe<Scalars['Boolean']>;
  spiro___ne?: InputMaybe<Scalars['Boolean']>;
  spiro___not___null?: InputMaybe<Scalars['Boolean']>;
  spiro___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Examination_type. */
export type Examination_TypePage = {
  __typename?: 'Examination_typePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Examination_type. */
  items: Array<Examination_Type>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Examination_type present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Examination_typePage. */
export type Examination_TypePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Examination_TypeFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Examination_TypeCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Examination_TypeSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Examination_type */
export type Examination_TypeRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Examination_TypeSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  echo___ASC = 'echo___ASC',
  echo___DESC = 'echo___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  rehab___ASC = 'rehab___ASC',
  rehab___DESC = 'rehab___DESC',
  spiro___ASC = 'spiro___ASC',
  spiro___DESC = 'spiro___DESC'
}

/** Represents an object of class Family, as defined in the model. */
export type Family = {
  __typename?: 'Family';
  /** The associated object for the role User_. */
  User_?: Maybe<_User>;
  /** The associable objects for the role User_. */
  User____associables?: Maybe<_UserPage>;
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _createdby of Family. It is of type String. Cannot be null. */
  _createdby?: Maybe<Scalars['String']>;
  /** The attribute _createdon of Family. It is of type Datetime @datetimeFormat. Cannot be null. */
  _createdon?: Maybe<Scalars['Datetime']>;
  /** The attribute _id of Family. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute _lastmodifiedby of Family. It is of type String. Cannot be null. */
  _lastmodifiedby?: Maybe<Scalars['String']>;
  /** The attribute _lastmodifiedon of Family. It is of type Datetime @datetimeFormat. Cannot be null. */
  _lastmodifiedon?: Maybe<Scalars['Datetime']>;
  /** The associated object for the role center_. */
  center_?: Maybe<Center>;
  /** The associable objects for the role center_. */
  center____associables?: Maybe<CenterPage>;
  /** The attribute city of Family. It is of type String. Max length: 255.  */
  city?: Maybe<Scalars['String']>;
  /** The attribute date_last_questionnaire of Family. It is of type Date @dateFormat. Path: MAX - Maxquestionnaire_completion_ completion_date. Cannot be null. */
  date_last_questionnaire?: Maybe<Scalars['Date']>;
  /** The attribute date_last_weekly_questionnaire of Family. It is of type Date @dateFormat. Path: MAX - Maxfamily_weekly_questionnaire_ date. Cannot be null. */
  date_last_weekly_questionnaire?: Maybe<Scalars['Date']>;
  /** The attribute email of Family. It is of type String. Max length: 255. Cannot be null. */
  email?: Maybe<Scalars['String']>;
  /** The associated objects for the role family_weekly_questionnaire_. */
  family_weekly_questionnaire_?: Maybe<Family_Weekly_QuestionnairePage>;
  /** The associable objects for the role family_weekly_questionnaire_. */
  family_weekly_questionnaire____associables?: Maybe<Family_Weekly_QuestionnairePage>;
  /** The attribute n_students of Family. It is of type Int. Path: COUNT_DISTINCT - Count distinct [School_people.type_student]person_.school_people_ __id. Cannot be null. */
  n_students?: Maybe<Scalars['Int']>;
  /** The attribute neighbourhood_type of Family. It is of type String. Path: neighbourhood_type_ name. Cannot be null. */
  neighbourhood_type?: Maybe<Scalars['String']>;
  /** The associated object for the role neighbourhood_type_. */
  neighbourhood_type_?: Maybe<Neighbourhood_Type>;
  /** The associable objects for the role neighbourhood_type_. */
  neighbourhood_type____associables?: Maybe<Neighbourhood_TypePage>;
  /** The associated objects for the role person_. */
  person_?: Maybe<PersonPage>;
  /** The associable objects for the role person_. */
  person____associables?: Maybe<PersonPage>;
  /** The attribute school_ids of Family. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)person_.school_people_.school_ __id. Cannot be null. */
  school_ids?: Maybe<Scalars['String']>;
  /** The attribute schools of Family. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)person_.school_people_ school. Cannot be null. */
  schools?: Maybe<Scalars['String']>;
  /** The attribute username of Family. It is of type String. Path: User_ username. Cannot be null. */
  username?: Maybe<Scalars['String']>;
};


/** Represents an object of class Family, as defined in the model. */
export type FamilyUser____AssociablesArgs = {
  options?: InputMaybe<_UserPageOptions>;
};


/** Represents an object of class Family, as defined in the model. */
export type Family_CreatedonArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Family, as defined in the model. */
export type Family_LastmodifiedonArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Family, as defined in the model. */
export type FamilyCenter____AssociablesArgs = {
  options?: InputMaybe<CenterPageOptions>;
};


/** Represents an object of class Family, as defined in the model. */
export type FamilyDate_Last_QuestionnaireArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Family, as defined in the model. */
export type FamilyDate_Last_Weekly_QuestionnaireArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Family, as defined in the model. */
export type FamilyFamily_Weekly_Questionnaire_Args = {
  options?: InputMaybe<Family_Weekly_QuestionnairePageOptions>;
};


/** Represents an object of class Family, as defined in the model. */
export type FamilyFamily_Weekly_Questionnaire____AssociablesArgs = {
  options?: InputMaybe<Family_Weekly_QuestionnairePageOptions>;
};


/** Represents an object of class Family, as defined in the model. */
export type FamilyNeighbourhood_Type____AssociablesArgs = {
  options?: InputMaybe<Neighbourhood_TypePageOptions>;
};


/** Represents an object of class Family, as defined in the model. */
export type FamilyPerson_Args = {
  options?: InputMaybe<PersonPageOptions>;
};


/** Represents an object of class Family, as defined in the model. */
export type FamilyPerson____AssociablesArgs = {
  options?: InputMaybe<PersonPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Family.
 */
export type FamilyCursor = {
  _createdby?: InputMaybe<Scalars['String']>;
  _createdon?: InputMaybe<Scalars['Datetime']>;
  _id?: InputMaybe<Scalars['ID']>;
  _lastmodifiedby?: InputMaybe<Scalars['String']>;
  _lastmodifiedon?: InputMaybe<Scalars['Datetime']>;
  city?: InputMaybe<Scalars['String']>;
  date_last_questionnaire?: InputMaybe<Scalars['Date']>;
  date_last_weekly_questionnaire?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  n_students?: InputMaybe<Scalars['Int']>;
  neighbourhood_type?: InputMaybe<Scalars['String']>;
  school_ids?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class Family */
export type FamilyDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Family.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type FamilyFilter = {
  AND?: InputMaybe<Array<FamilyFilter>>;
  NOT?: InputMaybe<FamilyFilter>;
  OR?: InputMaybe<Array<FamilyFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _createdby___contains?: InputMaybe<Scalars['String']>;
  _createdby___ends_with?: InputMaybe<Scalars['String']>;
  _createdby___eq?: InputMaybe<Scalars['String']>;
  _createdby___gt?: InputMaybe<Scalars['String']>;
  _createdby___gte?: InputMaybe<Scalars['String']>;
  _createdby___in?: InputMaybe<Array<Scalars['String']>>;
  _createdby___lt?: InputMaybe<Scalars['String']>;
  _createdby___lte?: InputMaybe<Scalars['String']>;
  _createdby___ne?: InputMaybe<Scalars['String']>;
  _createdby___not___contains?: InputMaybe<Scalars['String']>;
  _createdby___not___ends_with?: InputMaybe<Scalars['String']>;
  _createdby___not___in?: InputMaybe<Array<Scalars['String']>>;
  _createdby___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdby___not___starts_with?: InputMaybe<Scalars['String']>;
  _createdby___null?: InputMaybe<Scalars['Boolean']>;
  _createdby___starts_with?: InputMaybe<Scalars['String']>;
  _createdon___eq?: InputMaybe<Scalars['Datetime']>;
  _createdon___gt?: InputMaybe<Scalars['Datetime']>;
  _createdon___gte?: InputMaybe<Scalars['Datetime']>;
  _createdon___in?: InputMaybe<Array<InputMaybe<Scalars['Datetime']>>>;
  _createdon___lt?: InputMaybe<Scalars['Datetime']>;
  _createdon___lte?: InputMaybe<Scalars['Datetime']>;
  _createdon___ne?: InputMaybe<Scalars['Datetime']>;
  _createdon___not___in?: InputMaybe<Array<Scalars['Datetime']>>;
  _createdon___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdon___null?: InputMaybe<Scalars['Boolean']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedby___contains?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___ends_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___eq?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___gt?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___gte?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___in?: InputMaybe<Array<Scalars['String']>>;
  _lastmodifiedby___lt?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___lte?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___ne?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___not___contains?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___not___ends_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___not___in?: InputMaybe<Array<Scalars['String']>>;
  _lastmodifiedby___not___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedby___not___starts_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedby___starts_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedon___eq?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___gt?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___gte?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___in?: InputMaybe<Array<InputMaybe<Scalars['Datetime']>>>;
  _lastmodifiedon___lt?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___lte?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___ne?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___not___in?: InputMaybe<Array<Scalars['Datetime']>>;
  _lastmodifiedon___not___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedon___null?: InputMaybe<Scalars['Boolean']>;
  city___contains?: InputMaybe<Scalars['String']>;
  city___ends_with?: InputMaybe<Scalars['String']>;
  city___eq?: InputMaybe<Scalars['String']>;
  city___gt?: InputMaybe<Scalars['String']>;
  city___gte?: InputMaybe<Scalars['String']>;
  city___in?: InputMaybe<Array<Scalars['String']>>;
  city___lt?: InputMaybe<Scalars['String']>;
  city___lte?: InputMaybe<Scalars['String']>;
  city___ne?: InputMaybe<Scalars['String']>;
  city___not___contains?: InputMaybe<Scalars['String']>;
  city___not___ends_with?: InputMaybe<Scalars['String']>;
  city___not___in?: InputMaybe<Array<Scalars['String']>>;
  city___not___null?: InputMaybe<Scalars['Boolean']>;
  city___not___starts_with?: InputMaybe<Scalars['String']>;
  city___null?: InputMaybe<Scalars['Boolean']>;
  city___starts_with?: InputMaybe<Scalars['String']>;
  date_last_questionnaire___eq?: InputMaybe<Scalars['Date']>;
  date_last_questionnaire___gt?: InputMaybe<Scalars['Date']>;
  date_last_questionnaire___gte?: InputMaybe<Scalars['Date']>;
  date_last_questionnaire___in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  date_last_questionnaire___lt?: InputMaybe<Scalars['Date']>;
  date_last_questionnaire___lte?: InputMaybe<Scalars['Date']>;
  date_last_questionnaire___ne?: InputMaybe<Scalars['Date']>;
  date_last_questionnaire___not___in?: InputMaybe<Array<Scalars['Date']>>;
  date_last_questionnaire___not___null?: InputMaybe<Scalars['Boolean']>;
  date_last_questionnaire___null?: InputMaybe<Scalars['Boolean']>;
  date_last_weekly_questionnaire___eq?: InputMaybe<Scalars['Date']>;
  date_last_weekly_questionnaire___gt?: InputMaybe<Scalars['Date']>;
  date_last_weekly_questionnaire___gte?: InputMaybe<Scalars['Date']>;
  date_last_weekly_questionnaire___in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  date_last_weekly_questionnaire___lt?: InputMaybe<Scalars['Date']>;
  date_last_weekly_questionnaire___lte?: InputMaybe<Scalars['Date']>;
  date_last_weekly_questionnaire___ne?: InputMaybe<Scalars['Date']>;
  date_last_weekly_questionnaire___not___in?: InputMaybe<Array<Scalars['Date']>>;
  date_last_weekly_questionnaire___not___null?: InputMaybe<Scalars['Boolean']>;
  date_last_weekly_questionnaire___null?: InputMaybe<Scalars['Boolean']>;
  email___contains?: InputMaybe<Scalars['String']>;
  email___ends_with?: InputMaybe<Scalars['String']>;
  email___eq?: InputMaybe<Scalars['String']>;
  email___gt?: InputMaybe<Scalars['String']>;
  email___gte?: InputMaybe<Scalars['String']>;
  email___in?: InputMaybe<Array<Scalars['String']>>;
  email___lt?: InputMaybe<Scalars['String']>;
  email___lte?: InputMaybe<Scalars['String']>;
  email___ne?: InputMaybe<Scalars['String']>;
  email___not___contains?: InputMaybe<Scalars['String']>;
  email___not___ends_with?: InputMaybe<Scalars['String']>;
  email___not___in?: InputMaybe<Array<Scalars['String']>>;
  email___not___null?: InputMaybe<Scalars['Boolean']>;
  email___not___starts_with?: InputMaybe<Scalars['String']>;
  email___null?: InputMaybe<Scalars['Boolean']>;
  email___starts_with?: InputMaybe<Scalars['String']>;
  n_students___eq?: InputMaybe<Scalars['Int']>;
  n_students___gt?: InputMaybe<Scalars['Int']>;
  n_students___gte?: InputMaybe<Scalars['Int']>;
  n_students___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  n_students___lt?: InputMaybe<Scalars['Int']>;
  n_students___lte?: InputMaybe<Scalars['Int']>;
  n_students___ne?: InputMaybe<Scalars['Int']>;
  n_students___not___in?: InputMaybe<Array<Scalars['Int']>>;
  n_students___not___null?: InputMaybe<Scalars['Boolean']>;
  n_students___null?: InputMaybe<Scalars['Boolean']>;
  neighbourhood_type___contains?: InputMaybe<Scalars['String']>;
  neighbourhood_type___ends_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___eq?: InputMaybe<Scalars['String']>;
  neighbourhood_type___gt?: InputMaybe<Scalars['String']>;
  neighbourhood_type___gte?: InputMaybe<Scalars['String']>;
  neighbourhood_type___in?: InputMaybe<Array<Scalars['String']>>;
  neighbourhood_type___lt?: InputMaybe<Scalars['String']>;
  neighbourhood_type___lte?: InputMaybe<Scalars['String']>;
  neighbourhood_type___ne?: InputMaybe<Scalars['String']>;
  neighbourhood_type___not___contains?: InputMaybe<Scalars['String']>;
  neighbourhood_type___not___ends_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___not___in?: InputMaybe<Array<Scalars['String']>>;
  neighbourhood_type___not___null?: InputMaybe<Scalars['Boolean']>;
  neighbourhood_type___not___starts_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___null?: InputMaybe<Scalars['Boolean']>;
  neighbourhood_type___starts_with?: InputMaybe<Scalars['String']>;
  school_ids___contains?: InputMaybe<Scalars['String']>;
  school_ids___ends_with?: InputMaybe<Scalars['String']>;
  school_ids___eq?: InputMaybe<Scalars['String']>;
  school_ids___gt?: InputMaybe<Scalars['String']>;
  school_ids___gte?: InputMaybe<Scalars['String']>;
  school_ids___in?: InputMaybe<Array<Scalars['String']>>;
  school_ids___lt?: InputMaybe<Scalars['String']>;
  school_ids___lte?: InputMaybe<Scalars['String']>;
  school_ids___ne?: InputMaybe<Scalars['String']>;
  school_ids___not___contains?: InputMaybe<Scalars['String']>;
  school_ids___not___ends_with?: InputMaybe<Scalars['String']>;
  school_ids___not___in?: InputMaybe<Array<Scalars['String']>>;
  school_ids___not___null?: InputMaybe<Scalars['Boolean']>;
  school_ids___not___starts_with?: InputMaybe<Scalars['String']>;
  school_ids___null?: InputMaybe<Scalars['Boolean']>;
  school_ids___starts_with?: InputMaybe<Scalars['String']>;
  schools___contains?: InputMaybe<Scalars['String']>;
  schools___ends_with?: InputMaybe<Scalars['String']>;
  schools___eq?: InputMaybe<Scalars['String']>;
  schools___gt?: InputMaybe<Scalars['String']>;
  schools___gte?: InputMaybe<Scalars['String']>;
  schools___in?: InputMaybe<Array<Scalars['String']>>;
  schools___lt?: InputMaybe<Scalars['String']>;
  schools___lte?: InputMaybe<Scalars['String']>;
  schools___ne?: InputMaybe<Scalars['String']>;
  schools___not___contains?: InputMaybe<Scalars['String']>;
  schools___not___ends_with?: InputMaybe<Scalars['String']>;
  schools___not___in?: InputMaybe<Array<Scalars['String']>>;
  schools___not___null?: InputMaybe<Scalars['Boolean']>;
  schools___not___starts_with?: InputMaybe<Scalars['String']>;
  schools___null?: InputMaybe<Scalars['Boolean']>;
  schools___starts_with?: InputMaybe<Scalars['String']>;
  username___contains?: InputMaybe<Scalars['String']>;
  username___ends_with?: InputMaybe<Scalars['String']>;
  username___eq?: InputMaybe<Scalars['String']>;
  username___gt?: InputMaybe<Scalars['String']>;
  username___gte?: InputMaybe<Scalars['String']>;
  username___in?: InputMaybe<Array<Scalars['String']>>;
  username___lt?: InputMaybe<Scalars['String']>;
  username___lte?: InputMaybe<Scalars['String']>;
  username___ne?: InputMaybe<Scalars['String']>;
  username___not___contains?: InputMaybe<Scalars['String']>;
  username___not___ends_with?: InputMaybe<Scalars['String']>;
  username___not___in?: InputMaybe<Array<Scalars['String']>>;
  username___not___null?: InputMaybe<Scalars['Boolean']>;
  username___not___starts_with?: InputMaybe<Scalars['String']>;
  username___null?: InputMaybe<Scalars['Boolean']>;
  username___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Family. */
export type FamilyPage = {
  __typename?: 'FamilyPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Family. */
  items: Array<Family>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Family present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a FamilyPage. */
export type FamilyPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<FamilyFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<FamilyCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<FamilySort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Family */
export type FamilyRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum FamilySort {
  _createdby___ASC = '_createdby___ASC',
  _createdby___DESC = '_createdby___DESC',
  _createdon___ASC = '_createdon___ASC',
  _createdon___DESC = '_createdon___DESC',
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  _lastmodifiedby___ASC = '_lastmodifiedby___ASC',
  _lastmodifiedby___DESC = '_lastmodifiedby___DESC',
  _lastmodifiedon___ASC = '_lastmodifiedon___ASC',
  _lastmodifiedon___DESC = '_lastmodifiedon___DESC',
  city___ASC = 'city___ASC',
  city___DESC = 'city___DESC',
  date_last_questionnaire___ASC = 'date_last_questionnaire___ASC',
  date_last_questionnaire___DESC = 'date_last_questionnaire___DESC',
  date_last_weekly_questionnaire___ASC = 'date_last_weekly_questionnaire___ASC',
  date_last_weekly_questionnaire___DESC = 'date_last_weekly_questionnaire___DESC',
  email___ASC = 'email___ASC',
  email___DESC = 'email___DESC',
  n_students___ASC = 'n_students___ASC',
  n_students___DESC = 'n_students___DESC',
  neighbourhood_type___ASC = 'neighbourhood_type___ASC',
  neighbourhood_type___DESC = 'neighbourhood_type___DESC',
  school_ids___ASC = 'school_ids___ASC',
  school_ids___DESC = 'school_ids___DESC',
  schools___ASC = 'schools___ASC',
  schools___DESC = 'schools___DESC',
  username___ASC = 'username___ASC',
  username___DESC = 'username___DESC'
}

/** Represents an object of class Family_covid_test, as defined in the model. */
export type Family_Covid_Test = {
  __typename?: 'Family_covid_test';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Family_covid_test. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute antigen_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  antigen_tests?: Maybe<Scalars['Int']>;
  /** The attribute any_positive_antigen_test of Family_covid_test. It is of type Boolean.  */
  any_positive_antigen_test?: Maybe<Scalars['Boolean']>;
  /** The attribute confirmed_by_molecular of Family_covid_test. It is of type Boolean.  */
  confirmed_by_molecular?: Maybe<Scalars['Boolean']>;
  /** The associated object for the role family_weekly_questionnaire_. */
  family_weekly_questionnaire_?: Maybe<Family_Weekly_Questionnaire>;
  /** The attribute gender of Family_covid_test. It is of type String. Path: person_ gender. Cannot be null. */
  gender?: Maybe<Scalars['String']>;
  /** The attribute molecular_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  molecular_tests?: Maybe<Scalars['Int']>;
  /** The associated object for the role person_. */
  person_?: Maybe<Person>;
  /** The associable objects for the role person_. */
  person____associables?: Maybe<PersonPage>;
  /** The attribute person_code of Family_covid_test. It is of type String. Path: person_ code.  */
  person_code?: Maybe<Scalars['String']>;
  /** The attribute year_of_birth of Family_covid_test. It is of type Year. Path: person_ year_of_birth. Cannot be null. */
  year_of_birth?: Maybe<Scalars['Year']>;
};


/** Represents an object of class Family_covid_test, as defined in the model. */
export type Family_Covid_TestPerson____AssociablesArgs = {
  options?: InputMaybe<PersonPageOptions>;
};

/** A data structure for objects of Family_covid_test. */
export type Family_Covid_TestCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute antigen_tests of Family_covid_test. It is of type Int!. Ranges: -1:10. Cannot be null. */
  antigen_tests: Scalars['Int'];
  /** The attribute any_positive_antigen_test of Family_covid_test. It is of type Boolean.  */
  any_positive_antigen_test?: InputMaybe<Scalars['Boolean']>;
  /** The attribute confirmed_by_molecular of Family_covid_test. It is of type Boolean.  */
  confirmed_by_molecular?: InputMaybe<Scalars['Boolean']>;
  /** The attribute molecular_tests of Family_covid_test. It is of type Int!. Ranges: -1:10. Cannot be null. */
  molecular_tests: Scalars['Int'];
  /** The Association role family_covid_test_(0,N) --> (1)person_. */
  person_: Scalars['ID'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Family_covid_test.
 */
export type Family_Covid_TestCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  antigen_tests?: InputMaybe<Scalars['Int']>;
  any_positive_antigen_test?: InputMaybe<Scalars['Boolean']>;
  confirmed_by_molecular?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['String']>;
  molecular_tests?: InputMaybe<Scalars['Int']>;
  person_code?: InputMaybe<Scalars['String']>;
  year_of_birth?: InputMaybe<Scalars['Year']>;
};

/** A data structure for a draft object of class Family_covid_test. */
export type Family_Covid_TestDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute antigen_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  antigen_tests?: InputMaybe<Scalars['Int']>;
  /** The attribute any_positive_antigen_test of Family_covid_test. It is of type Boolean.  */
  any_positive_antigen_test?: InputMaybe<Scalars['Boolean']>;
  /** The attribute confirmed_by_molecular of Family_covid_test. It is of type Boolean.  */
  confirmed_by_molecular?: InputMaybe<Scalars['Boolean']>;
  /** The attribute molecular_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  molecular_tests?: InputMaybe<Scalars['Int']>;
  /** The Association role family_covid_test_(0,N) --> (1)person_. */
  person_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for draft create objects of class Family_covid_test. */
export type Family_Covid_TestDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute antigen_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  antigen_tests?: InputMaybe<Scalars['Int']>;
  /** The attribute any_positive_antigen_test of Family_covid_test. It is of type Boolean.  */
  any_positive_antigen_test?: InputMaybe<Scalars['Boolean']>;
  /** The attribute confirmed_by_molecular of Family_covid_test. It is of type Boolean.  */
  confirmed_by_molecular?: InputMaybe<Scalars['Boolean']>;
  /** The attribute molecular_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  molecular_tests?: InputMaybe<Scalars['Int']>;
  /** The Association role family_covid_test_(0,N) --> (1)person_. */
  person_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft part objects of class Family_covid_test */
export type Family_Covid_TestDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Family_covid_test to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Family_Covid_TestDraft>>>;
};

/** A data structure for a draft update object of class Family_covid_test. */
export type Family_Covid_TestDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute antigen_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  antigen_tests?: InputMaybe<Scalars['Int']>;
  /** The attribute any_positive_antigen_test of Family_covid_test. It is of type Boolean.  */
  any_positive_antigen_test?: InputMaybe<Scalars['Boolean']>;
  /** The attribute confirmed_by_molecular of Family_covid_test. It is of type Boolean.  */
  confirmed_by_molecular?: InputMaybe<Scalars['Boolean']>;
  /** The attribute molecular_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  molecular_tests?: InputMaybe<Scalars['Int']>;
  /** The Association role family_covid_test_(0,N) --> (1)person_. */
  person_?: InputMaybe<PersonRoleRef>;
};

/** A data structure for a draft update objects of class Family_covid_test. */
export type Family_Covid_TestDraftUpdateBulk = {
  /** The attribute antigen_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  antigen_tests?: InputMaybe<Scalars['Int']>;
  /** The attribute any_positive_antigen_test of Family_covid_test. It is of type Boolean.  */
  any_positive_antigen_test?: InputMaybe<Scalars['Boolean']>;
  /** The attribute confirmed_by_molecular of Family_covid_test. It is of type Boolean.  */
  confirmed_by_molecular?: InputMaybe<Scalars['Boolean']>;
  /** The attribute molecular_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  molecular_tests?: InputMaybe<Scalars['Int']>;
  /** The Association role family_covid_test_(0,N) --> (1)person_. */
  person_?: InputMaybe<PersonRoleRef>;
};

/** A data structure for the draft part objects of class Family_covid_test */
export type Family_Covid_TestDraftUpdateRoleObjects = {
  /** An array of objects of class Family_covid_test to be created. */
  create?: InputMaybe<Array<InputMaybe<Family_Covid_TestDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Family_covid_test to be updated. */
  update?: InputMaybe<Array<InputMaybe<Family_Covid_TestDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Family_covid_test.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Family_Covid_TestFilter = {
  AND?: InputMaybe<Array<Family_Covid_TestFilter>>;
  NOT?: InputMaybe<Family_Covid_TestFilter>;
  OR?: InputMaybe<Array<Family_Covid_TestFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  antigen_tests___eq?: InputMaybe<Scalars['Int']>;
  antigen_tests___gt?: InputMaybe<Scalars['Int']>;
  antigen_tests___gte?: InputMaybe<Scalars['Int']>;
  antigen_tests___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  antigen_tests___lt?: InputMaybe<Scalars['Int']>;
  antigen_tests___lte?: InputMaybe<Scalars['Int']>;
  antigen_tests___ne?: InputMaybe<Scalars['Int']>;
  antigen_tests___not___in?: InputMaybe<Array<Scalars['Int']>>;
  antigen_tests___not___null?: InputMaybe<Scalars['Boolean']>;
  antigen_tests___null?: InputMaybe<Scalars['Boolean']>;
  any_positive_antigen_test___eq?: InputMaybe<Scalars['Boolean']>;
  any_positive_antigen_test___ne?: InputMaybe<Scalars['Boolean']>;
  any_positive_antigen_test___not___null?: InputMaybe<Scalars['Boolean']>;
  any_positive_antigen_test___null?: InputMaybe<Scalars['Boolean']>;
  confirmed_by_molecular___eq?: InputMaybe<Scalars['Boolean']>;
  confirmed_by_molecular___ne?: InputMaybe<Scalars['Boolean']>;
  confirmed_by_molecular___not___null?: InputMaybe<Scalars['Boolean']>;
  confirmed_by_molecular___null?: InputMaybe<Scalars['Boolean']>;
  gender___contains?: InputMaybe<Scalars['String']>;
  gender___ends_with?: InputMaybe<Scalars['String']>;
  gender___eq?: InputMaybe<Scalars['String']>;
  gender___gt?: InputMaybe<Scalars['String']>;
  gender___gte?: InputMaybe<Scalars['String']>;
  gender___in?: InputMaybe<Array<Scalars['String']>>;
  gender___lt?: InputMaybe<Scalars['String']>;
  gender___lte?: InputMaybe<Scalars['String']>;
  gender___ne?: InputMaybe<Scalars['String']>;
  gender___not___contains?: InputMaybe<Scalars['String']>;
  gender___not___ends_with?: InputMaybe<Scalars['String']>;
  gender___not___in?: InputMaybe<Array<Scalars['String']>>;
  gender___not___null?: InputMaybe<Scalars['Boolean']>;
  gender___not___starts_with?: InputMaybe<Scalars['String']>;
  gender___null?: InputMaybe<Scalars['Boolean']>;
  gender___starts_with?: InputMaybe<Scalars['String']>;
  molecular_tests___eq?: InputMaybe<Scalars['Int']>;
  molecular_tests___gt?: InputMaybe<Scalars['Int']>;
  molecular_tests___gte?: InputMaybe<Scalars['Int']>;
  molecular_tests___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  molecular_tests___lt?: InputMaybe<Scalars['Int']>;
  molecular_tests___lte?: InputMaybe<Scalars['Int']>;
  molecular_tests___ne?: InputMaybe<Scalars['Int']>;
  molecular_tests___not___in?: InputMaybe<Array<Scalars['Int']>>;
  molecular_tests___not___null?: InputMaybe<Scalars['Boolean']>;
  molecular_tests___null?: InputMaybe<Scalars['Boolean']>;
  person_code___contains?: InputMaybe<Scalars['String']>;
  person_code___ends_with?: InputMaybe<Scalars['String']>;
  person_code___eq?: InputMaybe<Scalars['String']>;
  person_code___gt?: InputMaybe<Scalars['String']>;
  person_code___gte?: InputMaybe<Scalars['String']>;
  person_code___in?: InputMaybe<Array<Scalars['String']>>;
  person_code___lt?: InputMaybe<Scalars['String']>;
  person_code___lte?: InputMaybe<Scalars['String']>;
  person_code___ne?: InputMaybe<Scalars['String']>;
  person_code___not___contains?: InputMaybe<Scalars['String']>;
  person_code___not___ends_with?: InputMaybe<Scalars['String']>;
  person_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  person_code___not___null?: InputMaybe<Scalars['Boolean']>;
  person_code___not___starts_with?: InputMaybe<Scalars['String']>;
  person_code___null?: InputMaybe<Scalars['Boolean']>;
  person_code___starts_with?: InputMaybe<Scalars['String']>;
  year_of_birth___eq?: InputMaybe<Scalars['Year']>;
  year_of_birth___gt?: InputMaybe<Scalars['Year']>;
  year_of_birth___gte?: InputMaybe<Scalars['Year']>;
  year_of_birth___in?: InputMaybe<Array<InputMaybe<Scalars['Year']>>>;
  year_of_birth___lt?: InputMaybe<Scalars['Year']>;
  year_of_birth___lte?: InputMaybe<Scalars['Year']>;
  year_of_birth___ne?: InputMaybe<Scalars['Year']>;
  year_of_birth___not___in?: InputMaybe<Array<Scalars['Year']>>;
  year_of_birth___not___null?: InputMaybe<Scalars['Boolean']>;
  year_of_birth___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Family_covid_test. */
export type Family_Covid_TestPage = {
  __typename?: 'Family_covid_testPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Family_covid_test. */
  items: Array<Family_Covid_Test>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Family_covid_test present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Family_covid_testPage. */
export type Family_Covid_TestPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Family_Covid_TestFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Family_Covid_TestCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Family_Covid_TestSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Family_covid_test */
export type Family_Covid_TestRoleObjects = {
  /** An array of objects of class Family_covid_test to be created. */
  create?: InputMaybe<Array<InputMaybe<Family_Covid_TestCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Family_covid_test to be updated. */
  update?: InputMaybe<Array<InputMaybe<Family_Covid_TestUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Family_Covid_TestSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  antigen_tests___ASC = 'antigen_tests___ASC',
  antigen_tests___DESC = 'antigen_tests___DESC',
  any_positive_antigen_test___ASC = 'any_positive_antigen_test___ASC',
  any_positive_antigen_test___DESC = 'any_positive_antigen_test___DESC',
  confirmed_by_molecular___ASC = 'confirmed_by_molecular___ASC',
  confirmed_by_molecular___DESC = 'confirmed_by_molecular___DESC',
  gender___ASC = 'gender___ASC',
  gender___DESC = 'gender___DESC',
  molecular_tests___ASC = 'molecular_tests___ASC',
  molecular_tests___DESC = 'molecular_tests___DESC',
  person_code___ASC = 'person_code___ASC',
  person_code___DESC = 'person_code___DESC',
  year_of_birth___ASC = 'year_of_birth___ASC',
  year_of_birth___DESC = 'year_of_birth___DESC'
}

/** A data structure for a single object of class Family_covid_test. */
export type Family_Covid_TestUpdate = {
  _id: Scalars['ID'];
  /** The attribute antigen_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  antigen_tests?: InputMaybe<Scalars['Int']>;
  /** The attribute any_positive_antigen_test of Family_covid_test. It is of type Boolean.  */
  any_positive_antigen_test?: InputMaybe<Scalars['Boolean']>;
  /** The attribute confirmed_by_molecular of Family_covid_test. It is of type Boolean.  */
  confirmed_by_molecular?: InputMaybe<Scalars['Boolean']>;
  /** The attribute molecular_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  molecular_tests?: InputMaybe<Scalars['Int']>;
  /** The Association role family_covid_test_(0,N) --> (1)person_. */
  person_?: InputMaybe<PersonRoleRef>;
};

/** A data structure for objects of class Family_covid_test. */
export type Family_Covid_TestUpdateBulk = {
  /** The attribute antigen_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  antigen_tests?: InputMaybe<Scalars['Int']>;
  /** The attribute any_positive_antigen_test of Family_covid_test. It is of type Boolean.  */
  any_positive_antigen_test?: InputMaybe<Scalars['Boolean']>;
  /** The attribute confirmed_by_molecular of Family_covid_test. It is of type Boolean.  */
  confirmed_by_molecular?: InputMaybe<Scalars['Boolean']>;
  /** The attribute molecular_tests of Family_covid_test. It is of type Int. Ranges: -1:10. Cannot be null. */
  molecular_tests?: InputMaybe<Scalars['Int']>;
  /** The Association role family_covid_test_(0,N) --> (1)person_. */
  person_?: InputMaybe<PersonRoleRef>;
};

/** Represents an object of class Family_weekly_questionnaire, as defined in the model. */
export type Family_Weekly_Questionnaire = {
  __typename?: 'Family_weekly_questionnaire';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Family_weekly_questionnaire. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute any_confirmed_by_molecular of Family_weekly_questionnaire. It is of type Boolean. Path: OR - Logical ORfamily_covid_test_ confirmed_by_molecular.  */
  any_confirmed_by_molecular?: Maybe<Scalars['Boolean']>;
  /** The attribute any_positive_antigen_test of Family_weekly_questionnaire. It is of type Boolean. Path: OR - Logical ORfamily_covid_test_ any_positive_antigen_test.  */
  any_positive_antigen_test?: Maybe<Scalars['Boolean']>;
  /** The attribute center of Family_weekly_questionnaire. It is of type String. Path: family_.center_ name. Cannot be null. */
  center?: Maybe<Scalars['String']>;
  /** The attribute covid_tests of Family_weekly_questionnaire. It is of type Boolean.  */
  covid_tests?: Maybe<Scalars['Boolean']>;
  /** The attribute date of Family_weekly_questionnaire. It is of type Date @dateFormat. Cannot be null. */
  date?: Maybe<Scalars['Date']>;
  /** The associated object for the role family_. */
  family_?: Maybe<Family>;
  /** The associable objects for the role family_. */
  family____associables?: Maybe<FamilyPage>;
  /** The associated objects for the role family_covid_test_. */
  family_covid_test_?: Maybe<Family_Covid_TestPage>;
  /** The attribute family_email of Family_weekly_questionnaire. It is of type String. Path: family_ email. Cannot be null. */
  family_email?: Maybe<Scalars['String']>;
  /** The attribute family_schools of Family_weekly_questionnaire. It is of type String. Path: family_ schools. Cannot be null. */
  family_schools?: Maybe<Scalars['String']>;
  /** The associated objects for the role symptom_. */
  symptom_?: Maybe<SymptomPage>;
  /** The associable objects for the role symptom_. */
  symptom____associables?: Maybe<SymptomPage>;
  /** The attribute symptoms of Family_weekly_questionnaire. It is of type Boolean.  */
  symptoms?: Maybe<Scalars['Boolean']>;
  /** The attribute symptoms_ of Family_weekly_questionnaire. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)symptom_ symptom. Cannot be null. */
  symptoms_?: Maybe<Scalars['String']>;
  /** The attribute test of Family_weekly_questionnaire. It is of type Boolean. Path: family_.center_ test.  */
  test?: Maybe<Scalars['Boolean']>;
  /** The attribute tot_antigen of Family_weekly_questionnaire. It is of type Int. Path: SUM - Sumfamily_covid_test_ antigen_tests. Cannot be null. */
  tot_antigen?: Maybe<Scalars['Int']>;
  /** The attribute tot_molecular of Family_weekly_questionnaire. It is of type Int. Path: SUM - Sumfamily_covid_test_ molecular_tests. Cannot be null. */
  tot_molecular?: Maybe<Scalars['Int']>;
  /** The attribute week of Family_weekly_questionnaire. It is of type String. Expression: 'getWeek(date)'. Cannot be null. */
  week?: Maybe<Scalars['String']>;
};


/** Represents an object of class Family_weekly_questionnaire, as defined in the model. */
export type Family_Weekly_QuestionnaireDateArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Family_weekly_questionnaire, as defined in the model. */
export type Family_Weekly_QuestionnaireFamily____AssociablesArgs = {
  options?: InputMaybe<FamilyPageOptions>;
};


/** Represents an object of class Family_weekly_questionnaire, as defined in the model. */
export type Family_Weekly_QuestionnaireFamily_Covid_Test_Args = {
  options?: InputMaybe<Family_Covid_TestPageOptions>;
};


/** Represents an object of class Family_weekly_questionnaire, as defined in the model. */
export type Family_Weekly_QuestionnaireSymptom_Args = {
  options?: InputMaybe<SymptomPageOptions>;
};


/** Represents an object of class Family_weekly_questionnaire, as defined in the model. */
export type Family_Weekly_QuestionnaireSymptom____AssociablesArgs = {
  options?: InputMaybe<SymptomPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Family_weekly_questionnaire.
 */
export type Family_Weekly_QuestionnaireCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  any_confirmed_by_molecular?: InputMaybe<Scalars['Boolean']>;
  any_positive_antigen_test?: InputMaybe<Scalars['Boolean']>;
  center?: InputMaybe<Scalars['String']>;
  covid_tests?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Date']>;
  family_email?: InputMaybe<Scalars['String']>;
  family_schools?: InputMaybe<Scalars['String']>;
  symptoms?: InputMaybe<Scalars['Boolean']>;
  symptoms_?: InputMaybe<Scalars['String']>;
  test?: InputMaybe<Scalars['Boolean']>;
  tot_antigen?: InputMaybe<Scalars['Int']>;
  tot_molecular?: InputMaybe<Scalars['Int']>;
  week?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated objects of class Family_weekly_questionnaire */
export type Family_Weekly_QuestionnaireDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type Family_weekly_questionnaire.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Family_Weekly_QuestionnaireFilter = {
  AND?: InputMaybe<Array<Family_Weekly_QuestionnaireFilter>>;
  NOT?: InputMaybe<Family_Weekly_QuestionnaireFilter>;
  OR?: InputMaybe<Array<Family_Weekly_QuestionnaireFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  any_confirmed_by_molecular___eq?: InputMaybe<Scalars['Boolean']>;
  any_confirmed_by_molecular___ne?: InputMaybe<Scalars['Boolean']>;
  any_confirmed_by_molecular___not___null?: InputMaybe<Scalars['Boolean']>;
  any_confirmed_by_molecular___null?: InputMaybe<Scalars['Boolean']>;
  any_positive_antigen_test___eq?: InputMaybe<Scalars['Boolean']>;
  any_positive_antigen_test___ne?: InputMaybe<Scalars['Boolean']>;
  any_positive_antigen_test___not___null?: InputMaybe<Scalars['Boolean']>;
  any_positive_antigen_test___null?: InputMaybe<Scalars['Boolean']>;
  center___contains?: InputMaybe<Scalars['String']>;
  center___ends_with?: InputMaybe<Scalars['String']>;
  center___eq?: InputMaybe<Scalars['String']>;
  center___gt?: InputMaybe<Scalars['String']>;
  center___gte?: InputMaybe<Scalars['String']>;
  center___in?: InputMaybe<Array<Scalars['String']>>;
  center___lt?: InputMaybe<Scalars['String']>;
  center___lte?: InputMaybe<Scalars['String']>;
  center___ne?: InputMaybe<Scalars['String']>;
  center___not___contains?: InputMaybe<Scalars['String']>;
  center___not___ends_with?: InputMaybe<Scalars['String']>;
  center___not___in?: InputMaybe<Array<Scalars['String']>>;
  center___not___null?: InputMaybe<Scalars['Boolean']>;
  center___not___starts_with?: InputMaybe<Scalars['String']>;
  center___null?: InputMaybe<Scalars['Boolean']>;
  center___starts_with?: InputMaybe<Scalars['String']>;
  covid_tests___eq?: InputMaybe<Scalars['Boolean']>;
  covid_tests___ne?: InputMaybe<Scalars['Boolean']>;
  covid_tests___not___null?: InputMaybe<Scalars['Boolean']>;
  covid_tests___null?: InputMaybe<Scalars['Boolean']>;
  date___eq?: InputMaybe<Scalars['Date']>;
  date___gt?: InputMaybe<Scalars['Date']>;
  date___gte?: InputMaybe<Scalars['Date']>;
  date___in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  date___lt?: InputMaybe<Scalars['Date']>;
  date___lte?: InputMaybe<Scalars['Date']>;
  date___ne?: InputMaybe<Scalars['Date']>;
  date___not___in?: InputMaybe<Array<Scalars['Date']>>;
  date___not___null?: InputMaybe<Scalars['Boolean']>;
  date___null?: InputMaybe<Scalars['Boolean']>;
  family_email___contains?: InputMaybe<Scalars['String']>;
  family_email___ends_with?: InputMaybe<Scalars['String']>;
  family_email___eq?: InputMaybe<Scalars['String']>;
  family_email___gt?: InputMaybe<Scalars['String']>;
  family_email___gte?: InputMaybe<Scalars['String']>;
  family_email___in?: InputMaybe<Array<Scalars['String']>>;
  family_email___lt?: InputMaybe<Scalars['String']>;
  family_email___lte?: InputMaybe<Scalars['String']>;
  family_email___ne?: InputMaybe<Scalars['String']>;
  family_email___not___contains?: InputMaybe<Scalars['String']>;
  family_email___not___ends_with?: InputMaybe<Scalars['String']>;
  family_email___not___in?: InputMaybe<Array<Scalars['String']>>;
  family_email___not___null?: InputMaybe<Scalars['Boolean']>;
  family_email___not___starts_with?: InputMaybe<Scalars['String']>;
  family_email___null?: InputMaybe<Scalars['Boolean']>;
  family_email___starts_with?: InputMaybe<Scalars['String']>;
  family_schools___contains?: InputMaybe<Scalars['String']>;
  family_schools___ends_with?: InputMaybe<Scalars['String']>;
  family_schools___eq?: InputMaybe<Scalars['String']>;
  family_schools___gt?: InputMaybe<Scalars['String']>;
  family_schools___gte?: InputMaybe<Scalars['String']>;
  family_schools___in?: InputMaybe<Array<Scalars['String']>>;
  family_schools___lt?: InputMaybe<Scalars['String']>;
  family_schools___lte?: InputMaybe<Scalars['String']>;
  family_schools___ne?: InputMaybe<Scalars['String']>;
  family_schools___not___contains?: InputMaybe<Scalars['String']>;
  family_schools___not___ends_with?: InputMaybe<Scalars['String']>;
  family_schools___not___in?: InputMaybe<Array<Scalars['String']>>;
  family_schools___not___null?: InputMaybe<Scalars['Boolean']>;
  family_schools___not___starts_with?: InputMaybe<Scalars['String']>;
  family_schools___null?: InputMaybe<Scalars['Boolean']>;
  family_schools___starts_with?: InputMaybe<Scalars['String']>;
  symptoms____contains?: InputMaybe<Scalars['String']>;
  symptoms____ends_with?: InputMaybe<Scalars['String']>;
  symptoms____eq?: InputMaybe<Scalars['String']>;
  symptoms____gt?: InputMaybe<Scalars['String']>;
  symptoms____gte?: InputMaybe<Scalars['String']>;
  symptoms____in?: InputMaybe<Array<Scalars['String']>>;
  symptoms____lt?: InputMaybe<Scalars['String']>;
  symptoms____lte?: InputMaybe<Scalars['String']>;
  symptoms____ne?: InputMaybe<Scalars['String']>;
  symptoms____not___contains?: InputMaybe<Scalars['String']>;
  symptoms____not___ends_with?: InputMaybe<Scalars['String']>;
  symptoms____not___in?: InputMaybe<Array<Scalars['String']>>;
  symptoms____not___null?: InputMaybe<Scalars['Boolean']>;
  symptoms____not___starts_with?: InputMaybe<Scalars['String']>;
  symptoms____null?: InputMaybe<Scalars['Boolean']>;
  symptoms____starts_with?: InputMaybe<Scalars['String']>;
  symptoms___eq?: InputMaybe<Scalars['Boolean']>;
  symptoms___ne?: InputMaybe<Scalars['Boolean']>;
  symptoms___not___null?: InputMaybe<Scalars['Boolean']>;
  symptoms___null?: InputMaybe<Scalars['Boolean']>;
  test___eq?: InputMaybe<Scalars['Boolean']>;
  test___ne?: InputMaybe<Scalars['Boolean']>;
  test___not___null?: InputMaybe<Scalars['Boolean']>;
  test___null?: InputMaybe<Scalars['Boolean']>;
  tot_antigen___eq?: InputMaybe<Scalars['Int']>;
  tot_antigen___gt?: InputMaybe<Scalars['Int']>;
  tot_antigen___gte?: InputMaybe<Scalars['Int']>;
  tot_antigen___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tot_antigen___lt?: InputMaybe<Scalars['Int']>;
  tot_antigen___lte?: InputMaybe<Scalars['Int']>;
  tot_antigen___ne?: InputMaybe<Scalars['Int']>;
  tot_antigen___not___in?: InputMaybe<Array<Scalars['Int']>>;
  tot_antigen___not___null?: InputMaybe<Scalars['Boolean']>;
  tot_antigen___null?: InputMaybe<Scalars['Boolean']>;
  tot_molecular___eq?: InputMaybe<Scalars['Int']>;
  tot_molecular___gt?: InputMaybe<Scalars['Int']>;
  tot_molecular___gte?: InputMaybe<Scalars['Int']>;
  tot_molecular___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tot_molecular___lt?: InputMaybe<Scalars['Int']>;
  tot_molecular___lte?: InputMaybe<Scalars['Int']>;
  tot_molecular___ne?: InputMaybe<Scalars['Int']>;
  tot_molecular___not___in?: InputMaybe<Array<Scalars['Int']>>;
  tot_molecular___not___null?: InputMaybe<Scalars['Boolean']>;
  tot_molecular___null?: InputMaybe<Scalars['Boolean']>;
  week___contains?: InputMaybe<Scalars['String']>;
  week___ends_with?: InputMaybe<Scalars['String']>;
  week___eq?: InputMaybe<Scalars['String']>;
  week___gt?: InputMaybe<Scalars['String']>;
  week___gte?: InputMaybe<Scalars['String']>;
  week___in?: InputMaybe<Array<Scalars['String']>>;
  week___lt?: InputMaybe<Scalars['String']>;
  week___lte?: InputMaybe<Scalars['String']>;
  week___ne?: InputMaybe<Scalars['String']>;
  week___not___contains?: InputMaybe<Scalars['String']>;
  week___not___ends_with?: InputMaybe<Scalars['String']>;
  week___not___in?: InputMaybe<Array<Scalars['String']>>;
  week___not___null?: InputMaybe<Scalars['Boolean']>;
  week___not___starts_with?: InputMaybe<Scalars['String']>;
  week___null?: InputMaybe<Scalars['Boolean']>;
  week___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Family_weekly_questionnaire. */
export type Family_Weekly_QuestionnairePage = {
  __typename?: 'Family_weekly_questionnairePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Family_weekly_questionnaire. */
  items: Array<Family_Weekly_Questionnaire>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Family_weekly_questionnaire present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Family_weekly_questionnairePage. */
export type Family_Weekly_QuestionnairePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Family_Weekly_QuestionnaireFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Family_Weekly_QuestionnaireCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Family_Weekly_QuestionnaireSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated objects of class Family_weekly_questionnaire */
export type Family_Weekly_QuestionnaireRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Family_Weekly_QuestionnaireSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  any_confirmed_by_molecular___ASC = 'any_confirmed_by_molecular___ASC',
  any_confirmed_by_molecular___DESC = 'any_confirmed_by_molecular___DESC',
  any_positive_antigen_test___ASC = 'any_positive_antigen_test___ASC',
  any_positive_antigen_test___DESC = 'any_positive_antigen_test___DESC',
  center___ASC = 'center___ASC',
  center___DESC = 'center___DESC',
  covid_tests___ASC = 'covid_tests___ASC',
  covid_tests___DESC = 'covid_tests___DESC',
  date___ASC = 'date___ASC',
  date___DESC = 'date___DESC',
  family_email___ASC = 'family_email___ASC',
  family_email___DESC = 'family_email___DESC',
  family_schools___ASC = 'family_schools___ASC',
  family_schools___DESC = 'family_schools___DESC',
  symptoms___ASC = 'symptoms___ASC',
  symptoms___DESC = 'symptoms___DESC',
  symptoms____ASC = 'symptoms____ASC',
  symptoms____DESC = 'symptoms____DESC',
  test___ASC = 'test___ASC',
  test___DESC = 'test___DESC',
  tot_antigen___ASC = 'tot_antigen___ASC',
  tot_antigen___DESC = 'tot_antigen___DESC',
  tot_molecular___ASC = 'tot_molecular___ASC',
  tot_molecular___DESC = 'tot_molecular___DESC',
  week___ASC = 'week___ASC',
  week___DESC = 'week___DESC'
}

export type FileInfo = {
  __typename?: 'FileInfo';
  fileId: Scalars['ID'];
  link: Scalars['String'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['Int'];
  uploadedOn: Scalars['Datetime'];
};


export type FileInfoUploadedOnArgs = {
  format?: InputMaybe<Scalars['String']>;
};

/** Options to configure the list of Issue (only for issueLevel=WARNING) to be returned by a mutation service. */
export type ForceWarnings = {
  actionVeto?: InputMaybe<Scalars['Boolean']>;
  dataValidation?: InputMaybe<Scalars['Boolean']>;
};

export type FormActionHandlerMessageResult = {
  __typename?: 'FormActionHandlerMessageResult';
  message: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: MessageType;
};

export type FormActionHandlerResult = {
  __typename?: 'FormActionHandlerResult';
  message?: Maybe<FormActionHandlerMessageResult>;
  type: FormActionHandlerResultType;
};

export enum FormActionHandlerResultType {
  MESSAGE = 'MESSAGE',
  NONE = 'NONE'
}

/** Represents an object of class Gender, as defined in the model. */
export type Gender = {
  __typename?: 'Gender';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Gender. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute code of Gender. It is of type String. Max length: 255. Cannot be null. */
  code?: Maybe<Scalars['String']>;
  /** The attribute name of Gender. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Gender.
 */
export type GenderCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class Gender */
export type GenderDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Gender.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type GenderFilter = {
  AND?: InputMaybe<Array<GenderFilter>>;
  NOT?: InputMaybe<GenderFilter>;
  OR?: InputMaybe<Array<GenderFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Gender. */
export type GenderPage = {
  __typename?: 'GenderPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Gender. */
  items: Array<Gender>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Gender present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a GenderPage. */
export type GenderPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<GenderFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<GenderCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<GenderSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Gender */
export type GenderRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum GenderSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC'
}

/** Represents an object of class Hospital, as defined in the model. */
export type Hospital = {
  __typename?: 'Hospital';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Hospital. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute address of Hospital. It is of type String. Path: address_ description. Cannot be null. */
  address?: Maybe<Scalars['String']>;
  /** The associated object for the role address_. */
  address_?: Maybe<Address>;
  /** The attribute center of Hospital. It is of type String. Path: center_ name. Cannot be null. */
  center?: Maybe<Scalars['String']>;
  /** The associated object for the role center_. */
  center_?: Maybe<Center>;
  /** The associable objects for the role center_. */
  center____associables?: Maybe<CenterPage>;
  /** The attribute code of Hospital. It is of type String. Max length: 255. Cannot be null. */
  code?: Maybe<Scalars['String']>;
  /** The associated objects for the role laboratory_. */
  laboratory_?: Maybe<LaboratoryPage>;
  /** The associable objects for the role laboratory_. */
  laboratory____associables?: Maybe<LaboratoryPage>;
  /** The attribute name of Hospital. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
};


/** Represents an object of class Hospital, as defined in the model. */
export type HospitalCenter____AssociablesArgs = {
  options?: InputMaybe<CenterPageOptions>;
};


/** Represents an object of class Hospital, as defined in the model. */
export type HospitalLaboratory_Args = {
  options?: InputMaybe<LaboratoryPageOptions>;
};


/** Represents an object of class Hospital, as defined in the model. */
export type HospitalLaboratory____AssociablesArgs = {
  options?: InputMaybe<LaboratoryPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Hospital.
 */
export type HospitalCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  address?: InputMaybe<Scalars['String']>;
  center?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class Hospital */
export type HospitalDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft associated objects of class Hospital */
export type HospitalDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type Hospital.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type HospitalFilter = {
  AND?: InputMaybe<Array<HospitalFilter>>;
  NOT?: InputMaybe<HospitalFilter>;
  OR?: InputMaybe<Array<HospitalFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  address___contains?: InputMaybe<Scalars['String']>;
  address___ends_with?: InputMaybe<Scalars['String']>;
  address___eq?: InputMaybe<Scalars['String']>;
  address___gt?: InputMaybe<Scalars['String']>;
  address___gte?: InputMaybe<Scalars['String']>;
  address___in?: InputMaybe<Array<Scalars['String']>>;
  address___lt?: InputMaybe<Scalars['String']>;
  address___lte?: InputMaybe<Scalars['String']>;
  address___ne?: InputMaybe<Scalars['String']>;
  address___not___contains?: InputMaybe<Scalars['String']>;
  address___not___ends_with?: InputMaybe<Scalars['String']>;
  address___not___in?: InputMaybe<Array<Scalars['String']>>;
  address___not___null?: InputMaybe<Scalars['Boolean']>;
  address___not___starts_with?: InputMaybe<Scalars['String']>;
  address___null?: InputMaybe<Scalars['Boolean']>;
  address___starts_with?: InputMaybe<Scalars['String']>;
  center___contains?: InputMaybe<Scalars['String']>;
  center___ends_with?: InputMaybe<Scalars['String']>;
  center___eq?: InputMaybe<Scalars['String']>;
  center___gt?: InputMaybe<Scalars['String']>;
  center___gte?: InputMaybe<Scalars['String']>;
  center___in?: InputMaybe<Array<Scalars['String']>>;
  center___lt?: InputMaybe<Scalars['String']>;
  center___lte?: InputMaybe<Scalars['String']>;
  center___ne?: InputMaybe<Scalars['String']>;
  center___not___contains?: InputMaybe<Scalars['String']>;
  center___not___ends_with?: InputMaybe<Scalars['String']>;
  center___not___in?: InputMaybe<Array<Scalars['String']>>;
  center___not___null?: InputMaybe<Scalars['Boolean']>;
  center___not___starts_with?: InputMaybe<Scalars['String']>;
  center___null?: InputMaybe<Scalars['Boolean']>;
  center___starts_with?: InputMaybe<Scalars['String']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Hospital. */
export type HospitalPage = {
  __typename?: 'HospitalPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Hospital. */
  items: Array<Hospital>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Hospital present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a HospitalPage. */
export type HospitalPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<HospitalFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<HospitalCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<HospitalSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Hospital */
export type HospitalRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the associated objects of class Hospital */
export type HospitalRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum HospitalSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  address___ASC = 'address___ASC',
  address___DESC = 'address___DESC',
  center___ASC = 'center___ASC',
  center___DESC = 'center___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC'
}

/**
 * The InsightType enum type is used to enable insight of the process of data retrieval.
 * This is useful for example to get the execution time of the query resolved by the server.
 * Use only in debug to identify possible bottlenecks.
 */
export enum InsightType {
  /**
   * Enable a full insight, that returns all the information about the executed queries.
   * Can add a significant overhead due to the quantity of data retrieved.
   * Include all the informations of LIGHT, alongside the SQL queries
   * actually executed and their EXPLAIN.
   */
  FULL = 'FULL',
  /**
   * Enable a light insight, that doesn't add a big overhead on performances
   * but its information can be incomplete.
   * The returned informations include the number of queries, their configuration,
   * and the times needed to perform them.
   */
  LIGHT = 'LIGHT'
}

/** Represents an object of class Instrumental_examination, as defined in the model. */
export type Instrumental_Examination = {
  __typename?: 'Instrumental_examination';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Instrumental_examination. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute date of Instrumental_examination. It is of type Date @dateFormat.  */
  date?: Maybe<Scalars['Date']>;
  /** The attribute echo of Instrumental_examination. It is of type Boolean. Path: examination_type_ echo.  */
  echo?: Maybe<Scalars['Boolean']>;
  /** The attribute echo_ejection_fraction of Instrumental_examination. It is of type Real @realFormat.  */
  echo_ejection_fraction?: Maybe<Scalars['Real']>;
  /** The attribute echo_kinesis_abnormalities of Instrumental_examination. It is of type Boolean.  */
  echo_kinesis_abnormalities?: Maybe<Scalars['Boolean']>;
  /** The associated objects for the role examination_diagnosis_. */
  examination_diagnosis_?: Maybe<Examination_DiagnosisPage>;
  /** The associable objects for the role examination_diagnosis_. */
  examination_diagnosis____associables?: Maybe<Examination_DiagnosisPage>;
  /** The associated object for the role examination_type_. */
  examination_type_?: Maybe<Examination_Type>;
  /** The associable objects for the role examination_type_. */
  examination_type____associables?: Maybe<Examination_TypePage>;
  /** The attribute notes of Instrumental_examination. It is of type Text.  */
  notes?: Maybe<Scalars['Text']>;
  /** The attribute pathological of Instrumental_examination. It is of type Boolean.  */
  pathological?: Maybe<Scalars['Boolean']>;
  /** The associated object for the role patient_. */
  patient_?: Maybe<Patient>;
  /** The attribute rehab of Instrumental_examination. It is of type Boolean. Path: examination_type_ rehab.  */
  rehab?: Maybe<Scalars['Boolean']>;
  /** The attribute rehab_physiotherapy_prescription of Instrumental_examination. It is of type Boolean.  */
  rehab_physiotherapy_prescription?: Maybe<Scalars['Boolean']>;
  /** The attribute spiro of Instrumental_examination. It is of type Boolean. Path: examination_type_ spiro.  */
  spiro?: Maybe<Scalars['Boolean']>;
  /** The attribute spiro_fcv of Instrumental_examination. It is of type Real @realFormat.  */
  spiro_fcv?: Maybe<Scalars['Real']>;
  /** The attribute spiro_tcl of Instrumental_examination. It is of type Real @realFormat.  */
  spiro_tcl?: Maybe<Scalars['Real']>;
  /** The attribute spiro_vems of Instrumental_examination. It is of type Real @realFormat.  */
  spiro_vems?: Maybe<Scalars['Real']>;
};


/** Represents an object of class Instrumental_examination, as defined in the model. */
export type Instrumental_ExaminationDateArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Instrumental_examination, as defined in the model. */
export type Instrumental_ExaminationEcho_Ejection_FractionArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Instrumental_examination, as defined in the model. */
export type Instrumental_ExaminationExamination_Diagnosis_Args = {
  options?: InputMaybe<Examination_DiagnosisPageOptions>;
};


/** Represents an object of class Instrumental_examination, as defined in the model. */
export type Instrumental_ExaminationExamination_Diagnosis____AssociablesArgs = {
  options?: InputMaybe<Examination_DiagnosisPageOptions>;
};


/** Represents an object of class Instrumental_examination, as defined in the model. */
export type Instrumental_ExaminationExamination_Type____AssociablesArgs = {
  options?: InputMaybe<Examination_TypePageOptions>;
};


/** Represents an object of class Instrumental_examination, as defined in the model. */
export type Instrumental_ExaminationSpiro_FcvArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Instrumental_examination, as defined in the model. */
export type Instrumental_ExaminationSpiro_TclArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Instrumental_examination, as defined in the model. */
export type Instrumental_ExaminationSpiro_VemsArgs = {
  format?: InputMaybe<Scalars['String']>;
};

/** A data structure for objects of Instrumental_examination. */
export type Instrumental_ExaminationCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute date of Instrumental_examination. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /** The attribute echo_ejection_fraction of Instrumental_examination. It is of type Real.  */
  echo_ejection_fraction?: InputMaybe<Scalars['Real']>;
  /** The attribute echo_kinesis_abnormalities of Instrumental_examination. It is of type Boolean.  */
  echo_kinesis_abnormalities?: InputMaybe<Scalars['Boolean']>;
  /** The Association role instrumental_examination_(0,N) --> (0,N)examination_diagnosis_. */
  examination_diagnosis_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role instrumental_examination_(0,N) --> (1)examination_type_. */
  examination_type_: Scalars['ID'];
  /** The attribute notes of Instrumental_examination. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute pathological of Instrumental_examination. It is of type Boolean.  */
  pathological?: InputMaybe<Scalars['Boolean']>;
  /** The attribute rehab_physiotherapy_prescription of Instrumental_examination. It is of type Boolean.  */
  rehab_physiotherapy_prescription?: InputMaybe<Scalars['Boolean']>;
  /** The attribute spiro_fcv of Instrumental_examination. It is of type Real.  */
  spiro_fcv?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_tcl of Instrumental_examination. It is of type Real.  */
  spiro_tcl?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_vems of Instrumental_examination. It is of type Real.  */
  spiro_vems?: InputMaybe<Scalars['Real']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Instrumental_examination.
 */
export type Instrumental_ExaminationCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
  echo?: InputMaybe<Scalars['Boolean']>;
  echo_ejection_fraction?: InputMaybe<Scalars['Real']>;
  echo_kinesis_abnormalities?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['Text']>;
  pathological?: InputMaybe<Scalars['Boolean']>;
  rehab?: InputMaybe<Scalars['Boolean']>;
  rehab_physiotherapy_prescription?: InputMaybe<Scalars['Boolean']>;
  spiro?: InputMaybe<Scalars['Boolean']>;
  spiro_fcv?: InputMaybe<Scalars['Real']>;
  spiro_tcl?: InputMaybe<Scalars['Real']>;
  spiro_vems?: InputMaybe<Scalars['Real']>;
};

/** A data structure for a draft object of class Instrumental_examination. */
export type Instrumental_ExaminationDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute date of Instrumental_examination. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /** The attribute echo_ejection_fraction of Instrumental_examination. It is of type Real.  */
  echo_ejection_fraction?: InputMaybe<Scalars['Real']>;
  /** The attribute echo_kinesis_abnormalities of Instrumental_examination. It is of type Boolean.  */
  echo_kinesis_abnormalities?: InputMaybe<Scalars['Boolean']>;
  /** The Association role instrumental_examination_(0,N) --> (0,N)examination_diagnosis_. */
  examination_diagnosis_?: InputMaybe<Examination_DiagnosisDraftRoleRefs>;
  /** The Association role instrumental_examination_(0,N) --> (1)examination_type_. */
  examination_type_?: InputMaybe<Scalars['ID']>;
  /** The attribute notes of Instrumental_examination. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute pathological of Instrumental_examination. It is of type Boolean.  */
  pathological?: InputMaybe<Scalars['Boolean']>;
  /** The attribute rehab_physiotherapy_prescription of Instrumental_examination. It is of type Boolean.  */
  rehab_physiotherapy_prescription?: InputMaybe<Scalars['Boolean']>;
  /** The attribute spiro_fcv of Instrumental_examination. It is of type Real.  */
  spiro_fcv?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_tcl of Instrumental_examination. It is of type Real.  */
  spiro_tcl?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_vems of Instrumental_examination. It is of type Real.  */
  spiro_vems?: InputMaybe<Scalars['Real']>;
};

/** A data structure for draft create objects of class Instrumental_examination. */
export type Instrumental_ExaminationDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute date of Instrumental_examination. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /** The attribute echo_ejection_fraction of Instrumental_examination. It is of type Real.  */
  echo_ejection_fraction?: InputMaybe<Scalars['Real']>;
  /** The attribute echo_kinesis_abnormalities of Instrumental_examination. It is of type Boolean.  */
  echo_kinesis_abnormalities?: InputMaybe<Scalars['Boolean']>;
  /** The Association role instrumental_examination_(0,N) --> (0,N)examination_diagnosis_. */
  examination_diagnosis_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role instrumental_examination_(0,N) --> (1)examination_type_. */
  examination_type_?: InputMaybe<Scalars['ID']>;
  /** The attribute notes of Instrumental_examination. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute pathological of Instrumental_examination. It is of type Boolean.  */
  pathological?: InputMaybe<Scalars['Boolean']>;
  /** The attribute rehab_physiotherapy_prescription of Instrumental_examination. It is of type Boolean.  */
  rehab_physiotherapy_prescription?: InputMaybe<Scalars['Boolean']>;
  /** The attribute spiro_fcv of Instrumental_examination. It is of type Real.  */
  spiro_fcv?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_tcl of Instrumental_examination. It is of type Real.  */
  spiro_tcl?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_vems of Instrumental_examination. It is of type Real.  */
  spiro_vems?: InputMaybe<Scalars['Real']>;
};

/** A data structure for the draft part objects of class Instrumental_examination */
export type Instrumental_ExaminationDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Instrumental_examination to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Instrumental_ExaminationDraft>>>;
};

/** A data structure for a draft update object of class Instrumental_examination. */
export type Instrumental_ExaminationDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute date of Instrumental_examination. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /** The attribute echo_ejection_fraction of Instrumental_examination. It is of type Real.  */
  echo_ejection_fraction?: InputMaybe<Scalars['Real']>;
  /** The attribute echo_kinesis_abnormalities of Instrumental_examination. It is of type Boolean.  */
  echo_kinesis_abnormalities?: InputMaybe<Scalars['Boolean']>;
  /** The Association role instrumental_examination_(0,N) --> (0,N)examination_diagnosis_. */
  examination_diagnosis_?: InputMaybe<Examination_DiagnosisRoleRefs>;
  /** The Association role instrumental_examination_(0,N) --> (1)examination_type_. */
  examination_type_?: InputMaybe<Examination_TypeRoleRef>;
  /** The attribute notes of Instrumental_examination. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute pathological of Instrumental_examination. It is of type Boolean.  */
  pathological?: InputMaybe<Scalars['Boolean']>;
  /** The attribute rehab_physiotherapy_prescription of Instrumental_examination. It is of type Boolean.  */
  rehab_physiotherapy_prescription?: InputMaybe<Scalars['Boolean']>;
  /** The attribute spiro_fcv of Instrumental_examination. It is of type Real.  */
  spiro_fcv?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_tcl of Instrumental_examination. It is of type Real.  */
  spiro_tcl?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_vems of Instrumental_examination. It is of type Real.  */
  spiro_vems?: InputMaybe<Scalars['Real']>;
};

/** A data structure for a draft update objects of class Instrumental_examination. */
export type Instrumental_ExaminationDraftUpdateBulk = {
  /** The attribute date of Instrumental_examination. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /** The attribute echo_ejection_fraction of Instrumental_examination. It is of type Real.  */
  echo_ejection_fraction?: InputMaybe<Scalars['Real']>;
  /** The attribute echo_kinesis_abnormalities of Instrumental_examination. It is of type Boolean.  */
  echo_kinesis_abnormalities?: InputMaybe<Scalars['Boolean']>;
  /** The Association role instrumental_examination_(0,N) --> (0,N)examination_diagnosis_. */
  examination_diagnosis_?: InputMaybe<Examination_DiagnosisRoleRefs>;
  /** The Association role instrumental_examination_(0,N) --> (1)examination_type_. */
  examination_type_?: InputMaybe<Examination_TypeRoleRef>;
  /** The attribute notes of Instrumental_examination. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute pathological of Instrumental_examination. It is of type Boolean.  */
  pathological?: InputMaybe<Scalars['Boolean']>;
  /** The attribute rehab_physiotherapy_prescription of Instrumental_examination. It is of type Boolean.  */
  rehab_physiotherapy_prescription?: InputMaybe<Scalars['Boolean']>;
  /** The attribute spiro_fcv of Instrumental_examination. It is of type Real.  */
  spiro_fcv?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_tcl of Instrumental_examination. It is of type Real.  */
  spiro_tcl?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_vems of Instrumental_examination. It is of type Real.  */
  spiro_vems?: InputMaybe<Scalars['Real']>;
};

/** A data structure for the draft part objects of class Instrumental_examination */
export type Instrumental_ExaminationDraftUpdateRoleObjects = {
  /** An array of objects of class Instrumental_examination to be created. */
  create?: InputMaybe<Array<InputMaybe<Instrumental_ExaminationDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Instrumental_examination to be updated. */
  update?: InputMaybe<Array<InputMaybe<Instrumental_ExaminationDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Instrumental_examination.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Instrumental_ExaminationFilter = {
  AND?: InputMaybe<Array<Instrumental_ExaminationFilter>>;
  NOT?: InputMaybe<Instrumental_ExaminationFilter>;
  OR?: InputMaybe<Array<Instrumental_ExaminationFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  date___eq?: InputMaybe<Scalars['Date']>;
  date___gt?: InputMaybe<Scalars['Date']>;
  date___gte?: InputMaybe<Scalars['Date']>;
  date___in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  date___lt?: InputMaybe<Scalars['Date']>;
  date___lte?: InputMaybe<Scalars['Date']>;
  date___ne?: InputMaybe<Scalars['Date']>;
  date___not___in?: InputMaybe<Array<Scalars['Date']>>;
  date___not___null?: InputMaybe<Scalars['Boolean']>;
  date___null?: InputMaybe<Scalars['Boolean']>;
  echo___eq?: InputMaybe<Scalars['Boolean']>;
  echo___ne?: InputMaybe<Scalars['Boolean']>;
  echo___not___null?: InputMaybe<Scalars['Boolean']>;
  echo___null?: InputMaybe<Scalars['Boolean']>;
  echo_ejection_fraction___eq?: InputMaybe<Scalars['Real']>;
  echo_ejection_fraction___gt?: InputMaybe<Scalars['Real']>;
  echo_ejection_fraction___gte?: InputMaybe<Scalars['Real']>;
  echo_ejection_fraction___in?: InputMaybe<Array<InputMaybe<Scalars['Real']>>>;
  echo_ejection_fraction___lt?: InputMaybe<Scalars['Real']>;
  echo_ejection_fraction___lte?: InputMaybe<Scalars['Real']>;
  echo_ejection_fraction___ne?: InputMaybe<Scalars['Real']>;
  echo_ejection_fraction___not___in?: InputMaybe<Array<Scalars['Real']>>;
  echo_ejection_fraction___not___null?: InputMaybe<Scalars['Boolean']>;
  echo_ejection_fraction___null?: InputMaybe<Scalars['Boolean']>;
  echo_kinesis_abnormalities___eq?: InputMaybe<Scalars['Boolean']>;
  echo_kinesis_abnormalities___ne?: InputMaybe<Scalars['Boolean']>;
  echo_kinesis_abnormalities___not___null?: InputMaybe<Scalars['Boolean']>;
  echo_kinesis_abnormalities___null?: InputMaybe<Scalars['Boolean']>;
  notes___contains?: InputMaybe<Scalars['Text']>;
  notes___ends_with?: InputMaybe<Scalars['Text']>;
  notes___eq?: InputMaybe<Scalars['Text']>;
  notes___gt?: InputMaybe<Scalars['Text']>;
  notes___gte?: InputMaybe<Scalars['Text']>;
  notes___in?: InputMaybe<Array<Scalars['Text']>>;
  notes___lt?: InputMaybe<Scalars['Text']>;
  notes___lte?: InputMaybe<Scalars['Text']>;
  notes___ne?: InputMaybe<Scalars['Text']>;
  notes___not___contains?: InputMaybe<Scalars['Text']>;
  notes___not___ends_with?: InputMaybe<Scalars['Text']>;
  notes___not___in?: InputMaybe<Array<Scalars['Text']>>;
  notes___not___null?: InputMaybe<Scalars['Boolean']>;
  notes___not___starts_with?: InputMaybe<Scalars['Text']>;
  notes___null?: InputMaybe<Scalars['Boolean']>;
  notes___starts_with?: InputMaybe<Scalars['Text']>;
  pathological___eq?: InputMaybe<Scalars['Boolean']>;
  pathological___ne?: InputMaybe<Scalars['Boolean']>;
  pathological___not___null?: InputMaybe<Scalars['Boolean']>;
  pathological___null?: InputMaybe<Scalars['Boolean']>;
  rehab___eq?: InputMaybe<Scalars['Boolean']>;
  rehab___ne?: InputMaybe<Scalars['Boolean']>;
  rehab___not___null?: InputMaybe<Scalars['Boolean']>;
  rehab___null?: InputMaybe<Scalars['Boolean']>;
  rehab_physiotherapy_prescription___eq?: InputMaybe<Scalars['Boolean']>;
  rehab_physiotherapy_prescription___ne?: InputMaybe<Scalars['Boolean']>;
  rehab_physiotherapy_prescription___not___null?: InputMaybe<Scalars['Boolean']>;
  rehab_physiotherapy_prescription___null?: InputMaybe<Scalars['Boolean']>;
  spiro___eq?: InputMaybe<Scalars['Boolean']>;
  spiro___ne?: InputMaybe<Scalars['Boolean']>;
  spiro___not___null?: InputMaybe<Scalars['Boolean']>;
  spiro___null?: InputMaybe<Scalars['Boolean']>;
  spiro_fcv___eq?: InputMaybe<Scalars['Real']>;
  spiro_fcv___gt?: InputMaybe<Scalars['Real']>;
  spiro_fcv___gte?: InputMaybe<Scalars['Real']>;
  spiro_fcv___in?: InputMaybe<Array<InputMaybe<Scalars['Real']>>>;
  spiro_fcv___lt?: InputMaybe<Scalars['Real']>;
  spiro_fcv___lte?: InputMaybe<Scalars['Real']>;
  spiro_fcv___ne?: InputMaybe<Scalars['Real']>;
  spiro_fcv___not___in?: InputMaybe<Array<Scalars['Real']>>;
  spiro_fcv___not___null?: InputMaybe<Scalars['Boolean']>;
  spiro_fcv___null?: InputMaybe<Scalars['Boolean']>;
  spiro_tcl___eq?: InputMaybe<Scalars['Real']>;
  spiro_tcl___gt?: InputMaybe<Scalars['Real']>;
  spiro_tcl___gte?: InputMaybe<Scalars['Real']>;
  spiro_tcl___in?: InputMaybe<Array<InputMaybe<Scalars['Real']>>>;
  spiro_tcl___lt?: InputMaybe<Scalars['Real']>;
  spiro_tcl___lte?: InputMaybe<Scalars['Real']>;
  spiro_tcl___ne?: InputMaybe<Scalars['Real']>;
  spiro_tcl___not___in?: InputMaybe<Array<Scalars['Real']>>;
  spiro_tcl___not___null?: InputMaybe<Scalars['Boolean']>;
  spiro_tcl___null?: InputMaybe<Scalars['Boolean']>;
  spiro_vems___eq?: InputMaybe<Scalars['Real']>;
  spiro_vems___gt?: InputMaybe<Scalars['Real']>;
  spiro_vems___gte?: InputMaybe<Scalars['Real']>;
  spiro_vems___in?: InputMaybe<Array<InputMaybe<Scalars['Real']>>>;
  spiro_vems___lt?: InputMaybe<Scalars['Real']>;
  spiro_vems___lte?: InputMaybe<Scalars['Real']>;
  spiro_vems___ne?: InputMaybe<Scalars['Real']>;
  spiro_vems___not___in?: InputMaybe<Array<Scalars['Real']>>;
  spiro_vems___not___null?: InputMaybe<Scalars['Boolean']>;
  spiro_vems___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Instrumental_examination. */
export type Instrumental_ExaminationPage = {
  __typename?: 'Instrumental_examinationPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Instrumental_examination. */
  items: Array<Instrumental_Examination>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Instrumental_examination present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Instrumental_examinationPage. */
export type Instrumental_ExaminationPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Instrumental_ExaminationFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Instrumental_ExaminationCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Instrumental_ExaminationSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Instrumental_examination */
export type Instrumental_ExaminationRoleObjects = {
  /** An array of objects of class Instrumental_examination to be created. */
  create?: InputMaybe<Array<InputMaybe<Instrumental_ExaminationCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Instrumental_examination to be updated. */
  update?: InputMaybe<Array<InputMaybe<Instrumental_ExaminationUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Instrumental_ExaminationSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  date___ASC = 'date___ASC',
  date___DESC = 'date___DESC',
  echo___ASC = 'echo___ASC',
  echo___DESC = 'echo___DESC',
  echo_ejection_fraction___ASC = 'echo_ejection_fraction___ASC',
  echo_ejection_fraction___DESC = 'echo_ejection_fraction___DESC',
  echo_kinesis_abnormalities___ASC = 'echo_kinesis_abnormalities___ASC',
  echo_kinesis_abnormalities___DESC = 'echo_kinesis_abnormalities___DESC',
  notes___ASC = 'notes___ASC',
  notes___DESC = 'notes___DESC',
  pathological___ASC = 'pathological___ASC',
  pathological___DESC = 'pathological___DESC',
  rehab___ASC = 'rehab___ASC',
  rehab___DESC = 'rehab___DESC',
  rehab_physiotherapy_prescription___ASC = 'rehab_physiotherapy_prescription___ASC',
  rehab_physiotherapy_prescription___DESC = 'rehab_physiotherapy_prescription___DESC',
  spiro___ASC = 'spiro___ASC',
  spiro___DESC = 'spiro___DESC',
  spiro_fcv___ASC = 'spiro_fcv___ASC',
  spiro_fcv___DESC = 'spiro_fcv___DESC',
  spiro_tcl___ASC = 'spiro_tcl___ASC',
  spiro_tcl___DESC = 'spiro_tcl___DESC',
  spiro_vems___ASC = 'spiro_vems___ASC',
  spiro_vems___DESC = 'spiro_vems___DESC'
}

/** A data structure for a single object of class Instrumental_examination. */
export type Instrumental_ExaminationUpdate = {
  _id: Scalars['ID'];
  /** The attribute date of Instrumental_examination. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /** The attribute echo_ejection_fraction of Instrumental_examination. It is of type Real.  */
  echo_ejection_fraction?: InputMaybe<Scalars['Real']>;
  /** The attribute echo_kinesis_abnormalities of Instrumental_examination. It is of type Boolean.  */
  echo_kinesis_abnormalities?: InputMaybe<Scalars['Boolean']>;
  /** The Association role instrumental_examination_(0,N) --> (0,N)examination_diagnosis_. */
  examination_diagnosis_?: InputMaybe<Examination_DiagnosisRoleRefs>;
  /** The Association role instrumental_examination_(0,N) --> (1)examination_type_. */
  examination_type_?: InputMaybe<Examination_TypeRoleRef>;
  /** The attribute notes of Instrumental_examination. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute pathological of Instrumental_examination. It is of type Boolean.  */
  pathological?: InputMaybe<Scalars['Boolean']>;
  /** The attribute rehab_physiotherapy_prescription of Instrumental_examination. It is of type Boolean.  */
  rehab_physiotherapy_prescription?: InputMaybe<Scalars['Boolean']>;
  /** The attribute spiro_fcv of Instrumental_examination. It is of type Real.  */
  spiro_fcv?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_tcl of Instrumental_examination. It is of type Real.  */
  spiro_tcl?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_vems of Instrumental_examination. It is of type Real.  */
  spiro_vems?: InputMaybe<Scalars['Real']>;
};

/** A data structure for objects of class Instrumental_examination. */
export type Instrumental_ExaminationUpdateBulk = {
  /** The attribute date of Instrumental_examination. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /** The attribute echo_ejection_fraction of Instrumental_examination. It is of type Real.  */
  echo_ejection_fraction?: InputMaybe<Scalars['Real']>;
  /** The attribute echo_kinesis_abnormalities of Instrumental_examination. It is of type Boolean.  */
  echo_kinesis_abnormalities?: InputMaybe<Scalars['Boolean']>;
  /** The Association role instrumental_examination_(0,N) --> (0,N)examination_diagnosis_. */
  examination_diagnosis_?: InputMaybe<Examination_DiagnosisRoleRefs>;
  /** The Association role instrumental_examination_(0,N) --> (1)examination_type_. */
  examination_type_?: InputMaybe<Examination_TypeRoleRef>;
  /** The attribute notes of Instrumental_examination. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute pathological of Instrumental_examination. It is of type Boolean.  */
  pathological?: InputMaybe<Scalars['Boolean']>;
  /** The attribute rehab_physiotherapy_prescription of Instrumental_examination. It is of type Boolean.  */
  rehab_physiotherapy_prescription?: InputMaybe<Scalars['Boolean']>;
  /** The attribute spiro_fcv of Instrumental_examination. It is of type Real.  */
  spiro_fcv?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_tcl of Instrumental_examination. It is of type Real.  */
  spiro_tcl?: InputMaybe<Scalars['Real']>;
  /** The attribute spiro_vems of Instrumental_examination. It is of type Real.  */
  spiro_vems?: InputMaybe<Scalars['Real']>;
};

export type Issue = {
  __typename?: 'Issue';
  _clientId?: Maybe<Scalars['ID']>;
  applicationName: Scalars['String'];
  attributeNames?: Maybe<Array<Scalars['String']>>;
  entityID?: Maybe<Scalars['ID']>;
  entityName?: Maybe<Scalars['String']>;
  issueLevel: IssueLevel;
  issueReferenceType?: Maybe<IssueReferenceType>;
  issueType: IssueType;
  profileName: Scalars['String'];
  roleNames?: Maybe<Array<Scalars['String']>>;
  sourceRequestReference: Scalars['String'];
  traceId: Scalars['String'];
  userMessage: Scalars['String'];
};

export enum IssueLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING'
}

export enum IssueReferenceType {
  ENTITY = 'ENTITY',
  ENTITY_ATTRIBUTE = 'ENTITY_ATTRIBUTE',
  ENTITY_ROLE = 'ENTITY_ROLE'
}

export enum IssueType {
  APPLICATION_ACCESS_FORBIDDEN = 'APPLICATION_ACCESS_FORBIDDEN',
  ATTRIBUTE_CODICE_FISCALE_PERSONA_FISICA = 'ATTRIBUTE_CODICE_FISCALE_PERSONA_FISICA',
  ATTRIBUTE_CODICE_FISCALE_PERSONA_GIURIDICA = 'ATTRIBUTE_CODICE_FISCALE_PERSONA_GIURIDICA',
  ATTRIBUTE_EMAIL = 'ATTRIBUTE_EMAIL',
  ATTRIBUTE_FILE_SIZE = 'ATTRIBUTE_FILE_SIZE',
  ATTRIBUTE_FILE_TYPE = 'ATTRIBUTE_FILE_TYPE',
  ATTRIBUTE_GRANT_EDIT = 'ATTRIBUTE_GRANT_EDIT',
  ATTRIBUTE_GRANT_READ = 'ATTRIBUTE_GRANT_READ',
  ATTRIBUTE_PARTITA_IVA = 'ATTRIBUTE_PARTITA_IVA',
  ATTRIBUTE_PHONE = 'ATTRIBUTE_PHONE',
  ATTRIBUTE_RANGE = 'ATTRIBUTE_RANGE',
  ATTRIBUTE_REAL_DECIMAL_DIGITS = 'ATTRIBUTE_REAL_DECIMAL_DIGITS',
  ATTRIBUTE_REQUIRED = 'ATTRIBUTE_REQUIRED',
  ATTRIBUTE_STRING_LENGTH = 'ATTRIBUTE_STRING_LENGTH',
  ATTRIBUTE_STRING_PATTERN = 'ATTRIBUTE_STRING_PATTERN',
  DATA_TYPE = 'DATA_TYPE',
  ENTITY_ACCESS_FORBIDDEN = 'ENTITY_ACCESS_FORBIDDEN',
  ENTITY_ATTRIBUTE_ACCESS_FORBIDDEN = 'ENTITY_ATTRIBUTE_ACCESS_FORBIDDEN',
  ENTITY_ATTRIBUTE_NOT_FOUND = 'ENTITY_ATTRIBUTE_NOT_FOUND',
  ENTITY_CREATE_VETO = 'ENTITY_CREATE_VETO',
  ENTITY_DELETE_VETO = 'ENTITY_DELETE_VETO',
  ENTITY_DOMAIN = 'ENTITY_DOMAIN',
  ENTITY_EDIT_VETO = 'ENTITY_EDIT_VETO',
  ENTITY_GRANT_CREATE = 'ENTITY_GRANT_CREATE',
  ENTITY_GRANT_DELETE = 'ENTITY_GRANT_DELETE',
  ENTITY_GRANT_EDIT = 'ENTITY_GRANT_EDIT',
  ENTITY_GRANT_READ = 'ENTITY_GRANT_READ',
  ENTITY_LOCK_EDIT = 'ENTITY_LOCK_EDIT',
  ENTITY_LOCK_PERSIST = 'ENTITY_LOCK_PERSIST',
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
  ENTITY_ROLE_ACCESS_FORBIDDEN = 'ENTITY_ROLE_ACCESS_FORBIDDEN',
  ENTITY_ROLE_NOT_FOUND = 'ENTITY_ROLE_NOT_FOUND',
  ENTITY_UNIQUE = 'ENTITY_UNIQUE',
  MALFORMED_REQUEST = 'MALFORMED_REQUEST',
  ROLE_CARDINALITY = 'ROLE_CARDINALITY',
  ROLE_DOMAIN = 'ROLE_DOMAIN',
  ROLE_GRANT_CREATE = 'ROLE_GRANT_CREATE',
  ROLE_GRANT_DELETE = 'ROLE_GRANT_DELETE',
  ROLE_GRANT_EDIT = 'ROLE_GRANT_EDIT',
  ROLE_GRANT_READ = 'ROLE_GRANT_READ',
  SERVER_ERROR = 'SERVER_ERROR',
  SERVICE_HANDLER_ERROR = 'SERVICE_HANDLER_ERROR'
}

/** Represents an object of class Laboratory, as defined in the model. */
export type Laboratory = {
  __typename?: 'Laboratory';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Laboratory. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute address of Laboratory. It is of type String. Path: address_ description. Cannot be null. */
  address?: Maybe<Scalars['String']>;
  /** The associated object for the role address_. */
  address_?: Maybe<Address>;
  /** The associated objects for the role center_. */
  center_?: Maybe<CenterPage>;
  /** The associable objects for the role center_. */
  center____associables?: Maybe<CenterPage>;
  /** The attribute center_ids of Laboratory. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)center_ __id. Cannot be null. */
  center_ids?: Maybe<Scalars['String']>;
  /** The attribute centers of Laboratory. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)center_ name. Cannot be null. */
  centers?: Maybe<Scalars['String']>;
  /** The associated objects for the role hospital_. */
  hospital_?: Maybe<HospitalPage>;
  /** The associable objects for the role hospital_. */
  hospital____associables?: Maybe<HospitalPage>;
  /** The attribute label_height of Laboratory. It is of type Real @realFormat.  */
  label_height?: Maybe<Scalars['Real']>;
  /** The attribute label_width of Laboratory. It is of type Real @realFormat.  */
  label_width?: Maybe<Scalars['Real']>;
  /** The attribute n_schools of Laboratory. It is of type Int. Path: COUNT_ALL - Count allschool_ __id. Cannot be null. */
  n_schools?: Maybe<Scalars['Int']>;
  /** The attribute name of Laboratory. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The associated objects for the role school_. */
  school_?: Maybe<SchoolPage>;
  /** The associable objects for the role school_. */
  school____associables?: Maybe<SchoolPage>;
  /** The attribute school_ids of Laboratory. It is of type String. Path: CONCAT_CS - Concat (comma separated)school_ __id. Cannot be null. */
  school_ids?: Maybe<Scalars['String']>;
  /** The attribute schools of Laboratory. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)school_ name. Cannot be null. */
  schools?: Maybe<Scalars['String']>;
};


/** Represents an object of class Laboratory, as defined in the model. */
export type LaboratoryCenter_Args = {
  options?: InputMaybe<CenterPageOptions>;
};


/** Represents an object of class Laboratory, as defined in the model. */
export type LaboratoryCenter____AssociablesArgs = {
  options?: InputMaybe<CenterPageOptions>;
};


/** Represents an object of class Laboratory, as defined in the model. */
export type LaboratoryHospital_Args = {
  options?: InputMaybe<HospitalPageOptions>;
};


/** Represents an object of class Laboratory, as defined in the model. */
export type LaboratoryHospital____AssociablesArgs = {
  options?: InputMaybe<HospitalPageOptions>;
};


/** Represents an object of class Laboratory, as defined in the model. */
export type LaboratoryLabel_HeightArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Laboratory, as defined in the model. */
export type LaboratoryLabel_WidthArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Laboratory, as defined in the model. */
export type LaboratorySchool_Args = {
  options?: InputMaybe<SchoolPageOptions>;
};


/** Represents an object of class Laboratory, as defined in the model. */
export type LaboratorySchool____AssociablesArgs = {
  options?: InputMaybe<SchoolPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Laboratory.
 */
export type LaboratoryCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  address?: InputMaybe<Scalars['String']>;
  center_ids?: InputMaybe<Scalars['String']>;
  centers?: InputMaybe<Scalars['String']>;
  label_height?: InputMaybe<Scalars['Real']>;
  label_width?: InputMaybe<Scalars['Real']>;
  n_schools?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  school_ids?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class Laboratory */
export type LaboratoryDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft associated objects of class Laboratory */
export type LaboratoryDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type Laboratory.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type LaboratoryFilter = {
  AND?: InputMaybe<Array<LaboratoryFilter>>;
  NOT?: InputMaybe<LaboratoryFilter>;
  OR?: InputMaybe<Array<LaboratoryFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  address___contains?: InputMaybe<Scalars['String']>;
  address___ends_with?: InputMaybe<Scalars['String']>;
  address___eq?: InputMaybe<Scalars['String']>;
  address___gt?: InputMaybe<Scalars['String']>;
  address___gte?: InputMaybe<Scalars['String']>;
  address___in?: InputMaybe<Array<Scalars['String']>>;
  address___lt?: InputMaybe<Scalars['String']>;
  address___lte?: InputMaybe<Scalars['String']>;
  address___ne?: InputMaybe<Scalars['String']>;
  address___not___contains?: InputMaybe<Scalars['String']>;
  address___not___ends_with?: InputMaybe<Scalars['String']>;
  address___not___in?: InputMaybe<Array<Scalars['String']>>;
  address___not___null?: InputMaybe<Scalars['Boolean']>;
  address___not___starts_with?: InputMaybe<Scalars['String']>;
  address___null?: InputMaybe<Scalars['Boolean']>;
  address___starts_with?: InputMaybe<Scalars['String']>;
  center_ids___contains?: InputMaybe<Scalars['String']>;
  center_ids___ends_with?: InputMaybe<Scalars['String']>;
  center_ids___eq?: InputMaybe<Scalars['String']>;
  center_ids___gt?: InputMaybe<Scalars['String']>;
  center_ids___gte?: InputMaybe<Scalars['String']>;
  center_ids___in?: InputMaybe<Array<Scalars['String']>>;
  center_ids___lt?: InputMaybe<Scalars['String']>;
  center_ids___lte?: InputMaybe<Scalars['String']>;
  center_ids___ne?: InputMaybe<Scalars['String']>;
  center_ids___not___contains?: InputMaybe<Scalars['String']>;
  center_ids___not___ends_with?: InputMaybe<Scalars['String']>;
  center_ids___not___in?: InputMaybe<Array<Scalars['String']>>;
  center_ids___not___null?: InputMaybe<Scalars['Boolean']>;
  center_ids___not___starts_with?: InputMaybe<Scalars['String']>;
  center_ids___null?: InputMaybe<Scalars['Boolean']>;
  center_ids___starts_with?: InputMaybe<Scalars['String']>;
  centers___contains?: InputMaybe<Scalars['String']>;
  centers___ends_with?: InputMaybe<Scalars['String']>;
  centers___eq?: InputMaybe<Scalars['String']>;
  centers___gt?: InputMaybe<Scalars['String']>;
  centers___gte?: InputMaybe<Scalars['String']>;
  centers___in?: InputMaybe<Array<Scalars['String']>>;
  centers___lt?: InputMaybe<Scalars['String']>;
  centers___lte?: InputMaybe<Scalars['String']>;
  centers___ne?: InputMaybe<Scalars['String']>;
  centers___not___contains?: InputMaybe<Scalars['String']>;
  centers___not___ends_with?: InputMaybe<Scalars['String']>;
  centers___not___in?: InputMaybe<Array<Scalars['String']>>;
  centers___not___null?: InputMaybe<Scalars['Boolean']>;
  centers___not___starts_with?: InputMaybe<Scalars['String']>;
  centers___null?: InputMaybe<Scalars['Boolean']>;
  centers___starts_with?: InputMaybe<Scalars['String']>;
  label_height___eq?: InputMaybe<Scalars['Real']>;
  label_height___gt?: InputMaybe<Scalars['Real']>;
  label_height___gte?: InputMaybe<Scalars['Real']>;
  label_height___in?: InputMaybe<Array<InputMaybe<Scalars['Real']>>>;
  label_height___lt?: InputMaybe<Scalars['Real']>;
  label_height___lte?: InputMaybe<Scalars['Real']>;
  label_height___ne?: InputMaybe<Scalars['Real']>;
  label_height___not___in?: InputMaybe<Array<Scalars['Real']>>;
  label_height___not___null?: InputMaybe<Scalars['Boolean']>;
  label_height___null?: InputMaybe<Scalars['Boolean']>;
  label_width___eq?: InputMaybe<Scalars['Real']>;
  label_width___gt?: InputMaybe<Scalars['Real']>;
  label_width___gte?: InputMaybe<Scalars['Real']>;
  label_width___in?: InputMaybe<Array<InputMaybe<Scalars['Real']>>>;
  label_width___lt?: InputMaybe<Scalars['Real']>;
  label_width___lte?: InputMaybe<Scalars['Real']>;
  label_width___ne?: InputMaybe<Scalars['Real']>;
  label_width___not___in?: InputMaybe<Array<Scalars['Real']>>;
  label_width___not___null?: InputMaybe<Scalars['Boolean']>;
  label_width___null?: InputMaybe<Scalars['Boolean']>;
  n_schools___eq?: InputMaybe<Scalars['Int']>;
  n_schools___gt?: InputMaybe<Scalars['Int']>;
  n_schools___gte?: InputMaybe<Scalars['Int']>;
  n_schools___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  n_schools___lt?: InputMaybe<Scalars['Int']>;
  n_schools___lte?: InputMaybe<Scalars['Int']>;
  n_schools___ne?: InputMaybe<Scalars['Int']>;
  n_schools___not___in?: InputMaybe<Array<Scalars['Int']>>;
  n_schools___not___null?: InputMaybe<Scalars['Boolean']>;
  n_schools___null?: InputMaybe<Scalars['Boolean']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  school_ids___contains?: InputMaybe<Scalars['String']>;
  school_ids___ends_with?: InputMaybe<Scalars['String']>;
  school_ids___eq?: InputMaybe<Scalars['String']>;
  school_ids___gt?: InputMaybe<Scalars['String']>;
  school_ids___gte?: InputMaybe<Scalars['String']>;
  school_ids___in?: InputMaybe<Array<Scalars['String']>>;
  school_ids___lt?: InputMaybe<Scalars['String']>;
  school_ids___lte?: InputMaybe<Scalars['String']>;
  school_ids___ne?: InputMaybe<Scalars['String']>;
  school_ids___not___contains?: InputMaybe<Scalars['String']>;
  school_ids___not___ends_with?: InputMaybe<Scalars['String']>;
  school_ids___not___in?: InputMaybe<Array<Scalars['String']>>;
  school_ids___not___null?: InputMaybe<Scalars['Boolean']>;
  school_ids___not___starts_with?: InputMaybe<Scalars['String']>;
  school_ids___null?: InputMaybe<Scalars['Boolean']>;
  school_ids___starts_with?: InputMaybe<Scalars['String']>;
  schools___contains?: InputMaybe<Scalars['String']>;
  schools___ends_with?: InputMaybe<Scalars['String']>;
  schools___eq?: InputMaybe<Scalars['String']>;
  schools___gt?: InputMaybe<Scalars['String']>;
  schools___gte?: InputMaybe<Scalars['String']>;
  schools___in?: InputMaybe<Array<Scalars['String']>>;
  schools___lt?: InputMaybe<Scalars['String']>;
  schools___lte?: InputMaybe<Scalars['String']>;
  schools___ne?: InputMaybe<Scalars['String']>;
  schools___not___contains?: InputMaybe<Scalars['String']>;
  schools___not___ends_with?: InputMaybe<Scalars['String']>;
  schools___not___in?: InputMaybe<Array<Scalars['String']>>;
  schools___not___null?: InputMaybe<Scalars['Boolean']>;
  schools___not___starts_with?: InputMaybe<Scalars['String']>;
  schools___null?: InputMaybe<Scalars['Boolean']>;
  schools___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Laboratory. */
export type LaboratoryPage = {
  __typename?: 'LaboratoryPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Laboratory. */
  items: Array<Laboratory>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Laboratory present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a LaboratoryPage. */
export type LaboratoryPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<LaboratoryFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<LaboratoryCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<LaboratorySort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Laboratory */
export type LaboratoryRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the associated objects of class Laboratory */
export type LaboratoryRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum LaboratorySort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  address___ASC = 'address___ASC',
  address___DESC = 'address___DESC',
  center_ids___ASC = 'center_ids___ASC',
  center_ids___DESC = 'center_ids___DESC',
  centers___ASC = 'centers___ASC',
  centers___DESC = 'centers___DESC',
  label_height___ASC = 'label_height___ASC',
  label_height___DESC = 'label_height___DESC',
  label_width___ASC = 'label_width___ASC',
  label_width___DESC = 'label_width___DESC',
  n_schools___ASC = 'n_schools___ASC',
  n_schools___DESC = 'n_schools___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  school_ids___ASC = 'school_ids___ASC',
  school_ids___DESC = 'school_ids___DESC',
  schools___ASC = 'schools___ASC',
  schools___DESC = 'schools___DESC'
}

/** Represents an object of class Lang, as defined in the model. */
export type Lang = {
  __typename?: 'Lang';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Lang. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute name of Lang. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Lang.
 */
export type LangCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class Lang */
export type LangDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Lang.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type LangFilter = {
  AND?: InputMaybe<Array<LangFilter>>;
  NOT?: InputMaybe<LangFilter>;
  OR?: InputMaybe<Array<LangFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Lang. */
export type LangPage = {
  __typename?: 'LangPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Lang. */
  items: Array<Lang>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Lang present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a LangPage. */
export type LangPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<LangFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<LangCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<LangSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Lang */
export type LangRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum LangSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC'
}

/** Represents an object of class Level_type, as defined in the model. */
export type Level_Type = {
  __typename?: 'Level_type';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Level_type. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute code of Level_type. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The attribute high of Level_type. It is of type Boolean. Cannot be null. */
  high?: Maybe<Scalars['Boolean']>;
  /** The attribute intermediate of Level_type. It is of type Boolean. Cannot be null. */
  intermediate?: Maybe<Scalars['Boolean']>;
  /** The attribute kindergarten of Level_type. It is of type Boolean. Cannot be null. */
  kindergarten?: Maybe<Scalars['Boolean']>;
  /** The attribute langs of Level_type. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)level_type_lang_ lang. Cannot be null. */
  langs?: Maybe<Scalars['String']>;
  /** The attribute level_type of Level_type. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated) [if(isNull(__CurrentUser.lang),Level_type_lang.lang="English", if(containsCS(Level_type.langs,__CurrentUser.lang), __CurrentUser.lang= Level_type_lang.lang,Level_type_lang.lang="English"))]level_type_lang_ level_type. Cannot be null. */
  level_type?: Maybe<Scalars['String']>;
  /** The associated objects for the role level_type_lang_. */
  level_type_lang_?: Maybe<Level_Type_LangPage>;
  /** The attribute nursery of Level_type. It is of type Boolean. Cannot be null. */
  nursery?: Maybe<Scalars['Boolean']>;
  /** The attribute order of Level_type. It is of type Int.  */
  order?: Maybe<Scalars['Int']>;
  /** The attribute primary of Level_type. It is of type Boolean. Cannot be null. */
  primary?: Maybe<Scalars['Boolean']>;
};


/** Represents an object of class Level_type, as defined in the model. */
export type Level_TypeLevel_Type_Lang_Args = {
  options?: InputMaybe<Level_Type_LangPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Level_type.
 */
export type Level_TypeCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  high?: InputMaybe<Scalars['Boolean']>;
  intermediate?: InputMaybe<Scalars['Boolean']>;
  kindergarten?: InputMaybe<Scalars['Boolean']>;
  langs?: InputMaybe<Scalars['String']>;
  level_type?: InputMaybe<Scalars['String']>;
  nursery?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  primary?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft associated object of class Level_type */
export type Level_TypeDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft associated objects of class Level_type */
export type Level_TypeDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type Level_type.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Level_TypeFilter = {
  AND?: InputMaybe<Array<Level_TypeFilter>>;
  NOT?: InputMaybe<Level_TypeFilter>;
  OR?: InputMaybe<Array<Level_TypeFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  high___eq?: InputMaybe<Scalars['Boolean']>;
  high___ne?: InputMaybe<Scalars['Boolean']>;
  high___not___null?: InputMaybe<Scalars['Boolean']>;
  high___null?: InputMaybe<Scalars['Boolean']>;
  intermediate___eq?: InputMaybe<Scalars['Boolean']>;
  intermediate___ne?: InputMaybe<Scalars['Boolean']>;
  intermediate___not___null?: InputMaybe<Scalars['Boolean']>;
  intermediate___null?: InputMaybe<Scalars['Boolean']>;
  kindergarten___eq?: InputMaybe<Scalars['Boolean']>;
  kindergarten___ne?: InputMaybe<Scalars['Boolean']>;
  kindergarten___not___null?: InputMaybe<Scalars['Boolean']>;
  kindergarten___null?: InputMaybe<Scalars['Boolean']>;
  langs___contains?: InputMaybe<Scalars['String']>;
  langs___ends_with?: InputMaybe<Scalars['String']>;
  langs___eq?: InputMaybe<Scalars['String']>;
  langs___gt?: InputMaybe<Scalars['String']>;
  langs___gte?: InputMaybe<Scalars['String']>;
  langs___in?: InputMaybe<Array<Scalars['String']>>;
  langs___lt?: InputMaybe<Scalars['String']>;
  langs___lte?: InputMaybe<Scalars['String']>;
  langs___ne?: InputMaybe<Scalars['String']>;
  langs___not___contains?: InputMaybe<Scalars['String']>;
  langs___not___ends_with?: InputMaybe<Scalars['String']>;
  langs___not___in?: InputMaybe<Array<Scalars['String']>>;
  langs___not___null?: InputMaybe<Scalars['Boolean']>;
  langs___not___starts_with?: InputMaybe<Scalars['String']>;
  langs___null?: InputMaybe<Scalars['Boolean']>;
  langs___starts_with?: InputMaybe<Scalars['String']>;
  level_type___contains?: InputMaybe<Scalars['String']>;
  level_type___ends_with?: InputMaybe<Scalars['String']>;
  level_type___eq?: InputMaybe<Scalars['String']>;
  level_type___gt?: InputMaybe<Scalars['String']>;
  level_type___gte?: InputMaybe<Scalars['String']>;
  level_type___in?: InputMaybe<Array<Scalars['String']>>;
  level_type___lt?: InputMaybe<Scalars['String']>;
  level_type___lte?: InputMaybe<Scalars['String']>;
  level_type___ne?: InputMaybe<Scalars['String']>;
  level_type___not___contains?: InputMaybe<Scalars['String']>;
  level_type___not___ends_with?: InputMaybe<Scalars['String']>;
  level_type___not___in?: InputMaybe<Array<Scalars['String']>>;
  level_type___not___null?: InputMaybe<Scalars['Boolean']>;
  level_type___not___starts_with?: InputMaybe<Scalars['String']>;
  level_type___null?: InputMaybe<Scalars['Boolean']>;
  level_type___starts_with?: InputMaybe<Scalars['String']>;
  nursery___eq?: InputMaybe<Scalars['Boolean']>;
  nursery___ne?: InputMaybe<Scalars['Boolean']>;
  nursery___not___null?: InputMaybe<Scalars['Boolean']>;
  nursery___null?: InputMaybe<Scalars['Boolean']>;
  order___eq?: InputMaybe<Scalars['Int']>;
  order___gt?: InputMaybe<Scalars['Int']>;
  order___gte?: InputMaybe<Scalars['Int']>;
  order___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  order___lt?: InputMaybe<Scalars['Int']>;
  order___lte?: InputMaybe<Scalars['Int']>;
  order___ne?: InputMaybe<Scalars['Int']>;
  order___not___in?: InputMaybe<Array<Scalars['Int']>>;
  order___not___null?: InputMaybe<Scalars['Boolean']>;
  order___null?: InputMaybe<Scalars['Boolean']>;
  primary___eq?: InputMaybe<Scalars['Boolean']>;
  primary___ne?: InputMaybe<Scalars['Boolean']>;
  primary___not___null?: InputMaybe<Scalars['Boolean']>;
  primary___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Level_type. */
export type Level_TypePage = {
  __typename?: 'Level_typePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Level_type. */
  items: Array<Level_Type>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Level_type present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Level_typePage. */
export type Level_TypePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Level_TypeFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Level_TypeCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Level_TypeSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Level_type */
export type Level_TypeRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the associated objects of class Level_type */
export type Level_TypeRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Level_TypeSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  high___ASC = 'high___ASC',
  high___DESC = 'high___DESC',
  intermediate___ASC = 'intermediate___ASC',
  intermediate___DESC = 'intermediate___DESC',
  kindergarten___ASC = 'kindergarten___ASC',
  kindergarten___DESC = 'kindergarten___DESC',
  langs___ASC = 'langs___ASC',
  langs___DESC = 'langs___DESC',
  level_type___ASC = 'level_type___ASC',
  level_type___DESC = 'level_type___DESC',
  nursery___ASC = 'nursery___ASC',
  nursery___DESC = 'nursery___DESC',
  order___ASC = 'order___ASC',
  order___DESC = 'order___DESC',
  primary___ASC = 'primary___ASC',
  primary___DESC = 'primary___DESC'
}

/** Represents an object of class Level_type_lang, as defined in the model. */
export type Level_Type_Lang = {
  __typename?: 'Level_type_lang';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Level_type_lang. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute lang of Level_type_lang. It is of type String. Path: lang_ name. Cannot be null. */
  lang?: Maybe<Scalars['String']>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
  /** The attribute level_type of Level_type_lang. It is of type String. Max length: 255. Cannot be null. */
  level_type?: Maybe<Scalars['String']>;
  /** The associated object for the role level_type_. */
  level_type_?: Maybe<Level_Type>;
};


/** Represents an object of class Level_type_lang, as defined in the model. */
export type Level_Type_LangLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};

/** A data structure for objects of Level_type_lang. */
export type Level_Type_LangCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role lang_(0,1) <-- (0,N)level_type_lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute level_type of Level_type_lang. It is of type String!. Max length: 255. Cannot be null. */
  level_type: Scalars['String'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Level_type_lang.
 */
export type Level_Type_LangCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  lang?: InputMaybe<Scalars['String']>;
  level_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft object of class Level_type_lang. */
export type Level_Type_LangDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The Association role lang_(0,1) <-- (0,N)level_type_lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute level_type of Level_type_lang. It is of type String. Max length: 255. Cannot be null. */
  level_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for draft create objects of class Level_type_lang. */
export type Level_Type_LangDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role lang_(0,1) <-- (0,N)level_type_lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute level_type of Level_type_lang. It is of type String. Max length: 255. Cannot be null. */
  level_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part objects of class Level_type_lang */
export type Level_Type_LangDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Level_type_lang to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Level_Type_LangDraft>>>;
};

/** A data structure for a draft update object of class Level_type_lang. */
export type Level_Type_LangDraftUpdate = {
  _id: Scalars['ID'];
  /** The Association role lang_(0,1) <-- (0,N)level_type_lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute level_type of Level_type_lang. It is of type String. Max length: 255. Cannot be null. */
  level_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft update objects of class Level_type_lang. */
export type Level_Type_LangDraftUpdateBulk = {
  /** The Association role lang_(0,1) <-- (0,N)level_type_lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute level_type of Level_type_lang. It is of type String. Max length: 255. Cannot be null. */
  level_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part objects of class Level_type_lang */
export type Level_Type_LangDraftUpdateRoleObjects = {
  /** An array of objects of class Level_type_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Level_Type_LangDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Level_type_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Level_Type_LangDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Level_type_lang.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Level_Type_LangFilter = {
  AND?: InputMaybe<Array<Level_Type_LangFilter>>;
  NOT?: InputMaybe<Level_Type_LangFilter>;
  OR?: InputMaybe<Array<Level_Type_LangFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  lang___contains?: InputMaybe<Scalars['String']>;
  lang___ends_with?: InputMaybe<Scalars['String']>;
  lang___eq?: InputMaybe<Scalars['String']>;
  lang___gt?: InputMaybe<Scalars['String']>;
  lang___gte?: InputMaybe<Scalars['String']>;
  lang___in?: InputMaybe<Array<Scalars['String']>>;
  lang___lt?: InputMaybe<Scalars['String']>;
  lang___lte?: InputMaybe<Scalars['String']>;
  lang___ne?: InputMaybe<Scalars['String']>;
  lang___not___contains?: InputMaybe<Scalars['String']>;
  lang___not___ends_with?: InputMaybe<Scalars['String']>;
  lang___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang___not___null?: InputMaybe<Scalars['Boolean']>;
  lang___not___starts_with?: InputMaybe<Scalars['String']>;
  lang___null?: InputMaybe<Scalars['Boolean']>;
  lang___starts_with?: InputMaybe<Scalars['String']>;
  level_type___contains?: InputMaybe<Scalars['String']>;
  level_type___ends_with?: InputMaybe<Scalars['String']>;
  level_type___eq?: InputMaybe<Scalars['String']>;
  level_type___gt?: InputMaybe<Scalars['String']>;
  level_type___gte?: InputMaybe<Scalars['String']>;
  level_type___in?: InputMaybe<Array<Scalars['String']>>;
  level_type___lt?: InputMaybe<Scalars['String']>;
  level_type___lte?: InputMaybe<Scalars['String']>;
  level_type___ne?: InputMaybe<Scalars['String']>;
  level_type___not___contains?: InputMaybe<Scalars['String']>;
  level_type___not___ends_with?: InputMaybe<Scalars['String']>;
  level_type___not___in?: InputMaybe<Array<Scalars['String']>>;
  level_type___not___null?: InputMaybe<Scalars['Boolean']>;
  level_type___not___starts_with?: InputMaybe<Scalars['String']>;
  level_type___null?: InputMaybe<Scalars['Boolean']>;
  level_type___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Level_type_lang. */
export type Level_Type_LangPage = {
  __typename?: 'Level_type_langPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Level_type_lang. */
  items: Array<Level_Type_Lang>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Level_type_lang present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Level_type_langPage. */
export type Level_Type_LangPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Level_Type_LangFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Level_Type_LangCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Level_Type_LangSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Level_type_lang */
export type Level_Type_LangRoleObjects = {
  /** An array of objects of class Level_type_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Level_Type_LangCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Level_type_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Level_Type_LangUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Level_Type_LangSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  lang___ASC = 'lang___ASC',
  lang___DESC = 'lang___DESC',
  level_type___ASC = 'level_type___ASC',
  level_type___DESC = 'level_type___DESC'
}

/** A data structure for a single object of class Level_type_lang. */
export type Level_Type_LangUpdate = {
  _id: Scalars['ID'];
  /** The Association role lang_(0,1) <-- (0,N)level_type_lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute level_type of Level_type_lang. It is of type String. Max length: 255. Cannot be null. */
  level_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for objects of class Level_type_lang. */
export type Level_Type_LangUpdateBulk = {
  /** The Association role lang_(0,1) <-- (0,N)level_type_lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute level_type of Level_type_lang. It is of type String. Max length: 255. Cannot be null. */
  level_type?: InputMaybe<Scalars['String']>;
};

/** The result of the lock and unlock services. */
export type LockStatusResult = {
  __typename?: 'LockStatusResult';
  locked: Scalars['Boolean'];
  minutes: Scalars['Int'];
};

export enum MessageType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING'
}

/** Represents an object of class Message_lang, as defined in the model. */
export type Message_Lang = {
  __typename?: 'Message_lang';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Message_lang. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute editing_existing of Message_lang. It is of type Text.  */
  editing_existing?: Maybe<Scalars['Text']>;
  /** The attribute lang of Message_lang. It is of type String. Path: lang_ name. Cannot be null. */
  lang?: Maybe<Scalars['String']>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
  /** The attribute no_positve_tests of Message_lang. It is of type Text.  */
  no_positve_tests?: Maybe<Scalars['Text']>;
  /** The attribute no_prev_question of Message_lang. It is of type Text. Cannot be null. */
  no_prev_question?: Maybe<Scalars['Text']>;
  /** The associated object for the role questionnaire_config_. */
  questionnaire_config_?: Maybe<Questionnaire_Config>;
  /** The attribute questionnaire_finished of Message_lang. It is of type Text. Cannot be null. */
  questionnaire_finished?: Maybe<Scalars['Text']>;
  /** The attribute select_an_answer of Message_lang. It is of type Text. Cannot be null. */
  select_an_answer?: Maybe<Scalars['Text']>;
};


/** Represents an object of class Message_lang, as defined in the model. */
export type Message_LangLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};

/** A data structure for objects of Message_lang. */
export type Message_LangCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute editing_existing of Message_lang. It is of type Text.  */
  editing_existing?: InputMaybe<Scalars['Text']>;
  /** The Association role message_lang_(0,N) --> (1)lang_. */
  lang_: Scalars['ID'];
  /** The attribute no_positve_tests of Message_lang. It is of type Text.  */
  no_positve_tests?: InputMaybe<Scalars['Text']>;
  /** The attribute no_prev_question of Message_lang. It is of type Text!. Cannot be null. */
  no_prev_question: Scalars['Text'];
  /** The attribute questionnaire_finished of Message_lang. It is of type Text!. Cannot be null. */
  questionnaire_finished: Scalars['Text'];
  /** The attribute select_an_answer of Message_lang. It is of type Text!. Cannot be null. */
  select_an_answer: Scalars['Text'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Message_lang.
 */
export type Message_LangCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  editing_existing?: InputMaybe<Scalars['Text']>;
  lang?: InputMaybe<Scalars['String']>;
  no_positve_tests?: InputMaybe<Scalars['Text']>;
  no_prev_question?: InputMaybe<Scalars['Text']>;
  questionnaire_finished?: InputMaybe<Scalars['Text']>;
  select_an_answer?: InputMaybe<Scalars['Text']>;
};

/** A data structure for a draft object of class Message_lang. */
export type Message_LangDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute editing_existing of Message_lang. It is of type Text.  */
  editing_existing?: InputMaybe<Scalars['Text']>;
  /** The Association role message_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute no_positve_tests of Message_lang. It is of type Text.  */
  no_positve_tests?: InputMaybe<Scalars['Text']>;
  /** The attribute no_prev_question of Message_lang. It is of type Text. Cannot be null. */
  no_prev_question?: InputMaybe<Scalars['Text']>;
  /** The attribute questionnaire_finished of Message_lang. It is of type Text. Cannot be null. */
  questionnaire_finished?: InputMaybe<Scalars['Text']>;
  /** The attribute select_an_answer of Message_lang. It is of type Text. Cannot be null. */
  select_an_answer?: InputMaybe<Scalars['Text']>;
};

/** A data structure for draft create objects of class Message_lang. */
export type Message_LangDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute editing_existing of Message_lang. It is of type Text.  */
  editing_existing?: InputMaybe<Scalars['Text']>;
  /** The Association role message_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute no_positve_tests of Message_lang. It is of type Text.  */
  no_positve_tests?: InputMaybe<Scalars['Text']>;
  /** The attribute no_prev_question of Message_lang. It is of type Text. Cannot be null. */
  no_prev_question?: InputMaybe<Scalars['Text']>;
  /** The attribute questionnaire_finished of Message_lang. It is of type Text. Cannot be null. */
  questionnaire_finished?: InputMaybe<Scalars['Text']>;
  /** The attribute select_an_answer of Message_lang. It is of type Text. Cannot be null. */
  select_an_answer?: InputMaybe<Scalars['Text']>;
};

/** A data structure for the draft part objects of class Message_lang */
export type Message_LangDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Message_lang to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Message_LangDraft>>>;
};

/** A data structure for a draft update object of class Message_lang. */
export type Message_LangDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute editing_existing of Message_lang. It is of type Text.  */
  editing_existing?: InputMaybe<Scalars['Text']>;
  /** The Association role message_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute no_positve_tests of Message_lang. It is of type Text.  */
  no_positve_tests?: InputMaybe<Scalars['Text']>;
  /** The attribute no_prev_question of Message_lang. It is of type Text. Cannot be null. */
  no_prev_question?: InputMaybe<Scalars['Text']>;
  /** The attribute questionnaire_finished of Message_lang. It is of type Text. Cannot be null. */
  questionnaire_finished?: InputMaybe<Scalars['Text']>;
  /** The attribute select_an_answer of Message_lang. It is of type Text. Cannot be null. */
  select_an_answer?: InputMaybe<Scalars['Text']>;
};

/** A data structure for a draft update objects of class Message_lang. */
export type Message_LangDraftUpdateBulk = {
  /** The attribute editing_existing of Message_lang. It is of type Text.  */
  editing_existing?: InputMaybe<Scalars['Text']>;
  /** The Association role message_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute no_positve_tests of Message_lang. It is of type Text.  */
  no_positve_tests?: InputMaybe<Scalars['Text']>;
  /** The attribute no_prev_question of Message_lang. It is of type Text. Cannot be null. */
  no_prev_question?: InputMaybe<Scalars['Text']>;
  /** The attribute questionnaire_finished of Message_lang. It is of type Text. Cannot be null. */
  questionnaire_finished?: InputMaybe<Scalars['Text']>;
  /** The attribute select_an_answer of Message_lang. It is of type Text. Cannot be null. */
  select_an_answer?: InputMaybe<Scalars['Text']>;
};

/** A data structure for the draft part objects of class Message_lang */
export type Message_LangDraftUpdateRoleObjects = {
  /** An array of objects of class Message_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Message_LangDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Message_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Message_LangDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Message_lang.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Message_LangFilter = {
  AND?: InputMaybe<Array<Message_LangFilter>>;
  NOT?: InputMaybe<Message_LangFilter>;
  OR?: InputMaybe<Array<Message_LangFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  editing_existing___contains?: InputMaybe<Scalars['Text']>;
  editing_existing___ends_with?: InputMaybe<Scalars['Text']>;
  editing_existing___eq?: InputMaybe<Scalars['Text']>;
  editing_existing___gt?: InputMaybe<Scalars['Text']>;
  editing_existing___gte?: InputMaybe<Scalars['Text']>;
  editing_existing___in?: InputMaybe<Array<Scalars['Text']>>;
  editing_existing___lt?: InputMaybe<Scalars['Text']>;
  editing_existing___lte?: InputMaybe<Scalars['Text']>;
  editing_existing___ne?: InputMaybe<Scalars['Text']>;
  editing_existing___not___contains?: InputMaybe<Scalars['Text']>;
  editing_existing___not___ends_with?: InputMaybe<Scalars['Text']>;
  editing_existing___not___in?: InputMaybe<Array<Scalars['Text']>>;
  editing_existing___not___null?: InputMaybe<Scalars['Boolean']>;
  editing_existing___not___starts_with?: InputMaybe<Scalars['Text']>;
  editing_existing___null?: InputMaybe<Scalars['Boolean']>;
  editing_existing___starts_with?: InputMaybe<Scalars['Text']>;
  lang___contains?: InputMaybe<Scalars['String']>;
  lang___ends_with?: InputMaybe<Scalars['String']>;
  lang___eq?: InputMaybe<Scalars['String']>;
  lang___gt?: InputMaybe<Scalars['String']>;
  lang___gte?: InputMaybe<Scalars['String']>;
  lang___in?: InputMaybe<Array<Scalars['String']>>;
  lang___lt?: InputMaybe<Scalars['String']>;
  lang___lte?: InputMaybe<Scalars['String']>;
  lang___ne?: InputMaybe<Scalars['String']>;
  lang___not___contains?: InputMaybe<Scalars['String']>;
  lang___not___ends_with?: InputMaybe<Scalars['String']>;
  lang___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang___not___null?: InputMaybe<Scalars['Boolean']>;
  lang___not___starts_with?: InputMaybe<Scalars['String']>;
  lang___null?: InputMaybe<Scalars['Boolean']>;
  lang___starts_with?: InputMaybe<Scalars['String']>;
  no_positve_tests___contains?: InputMaybe<Scalars['Text']>;
  no_positve_tests___ends_with?: InputMaybe<Scalars['Text']>;
  no_positve_tests___eq?: InputMaybe<Scalars['Text']>;
  no_positve_tests___gt?: InputMaybe<Scalars['Text']>;
  no_positve_tests___gte?: InputMaybe<Scalars['Text']>;
  no_positve_tests___in?: InputMaybe<Array<Scalars['Text']>>;
  no_positve_tests___lt?: InputMaybe<Scalars['Text']>;
  no_positve_tests___lte?: InputMaybe<Scalars['Text']>;
  no_positve_tests___ne?: InputMaybe<Scalars['Text']>;
  no_positve_tests___not___contains?: InputMaybe<Scalars['Text']>;
  no_positve_tests___not___ends_with?: InputMaybe<Scalars['Text']>;
  no_positve_tests___not___in?: InputMaybe<Array<Scalars['Text']>>;
  no_positve_tests___not___null?: InputMaybe<Scalars['Boolean']>;
  no_positve_tests___not___starts_with?: InputMaybe<Scalars['Text']>;
  no_positve_tests___null?: InputMaybe<Scalars['Boolean']>;
  no_positve_tests___starts_with?: InputMaybe<Scalars['Text']>;
  no_prev_question___contains?: InputMaybe<Scalars['Text']>;
  no_prev_question___ends_with?: InputMaybe<Scalars['Text']>;
  no_prev_question___eq?: InputMaybe<Scalars['Text']>;
  no_prev_question___gt?: InputMaybe<Scalars['Text']>;
  no_prev_question___gte?: InputMaybe<Scalars['Text']>;
  no_prev_question___in?: InputMaybe<Array<Scalars['Text']>>;
  no_prev_question___lt?: InputMaybe<Scalars['Text']>;
  no_prev_question___lte?: InputMaybe<Scalars['Text']>;
  no_prev_question___ne?: InputMaybe<Scalars['Text']>;
  no_prev_question___not___contains?: InputMaybe<Scalars['Text']>;
  no_prev_question___not___ends_with?: InputMaybe<Scalars['Text']>;
  no_prev_question___not___in?: InputMaybe<Array<Scalars['Text']>>;
  no_prev_question___not___null?: InputMaybe<Scalars['Boolean']>;
  no_prev_question___not___starts_with?: InputMaybe<Scalars['Text']>;
  no_prev_question___null?: InputMaybe<Scalars['Boolean']>;
  no_prev_question___starts_with?: InputMaybe<Scalars['Text']>;
  questionnaire_finished___contains?: InputMaybe<Scalars['Text']>;
  questionnaire_finished___ends_with?: InputMaybe<Scalars['Text']>;
  questionnaire_finished___eq?: InputMaybe<Scalars['Text']>;
  questionnaire_finished___gt?: InputMaybe<Scalars['Text']>;
  questionnaire_finished___gte?: InputMaybe<Scalars['Text']>;
  questionnaire_finished___in?: InputMaybe<Array<Scalars['Text']>>;
  questionnaire_finished___lt?: InputMaybe<Scalars['Text']>;
  questionnaire_finished___lte?: InputMaybe<Scalars['Text']>;
  questionnaire_finished___ne?: InputMaybe<Scalars['Text']>;
  questionnaire_finished___not___contains?: InputMaybe<Scalars['Text']>;
  questionnaire_finished___not___ends_with?: InputMaybe<Scalars['Text']>;
  questionnaire_finished___not___in?: InputMaybe<Array<Scalars['Text']>>;
  questionnaire_finished___not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_finished___not___starts_with?: InputMaybe<Scalars['Text']>;
  questionnaire_finished___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_finished___starts_with?: InputMaybe<Scalars['Text']>;
  select_an_answer___contains?: InputMaybe<Scalars['Text']>;
  select_an_answer___ends_with?: InputMaybe<Scalars['Text']>;
  select_an_answer___eq?: InputMaybe<Scalars['Text']>;
  select_an_answer___gt?: InputMaybe<Scalars['Text']>;
  select_an_answer___gte?: InputMaybe<Scalars['Text']>;
  select_an_answer___in?: InputMaybe<Array<Scalars['Text']>>;
  select_an_answer___lt?: InputMaybe<Scalars['Text']>;
  select_an_answer___lte?: InputMaybe<Scalars['Text']>;
  select_an_answer___ne?: InputMaybe<Scalars['Text']>;
  select_an_answer___not___contains?: InputMaybe<Scalars['Text']>;
  select_an_answer___not___ends_with?: InputMaybe<Scalars['Text']>;
  select_an_answer___not___in?: InputMaybe<Array<Scalars['Text']>>;
  select_an_answer___not___null?: InputMaybe<Scalars['Boolean']>;
  select_an_answer___not___starts_with?: InputMaybe<Scalars['Text']>;
  select_an_answer___null?: InputMaybe<Scalars['Boolean']>;
  select_an_answer___starts_with?: InputMaybe<Scalars['Text']>;
};

/** Represents a collection of objects of class Message_lang. */
export type Message_LangPage = {
  __typename?: 'Message_langPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Message_lang. */
  items: Array<Message_Lang>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Message_lang present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Message_langPage. */
export type Message_LangPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Message_LangFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Message_LangCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Message_LangSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Message_lang */
export type Message_LangRoleObjects = {
  /** An array of objects of class Message_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Message_LangCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Message_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Message_LangUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Message_LangSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  editing_existing___ASC = 'editing_existing___ASC',
  editing_existing___DESC = 'editing_existing___DESC',
  lang___ASC = 'lang___ASC',
  lang___DESC = 'lang___DESC',
  no_positve_tests___ASC = 'no_positve_tests___ASC',
  no_positve_tests___DESC = 'no_positve_tests___DESC',
  no_prev_question___ASC = 'no_prev_question___ASC',
  no_prev_question___DESC = 'no_prev_question___DESC',
  questionnaire_finished___ASC = 'questionnaire_finished___ASC',
  questionnaire_finished___DESC = 'questionnaire_finished___DESC',
  select_an_answer___ASC = 'select_an_answer___ASC',
  select_an_answer___DESC = 'select_an_answer___DESC'
}

/** A data structure for a single object of class Message_lang. */
export type Message_LangUpdate = {
  _id: Scalars['ID'];
  /** The attribute editing_existing of Message_lang. It is of type Text.  */
  editing_existing?: InputMaybe<Scalars['Text']>;
  /** The Association role message_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute no_positve_tests of Message_lang. It is of type Text.  */
  no_positve_tests?: InputMaybe<Scalars['Text']>;
  /** The attribute no_prev_question of Message_lang. It is of type Text. Cannot be null. */
  no_prev_question?: InputMaybe<Scalars['Text']>;
  /** The attribute questionnaire_finished of Message_lang. It is of type Text. Cannot be null. */
  questionnaire_finished?: InputMaybe<Scalars['Text']>;
  /** The attribute select_an_answer of Message_lang. It is of type Text. Cannot be null. */
  select_an_answer?: InputMaybe<Scalars['Text']>;
};

/** A data structure for objects of class Message_lang. */
export type Message_LangUpdateBulk = {
  /** The attribute editing_existing of Message_lang. It is of type Text.  */
  editing_existing?: InputMaybe<Scalars['Text']>;
  /** The Association role message_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute no_positve_tests of Message_lang. It is of type Text.  */
  no_positve_tests?: InputMaybe<Scalars['Text']>;
  /** The attribute no_prev_question of Message_lang. It is of type Text. Cannot be null. */
  no_prev_question?: InputMaybe<Scalars['Text']>;
  /** The attribute questionnaire_finished of Message_lang. It is of type Text. Cannot be null. */
  questionnaire_finished?: InputMaybe<Scalars['Text']>;
  /** The attribute select_an_answer of Message_lang. It is of type Text. Cannot be null. */
  select_an_answer?: InputMaybe<Scalars['Text']>;
};

/** Use a Mutation to create, update and delete data. */
export type Mutation = {
  __typename?: 'Mutation';
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named getCompletions on a new object of form class Completion_selector.
   */
  Completion_selector___create___formAction___getCompletions?: Maybe<Completion_SelectorFormActionHandlerResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named getCompletions on an object of form class Completion_selector.
   */
  Completion_selector___formAction___getCompletions?: Maybe<Completion_SelectorFormActionHandlerResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named run on a new object of form class Plugin_runner.
   */
  Plugin_runner___create___formAction___run?: Maybe<Plugin_RunnerFormActionHandlerResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named run on an object of form class Plugin_runner.
   */
  Plugin_runner___formAction___run?: Maybe<Plugin_RunnerFormActionHandlerResult>;
  /**
   * Create an object of class Questionnaire_completion.
   * Returns the newly created object.
   */
  Questionnaire_completion___create?: Maybe<Questionnaire_Completion>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named cacheData on a new object of class Questionnaire_completion.
   */
  Questionnaire_completion___create___formAction___cacheData?: Maybe<Questionnaire_CompletionFormActionHandlerResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named respond on a new object of class Questionnaire_completion.
   */
  Questionnaire_completion___create___formAction___respond?: Maybe<Questionnaire_CompletionFormActionHandlerResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named showName on a new object of class Questionnaire_completion.
   */
  Questionnaire_completion___create___formAction___showName?: Maybe<Questionnaire_CompletionFormActionHandlerResult>;
  /**
   * Delete an object of class Questionnaire_completion.
   * Returns true if the object has been deleted succesfully.
   */
  Questionnaire_completion___delete?: Maybe<DeleteResult>;
  /**
   * Bulk delete a set of objects of class Questionnaire_completion.
   * Returns the number of object successfully deleted.
   */
  Questionnaire_completion___deleteBulk?: Maybe<DeleteBulkResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named cacheData on an object of class Questionnaire_completion.
   */
  Questionnaire_completion___formAction___cacheData?: Maybe<Questionnaire_CompletionFormActionHandlerResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named respond on an object of class Questionnaire_completion.
   */
  Questionnaire_completion___formAction___respond?: Maybe<Questionnaire_CompletionFormActionHandlerResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named showName on an object of class Questionnaire_completion.
   */
  Questionnaire_completion___formAction___showName?: Maybe<Questionnaire_CompletionFormActionHandlerResult>;
  /**
   * Create or update an object of class Questionnaire_completion.
   * Returns the newly created/updated object.
   */
  Questionnaire_completion___save?: Maybe<Questionnaire_Completion>;
  /**
   * Update an object of class Questionnaire_completion.
   * Returns the newly created object.
   */
  Questionnaire_completion___update?: Maybe<Questionnaire_Completion>;
  /**
   * Bulk update a set of objects of class Questionnaire_completion.
   * Returns an array of updated objects and a number representing the total count of items affected.
   */
  Questionnaire_completion___updateBulk?: Maybe<Questionnaire_CompletionBulkResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named cacheData on an existing object of class Questionnaire_completion.
   */
  Questionnaire_completion___update___formAction___cacheData?: Maybe<Questionnaire_CompletionFormActionHandlerResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named respond on an existing object of class Questionnaire_completion.
   */
  Questionnaire_completion___update___formAction___respond?: Maybe<Questionnaire_CompletionFormActionHandlerResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named showName on an existing object of class Questionnaire_completion.
   */
  Questionnaire_completion___update___formAction___showName?: Maybe<Questionnaire_CompletionFormActionHandlerResult>;
  /**
   * Update an object of singleton class Questionnaire_config.
   * Returns the newly created object.
   */
  Questionnaire_config___update?: Maybe<Questionnaire_Config>;
  /**
   * Create an object of class Questionnaire_schema.
   * Returns the newly created object.
   */
  Questionnaire_schema___create?: Maybe<Questionnaire_Schema>;
  /**
   * Delete an object of class Questionnaire_schema.
   * Returns true if the object has been deleted succesfully.
   */
  Questionnaire_schema___delete?: Maybe<DeleteResult>;
  /**
   * Bulk delete a set of objects of class Questionnaire_schema.
   * Returns the number of object successfully deleted.
   */
  Questionnaire_schema___deleteBulk?: Maybe<DeleteBulkResult>;
  /**
   * Create or update an object of class Questionnaire_schema.
   * Returns the newly created/updated object.
   */
  Questionnaire_schema___save?: Maybe<Questionnaire_Schema>;
  /**
   * Update an object of class Questionnaire_schema.
   * Returns the newly created object.
   */
  Questionnaire_schema___update?: Maybe<Questionnaire_Schema>;
  /**
   * Bulk update a set of objects of class Questionnaire_schema.
   * Returns an array of updated objects and a number representing the total count of items affected.
   */
  Questionnaire_schema___updateBulk?: Maybe<Questionnaire_SchemaBulkResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named precedente on a new object of form class Responses.
   */
  Responses___create___formAction___precedente?: Maybe<ResponsesFormActionHandlerResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named successiva on a new object of form class Responses.
   */
  Responses___create___formAction___successiva?: Maybe<ResponsesFormActionHandlerResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named precedente on an object of form class Responses.
   */
  Responses___formAction___precedente?: Maybe<ResponsesFormActionHandlerResult>;
  /**
   * BETA: this service is still in beta. It can change in future releases.
   * Invoke the form action handler named successiva on an object of form class Responses.
   */
  Responses___formAction___successiva?: Maybe<ResponsesFormActionHandlerResult>;
  /**
   * Create an object of class Template.
   * Returns the newly created object.
   */
  Template___create?: Maybe<Template>;
  /**
   * Delete an object of class Template.
   * Returns true if the object has been deleted succesfully.
   */
  Template___delete?: Maybe<DeleteResult>;
  /**
   * Bulk delete a set of objects of class Template.
   * Returns the number of object successfully deleted.
   */
  Template___deleteBulk?: Maybe<DeleteBulkResult>;
  /**
   * Create or update an object of class Template.
   * Returns the newly created/updated object.
   */
  Template___save?: Maybe<Template>;
  /**
   * Update an object of class Template.
   * Returns the newly created object.
   */
  Template___update?: Maybe<Template>;
  /**
   * Bulk update a set of objects of class Template.
   * Returns an array of updated objects and a number representing the total count of items affected.
   */
  Template___updateBulk?: Maybe<TemplateBulkResult>;
  lock?: Maybe<LockStatusResult>;
  unlock?: Maybe<LockStatusResult>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationCompletion_Selector___Create___FormAction___GetCompletionsArgs = {
  data?: InputMaybe<Completion_SelectorDraftCreate>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationCompletion_Selector___FormAction___GetCompletionsArgs = {
  data?: InputMaybe<Completion_SelectorDraft>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationPlugin_Runner___Create___FormAction___RunArgs = {
  data?: InputMaybe<Plugin_RunnerDraftCreate>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationPlugin_Runner___FormAction___RunArgs = {
  data?: InputMaybe<Plugin_RunnerDraft>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___CreateArgs = {
  data: Questionnaire_CompletionCreate;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___Create___FormAction___CacheDataArgs = {
  data?: InputMaybe<Questionnaire_CompletionDraftCreate>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___Create___FormAction___RespondArgs = {
  data?: InputMaybe<Questionnaire_CompletionDraftCreate>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  formParam: ResponsesDraftCreate;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___Create___FormAction___ShowNameArgs = {
  data?: InputMaybe<Questionnaire_CompletionDraftCreate>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___DeleteArgs = {
  _id: Scalars['ID'];
  forceWarnings?: InputMaybe<ForceWarnings>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___DeleteBulkArgs = {
  forceWarnings?: InputMaybe<ForceWarnings>;
  options: Questionnaire_CompletionPageOptions;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___FormAction___CacheDataArgs = {
  data?: InputMaybe<Questionnaire_CompletionDraft>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___FormAction___RespondArgs = {
  data?: InputMaybe<Questionnaire_CompletionDraft>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  formParam: ResponsesDraft;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___FormAction___ShowNameArgs = {
  data?: InputMaybe<Questionnaire_CompletionDraft>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___SaveArgs = {
  data: Questionnaire_CompletionDraft;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___UpdateArgs = {
  data: Questionnaire_CompletionUpdate;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___UpdateBulkArgs = {
  data: Questionnaire_CompletionUpdateBulk;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  options: Questionnaire_CompletionPageOptions;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___Update___FormAction___CacheDataArgs = {
  data?: InputMaybe<Questionnaire_CompletionDraftUpdate>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___Update___FormAction___RespondArgs = {
  data?: InputMaybe<Questionnaire_CompletionDraftUpdate>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  formParam: ResponsesDraft;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Completion___Update___FormAction___ShowNameArgs = {
  data?: InputMaybe<Questionnaire_CompletionDraftUpdate>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Config___UpdateArgs = {
  data: Questionnaire_ConfigUpdate;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Schema___CreateArgs = {
  data: Questionnaire_SchemaCreate;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Schema___DeleteArgs = {
  _id: Scalars['ID'];
  forceWarnings?: InputMaybe<ForceWarnings>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Schema___DeleteBulkArgs = {
  forceWarnings?: InputMaybe<ForceWarnings>;
  options: Questionnaire_SchemaPageOptions;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Schema___SaveArgs = {
  data: Questionnaire_SchemaDraft;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Schema___UpdateArgs = {
  data: Questionnaire_SchemaUpdate;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationQuestionnaire_Schema___UpdateBulkArgs = {
  data: Questionnaire_SchemaUpdateBulk;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  options: Questionnaire_SchemaPageOptions;
};


/** Use a Mutation to create, update and delete data. */
export type MutationResponses___Create___FormAction___PrecedenteArgs = {
  data?: InputMaybe<ResponsesDraftCreate>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationResponses___Create___FormAction___SuccessivaArgs = {
  data?: InputMaybe<ResponsesDraftCreate>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationResponses___FormAction___PrecedenteArgs = {
  data?: InputMaybe<ResponsesDraft>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationResponses___FormAction___SuccessivaArgs = {
  data?: InputMaybe<ResponsesDraft>;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  jsonParam?: InputMaybe<Scalars['String']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationTemplate___CreateArgs = {
  data: TemplateCreate;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationTemplate___DeleteArgs = {
  _id: Scalars['ID'];
  forceWarnings?: InputMaybe<ForceWarnings>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationTemplate___DeleteBulkArgs = {
  forceWarnings?: InputMaybe<ForceWarnings>;
  options: TemplatePageOptions;
};


/** Use a Mutation to create, update and delete data. */
export type MutationTemplate___SaveArgs = {
  data: TemplateDraft;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationTemplate___UpdateArgs = {
  data: TemplateUpdate;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationTemplate___UpdateBulkArgs = {
  data: TemplateUpdateBulk;
  forceWarnings?: InputMaybe<ForceWarnings>;
  insight?: InputMaybe<InsightType>;
  options: TemplatePageOptions;
};


/** Use a Mutation to create, update and delete data. */
export type MutationLockArgs = {
  locks: Array<EntityId>;
  minutes?: InputMaybe<Scalars['Int']>;
};


/** Use a Mutation to create, update and delete data. */
export type MutationUnlockArgs = {
  locks: Array<EntityId>;
};

/** Represents an object of class Neighbourhood_type, as defined in the model. */
export type Neighbourhood_Type = {
  __typename?: 'Neighbourhood_type';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Neighbourhood_type. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute langs of Neighbourhood_type. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)neighbourhood_type_lang_ lang. Cannot be null. */
  langs?: Maybe<Scalars['String']>;
  /** The attribute name of Neighbourhood_type. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The attribute neighbourhood_type of Neighbourhood_type. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated) [if(isNull(__CurrentUser.lang),Neighbourhood_type_lang.lang="English", if(containsCS(Neighbourhood_type.langs,__CurrentUser.lang), __CurrentUser.lang= Neighbourhood_type_lang.lang,Neighbourhood_type_lang.lang="English"))]neighbourhood_type_lang_ neighbourhood_type. Cannot be null. */
  neighbourhood_type?: Maybe<Scalars['String']>;
  /** The associated objects for the role neighbourhood_type_lang_. */
  neighbourhood_type_lang_?: Maybe<Neighbourhood_Type_LangPage>;
};


/** Represents an object of class Neighbourhood_type, as defined in the model. */
export type Neighbourhood_TypeNeighbourhood_Type_Lang_Args = {
  options?: InputMaybe<Neighbourhood_Type_LangPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Neighbourhood_type.
 */
export type Neighbourhood_TypeCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  langs?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  neighbourhood_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class Neighbourhood_type */
export type Neighbourhood_TypeDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Neighbourhood_type.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Neighbourhood_TypeFilter = {
  AND?: InputMaybe<Array<Neighbourhood_TypeFilter>>;
  NOT?: InputMaybe<Neighbourhood_TypeFilter>;
  OR?: InputMaybe<Array<Neighbourhood_TypeFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  langs___contains?: InputMaybe<Scalars['String']>;
  langs___ends_with?: InputMaybe<Scalars['String']>;
  langs___eq?: InputMaybe<Scalars['String']>;
  langs___gt?: InputMaybe<Scalars['String']>;
  langs___gte?: InputMaybe<Scalars['String']>;
  langs___in?: InputMaybe<Array<Scalars['String']>>;
  langs___lt?: InputMaybe<Scalars['String']>;
  langs___lte?: InputMaybe<Scalars['String']>;
  langs___ne?: InputMaybe<Scalars['String']>;
  langs___not___contains?: InputMaybe<Scalars['String']>;
  langs___not___ends_with?: InputMaybe<Scalars['String']>;
  langs___not___in?: InputMaybe<Array<Scalars['String']>>;
  langs___not___null?: InputMaybe<Scalars['Boolean']>;
  langs___not___starts_with?: InputMaybe<Scalars['String']>;
  langs___null?: InputMaybe<Scalars['Boolean']>;
  langs___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___contains?: InputMaybe<Scalars['String']>;
  neighbourhood_type___ends_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___eq?: InputMaybe<Scalars['String']>;
  neighbourhood_type___gt?: InputMaybe<Scalars['String']>;
  neighbourhood_type___gte?: InputMaybe<Scalars['String']>;
  neighbourhood_type___in?: InputMaybe<Array<Scalars['String']>>;
  neighbourhood_type___lt?: InputMaybe<Scalars['String']>;
  neighbourhood_type___lte?: InputMaybe<Scalars['String']>;
  neighbourhood_type___ne?: InputMaybe<Scalars['String']>;
  neighbourhood_type___not___contains?: InputMaybe<Scalars['String']>;
  neighbourhood_type___not___ends_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___not___in?: InputMaybe<Array<Scalars['String']>>;
  neighbourhood_type___not___null?: InputMaybe<Scalars['Boolean']>;
  neighbourhood_type___not___starts_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___null?: InputMaybe<Scalars['Boolean']>;
  neighbourhood_type___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Neighbourhood_type. */
export type Neighbourhood_TypePage = {
  __typename?: 'Neighbourhood_typePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Neighbourhood_type. */
  items: Array<Neighbourhood_Type>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Neighbourhood_type present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Neighbourhood_typePage. */
export type Neighbourhood_TypePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Neighbourhood_TypeFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Neighbourhood_TypeCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Neighbourhood_TypeSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Neighbourhood_type */
export type Neighbourhood_TypeRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Neighbourhood_TypeSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  langs___ASC = 'langs___ASC',
  langs___DESC = 'langs___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  neighbourhood_type___ASC = 'neighbourhood_type___ASC',
  neighbourhood_type___DESC = 'neighbourhood_type___DESC'
}

/** Represents an object of class Neighbourhood_type_lang, as defined in the model. */
export type Neighbourhood_Type_Lang = {
  __typename?: 'Neighbourhood_type_lang';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Neighbourhood_type_lang. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute lang of Neighbourhood_type_lang. It is of type String. Path: lang_ name. Cannot be null. */
  lang?: Maybe<Scalars['String']>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
  /** The attribute neighbourhood_type of Neighbourhood_type_lang. It is of type String. Max length: 255. Cannot be null. */
  neighbourhood_type?: Maybe<Scalars['String']>;
  /** The associated object for the role neighbourhood_type_. */
  neighbourhood_type_?: Maybe<Neighbourhood_Type>;
};


/** Represents an object of class Neighbourhood_type_lang, as defined in the model. */
export type Neighbourhood_Type_LangLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};

/** A data structure for objects of Neighbourhood_type_lang. */
export type Neighbourhood_Type_LangCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role lang_(0,1) <-- (0,N)neighbourhood_type_lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute neighbourhood_type of Neighbourhood_type_lang. It is of type String!. Max length: 255. Cannot be null. */
  neighbourhood_type: Scalars['String'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Neighbourhood_type_lang.
 */
export type Neighbourhood_Type_LangCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  lang?: InputMaybe<Scalars['String']>;
  neighbourhood_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft object of class Neighbourhood_type_lang. */
export type Neighbourhood_Type_LangDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The Association role lang_(0,1) <-- (0,N)neighbourhood_type_lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute neighbourhood_type of Neighbourhood_type_lang. It is of type String. Max length: 255. Cannot be null. */
  neighbourhood_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for draft create objects of class Neighbourhood_type_lang. */
export type Neighbourhood_Type_LangDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role lang_(0,1) <-- (0,N)neighbourhood_type_lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute neighbourhood_type of Neighbourhood_type_lang. It is of type String. Max length: 255. Cannot be null. */
  neighbourhood_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part objects of class Neighbourhood_type_lang */
export type Neighbourhood_Type_LangDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Neighbourhood_type_lang to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Neighbourhood_Type_LangDraft>>>;
};

/** A data structure for a draft update object of class Neighbourhood_type_lang. */
export type Neighbourhood_Type_LangDraftUpdate = {
  _id: Scalars['ID'];
  /** The Association role lang_(0,1) <-- (0,N)neighbourhood_type_lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute neighbourhood_type of Neighbourhood_type_lang. It is of type String. Max length: 255. Cannot be null. */
  neighbourhood_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft update objects of class Neighbourhood_type_lang. */
export type Neighbourhood_Type_LangDraftUpdateBulk = {
  /** The Association role lang_(0,1) <-- (0,N)neighbourhood_type_lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute neighbourhood_type of Neighbourhood_type_lang. It is of type String. Max length: 255. Cannot be null. */
  neighbourhood_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part objects of class Neighbourhood_type_lang */
export type Neighbourhood_Type_LangDraftUpdateRoleObjects = {
  /** An array of objects of class Neighbourhood_type_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Neighbourhood_Type_LangDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Neighbourhood_type_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Neighbourhood_Type_LangDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Neighbourhood_type_lang.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Neighbourhood_Type_LangFilter = {
  AND?: InputMaybe<Array<Neighbourhood_Type_LangFilter>>;
  NOT?: InputMaybe<Neighbourhood_Type_LangFilter>;
  OR?: InputMaybe<Array<Neighbourhood_Type_LangFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  lang___contains?: InputMaybe<Scalars['String']>;
  lang___ends_with?: InputMaybe<Scalars['String']>;
  lang___eq?: InputMaybe<Scalars['String']>;
  lang___gt?: InputMaybe<Scalars['String']>;
  lang___gte?: InputMaybe<Scalars['String']>;
  lang___in?: InputMaybe<Array<Scalars['String']>>;
  lang___lt?: InputMaybe<Scalars['String']>;
  lang___lte?: InputMaybe<Scalars['String']>;
  lang___ne?: InputMaybe<Scalars['String']>;
  lang___not___contains?: InputMaybe<Scalars['String']>;
  lang___not___ends_with?: InputMaybe<Scalars['String']>;
  lang___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang___not___null?: InputMaybe<Scalars['Boolean']>;
  lang___not___starts_with?: InputMaybe<Scalars['String']>;
  lang___null?: InputMaybe<Scalars['Boolean']>;
  lang___starts_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___contains?: InputMaybe<Scalars['String']>;
  neighbourhood_type___ends_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___eq?: InputMaybe<Scalars['String']>;
  neighbourhood_type___gt?: InputMaybe<Scalars['String']>;
  neighbourhood_type___gte?: InputMaybe<Scalars['String']>;
  neighbourhood_type___in?: InputMaybe<Array<Scalars['String']>>;
  neighbourhood_type___lt?: InputMaybe<Scalars['String']>;
  neighbourhood_type___lte?: InputMaybe<Scalars['String']>;
  neighbourhood_type___ne?: InputMaybe<Scalars['String']>;
  neighbourhood_type___not___contains?: InputMaybe<Scalars['String']>;
  neighbourhood_type___not___ends_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___not___in?: InputMaybe<Array<Scalars['String']>>;
  neighbourhood_type___not___null?: InputMaybe<Scalars['Boolean']>;
  neighbourhood_type___not___starts_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___null?: InputMaybe<Scalars['Boolean']>;
  neighbourhood_type___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Neighbourhood_type_lang. */
export type Neighbourhood_Type_LangPage = {
  __typename?: 'Neighbourhood_type_langPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Neighbourhood_type_lang. */
  items: Array<Neighbourhood_Type_Lang>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Neighbourhood_type_lang present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Neighbourhood_type_langPage. */
export type Neighbourhood_Type_LangPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Neighbourhood_Type_LangFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Neighbourhood_Type_LangCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Neighbourhood_Type_LangSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Neighbourhood_type_lang */
export type Neighbourhood_Type_LangRoleObjects = {
  /** An array of objects of class Neighbourhood_type_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Neighbourhood_Type_LangCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Neighbourhood_type_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Neighbourhood_Type_LangUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Neighbourhood_Type_LangSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  lang___ASC = 'lang___ASC',
  lang___DESC = 'lang___DESC',
  neighbourhood_type___ASC = 'neighbourhood_type___ASC',
  neighbourhood_type___DESC = 'neighbourhood_type___DESC'
}

/** A data structure for a single object of class Neighbourhood_type_lang. */
export type Neighbourhood_Type_LangUpdate = {
  _id: Scalars['ID'];
  /** The Association role lang_(0,1) <-- (0,N)neighbourhood_type_lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute neighbourhood_type of Neighbourhood_type_lang. It is of type String. Max length: 255. Cannot be null. */
  neighbourhood_type?: InputMaybe<Scalars['String']>;
};

/** A data structure for objects of class Neighbourhood_type_lang. */
export type Neighbourhood_Type_LangUpdateBulk = {
  /** The Association role lang_(0,1) <-- (0,N)neighbourhood_type_lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute neighbourhood_type of Neighbourhood_type_lang. It is of type String. Max length: 255. Cannot be null. */
  neighbourhood_type?: InputMaybe<Scalars['String']>;
};

/** Represents an object of class PASC_arm, as defined in the model. */
export type Pasc_Arm = {
  __typename?: 'PASC_arm';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of PASC_arm. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute hospitalized_covid of PASC_arm. It is of type Boolean. Cannot be null. */
  hospitalized_covid?: Maybe<Scalars['Boolean']>;
  /** The attribute hospitalized_other of PASC_arm. It is of type Boolean. Cannot be null. */
  hospitalized_other?: Maybe<Scalars['Boolean']>;
  /** The attribute name of PASC_arm. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The attribute outpatients_covid of PASC_arm. It is of type Boolean. Cannot be null. */
  outpatients_covid?: Maybe<Scalars['Boolean']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * PASC_arm.
 */
export type Pasc_ArmCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  hospitalized_covid?: InputMaybe<Scalars['Boolean']>;
  hospitalized_other?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  outpatients_covid?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft associated object of class PASC_arm */
export type Pasc_ArmDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type PASC_arm.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Pasc_ArmFilter = {
  AND?: InputMaybe<Array<Pasc_ArmFilter>>;
  NOT?: InputMaybe<Pasc_ArmFilter>;
  OR?: InputMaybe<Array<Pasc_ArmFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  hospitalized_covid___eq?: InputMaybe<Scalars['Boolean']>;
  hospitalized_covid___ne?: InputMaybe<Scalars['Boolean']>;
  hospitalized_covid___not___null?: InputMaybe<Scalars['Boolean']>;
  hospitalized_covid___null?: InputMaybe<Scalars['Boolean']>;
  hospitalized_other___eq?: InputMaybe<Scalars['Boolean']>;
  hospitalized_other___ne?: InputMaybe<Scalars['Boolean']>;
  hospitalized_other___not___null?: InputMaybe<Scalars['Boolean']>;
  hospitalized_other___null?: InputMaybe<Scalars['Boolean']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  outpatients_covid___eq?: InputMaybe<Scalars['Boolean']>;
  outpatients_covid___ne?: InputMaybe<Scalars['Boolean']>;
  outpatients_covid___not___null?: InputMaybe<Scalars['Boolean']>;
  outpatients_covid___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class PASC_arm. */
export type Pasc_ArmPage = {
  __typename?: 'PASC_armPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class PASC_arm. */
  items: Array<Pasc_Arm>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class PASC_arm present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a PASC_armPage. */
export type Pasc_ArmPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Pasc_ArmFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Pasc_ArmCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Pasc_ArmSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class PASC_arm */
export type Pasc_ArmRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Pasc_ArmSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  hospitalized_covid___ASC = 'hospitalized_covid___ASC',
  hospitalized_covid___DESC = 'hospitalized_covid___DESC',
  hospitalized_other___ASC = 'hospitalized_other___ASC',
  hospitalized_other___DESC = 'hospitalized_other___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  outpatients_covid___ASC = 'outpatients_covid___ASC',
  outpatients_covid___DESC = 'outpatients_covid___DESC'
}

/** Represents an object of class Patient, as defined in the model. */
export type Patient = {
  __typename?: 'Patient';
  /** The attribute PASC_arm of Patient. It is of type String. Path: pASC_arm_ name. Cannot be null. */
  PASC_arm?: Maybe<Scalars['String']>;
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _createdby of Patient. It is of type String. Cannot be null. */
  _createdby?: Maybe<Scalars['String']>;
  /** The attribute _createdon of Patient. It is of type Datetime @datetimeFormat. Cannot be null. */
  _createdon?: Maybe<Scalars['Datetime']>;
  /** The attribute _id of Patient. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute _lastmodifiedby of Patient. It is of type String. Cannot be null. */
  _lastmodifiedby?: Maybe<Scalars['String']>;
  /** The attribute _lastmodifiedon of Patient. It is of type Datetime @datetimeFormat. Cannot be null. */
  _lastmodifiedon?: Maybe<Scalars['Datetime']>;
  /** The attribute center of Patient. It is of type String. Path: center_ name. Cannot be null. */
  center?: Maybe<Scalars['String']>;
  /** The associated object for the role center_. */
  center_?: Maybe<Center>;
  /** The associable objects for the role center_. */
  center____associables?: Maybe<CenterPage>;
  /** The attribute center_code of Patient. It is of type String. Path: center_ code.  */
  center_code?: Maybe<Scalars['String']>;
  /** The attribute city_living of Patient. It is of type String. Max length: 255.  */
  city_living?: Maybe<Scalars['String']>;
  /** The attribute code of Patient. It is of type String. Max length: 255. Cannot be null. */
  code?: Maybe<Scalars['String']>;
  /** The attribute code_default of Patient. It is of type String. Expression: 'concat(firstNotNull(hospital_code,center_code),"-")'.  */
  code_default?: Maybe<Scalars['String']>;
  /** The associated objects for the role comorbidity_enrolment. */
  comorbidity_enrolment?: Maybe<ComorbidityPage>;
  /** The associable objects for the role comorbidity_enrolment. */
  comorbidity_enrolment___associables?: Maybe<ComorbidityPage>;
  /** The attribute completed of Patient. It is of type Boolean.  */
  completed?: Maybe<Scalars['Boolean']>;
  /** The attribute country_living of Patient. It is of type String. Path: country_living_ name. Cannot be null. */
  country_living?: Maybe<Scalars['String']>;
  /** The associated object for the role country_living_. */
  country_living_?: Maybe<Country>;
  /** The associable objects for the role country_living_. */
  country_living____associables?: Maybe<CountryPage>;
  /** The attribute country_origin of Patient. It is of type String. Path: country_origin_ name. Cannot be null. */
  country_origin?: Maybe<Scalars['String']>;
  /** The associated object for the role country_origin_. */
  country_origin_?: Maybe<Country>;
  /** The associable objects for the role country_origin_. */
  country_origin____associables?: Maybe<CountryPage>;
  /** The attribute ethnicity of Patient. It is of type String. Path: ethnicity_ name. Cannot be null. */
  ethnicity?: Maybe<Scalars['String']>;
  /** The associated object for the role ethnicity_. */
  ethnicity_?: Maybe<Ethnicity>;
  /** The associable objects for the role ethnicity_. */
  ethnicity____associables?: Maybe<EthnicityPage>;
  /** The associated objects for the role examination_. */
  examination_?: Maybe<Instrumental_ExaminationPage>;
  /** The attribute gender of Patient. It is of type String. Path: gender_ name. Cannot be null. */
  gender?: Maybe<Scalars['String']>;
  /** The associated object for the role gender_. */
  gender_?: Maybe<Gender>;
  /** The associable objects for the role gender_. */
  gender____associables?: Maybe<GenderPage>;
  /** The attribute hcw of Patient. It is of type Boolean. Path: OR - Logical ORstudy_ hcw.  */
  hcw?: Maybe<Scalars['Boolean']>;
  /** The attribute height of Patient. It is of type Int.  */
  height?: Maybe<Scalars['Int']>;
  /** The associated object for the role hospital_. */
  hospital_?: Maybe<Hospital>;
  /** The associable objects for the role hospital_. */
  hospital____associables?: Maybe<HospitalPage>;
  /** The attribute hospital_code of Patient. It is of type String. Path: hospital_ code. Cannot be null. */
  hospital_code?: Maybe<Scalars['String']>;
  /** The attribute hospitalized of Patient. It is of type Boolean. Path: OR - Logical ORstudy_ hospitalized.  */
  hospitalized?: Maybe<Scalars['Boolean']>;
  /** The associated object for the role neighbourhood_type_. */
  neighbourhood_type_?: Maybe<Neighbourhood_Type>;
  /** The associable objects for the role neighbourhood_type_. */
  neighbourhood_type____associables?: Maybe<Neighbourhood_TypePage>;
  /** The associated object for the role pASC_arm_. */
  pASC_arm_?: Maybe<Pasc_Arm>;
  /** The associable objects for the role pASC_arm_. */
  pASC_arm____associables?: Maybe<Pasc_ArmPage>;
  /** The attribute pasc_ of Patient. It is of type Boolean. Path: OR - Logical ORstudy_ pasc.  */
  pasc_?: Maybe<Scalars['Boolean']>;
  /** The attribute risk_factor of Patient. It is of type String. Path: risk_factor_ name. Cannot be null. */
  risk_factor?: Maybe<Scalars['String']>;
  /** The associated object for the role risk_factor_. */
  risk_factor_?: Maybe<Risk_Factor>;
  /** The associable objects for the role risk_factor_. */
  risk_factor____associables?: Maybe<Risk_FactorPage>;
  /** The attribute samples of Patient. It is of type Int. Path: COUNT_ALL - Count allsample_ sampling_date. Cannot be null. */
  samples?: Maybe<Scalars['Int']>;
  /** The attribute serial_id of Patient. It is of type Serial. Cannot be null. */
  serial_id?: Maybe<Scalars['Serial']>;
  /** The attribute studies of Patient. It is of type String. Path: CONCAT_CS - Concat (comma separated)study_ name. Cannot be null. */
  studies?: Maybe<Scalars['String']>;
  /** The associated objects for the role study_. */
  study_?: Maybe<StudyPage>;
  /** The associable objects for the role study_. */
  study____associables?: Maybe<StudyPage>;
  /** The attribute symptoms_unknown of Patient. It is of type Boolean.  */
  symptoms_unknown?: Maybe<Scalars['Boolean']>;
  /** The associated objects for the role treatment_. */
  treatment_?: Maybe<TreatmentPage>;
  /**
   * The attribute vaccinated of Patient. It is of type String. Domain: Yes OR No OR Unknown.
   * Max length: 255.
   */
  vaccinated?: Maybe<Scalars['String']>;
  /** The attribute vaccine_doses of Patient. It is of type Int. Path: COUNT_ALL - Count allvaccine_dose_ date.  */
  vaccine_doses?: Maybe<Scalars['Int']>;
  /** The attribute year_of_birth of Patient. It is of type Year. Cannot be null. */
  year_of_birth?: Maybe<Scalars['Year']>;
};


/** Represents an object of class Patient, as defined in the model. */
export type Patient_CreatedonArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Patient, as defined in the model. */
export type Patient_LastmodifiedonArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientCenter____AssociablesArgs = {
  options?: InputMaybe<CenterPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientComorbidity_EnrolmentArgs = {
  options?: InputMaybe<ComorbidityPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientComorbidity_Enrolment___AssociablesArgs = {
  options?: InputMaybe<ComorbidityPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientCountry_Living____AssociablesArgs = {
  options?: InputMaybe<CountryPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientCountry_Origin____AssociablesArgs = {
  options?: InputMaybe<CountryPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientEthnicity____AssociablesArgs = {
  options?: InputMaybe<EthnicityPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientExamination_Args = {
  options?: InputMaybe<Instrumental_ExaminationPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientGender____AssociablesArgs = {
  options?: InputMaybe<GenderPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientHospital____AssociablesArgs = {
  options?: InputMaybe<HospitalPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientNeighbourhood_Type____AssociablesArgs = {
  options?: InputMaybe<Neighbourhood_TypePageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientPAsc_Arm____AssociablesArgs = {
  options?: InputMaybe<Pasc_ArmPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientRisk_Factor____AssociablesArgs = {
  options?: InputMaybe<Risk_FactorPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientStudy_Args = {
  options?: InputMaybe<StudyPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientStudy____AssociablesArgs = {
  options?: InputMaybe<StudyPageOptions>;
};


/** Represents an object of class Patient, as defined in the model. */
export type PatientTreatment_Args = {
  options?: InputMaybe<TreatmentPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Patient.
 */
export type PatientCursor = {
  PASC_arm?: InputMaybe<Scalars['String']>;
  _createdby?: InputMaybe<Scalars['String']>;
  _createdon?: InputMaybe<Scalars['Datetime']>;
  _id?: InputMaybe<Scalars['ID']>;
  _lastmodifiedby?: InputMaybe<Scalars['String']>;
  _lastmodifiedon?: InputMaybe<Scalars['Datetime']>;
  center?: InputMaybe<Scalars['String']>;
  center_code?: InputMaybe<Scalars['String']>;
  city_living?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  code_default?: InputMaybe<Scalars['String']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  country_living?: InputMaybe<Scalars['String']>;
  country_origin?: InputMaybe<Scalars['String']>;
  ethnicity?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  hcw?: InputMaybe<Scalars['Boolean']>;
  height?: InputMaybe<Scalars['Int']>;
  hospital_code?: InputMaybe<Scalars['String']>;
  hospitalized?: InputMaybe<Scalars['Boolean']>;
  pasc_?: InputMaybe<Scalars['Boolean']>;
  risk_factor?: InputMaybe<Scalars['String']>;
  samples?: InputMaybe<Scalars['Int']>;
  serial_id?: InputMaybe<Scalars['Serial']>;
  studies?: InputMaybe<Scalars['String']>;
  symptoms_unknown?: InputMaybe<Scalars['Boolean']>;
  vaccinated?: InputMaybe<Scalars['String']>;
  vaccine_doses?: InputMaybe<Scalars['Int']>;
  year_of_birth?: InputMaybe<Scalars['Year']>;
};

/** A data structure for the draft associated object of class Patient */
export type PatientDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Patient.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type PatientFilter = {
  AND?: InputMaybe<Array<PatientFilter>>;
  NOT?: InputMaybe<PatientFilter>;
  OR?: InputMaybe<Array<PatientFilter>>;
  PASC_arm___contains?: InputMaybe<Scalars['String']>;
  PASC_arm___ends_with?: InputMaybe<Scalars['String']>;
  PASC_arm___eq?: InputMaybe<Scalars['String']>;
  PASC_arm___gt?: InputMaybe<Scalars['String']>;
  PASC_arm___gte?: InputMaybe<Scalars['String']>;
  PASC_arm___in?: InputMaybe<Array<Scalars['String']>>;
  PASC_arm___lt?: InputMaybe<Scalars['String']>;
  PASC_arm___lte?: InputMaybe<Scalars['String']>;
  PASC_arm___ne?: InputMaybe<Scalars['String']>;
  PASC_arm___not___contains?: InputMaybe<Scalars['String']>;
  PASC_arm___not___ends_with?: InputMaybe<Scalars['String']>;
  PASC_arm___not___in?: InputMaybe<Array<Scalars['String']>>;
  PASC_arm___not___null?: InputMaybe<Scalars['Boolean']>;
  PASC_arm___not___starts_with?: InputMaybe<Scalars['String']>;
  PASC_arm___null?: InputMaybe<Scalars['Boolean']>;
  PASC_arm___starts_with?: InputMaybe<Scalars['String']>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _createdby___contains?: InputMaybe<Scalars['String']>;
  _createdby___ends_with?: InputMaybe<Scalars['String']>;
  _createdby___eq?: InputMaybe<Scalars['String']>;
  _createdby___gt?: InputMaybe<Scalars['String']>;
  _createdby___gte?: InputMaybe<Scalars['String']>;
  _createdby___in?: InputMaybe<Array<Scalars['String']>>;
  _createdby___lt?: InputMaybe<Scalars['String']>;
  _createdby___lte?: InputMaybe<Scalars['String']>;
  _createdby___ne?: InputMaybe<Scalars['String']>;
  _createdby___not___contains?: InputMaybe<Scalars['String']>;
  _createdby___not___ends_with?: InputMaybe<Scalars['String']>;
  _createdby___not___in?: InputMaybe<Array<Scalars['String']>>;
  _createdby___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdby___not___starts_with?: InputMaybe<Scalars['String']>;
  _createdby___null?: InputMaybe<Scalars['Boolean']>;
  _createdby___starts_with?: InputMaybe<Scalars['String']>;
  _createdon___eq?: InputMaybe<Scalars['Datetime']>;
  _createdon___gt?: InputMaybe<Scalars['Datetime']>;
  _createdon___gte?: InputMaybe<Scalars['Datetime']>;
  _createdon___in?: InputMaybe<Array<InputMaybe<Scalars['Datetime']>>>;
  _createdon___lt?: InputMaybe<Scalars['Datetime']>;
  _createdon___lte?: InputMaybe<Scalars['Datetime']>;
  _createdon___ne?: InputMaybe<Scalars['Datetime']>;
  _createdon___not___in?: InputMaybe<Array<Scalars['Datetime']>>;
  _createdon___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdon___null?: InputMaybe<Scalars['Boolean']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedby___contains?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___ends_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___eq?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___gt?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___gte?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___in?: InputMaybe<Array<Scalars['String']>>;
  _lastmodifiedby___lt?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___lte?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___ne?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___not___contains?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___not___ends_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___not___in?: InputMaybe<Array<Scalars['String']>>;
  _lastmodifiedby___not___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedby___not___starts_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedby___starts_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedon___eq?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___gt?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___gte?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___in?: InputMaybe<Array<InputMaybe<Scalars['Datetime']>>>;
  _lastmodifiedon___lt?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___lte?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___ne?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___not___in?: InputMaybe<Array<Scalars['Datetime']>>;
  _lastmodifiedon___not___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedon___null?: InputMaybe<Scalars['Boolean']>;
  center___contains?: InputMaybe<Scalars['String']>;
  center___ends_with?: InputMaybe<Scalars['String']>;
  center___eq?: InputMaybe<Scalars['String']>;
  center___gt?: InputMaybe<Scalars['String']>;
  center___gte?: InputMaybe<Scalars['String']>;
  center___in?: InputMaybe<Array<Scalars['String']>>;
  center___lt?: InputMaybe<Scalars['String']>;
  center___lte?: InputMaybe<Scalars['String']>;
  center___ne?: InputMaybe<Scalars['String']>;
  center___not___contains?: InputMaybe<Scalars['String']>;
  center___not___ends_with?: InputMaybe<Scalars['String']>;
  center___not___in?: InputMaybe<Array<Scalars['String']>>;
  center___not___null?: InputMaybe<Scalars['Boolean']>;
  center___not___starts_with?: InputMaybe<Scalars['String']>;
  center___null?: InputMaybe<Scalars['Boolean']>;
  center___starts_with?: InputMaybe<Scalars['String']>;
  center_code___contains?: InputMaybe<Scalars['String']>;
  center_code___ends_with?: InputMaybe<Scalars['String']>;
  center_code___eq?: InputMaybe<Scalars['String']>;
  center_code___gt?: InputMaybe<Scalars['String']>;
  center_code___gte?: InputMaybe<Scalars['String']>;
  center_code___in?: InputMaybe<Array<Scalars['String']>>;
  center_code___lt?: InputMaybe<Scalars['String']>;
  center_code___lte?: InputMaybe<Scalars['String']>;
  center_code___ne?: InputMaybe<Scalars['String']>;
  center_code___not___contains?: InputMaybe<Scalars['String']>;
  center_code___not___ends_with?: InputMaybe<Scalars['String']>;
  center_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  center_code___not___null?: InputMaybe<Scalars['Boolean']>;
  center_code___not___starts_with?: InputMaybe<Scalars['String']>;
  center_code___null?: InputMaybe<Scalars['Boolean']>;
  center_code___starts_with?: InputMaybe<Scalars['String']>;
  city_living___contains?: InputMaybe<Scalars['String']>;
  city_living___ends_with?: InputMaybe<Scalars['String']>;
  city_living___eq?: InputMaybe<Scalars['String']>;
  city_living___gt?: InputMaybe<Scalars['String']>;
  city_living___gte?: InputMaybe<Scalars['String']>;
  city_living___in?: InputMaybe<Array<Scalars['String']>>;
  city_living___lt?: InputMaybe<Scalars['String']>;
  city_living___lte?: InputMaybe<Scalars['String']>;
  city_living___ne?: InputMaybe<Scalars['String']>;
  city_living___not___contains?: InputMaybe<Scalars['String']>;
  city_living___not___ends_with?: InputMaybe<Scalars['String']>;
  city_living___not___in?: InputMaybe<Array<Scalars['String']>>;
  city_living___not___null?: InputMaybe<Scalars['Boolean']>;
  city_living___not___starts_with?: InputMaybe<Scalars['String']>;
  city_living___null?: InputMaybe<Scalars['Boolean']>;
  city_living___starts_with?: InputMaybe<Scalars['String']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  code_default___contains?: InputMaybe<Scalars['String']>;
  code_default___ends_with?: InputMaybe<Scalars['String']>;
  code_default___eq?: InputMaybe<Scalars['String']>;
  code_default___gt?: InputMaybe<Scalars['String']>;
  code_default___gte?: InputMaybe<Scalars['String']>;
  code_default___in?: InputMaybe<Array<Scalars['String']>>;
  code_default___lt?: InputMaybe<Scalars['String']>;
  code_default___lte?: InputMaybe<Scalars['String']>;
  code_default___ne?: InputMaybe<Scalars['String']>;
  code_default___not___contains?: InputMaybe<Scalars['String']>;
  code_default___not___ends_with?: InputMaybe<Scalars['String']>;
  code_default___not___in?: InputMaybe<Array<Scalars['String']>>;
  code_default___not___null?: InputMaybe<Scalars['Boolean']>;
  code_default___not___starts_with?: InputMaybe<Scalars['String']>;
  code_default___null?: InputMaybe<Scalars['Boolean']>;
  code_default___starts_with?: InputMaybe<Scalars['String']>;
  completed___eq?: InputMaybe<Scalars['Boolean']>;
  completed___ne?: InputMaybe<Scalars['Boolean']>;
  completed___not___null?: InputMaybe<Scalars['Boolean']>;
  completed___null?: InputMaybe<Scalars['Boolean']>;
  country_living___contains?: InputMaybe<Scalars['String']>;
  country_living___ends_with?: InputMaybe<Scalars['String']>;
  country_living___eq?: InputMaybe<Scalars['String']>;
  country_living___gt?: InputMaybe<Scalars['String']>;
  country_living___gte?: InputMaybe<Scalars['String']>;
  country_living___in?: InputMaybe<Array<Scalars['String']>>;
  country_living___lt?: InputMaybe<Scalars['String']>;
  country_living___lte?: InputMaybe<Scalars['String']>;
  country_living___ne?: InputMaybe<Scalars['String']>;
  country_living___not___contains?: InputMaybe<Scalars['String']>;
  country_living___not___ends_with?: InputMaybe<Scalars['String']>;
  country_living___not___in?: InputMaybe<Array<Scalars['String']>>;
  country_living___not___null?: InputMaybe<Scalars['Boolean']>;
  country_living___not___starts_with?: InputMaybe<Scalars['String']>;
  country_living___null?: InputMaybe<Scalars['Boolean']>;
  country_living___starts_with?: InputMaybe<Scalars['String']>;
  country_origin___contains?: InputMaybe<Scalars['String']>;
  country_origin___ends_with?: InputMaybe<Scalars['String']>;
  country_origin___eq?: InputMaybe<Scalars['String']>;
  country_origin___gt?: InputMaybe<Scalars['String']>;
  country_origin___gte?: InputMaybe<Scalars['String']>;
  country_origin___in?: InputMaybe<Array<Scalars['String']>>;
  country_origin___lt?: InputMaybe<Scalars['String']>;
  country_origin___lte?: InputMaybe<Scalars['String']>;
  country_origin___ne?: InputMaybe<Scalars['String']>;
  country_origin___not___contains?: InputMaybe<Scalars['String']>;
  country_origin___not___ends_with?: InputMaybe<Scalars['String']>;
  country_origin___not___in?: InputMaybe<Array<Scalars['String']>>;
  country_origin___not___null?: InputMaybe<Scalars['Boolean']>;
  country_origin___not___starts_with?: InputMaybe<Scalars['String']>;
  country_origin___null?: InputMaybe<Scalars['Boolean']>;
  country_origin___starts_with?: InputMaybe<Scalars['String']>;
  ethnicity___contains?: InputMaybe<Scalars['String']>;
  ethnicity___ends_with?: InputMaybe<Scalars['String']>;
  ethnicity___eq?: InputMaybe<Scalars['String']>;
  ethnicity___gt?: InputMaybe<Scalars['String']>;
  ethnicity___gte?: InputMaybe<Scalars['String']>;
  ethnicity___in?: InputMaybe<Array<Scalars['String']>>;
  ethnicity___lt?: InputMaybe<Scalars['String']>;
  ethnicity___lte?: InputMaybe<Scalars['String']>;
  ethnicity___ne?: InputMaybe<Scalars['String']>;
  ethnicity___not___contains?: InputMaybe<Scalars['String']>;
  ethnicity___not___ends_with?: InputMaybe<Scalars['String']>;
  ethnicity___not___in?: InputMaybe<Array<Scalars['String']>>;
  ethnicity___not___null?: InputMaybe<Scalars['Boolean']>;
  ethnicity___not___starts_with?: InputMaybe<Scalars['String']>;
  ethnicity___null?: InputMaybe<Scalars['Boolean']>;
  ethnicity___starts_with?: InputMaybe<Scalars['String']>;
  gender___contains?: InputMaybe<Scalars['String']>;
  gender___ends_with?: InputMaybe<Scalars['String']>;
  gender___eq?: InputMaybe<Scalars['String']>;
  gender___gt?: InputMaybe<Scalars['String']>;
  gender___gte?: InputMaybe<Scalars['String']>;
  gender___in?: InputMaybe<Array<Scalars['String']>>;
  gender___lt?: InputMaybe<Scalars['String']>;
  gender___lte?: InputMaybe<Scalars['String']>;
  gender___ne?: InputMaybe<Scalars['String']>;
  gender___not___contains?: InputMaybe<Scalars['String']>;
  gender___not___ends_with?: InputMaybe<Scalars['String']>;
  gender___not___in?: InputMaybe<Array<Scalars['String']>>;
  gender___not___null?: InputMaybe<Scalars['Boolean']>;
  gender___not___starts_with?: InputMaybe<Scalars['String']>;
  gender___null?: InputMaybe<Scalars['Boolean']>;
  gender___starts_with?: InputMaybe<Scalars['String']>;
  hcw___eq?: InputMaybe<Scalars['Boolean']>;
  hcw___ne?: InputMaybe<Scalars['Boolean']>;
  hcw___not___null?: InputMaybe<Scalars['Boolean']>;
  hcw___null?: InputMaybe<Scalars['Boolean']>;
  height___eq?: InputMaybe<Scalars['Int']>;
  height___gt?: InputMaybe<Scalars['Int']>;
  height___gte?: InputMaybe<Scalars['Int']>;
  height___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  height___lt?: InputMaybe<Scalars['Int']>;
  height___lte?: InputMaybe<Scalars['Int']>;
  height___ne?: InputMaybe<Scalars['Int']>;
  height___not___in?: InputMaybe<Array<Scalars['Int']>>;
  height___not___null?: InputMaybe<Scalars['Boolean']>;
  height___null?: InputMaybe<Scalars['Boolean']>;
  hospital_code___contains?: InputMaybe<Scalars['String']>;
  hospital_code___ends_with?: InputMaybe<Scalars['String']>;
  hospital_code___eq?: InputMaybe<Scalars['String']>;
  hospital_code___gt?: InputMaybe<Scalars['String']>;
  hospital_code___gte?: InputMaybe<Scalars['String']>;
  hospital_code___in?: InputMaybe<Array<Scalars['String']>>;
  hospital_code___lt?: InputMaybe<Scalars['String']>;
  hospital_code___lte?: InputMaybe<Scalars['String']>;
  hospital_code___ne?: InputMaybe<Scalars['String']>;
  hospital_code___not___contains?: InputMaybe<Scalars['String']>;
  hospital_code___not___ends_with?: InputMaybe<Scalars['String']>;
  hospital_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  hospital_code___not___null?: InputMaybe<Scalars['Boolean']>;
  hospital_code___not___starts_with?: InputMaybe<Scalars['String']>;
  hospital_code___null?: InputMaybe<Scalars['Boolean']>;
  hospital_code___starts_with?: InputMaybe<Scalars['String']>;
  hospitalized___eq?: InputMaybe<Scalars['Boolean']>;
  hospitalized___ne?: InputMaybe<Scalars['Boolean']>;
  hospitalized___not___null?: InputMaybe<Scalars['Boolean']>;
  hospitalized___null?: InputMaybe<Scalars['Boolean']>;
  pasc____eq?: InputMaybe<Scalars['Boolean']>;
  pasc____ne?: InputMaybe<Scalars['Boolean']>;
  pasc____not___null?: InputMaybe<Scalars['Boolean']>;
  pasc____null?: InputMaybe<Scalars['Boolean']>;
  risk_factor___contains?: InputMaybe<Scalars['String']>;
  risk_factor___ends_with?: InputMaybe<Scalars['String']>;
  risk_factor___eq?: InputMaybe<Scalars['String']>;
  risk_factor___gt?: InputMaybe<Scalars['String']>;
  risk_factor___gte?: InputMaybe<Scalars['String']>;
  risk_factor___in?: InputMaybe<Array<Scalars['String']>>;
  risk_factor___lt?: InputMaybe<Scalars['String']>;
  risk_factor___lte?: InputMaybe<Scalars['String']>;
  risk_factor___ne?: InputMaybe<Scalars['String']>;
  risk_factor___not___contains?: InputMaybe<Scalars['String']>;
  risk_factor___not___ends_with?: InputMaybe<Scalars['String']>;
  risk_factor___not___in?: InputMaybe<Array<Scalars['String']>>;
  risk_factor___not___null?: InputMaybe<Scalars['Boolean']>;
  risk_factor___not___starts_with?: InputMaybe<Scalars['String']>;
  risk_factor___null?: InputMaybe<Scalars['Boolean']>;
  risk_factor___starts_with?: InputMaybe<Scalars['String']>;
  samples___eq?: InputMaybe<Scalars['Int']>;
  samples___gt?: InputMaybe<Scalars['Int']>;
  samples___gte?: InputMaybe<Scalars['Int']>;
  samples___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  samples___lt?: InputMaybe<Scalars['Int']>;
  samples___lte?: InputMaybe<Scalars['Int']>;
  samples___ne?: InputMaybe<Scalars['Int']>;
  samples___not___in?: InputMaybe<Array<Scalars['Int']>>;
  samples___not___null?: InputMaybe<Scalars['Boolean']>;
  samples___null?: InputMaybe<Scalars['Boolean']>;
  serial_id___eq?: InputMaybe<Scalars['Serial']>;
  serial_id___gt?: InputMaybe<Scalars['Serial']>;
  serial_id___gte?: InputMaybe<Scalars['Serial']>;
  serial_id___in?: InputMaybe<Array<InputMaybe<Scalars['Serial']>>>;
  serial_id___lt?: InputMaybe<Scalars['Serial']>;
  serial_id___lte?: InputMaybe<Scalars['Serial']>;
  serial_id___ne?: InputMaybe<Scalars['Serial']>;
  serial_id___not___in?: InputMaybe<Array<Scalars['Serial']>>;
  serial_id___not___null?: InputMaybe<Scalars['Boolean']>;
  serial_id___null?: InputMaybe<Scalars['Boolean']>;
  studies___contains?: InputMaybe<Scalars['String']>;
  studies___ends_with?: InputMaybe<Scalars['String']>;
  studies___eq?: InputMaybe<Scalars['String']>;
  studies___gt?: InputMaybe<Scalars['String']>;
  studies___gte?: InputMaybe<Scalars['String']>;
  studies___in?: InputMaybe<Array<Scalars['String']>>;
  studies___lt?: InputMaybe<Scalars['String']>;
  studies___lte?: InputMaybe<Scalars['String']>;
  studies___ne?: InputMaybe<Scalars['String']>;
  studies___not___contains?: InputMaybe<Scalars['String']>;
  studies___not___ends_with?: InputMaybe<Scalars['String']>;
  studies___not___in?: InputMaybe<Array<Scalars['String']>>;
  studies___not___null?: InputMaybe<Scalars['Boolean']>;
  studies___not___starts_with?: InputMaybe<Scalars['String']>;
  studies___null?: InputMaybe<Scalars['Boolean']>;
  studies___starts_with?: InputMaybe<Scalars['String']>;
  symptoms_unknown___eq?: InputMaybe<Scalars['Boolean']>;
  symptoms_unknown___ne?: InputMaybe<Scalars['Boolean']>;
  symptoms_unknown___not___null?: InputMaybe<Scalars['Boolean']>;
  symptoms_unknown___null?: InputMaybe<Scalars['Boolean']>;
  vaccinated___contains?: InputMaybe<Scalars['String']>;
  vaccinated___ends_with?: InputMaybe<Scalars['String']>;
  vaccinated___eq?: InputMaybe<Scalars['String']>;
  vaccinated___gt?: InputMaybe<Scalars['String']>;
  vaccinated___gte?: InputMaybe<Scalars['String']>;
  vaccinated___in?: InputMaybe<Array<Scalars['String']>>;
  vaccinated___lt?: InputMaybe<Scalars['String']>;
  vaccinated___lte?: InputMaybe<Scalars['String']>;
  vaccinated___ne?: InputMaybe<Scalars['String']>;
  vaccinated___not___contains?: InputMaybe<Scalars['String']>;
  vaccinated___not___ends_with?: InputMaybe<Scalars['String']>;
  vaccinated___not___in?: InputMaybe<Array<Scalars['String']>>;
  vaccinated___not___null?: InputMaybe<Scalars['Boolean']>;
  vaccinated___not___starts_with?: InputMaybe<Scalars['String']>;
  vaccinated___null?: InputMaybe<Scalars['Boolean']>;
  vaccinated___starts_with?: InputMaybe<Scalars['String']>;
  vaccine_doses___eq?: InputMaybe<Scalars['Int']>;
  vaccine_doses___gt?: InputMaybe<Scalars['Int']>;
  vaccine_doses___gte?: InputMaybe<Scalars['Int']>;
  vaccine_doses___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  vaccine_doses___lt?: InputMaybe<Scalars['Int']>;
  vaccine_doses___lte?: InputMaybe<Scalars['Int']>;
  vaccine_doses___ne?: InputMaybe<Scalars['Int']>;
  vaccine_doses___not___in?: InputMaybe<Array<Scalars['Int']>>;
  vaccine_doses___not___null?: InputMaybe<Scalars['Boolean']>;
  vaccine_doses___null?: InputMaybe<Scalars['Boolean']>;
  year_of_birth___eq?: InputMaybe<Scalars['Year']>;
  year_of_birth___gt?: InputMaybe<Scalars['Year']>;
  year_of_birth___gte?: InputMaybe<Scalars['Year']>;
  year_of_birth___in?: InputMaybe<Array<InputMaybe<Scalars['Year']>>>;
  year_of_birth___lt?: InputMaybe<Scalars['Year']>;
  year_of_birth___lte?: InputMaybe<Scalars['Year']>;
  year_of_birth___ne?: InputMaybe<Scalars['Year']>;
  year_of_birth___not___in?: InputMaybe<Array<Scalars['Year']>>;
  year_of_birth___not___null?: InputMaybe<Scalars['Boolean']>;
  year_of_birth___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Patient. */
export type PatientPage = {
  __typename?: 'PatientPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Patient. */
  items: Array<Patient>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Patient present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a PatientPage. */
export type PatientPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<PatientFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<PatientCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<PatientSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Patient */
export type PatientRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum PatientSort {
  PASC_arm___ASC = 'PASC_arm___ASC',
  PASC_arm___DESC = 'PASC_arm___DESC',
  _createdby___ASC = '_createdby___ASC',
  _createdby___DESC = '_createdby___DESC',
  _createdon___ASC = '_createdon___ASC',
  _createdon___DESC = '_createdon___DESC',
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  _lastmodifiedby___ASC = '_lastmodifiedby___ASC',
  _lastmodifiedby___DESC = '_lastmodifiedby___DESC',
  _lastmodifiedon___ASC = '_lastmodifiedon___ASC',
  _lastmodifiedon___DESC = '_lastmodifiedon___DESC',
  center___ASC = 'center___ASC',
  center___DESC = 'center___DESC',
  center_code___ASC = 'center_code___ASC',
  center_code___DESC = 'center_code___DESC',
  city_living___ASC = 'city_living___ASC',
  city_living___DESC = 'city_living___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  code_default___ASC = 'code_default___ASC',
  code_default___DESC = 'code_default___DESC',
  completed___ASC = 'completed___ASC',
  completed___DESC = 'completed___DESC',
  country_living___ASC = 'country_living___ASC',
  country_living___DESC = 'country_living___DESC',
  country_origin___ASC = 'country_origin___ASC',
  country_origin___DESC = 'country_origin___DESC',
  ethnicity___ASC = 'ethnicity___ASC',
  ethnicity___DESC = 'ethnicity___DESC',
  gender___ASC = 'gender___ASC',
  gender___DESC = 'gender___DESC',
  hcw___ASC = 'hcw___ASC',
  hcw___DESC = 'hcw___DESC',
  height___ASC = 'height___ASC',
  height___DESC = 'height___DESC',
  hospital_code___ASC = 'hospital_code___ASC',
  hospital_code___DESC = 'hospital_code___DESC',
  hospitalized___ASC = 'hospitalized___ASC',
  hospitalized___DESC = 'hospitalized___DESC',
  pasc____ASC = 'pasc____ASC',
  pasc____DESC = 'pasc____DESC',
  risk_factor___ASC = 'risk_factor___ASC',
  risk_factor___DESC = 'risk_factor___DESC',
  samples___ASC = 'samples___ASC',
  samples___DESC = 'samples___DESC',
  serial_id___ASC = 'serial_id___ASC',
  serial_id___DESC = 'serial_id___DESC',
  studies___ASC = 'studies___ASC',
  studies___DESC = 'studies___DESC',
  symptoms_unknown___ASC = 'symptoms_unknown___ASC',
  symptoms_unknown___DESC = 'symptoms_unknown___DESC',
  vaccinated___ASC = 'vaccinated___ASC',
  vaccinated___DESC = 'vaccinated___DESC',
  vaccine_doses___ASC = 'vaccine_doses___ASC',
  vaccine_doses___DESC = 'vaccine_doses___DESC',
  year_of_birth___ASC = 'year_of_birth___ASC',
  year_of_birth___DESC = 'year_of_birth___DESC'
}

/**
 * POST <cloudletUrl>/auth/file/{class}/{attribute}
 * GET  <cloudletUrl>/auth/file/{class}/{attribute}/pending/{pendingFileId}
 * GET  <cloudletUrl>/auth/file/{class}/ID/{attribute}
 */
export type PendingFileReference = {
  fileId: Scalars['ID'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  pendingFileId: Scalars['String'];
  size: Scalars['Int'];
  uploadedOn: Scalars['Datetime'];
};

/** Represents an object of class Person, as defined in the model. */
export type Person = {
  __typename?: 'Person';
  /** The associated object for the role User_. */
  User_?: Maybe<_User>;
  /** The associable objects for the role User_. */
  User____associables?: Maybe<_UserPage>;
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _createdby of Person. It is of type String. Cannot be null. */
  _createdby?: Maybe<Scalars['String']>;
  /** The attribute _createdon of Person. It is of type Datetime @datetimeFormat. Cannot be null. */
  _createdon?: Maybe<Scalars['Datetime']>;
  /** The attribute _id of Person. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute _lastmodifiedby of Person. It is of type String. Cannot be null. */
  _lastmodifiedby?: Maybe<Scalars['String']>;
  /** The attribute _lastmodifiedon of Person. It is of type Datetime @datetimeFormat. Cannot be null. */
  _lastmodifiedon?: Maybe<Scalars['Datetime']>;
  /** The attribute center of Person. It is of type String. Path: center_ name. Cannot be null. */
  center?: Maybe<Scalars['String']>;
  /** The associated object for the role center_. */
  center_?: Maybe<Center>;
  /** The associable objects for the role center_. */
  center____associables?: Maybe<CenterPage>;
  /** The attribute children_relation of Person. It is of type String. Path: children_relation_ children_relation. Cannot be null. */
  children_relation?: Maybe<Scalars['String']>;
  /** The associated object for the role children_relation_. */
  children_relation_?: Maybe<Children_Relation>;
  /** The associable objects for the role children_relation_. */
  children_relation____associables?: Maybe<Children_RelationPage>;
  /** The attribute code of Person. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The attribute commute_vehicle of Person. It is of type String. Path: commute_vehicle_ commute_vehicle. Cannot be null. */
  commute_vehicle?: Maybe<Scalars['String']>;
  /** The associated object for the role commute_vehicle_. */
  commute_vehicle_?: Maybe<Commute_Vehicle>;
  /** The associable objects for the role commute_vehicle_. */
  commute_vehicle____associables?: Maybe<Commute_VehiclePage>;
  /** The attribute consent_withdrawn of Person. It is of type Boolean.  */
  consent_withdrawn?: Maybe<Scalars['Boolean']>;
  /** The attribute date_last_infection of Person. It is of type Date @dateFormat.  */
  date_last_infection?: Maybe<Scalars['Date']>;
  /** The attribute edit_mode of Person. It is of type Boolean. Cannot be null. */
  edit_mode?: Maybe<Scalars['Boolean']>;
  /** The attribute educational_level of Person. It is of type String. Path: educational_level_ educational_level. Cannot be null. */
  educational_level?: Maybe<Scalars['String']>;
  /** The associated object for the role educational_level_. */
  educational_level_?: Maybe<Educational_Level>;
  /** The associable objects for the role educational_level_. */
  educational_level____associables?: Maybe<Educational_LevelPage>;
  /** The attribute email of Person. It is of type String. Max length: 255.  */
  email?: Maybe<Scalars['String']>;
  /** The associated object for the role family_. */
  family_?: Maybe<Family>;
  /** The associable objects for the role family_. */
  family____associables?: Maybe<FamilyPage>;
  /** The attribute family_email of Person. It is of type String. Path: family_ email. Cannot be null. */
  family_email?: Maybe<Scalars['String']>;
  /** The attribute family_member of Person. It is of type Boolean. Path: person_type_ family_member. Cannot be null. */
  family_member?: Maybe<Scalars['Boolean']>;
  /** The attribute family_school_ids of Person. It is of type String. Path: family_ school_ids. Cannot be null. */
  family_school_ids?: Maybe<Scalars['String']>;
  /** The attribute family_username of Person. It is of type String. Path: family_.User_ username. Cannot be null. */
  family_username?: Maybe<Scalars['String']>;
  /** The attribute fiscal_code of Person. It is of type String. Max length: 255. Cannot be null. */
  fiscal_code?: Maybe<Scalars['String']>;
  /**
   * The attribute gender of Person. It is of type String. Domain: M OR F.
   * Max length: 255. Cannot be null.
   */
  gender?: Maybe<Scalars['String']>;
  /** The attribute kilometers of Person. It is of type Int.  */
  kilometers?: Maybe<Scalars['Int']>;
  /** The attribute minutes of Person. It is of type Int.  */
  minutes?: Maybe<Scalars['Int']>;
  /** The attribute name of Person. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The associated objects for the role natural_infection_. */
  natural_infection_?: Maybe<Vaccine_DosePage>;
  /** The attribute non_teaching of Person. It is of type Boolean. Path: person_type_ non_teaching_staff. Cannot be null. */
  non_teaching?: Maybe<Scalars['Boolean']>;
  /** The attribute past_infection of Person. It is of type Boolean.  */
  past_infection?: Maybe<Scalars['Boolean']>;
  /** The attribute person_type of Person. It is of type String. Path: person_type_ name. Cannot be null. */
  person_type?: Maybe<Scalars['String']>;
  /** The associated object for the role person_type_. */
  person_type_?: Maybe<Person_Type>;
  /** The associable objects for the role person_type_. */
  person_type____associables?: Maybe<Person_TypePage>;
  /** The associated objects for the role school_people_. */
  school_people_?: Maybe<School_PeoplePage>;
  /** The associable objects for the role school_people_. */
  school_people____associables?: Maybe<School_PeoplePage>;
  /** The attribute schools of Person. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)school_people_ school. Cannot be null. */
  schools?: Maybe<Scalars['String']>;
  /** The attribute serial_id of Person. It is of type Serial. Cannot be null. */
  serial_id?: Maybe<Scalars['Serial']>;
  /** The attribute sidi_code of Person. It is of type String. Max length: 255.  */
  sidi_code?: Maybe<Scalars['String']>;
  /** The attribute student of Person. It is of type Boolean. Path: person_type_ student. Cannot be null. */
  student?: Maybe<Scalars['Boolean']>;
  /** The attribute students_in_family of Person. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated) [School_people.isLast_year]family_.person_.school_people_ code.  */
  students_in_family?: Maybe<Scalars['String']>;
  /** The attribute surname of Person. It is of type String. Max length: 255. Cannot be null. */
  surname?: Maybe<Scalars['String']>;
  /** The attribute teacher of Person. It is of type Boolean. Path: person_type_ teacher. Cannot be null. */
  teacher?: Maybe<Scalars['Boolean']>;
  /** The attribute test of Person. It is of type Boolean. Path: center_ test.  */
  test?: Maybe<Scalars['Boolean']>;
  /** The attribute username of Person. It is of type String. Path: User_ username. Cannot be null. */
  username?: Maybe<Scalars['String']>;
  /** The attribute vaccinated of Person. It is of type Boolean.  */
  vaccinated?: Maybe<Scalars['Boolean']>;
  /** The associated objects for the role vaccine_dose_. */
  vaccine_dose_?: Maybe<Vaccine_DosePage>;
  /** The attribute year_of_birth of Person. It is of type Year. Cannot be null. */
  year_of_birth?: Maybe<Scalars['Year']>;
};


/** Represents an object of class Person, as defined in the model. */
export type PersonUser____AssociablesArgs = {
  options?: InputMaybe<_UserPageOptions>;
};


/** Represents an object of class Person, as defined in the model. */
export type Person_CreatedonArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Person, as defined in the model. */
export type Person_LastmodifiedonArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Person, as defined in the model. */
export type PersonCenter____AssociablesArgs = {
  options?: InputMaybe<CenterPageOptions>;
};


/** Represents an object of class Person, as defined in the model. */
export type PersonChildren_Relation____AssociablesArgs = {
  options?: InputMaybe<Children_RelationPageOptions>;
};


/** Represents an object of class Person, as defined in the model. */
export type PersonCommute_Vehicle____AssociablesArgs = {
  options?: InputMaybe<Commute_VehiclePageOptions>;
};


/** Represents an object of class Person, as defined in the model. */
export type PersonDate_Last_InfectionArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Person, as defined in the model. */
export type PersonEducational_Level____AssociablesArgs = {
  options?: InputMaybe<Educational_LevelPageOptions>;
};


/** Represents an object of class Person, as defined in the model. */
export type PersonFamily____AssociablesArgs = {
  options?: InputMaybe<FamilyPageOptions>;
};


/** Represents an object of class Person, as defined in the model. */
export type PersonNatural_Infection_Args = {
  options?: InputMaybe<Vaccine_DosePageOptions>;
};


/** Represents an object of class Person, as defined in the model. */
export type PersonPerson_Type____AssociablesArgs = {
  options?: InputMaybe<Person_TypePageOptions>;
};


/** Represents an object of class Person, as defined in the model. */
export type PersonSchool_People_Args = {
  options?: InputMaybe<School_PeoplePageOptions>;
};


/** Represents an object of class Person, as defined in the model. */
export type PersonSchool_People____AssociablesArgs = {
  options?: InputMaybe<School_PeoplePageOptions>;
};


/** Represents an object of class Person, as defined in the model. */
export type PersonVaccine_Dose_Args = {
  options?: InputMaybe<Vaccine_DosePageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Person.
 */
export type PersonCursor = {
  _createdby?: InputMaybe<Scalars['String']>;
  _createdon?: InputMaybe<Scalars['Datetime']>;
  _id?: InputMaybe<Scalars['ID']>;
  _lastmodifiedby?: InputMaybe<Scalars['String']>;
  _lastmodifiedon?: InputMaybe<Scalars['Datetime']>;
  center?: InputMaybe<Scalars['String']>;
  children_relation?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  commute_vehicle?: InputMaybe<Scalars['String']>;
  consent_withdrawn?: InputMaybe<Scalars['Boolean']>;
  date_last_infection?: InputMaybe<Scalars['Date']>;
  edit_mode?: InputMaybe<Scalars['Boolean']>;
  educational_level?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  family_email?: InputMaybe<Scalars['String']>;
  family_member?: InputMaybe<Scalars['Boolean']>;
  family_school_ids?: InputMaybe<Scalars['String']>;
  family_username?: InputMaybe<Scalars['String']>;
  fiscal_code?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  kilometers?: InputMaybe<Scalars['Int']>;
  minutes?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  non_teaching?: InputMaybe<Scalars['Boolean']>;
  past_infection?: InputMaybe<Scalars['Boolean']>;
  person_type?: InputMaybe<Scalars['String']>;
  schools?: InputMaybe<Scalars['String']>;
  serial_id?: InputMaybe<Scalars['Serial']>;
  sidi_code?: InputMaybe<Scalars['String']>;
  student?: InputMaybe<Scalars['Boolean']>;
  students_in_family?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  teacher?: InputMaybe<Scalars['Boolean']>;
  test?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
  vaccinated?: InputMaybe<Scalars['Boolean']>;
  year_of_birth?: InputMaybe<Scalars['Year']>;
};

/** A data structure for the draft associated object of class Person */
export type PersonDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft associated objects of class Person */
export type PersonDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type Person.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type PersonFilter = {
  AND?: InputMaybe<Array<PersonFilter>>;
  NOT?: InputMaybe<PersonFilter>;
  OR?: InputMaybe<Array<PersonFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _createdby___contains?: InputMaybe<Scalars['String']>;
  _createdby___ends_with?: InputMaybe<Scalars['String']>;
  _createdby___eq?: InputMaybe<Scalars['String']>;
  _createdby___gt?: InputMaybe<Scalars['String']>;
  _createdby___gte?: InputMaybe<Scalars['String']>;
  _createdby___in?: InputMaybe<Array<Scalars['String']>>;
  _createdby___lt?: InputMaybe<Scalars['String']>;
  _createdby___lte?: InputMaybe<Scalars['String']>;
  _createdby___ne?: InputMaybe<Scalars['String']>;
  _createdby___not___contains?: InputMaybe<Scalars['String']>;
  _createdby___not___ends_with?: InputMaybe<Scalars['String']>;
  _createdby___not___in?: InputMaybe<Array<Scalars['String']>>;
  _createdby___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdby___not___starts_with?: InputMaybe<Scalars['String']>;
  _createdby___null?: InputMaybe<Scalars['Boolean']>;
  _createdby___starts_with?: InputMaybe<Scalars['String']>;
  _createdon___eq?: InputMaybe<Scalars['Datetime']>;
  _createdon___gt?: InputMaybe<Scalars['Datetime']>;
  _createdon___gte?: InputMaybe<Scalars['Datetime']>;
  _createdon___in?: InputMaybe<Array<InputMaybe<Scalars['Datetime']>>>;
  _createdon___lt?: InputMaybe<Scalars['Datetime']>;
  _createdon___lte?: InputMaybe<Scalars['Datetime']>;
  _createdon___ne?: InputMaybe<Scalars['Datetime']>;
  _createdon___not___in?: InputMaybe<Array<Scalars['Datetime']>>;
  _createdon___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdon___null?: InputMaybe<Scalars['Boolean']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedby___contains?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___ends_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___eq?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___gt?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___gte?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___in?: InputMaybe<Array<Scalars['String']>>;
  _lastmodifiedby___lt?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___lte?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___ne?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___not___contains?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___not___ends_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___not___in?: InputMaybe<Array<Scalars['String']>>;
  _lastmodifiedby___not___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedby___not___starts_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedby___starts_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedon___eq?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___gt?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___gte?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___in?: InputMaybe<Array<InputMaybe<Scalars['Datetime']>>>;
  _lastmodifiedon___lt?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___lte?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___ne?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___not___in?: InputMaybe<Array<Scalars['Datetime']>>;
  _lastmodifiedon___not___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedon___null?: InputMaybe<Scalars['Boolean']>;
  center___contains?: InputMaybe<Scalars['String']>;
  center___ends_with?: InputMaybe<Scalars['String']>;
  center___eq?: InputMaybe<Scalars['String']>;
  center___gt?: InputMaybe<Scalars['String']>;
  center___gte?: InputMaybe<Scalars['String']>;
  center___in?: InputMaybe<Array<Scalars['String']>>;
  center___lt?: InputMaybe<Scalars['String']>;
  center___lte?: InputMaybe<Scalars['String']>;
  center___ne?: InputMaybe<Scalars['String']>;
  center___not___contains?: InputMaybe<Scalars['String']>;
  center___not___ends_with?: InputMaybe<Scalars['String']>;
  center___not___in?: InputMaybe<Array<Scalars['String']>>;
  center___not___null?: InputMaybe<Scalars['Boolean']>;
  center___not___starts_with?: InputMaybe<Scalars['String']>;
  center___null?: InputMaybe<Scalars['Boolean']>;
  center___starts_with?: InputMaybe<Scalars['String']>;
  children_relation___contains?: InputMaybe<Scalars['String']>;
  children_relation___ends_with?: InputMaybe<Scalars['String']>;
  children_relation___eq?: InputMaybe<Scalars['String']>;
  children_relation___gt?: InputMaybe<Scalars['String']>;
  children_relation___gte?: InputMaybe<Scalars['String']>;
  children_relation___in?: InputMaybe<Array<Scalars['String']>>;
  children_relation___lt?: InputMaybe<Scalars['String']>;
  children_relation___lte?: InputMaybe<Scalars['String']>;
  children_relation___ne?: InputMaybe<Scalars['String']>;
  children_relation___not___contains?: InputMaybe<Scalars['String']>;
  children_relation___not___ends_with?: InputMaybe<Scalars['String']>;
  children_relation___not___in?: InputMaybe<Array<Scalars['String']>>;
  children_relation___not___null?: InputMaybe<Scalars['Boolean']>;
  children_relation___not___starts_with?: InputMaybe<Scalars['String']>;
  children_relation___null?: InputMaybe<Scalars['Boolean']>;
  children_relation___starts_with?: InputMaybe<Scalars['String']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  commute_vehicle___contains?: InputMaybe<Scalars['String']>;
  commute_vehicle___ends_with?: InputMaybe<Scalars['String']>;
  commute_vehicle___eq?: InputMaybe<Scalars['String']>;
  commute_vehicle___gt?: InputMaybe<Scalars['String']>;
  commute_vehicle___gte?: InputMaybe<Scalars['String']>;
  commute_vehicle___in?: InputMaybe<Array<Scalars['String']>>;
  commute_vehicle___lt?: InputMaybe<Scalars['String']>;
  commute_vehicle___lte?: InputMaybe<Scalars['String']>;
  commute_vehicle___ne?: InputMaybe<Scalars['String']>;
  commute_vehicle___not___contains?: InputMaybe<Scalars['String']>;
  commute_vehicle___not___ends_with?: InputMaybe<Scalars['String']>;
  commute_vehicle___not___in?: InputMaybe<Array<Scalars['String']>>;
  commute_vehicle___not___null?: InputMaybe<Scalars['Boolean']>;
  commute_vehicle___not___starts_with?: InputMaybe<Scalars['String']>;
  commute_vehicle___null?: InputMaybe<Scalars['Boolean']>;
  commute_vehicle___starts_with?: InputMaybe<Scalars['String']>;
  consent_withdrawn___eq?: InputMaybe<Scalars['Boolean']>;
  consent_withdrawn___ne?: InputMaybe<Scalars['Boolean']>;
  consent_withdrawn___not___null?: InputMaybe<Scalars['Boolean']>;
  consent_withdrawn___null?: InputMaybe<Scalars['Boolean']>;
  date_last_infection___eq?: InputMaybe<Scalars['Date']>;
  date_last_infection___gt?: InputMaybe<Scalars['Date']>;
  date_last_infection___gte?: InputMaybe<Scalars['Date']>;
  date_last_infection___in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  date_last_infection___lt?: InputMaybe<Scalars['Date']>;
  date_last_infection___lte?: InputMaybe<Scalars['Date']>;
  date_last_infection___ne?: InputMaybe<Scalars['Date']>;
  date_last_infection___not___in?: InputMaybe<Array<Scalars['Date']>>;
  date_last_infection___not___null?: InputMaybe<Scalars['Boolean']>;
  date_last_infection___null?: InputMaybe<Scalars['Boolean']>;
  edit_mode___eq?: InputMaybe<Scalars['Boolean']>;
  edit_mode___ne?: InputMaybe<Scalars['Boolean']>;
  edit_mode___not___null?: InputMaybe<Scalars['Boolean']>;
  edit_mode___null?: InputMaybe<Scalars['Boolean']>;
  educational_level___contains?: InputMaybe<Scalars['String']>;
  educational_level___ends_with?: InputMaybe<Scalars['String']>;
  educational_level___eq?: InputMaybe<Scalars['String']>;
  educational_level___gt?: InputMaybe<Scalars['String']>;
  educational_level___gte?: InputMaybe<Scalars['String']>;
  educational_level___in?: InputMaybe<Array<Scalars['String']>>;
  educational_level___lt?: InputMaybe<Scalars['String']>;
  educational_level___lte?: InputMaybe<Scalars['String']>;
  educational_level___ne?: InputMaybe<Scalars['String']>;
  educational_level___not___contains?: InputMaybe<Scalars['String']>;
  educational_level___not___ends_with?: InputMaybe<Scalars['String']>;
  educational_level___not___in?: InputMaybe<Array<Scalars['String']>>;
  educational_level___not___null?: InputMaybe<Scalars['Boolean']>;
  educational_level___not___starts_with?: InputMaybe<Scalars['String']>;
  educational_level___null?: InputMaybe<Scalars['Boolean']>;
  educational_level___starts_with?: InputMaybe<Scalars['String']>;
  email___contains?: InputMaybe<Scalars['String']>;
  email___ends_with?: InputMaybe<Scalars['String']>;
  email___eq?: InputMaybe<Scalars['String']>;
  email___gt?: InputMaybe<Scalars['String']>;
  email___gte?: InputMaybe<Scalars['String']>;
  email___in?: InputMaybe<Array<Scalars['String']>>;
  email___lt?: InputMaybe<Scalars['String']>;
  email___lte?: InputMaybe<Scalars['String']>;
  email___ne?: InputMaybe<Scalars['String']>;
  email___not___contains?: InputMaybe<Scalars['String']>;
  email___not___ends_with?: InputMaybe<Scalars['String']>;
  email___not___in?: InputMaybe<Array<Scalars['String']>>;
  email___not___null?: InputMaybe<Scalars['Boolean']>;
  email___not___starts_with?: InputMaybe<Scalars['String']>;
  email___null?: InputMaybe<Scalars['Boolean']>;
  email___starts_with?: InputMaybe<Scalars['String']>;
  family_email___contains?: InputMaybe<Scalars['String']>;
  family_email___ends_with?: InputMaybe<Scalars['String']>;
  family_email___eq?: InputMaybe<Scalars['String']>;
  family_email___gt?: InputMaybe<Scalars['String']>;
  family_email___gte?: InputMaybe<Scalars['String']>;
  family_email___in?: InputMaybe<Array<Scalars['String']>>;
  family_email___lt?: InputMaybe<Scalars['String']>;
  family_email___lte?: InputMaybe<Scalars['String']>;
  family_email___ne?: InputMaybe<Scalars['String']>;
  family_email___not___contains?: InputMaybe<Scalars['String']>;
  family_email___not___ends_with?: InputMaybe<Scalars['String']>;
  family_email___not___in?: InputMaybe<Array<Scalars['String']>>;
  family_email___not___null?: InputMaybe<Scalars['Boolean']>;
  family_email___not___starts_with?: InputMaybe<Scalars['String']>;
  family_email___null?: InputMaybe<Scalars['Boolean']>;
  family_email___starts_with?: InputMaybe<Scalars['String']>;
  family_member___eq?: InputMaybe<Scalars['Boolean']>;
  family_member___ne?: InputMaybe<Scalars['Boolean']>;
  family_member___not___null?: InputMaybe<Scalars['Boolean']>;
  family_member___null?: InputMaybe<Scalars['Boolean']>;
  family_school_ids___contains?: InputMaybe<Scalars['String']>;
  family_school_ids___ends_with?: InputMaybe<Scalars['String']>;
  family_school_ids___eq?: InputMaybe<Scalars['String']>;
  family_school_ids___gt?: InputMaybe<Scalars['String']>;
  family_school_ids___gte?: InputMaybe<Scalars['String']>;
  family_school_ids___in?: InputMaybe<Array<Scalars['String']>>;
  family_school_ids___lt?: InputMaybe<Scalars['String']>;
  family_school_ids___lte?: InputMaybe<Scalars['String']>;
  family_school_ids___ne?: InputMaybe<Scalars['String']>;
  family_school_ids___not___contains?: InputMaybe<Scalars['String']>;
  family_school_ids___not___ends_with?: InputMaybe<Scalars['String']>;
  family_school_ids___not___in?: InputMaybe<Array<Scalars['String']>>;
  family_school_ids___not___null?: InputMaybe<Scalars['Boolean']>;
  family_school_ids___not___starts_with?: InputMaybe<Scalars['String']>;
  family_school_ids___null?: InputMaybe<Scalars['Boolean']>;
  family_school_ids___starts_with?: InputMaybe<Scalars['String']>;
  family_username___contains?: InputMaybe<Scalars['String']>;
  family_username___ends_with?: InputMaybe<Scalars['String']>;
  family_username___eq?: InputMaybe<Scalars['String']>;
  family_username___gt?: InputMaybe<Scalars['String']>;
  family_username___gte?: InputMaybe<Scalars['String']>;
  family_username___in?: InputMaybe<Array<Scalars['String']>>;
  family_username___lt?: InputMaybe<Scalars['String']>;
  family_username___lte?: InputMaybe<Scalars['String']>;
  family_username___ne?: InputMaybe<Scalars['String']>;
  family_username___not___contains?: InputMaybe<Scalars['String']>;
  family_username___not___ends_with?: InputMaybe<Scalars['String']>;
  family_username___not___in?: InputMaybe<Array<Scalars['String']>>;
  family_username___not___null?: InputMaybe<Scalars['Boolean']>;
  family_username___not___starts_with?: InputMaybe<Scalars['String']>;
  family_username___null?: InputMaybe<Scalars['Boolean']>;
  family_username___starts_with?: InputMaybe<Scalars['String']>;
  fiscal_code___contains?: InputMaybe<Scalars['String']>;
  fiscal_code___ends_with?: InputMaybe<Scalars['String']>;
  fiscal_code___eq?: InputMaybe<Scalars['String']>;
  fiscal_code___gt?: InputMaybe<Scalars['String']>;
  fiscal_code___gte?: InputMaybe<Scalars['String']>;
  fiscal_code___in?: InputMaybe<Array<Scalars['String']>>;
  fiscal_code___lt?: InputMaybe<Scalars['String']>;
  fiscal_code___lte?: InputMaybe<Scalars['String']>;
  fiscal_code___ne?: InputMaybe<Scalars['String']>;
  fiscal_code___not___contains?: InputMaybe<Scalars['String']>;
  fiscal_code___not___ends_with?: InputMaybe<Scalars['String']>;
  fiscal_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  fiscal_code___not___null?: InputMaybe<Scalars['Boolean']>;
  fiscal_code___not___starts_with?: InputMaybe<Scalars['String']>;
  fiscal_code___null?: InputMaybe<Scalars['Boolean']>;
  fiscal_code___starts_with?: InputMaybe<Scalars['String']>;
  gender___contains?: InputMaybe<Scalars['String']>;
  gender___ends_with?: InputMaybe<Scalars['String']>;
  gender___eq?: InputMaybe<Scalars['String']>;
  gender___gt?: InputMaybe<Scalars['String']>;
  gender___gte?: InputMaybe<Scalars['String']>;
  gender___in?: InputMaybe<Array<Scalars['String']>>;
  gender___lt?: InputMaybe<Scalars['String']>;
  gender___lte?: InputMaybe<Scalars['String']>;
  gender___ne?: InputMaybe<Scalars['String']>;
  gender___not___contains?: InputMaybe<Scalars['String']>;
  gender___not___ends_with?: InputMaybe<Scalars['String']>;
  gender___not___in?: InputMaybe<Array<Scalars['String']>>;
  gender___not___null?: InputMaybe<Scalars['Boolean']>;
  gender___not___starts_with?: InputMaybe<Scalars['String']>;
  gender___null?: InputMaybe<Scalars['Boolean']>;
  gender___starts_with?: InputMaybe<Scalars['String']>;
  kilometers___eq?: InputMaybe<Scalars['Int']>;
  kilometers___gt?: InputMaybe<Scalars['Int']>;
  kilometers___gte?: InputMaybe<Scalars['Int']>;
  kilometers___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  kilometers___lt?: InputMaybe<Scalars['Int']>;
  kilometers___lte?: InputMaybe<Scalars['Int']>;
  kilometers___ne?: InputMaybe<Scalars['Int']>;
  kilometers___not___in?: InputMaybe<Array<Scalars['Int']>>;
  kilometers___not___null?: InputMaybe<Scalars['Boolean']>;
  kilometers___null?: InputMaybe<Scalars['Boolean']>;
  minutes___eq?: InputMaybe<Scalars['Int']>;
  minutes___gt?: InputMaybe<Scalars['Int']>;
  minutes___gte?: InputMaybe<Scalars['Int']>;
  minutes___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  minutes___lt?: InputMaybe<Scalars['Int']>;
  minutes___lte?: InputMaybe<Scalars['Int']>;
  minutes___ne?: InputMaybe<Scalars['Int']>;
  minutes___not___in?: InputMaybe<Array<Scalars['Int']>>;
  minutes___not___null?: InputMaybe<Scalars['Boolean']>;
  minutes___null?: InputMaybe<Scalars['Boolean']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  non_teaching___eq?: InputMaybe<Scalars['Boolean']>;
  non_teaching___ne?: InputMaybe<Scalars['Boolean']>;
  non_teaching___not___null?: InputMaybe<Scalars['Boolean']>;
  non_teaching___null?: InputMaybe<Scalars['Boolean']>;
  past_infection___eq?: InputMaybe<Scalars['Boolean']>;
  past_infection___ne?: InputMaybe<Scalars['Boolean']>;
  past_infection___not___null?: InputMaybe<Scalars['Boolean']>;
  past_infection___null?: InputMaybe<Scalars['Boolean']>;
  person_type___contains?: InputMaybe<Scalars['String']>;
  person_type___ends_with?: InputMaybe<Scalars['String']>;
  person_type___eq?: InputMaybe<Scalars['String']>;
  person_type___gt?: InputMaybe<Scalars['String']>;
  person_type___gte?: InputMaybe<Scalars['String']>;
  person_type___in?: InputMaybe<Array<Scalars['String']>>;
  person_type___lt?: InputMaybe<Scalars['String']>;
  person_type___lte?: InputMaybe<Scalars['String']>;
  person_type___ne?: InputMaybe<Scalars['String']>;
  person_type___not___contains?: InputMaybe<Scalars['String']>;
  person_type___not___ends_with?: InputMaybe<Scalars['String']>;
  person_type___not___in?: InputMaybe<Array<Scalars['String']>>;
  person_type___not___null?: InputMaybe<Scalars['Boolean']>;
  person_type___not___starts_with?: InputMaybe<Scalars['String']>;
  person_type___null?: InputMaybe<Scalars['Boolean']>;
  person_type___starts_with?: InputMaybe<Scalars['String']>;
  schools___contains?: InputMaybe<Scalars['String']>;
  schools___ends_with?: InputMaybe<Scalars['String']>;
  schools___eq?: InputMaybe<Scalars['String']>;
  schools___gt?: InputMaybe<Scalars['String']>;
  schools___gte?: InputMaybe<Scalars['String']>;
  schools___in?: InputMaybe<Array<Scalars['String']>>;
  schools___lt?: InputMaybe<Scalars['String']>;
  schools___lte?: InputMaybe<Scalars['String']>;
  schools___ne?: InputMaybe<Scalars['String']>;
  schools___not___contains?: InputMaybe<Scalars['String']>;
  schools___not___ends_with?: InputMaybe<Scalars['String']>;
  schools___not___in?: InputMaybe<Array<Scalars['String']>>;
  schools___not___null?: InputMaybe<Scalars['Boolean']>;
  schools___not___starts_with?: InputMaybe<Scalars['String']>;
  schools___null?: InputMaybe<Scalars['Boolean']>;
  schools___starts_with?: InputMaybe<Scalars['String']>;
  serial_id___eq?: InputMaybe<Scalars['Serial']>;
  serial_id___gt?: InputMaybe<Scalars['Serial']>;
  serial_id___gte?: InputMaybe<Scalars['Serial']>;
  serial_id___in?: InputMaybe<Array<InputMaybe<Scalars['Serial']>>>;
  serial_id___lt?: InputMaybe<Scalars['Serial']>;
  serial_id___lte?: InputMaybe<Scalars['Serial']>;
  serial_id___ne?: InputMaybe<Scalars['Serial']>;
  serial_id___not___in?: InputMaybe<Array<Scalars['Serial']>>;
  serial_id___not___null?: InputMaybe<Scalars['Boolean']>;
  serial_id___null?: InputMaybe<Scalars['Boolean']>;
  sidi_code___contains?: InputMaybe<Scalars['String']>;
  sidi_code___ends_with?: InputMaybe<Scalars['String']>;
  sidi_code___eq?: InputMaybe<Scalars['String']>;
  sidi_code___gt?: InputMaybe<Scalars['String']>;
  sidi_code___gte?: InputMaybe<Scalars['String']>;
  sidi_code___in?: InputMaybe<Array<Scalars['String']>>;
  sidi_code___lt?: InputMaybe<Scalars['String']>;
  sidi_code___lte?: InputMaybe<Scalars['String']>;
  sidi_code___ne?: InputMaybe<Scalars['String']>;
  sidi_code___not___contains?: InputMaybe<Scalars['String']>;
  sidi_code___not___ends_with?: InputMaybe<Scalars['String']>;
  sidi_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  sidi_code___not___null?: InputMaybe<Scalars['Boolean']>;
  sidi_code___not___starts_with?: InputMaybe<Scalars['String']>;
  sidi_code___null?: InputMaybe<Scalars['Boolean']>;
  sidi_code___starts_with?: InputMaybe<Scalars['String']>;
  student___eq?: InputMaybe<Scalars['Boolean']>;
  student___ne?: InputMaybe<Scalars['Boolean']>;
  student___not___null?: InputMaybe<Scalars['Boolean']>;
  student___null?: InputMaybe<Scalars['Boolean']>;
  students_in_family___contains?: InputMaybe<Scalars['String']>;
  students_in_family___ends_with?: InputMaybe<Scalars['String']>;
  students_in_family___eq?: InputMaybe<Scalars['String']>;
  students_in_family___gt?: InputMaybe<Scalars['String']>;
  students_in_family___gte?: InputMaybe<Scalars['String']>;
  students_in_family___in?: InputMaybe<Array<Scalars['String']>>;
  students_in_family___lt?: InputMaybe<Scalars['String']>;
  students_in_family___lte?: InputMaybe<Scalars['String']>;
  students_in_family___ne?: InputMaybe<Scalars['String']>;
  students_in_family___not___contains?: InputMaybe<Scalars['String']>;
  students_in_family___not___ends_with?: InputMaybe<Scalars['String']>;
  students_in_family___not___in?: InputMaybe<Array<Scalars['String']>>;
  students_in_family___not___null?: InputMaybe<Scalars['Boolean']>;
  students_in_family___not___starts_with?: InputMaybe<Scalars['String']>;
  students_in_family___null?: InputMaybe<Scalars['Boolean']>;
  students_in_family___starts_with?: InputMaybe<Scalars['String']>;
  surname___contains?: InputMaybe<Scalars['String']>;
  surname___ends_with?: InputMaybe<Scalars['String']>;
  surname___eq?: InputMaybe<Scalars['String']>;
  surname___gt?: InputMaybe<Scalars['String']>;
  surname___gte?: InputMaybe<Scalars['String']>;
  surname___in?: InputMaybe<Array<Scalars['String']>>;
  surname___lt?: InputMaybe<Scalars['String']>;
  surname___lte?: InputMaybe<Scalars['String']>;
  surname___ne?: InputMaybe<Scalars['String']>;
  surname___not___contains?: InputMaybe<Scalars['String']>;
  surname___not___ends_with?: InputMaybe<Scalars['String']>;
  surname___not___in?: InputMaybe<Array<Scalars['String']>>;
  surname___not___null?: InputMaybe<Scalars['Boolean']>;
  surname___not___starts_with?: InputMaybe<Scalars['String']>;
  surname___null?: InputMaybe<Scalars['Boolean']>;
  surname___starts_with?: InputMaybe<Scalars['String']>;
  teacher___eq?: InputMaybe<Scalars['Boolean']>;
  teacher___ne?: InputMaybe<Scalars['Boolean']>;
  teacher___not___null?: InputMaybe<Scalars['Boolean']>;
  teacher___null?: InputMaybe<Scalars['Boolean']>;
  test___eq?: InputMaybe<Scalars['Boolean']>;
  test___ne?: InputMaybe<Scalars['Boolean']>;
  test___not___null?: InputMaybe<Scalars['Boolean']>;
  test___null?: InputMaybe<Scalars['Boolean']>;
  username___contains?: InputMaybe<Scalars['String']>;
  username___ends_with?: InputMaybe<Scalars['String']>;
  username___eq?: InputMaybe<Scalars['String']>;
  username___gt?: InputMaybe<Scalars['String']>;
  username___gte?: InputMaybe<Scalars['String']>;
  username___in?: InputMaybe<Array<Scalars['String']>>;
  username___lt?: InputMaybe<Scalars['String']>;
  username___lte?: InputMaybe<Scalars['String']>;
  username___ne?: InputMaybe<Scalars['String']>;
  username___not___contains?: InputMaybe<Scalars['String']>;
  username___not___ends_with?: InputMaybe<Scalars['String']>;
  username___not___in?: InputMaybe<Array<Scalars['String']>>;
  username___not___null?: InputMaybe<Scalars['Boolean']>;
  username___not___starts_with?: InputMaybe<Scalars['String']>;
  username___null?: InputMaybe<Scalars['Boolean']>;
  username___starts_with?: InputMaybe<Scalars['String']>;
  vaccinated___eq?: InputMaybe<Scalars['Boolean']>;
  vaccinated___ne?: InputMaybe<Scalars['Boolean']>;
  vaccinated___not___null?: InputMaybe<Scalars['Boolean']>;
  vaccinated___null?: InputMaybe<Scalars['Boolean']>;
  year_of_birth___eq?: InputMaybe<Scalars['Year']>;
  year_of_birth___gt?: InputMaybe<Scalars['Year']>;
  year_of_birth___gte?: InputMaybe<Scalars['Year']>;
  year_of_birth___in?: InputMaybe<Array<InputMaybe<Scalars['Year']>>>;
  year_of_birth___lt?: InputMaybe<Scalars['Year']>;
  year_of_birth___lte?: InputMaybe<Scalars['Year']>;
  year_of_birth___ne?: InputMaybe<Scalars['Year']>;
  year_of_birth___not___in?: InputMaybe<Array<Scalars['Year']>>;
  year_of_birth___not___null?: InputMaybe<Scalars['Boolean']>;
  year_of_birth___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Person. */
export type PersonPage = {
  __typename?: 'PersonPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Person. */
  items: Array<Person>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Person present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a PersonPage. */
export type PersonPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<PersonFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<PersonCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<PersonSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Person */
export type PersonRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the associated objects of class Person */
export type PersonRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum PersonSort {
  _createdby___ASC = '_createdby___ASC',
  _createdby___DESC = '_createdby___DESC',
  _createdon___ASC = '_createdon___ASC',
  _createdon___DESC = '_createdon___DESC',
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  _lastmodifiedby___ASC = '_lastmodifiedby___ASC',
  _lastmodifiedby___DESC = '_lastmodifiedby___DESC',
  _lastmodifiedon___ASC = '_lastmodifiedon___ASC',
  _lastmodifiedon___DESC = '_lastmodifiedon___DESC',
  center___ASC = 'center___ASC',
  center___DESC = 'center___DESC',
  children_relation___ASC = 'children_relation___ASC',
  children_relation___DESC = 'children_relation___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  commute_vehicle___ASC = 'commute_vehicle___ASC',
  commute_vehicle___DESC = 'commute_vehicle___DESC',
  consent_withdrawn___ASC = 'consent_withdrawn___ASC',
  consent_withdrawn___DESC = 'consent_withdrawn___DESC',
  date_last_infection___ASC = 'date_last_infection___ASC',
  date_last_infection___DESC = 'date_last_infection___DESC',
  edit_mode___ASC = 'edit_mode___ASC',
  edit_mode___DESC = 'edit_mode___DESC',
  educational_level___ASC = 'educational_level___ASC',
  educational_level___DESC = 'educational_level___DESC',
  email___ASC = 'email___ASC',
  email___DESC = 'email___DESC',
  family_email___ASC = 'family_email___ASC',
  family_email___DESC = 'family_email___DESC',
  family_member___ASC = 'family_member___ASC',
  family_member___DESC = 'family_member___DESC',
  family_school_ids___ASC = 'family_school_ids___ASC',
  family_school_ids___DESC = 'family_school_ids___DESC',
  family_username___ASC = 'family_username___ASC',
  family_username___DESC = 'family_username___DESC',
  fiscal_code___ASC = 'fiscal_code___ASC',
  fiscal_code___DESC = 'fiscal_code___DESC',
  gender___ASC = 'gender___ASC',
  gender___DESC = 'gender___DESC',
  kilometers___ASC = 'kilometers___ASC',
  kilometers___DESC = 'kilometers___DESC',
  minutes___ASC = 'minutes___ASC',
  minutes___DESC = 'minutes___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  non_teaching___ASC = 'non_teaching___ASC',
  non_teaching___DESC = 'non_teaching___DESC',
  past_infection___ASC = 'past_infection___ASC',
  past_infection___DESC = 'past_infection___DESC',
  person_type___ASC = 'person_type___ASC',
  person_type___DESC = 'person_type___DESC',
  schools___ASC = 'schools___ASC',
  schools___DESC = 'schools___DESC',
  serial_id___ASC = 'serial_id___ASC',
  serial_id___DESC = 'serial_id___DESC',
  sidi_code___ASC = 'sidi_code___ASC',
  sidi_code___DESC = 'sidi_code___DESC',
  student___ASC = 'student___ASC',
  student___DESC = 'student___DESC',
  students_in_family___ASC = 'students_in_family___ASC',
  students_in_family___DESC = 'students_in_family___DESC',
  surname___ASC = 'surname___ASC',
  surname___DESC = 'surname___DESC',
  teacher___ASC = 'teacher___ASC',
  teacher___DESC = 'teacher___DESC',
  test___ASC = 'test___ASC',
  test___DESC = 'test___DESC',
  username___ASC = 'username___ASC',
  username___DESC = 'username___DESC',
  vaccinated___ASC = 'vaccinated___ASC',
  vaccinated___DESC = 'vaccinated___DESC',
  year_of_birth___ASC = 'year_of_birth___ASC',
  year_of_birth___DESC = 'year_of_birth___DESC'
}

/** Represents an object of class Person_type, as defined in the model. */
export type Person_Type = {
  __typename?: 'Person_type';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Person_type. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute family_member of Person_type. It is of type Boolean. Cannot be null. */
  family_member?: Maybe<Scalars['Boolean']>;
  /** The attribute name of Person_type. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The attribute non_teaching_staff of Person_type. It is of type Boolean. Cannot be null. */
  non_teaching_staff?: Maybe<Scalars['Boolean']>;
  /** The attribute student of Person_type. It is of type Boolean. Cannot be null. */
  student?: Maybe<Scalars['Boolean']>;
  /** The attribute teacher of Person_type. It is of type Boolean. Cannot be null. */
  teacher?: Maybe<Scalars['Boolean']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Person_type.
 */
export type Person_TypeCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  family_member?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  non_teaching_staff?: InputMaybe<Scalars['Boolean']>;
  student?: InputMaybe<Scalars['Boolean']>;
  teacher?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft associated object of class Person_type */
export type Person_TypeDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Person_type.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Person_TypeFilter = {
  AND?: InputMaybe<Array<Person_TypeFilter>>;
  NOT?: InputMaybe<Person_TypeFilter>;
  OR?: InputMaybe<Array<Person_TypeFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  family_member___eq?: InputMaybe<Scalars['Boolean']>;
  family_member___ne?: InputMaybe<Scalars['Boolean']>;
  family_member___not___null?: InputMaybe<Scalars['Boolean']>;
  family_member___null?: InputMaybe<Scalars['Boolean']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  non_teaching_staff___eq?: InputMaybe<Scalars['Boolean']>;
  non_teaching_staff___ne?: InputMaybe<Scalars['Boolean']>;
  non_teaching_staff___not___null?: InputMaybe<Scalars['Boolean']>;
  non_teaching_staff___null?: InputMaybe<Scalars['Boolean']>;
  student___eq?: InputMaybe<Scalars['Boolean']>;
  student___ne?: InputMaybe<Scalars['Boolean']>;
  student___not___null?: InputMaybe<Scalars['Boolean']>;
  student___null?: InputMaybe<Scalars['Boolean']>;
  teacher___eq?: InputMaybe<Scalars['Boolean']>;
  teacher___ne?: InputMaybe<Scalars['Boolean']>;
  teacher___not___null?: InputMaybe<Scalars['Boolean']>;
  teacher___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Person_type. */
export type Person_TypePage = {
  __typename?: 'Person_typePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Person_type. */
  items: Array<Person_Type>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Person_type present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Person_typePage. */
export type Person_TypePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Person_TypeFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Person_TypeCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Person_TypeSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Person_type */
export type Person_TypeRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Person_TypeSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  family_member___ASC = 'family_member___ASC',
  family_member___DESC = 'family_member___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  non_teaching_staff___ASC = 'non_teaching_staff___ASC',
  non_teaching_staff___DESC = 'non_teaching_staff___DESC',
  student___ASC = 'student___ASC',
  student___DESC = 'student___DESC',
  teacher___ASC = 'teacher___ASC',
  teacher___DESC = 'teacher___DESC'
}

/** Represents an object of form class Plugin_runner, as defined in the model. */
export type Plugin_Runner = {
  __typename?: 'Plugin_runner';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Plugin_runner. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute file of Plugin_runner. It is of type FileInfo.  */
  file?: Maybe<FileInfo>;
  /** The attribute notes of Plugin_runner. It is of type Text.  */
  notes?: Maybe<Scalars['Text']>;
};

/** A data structure for a draft object of form class Plugin_runner. */
export type Plugin_RunnerDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute file of Plugin_runner. It is of type PendingFileReference.  */
  file?: InputMaybe<PendingFileReference>;
  /** The attribute notes of Plugin_runner. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
};

/** A data structure for draft create objects of form class Plugin_runner. */
export type Plugin_RunnerDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute file of Plugin_runner. It is of type PendingFileReference.  */
  file?: InputMaybe<PendingFileReference>;
  /** The attribute notes of Plugin_runner. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
};

/** The result of the form action invocation on class Plugin_runner. */
export type Plugin_RunnerFormActionHandlerResult = {
  __typename?: 'Plugin_runnerFormActionHandlerResult';
  data?: Maybe<Plugin_Runner>;
  result: FormActionHandlerResult;
};

/** Represents an object of class Possible_response, as defined in the model. */
export type Possible_Response = {
  __typename?: 'Possible_response';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Possible_response. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute langs of Possible_response. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)possible_response_lang_ lang. Cannot be null. */
  langs?: Maybe<Scalars['String']>;
  /** The attribute order of Possible_response. It is of type Int.  */
  order?: Maybe<Scalars['Int']>;
  /** The associated objects for the role possible_response_lang_. */
  possible_response_lang_?: Maybe<Possible_Response_LangPage>;
  /** The associated objects for the role question_. */
  question_?: Maybe<QuestionPage>;
  /** The associable objects for the role question_. */
  question____associables?: Maybe<QuestionPage>;
  /** The attribute questions of Possible_response. It is of type Int. Path: COUNT_DISTINCT - Count distinctquestion_ __id. Cannot be null. */
  questions?: Maybe<Scalars['Int']>;
  /** The attribute response_ of Possible_response. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated) [if(isNull(__CurrentUser.lang),Possible_response_lang.lang="English", if(containsCS(Possible_response.langs, __CurrentUser.lang),__CurrentUser.lang= Possible_response_lang.lang,Possible_response_lang.lang="English"))]possible_response_lang_ response. Cannot be null. */
  response_?: Maybe<Scalars['String']>;
  /** The attribute score of Possible_response. It is of type Real @realFormat.  */
  score?: Maybe<Scalars['Real']>;
};


/** Represents an object of class Possible_response, as defined in the model. */
export type Possible_ResponsePossible_Response_Lang_Args = {
  options?: InputMaybe<Possible_Response_LangPageOptions>;
};


/** Represents an object of class Possible_response, as defined in the model. */
export type Possible_ResponseQuestion_Args = {
  options?: InputMaybe<QuestionPageOptions>;
};


/** Represents an object of class Possible_response, as defined in the model. */
export type Possible_ResponseQuestion____AssociablesArgs = {
  options?: InputMaybe<QuestionPageOptions>;
};


/** Represents an object of class Possible_response, as defined in the model. */
export type Possible_ResponseScoreArgs = {
  format?: InputMaybe<Scalars['String']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Possible_response.
 */
export type Possible_ResponseCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  langs?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  questions?: InputMaybe<Scalars['Int']>;
  response_?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Real']>;
};

/** A data structure for the draft associated object of class Possible_response */
export type Possible_ResponseDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft associated objects of class Possible_response */
export type Possible_ResponseDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type Possible_response.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Possible_ResponseFilter = {
  AND?: InputMaybe<Array<Possible_ResponseFilter>>;
  NOT?: InputMaybe<Possible_ResponseFilter>;
  OR?: InputMaybe<Array<Possible_ResponseFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  langs___contains?: InputMaybe<Scalars['String']>;
  langs___ends_with?: InputMaybe<Scalars['String']>;
  langs___eq?: InputMaybe<Scalars['String']>;
  langs___gt?: InputMaybe<Scalars['String']>;
  langs___gte?: InputMaybe<Scalars['String']>;
  langs___in?: InputMaybe<Array<Scalars['String']>>;
  langs___lt?: InputMaybe<Scalars['String']>;
  langs___lte?: InputMaybe<Scalars['String']>;
  langs___ne?: InputMaybe<Scalars['String']>;
  langs___not___contains?: InputMaybe<Scalars['String']>;
  langs___not___ends_with?: InputMaybe<Scalars['String']>;
  langs___not___in?: InputMaybe<Array<Scalars['String']>>;
  langs___not___null?: InputMaybe<Scalars['Boolean']>;
  langs___not___starts_with?: InputMaybe<Scalars['String']>;
  langs___null?: InputMaybe<Scalars['Boolean']>;
  langs___starts_with?: InputMaybe<Scalars['String']>;
  order___eq?: InputMaybe<Scalars['Int']>;
  order___gt?: InputMaybe<Scalars['Int']>;
  order___gte?: InputMaybe<Scalars['Int']>;
  order___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  order___lt?: InputMaybe<Scalars['Int']>;
  order___lte?: InputMaybe<Scalars['Int']>;
  order___ne?: InputMaybe<Scalars['Int']>;
  order___not___in?: InputMaybe<Array<Scalars['Int']>>;
  order___not___null?: InputMaybe<Scalars['Boolean']>;
  order___null?: InputMaybe<Scalars['Boolean']>;
  questions___eq?: InputMaybe<Scalars['Int']>;
  questions___gt?: InputMaybe<Scalars['Int']>;
  questions___gte?: InputMaybe<Scalars['Int']>;
  questions___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  questions___lt?: InputMaybe<Scalars['Int']>;
  questions___lte?: InputMaybe<Scalars['Int']>;
  questions___ne?: InputMaybe<Scalars['Int']>;
  questions___not___in?: InputMaybe<Array<Scalars['Int']>>;
  questions___not___null?: InputMaybe<Scalars['Boolean']>;
  questions___null?: InputMaybe<Scalars['Boolean']>;
  response____contains?: InputMaybe<Scalars['String']>;
  response____ends_with?: InputMaybe<Scalars['String']>;
  response____eq?: InputMaybe<Scalars['String']>;
  response____gt?: InputMaybe<Scalars['String']>;
  response____gte?: InputMaybe<Scalars['String']>;
  response____in?: InputMaybe<Array<Scalars['String']>>;
  response____lt?: InputMaybe<Scalars['String']>;
  response____lte?: InputMaybe<Scalars['String']>;
  response____ne?: InputMaybe<Scalars['String']>;
  response____not___contains?: InputMaybe<Scalars['String']>;
  response____not___ends_with?: InputMaybe<Scalars['String']>;
  response____not___in?: InputMaybe<Array<Scalars['String']>>;
  response____not___null?: InputMaybe<Scalars['Boolean']>;
  response____not___starts_with?: InputMaybe<Scalars['String']>;
  response____null?: InputMaybe<Scalars['Boolean']>;
  response____starts_with?: InputMaybe<Scalars['String']>;
  score___eq?: InputMaybe<Scalars['Real']>;
  score___gt?: InputMaybe<Scalars['Real']>;
  score___gte?: InputMaybe<Scalars['Real']>;
  score___in?: InputMaybe<Array<InputMaybe<Scalars['Real']>>>;
  score___lt?: InputMaybe<Scalars['Real']>;
  score___lte?: InputMaybe<Scalars['Real']>;
  score___ne?: InputMaybe<Scalars['Real']>;
  score___not___in?: InputMaybe<Array<Scalars['Real']>>;
  score___not___null?: InputMaybe<Scalars['Boolean']>;
  score___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Possible_response. */
export type Possible_ResponsePage = {
  __typename?: 'Possible_responsePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Possible_response. */
  items: Array<Possible_Response>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Possible_response present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Possible_responsePage. */
export type Possible_ResponsePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Possible_ResponseFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Possible_ResponseCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Possible_ResponseSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Possible_response */
export type Possible_ResponseRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the associated objects of class Possible_response */
export type Possible_ResponseRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Possible_ResponseSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  langs___ASC = 'langs___ASC',
  langs___DESC = 'langs___DESC',
  order___ASC = 'order___ASC',
  order___DESC = 'order___DESC',
  questions___ASC = 'questions___ASC',
  questions___DESC = 'questions___DESC',
  response____ASC = 'response____ASC',
  response____DESC = 'response____DESC',
  score___ASC = 'score___ASC',
  score___DESC = 'score___DESC'
}

/** Represents an object of class Possible_response_lang, as defined in the model. */
export type Possible_Response_Lang = {
  __typename?: 'Possible_response_lang';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Possible_response_lang. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute lang of Possible_response_lang. It is of type String. Path: lang_ name. Cannot be null. */
  lang?: Maybe<Scalars['String']>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
  /** The associated object for the role possible_response_. */
  possible_response_?: Maybe<Possible_Response>;
  /** The attribute response of Possible_response_lang. It is of type Text. Cannot be null. */
  response?: Maybe<Scalars['Text']>;
};


/** Represents an object of class Possible_response_lang, as defined in the model. */
export type Possible_Response_LangLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};

/** A data structure for objects of Possible_response_lang. */
export type Possible_Response_LangCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role possible_response_lang_(0,N) --> (1)lang_. */
  lang_: Scalars['ID'];
  /** The attribute response of Possible_response_lang. It is of type Text!. Cannot be null. */
  response: Scalars['Text'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Possible_response_lang.
 */
export type Possible_Response_LangCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  lang?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['Text']>;
};

/** A data structure for a draft object of class Possible_response_lang. */
export type Possible_Response_LangDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The Association role possible_response_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute response of Possible_response_lang. It is of type Text. Cannot be null. */
  response?: InputMaybe<Scalars['Text']>;
};

/** A data structure for draft create objects of class Possible_response_lang. */
export type Possible_Response_LangDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role possible_response_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute response of Possible_response_lang. It is of type Text. Cannot be null. */
  response?: InputMaybe<Scalars['Text']>;
};

/** A data structure for the draft part objects of class Possible_response_lang */
export type Possible_Response_LangDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Possible_response_lang to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Possible_Response_LangDraft>>>;
};

/** A data structure for a draft update object of class Possible_response_lang. */
export type Possible_Response_LangDraftUpdate = {
  _id: Scalars['ID'];
  /** The Association role possible_response_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute response of Possible_response_lang. It is of type Text. Cannot be null. */
  response?: InputMaybe<Scalars['Text']>;
};

/** A data structure for a draft update objects of class Possible_response_lang. */
export type Possible_Response_LangDraftUpdateBulk = {
  /** The Association role possible_response_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute response of Possible_response_lang. It is of type Text. Cannot be null. */
  response?: InputMaybe<Scalars['Text']>;
};

/** A data structure for the draft part objects of class Possible_response_lang */
export type Possible_Response_LangDraftUpdateRoleObjects = {
  /** An array of objects of class Possible_response_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Possible_Response_LangDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Possible_response_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Possible_Response_LangDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Possible_response_lang.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Possible_Response_LangFilter = {
  AND?: InputMaybe<Array<Possible_Response_LangFilter>>;
  NOT?: InputMaybe<Possible_Response_LangFilter>;
  OR?: InputMaybe<Array<Possible_Response_LangFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  lang___contains?: InputMaybe<Scalars['String']>;
  lang___ends_with?: InputMaybe<Scalars['String']>;
  lang___eq?: InputMaybe<Scalars['String']>;
  lang___gt?: InputMaybe<Scalars['String']>;
  lang___gte?: InputMaybe<Scalars['String']>;
  lang___in?: InputMaybe<Array<Scalars['String']>>;
  lang___lt?: InputMaybe<Scalars['String']>;
  lang___lte?: InputMaybe<Scalars['String']>;
  lang___ne?: InputMaybe<Scalars['String']>;
  lang___not___contains?: InputMaybe<Scalars['String']>;
  lang___not___ends_with?: InputMaybe<Scalars['String']>;
  lang___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang___not___null?: InputMaybe<Scalars['Boolean']>;
  lang___not___starts_with?: InputMaybe<Scalars['String']>;
  lang___null?: InputMaybe<Scalars['Boolean']>;
  lang___starts_with?: InputMaybe<Scalars['String']>;
  response___contains?: InputMaybe<Scalars['Text']>;
  response___ends_with?: InputMaybe<Scalars['Text']>;
  response___eq?: InputMaybe<Scalars['Text']>;
  response___gt?: InputMaybe<Scalars['Text']>;
  response___gte?: InputMaybe<Scalars['Text']>;
  response___in?: InputMaybe<Array<Scalars['Text']>>;
  response___lt?: InputMaybe<Scalars['Text']>;
  response___lte?: InputMaybe<Scalars['Text']>;
  response___ne?: InputMaybe<Scalars['Text']>;
  response___not___contains?: InputMaybe<Scalars['Text']>;
  response___not___ends_with?: InputMaybe<Scalars['Text']>;
  response___not___in?: InputMaybe<Array<Scalars['Text']>>;
  response___not___null?: InputMaybe<Scalars['Boolean']>;
  response___not___starts_with?: InputMaybe<Scalars['Text']>;
  response___null?: InputMaybe<Scalars['Boolean']>;
  response___starts_with?: InputMaybe<Scalars['Text']>;
};

/** Represents a collection of objects of class Possible_response_lang. */
export type Possible_Response_LangPage = {
  __typename?: 'Possible_response_langPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Possible_response_lang. */
  items: Array<Possible_Response_Lang>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Possible_response_lang present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Possible_response_langPage. */
export type Possible_Response_LangPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Possible_Response_LangFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Possible_Response_LangCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Possible_Response_LangSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Possible_response_lang */
export type Possible_Response_LangRoleObjects = {
  /** An array of objects of class Possible_response_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Possible_Response_LangCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Possible_response_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Possible_Response_LangUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Possible_Response_LangSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  lang___ASC = 'lang___ASC',
  lang___DESC = 'lang___DESC',
  response___ASC = 'response___ASC',
  response___DESC = 'response___DESC'
}

/** A data structure for a single object of class Possible_response_lang. */
export type Possible_Response_LangUpdate = {
  _id: Scalars['ID'];
  /** The Association role possible_response_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute response of Possible_response_lang. It is of type Text. Cannot be null. */
  response?: InputMaybe<Scalars['Text']>;
};

/** A data structure for objects of class Possible_response_lang. */
export type Possible_Response_LangUpdateBulk = {
  /** The Association role possible_response_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute response of Possible_response_lang. It is of type Text. Cannot be null. */
  response?: InputMaybe<Scalars['Text']>;
};

/** Use a Query to fetch data. */
export type Query = {
  __typename?: 'Query';
  /**
   * Retrieve a preview object of form class Completion_selector for an object of Completion_selector not yet instanced.
   * Note that the passed param should NOT have the _id attribute
   */
  Completion_selector___preview?: Maybe<Completion_Selector>;
  /** Retrieve a preview object of form class Completion_selector for an object of Completion_selector not yet instanced. */
  Completion_selector___previewCreate?: Maybe<Completion_Selector>;
  /**
   * Retrieve a preview object of form class Plugin_runner for an object of Plugin_runner not yet instanced.
   * Note that the passed param should NOT have the _id attribute
   */
  Plugin_runner___preview?: Maybe<Plugin_Runner>;
  /** Retrieve a preview object of form class Plugin_runner for an object of Plugin_runner not yet instanced. */
  Plugin_runner___previewCreate?: Maybe<Plugin_Runner>;
  /** Retrieve a single object of class Questionnaire_completion by ID. */
  Questionnaire_completion___get?: Maybe<Questionnaire_Completion>;
  /** Retrieve a collection of object of class Questionnaire_completion. */
  Questionnaire_completion___getPage?: Maybe<Questionnaire_CompletionPage>;
  /** Retrieve a preview object of class Questionnaire_completion for an object of Questionnaire_completion not yet instanced. */
  Questionnaire_completion___preview?: Maybe<Questionnaire_Completion>;
  /** Retrieve a preview object of class Questionnaire_completion for an object of Questionnaire_completion not yet instanced. */
  Questionnaire_completion___previewCreate?: Maybe<Questionnaire_Completion>;
  /** Retrieve a preview object of class Questionnaire_completion for an updated instance of Questionnaire_completion. */
  Questionnaire_completion___previewUpdate?: Maybe<Questionnaire_Completion>;
  /**
   * Validation of a save on object of class Questionnaire_completion.
   * Returns a ValidationResult.
   */
  Questionnaire_completion___validate?: Maybe<ValidationResult>;
  /**
   * Validation of a create on object of class Questionnaire_completion.
   * Returns a ValidationResult.
   */
  Questionnaire_completion___validateCreate?: Maybe<ValidationResult>;
  /**
   * Validation of a delete on object of class Questionnaire_completion.
   * Returns a ValidationResult.
   */
  Questionnaire_completion___validateDelete?: Maybe<ValidationResult>;
  /**
   * Validation of a bulk delete on a set of objects of class Questionnaire_completion.
   * Returns a ValidationResult.
   */
  Questionnaire_completion___validateDeleteBulk?: Maybe<ValidationResult>;
  /**
   * Validation of an update on object of class Questionnaire_completion.
   * Returns a ValidationResult.
   */
  Questionnaire_completion___validateUpdate?: Maybe<ValidationResult>;
  /**
   * Validation of a bulk update on a set of objects of class Questionnaire_completion.
   * Returns a ValidationResult.
   */
  Questionnaire_completion___validateUpdateBulk?: Maybe<ValidationResult>;
  /** Retrieve the object of singleton class Questionnaire_config */
  Questionnaire_config___get?: Maybe<Questionnaire_Config>;
  /** Retrieve a preview object of singleton class Questionnaire_config for an updated instance of Questionnaire_config. */
  Questionnaire_config___previewUpdate?: Maybe<Questionnaire_Config>;
  /**
   * Validation of an update on object of singleton class Questionnaire_config.
   * Returns a ValidationResult.
   */
  Questionnaire_config___validateUpdate?: Maybe<ValidationResult>;
  /** Retrieve a single object of class Questionnaire_schema by ID. */
  Questionnaire_schema___get?: Maybe<Questionnaire_Schema>;
  /** Retrieve a single object of class Questionnaire_schema by uniques. */
  Questionnaire_schema___getByCode?: Maybe<Questionnaire_Schema>;
  /** Retrieve a collection of object of class Questionnaire_schema. */
  Questionnaire_schema___getPage?: Maybe<Questionnaire_SchemaPage>;
  /** Retrieve a preview object of class Questionnaire_schema for an object of Questionnaire_schema not yet instanced. */
  Questionnaire_schema___preview?: Maybe<Questionnaire_Schema>;
  /** Retrieve a preview object of class Questionnaire_schema for an object of Questionnaire_schema not yet instanced. */
  Questionnaire_schema___previewCreate?: Maybe<Questionnaire_Schema>;
  /** Retrieve a preview object of class Questionnaire_schema for an updated instance of Questionnaire_schema. */
  Questionnaire_schema___previewUpdate?: Maybe<Questionnaire_Schema>;
  /**
   * Validation of a save on object of class Questionnaire_schema.
   * Returns a ValidationResult.
   */
  Questionnaire_schema___validate?: Maybe<ValidationResult>;
  /**
   * Validation of a create on object of class Questionnaire_schema.
   * Returns a ValidationResult.
   */
  Questionnaire_schema___validateCreate?: Maybe<ValidationResult>;
  /**
   * Validation of a delete on object of class Questionnaire_schema.
   * Returns a ValidationResult.
   */
  Questionnaire_schema___validateDelete?: Maybe<ValidationResult>;
  /**
   * Validation of a bulk delete on a set of objects of class Questionnaire_schema.
   * Returns a ValidationResult.
   */
  Questionnaire_schema___validateDeleteBulk?: Maybe<ValidationResult>;
  /**
   * Validation of an update on object of class Questionnaire_schema.
   * Returns a ValidationResult.
   */
  Questionnaire_schema___validateUpdate?: Maybe<ValidationResult>;
  /**
   * Validation of a bulk update on a set of objects of class Questionnaire_schema.
   * Returns a ValidationResult.
   */
  Questionnaire_schema___validateUpdateBulk?: Maybe<ValidationResult>;
  /**
   * Retrieve a preview object of form class Responses for an object of Responses not yet instanced.
   * Note that the passed param should NOT have the _id attribute
   */
  Responses___preview?: Maybe<Responses>;
  /** Retrieve a preview object of form class Responses for an object of Responses not yet instanced. */
  Responses___previewCreate?: Maybe<Responses>;
  /**
   * Retrieve a preview object of form class Set_times for an object of Set_times not yet instanced.
   * Note that the passed param should NOT have the _id attribute
   */
  Set_times___preview?: Maybe<Set_Times>;
  /** Retrieve a preview object of form class Set_times for an object of Set_times not yet instanced. */
  Set_times___previewCreate?: Maybe<Set_Times>;
  /** Retrieve a single object of class Template by ID. */
  Template___get?: Maybe<Template>;
  /** Retrieve a collection of object of class Template. */
  Template___getPage?: Maybe<TemplatePage>;
  /** Retrieve a preview object of class Template for an object of Template not yet instanced. */
  Template___preview?: Maybe<Template>;
  /** Retrieve a preview object of class Template for an object of Template not yet instanced. */
  Template___previewCreate?: Maybe<Template>;
  /** Retrieve a preview object of class Template for an updated instance of Template. */
  Template___previewUpdate?: Maybe<Template>;
  /**
   * Validation of a save on object of class Template.
   * Returns a ValidationResult.
   */
  Template___validate?: Maybe<ValidationResult>;
  /**
   * Validation of a create on object of class Template.
   * Returns a ValidationResult.
   */
  Template___validateCreate?: Maybe<ValidationResult>;
  /**
   * Validation of a delete on object of class Template.
   * Returns a ValidationResult.
   */
  Template___validateDelete?: Maybe<ValidationResult>;
  /**
   * Validation of a bulk delete on a set of objects of class Template.
   * Returns a ValidationResult.
   */
  Template___validateDeleteBulk?: Maybe<ValidationResult>;
  /**
   * Validation of an update on object of class Template.
   * Returns a ValidationResult.
   */
  Template___validateUpdate?: Maybe<ValidationResult>;
  /**
   * Validation of a bulk update on a set of objects of class Template.
   * Returns a ValidationResult.
   */
  Template___validateUpdateBulk?: Maybe<ValidationResult>;
};


/** Use a Query to fetch data. */
export type QueryCompletion_Selector___PreviewArgs = {
  data: Completion_SelectorDraft;
};


/** Use a Query to fetch data. */
export type QueryCompletion_Selector___PreviewCreateArgs = {
  data: Completion_SelectorDraftCreate;
};


/** Use a Query to fetch data. */
export type QueryPlugin_Runner___PreviewArgs = {
  data: Plugin_RunnerDraft;
};


/** Use a Query to fetch data. */
export type QueryPlugin_Runner___PreviewCreateArgs = {
  data: Plugin_RunnerDraftCreate;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Completion___GetArgs = {
  _id: Scalars['ID'];
  insight?: InputMaybe<InsightType>;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Completion___GetPageArgs = {
  insight?: InputMaybe<InsightType>;
  options?: InputMaybe<Questionnaire_CompletionPageOptions>;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Completion___PreviewArgs = {
  data: Questionnaire_CompletionDraft;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Completion___PreviewCreateArgs = {
  data: Questionnaire_CompletionDraftCreate;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Completion___PreviewUpdateArgs = {
  data: Questionnaire_CompletionDraftUpdate;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Completion___ValidateArgs = {
  data: Questionnaire_CompletionDraft;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Completion___ValidateCreateArgs = {
  data: Questionnaire_CompletionDraftCreate;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Completion___ValidateDeleteArgs = {
  _id: Scalars['ID'];
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Completion___ValidateDeleteBulkArgs = {
  options: Questionnaire_CompletionPageOptions;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Completion___ValidateUpdateArgs = {
  data: Questionnaire_CompletionDraftUpdate;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Completion___ValidateUpdateBulkArgs = {
  data: Questionnaire_CompletionDraftUpdateBulk;
  options: Questionnaire_CompletionPageOptions;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Config___GetArgs = {
  insight?: InputMaybe<InsightType>;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Config___PreviewUpdateArgs = {
  data: Questionnaire_ConfigDraftUpdate;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Config___ValidateUpdateArgs = {
  data: Questionnaire_ConfigDraftUpdate;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Schema___GetArgs = {
  _id: Scalars['ID'];
  insight?: InputMaybe<InsightType>;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Schema___GetByCodeArgs = {
  code: Scalars['String'];
  insight?: InputMaybe<InsightType>;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Schema___GetPageArgs = {
  insight?: InputMaybe<InsightType>;
  options?: InputMaybe<Questionnaire_SchemaPageOptions>;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Schema___PreviewArgs = {
  data: Questionnaire_SchemaDraft;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Schema___PreviewCreateArgs = {
  data: Questionnaire_SchemaDraftCreate;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Schema___PreviewUpdateArgs = {
  data: Questionnaire_SchemaDraftUpdate;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Schema___ValidateArgs = {
  data: Questionnaire_SchemaDraft;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Schema___ValidateCreateArgs = {
  data: Questionnaire_SchemaDraftCreate;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Schema___ValidateDeleteArgs = {
  _id: Scalars['ID'];
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Schema___ValidateDeleteBulkArgs = {
  options: Questionnaire_SchemaPageOptions;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Schema___ValidateUpdateArgs = {
  data: Questionnaire_SchemaDraftUpdate;
};


/** Use a Query to fetch data. */
export type QueryQuestionnaire_Schema___ValidateUpdateBulkArgs = {
  data: Questionnaire_SchemaDraftUpdateBulk;
  options: Questionnaire_SchemaPageOptions;
};


/** Use a Query to fetch data. */
export type QueryResponses___PreviewArgs = {
  data: ResponsesDraft;
};


/** Use a Query to fetch data. */
export type QueryResponses___PreviewCreateArgs = {
  data: ResponsesDraftCreate;
};


/** Use a Query to fetch data. */
export type QuerySet_Times___PreviewArgs = {
  data: Set_TimesDraft;
};


/** Use a Query to fetch data. */
export type QuerySet_Times___PreviewCreateArgs = {
  data: Set_TimesDraftCreate;
};


/** Use a Query to fetch data. */
export type QueryTemplate___GetArgs = {
  _id: Scalars['ID'];
  insight?: InputMaybe<InsightType>;
};


/** Use a Query to fetch data. */
export type QueryTemplate___GetPageArgs = {
  insight?: InputMaybe<InsightType>;
  options?: InputMaybe<TemplatePageOptions>;
};


/** Use a Query to fetch data. */
export type QueryTemplate___PreviewArgs = {
  data: TemplateDraft;
};


/** Use a Query to fetch data. */
export type QueryTemplate___PreviewCreateArgs = {
  data: TemplateDraftCreate;
};


/** Use a Query to fetch data. */
export type QueryTemplate___PreviewUpdateArgs = {
  data: TemplateDraftUpdate;
};


/** Use a Query to fetch data. */
export type QueryTemplate___ValidateArgs = {
  data: TemplateDraft;
};


/** Use a Query to fetch data. */
export type QueryTemplate___ValidateCreateArgs = {
  data: TemplateDraftCreate;
};


/** Use a Query to fetch data. */
export type QueryTemplate___ValidateDeleteArgs = {
  _id: Scalars['ID'];
};


/** Use a Query to fetch data. */
export type QueryTemplate___ValidateDeleteBulkArgs = {
  options: TemplatePageOptions;
};


/** Use a Query to fetch data. */
export type QueryTemplate___ValidateUpdateArgs = {
  data: TemplateDraftUpdate;
};


/** Use a Query to fetch data. */
export type QueryTemplate___ValidateUpdateBulkArgs = {
  data: TemplateDraftUpdateBulk;
  options: TemplatePageOptions;
};

/** Represents an object of class Question, as defined in the model. */
export type Question = {
  __typename?: 'Question';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Question. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute code of Question. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The attribute description of Question. It is of type String. Expression: 'concat(if(isNull(code),"",concat(code,": ")),question_)'.  */
  description?: Maybe<Scalars['String']>;
  /** The attribute disabled_by of Question. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)question_condition_disabled question_code.  */
  disabled_by?: Maybe<Scalars['String']>;
  /** The attribute enabled_by of Question. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)question_condition_enabled question_code.  */
  enabled_by?: Maybe<Scalars['String']>;
  /** The attribute int_range of Question. It is of type Boolean. Path: response_type_ int_range.  */
  int_range?: Maybe<Scalars['Boolean']>;
  /** The attribute intro_only of Question. It is of type Boolean. Path: response_type_ intro_only.  */
  intro_only?: Maybe<Scalars['Boolean']>;
  /** The attribute langs of Question. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)question_lang_ lang. Cannot be null. */
  langs?: Maybe<Scalars['String']>;
  /** The attribute lolli_only of Question. It is of type Boolean.  */
  lolli_only?: Maybe<Scalars['Boolean']>;
  /** The attribute mandatory of Question. It is of type Boolean. Cannot be null. */
  mandatory?: Maybe<Scalars['Boolean']>;
  /** The attribute mult_choice of Question. It is of type Boolean. Path: response_type_ multiple_choice.  */
  mult_choice?: Maybe<Scalars['Boolean']>;
  /** The attribute n_possible_responses of Question. It is of type Int. Path: COUNT_DISTINCT - Count distinctpossibile_response_ __id. Cannot be null. */
  n_possible_responses?: Maybe<Scalars['Int']>;
  /** The attribute order of Question. It is of type Int.  */
  order?: Maybe<Scalars['Int']>;
  /** The associated objects for the role possibile_response_. */
  possibile_response_?: Maybe<Possible_ResponsePage>;
  /** The associable objects for the role possibile_response_. */
  possibile_response____associables?: Maybe<Possible_ResponsePage>;
  /** The attribute possible_responses of Question. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)possibile_response_ response_. Cannot be null. */
  possible_responses?: Maybe<Scalars['String']>;
  /** The attribute question_ of Question. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated) [if(isNull(__CurrentUser.lang),Question_lang.lang="English", if(containsCS(Question.langs,__CurrentUser.lang), __CurrentUser.lang= Question_lang.lang,Question_lang.lang="English"))]question_lang_ question. Cannot be null. */
  question_?: Maybe<Scalars['String']>;
  /** The associated objects for the role question_condition_disabled. */
  question_condition_disabled?: Maybe<Question_Enabling_ConditionPage>;
  /** The associable objects for the role question_condition_disabled. */
  question_condition_disabled___associables?: Maybe<Question_Enabling_ConditionPage>;
  /** The associated objects for the role question_condition_enabled. */
  question_condition_enabled?: Maybe<Question_Enabling_ConditionPage>;
  /** The associable objects for the role question_condition_enabled. */
  question_condition_enabled___associables?: Maybe<Question_Enabling_ConditionPage>;
  /** The associated objects for the role question_enabling_condition_. */
  question_enabling_condition_?: Maybe<Question_Enabling_ConditionPage>;
  /** The associable objects for the role question_enabling_condition_. */
  question_enabling_condition____associables?: Maybe<Question_Enabling_ConditionPage>;
  /** The attribute question_fixed of Question. It is of type String. Expression: 'trim(substring(Question.question_,ifNull(indexOf(Question.question_,"]")+1,1)))'. Cannot be null. */
  question_fixed?: Maybe<Scalars['String']>;
  /** The associated objects for the role question_lang_. */
  question_lang_?: Maybe<Question_LangPage>;
  /** The attribute questionnaire of Question. It is of type String. Path: questionnaire_ code. Cannot be null. */
  questionnaire?: Maybe<Scalars['String']>;
  /** The associated object for the role questionnaire_. */
  questionnaire_?: Maybe<Questionnaire_Schema>;
  /** The attribute questionnaire_section of Question. It is of type String. Path: questionnaire_section_ questionnaire_section. Cannot be null. */
  questionnaire_section?: Maybe<Scalars['String']>;
  /** The associated object for the role questionnaire_section_. */
  questionnaire_section_?: Maybe<Questionnaire_Section>;
  /** The associable objects for the role questionnaire_section_. */
  questionnaire_section____associables?: Maybe<Questionnaire_SectionPage>;
  /** The attribute questionnaire_section_code of Question. It is of type String. Path: questionnaire_section_ questionnaire_secton_code.  */
  questionnaire_section_code?: Maybe<Scalars['String']>;
  /** The attribute response_type of Question. It is of type String. Path: response_type_ name. Cannot be null. */
  response_type?: Maybe<Scalars['String']>;
  /** The associated object for the role response_type_. */
  response_type_?: Maybe<Response_Type>;
  /** The associable objects for the role response_type_. */
  response_type____associables?: Maybe<Response_TypePage>;
  /** The attribute section_code of Question. It is of type String. Path: questionnaire_section_ code.  */
  section_code?: Maybe<Scalars['String']>;
  /** The attribute section_levels of Question. It is of type String. Path: questionnaire_section_ levels. Cannot be null. */
  section_levels?: Maybe<Scalars['String']>;
  /** The attribute section_user_types of Question. It is of type String. Path: questionnaire_section_ section_user_types. Cannot be null. */
  section_user_types?: Maybe<Scalars['String']>;
  /** The attribute single_chioce of Question. It is of type Boolean. Path: response_type_ single_choice.  */
  single_chioce?: Maybe<Scalars['Boolean']>;
  /** The attribute t0 of Question. It is of type Boolean.  */
  t0?: Maybe<Scalars['Boolean']>;
  /** The attribute t1 of Question. It is of type Boolean.  */
  t1?: Maybe<Scalars['Boolean']>;
};


/** Represents an object of class Question, as defined in the model. */
export type QuestionPossibile_Response_Args = {
  options?: InputMaybe<Possible_ResponsePageOptions>;
};


/** Represents an object of class Question, as defined in the model. */
export type QuestionPossibile_Response____AssociablesArgs = {
  options?: InputMaybe<Possible_ResponsePageOptions>;
};


/** Represents an object of class Question, as defined in the model. */
export type QuestionQuestion_Condition_DisabledArgs = {
  options?: InputMaybe<Question_Enabling_ConditionPageOptions>;
};


/** Represents an object of class Question, as defined in the model. */
export type QuestionQuestion_Condition_Disabled___AssociablesArgs = {
  options?: InputMaybe<Question_Enabling_ConditionPageOptions>;
};


/** Represents an object of class Question, as defined in the model. */
export type QuestionQuestion_Condition_EnabledArgs = {
  options?: InputMaybe<Question_Enabling_ConditionPageOptions>;
};


/** Represents an object of class Question, as defined in the model. */
export type QuestionQuestion_Condition_Enabled___AssociablesArgs = {
  options?: InputMaybe<Question_Enabling_ConditionPageOptions>;
};


/** Represents an object of class Question, as defined in the model. */
export type QuestionQuestion_Enabling_Condition_Args = {
  options?: InputMaybe<Question_Enabling_ConditionPageOptions>;
};


/** Represents an object of class Question, as defined in the model. */
export type QuestionQuestion_Enabling_Condition____AssociablesArgs = {
  options?: InputMaybe<Question_Enabling_ConditionPageOptions>;
};


/** Represents an object of class Question, as defined in the model. */
export type QuestionQuestion_Lang_Args = {
  options?: InputMaybe<Question_LangPageOptions>;
};


/** Represents an object of class Question, as defined in the model. */
export type QuestionQuestionnaire_Section____AssociablesArgs = {
  options?: InputMaybe<Questionnaire_SectionPageOptions>;
};


/** Represents an object of class Question, as defined in the model. */
export type QuestionResponse_Type____AssociablesArgs = {
  options?: InputMaybe<Response_TypePageOptions>;
};

/** A data structure for objects of Question. */
export type QuestionCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute code of Question. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute lolli_only of Question. It is of type Boolean.  */
  lolli_only?: InputMaybe<Scalars['Boolean']>;
  /** The attribute mandatory of Question. It is of type Boolean!. Cannot be null. */
  mandatory: Scalars['Boolean'];
  /** The attribute order of Question. It is of type Int.  */
  order?: InputMaybe<Scalars['Int']>;
  /** The Association role question_(0,N) <--> (0,N)possibile_response_. */
  possibile_response_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role question_condition_disabled(0,N) <--> (0,N)questions_disabled. */
  question_condition_disabled?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role question_condition_enabled(0,N) <--> (0,N)questions_enabled. */
  question_condition_enabled?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role question_enabling_condition_(0,N) <--> (1)question_. */
  question_enabling_condition_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Composition role question_ ◊--> (1,N)question_lang_. */
  question_lang_: Array<InputMaybe<Question_LangCreate>>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Scalars['ID']>;
  /** The Association role question_(0,N) --> (1)response_type_. */
  response_type_: Scalars['ID'];
  /** The attribute t0 of Question. It is of type Boolean.  */
  t0?: InputMaybe<Scalars['Boolean']>;
  /** The attribute t1 of Question. It is of type Boolean.  */
  t1?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Question.
 */
export type QuestionCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disabled_by?: InputMaybe<Scalars['String']>;
  enabled_by?: InputMaybe<Scalars['String']>;
  int_range?: InputMaybe<Scalars['Boolean']>;
  intro_only?: InputMaybe<Scalars['Boolean']>;
  langs?: InputMaybe<Scalars['String']>;
  lolli_only?: InputMaybe<Scalars['Boolean']>;
  mandatory?: InputMaybe<Scalars['Boolean']>;
  mult_choice?: InputMaybe<Scalars['Boolean']>;
  n_possible_responses?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  possible_responses?: InputMaybe<Scalars['String']>;
  question_?: InputMaybe<Scalars['String']>;
  question_fixed?: InputMaybe<Scalars['String']>;
  questionnaire?: InputMaybe<Scalars['String']>;
  questionnaire_section?: InputMaybe<Scalars['String']>;
  questionnaire_section_code?: InputMaybe<Scalars['String']>;
  response_type?: InputMaybe<Scalars['String']>;
  section_code?: InputMaybe<Scalars['String']>;
  section_levels?: InputMaybe<Scalars['String']>;
  section_user_types?: InputMaybe<Scalars['String']>;
  single_chioce?: InputMaybe<Scalars['Boolean']>;
  t0?: InputMaybe<Scalars['Boolean']>;
  t1?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for a draft object of class Question. */
export type QuestionDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute code of Question. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute lolli_only of Question. It is of type Boolean.  */
  lolli_only?: InputMaybe<Scalars['Boolean']>;
  /** The attribute mandatory of Question. It is of type Boolean. Cannot be null. */
  mandatory?: InputMaybe<Scalars['Boolean']>;
  /** The attribute order of Question. It is of type Int.  */
  order?: InputMaybe<Scalars['Int']>;
  /** The Association role question_(0,N) <--> (0,N)possibile_response_. */
  possibile_response_?: InputMaybe<Possible_ResponseDraftRoleRefs>;
  /** The Association role question_condition_disabled(0,N) <--> (0,N)questions_disabled. */
  question_condition_disabled?: InputMaybe<Question_Enabling_ConditionDraftRoleRefs>;
  /** The Association role question_condition_enabled(0,N) <--> (0,N)questions_enabled. */
  question_condition_enabled?: InputMaybe<Question_Enabling_ConditionDraftRoleRefs>;
  /** The Association role question_enabling_condition_(0,N) <--> (1)question_. */
  question_enabling_condition_?: InputMaybe<Question_Enabling_ConditionDraftRoleRefs>;
  /** The Composition role question_ ◊--> (1,N)question_lang_. */
  question_lang_?: InputMaybe<Question_LangDraftRoleObjects>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Scalars['ID']>;
  /** The Association role question_(0,N) --> (1)response_type_. */
  response_type_?: InputMaybe<Scalars['ID']>;
  /** The attribute t0 of Question. It is of type Boolean.  */
  t0?: InputMaybe<Scalars['Boolean']>;
  /** The attribute t1 of Question. It is of type Boolean.  */
  t1?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for draft create objects of class Question. */
export type QuestionDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute code of Question. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute lolli_only of Question. It is of type Boolean.  */
  lolli_only?: InputMaybe<Scalars['Boolean']>;
  /** The attribute mandatory of Question. It is of type Boolean. Cannot be null. */
  mandatory?: InputMaybe<Scalars['Boolean']>;
  /** The attribute order of Question. It is of type Int.  */
  order?: InputMaybe<Scalars['Int']>;
  /** The Association role question_(0,N) <--> (0,N)possibile_response_. */
  possibile_response_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role question_condition_disabled(0,N) <--> (0,N)questions_disabled. */
  question_condition_disabled?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role question_condition_enabled(0,N) <--> (0,N)questions_enabled. */
  question_condition_enabled?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role question_enabling_condition_(0,N) <--> (1)question_. */
  question_enabling_condition_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Composition role question_ ◊--> (1,N)question_lang_. */
  question_lang_?: InputMaybe<Array<InputMaybe<Question_LangDraftCreate>>>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Scalars['ID']>;
  /** The Association role question_(0,N) --> (1)response_type_. */
  response_type_?: InputMaybe<Scalars['ID']>;
  /** The attribute t0 of Question. It is of type Boolean.  */
  t0?: InputMaybe<Scalars['Boolean']>;
  /** The attribute t1 of Question. It is of type Boolean.  */
  t1?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft part objects of class Question */
export type QuestionDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Question to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<QuestionDraft>>>;
};

/** A data structure for the draft associated object of class Question */
export type QuestionDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft associated objects of class Question */
export type QuestionDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for a draft update object of class Question. */
export type QuestionDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute code of Question. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute lolli_only of Question. It is of type Boolean.  */
  lolli_only?: InputMaybe<Scalars['Boolean']>;
  /** The attribute mandatory of Question. It is of type Boolean. Cannot be null. */
  mandatory?: InputMaybe<Scalars['Boolean']>;
  /** The attribute order of Question. It is of type Int.  */
  order?: InputMaybe<Scalars['Int']>;
  /** The Association role question_(0,N) <--> (0,N)possibile_response_. */
  possibile_response_?: InputMaybe<Possible_ResponseRoleRefs>;
  /** The Association role question_condition_disabled(0,N) <--> (0,N)questions_disabled. */
  question_condition_disabled?: InputMaybe<Question_Enabling_ConditionRoleRefs>;
  /** The Association role question_condition_enabled(0,N) <--> (0,N)questions_enabled. */
  question_condition_enabled?: InputMaybe<Question_Enabling_ConditionRoleRefs>;
  /** The Association role question_enabling_condition_(0,N) <--> (1)question_. */
  question_enabling_condition_?: InputMaybe<Question_Enabling_ConditionRoleRefs>;
  /** The Composition role question_ ◊--> (1,N)question_lang_. */
  question_lang_?: InputMaybe<Question_LangDraftUpdateRoleObjects>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Questionnaire_SectionRoleRef>;
  /** The Association role question_(0,N) --> (1)response_type_. */
  response_type_?: InputMaybe<Response_TypeRoleRef>;
  /** The attribute t0 of Question. It is of type Boolean.  */
  t0?: InputMaybe<Scalars['Boolean']>;
  /** The attribute t1 of Question. It is of type Boolean.  */
  t1?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for a draft update objects of class Question. */
export type QuestionDraftUpdateBulk = {
  /** The attribute code of Question. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute lolli_only of Question. It is of type Boolean.  */
  lolli_only?: InputMaybe<Scalars['Boolean']>;
  /** The attribute mandatory of Question. It is of type Boolean. Cannot be null. */
  mandatory?: InputMaybe<Scalars['Boolean']>;
  /** The attribute order of Question. It is of type Int.  */
  order?: InputMaybe<Scalars['Int']>;
  /** The Association role question_(0,N) <--> (0,N)possibile_response_. */
  possibile_response_?: InputMaybe<Possible_ResponseRoleRefs>;
  /** The Association role question_condition_disabled(0,N) <--> (0,N)questions_disabled. */
  question_condition_disabled?: InputMaybe<Question_Enabling_ConditionRoleRefs>;
  /** The Association role question_condition_enabled(0,N) <--> (0,N)questions_enabled. */
  question_condition_enabled?: InputMaybe<Question_Enabling_ConditionRoleRefs>;
  /** The Association role question_enabling_condition_(0,N) <--> (1)question_. */
  question_enabling_condition_?: InputMaybe<Question_Enabling_ConditionRoleRefs>;
  /** The Composition role question_ ◊--> (1,N)question_lang_. */
  question_lang_?: InputMaybe<Question_LangDraftUpdateRoleObjects>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Questionnaire_SectionRoleRef>;
  /** The Association role question_(0,N) --> (1)response_type_. */
  response_type_?: InputMaybe<Response_TypeRoleRef>;
  /** The attribute t0 of Question. It is of type Boolean.  */
  t0?: InputMaybe<Scalars['Boolean']>;
  /** The attribute t1 of Question. It is of type Boolean.  */
  t1?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft part objects of class Question */
export type QuestionDraftUpdateRoleObjects = {
  /** An array of objects of class Question to be created. */
  create?: InputMaybe<Array<InputMaybe<QuestionDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Question to be updated. */
  update?: InputMaybe<Array<InputMaybe<QuestionDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Question.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type QuestionFilter = {
  AND?: InputMaybe<Array<QuestionFilter>>;
  NOT?: InputMaybe<QuestionFilter>;
  OR?: InputMaybe<Array<QuestionFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  description___contains?: InputMaybe<Scalars['String']>;
  description___ends_with?: InputMaybe<Scalars['String']>;
  description___eq?: InputMaybe<Scalars['String']>;
  description___gt?: InputMaybe<Scalars['String']>;
  description___gte?: InputMaybe<Scalars['String']>;
  description___in?: InputMaybe<Array<Scalars['String']>>;
  description___lt?: InputMaybe<Scalars['String']>;
  description___lte?: InputMaybe<Scalars['String']>;
  description___ne?: InputMaybe<Scalars['String']>;
  description___not___contains?: InputMaybe<Scalars['String']>;
  description___not___ends_with?: InputMaybe<Scalars['String']>;
  description___not___in?: InputMaybe<Array<Scalars['String']>>;
  description___not___null?: InputMaybe<Scalars['Boolean']>;
  description___not___starts_with?: InputMaybe<Scalars['String']>;
  description___null?: InputMaybe<Scalars['Boolean']>;
  description___starts_with?: InputMaybe<Scalars['String']>;
  disabled_by___contains?: InputMaybe<Scalars['String']>;
  disabled_by___ends_with?: InputMaybe<Scalars['String']>;
  disabled_by___eq?: InputMaybe<Scalars['String']>;
  disabled_by___gt?: InputMaybe<Scalars['String']>;
  disabled_by___gte?: InputMaybe<Scalars['String']>;
  disabled_by___in?: InputMaybe<Array<Scalars['String']>>;
  disabled_by___lt?: InputMaybe<Scalars['String']>;
  disabled_by___lte?: InputMaybe<Scalars['String']>;
  disabled_by___ne?: InputMaybe<Scalars['String']>;
  disabled_by___not___contains?: InputMaybe<Scalars['String']>;
  disabled_by___not___ends_with?: InputMaybe<Scalars['String']>;
  disabled_by___not___in?: InputMaybe<Array<Scalars['String']>>;
  disabled_by___not___null?: InputMaybe<Scalars['Boolean']>;
  disabled_by___not___starts_with?: InputMaybe<Scalars['String']>;
  disabled_by___null?: InputMaybe<Scalars['Boolean']>;
  disabled_by___starts_with?: InputMaybe<Scalars['String']>;
  enabled_by___contains?: InputMaybe<Scalars['String']>;
  enabled_by___ends_with?: InputMaybe<Scalars['String']>;
  enabled_by___eq?: InputMaybe<Scalars['String']>;
  enabled_by___gt?: InputMaybe<Scalars['String']>;
  enabled_by___gte?: InputMaybe<Scalars['String']>;
  enabled_by___in?: InputMaybe<Array<Scalars['String']>>;
  enabled_by___lt?: InputMaybe<Scalars['String']>;
  enabled_by___lte?: InputMaybe<Scalars['String']>;
  enabled_by___ne?: InputMaybe<Scalars['String']>;
  enabled_by___not___contains?: InputMaybe<Scalars['String']>;
  enabled_by___not___ends_with?: InputMaybe<Scalars['String']>;
  enabled_by___not___in?: InputMaybe<Array<Scalars['String']>>;
  enabled_by___not___null?: InputMaybe<Scalars['Boolean']>;
  enabled_by___not___starts_with?: InputMaybe<Scalars['String']>;
  enabled_by___null?: InputMaybe<Scalars['Boolean']>;
  enabled_by___starts_with?: InputMaybe<Scalars['String']>;
  int_range___eq?: InputMaybe<Scalars['Boolean']>;
  int_range___ne?: InputMaybe<Scalars['Boolean']>;
  int_range___not___null?: InputMaybe<Scalars['Boolean']>;
  int_range___null?: InputMaybe<Scalars['Boolean']>;
  intro_only___eq?: InputMaybe<Scalars['Boolean']>;
  intro_only___ne?: InputMaybe<Scalars['Boolean']>;
  intro_only___not___null?: InputMaybe<Scalars['Boolean']>;
  intro_only___null?: InputMaybe<Scalars['Boolean']>;
  langs___contains?: InputMaybe<Scalars['String']>;
  langs___ends_with?: InputMaybe<Scalars['String']>;
  langs___eq?: InputMaybe<Scalars['String']>;
  langs___gt?: InputMaybe<Scalars['String']>;
  langs___gte?: InputMaybe<Scalars['String']>;
  langs___in?: InputMaybe<Array<Scalars['String']>>;
  langs___lt?: InputMaybe<Scalars['String']>;
  langs___lte?: InputMaybe<Scalars['String']>;
  langs___ne?: InputMaybe<Scalars['String']>;
  langs___not___contains?: InputMaybe<Scalars['String']>;
  langs___not___ends_with?: InputMaybe<Scalars['String']>;
  langs___not___in?: InputMaybe<Array<Scalars['String']>>;
  langs___not___null?: InputMaybe<Scalars['Boolean']>;
  langs___not___starts_with?: InputMaybe<Scalars['String']>;
  langs___null?: InputMaybe<Scalars['Boolean']>;
  langs___starts_with?: InputMaybe<Scalars['String']>;
  lolli_only___eq?: InputMaybe<Scalars['Boolean']>;
  lolli_only___ne?: InputMaybe<Scalars['Boolean']>;
  lolli_only___not___null?: InputMaybe<Scalars['Boolean']>;
  lolli_only___null?: InputMaybe<Scalars['Boolean']>;
  mandatory___eq?: InputMaybe<Scalars['Boolean']>;
  mandatory___ne?: InputMaybe<Scalars['Boolean']>;
  mandatory___not___null?: InputMaybe<Scalars['Boolean']>;
  mandatory___null?: InputMaybe<Scalars['Boolean']>;
  mult_choice___eq?: InputMaybe<Scalars['Boolean']>;
  mult_choice___ne?: InputMaybe<Scalars['Boolean']>;
  mult_choice___not___null?: InputMaybe<Scalars['Boolean']>;
  mult_choice___null?: InputMaybe<Scalars['Boolean']>;
  n_possible_responses___eq?: InputMaybe<Scalars['Int']>;
  n_possible_responses___gt?: InputMaybe<Scalars['Int']>;
  n_possible_responses___gte?: InputMaybe<Scalars['Int']>;
  n_possible_responses___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  n_possible_responses___lt?: InputMaybe<Scalars['Int']>;
  n_possible_responses___lte?: InputMaybe<Scalars['Int']>;
  n_possible_responses___ne?: InputMaybe<Scalars['Int']>;
  n_possible_responses___not___in?: InputMaybe<Array<Scalars['Int']>>;
  n_possible_responses___not___null?: InputMaybe<Scalars['Boolean']>;
  n_possible_responses___null?: InputMaybe<Scalars['Boolean']>;
  order___eq?: InputMaybe<Scalars['Int']>;
  order___gt?: InputMaybe<Scalars['Int']>;
  order___gte?: InputMaybe<Scalars['Int']>;
  order___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  order___lt?: InputMaybe<Scalars['Int']>;
  order___lte?: InputMaybe<Scalars['Int']>;
  order___ne?: InputMaybe<Scalars['Int']>;
  order___not___in?: InputMaybe<Array<Scalars['Int']>>;
  order___not___null?: InputMaybe<Scalars['Boolean']>;
  order___null?: InputMaybe<Scalars['Boolean']>;
  possible_responses___contains?: InputMaybe<Scalars['String']>;
  possible_responses___ends_with?: InputMaybe<Scalars['String']>;
  possible_responses___eq?: InputMaybe<Scalars['String']>;
  possible_responses___gt?: InputMaybe<Scalars['String']>;
  possible_responses___gte?: InputMaybe<Scalars['String']>;
  possible_responses___in?: InputMaybe<Array<Scalars['String']>>;
  possible_responses___lt?: InputMaybe<Scalars['String']>;
  possible_responses___lte?: InputMaybe<Scalars['String']>;
  possible_responses___ne?: InputMaybe<Scalars['String']>;
  possible_responses___not___contains?: InputMaybe<Scalars['String']>;
  possible_responses___not___ends_with?: InputMaybe<Scalars['String']>;
  possible_responses___not___in?: InputMaybe<Array<Scalars['String']>>;
  possible_responses___not___null?: InputMaybe<Scalars['Boolean']>;
  possible_responses___not___starts_with?: InputMaybe<Scalars['String']>;
  possible_responses___null?: InputMaybe<Scalars['Boolean']>;
  possible_responses___starts_with?: InputMaybe<Scalars['String']>;
  question____contains?: InputMaybe<Scalars['String']>;
  question____ends_with?: InputMaybe<Scalars['String']>;
  question____eq?: InputMaybe<Scalars['String']>;
  question____gt?: InputMaybe<Scalars['String']>;
  question____gte?: InputMaybe<Scalars['String']>;
  question____in?: InputMaybe<Array<Scalars['String']>>;
  question____lt?: InputMaybe<Scalars['String']>;
  question____lte?: InputMaybe<Scalars['String']>;
  question____ne?: InputMaybe<Scalars['String']>;
  question____not___contains?: InputMaybe<Scalars['String']>;
  question____not___ends_with?: InputMaybe<Scalars['String']>;
  question____not___in?: InputMaybe<Array<Scalars['String']>>;
  question____not___null?: InputMaybe<Scalars['Boolean']>;
  question____not___starts_with?: InputMaybe<Scalars['String']>;
  question____null?: InputMaybe<Scalars['Boolean']>;
  question____starts_with?: InputMaybe<Scalars['String']>;
  question_fixed___contains?: InputMaybe<Scalars['String']>;
  question_fixed___ends_with?: InputMaybe<Scalars['String']>;
  question_fixed___eq?: InputMaybe<Scalars['String']>;
  question_fixed___gt?: InputMaybe<Scalars['String']>;
  question_fixed___gte?: InputMaybe<Scalars['String']>;
  question_fixed___in?: InputMaybe<Array<Scalars['String']>>;
  question_fixed___lt?: InputMaybe<Scalars['String']>;
  question_fixed___lte?: InputMaybe<Scalars['String']>;
  question_fixed___ne?: InputMaybe<Scalars['String']>;
  question_fixed___not___contains?: InputMaybe<Scalars['String']>;
  question_fixed___not___ends_with?: InputMaybe<Scalars['String']>;
  question_fixed___not___in?: InputMaybe<Array<Scalars['String']>>;
  question_fixed___not___null?: InputMaybe<Scalars['Boolean']>;
  question_fixed___not___starts_with?: InputMaybe<Scalars['String']>;
  question_fixed___null?: InputMaybe<Scalars['Boolean']>;
  question_fixed___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire___contains?: InputMaybe<Scalars['String']>;
  questionnaire___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire___eq?: InputMaybe<Scalars['String']>;
  questionnaire___gt?: InputMaybe<Scalars['String']>;
  questionnaire___gte?: InputMaybe<Scalars['String']>;
  questionnaire___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire___lt?: InputMaybe<Scalars['String']>;
  questionnaire___lte?: InputMaybe<Scalars['String']>;
  questionnaire___ne?: InputMaybe<Scalars['String']>;
  questionnaire___not___contains?: InputMaybe<Scalars['String']>;
  questionnaire___not___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire___not___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire___not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire___not___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_section___contains?: InputMaybe<Scalars['String']>;
  questionnaire_section___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire_section___eq?: InputMaybe<Scalars['String']>;
  questionnaire_section___gt?: InputMaybe<Scalars['String']>;
  questionnaire_section___gte?: InputMaybe<Scalars['String']>;
  questionnaire_section___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire_section___lt?: InputMaybe<Scalars['String']>;
  questionnaire_section___lte?: InputMaybe<Scalars['String']>;
  questionnaire_section___ne?: InputMaybe<Scalars['String']>;
  questionnaire_section___not___contains?: InputMaybe<Scalars['String']>;
  questionnaire_section___not___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire_section___not___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire_section___not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_section___not___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_section___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_section___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_section_code___contains?: InputMaybe<Scalars['String']>;
  questionnaire_section_code___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire_section_code___eq?: InputMaybe<Scalars['String']>;
  questionnaire_section_code___gt?: InputMaybe<Scalars['String']>;
  questionnaire_section_code___gte?: InputMaybe<Scalars['String']>;
  questionnaire_section_code___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire_section_code___lt?: InputMaybe<Scalars['String']>;
  questionnaire_section_code___lte?: InputMaybe<Scalars['String']>;
  questionnaire_section_code___ne?: InputMaybe<Scalars['String']>;
  questionnaire_section_code___not___contains?: InputMaybe<Scalars['String']>;
  questionnaire_section_code___not___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire_section_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire_section_code___not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_section_code___not___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_section_code___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_section_code___starts_with?: InputMaybe<Scalars['String']>;
  response_type___contains?: InputMaybe<Scalars['String']>;
  response_type___ends_with?: InputMaybe<Scalars['String']>;
  response_type___eq?: InputMaybe<Scalars['String']>;
  response_type___gt?: InputMaybe<Scalars['String']>;
  response_type___gte?: InputMaybe<Scalars['String']>;
  response_type___in?: InputMaybe<Array<Scalars['String']>>;
  response_type___lt?: InputMaybe<Scalars['String']>;
  response_type___lte?: InputMaybe<Scalars['String']>;
  response_type___ne?: InputMaybe<Scalars['String']>;
  response_type___not___contains?: InputMaybe<Scalars['String']>;
  response_type___not___ends_with?: InputMaybe<Scalars['String']>;
  response_type___not___in?: InputMaybe<Array<Scalars['String']>>;
  response_type___not___null?: InputMaybe<Scalars['Boolean']>;
  response_type___not___starts_with?: InputMaybe<Scalars['String']>;
  response_type___null?: InputMaybe<Scalars['Boolean']>;
  response_type___starts_with?: InputMaybe<Scalars['String']>;
  section_code___contains?: InputMaybe<Scalars['String']>;
  section_code___ends_with?: InputMaybe<Scalars['String']>;
  section_code___eq?: InputMaybe<Scalars['String']>;
  section_code___gt?: InputMaybe<Scalars['String']>;
  section_code___gte?: InputMaybe<Scalars['String']>;
  section_code___in?: InputMaybe<Array<Scalars['String']>>;
  section_code___lt?: InputMaybe<Scalars['String']>;
  section_code___lte?: InputMaybe<Scalars['String']>;
  section_code___ne?: InputMaybe<Scalars['String']>;
  section_code___not___contains?: InputMaybe<Scalars['String']>;
  section_code___not___ends_with?: InputMaybe<Scalars['String']>;
  section_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  section_code___not___null?: InputMaybe<Scalars['Boolean']>;
  section_code___not___starts_with?: InputMaybe<Scalars['String']>;
  section_code___null?: InputMaybe<Scalars['Boolean']>;
  section_code___starts_with?: InputMaybe<Scalars['String']>;
  section_levels___contains?: InputMaybe<Scalars['String']>;
  section_levels___ends_with?: InputMaybe<Scalars['String']>;
  section_levels___eq?: InputMaybe<Scalars['String']>;
  section_levels___gt?: InputMaybe<Scalars['String']>;
  section_levels___gte?: InputMaybe<Scalars['String']>;
  section_levels___in?: InputMaybe<Array<Scalars['String']>>;
  section_levels___lt?: InputMaybe<Scalars['String']>;
  section_levels___lte?: InputMaybe<Scalars['String']>;
  section_levels___ne?: InputMaybe<Scalars['String']>;
  section_levels___not___contains?: InputMaybe<Scalars['String']>;
  section_levels___not___ends_with?: InputMaybe<Scalars['String']>;
  section_levels___not___in?: InputMaybe<Array<Scalars['String']>>;
  section_levels___not___null?: InputMaybe<Scalars['Boolean']>;
  section_levels___not___starts_with?: InputMaybe<Scalars['String']>;
  section_levels___null?: InputMaybe<Scalars['Boolean']>;
  section_levels___starts_with?: InputMaybe<Scalars['String']>;
  section_user_types___contains?: InputMaybe<Scalars['String']>;
  section_user_types___ends_with?: InputMaybe<Scalars['String']>;
  section_user_types___eq?: InputMaybe<Scalars['String']>;
  section_user_types___gt?: InputMaybe<Scalars['String']>;
  section_user_types___gte?: InputMaybe<Scalars['String']>;
  section_user_types___in?: InputMaybe<Array<Scalars['String']>>;
  section_user_types___lt?: InputMaybe<Scalars['String']>;
  section_user_types___lte?: InputMaybe<Scalars['String']>;
  section_user_types___ne?: InputMaybe<Scalars['String']>;
  section_user_types___not___contains?: InputMaybe<Scalars['String']>;
  section_user_types___not___ends_with?: InputMaybe<Scalars['String']>;
  section_user_types___not___in?: InputMaybe<Array<Scalars['String']>>;
  section_user_types___not___null?: InputMaybe<Scalars['Boolean']>;
  section_user_types___not___starts_with?: InputMaybe<Scalars['String']>;
  section_user_types___null?: InputMaybe<Scalars['Boolean']>;
  section_user_types___starts_with?: InputMaybe<Scalars['String']>;
  single_chioce___eq?: InputMaybe<Scalars['Boolean']>;
  single_chioce___ne?: InputMaybe<Scalars['Boolean']>;
  single_chioce___not___null?: InputMaybe<Scalars['Boolean']>;
  single_chioce___null?: InputMaybe<Scalars['Boolean']>;
  t0___eq?: InputMaybe<Scalars['Boolean']>;
  t0___ne?: InputMaybe<Scalars['Boolean']>;
  t0___not___null?: InputMaybe<Scalars['Boolean']>;
  t0___null?: InputMaybe<Scalars['Boolean']>;
  t1___eq?: InputMaybe<Scalars['Boolean']>;
  t1___ne?: InputMaybe<Scalars['Boolean']>;
  t1___not___null?: InputMaybe<Scalars['Boolean']>;
  t1___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Question. */
export type QuestionPage = {
  __typename?: 'QuestionPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Question. */
  items: Array<Question>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Question present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a QuestionPage. */
export type QuestionPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<QuestionFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<QuestionCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<QuestionSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Question */
export type QuestionRoleObjects = {
  /** An array of objects of class Question to be created. */
  create?: InputMaybe<Array<InputMaybe<QuestionCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Question to be updated. */
  update?: InputMaybe<Array<InputMaybe<QuestionUpdate>>>;
};

/** A data structure for the associated object of class Question */
export type QuestionRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the associated objects of class Question */
export type QuestionRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum QuestionSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  description___ASC = 'description___ASC',
  description___DESC = 'description___DESC',
  disabled_by___ASC = 'disabled_by___ASC',
  disabled_by___DESC = 'disabled_by___DESC',
  enabled_by___ASC = 'enabled_by___ASC',
  enabled_by___DESC = 'enabled_by___DESC',
  int_range___ASC = 'int_range___ASC',
  int_range___DESC = 'int_range___DESC',
  intro_only___ASC = 'intro_only___ASC',
  intro_only___DESC = 'intro_only___DESC',
  langs___ASC = 'langs___ASC',
  langs___DESC = 'langs___DESC',
  lolli_only___ASC = 'lolli_only___ASC',
  lolli_only___DESC = 'lolli_only___DESC',
  mandatory___ASC = 'mandatory___ASC',
  mandatory___DESC = 'mandatory___DESC',
  mult_choice___ASC = 'mult_choice___ASC',
  mult_choice___DESC = 'mult_choice___DESC',
  n_possible_responses___ASC = 'n_possible_responses___ASC',
  n_possible_responses___DESC = 'n_possible_responses___DESC',
  order___ASC = 'order___ASC',
  order___DESC = 'order___DESC',
  possible_responses___ASC = 'possible_responses___ASC',
  possible_responses___DESC = 'possible_responses___DESC',
  question____ASC = 'question____ASC',
  question____DESC = 'question____DESC',
  question_fixed___ASC = 'question_fixed___ASC',
  question_fixed___DESC = 'question_fixed___DESC',
  questionnaire___ASC = 'questionnaire___ASC',
  questionnaire___DESC = 'questionnaire___DESC',
  questionnaire_section___ASC = 'questionnaire_section___ASC',
  questionnaire_section___DESC = 'questionnaire_section___DESC',
  questionnaire_section_code___ASC = 'questionnaire_section_code___ASC',
  questionnaire_section_code___DESC = 'questionnaire_section_code___DESC',
  response_type___ASC = 'response_type___ASC',
  response_type___DESC = 'response_type___DESC',
  section_code___ASC = 'section_code___ASC',
  section_code___DESC = 'section_code___DESC',
  section_levels___ASC = 'section_levels___ASC',
  section_levels___DESC = 'section_levels___DESC',
  section_user_types___ASC = 'section_user_types___ASC',
  section_user_types___DESC = 'section_user_types___DESC',
  single_chioce___ASC = 'single_chioce___ASC',
  single_chioce___DESC = 'single_chioce___DESC',
  t0___ASC = 't0___ASC',
  t0___DESC = 't0___DESC',
  t1___ASC = 't1___ASC',
  t1___DESC = 't1___DESC'
}

/** A data structure for a single object of class Question. */
export type QuestionUpdate = {
  _id: Scalars['ID'];
  /** The attribute code of Question. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute lolli_only of Question. It is of type Boolean.  */
  lolli_only?: InputMaybe<Scalars['Boolean']>;
  /** The attribute mandatory of Question. It is of type Boolean. Cannot be null. */
  mandatory?: InputMaybe<Scalars['Boolean']>;
  /** The attribute order of Question. It is of type Int.  */
  order?: InputMaybe<Scalars['Int']>;
  /** The Association role question_(0,N) <--> (0,N)possibile_response_. */
  possibile_response_?: InputMaybe<Possible_ResponseRoleRefs>;
  /** The Association role question_condition_disabled(0,N) <--> (0,N)questions_disabled. */
  question_condition_disabled?: InputMaybe<Question_Enabling_ConditionRoleRefs>;
  /** The Association role question_condition_enabled(0,N) <--> (0,N)questions_enabled. */
  question_condition_enabled?: InputMaybe<Question_Enabling_ConditionRoleRefs>;
  /** The Association role question_enabling_condition_(0,N) <--> (1)question_. */
  question_enabling_condition_?: InputMaybe<Question_Enabling_ConditionRoleRefs>;
  /** The Composition role question_ ◊--> (1,N)question_lang_. */
  question_lang_?: InputMaybe<Question_LangRoleObjects>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Questionnaire_SectionRoleRef>;
  /** The Association role question_(0,N) --> (1)response_type_. */
  response_type_?: InputMaybe<Response_TypeRoleRef>;
  /** The attribute t0 of Question. It is of type Boolean.  */
  t0?: InputMaybe<Scalars['Boolean']>;
  /** The attribute t1 of Question. It is of type Boolean.  */
  t1?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for objects of class Question. */
export type QuestionUpdateBulk = {
  /** The attribute code of Question. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute lolli_only of Question. It is of type Boolean.  */
  lolli_only?: InputMaybe<Scalars['Boolean']>;
  /** The attribute mandatory of Question. It is of type Boolean. Cannot be null. */
  mandatory?: InputMaybe<Scalars['Boolean']>;
  /** The attribute order of Question. It is of type Int.  */
  order?: InputMaybe<Scalars['Int']>;
  /** The Association role question_(0,N) <--> (0,N)possibile_response_. */
  possibile_response_?: InputMaybe<Possible_ResponseRoleRefs>;
  /** The Association role question_condition_disabled(0,N) <--> (0,N)questions_disabled. */
  question_condition_disabled?: InputMaybe<Question_Enabling_ConditionRoleRefs>;
  /** The Association role question_condition_enabled(0,N) <--> (0,N)questions_enabled. */
  question_condition_enabled?: InputMaybe<Question_Enabling_ConditionRoleRefs>;
  /** The Association role question_enabling_condition_(0,N) <--> (1)question_. */
  question_enabling_condition_?: InputMaybe<Question_Enabling_ConditionRoleRefs>;
  /** The Composition role question_ ◊--> (1,N)question_lang_. */
  question_lang_?: InputMaybe<Question_LangRoleObjects>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Questionnaire_SectionRoleRef>;
  /** The Association role question_(0,N) --> (1)response_type_. */
  response_type_?: InputMaybe<Response_TypeRoleRef>;
  /** The attribute t0 of Question. It is of type Boolean.  */
  t0?: InputMaybe<Scalars['Boolean']>;
  /** The attribute t1 of Question. It is of type Boolean.  */
  t1?: InputMaybe<Scalars['Boolean']>;
};

/** Represents an object of class Question_enabling_condition, as defined in the model. */
export type Question_Enabling_Condition = {
  __typename?: 'Question_enabling_condition';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Question_enabling_condition. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute disabled_mandatory of Question_enabling_condition. It is of type Boolean. Path: OR - Logical ORquestions_disabled mandatory. Cannot be null. */
  disabled_mandatory?: Maybe<Scalars['Boolean']>;
  /** The attribute disabled_questions of Question_enabling_condition. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)questions_disabled code.  */
  disabled_questions?: Maybe<Scalars['String']>;
  /** The attribute enabled_mandatory of Question_enabling_condition. It is of type Boolean. Path: OR - Logical ORquestions_enabled mandatory. Cannot be null. */
  enabled_mandatory?: Maybe<Scalars['Boolean']>;
  /** The attribute enabled_questions of Question_enabling_condition. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)questions_enabled code.  */
  enabled_questions?: Maybe<Scalars['String']>;
  /** The associated object for the role possible_response_. */
  possible_response_?: Maybe<Possible_Response>;
  /** The associable objects for the role possible_response_. */
  possible_response____associables?: Maybe<Possible_ResponsePage>;
  /** The attribute question of Question_enabling_condition. It is of type String. Path: question_ description.  */
  question?: Maybe<Scalars['String']>;
  /** The associated object for the role question_. */
  question_?: Maybe<Question>;
  /** The associable objects for the role question_. */
  question____associables?: Maybe<QuestionPage>;
  /** The attribute question_code of Question_enabling_condition. It is of type String. Path: question_ code.  */
  question_code?: Maybe<Scalars['String']>;
  /** The attribute questionnaire_ of Question_enabling_condition. It is of type String. Path: questionnaire_schema_ quest_code. Cannot be null. */
  questionnaire_?: Maybe<Scalars['String']>;
  /** The associated object for the role questionnaire_schema_. */
  questionnaire_schema_?: Maybe<Questionnaire_Schema>;
  /** The associable objects for the role questionnaire_schema_. */
  questionnaire_schema____associables?: Maybe<Questionnaire_SchemaPage>;
  /** The associated objects for the role questions_disabled. */
  questions_disabled?: Maybe<QuestionPage>;
  /** The associable objects for the role questions_disabled. */
  questions_disabled___associables?: Maybe<QuestionPage>;
  /** The associated objects for the role questions_enabled. */
  questions_enabled?: Maybe<QuestionPage>;
  /** The associable objects for the role questions_enabled. */
  questions_enabled___associables?: Maybe<QuestionPage>;
  /** The attribute trigger_response of Question_enabling_condition. It is of type String. Path: possible_response_ response_. Cannot be null. */
  trigger_response?: Maybe<Scalars['String']>;
};


/** Represents an object of class Question_enabling_condition, as defined in the model. */
export type Question_Enabling_ConditionPossible_Response____AssociablesArgs = {
  options?: InputMaybe<Possible_ResponsePageOptions>;
};


/** Represents an object of class Question_enabling_condition, as defined in the model. */
export type Question_Enabling_ConditionQuestion____AssociablesArgs = {
  options?: InputMaybe<QuestionPageOptions>;
};


/** Represents an object of class Question_enabling_condition, as defined in the model. */
export type Question_Enabling_ConditionQuestionnaire_Schema____AssociablesArgs = {
  options?: InputMaybe<Questionnaire_SchemaPageOptions>;
};


/** Represents an object of class Question_enabling_condition, as defined in the model. */
export type Question_Enabling_ConditionQuestions_DisabledArgs = {
  options?: InputMaybe<QuestionPageOptions>;
};


/** Represents an object of class Question_enabling_condition, as defined in the model. */
export type Question_Enabling_ConditionQuestions_Disabled___AssociablesArgs = {
  options?: InputMaybe<QuestionPageOptions>;
};


/** Represents an object of class Question_enabling_condition, as defined in the model. */
export type Question_Enabling_ConditionQuestions_EnabledArgs = {
  options?: InputMaybe<QuestionPageOptions>;
};


/** Represents an object of class Question_enabling_condition, as defined in the model. */
export type Question_Enabling_ConditionQuestions_Enabled___AssociablesArgs = {
  options?: InputMaybe<QuestionPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Question_enabling_condition.
 */
export type Question_Enabling_ConditionCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  disabled_mandatory?: InputMaybe<Scalars['Boolean']>;
  disabled_questions?: InputMaybe<Scalars['String']>;
  enabled_mandatory?: InputMaybe<Scalars['Boolean']>;
  enabled_questions?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
  question_code?: InputMaybe<Scalars['String']>;
  questionnaire_?: InputMaybe<Scalars['String']>;
  trigger_response?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated objects of class Question_enabling_condition */
export type Question_Enabling_ConditionDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type Question_enabling_condition.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Question_Enabling_ConditionFilter = {
  AND?: InputMaybe<Array<Question_Enabling_ConditionFilter>>;
  NOT?: InputMaybe<Question_Enabling_ConditionFilter>;
  OR?: InputMaybe<Array<Question_Enabling_ConditionFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  disabled_mandatory___eq?: InputMaybe<Scalars['Boolean']>;
  disabled_mandatory___ne?: InputMaybe<Scalars['Boolean']>;
  disabled_mandatory___not___null?: InputMaybe<Scalars['Boolean']>;
  disabled_mandatory___null?: InputMaybe<Scalars['Boolean']>;
  disabled_questions___contains?: InputMaybe<Scalars['String']>;
  disabled_questions___ends_with?: InputMaybe<Scalars['String']>;
  disabled_questions___eq?: InputMaybe<Scalars['String']>;
  disabled_questions___gt?: InputMaybe<Scalars['String']>;
  disabled_questions___gte?: InputMaybe<Scalars['String']>;
  disabled_questions___in?: InputMaybe<Array<Scalars['String']>>;
  disabled_questions___lt?: InputMaybe<Scalars['String']>;
  disabled_questions___lte?: InputMaybe<Scalars['String']>;
  disabled_questions___ne?: InputMaybe<Scalars['String']>;
  disabled_questions___not___contains?: InputMaybe<Scalars['String']>;
  disabled_questions___not___ends_with?: InputMaybe<Scalars['String']>;
  disabled_questions___not___in?: InputMaybe<Array<Scalars['String']>>;
  disabled_questions___not___null?: InputMaybe<Scalars['Boolean']>;
  disabled_questions___not___starts_with?: InputMaybe<Scalars['String']>;
  disabled_questions___null?: InputMaybe<Scalars['Boolean']>;
  disabled_questions___starts_with?: InputMaybe<Scalars['String']>;
  enabled_mandatory___eq?: InputMaybe<Scalars['Boolean']>;
  enabled_mandatory___ne?: InputMaybe<Scalars['Boolean']>;
  enabled_mandatory___not___null?: InputMaybe<Scalars['Boolean']>;
  enabled_mandatory___null?: InputMaybe<Scalars['Boolean']>;
  enabled_questions___contains?: InputMaybe<Scalars['String']>;
  enabled_questions___ends_with?: InputMaybe<Scalars['String']>;
  enabled_questions___eq?: InputMaybe<Scalars['String']>;
  enabled_questions___gt?: InputMaybe<Scalars['String']>;
  enabled_questions___gte?: InputMaybe<Scalars['String']>;
  enabled_questions___in?: InputMaybe<Array<Scalars['String']>>;
  enabled_questions___lt?: InputMaybe<Scalars['String']>;
  enabled_questions___lte?: InputMaybe<Scalars['String']>;
  enabled_questions___ne?: InputMaybe<Scalars['String']>;
  enabled_questions___not___contains?: InputMaybe<Scalars['String']>;
  enabled_questions___not___ends_with?: InputMaybe<Scalars['String']>;
  enabled_questions___not___in?: InputMaybe<Array<Scalars['String']>>;
  enabled_questions___not___null?: InputMaybe<Scalars['Boolean']>;
  enabled_questions___not___starts_with?: InputMaybe<Scalars['String']>;
  enabled_questions___null?: InputMaybe<Scalars['Boolean']>;
  enabled_questions___starts_with?: InputMaybe<Scalars['String']>;
  question___contains?: InputMaybe<Scalars['String']>;
  question___ends_with?: InputMaybe<Scalars['String']>;
  question___eq?: InputMaybe<Scalars['String']>;
  question___gt?: InputMaybe<Scalars['String']>;
  question___gte?: InputMaybe<Scalars['String']>;
  question___in?: InputMaybe<Array<Scalars['String']>>;
  question___lt?: InputMaybe<Scalars['String']>;
  question___lte?: InputMaybe<Scalars['String']>;
  question___ne?: InputMaybe<Scalars['String']>;
  question___not___contains?: InputMaybe<Scalars['String']>;
  question___not___ends_with?: InputMaybe<Scalars['String']>;
  question___not___in?: InputMaybe<Array<Scalars['String']>>;
  question___not___null?: InputMaybe<Scalars['Boolean']>;
  question___not___starts_with?: InputMaybe<Scalars['String']>;
  question___null?: InputMaybe<Scalars['Boolean']>;
  question___starts_with?: InputMaybe<Scalars['String']>;
  question_code___contains?: InputMaybe<Scalars['String']>;
  question_code___ends_with?: InputMaybe<Scalars['String']>;
  question_code___eq?: InputMaybe<Scalars['String']>;
  question_code___gt?: InputMaybe<Scalars['String']>;
  question_code___gte?: InputMaybe<Scalars['String']>;
  question_code___in?: InputMaybe<Array<Scalars['String']>>;
  question_code___lt?: InputMaybe<Scalars['String']>;
  question_code___lte?: InputMaybe<Scalars['String']>;
  question_code___ne?: InputMaybe<Scalars['String']>;
  question_code___not___contains?: InputMaybe<Scalars['String']>;
  question_code___not___ends_with?: InputMaybe<Scalars['String']>;
  question_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  question_code___not___null?: InputMaybe<Scalars['Boolean']>;
  question_code___not___starts_with?: InputMaybe<Scalars['String']>;
  question_code___null?: InputMaybe<Scalars['Boolean']>;
  question_code___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire____contains?: InputMaybe<Scalars['String']>;
  questionnaire____ends_with?: InputMaybe<Scalars['String']>;
  questionnaire____eq?: InputMaybe<Scalars['String']>;
  questionnaire____gt?: InputMaybe<Scalars['String']>;
  questionnaire____gte?: InputMaybe<Scalars['String']>;
  questionnaire____in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire____lt?: InputMaybe<Scalars['String']>;
  questionnaire____lte?: InputMaybe<Scalars['String']>;
  questionnaire____ne?: InputMaybe<Scalars['String']>;
  questionnaire____not___contains?: InputMaybe<Scalars['String']>;
  questionnaire____not___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire____not___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire____not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire____not___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire____null?: InputMaybe<Scalars['Boolean']>;
  questionnaire____starts_with?: InputMaybe<Scalars['String']>;
  trigger_response___contains?: InputMaybe<Scalars['String']>;
  trigger_response___ends_with?: InputMaybe<Scalars['String']>;
  trigger_response___eq?: InputMaybe<Scalars['String']>;
  trigger_response___gt?: InputMaybe<Scalars['String']>;
  trigger_response___gte?: InputMaybe<Scalars['String']>;
  trigger_response___in?: InputMaybe<Array<Scalars['String']>>;
  trigger_response___lt?: InputMaybe<Scalars['String']>;
  trigger_response___lte?: InputMaybe<Scalars['String']>;
  trigger_response___ne?: InputMaybe<Scalars['String']>;
  trigger_response___not___contains?: InputMaybe<Scalars['String']>;
  trigger_response___not___ends_with?: InputMaybe<Scalars['String']>;
  trigger_response___not___in?: InputMaybe<Array<Scalars['String']>>;
  trigger_response___not___null?: InputMaybe<Scalars['Boolean']>;
  trigger_response___not___starts_with?: InputMaybe<Scalars['String']>;
  trigger_response___null?: InputMaybe<Scalars['Boolean']>;
  trigger_response___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Question_enabling_condition. */
export type Question_Enabling_ConditionPage = {
  __typename?: 'Question_enabling_conditionPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Question_enabling_condition. */
  items: Array<Question_Enabling_Condition>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Question_enabling_condition present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Question_enabling_conditionPage. */
export type Question_Enabling_ConditionPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Question_Enabling_ConditionFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Question_Enabling_ConditionCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Question_Enabling_ConditionSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated objects of class Question_enabling_condition */
export type Question_Enabling_ConditionRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Question_Enabling_ConditionSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  disabled_mandatory___ASC = 'disabled_mandatory___ASC',
  disabled_mandatory___DESC = 'disabled_mandatory___DESC',
  disabled_questions___ASC = 'disabled_questions___ASC',
  disabled_questions___DESC = 'disabled_questions___DESC',
  enabled_mandatory___ASC = 'enabled_mandatory___ASC',
  enabled_mandatory___DESC = 'enabled_mandatory___DESC',
  enabled_questions___ASC = 'enabled_questions___ASC',
  enabled_questions___DESC = 'enabled_questions___DESC',
  question___ASC = 'question___ASC',
  question___DESC = 'question___DESC',
  question_code___ASC = 'question_code___ASC',
  question_code___DESC = 'question_code___DESC',
  questionnaire____ASC = 'questionnaire____ASC',
  questionnaire____DESC = 'questionnaire____DESC',
  trigger_response___ASC = 'trigger_response___ASC',
  trigger_response___DESC = 'trigger_response___DESC'
}

/** Represents an object of class Question_lang, as defined in the model. */
export type Question_Lang = {
  __typename?: 'Question_lang';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Question_lang. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute lang of Question_lang. It is of type String. Path: lang_ name. Cannot be null. */
  lang?: Maybe<Scalars['String']>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
  /** The attribute question of Question_lang. It is of type Text. Cannot be null. */
  question?: Maybe<Scalars['Text']>;
  /** The associated object for the role question_. */
  question_?: Maybe<Question>;
};


/** Represents an object of class Question_lang, as defined in the model. */
export type Question_LangLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};

/** A data structure for objects of Question_lang. */
export type Question_LangCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role question_lang_(0,N) --> (1)lang_. */
  lang_: Scalars['ID'];
  /** The attribute question of Question_lang. It is of type Text!. Cannot be null. */
  question: Scalars['Text'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Question_lang.
 */
export type Question_LangCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  lang?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['Text']>;
};

/** A data structure for a draft object of class Question_lang. */
export type Question_LangDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The Association role question_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute question of Question_lang. It is of type Text. Cannot be null. */
  question?: InputMaybe<Scalars['Text']>;
};

/** A data structure for draft create objects of class Question_lang. */
export type Question_LangDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role question_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute question of Question_lang. It is of type Text. Cannot be null. */
  question?: InputMaybe<Scalars['Text']>;
};

/** A data structure for the draft part objects of class Question_lang */
export type Question_LangDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Question_lang to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Question_LangDraft>>>;
};

/** A data structure for a draft update object of class Question_lang. */
export type Question_LangDraftUpdate = {
  _id: Scalars['ID'];
  /** The Association role question_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute question of Question_lang. It is of type Text. Cannot be null. */
  question?: InputMaybe<Scalars['Text']>;
};

/** A data structure for a draft update objects of class Question_lang. */
export type Question_LangDraftUpdateBulk = {
  /** The Association role question_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute question of Question_lang. It is of type Text. Cannot be null. */
  question?: InputMaybe<Scalars['Text']>;
};

/** A data structure for the draft part objects of class Question_lang */
export type Question_LangDraftUpdateRoleObjects = {
  /** An array of objects of class Question_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Question_LangDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Question_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Question_LangDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Question_lang.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Question_LangFilter = {
  AND?: InputMaybe<Array<Question_LangFilter>>;
  NOT?: InputMaybe<Question_LangFilter>;
  OR?: InputMaybe<Array<Question_LangFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  lang___contains?: InputMaybe<Scalars['String']>;
  lang___ends_with?: InputMaybe<Scalars['String']>;
  lang___eq?: InputMaybe<Scalars['String']>;
  lang___gt?: InputMaybe<Scalars['String']>;
  lang___gte?: InputMaybe<Scalars['String']>;
  lang___in?: InputMaybe<Array<Scalars['String']>>;
  lang___lt?: InputMaybe<Scalars['String']>;
  lang___lte?: InputMaybe<Scalars['String']>;
  lang___ne?: InputMaybe<Scalars['String']>;
  lang___not___contains?: InputMaybe<Scalars['String']>;
  lang___not___ends_with?: InputMaybe<Scalars['String']>;
  lang___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang___not___null?: InputMaybe<Scalars['Boolean']>;
  lang___not___starts_with?: InputMaybe<Scalars['String']>;
  lang___null?: InputMaybe<Scalars['Boolean']>;
  lang___starts_with?: InputMaybe<Scalars['String']>;
  question___contains?: InputMaybe<Scalars['Text']>;
  question___ends_with?: InputMaybe<Scalars['Text']>;
  question___eq?: InputMaybe<Scalars['Text']>;
  question___gt?: InputMaybe<Scalars['Text']>;
  question___gte?: InputMaybe<Scalars['Text']>;
  question___in?: InputMaybe<Array<Scalars['Text']>>;
  question___lt?: InputMaybe<Scalars['Text']>;
  question___lte?: InputMaybe<Scalars['Text']>;
  question___ne?: InputMaybe<Scalars['Text']>;
  question___not___contains?: InputMaybe<Scalars['Text']>;
  question___not___ends_with?: InputMaybe<Scalars['Text']>;
  question___not___in?: InputMaybe<Array<Scalars['Text']>>;
  question___not___null?: InputMaybe<Scalars['Boolean']>;
  question___not___starts_with?: InputMaybe<Scalars['Text']>;
  question___null?: InputMaybe<Scalars['Boolean']>;
  question___starts_with?: InputMaybe<Scalars['Text']>;
};

/** Represents a collection of objects of class Question_lang. */
export type Question_LangPage = {
  __typename?: 'Question_langPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Question_lang. */
  items: Array<Question_Lang>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Question_lang present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Question_langPage. */
export type Question_LangPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Question_LangFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Question_LangCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Question_LangSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Question_lang */
export type Question_LangRoleObjects = {
  /** An array of objects of class Question_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Question_LangCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Question_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Question_LangUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Question_LangSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  lang___ASC = 'lang___ASC',
  lang___DESC = 'lang___DESC',
  question___ASC = 'question___ASC',
  question___DESC = 'question___DESC'
}

/** A data structure for a single object of class Question_lang. */
export type Question_LangUpdate = {
  _id: Scalars['ID'];
  /** The Association role question_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute question of Question_lang. It is of type Text. Cannot be null. */
  question?: InputMaybe<Scalars['Text']>;
};

/** A data structure for objects of class Question_lang. */
export type Question_LangUpdateBulk = {
  /** The Association role question_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute question of Question_lang. It is of type Text. Cannot be null. */
  question?: InputMaybe<Scalars['Text']>;
};

/** Represents an object of class Questionnaire_completion, as defined in the model. */
export type Questionnaire_Completion = {
  __typename?: 'Questionnaire_completion';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _createdby of Questionnaire_completion. It is of type String. Cannot be null. */
  _createdby?: Maybe<Scalars['String']>;
  /** The attribute _createdon of Questionnaire_completion. It is of type Datetime @datetimeFormat. Cannot be null. */
  _createdon?: Maybe<Scalars['Datetime']>;
  /** The attribute _id of Questionnaire_completion. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute _lastmodifiedon of Questionnaire_completion. It is of type Datetime @datetimeFormat. Cannot be null. */
  _lastmodifiedon?: Maybe<Scalars['Datetime']>;
  /** The attribute alert_mandat2 of Questionnaire_completion. It is of type Boolean. Expression: 'differenceCS(question_mandat_ids, question_resp_ids)!=concat("")'. Cannot be null. */
  alert_mandat2?: Maybe<Scalars['Boolean']>;
  /** The attribute allowed of Questionnaire_completion. It is of type Boolean.  */
  allowed?: Maybe<Scalars['Boolean']>;
  /** The attribute center of Questionnaire_completion. It is of type String. Expression: 'firstNotNull(school_center,patient_center)'. Cannot be null. */
  center?: Maybe<Scalars['String']>;
  /** The attribute compiled_by_school_admin of Questionnaire_completion. It is of type Boolean.  */
  compiled_by_school_admin?: Maybe<Scalars['Boolean']>;
  /** The attribute completed of Questionnaire_completion. It is of type Boolean.  */
  completed?: Maybe<Scalars['Boolean']>;
  /** The attribute completion_date of Questionnaire_completion. It is of type Date @dateFormat. Cannot be null. */
  completion_date?: Maybe<Scalars['Date']>;
  /** The associated object for the role family_. */
  family_?: Maybe<Family>;
  /** The associable objects for the role family_. */
  family____associables?: Maybe<FamilyPage>;
  /** The attribute mobile of Questionnaire_completion. It is of type Boolean.  */
  mobile?: Maybe<Scalars['Boolean']>;
  /** The attribute observational_study of Questionnaire_completion. It is of type Boolean. Path: school_people_.school_class_ observational.  */
  observational_study?: Maybe<Scalars['Boolean']>;
  /** The associated object for the role patient_. */
  patient_?: Maybe<Patient>;
  /** The associable objects for the role patient_. */
  patient____associables?: Maybe<PatientPage>;
  /** The attribute patient_center of Questionnaire_completion. It is of type String. Path: patient_.center_ name. Cannot be null. */
  patient_center?: Maybe<Scalars['String']>;
  /** The attribute patient_code of Questionnaire_completion. It is of type String. Path: patient_ code. Cannot be null. */
  patient_code?: Maybe<Scalars['String']>;
  /** The attribute patient_id of Questionnaire_completion. It is of type Int. Path: patient_ __id. Cannot be null. */
  patient_id?: Maybe<Scalars['Int']>;
  /** The attribute questionnaire of Questionnaire_completion. It is of type String. Path: questionnaire_schema_ code. Cannot be null. */
  questionnaire?: Maybe<Scalars['String']>;
  /** The associated object for the role questionnaire_schema_. */
  questionnaire_schema_?: Maybe<Questionnaire_Schema>;
  /** The associable objects for the role questionnaire_schema_. */
  questionnaire_schema____associables?: Maybe<Questionnaire_SchemaPage>;
  /** The attribute rand_study of Questionnaire_completion. It is of type Boolean. Path: school_people_.school_class_ randomized. Cannot be null. */
  rand_study?: Maybe<Scalars['Boolean']>;
  /** The associated objects for the role response_. */
  response_?: Maybe<ResponsePage>;
  /** The attribute responses of Questionnaire_completion. It is of type Int. Path: COUNT_DISTINCT - Count distinctresponse_ __id. Cannot be null. */
  responses?: Maybe<Scalars['Int']>;
  /** The attribute school of Questionnaire_completion. It is of type String. Path: school_ name. Cannot be null. */
  school?: Maybe<Scalars['String']>;
  /** The associated object for the role school_. */
  school_?: Maybe<School>;
  /** The associable objects for the role school_. */
  school____associables?: Maybe<SchoolPage>;
  /** The attribute school_center of Questionnaire_completion. It is of type String. Path: school_.center_ name. Cannot be null. */
  school_center?: Maybe<Scalars['String']>;
  /** The attribute school_center_id of Questionnaire_completion. It is of type Int. Path: school_.center_ __id. Cannot be null. */
  school_center_id?: Maybe<Scalars['Int']>;
  /** The associated object for the role school_class_. */
  school_class_?: Maybe<School_Class>;
  /** The associable objects for the role school_class_. */
  school_class____associables?: Maybe<School_ClassPage>;
  /** The associated object for the role school_people_. */
  school_people_?: Maybe<School_People>;
  /** The associable objects for the role school_people_. */
  school_people____associables?: Maybe<School_PeoplePage>;
  /** The attribute school_people_code of Questionnaire_completion. It is of type String. Path: school_people_ code.  */
  school_people_code?: Maybe<Scalars['String']>;
  /** The attribute school_people_level of Questionnaire_completion. It is of type String. Path: school_people_.school_class_ level. Cannot be null. */
  school_people_level?: Maybe<Scalars['String']>;
  /** The attribute test of Questionnaire_completion. It is of type Boolean. Path: school_people_.school_.center_ test.  */
  test?: Maybe<Scalars['Boolean']>;
  /** The attribute time of Questionnaire_completion. It is of type Int.  */
  time?: Maybe<Scalars['Int']>;
};


/** Represents an object of class Questionnaire_completion, as defined in the model. */
export type Questionnaire_Completion_CreatedonArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Questionnaire_completion, as defined in the model. */
export type Questionnaire_Completion_LastmodifiedonArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Questionnaire_completion, as defined in the model. */
export type Questionnaire_CompletionCompletion_DateArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Questionnaire_completion, as defined in the model. */
export type Questionnaire_CompletionFamily____AssociablesArgs = {
  options?: InputMaybe<FamilyPageOptions>;
};


/** Represents an object of class Questionnaire_completion, as defined in the model. */
export type Questionnaire_CompletionPatient____AssociablesArgs = {
  options?: InputMaybe<PatientPageOptions>;
};


/** Represents an object of class Questionnaire_completion, as defined in the model. */
export type Questionnaire_CompletionQuestionnaire_Schema____AssociablesArgs = {
  options?: InputMaybe<Questionnaire_SchemaPageOptions>;
};


/** Represents an object of class Questionnaire_completion, as defined in the model. */
export type Questionnaire_CompletionResponse_Args = {
  options?: InputMaybe<ResponsePageOptions>;
};


/** Represents an object of class Questionnaire_completion, as defined in the model. */
export type Questionnaire_CompletionSchool____AssociablesArgs = {
  options?: InputMaybe<SchoolPageOptions>;
};


/** Represents an object of class Questionnaire_completion, as defined in the model. */
export type Questionnaire_CompletionSchool_Class____AssociablesArgs = {
  options?: InputMaybe<School_ClassPageOptions>;
};


/** Represents an object of class Questionnaire_completion, as defined in the model. */
export type Questionnaire_CompletionSchool_People____AssociablesArgs = {
  options?: InputMaybe<School_PeoplePageOptions>;
};

/** The result of the Questionnaire_completion___updateBulk mutation on objects of class Questionnaire_completion. */
export type Questionnaire_CompletionBulkResult = {
  __typename?: 'Questionnaire_completionBulkResult';
  /** An array of objects of class Questionnaire_completion affected by the mutation. */
  items: Array<Questionnaire_Completion>;
  /** The total count of objects affected by the mutation, */
  totalCount: Scalars['Int'];
};

/** A data structure for objects of Questionnaire_completion. */
export type Questionnaire_CompletionCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute allowed of Questionnaire_completion. It is of type Boolean.  */
  allowed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute compiled_by_school_admin of Questionnaire_completion. It is of type Boolean.  */
  compiled_by_school_admin?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completed of Questionnaire_completion. It is of type Boolean.  */
  completed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completion_date of Questionnaire_completion. It is of type Date!. Cannot be null. */
  completion_date: Scalars['Date'];
  /** The Association role family_(0,1) <-- (0,N)questionnaire_completion_. */
  family_?: InputMaybe<Scalars['ID']>;
  /** The attribute mobile of Questionnaire_completion. It is of type Boolean.  */
  mobile?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)patient_. */
  patient_?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_completion_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_: Scalars['ID'];
  /** The Composition role questionnaire_completion_ ◊--> (0,N)response_. */
  response_?: InputMaybe<Array<InputMaybe<ResponseCreate>>>;
  /** The Association role school_(0,1) <-- (0,N)questionnaire_completion_. */
  school_?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)school_class_. */
  school_class_?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  school_people_?: InputMaybe<Scalars['ID']>;
  /** The attribute time of Questionnaire_completion. It is of type Int.  */
  time?: InputMaybe<Scalars['Int']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Questionnaire_completion.
 */
export type Questionnaire_CompletionCursor = {
  _createdby?: InputMaybe<Scalars['String']>;
  _createdon?: InputMaybe<Scalars['Datetime']>;
  _id?: InputMaybe<Scalars['ID']>;
  _lastmodifiedon?: InputMaybe<Scalars['Datetime']>;
  alert_mandat2?: InputMaybe<Scalars['Boolean']>;
  allowed?: InputMaybe<Scalars['Boolean']>;
  center?: InputMaybe<Scalars['String']>;
  compiled_by_school_admin?: InputMaybe<Scalars['Boolean']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  completion_date?: InputMaybe<Scalars['Date']>;
  mobile?: InputMaybe<Scalars['Boolean']>;
  observational_study?: InputMaybe<Scalars['Boolean']>;
  patient_center?: InputMaybe<Scalars['String']>;
  patient_code?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['Int']>;
  questionnaire?: InputMaybe<Scalars['String']>;
  rand_study?: InputMaybe<Scalars['Boolean']>;
  responses?: InputMaybe<Scalars['Int']>;
  school?: InputMaybe<Scalars['String']>;
  school_center?: InputMaybe<Scalars['String']>;
  school_center_id?: InputMaybe<Scalars['Int']>;
  school_people_code?: InputMaybe<Scalars['String']>;
  school_people_level?: InputMaybe<Scalars['String']>;
  test?: InputMaybe<Scalars['Boolean']>;
  time?: InputMaybe<Scalars['Int']>;
};

/** A data structure for a draft object of class Questionnaire_completion. */
export type Questionnaire_CompletionDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute allowed of Questionnaire_completion. It is of type Boolean.  */
  allowed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute compiled_by_school_admin of Questionnaire_completion. It is of type Boolean.  */
  compiled_by_school_admin?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completed of Questionnaire_completion. It is of type Boolean.  */
  completed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completion_date of Questionnaire_completion. It is of type Date. Cannot be null. */
  completion_date?: InputMaybe<Scalars['Date']>;
  /** The Association role family_(0,1) <-- (0,N)questionnaire_completion_. */
  family_?: InputMaybe<Scalars['ID']>;
  /** The attribute mobile of Questionnaire_completion. It is of type Boolean.  */
  mobile?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)patient_. */
  patient_?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_completion_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Scalars['ID']>;
  /** The Composition role questionnaire_completion_ ◊--> (0,N)response_. */
  response_?: InputMaybe<ResponseDraftRoleObjects>;
  /** The Association role school_(0,1) <-- (0,N)questionnaire_completion_. */
  school_?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)school_class_. */
  school_class_?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  school_people_?: InputMaybe<Scalars['ID']>;
  /** The attribute time of Questionnaire_completion. It is of type Int.  */
  time?: InputMaybe<Scalars['Int']>;
};

/** A data structure for draft create objects of class Questionnaire_completion. */
export type Questionnaire_CompletionDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute allowed of Questionnaire_completion. It is of type Boolean.  */
  allowed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute compiled_by_school_admin of Questionnaire_completion. It is of type Boolean.  */
  compiled_by_school_admin?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completed of Questionnaire_completion. It is of type Boolean.  */
  completed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completion_date of Questionnaire_completion. It is of type Date. Cannot be null. */
  completion_date?: InputMaybe<Scalars['Date']>;
  /** The Association role family_(0,1) <-- (0,N)questionnaire_completion_. */
  family_?: InputMaybe<Scalars['ID']>;
  /** The attribute mobile of Questionnaire_completion. It is of type Boolean.  */
  mobile?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)patient_. */
  patient_?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_completion_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Scalars['ID']>;
  /** The Composition role questionnaire_completion_ ◊--> (0,N)response_. */
  response_?: InputMaybe<Array<InputMaybe<ResponseDraftCreate>>>;
  /** The Association role school_(0,1) <-- (0,N)questionnaire_completion_. */
  school_?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)school_class_. */
  school_class_?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  school_people_?: InputMaybe<Scalars['ID']>;
  /** The attribute time of Questionnaire_completion. It is of type Int.  */
  time?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the draft associated object of class Questionnaire_completion */
export type Questionnaire_CompletionDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft associated objects of class Questionnaire_completion */
export type Questionnaire_CompletionDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for a draft update object of class Questionnaire_completion. */
export type Questionnaire_CompletionDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute allowed of Questionnaire_completion. It is of type Boolean.  */
  allowed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute compiled_by_school_admin of Questionnaire_completion. It is of type Boolean.  */
  compiled_by_school_admin?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completed of Questionnaire_completion. It is of type Boolean.  */
  completed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completion_date of Questionnaire_completion. It is of type Date. Cannot be null. */
  completion_date?: InputMaybe<Scalars['Date']>;
  /** The Association role family_(0,1) <-- (0,N)questionnaire_completion_. */
  family_?: InputMaybe<FamilyRoleRef>;
  /** The attribute mobile of Questionnaire_completion. It is of type Boolean.  */
  mobile?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)patient_. */
  patient_?: InputMaybe<PatientRoleRef>;
  /** The Association role questionnaire_completion_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Questionnaire_SchemaRoleRef>;
  /** The Composition role questionnaire_completion_ ◊--> (0,N)response_. */
  response_?: InputMaybe<ResponseDraftUpdateRoleObjects>;
  /** The Association role school_(0,1) <-- (0,N)questionnaire_completion_. */
  school_?: InputMaybe<SchoolRoleRef>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)school_class_. */
  school_class_?: InputMaybe<School_ClassRoleRef>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  school_people_?: InputMaybe<School_PeopleRoleRef>;
  /** The attribute time of Questionnaire_completion. It is of type Int.  */
  time?: InputMaybe<Scalars['Int']>;
};

/** A data structure for a draft update objects of class Questionnaire_completion. */
export type Questionnaire_CompletionDraftUpdateBulk = {
  /** The attribute allowed of Questionnaire_completion. It is of type Boolean.  */
  allowed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute compiled_by_school_admin of Questionnaire_completion. It is of type Boolean.  */
  compiled_by_school_admin?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completed of Questionnaire_completion. It is of type Boolean.  */
  completed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completion_date of Questionnaire_completion. It is of type Date. Cannot be null. */
  completion_date?: InputMaybe<Scalars['Date']>;
  /** The Association role family_(0,1) <-- (0,N)questionnaire_completion_. */
  family_?: InputMaybe<FamilyRoleRef>;
  /** The attribute mobile of Questionnaire_completion. It is of type Boolean.  */
  mobile?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)patient_. */
  patient_?: InputMaybe<PatientRoleRef>;
  /** The Association role questionnaire_completion_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Questionnaire_SchemaRoleRef>;
  /** The Composition role questionnaire_completion_ ◊--> (0,N)response_. */
  response_?: InputMaybe<ResponseDraftUpdateRoleObjects>;
  /** The Association role school_(0,1) <-- (0,N)questionnaire_completion_. */
  school_?: InputMaybe<SchoolRoleRef>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)school_class_. */
  school_class_?: InputMaybe<School_ClassRoleRef>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  school_people_?: InputMaybe<School_PeopleRoleRef>;
  /** The attribute time of Questionnaire_completion. It is of type Int.  */
  time?: InputMaybe<Scalars['Int']>;
};

/**
 * Define a specific filter for objects of type Questionnaire_completion.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Questionnaire_CompletionFilter = {
  AND?: InputMaybe<Array<Questionnaire_CompletionFilter>>;
  NOT?: InputMaybe<Questionnaire_CompletionFilter>;
  OR?: InputMaybe<Array<Questionnaire_CompletionFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _createdby___contains?: InputMaybe<Scalars['String']>;
  _createdby___ends_with?: InputMaybe<Scalars['String']>;
  _createdby___eq?: InputMaybe<Scalars['String']>;
  _createdby___gt?: InputMaybe<Scalars['String']>;
  _createdby___gte?: InputMaybe<Scalars['String']>;
  _createdby___in?: InputMaybe<Array<Scalars['String']>>;
  _createdby___lt?: InputMaybe<Scalars['String']>;
  _createdby___lte?: InputMaybe<Scalars['String']>;
  _createdby___ne?: InputMaybe<Scalars['String']>;
  _createdby___not___contains?: InputMaybe<Scalars['String']>;
  _createdby___not___ends_with?: InputMaybe<Scalars['String']>;
  _createdby___not___in?: InputMaybe<Array<Scalars['String']>>;
  _createdby___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdby___not___starts_with?: InputMaybe<Scalars['String']>;
  _createdby___null?: InputMaybe<Scalars['Boolean']>;
  _createdby___starts_with?: InputMaybe<Scalars['String']>;
  _createdon___eq?: InputMaybe<Scalars['Datetime']>;
  _createdon___gt?: InputMaybe<Scalars['Datetime']>;
  _createdon___gte?: InputMaybe<Scalars['Datetime']>;
  _createdon___in?: InputMaybe<Array<InputMaybe<Scalars['Datetime']>>>;
  _createdon___lt?: InputMaybe<Scalars['Datetime']>;
  _createdon___lte?: InputMaybe<Scalars['Datetime']>;
  _createdon___ne?: InputMaybe<Scalars['Datetime']>;
  _createdon___not___in?: InputMaybe<Array<Scalars['Datetime']>>;
  _createdon___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdon___null?: InputMaybe<Scalars['Boolean']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedon___eq?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___gt?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___gte?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___in?: InputMaybe<Array<InputMaybe<Scalars['Datetime']>>>;
  _lastmodifiedon___lt?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___lte?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___ne?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___not___in?: InputMaybe<Array<Scalars['Datetime']>>;
  _lastmodifiedon___not___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedon___null?: InputMaybe<Scalars['Boolean']>;
  alert_mandat2___eq?: InputMaybe<Scalars['Boolean']>;
  alert_mandat2___ne?: InputMaybe<Scalars['Boolean']>;
  alert_mandat2___not___null?: InputMaybe<Scalars['Boolean']>;
  alert_mandat2___null?: InputMaybe<Scalars['Boolean']>;
  allowed___eq?: InputMaybe<Scalars['Boolean']>;
  allowed___ne?: InputMaybe<Scalars['Boolean']>;
  allowed___not___null?: InputMaybe<Scalars['Boolean']>;
  allowed___null?: InputMaybe<Scalars['Boolean']>;
  center___contains?: InputMaybe<Scalars['String']>;
  center___ends_with?: InputMaybe<Scalars['String']>;
  center___eq?: InputMaybe<Scalars['String']>;
  center___gt?: InputMaybe<Scalars['String']>;
  center___gte?: InputMaybe<Scalars['String']>;
  center___in?: InputMaybe<Array<Scalars['String']>>;
  center___lt?: InputMaybe<Scalars['String']>;
  center___lte?: InputMaybe<Scalars['String']>;
  center___ne?: InputMaybe<Scalars['String']>;
  center___not___contains?: InputMaybe<Scalars['String']>;
  center___not___ends_with?: InputMaybe<Scalars['String']>;
  center___not___in?: InputMaybe<Array<Scalars['String']>>;
  center___not___null?: InputMaybe<Scalars['Boolean']>;
  center___not___starts_with?: InputMaybe<Scalars['String']>;
  center___null?: InputMaybe<Scalars['Boolean']>;
  center___starts_with?: InputMaybe<Scalars['String']>;
  compiled_by_school_admin___eq?: InputMaybe<Scalars['Boolean']>;
  compiled_by_school_admin___ne?: InputMaybe<Scalars['Boolean']>;
  compiled_by_school_admin___not___null?: InputMaybe<Scalars['Boolean']>;
  compiled_by_school_admin___null?: InputMaybe<Scalars['Boolean']>;
  completed___eq?: InputMaybe<Scalars['Boolean']>;
  completed___ne?: InputMaybe<Scalars['Boolean']>;
  completed___not___null?: InputMaybe<Scalars['Boolean']>;
  completed___null?: InputMaybe<Scalars['Boolean']>;
  completion_date___eq?: InputMaybe<Scalars['Date']>;
  completion_date___gt?: InputMaybe<Scalars['Date']>;
  completion_date___gte?: InputMaybe<Scalars['Date']>;
  completion_date___in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  completion_date___lt?: InputMaybe<Scalars['Date']>;
  completion_date___lte?: InputMaybe<Scalars['Date']>;
  completion_date___ne?: InputMaybe<Scalars['Date']>;
  completion_date___not___in?: InputMaybe<Array<Scalars['Date']>>;
  completion_date___not___null?: InputMaybe<Scalars['Boolean']>;
  completion_date___null?: InputMaybe<Scalars['Boolean']>;
  mobile___eq?: InputMaybe<Scalars['Boolean']>;
  mobile___ne?: InputMaybe<Scalars['Boolean']>;
  mobile___not___null?: InputMaybe<Scalars['Boolean']>;
  mobile___null?: InputMaybe<Scalars['Boolean']>;
  observational_study___eq?: InputMaybe<Scalars['Boolean']>;
  observational_study___ne?: InputMaybe<Scalars['Boolean']>;
  observational_study___not___null?: InputMaybe<Scalars['Boolean']>;
  observational_study___null?: InputMaybe<Scalars['Boolean']>;
  patient_center___contains?: InputMaybe<Scalars['String']>;
  patient_center___ends_with?: InputMaybe<Scalars['String']>;
  patient_center___eq?: InputMaybe<Scalars['String']>;
  patient_center___gt?: InputMaybe<Scalars['String']>;
  patient_center___gte?: InputMaybe<Scalars['String']>;
  patient_center___in?: InputMaybe<Array<Scalars['String']>>;
  patient_center___lt?: InputMaybe<Scalars['String']>;
  patient_center___lte?: InputMaybe<Scalars['String']>;
  patient_center___ne?: InputMaybe<Scalars['String']>;
  patient_center___not___contains?: InputMaybe<Scalars['String']>;
  patient_center___not___ends_with?: InputMaybe<Scalars['String']>;
  patient_center___not___in?: InputMaybe<Array<Scalars['String']>>;
  patient_center___not___null?: InputMaybe<Scalars['Boolean']>;
  patient_center___not___starts_with?: InputMaybe<Scalars['String']>;
  patient_center___null?: InputMaybe<Scalars['Boolean']>;
  patient_center___starts_with?: InputMaybe<Scalars['String']>;
  patient_code___contains?: InputMaybe<Scalars['String']>;
  patient_code___ends_with?: InputMaybe<Scalars['String']>;
  patient_code___eq?: InputMaybe<Scalars['String']>;
  patient_code___gt?: InputMaybe<Scalars['String']>;
  patient_code___gte?: InputMaybe<Scalars['String']>;
  patient_code___in?: InputMaybe<Array<Scalars['String']>>;
  patient_code___lt?: InputMaybe<Scalars['String']>;
  patient_code___lte?: InputMaybe<Scalars['String']>;
  patient_code___ne?: InputMaybe<Scalars['String']>;
  patient_code___not___contains?: InputMaybe<Scalars['String']>;
  patient_code___not___ends_with?: InputMaybe<Scalars['String']>;
  patient_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  patient_code___not___null?: InputMaybe<Scalars['Boolean']>;
  patient_code___not___starts_with?: InputMaybe<Scalars['String']>;
  patient_code___null?: InputMaybe<Scalars['Boolean']>;
  patient_code___starts_with?: InputMaybe<Scalars['String']>;
  patient_id___eq?: InputMaybe<Scalars['Int']>;
  patient_id___gt?: InputMaybe<Scalars['Int']>;
  patient_id___gte?: InputMaybe<Scalars['Int']>;
  patient_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  patient_id___lt?: InputMaybe<Scalars['Int']>;
  patient_id___lte?: InputMaybe<Scalars['Int']>;
  patient_id___ne?: InputMaybe<Scalars['Int']>;
  patient_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  patient_id___not___null?: InputMaybe<Scalars['Boolean']>;
  patient_id___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire___contains?: InputMaybe<Scalars['String']>;
  questionnaire___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire___eq?: InputMaybe<Scalars['String']>;
  questionnaire___gt?: InputMaybe<Scalars['String']>;
  questionnaire___gte?: InputMaybe<Scalars['String']>;
  questionnaire___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire___lt?: InputMaybe<Scalars['String']>;
  questionnaire___lte?: InputMaybe<Scalars['String']>;
  questionnaire___ne?: InputMaybe<Scalars['String']>;
  questionnaire___not___contains?: InputMaybe<Scalars['String']>;
  questionnaire___not___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire___not___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire___not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire___not___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire___starts_with?: InputMaybe<Scalars['String']>;
  rand_study___eq?: InputMaybe<Scalars['Boolean']>;
  rand_study___ne?: InputMaybe<Scalars['Boolean']>;
  rand_study___not___null?: InputMaybe<Scalars['Boolean']>;
  rand_study___null?: InputMaybe<Scalars['Boolean']>;
  responses___eq?: InputMaybe<Scalars['Int']>;
  responses___gt?: InputMaybe<Scalars['Int']>;
  responses___gte?: InputMaybe<Scalars['Int']>;
  responses___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  responses___lt?: InputMaybe<Scalars['Int']>;
  responses___lte?: InputMaybe<Scalars['Int']>;
  responses___ne?: InputMaybe<Scalars['Int']>;
  responses___not___in?: InputMaybe<Array<Scalars['Int']>>;
  responses___not___null?: InputMaybe<Scalars['Boolean']>;
  responses___null?: InputMaybe<Scalars['Boolean']>;
  school___contains?: InputMaybe<Scalars['String']>;
  school___ends_with?: InputMaybe<Scalars['String']>;
  school___eq?: InputMaybe<Scalars['String']>;
  school___gt?: InputMaybe<Scalars['String']>;
  school___gte?: InputMaybe<Scalars['String']>;
  school___in?: InputMaybe<Array<Scalars['String']>>;
  school___lt?: InputMaybe<Scalars['String']>;
  school___lte?: InputMaybe<Scalars['String']>;
  school___ne?: InputMaybe<Scalars['String']>;
  school___not___contains?: InputMaybe<Scalars['String']>;
  school___not___ends_with?: InputMaybe<Scalars['String']>;
  school___not___in?: InputMaybe<Array<Scalars['String']>>;
  school___not___null?: InputMaybe<Scalars['Boolean']>;
  school___not___starts_with?: InputMaybe<Scalars['String']>;
  school___null?: InputMaybe<Scalars['Boolean']>;
  school___starts_with?: InputMaybe<Scalars['String']>;
  school_center___contains?: InputMaybe<Scalars['String']>;
  school_center___ends_with?: InputMaybe<Scalars['String']>;
  school_center___eq?: InputMaybe<Scalars['String']>;
  school_center___gt?: InputMaybe<Scalars['String']>;
  school_center___gte?: InputMaybe<Scalars['String']>;
  school_center___in?: InputMaybe<Array<Scalars['String']>>;
  school_center___lt?: InputMaybe<Scalars['String']>;
  school_center___lte?: InputMaybe<Scalars['String']>;
  school_center___ne?: InputMaybe<Scalars['String']>;
  school_center___not___contains?: InputMaybe<Scalars['String']>;
  school_center___not___ends_with?: InputMaybe<Scalars['String']>;
  school_center___not___in?: InputMaybe<Array<Scalars['String']>>;
  school_center___not___null?: InputMaybe<Scalars['Boolean']>;
  school_center___not___starts_with?: InputMaybe<Scalars['String']>;
  school_center___null?: InputMaybe<Scalars['Boolean']>;
  school_center___starts_with?: InputMaybe<Scalars['String']>;
  school_center_id___eq?: InputMaybe<Scalars['Int']>;
  school_center_id___gt?: InputMaybe<Scalars['Int']>;
  school_center_id___gte?: InputMaybe<Scalars['Int']>;
  school_center_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  school_center_id___lt?: InputMaybe<Scalars['Int']>;
  school_center_id___lte?: InputMaybe<Scalars['Int']>;
  school_center_id___ne?: InputMaybe<Scalars['Int']>;
  school_center_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  school_center_id___not___null?: InputMaybe<Scalars['Boolean']>;
  school_center_id___null?: InputMaybe<Scalars['Boolean']>;
  school_people_code___contains?: InputMaybe<Scalars['String']>;
  school_people_code___ends_with?: InputMaybe<Scalars['String']>;
  school_people_code___eq?: InputMaybe<Scalars['String']>;
  school_people_code___gt?: InputMaybe<Scalars['String']>;
  school_people_code___gte?: InputMaybe<Scalars['String']>;
  school_people_code___in?: InputMaybe<Array<Scalars['String']>>;
  school_people_code___lt?: InputMaybe<Scalars['String']>;
  school_people_code___lte?: InputMaybe<Scalars['String']>;
  school_people_code___ne?: InputMaybe<Scalars['String']>;
  school_people_code___not___contains?: InputMaybe<Scalars['String']>;
  school_people_code___not___ends_with?: InputMaybe<Scalars['String']>;
  school_people_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  school_people_code___not___null?: InputMaybe<Scalars['Boolean']>;
  school_people_code___not___starts_with?: InputMaybe<Scalars['String']>;
  school_people_code___null?: InputMaybe<Scalars['Boolean']>;
  school_people_code___starts_with?: InputMaybe<Scalars['String']>;
  school_people_level___contains?: InputMaybe<Scalars['String']>;
  school_people_level___ends_with?: InputMaybe<Scalars['String']>;
  school_people_level___eq?: InputMaybe<Scalars['String']>;
  school_people_level___gt?: InputMaybe<Scalars['String']>;
  school_people_level___gte?: InputMaybe<Scalars['String']>;
  school_people_level___in?: InputMaybe<Array<Scalars['String']>>;
  school_people_level___lt?: InputMaybe<Scalars['String']>;
  school_people_level___lte?: InputMaybe<Scalars['String']>;
  school_people_level___ne?: InputMaybe<Scalars['String']>;
  school_people_level___not___contains?: InputMaybe<Scalars['String']>;
  school_people_level___not___ends_with?: InputMaybe<Scalars['String']>;
  school_people_level___not___in?: InputMaybe<Array<Scalars['String']>>;
  school_people_level___not___null?: InputMaybe<Scalars['Boolean']>;
  school_people_level___not___starts_with?: InputMaybe<Scalars['String']>;
  school_people_level___null?: InputMaybe<Scalars['Boolean']>;
  school_people_level___starts_with?: InputMaybe<Scalars['String']>;
  test___eq?: InputMaybe<Scalars['Boolean']>;
  test___ne?: InputMaybe<Scalars['Boolean']>;
  test___not___null?: InputMaybe<Scalars['Boolean']>;
  test___null?: InputMaybe<Scalars['Boolean']>;
  time___eq?: InputMaybe<Scalars['Int']>;
  time___gt?: InputMaybe<Scalars['Int']>;
  time___gte?: InputMaybe<Scalars['Int']>;
  time___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  time___lt?: InputMaybe<Scalars['Int']>;
  time___lte?: InputMaybe<Scalars['Int']>;
  time___ne?: InputMaybe<Scalars['Int']>;
  time___not___in?: InputMaybe<Array<Scalars['Int']>>;
  time___not___null?: InputMaybe<Scalars['Boolean']>;
  time___null?: InputMaybe<Scalars['Boolean']>;
};

/** The result of the form action invocation on class Questionnaire_completion. */
export type Questionnaire_CompletionFormActionHandlerResult = {
  __typename?: 'Questionnaire_completionFormActionHandlerResult';
  data?: Maybe<Questionnaire_Completion>;
  result: FormActionHandlerResult;
};

/** Represents a collection of objects of class Questionnaire_completion. */
export type Questionnaire_CompletionPage = {
  __typename?: 'Questionnaire_completionPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Questionnaire_completion. */
  items: Array<Questionnaire_Completion>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Questionnaire_completion present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Questionnaire_completionPage. */
export type Questionnaire_CompletionPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Questionnaire_CompletionFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Questionnaire_CompletionCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Questionnaire_CompletionSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Questionnaire_completion */
export type Questionnaire_CompletionRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the associated objects of class Questionnaire_completion */
export type Questionnaire_CompletionRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Questionnaire_CompletionSort {
  _createdby___ASC = '_createdby___ASC',
  _createdby___DESC = '_createdby___DESC',
  _createdon___ASC = '_createdon___ASC',
  _createdon___DESC = '_createdon___DESC',
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  _lastmodifiedon___ASC = '_lastmodifiedon___ASC',
  _lastmodifiedon___DESC = '_lastmodifiedon___DESC',
  alert_mandat2___ASC = 'alert_mandat2___ASC',
  alert_mandat2___DESC = 'alert_mandat2___DESC',
  allowed___ASC = 'allowed___ASC',
  allowed___DESC = 'allowed___DESC',
  center___ASC = 'center___ASC',
  center___DESC = 'center___DESC',
  compiled_by_school_admin___ASC = 'compiled_by_school_admin___ASC',
  compiled_by_school_admin___DESC = 'compiled_by_school_admin___DESC',
  completed___ASC = 'completed___ASC',
  completed___DESC = 'completed___DESC',
  completion_date___ASC = 'completion_date___ASC',
  completion_date___DESC = 'completion_date___DESC',
  mobile___ASC = 'mobile___ASC',
  mobile___DESC = 'mobile___DESC',
  observational_study___ASC = 'observational_study___ASC',
  observational_study___DESC = 'observational_study___DESC',
  patient_center___ASC = 'patient_center___ASC',
  patient_center___DESC = 'patient_center___DESC',
  patient_code___ASC = 'patient_code___ASC',
  patient_code___DESC = 'patient_code___DESC',
  patient_id___ASC = 'patient_id___ASC',
  patient_id___DESC = 'patient_id___DESC',
  questionnaire___ASC = 'questionnaire___ASC',
  questionnaire___DESC = 'questionnaire___DESC',
  rand_study___ASC = 'rand_study___ASC',
  rand_study___DESC = 'rand_study___DESC',
  responses___ASC = 'responses___ASC',
  responses___DESC = 'responses___DESC',
  school___ASC = 'school___ASC',
  school___DESC = 'school___DESC',
  school_center___ASC = 'school_center___ASC',
  school_center___DESC = 'school_center___DESC',
  school_center_id___ASC = 'school_center_id___ASC',
  school_center_id___DESC = 'school_center_id___DESC',
  school_people_code___ASC = 'school_people_code___ASC',
  school_people_code___DESC = 'school_people_code___DESC',
  school_people_level___ASC = 'school_people_level___ASC',
  school_people_level___DESC = 'school_people_level___DESC',
  test___ASC = 'test___ASC',
  test___DESC = 'test___DESC',
  time___ASC = 'time___ASC',
  time___DESC = 'time___DESC'
}

/** A data structure for a single object of class Questionnaire_completion. */
export type Questionnaire_CompletionUpdate = {
  _id: Scalars['ID'];
  /** The attribute allowed of Questionnaire_completion. It is of type Boolean.  */
  allowed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute compiled_by_school_admin of Questionnaire_completion. It is of type Boolean.  */
  compiled_by_school_admin?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completed of Questionnaire_completion. It is of type Boolean.  */
  completed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completion_date of Questionnaire_completion. It is of type Date. Cannot be null. */
  completion_date?: InputMaybe<Scalars['Date']>;
  /** The Association role family_(0,1) <-- (0,N)questionnaire_completion_. */
  family_?: InputMaybe<FamilyRoleRef>;
  /** The attribute mobile of Questionnaire_completion. It is of type Boolean.  */
  mobile?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)patient_. */
  patient_?: InputMaybe<PatientRoleRef>;
  /** The Association role questionnaire_completion_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Questionnaire_SchemaRoleRef>;
  /** The Composition role questionnaire_completion_ ◊--> (0,N)response_. */
  response_?: InputMaybe<ResponseRoleObjects>;
  /** The Association role school_(0,1) <-- (0,N)questionnaire_completion_. */
  school_?: InputMaybe<SchoolRoleRef>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)school_class_. */
  school_class_?: InputMaybe<School_ClassRoleRef>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  school_people_?: InputMaybe<School_PeopleRoleRef>;
  /** The attribute time of Questionnaire_completion. It is of type Int.  */
  time?: InputMaybe<Scalars['Int']>;
};

/** A data structure for objects of class Questionnaire_completion. */
export type Questionnaire_CompletionUpdateBulk = {
  /** The attribute allowed of Questionnaire_completion. It is of type Boolean.  */
  allowed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute compiled_by_school_admin of Questionnaire_completion. It is of type Boolean.  */
  compiled_by_school_admin?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completed of Questionnaire_completion. It is of type Boolean.  */
  completed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute completion_date of Questionnaire_completion. It is of type Date. Cannot be null. */
  completion_date?: InputMaybe<Scalars['Date']>;
  /** The Association role family_(0,1) <-- (0,N)questionnaire_completion_. */
  family_?: InputMaybe<FamilyRoleRef>;
  /** The attribute mobile of Questionnaire_completion. It is of type Boolean.  */
  mobile?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)patient_. */
  patient_?: InputMaybe<PatientRoleRef>;
  /** The Association role questionnaire_completion_(0,N) --> (1)questionnaire_schema_. */
  questionnaire_schema_?: InputMaybe<Questionnaire_SchemaRoleRef>;
  /** The Composition role questionnaire_completion_ ◊--> (0,N)response_. */
  response_?: InputMaybe<ResponseRoleObjects>;
  /** The Association role school_(0,1) <-- (0,N)questionnaire_completion_. */
  school_?: InputMaybe<SchoolRoleRef>;
  /** The Association role questionnaire_completion_(0,N) --> (0,1)school_class_. */
  school_class_?: InputMaybe<School_ClassRoleRef>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  school_people_?: InputMaybe<School_PeopleRoleRef>;
  /** The attribute time of Questionnaire_completion. It is of type Int.  */
  time?: InputMaybe<Scalars['Int']>;
};

/** Represents an object of singleton class Questionnaire_config, as defined in the model. */
export type Questionnaire_Config = {
  __typename?: 'Questionnaire_config';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Questionnaire_config. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute langs of Questionnaire_config. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)message_lang_.lang_ name. Cannot be null. */
  langs?: Maybe<Scalars['String']>;
  /** The associated objects for the role message_lang_. */
  message_lang_?: Maybe<Message_LangPage>;
  /** The associated objects for the role school_t0. */
  school_t0?: Maybe<SchoolPage>;
  /** The associable objects for the role school_t0. */
  school_t0___associables?: Maybe<SchoolPage>;
  /** The attribute school_t0_ids of Questionnaire_config. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)school_t0 __id. Cannot be null. */
  school_t0_ids?: Maybe<Scalars['String']>;
  /** The associated objects for the role school_t1. */
  school_t1?: Maybe<SchoolPage>;
  /** The associable objects for the role school_t1. */
  school_t1___associables?: Maybe<SchoolPage>;
  /** The attribute school_t1_ids of Questionnaire_config. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)school_t1 __id. Cannot be null. */
  school_t1_ids?: Maybe<Scalars['String']>;
};


/** Represents an object of singleton class Questionnaire_config, as defined in the model. */
export type Questionnaire_ConfigMessage_Lang_Args = {
  options?: InputMaybe<Message_LangPageOptions>;
};


/** Represents an object of singleton class Questionnaire_config, as defined in the model. */
export type Questionnaire_ConfigSchool_T0Args = {
  options?: InputMaybe<SchoolPageOptions>;
};


/** Represents an object of singleton class Questionnaire_config, as defined in the model. */
export type Questionnaire_ConfigSchool_T0___AssociablesArgs = {
  options?: InputMaybe<SchoolPageOptions>;
};


/** Represents an object of singleton class Questionnaire_config, as defined in the model. */
export type Questionnaire_ConfigSchool_T1Args = {
  options?: InputMaybe<SchoolPageOptions>;
};


/** Represents an object of singleton class Questionnaire_config, as defined in the model. */
export type Questionnaire_ConfigSchool_T1___AssociablesArgs = {
  options?: InputMaybe<SchoolPageOptions>;
};

/** A data structure for a draft update object of singleton class Questionnaire_config. */
export type Questionnaire_ConfigDraftUpdate = {
  /** The Composition role questionnaire_config_ ◊--> (0,N)message_lang_. */
  message_lang_?: InputMaybe<Message_LangDraftUpdateRoleObjects>;
  /** The Association role __questionnaire_config_(0,N) --> (0,N)school_t0. */
  school_t0?: InputMaybe<SchoolRoleRefs>;
  /** The Association role __questionnaire_config_1(0,N) --> (0,N)school_t1. */
  school_t1?: InputMaybe<SchoolRoleRefs>;
};

/** A data structure for a single object of singleton class Questionnaire_config. */
export type Questionnaire_ConfigUpdate = {
  /** The Composition role questionnaire_config_ ◊--> (0,N)message_lang_. */
  message_lang_?: InputMaybe<Message_LangRoleObjects>;
  /** The Association role __questionnaire_config_(0,N) --> (0,N)school_t0. */
  school_t0?: InputMaybe<SchoolRoleRefs>;
  /** The Association role __questionnaire_config_1(0,N) --> (0,N)school_t1. */
  school_t1?: InputMaybe<SchoolRoleRefs>;
};

/** Represents an object of class Questionnaire_lang, as defined in the model. */
export type Questionnaire_Lang = {
  __typename?: 'Questionnaire_lang';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Questionnaire_lang. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute description of Questionnaire_lang. It is of type Text.  */
  description?: Maybe<Scalars['Text']>;
  /** The attribute lang of Questionnaire_lang. It is of type String. Path: lang_ name. Cannot be null. */
  lang?: Maybe<Scalars['String']>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
  /** The associated object for the role questionnaire_schema_. */
  questionnaire_schema_?: Maybe<Questionnaire_Schema>;
  /** The attribute title of Questionnaire_lang. It is of type String. Max length: 255. Cannot be null. */
  title?: Maybe<Scalars['String']>;
};


/** Represents an object of class Questionnaire_lang, as defined in the model. */
export type Questionnaire_LangLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};

/** A data structure for objects of Questionnaire_lang. */
export type Questionnaire_LangCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute description of Questionnaire_lang. It is of type Text.  */
  description?: InputMaybe<Scalars['Text']>;
  /** The Association role questionnaire_lang_(0,N) --> (1)lang_. */
  lang_: Scalars['ID'];
  /** The attribute title of Questionnaire_lang. It is of type String!. Max length: 255. Cannot be null. */
  title: Scalars['String'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Questionnaire_lang.
 */
export type Questionnaire_LangCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['Text']>;
  lang?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft object of class Questionnaire_lang. */
export type Questionnaire_LangDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute description of Questionnaire_lang. It is of type Text.  */
  description?: InputMaybe<Scalars['Text']>;
  /** The Association role questionnaire_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute title of Questionnaire_lang. It is of type String. Max length: 255. Cannot be null. */
  title?: InputMaybe<Scalars['String']>;
};

/** A data structure for draft create objects of class Questionnaire_lang. */
export type Questionnaire_LangDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute description of Questionnaire_lang. It is of type Text.  */
  description?: InputMaybe<Scalars['Text']>;
  /** The Association role questionnaire_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute title of Questionnaire_lang. It is of type String. Max length: 255. Cannot be null. */
  title?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part objects of class Questionnaire_lang */
export type Questionnaire_LangDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Questionnaire_lang to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Questionnaire_LangDraft>>>;
};

/** A data structure for a draft update object of class Questionnaire_lang. */
export type Questionnaire_LangDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute description of Questionnaire_lang. It is of type Text.  */
  description?: InputMaybe<Scalars['Text']>;
  /** The Association role questionnaire_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute title of Questionnaire_lang. It is of type String. Max length: 255. Cannot be null. */
  title?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft update objects of class Questionnaire_lang. */
export type Questionnaire_LangDraftUpdateBulk = {
  /** The attribute description of Questionnaire_lang. It is of type Text.  */
  description?: InputMaybe<Scalars['Text']>;
  /** The Association role questionnaire_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute title of Questionnaire_lang. It is of type String. Max length: 255. Cannot be null. */
  title?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part objects of class Questionnaire_lang */
export type Questionnaire_LangDraftUpdateRoleObjects = {
  /** An array of objects of class Questionnaire_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Questionnaire_LangDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Questionnaire_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Questionnaire_LangDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Questionnaire_lang.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Questionnaire_LangFilter = {
  AND?: InputMaybe<Array<Questionnaire_LangFilter>>;
  NOT?: InputMaybe<Questionnaire_LangFilter>;
  OR?: InputMaybe<Array<Questionnaire_LangFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  description___contains?: InputMaybe<Scalars['Text']>;
  description___ends_with?: InputMaybe<Scalars['Text']>;
  description___eq?: InputMaybe<Scalars['Text']>;
  description___gt?: InputMaybe<Scalars['Text']>;
  description___gte?: InputMaybe<Scalars['Text']>;
  description___in?: InputMaybe<Array<Scalars['Text']>>;
  description___lt?: InputMaybe<Scalars['Text']>;
  description___lte?: InputMaybe<Scalars['Text']>;
  description___ne?: InputMaybe<Scalars['Text']>;
  description___not___contains?: InputMaybe<Scalars['Text']>;
  description___not___ends_with?: InputMaybe<Scalars['Text']>;
  description___not___in?: InputMaybe<Array<Scalars['Text']>>;
  description___not___null?: InputMaybe<Scalars['Boolean']>;
  description___not___starts_with?: InputMaybe<Scalars['Text']>;
  description___null?: InputMaybe<Scalars['Boolean']>;
  description___starts_with?: InputMaybe<Scalars['Text']>;
  lang___contains?: InputMaybe<Scalars['String']>;
  lang___ends_with?: InputMaybe<Scalars['String']>;
  lang___eq?: InputMaybe<Scalars['String']>;
  lang___gt?: InputMaybe<Scalars['String']>;
  lang___gte?: InputMaybe<Scalars['String']>;
  lang___in?: InputMaybe<Array<Scalars['String']>>;
  lang___lt?: InputMaybe<Scalars['String']>;
  lang___lte?: InputMaybe<Scalars['String']>;
  lang___ne?: InputMaybe<Scalars['String']>;
  lang___not___contains?: InputMaybe<Scalars['String']>;
  lang___not___ends_with?: InputMaybe<Scalars['String']>;
  lang___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang___not___null?: InputMaybe<Scalars['Boolean']>;
  lang___not___starts_with?: InputMaybe<Scalars['String']>;
  lang___null?: InputMaybe<Scalars['Boolean']>;
  lang___starts_with?: InputMaybe<Scalars['String']>;
  title___contains?: InputMaybe<Scalars['String']>;
  title___ends_with?: InputMaybe<Scalars['String']>;
  title___eq?: InputMaybe<Scalars['String']>;
  title___gt?: InputMaybe<Scalars['String']>;
  title___gte?: InputMaybe<Scalars['String']>;
  title___in?: InputMaybe<Array<Scalars['String']>>;
  title___lt?: InputMaybe<Scalars['String']>;
  title___lte?: InputMaybe<Scalars['String']>;
  title___ne?: InputMaybe<Scalars['String']>;
  title___not___contains?: InputMaybe<Scalars['String']>;
  title___not___ends_with?: InputMaybe<Scalars['String']>;
  title___not___in?: InputMaybe<Array<Scalars['String']>>;
  title___not___null?: InputMaybe<Scalars['Boolean']>;
  title___not___starts_with?: InputMaybe<Scalars['String']>;
  title___null?: InputMaybe<Scalars['Boolean']>;
  title___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Questionnaire_lang. */
export type Questionnaire_LangPage = {
  __typename?: 'Questionnaire_langPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Questionnaire_lang. */
  items: Array<Questionnaire_Lang>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Questionnaire_lang present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Questionnaire_langPage. */
export type Questionnaire_LangPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Questionnaire_LangFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Questionnaire_LangCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Questionnaire_LangSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Questionnaire_lang */
export type Questionnaire_LangRoleObjects = {
  /** An array of objects of class Questionnaire_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Questionnaire_LangCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Questionnaire_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Questionnaire_LangUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Questionnaire_LangSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  description___ASC = 'description___ASC',
  description___DESC = 'description___DESC',
  lang___ASC = 'lang___ASC',
  lang___DESC = 'lang___DESC',
  title___ASC = 'title___ASC',
  title___DESC = 'title___DESC'
}

/** A data structure for a single object of class Questionnaire_lang. */
export type Questionnaire_LangUpdate = {
  _id: Scalars['ID'];
  /** The attribute description of Questionnaire_lang. It is of type Text.  */
  description?: InputMaybe<Scalars['Text']>;
  /** The Association role questionnaire_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute title of Questionnaire_lang. It is of type String. Max length: 255. Cannot be null. */
  title?: InputMaybe<Scalars['String']>;
};

/** A data structure for objects of class Questionnaire_lang. */
export type Questionnaire_LangUpdateBulk = {
  /** The attribute description of Questionnaire_lang. It is of type Text.  */
  description?: InputMaybe<Scalars['Text']>;
  /** The Association role questionnaire_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute title of Questionnaire_lang. It is of type String. Max length: 255. Cannot be null. */
  title?: InputMaybe<Scalars['String']>;
};

/** Represents an object of class Questionnaire_schema, as defined in the model. */
export type Questionnaire_Schema = {
  __typename?: 'Questionnaire_schema';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Questionnaire_schema. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute code of Questionnaire_schema. It is of type String. Max length: 255. Cannot be null. */
  code?: Maybe<Scalars['String']>;
  /** The attribute contains_t0 of Questionnaire_schema. It is of type Boolean. Path: OR - Logical ORquestion_ t0.  */
  contains_t0?: Maybe<Scalars['Boolean']>;
  /** The attribute contains_t1 of Questionnaire_schema. It is of type Boolean. Path: OR - Logical ORquestion_ t1.  */
  contains_t1?: Maybe<Scalars['Boolean']>;
  /** The attribute description_ of Questionnaire_schema. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated) [if(containsCS(Questionnaire_schema.langs,__CurrentUser.lang),Questionnaire_lang.lang= __CurrentUser.lang,__CurrentUser.lang="English")]questionnaire_lang_ description.  */
  description_?: Maybe<Scalars['String']>;
  /** The attribute enabled of Questionnaire_schema. It is of type Boolean.  */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The attribute langs of Questionnaire_schema. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)questionnaire_lang_ lang. Cannot be null. */
  langs?: Maybe<Scalars['String']>;
  /** The associated objects for the role level_type_. */
  level_type_?: Maybe<Level_TypePage>;
  /** The associable objects for the role level_type_. */
  level_type____associables?: Maybe<Level_TypePage>;
  /** The attribute level_types of Questionnaire_schema. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)level_type_ level_type. Cannot be null. */
  level_types?: Maybe<Scalars['String']>;
  /** The attribute n_questions of Questionnaire_schema. It is of type Int. Path: COUNT_ALL - Count allquestion_ __id. Cannot be null. */
  n_questions?: Maybe<Scalars['Int']>;
  /** The attribute n_sections of Questionnaire_schema. It is of type Int. Path: COUNT_ALL - Count allquestionnaire_section_ __id. Cannot be null. */
  n_sections?: Maybe<Scalars['Int']>;
  /** The attribute quest_code of Questionnaire_schema. It is of type String. Expression: 'concat(questionnaire_," (",code,")")'. Cannot be null. */
  quest_code?: Maybe<Scalars['String']>;
  /** The associated objects for the role question_. */
  question_?: Maybe<QuestionPage>;
  /** The attribute questionnaire_ of Questionnaire_schema. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated) [if(isNull(__CurrentUser.lang),Questionnaire_lang.lang="English", if(containsCS(Questionnaire_schema.langs,__CurrentUser.lang),Questionnaire_lang.lang= __CurrentUser.lang,__CurrentUser.lang="English"))]questionnaire_lang_ title. Cannot be null. */
  questionnaire_?: Maybe<Scalars['String']>;
  /** The associated objects for the role questionnaire_lang_. */
  questionnaire_lang_?: Maybe<Questionnaire_LangPage>;
  /** The associated objects for the role questionnaire_section_. */
  questionnaire_section_?: Maybe<Questionnaire_SectionPage>;
  /** The attribute rand_study of Questionnaire_schema. It is of type Boolean.  */
  rand_study?: Maybe<Scalars['Boolean']>;
  /** The associated objects for the role user_type_. */
  user_type_?: Maybe<User_TypePage>;
  /** The associable objects for the role user_type_. */
  user_type____associables?: Maybe<User_TypePage>;
  /** The attribute user_types of Questionnaire_schema. It is of type String. Path: CONCAT_CS - Concat (comma separated)user_type_ name. Cannot be null. */
  user_types?: Maybe<Scalars['String']>;
  /** The attribute weekly of Questionnaire_schema. It is of type Boolean.  */
  weekly?: Maybe<Scalars['Boolean']>;
};


/** Represents an object of class Questionnaire_schema, as defined in the model. */
export type Questionnaire_SchemaLevel_Type_Args = {
  options?: InputMaybe<Level_TypePageOptions>;
};


/** Represents an object of class Questionnaire_schema, as defined in the model. */
export type Questionnaire_SchemaLevel_Type____AssociablesArgs = {
  options?: InputMaybe<Level_TypePageOptions>;
};


/** Represents an object of class Questionnaire_schema, as defined in the model. */
export type Questionnaire_SchemaQuestion_Args = {
  options?: InputMaybe<QuestionPageOptions>;
};


/** Represents an object of class Questionnaire_schema, as defined in the model. */
export type Questionnaire_SchemaQuestionnaire_Lang_Args = {
  options?: InputMaybe<Questionnaire_LangPageOptions>;
};


/** Represents an object of class Questionnaire_schema, as defined in the model. */
export type Questionnaire_SchemaQuestionnaire_Section_Args = {
  options?: InputMaybe<Questionnaire_SectionPageOptions>;
};


/** Represents an object of class Questionnaire_schema, as defined in the model. */
export type Questionnaire_SchemaUser_Type_Args = {
  options?: InputMaybe<User_TypePageOptions>;
};


/** Represents an object of class Questionnaire_schema, as defined in the model. */
export type Questionnaire_SchemaUser_Type____AssociablesArgs = {
  options?: InputMaybe<User_TypePageOptions>;
};

/** The result of the Questionnaire_schema___updateBulk mutation on objects of class Questionnaire_schema. */
export type Questionnaire_SchemaBulkResult = {
  __typename?: 'Questionnaire_schemaBulkResult';
  /** An array of objects of class Questionnaire_schema affected by the mutation. */
  items: Array<Questionnaire_Schema>;
  /** The total count of objects affected by the mutation, */
  totalCount: Scalars['Int'];
};

/** A data structure for objects of Questionnaire_schema. */
export type Questionnaire_SchemaCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute code of Questionnaire_schema. It is of type String!. Max length: 255. Cannot be null. */
  code: Scalars['String'];
  /** The attribute enabled of Questionnaire_schema. It is of type Boolean.  */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Composition role questionnaire_ ◊--> (0,N)question_. */
  question_?: InputMaybe<Array<InputMaybe<QuestionCreate>>>;
  /** The Composition role questionnaire_schema_ ◊--> (1,N)questionnaire_lang_. */
  questionnaire_lang_: Array<InputMaybe<Questionnaire_LangCreate>>;
  /** The Composition role questionnaire_schema_ ◊--> (0,N)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Array<InputMaybe<Questionnaire_SectionCreate>>>;
  /** The attribute rand_study of Questionnaire_schema. It is of type Boolean.  */
  rand_study?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (1,N)user_type_. */
  user_type_: Array<InputMaybe<Scalars['ID']>>;
  /** The attribute weekly of Questionnaire_schema. It is of type Boolean.  */
  weekly?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Questionnaire_schema.
 */
export type Questionnaire_SchemaCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  contains_t0?: InputMaybe<Scalars['Boolean']>;
  contains_t1?: InputMaybe<Scalars['Boolean']>;
  description_?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  langs?: InputMaybe<Scalars['String']>;
  level_types?: InputMaybe<Scalars['String']>;
  n_questions?: InputMaybe<Scalars['Int']>;
  n_sections?: InputMaybe<Scalars['Int']>;
  quest_code?: InputMaybe<Scalars['String']>;
  questionnaire_?: InputMaybe<Scalars['String']>;
  rand_study?: InputMaybe<Scalars['Boolean']>;
  user_types?: InputMaybe<Scalars['String']>;
  weekly?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for a draft object of class Questionnaire_schema. */
export type Questionnaire_SchemaDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute code of Questionnaire_schema. It is of type String. Max length: 255. Cannot be null. */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute enabled of Questionnaire_schema. It is of type Boolean.  */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Level_TypeDraftRoleRefs>;
  /** The Composition role questionnaire_ ◊--> (0,N)question_. */
  question_?: InputMaybe<QuestionDraftRoleObjects>;
  /** The Composition role questionnaire_schema_ ◊--> (1,N)questionnaire_lang_. */
  questionnaire_lang_?: InputMaybe<Questionnaire_LangDraftRoleObjects>;
  /** The Composition role questionnaire_schema_ ◊--> (0,N)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Questionnaire_SectionDraftRoleObjects>;
  /** The attribute rand_study of Questionnaire_schema. It is of type Boolean.  */
  rand_study?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (1,N)user_type_. */
  user_type_?: InputMaybe<User_TypeDraftRoleRefs>;
  /** The attribute weekly of Questionnaire_schema. It is of type Boolean.  */
  weekly?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for draft create objects of class Questionnaire_schema. */
export type Questionnaire_SchemaDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute code of Questionnaire_schema. It is of type String. Max length: 255. Cannot be null. */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute enabled of Questionnaire_schema. It is of type Boolean.  */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Composition role questionnaire_ ◊--> (0,N)question_. */
  question_?: InputMaybe<Array<InputMaybe<QuestionDraftCreate>>>;
  /** The Composition role questionnaire_schema_ ◊--> (1,N)questionnaire_lang_. */
  questionnaire_lang_?: InputMaybe<Array<InputMaybe<Questionnaire_LangDraftCreate>>>;
  /** The Composition role questionnaire_schema_ ◊--> (0,N)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Array<InputMaybe<Questionnaire_SectionDraftCreate>>>;
  /** The attribute rand_study of Questionnaire_schema. It is of type Boolean.  */
  rand_study?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (1,N)user_type_. */
  user_type_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The attribute weekly of Questionnaire_schema. It is of type Boolean.  */
  weekly?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft associated object of class Questionnaire_schema */
export type Questionnaire_SchemaDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft associated objects of class Questionnaire_schema */
export type Questionnaire_SchemaDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for a draft update object of class Questionnaire_schema. */
export type Questionnaire_SchemaDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute code of Questionnaire_schema. It is of type String. Max length: 255. Cannot be null. */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute enabled of Questionnaire_schema. It is of type Boolean.  */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Level_TypeRoleRefs>;
  /** The Composition role questionnaire_ ◊--> (0,N)question_. */
  question_?: InputMaybe<QuestionDraftUpdateRoleObjects>;
  /** The Composition role questionnaire_schema_ ◊--> (1,N)questionnaire_lang_. */
  questionnaire_lang_?: InputMaybe<Questionnaire_LangDraftUpdateRoleObjects>;
  /** The Composition role questionnaire_schema_ ◊--> (0,N)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Questionnaire_SectionDraftUpdateRoleObjects>;
  /** The attribute rand_study of Questionnaire_schema. It is of type Boolean.  */
  rand_study?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (1,N)user_type_. */
  user_type_?: InputMaybe<User_TypeRoleRefs>;
  /** The attribute weekly of Questionnaire_schema. It is of type Boolean.  */
  weekly?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for a draft update objects of class Questionnaire_schema. */
export type Questionnaire_SchemaDraftUpdateBulk = {
  /** The attribute code of Questionnaire_schema. It is of type String. Max length: 255. Cannot be null. */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute enabled of Questionnaire_schema. It is of type Boolean.  */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Level_TypeRoleRefs>;
  /** The Composition role questionnaire_ ◊--> (0,N)question_. */
  question_?: InputMaybe<QuestionDraftUpdateRoleObjects>;
  /** The Composition role questionnaire_schema_ ◊--> (1,N)questionnaire_lang_. */
  questionnaire_lang_?: InputMaybe<Questionnaire_LangDraftUpdateRoleObjects>;
  /** The Composition role questionnaire_schema_ ◊--> (0,N)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Questionnaire_SectionDraftUpdateRoleObjects>;
  /** The attribute rand_study of Questionnaire_schema. It is of type Boolean.  */
  rand_study?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (1,N)user_type_. */
  user_type_?: InputMaybe<User_TypeRoleRefs>;
  /** The attribute weekly of Questionnaire_schema. It is of type Boolean.  */
  weekly?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type Questionnaire_schema.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Questionnaire_SchemaFilter = {
  AND?: InputMaybe<Array<Questionnaire_SchemaFilter>>;
  NOT?: InputMaybe<Questionnaire_SchemaFilter>;
  OR?: InputMaybe<Array<Questionnaire_SchemaFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  contains_t0___eq?: InputMaybe<Scalars['Boolean']>;
  contains_t0___ne?: InputMaybe<Scalars['Boolean']>;
  contains_t0___not___null?: InputMaybe<Scalars['Boolean']>;
  contains_t0___null?: InputMaybe<Scalars['Boolean']>;
  contains_t1___eq?: InputMaybe<Scalars['Boolean']>;
  contains_t1___ne?: InputMaybe<Scalars['Boolean']>;
  contains_t1___not___null?: InputMaybe<Scalars['Boolean']>;
  contains_t1___null?: InputMaybe<Scalars['Boolean']>;
  description____contains?: InputMaybe<Scalars['String']>;
  description____ends_with?: InputMaybe<Scalars['String']>;
  description____eq?: InputMaybe<Scalars['String']>;
  description____gt?: InputMaybe<Scalars['String']>;
  description____gte?: InputMaybe<Scalars['String']>;
  description____in?: InputMaybe<Array<Scalars['String']>>;
  description____lt?: InputMaybe<Scalars['String']>;
  description____lte?: InputMaybe<Scalars['String']>;
  description____ne?: InputMaybe<Scalars['String']>;
  description____not___contains?: InputMaybe<Scalars['String']>;
  description____not___ends_with?: InputMaybe<Scalars['String']>;
  description____not___in?: InputMaybe<Array<Scalars['String']>>;
  description____not___null?: InputMaybe<Scalars['Boolean']>;
  description____not___starts_with?: InputMaybe<Scalars['String']>;
  description____null?: InputMaybe<Scalars['Boolean']>;
  description____starts_with?: InputMaybe<Scalars['String']>;
  enabled___eq?: InputMaybe<Scalars['Boolean']>;
  enabled___ne?: InputMaybe<Scalars['Boolean']>;
  enabled___not___null?: InputMaybe<Scalars['Boolean']>;
  enabled___null?: InputMaybe<Scalars['Boolean']>;
  langs___contains?: InputMaybe<Scalars['String']>;
  langs___ends_with?: InputMaybe<Scalars['String']>;
  langs___eq?: InputMaybe<Scalars['String']>;
  langs___gt?: InputMaybe<Scalars['String']>;
  langs___gte?: InputMaybe<Scalars['String']>;
  langs___in?: InputMaybe<Array<Scalars['String']>>;
  langs___lt?: InputMaybe<Scalars['String']>;
  langs___lte?: InputMaybe<Scalars['String']>;
  langs___ne?: InputMaybe<Scalars['String']>;
  langs___not___contains?: InputMaybe<Scalars['String']>;
  langs___not___ends_with?: InputMaybe<Scalars['String']>;
  langs___not___in?: InputMaybe<Array<Scalars['String']>>;
  langs___not___null?: InputMaybe<Scalars['Boolean']>;
  langs___not___starts_with?: InputMaybe<Scalars['String']>;
  langs___null?: InputMaybe<Scalars['Boolean']>;
  langs___starts_with?: InputMaybe<Scalars['String']>;
  level_types___contains?: InputMaybe<Scalars['String']>;
  level_types___ends_with?: InputMaybe<Scalars['String']>;
  level_types___eq?: InputMaybe<Scalars['String']>;
  level_types___gt?: InputMaybe<Scalars['String']>;
  level_types___gte?: InputMaybe<Scalars['String']>;
  level_types___in?: InputMaybe<Array<Scalars['String']>>;
  level_types___lt?: InputMaybe<Scalars['String']>;
  level_types___lte?: InputMaybe<Scalars['String']>;
  level_types___ne?: InputMaybe<Scalars['String']>;
  level_types___not___contains?: InputMaybe<Scalars['String']>;
  level_types___not___ends_with?: InputMaybe<Scalars['String']>;
  level_types___not___in?: InputMaybe<Array<Scalars['String']>>;
  level_types___not___null?: InputMaybe<Scalars['Boolean']>;
  level_types___not___starts_with?: InputMaybe<Scalars['String']>;
  level_types___null?: InputMaybe<Scalars['Boolean']>;
  level_types___starts_with?: InputMaybe<Scalars['String']>;
  n_questions___eq?: InputMaybe<Scalars['Int']>;
  n_questions___gt?: InputMaybe<Scalars['Int']>;
  n_questions___gte?: InputMaybe<Scalars['Int']>;
  n_questions___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  n_questions___lt?: InputMaybe<Scalars['Int']>;
  n_questions___lte?: InputMaybe<Scalars['Int']>;
  n_questions___ne?: InputMaybe<Scalars['Int']>;
  n_questions___not___in?: InputMaybe<Array<Scalars['Int']>>;
  n_questions___not___null?: InputMaybe<Scalars['Boolean']>;
  n_questions___null?: InputMaybe<Scalars['Boolean']>;
  n_sections___eq?: InputMaybe<Scalars['Int']>;
  n_sections___gt?: InputMaybe<Scalars['Int']>;
  n_sections___gte?: InputMaybe<Scalars['Int']>;
  n_sections___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  n_sections___lt?: InputMaybe<Scalars['Int']>;
  n_sections___lte?: InputMaybe<Scalars['Int']>;
  n_sections___ne?: InputMaybe<Scalars['Int']>;
  n_sections___not___in?: InputMaybe<Array<Scalars['Int']>>;
  n_sections___not___null?: InputMaybe<Scalars['Boolean']>;
  n_sections___null?: InputMaybe<Scalars['Boolean']>;
  quest_code___contains?: InputMaybe<Scalars['String']>;
  quest_code___ends_with?: InputMaybe<Scalars['String']>;
  quest_code___eq?: InputMaybe<Scalars['String']>;
  quest_code___gt?: InputMaybe<Scalars['String']>;
  quest_code___gte?: InputMaybe<Scalars['String']>;
  quest_code___in?: InputMaybe<Array<Scalars['String']>>;
  quest_code___lt?: InputMaybe<Scalars['String']>;
  quest_code___lte?: InputMaybe<Scalars['String']>;
  quest_code___ne?: InputMaybe<Scalars['String']>;
  quest_code___not___contains?: InputMaybe<Scalars['String']>;
  quest_code___not___ends_with?: InputMaybe<Scalars['String']>;
  quest_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  quest_code___not___null?: InputMaybe<Scalars['Boolean']>;
  quest_code___not___starts_with?: InputMaybe<Scalars['String']>;
  quest_code___null?: InputMaybe<Scalars['Boolean']>;
  quest_code___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire____contains?: InputMaybe<Scalars['String']>;
  questionnaire____ends_with?: InputMaybe<Scalars['String']>;
  questionnaire____eq?: InputMaybe<Scalars['String']>;
  questionnaire____gt?: InputMaybe<Scalars['String']>;
  questionnaire____gte?: InputMaybe<Scalars['String']>;
  questionnaire____in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire____lt?: InputMaybe<Scalars['String']>;
  questionnaire____lte?: InputMaybe<Scalars['String']>;
  questionnaire____ne?: InputMaybe<Scalars['String']>;
  questionnaire____not___contains?: InputMaybe<Scalars['String']>;
  questionnaire____not___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire____not___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire____not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire____not___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire____null?: InputMaybe<Scalars['Boolean']>;
  questionnaire____starts_with?: InputMaybe<Scalars['String']>;
  rand_study___eq?: InputMaybe<Scalars['Boolean']>;
  rand_study___ne?: InputMaybe<Scalars['Boolean']>;
  rand_study___not___null?: InputMaybe<Scalars['Boolean']>;
  rand_study___null?: InputMaybe<Scalars['Boolean']>;
  user_types___contains?: InputMaybe<Scalars['String']>;
  user_types___ends_with?: InputMaybe<Scalars['String']>;
  user_types___eq?: InputMaybe<Scalars['String']>;
  user_types___gt?: InputMaybe<Scalars['String']>;
  user_types___gte?: InputMaybe<Scalars['String']>;
  user_types___in?: InputMaybe<Array<Scalars['String']>>;
  user_types___lt?: InputMaybe<Scalars['String']>;
  user_types___lte?: InputMaybe<Scalars['String']>;
  user_types___ne?: InputMaybe<Scalars['String']>;
  user_types___not___contains?: InputMaybe<Scalars['String']>;
  user_types___not___ends_with?: InputMaybe<Scalars['String']>;
  user_types___not___in?: InputMaybe<Array<Scalars['String']>>;
  user_types___not___null?: InputMaybe<Scalars['Boolean']>;
  user_types___not___starts_with?: InputMaybe<Scalars['String']>;
  user_types___null?: InputMaybe<Scalars['Boolean']>;
  user_types___starts_with?: InputMaybe<Scalars['String']>;
  weekly___eq?: InputMaybe<Scalars['Boolean']>;
  weekly___ne?: InputMaybe<Scalars['Boolean']>;
  weekly___not___null?: InputMaybe<Scalars['Boolean']>;
  weekly___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Questionnaire_schema. */
export type Questionnaire_SchemaPage = {
  __typename?: 'Questionnaire_schemaPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Questionnaire_schema. */
  items: Array<Questionnaire_Schema>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Questionnaire_schema present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Questionnaire_schemaPage. */
export type Questionnaire_SchemaPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Questionnaire_SchemaFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Questionnaire_SchemaCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Questionnaire_SchemaSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Questionnaire_schema */
export type Questionnaire_SchemaRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the associated objects of class Questionnaire_schema */
export type Questionnaire_SchemaRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Questionnaire_SchemaSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  contains_t0___ASC = 'contains_t0___ASC',
  contains_t0___DESC = 'contains_t0___DESC',
  contains_t1___ASC = 'contains_t1___ASC',
  contains_t1___DESC = 'contains_t1___DESC',
  description____ASC = 'description____ASC',
  description____DESC = 'description____DESC',
  enabled___ASC = 'enabled___ASC',
  enabled___DESC = 'enabled___DESC',
  langs___ASC = 'langs___ASC',
  langs___DESC = 'langs___DESC',
  level_types___ASC = 'level_types___ASC',
  level_types___DESC = 'level_types___DESC',
  n_questions___ASC = 'n_questions___ASC',
  n_questions___DESC = 'n_questions___DESC',
  n_sections___ASC = 'n_sections___ASC',
  n_sections___DESC = 'n_sections___DESC',
  quest_code___ASC = 'quest_code___ASC',
  quest_code___DESC = 'quest_code___DESC',
  questionnaire____ASC = 'questionnaire____ASC',
  questionnaire____DESC = 'questionnaire____DESC',
  rand_study___ASC = 'rand_study___ASC',
  rand_study___DESC = 'rand_study___DESC',
  user_types___ASC = 'user_types___ASC',
  user_types___DESC = 'user_types___DESC',
  weekly___ASC = 'weekly___ASC',
  weekly___DESC = 'weekly___DESC'
}

/** A data structure for a single object of class Questionnaire_schema. */
export type Questionnaire_SchemaUpdate = {
  _id: Scalars['ID'];
  /** The attribute code of Questionnaire_schema. It is of type String. Max length: 255. Cannot be null. */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute enabled of Questionnaire_schema. It is of type Boolean.  */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Level_TypeRoleRefs>;
  /** The Composition role questionnaire_ ◊--> (0,N)question_. */
  question_?: InputMaybe<QuestionRoleObjects>;
  /** The Composition role questionnaire_schema_ ◊--> (1,N)questionnaire_lang_. */
  questionnaire_lang_?: InputMaybe<Questionnaire_LangRoleObjects>;
  /** The Composition role questionnaire_schema_ ◊--> (0,N)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Questionnaire_SectionRoleObjects>;
  /** The attribute rand_study of Questionnaire_schema. It is of type Boolean.  */
  rand_study?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (1,N)user_type_. */
  user_type_?: InputMaybe<User_TypeRoleRefs>;
  /** The attribute weekly of Questionnaire_schema. It is of type Boolean.  */
  weekly?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for objects of class Questionnaire_schema. */
export type Questionnaire_SchemaUpdateBulk = {
  /** The attribute code of Questionnaire_schema. It is of type String. Max length: 255. Cannot be null. */
  code?: InputMaybe<Scalars['String']>;
  /** The attribute enabled of Questionnaire_schema. It is of type Boolean.  */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Level_TypeRoleRefs>;
  /** The Composition role questionnaire_ ◊--> (0,N)question_. */
  question_?: InputMaybe<QuestionRoleObjects>;
  /** The Composition role questionnaire_schema_ ◊--> (1,N)questionnaire_lang_. */
  questionnaire_lang_?: InputMaybe<Questionnaire_LangRoleObjects>;
  /** The Composition role questionnaire_schema_ ◊--> (0,N)questionnaire_section_. */
  questionnaire_section_?: InputMaybe<Questionnaire_SectionRoleObjects>;
  /** The attribute rand_study of Questionnaire_schema. It is of type Boolean.  */
  rand_study?: InputMaybe<Scalars['Boolean']>;
  /** The Association role questionnaire_schema_(0,N) --> (1,N)user_type_. */
  user_type_?: InputMaybe<User_TypeRoleRefs>;
  /** The attribute weekly of Questionnaire_schema. It is of type Boolean.  */
  weekly?: InputMaybe<Scalars['Boolean']>;
};

/** Represents an object of class Questionnaire_section, as defined in the model. */
export type Questionnaire_Section = {
  __typename?: 'Questionnaire_section';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Questionnaire_section. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute code of Questionnaire_section. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The attribute langs of Questionnaire_section. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)questionnaire_section_lang_ lang. Cannot be null. */
  langs?: Maybe<Scalars['String']>;
  /** The associated objects for the role level_type_. */
  level_type_?: Maybe<Level_TypePage>;
  /** The associable objects for the role level_type_. */
  level_type____associables?: Maybe<Level_TypePage>;
  /** The attribute levels of Questionnaire_section. It is of type String. Path: CONCAT_CS - Concat (comma separated)level_type_ level_type. Cannot be null. */
  levels?: Maybe<Scalars['String']>;
  /** The attribute n_questions of Questionnaire_section. It is of type Int. Path: COUNT_ALL - Count allquestion_ __id. Cannot be null. */
  n_questions?: Maybe<Scalars['Int']>;
  /** The associated objects for the role question_. */
  question_?: Maybe<QuestionPage>;
  /** The associable objects for the role question_. */
  question____associables?: Maybe<QuestionPage>;
  /** The attribute questionnaire_ of Questionnaire_section. It is of type String. Path: questionnaire_schema_ questionnaire_. Cannot be null. */
  questionnaire_?: Maybe<Scalars['String']>;
  /** The attribute questionnaire_code of Questionnaire_section. It is of type String. Path: questionnaire_schema_ code. Cannot be null. */
  questionnaire_code?: Maybe<Scalars['String']>;
  /** The associated object for the role questionnaire_schema_. */
  questionnaire_schema_?: Maybe<Questionnaire_Schema>;
  /** The attribute questionnaire_section of Questionnaire_section. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated) [if(isNull(__CurrentUser.lang),Questionnaire_section_lang.lang="English", if(containsCS(Questionnaire_section.langs, __CurrentUser.lang),__CurrentUser.lang= Questionnaire_section_lang.lang,Questionnaire_section_lang.lang="English"))]questionnaire_section_lang_ questionnaire_section. Cannot be null. */
  questionnaire_section?: Maybe<Scalars['String']>;
  /** The associated objects for the role questionnaire_section_lang_. */
  questionnaire_section_lang_?: Maybe<Questionnaire_Section_LangPage>;
  /** The attribute questionnaire_secton_code of Questionnaire_section. It is of type String. Expression: 'concat(questionnaire_section," (",code,")")'.  */
  questionnaire_secton_code?: Maybe<Scalars['String']>;
  /** The attribute questionnaire_user_types of Questionnaire_section. It is of type String. Path: questionnaire_schema_ user_types. Cannot be null. */
  questionnaire_user_types?: Maybe<Scalars['String']>;
  /** The attribute section_user_types of Questionnaire_section. It is of type String. Path: CONCAT_CS - Concat (comma separated)user_type_ name. Cannot be null. */
  section_user_types?: Maybe<Scalars['String']>;
  /** The associated objects for the role user_type_. */
  user_type_?: Maybe<User_TypePage>;
  /** The associable objects for the role user_type_. */
  user_type____associables?: Maybe<User_TypePage>;
};


/** Represents an object of class Questionnaire_section, as defined in the model. */
export type Questionnaire_SectionLevel_Type_Args = {
  options?: InputMaybe<Level_TypePageOptions>;
};


/** Represents an object of class Questionnaire_section, as defined in the model. */
export type Questionnaire_SectionLevel_Type____AssociablesArgs = {
  options?: InputMaybe<Level_TypePageOptions>;
};


/** Represents an object of class Questionnaire_section, as defined in the model. */
export type Questionnaire_SectionQuestion_Args = {
  options?: InputMaybe<QuestionPageOptions>;
};


/** Represents an object of class Questionnaire_section, as defined in the model. */
export type Questionnaire_SectionQuestion____AssociablesArgs = {
  options?: InputMaybe<QuestionPageOptions>;
};


/** Represents an object of class Questionnaire_section, as defined in the model. */
export type Questionnaire_SectionQuestionnaire_Section_Lang_Args = {
  options?: InputMaybe<Questionnaire_Section_LangPageOptions>;
};


/** Represents an object of class Questionnaire_section, as defined in the model. */
export type Questionnaire_SectionUser_Type_Args = {
  options?: InputMaybe<User_TypePageOptions>;
};


/** Represents an object of class Questionnaire_section, as defined in the model. */
export type Questionnaire_SectionUser_Type____AssociablesArgs = {
  options?: InputMaybe<User_TypePageOptions>;
};

/** A data structure for objects of Questionnaire_section. */
export type Questionnaire_SectionCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute code of Questionnaire_section. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  question_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Composition role questionnaire_section_ ◊--> (0,N)questionnaire_section_lang_. */
  questionnaire_section_lang_?: InputMaybe<Array<InputMaybe<Questionnaire_Section_LangCreate>>>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)user_type_. */
  user_type_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Questionnaire_section.
 */
export type Questionnaire_SectionCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  langs?: InputMaybe<Scalars['String']>;
  levels?: InputMaybe<Scalars['String']>;
  n_questions?: InputMaybe<Scalars['Int']>;
  questionnaire_?: InputMaybe<Scalars['String']>;
  questionnaire_code?: InputMaybe<Scalars['String']>;
  questionnaire_section?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code?: InputMaybe<Scalars['String']>;
  questionnaire_user_types?: InputMaybe<Scalars['String']>;
  section_user_types?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft object of class Questionnaire_section. */
export type Questionnaire_SectionDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute code of Questionnaire_section. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Level_TypeDraftRoleRefs>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  question_?: InputMaybe<QuestionDraftRoleRefs>;
  /** The Composition role questionnaire_section_ ◊--> (0,N)questionnaire_section_lang_. */
  questionnaire_section_lang_?: InputMaybe<Questionnaire_Section_LangDraftRoleObjects>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)user_type_. */
  user_type_?: InputMaybe<User_TypeDraftRoleRefs>;
};

/** A data structure for draft create objects of class Questionnaire_section. */
export type Questionnaire_SectionDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute code of Questionnaire_section. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  question_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Composition role questionnaire_section_ ◊--> (0,N)questionnaire_section_lang_. */
  questionnaire_section_lang_?: InputMaybe<Array<InputMaybe<Questionnaire_Section_LangDraftCreate>>>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)user_type_. */
  user_type_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

/** A data structure for the draft part objects of class Questionnaire_section */
export type Questionnaire_SectionDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Questionnaire_section to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Questionnaire_SectionDraft>>>;
};

/** A data structure for the draft associated object of class Questionnaire_section */
export type Questionnaire_SectionDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for a draft update object of class Questionnaire_section. */
export type Questionnaire_SectionDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute code of Questionnaire_section. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Level_TypeRoleRefs>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  question_?: InputMaybe<QuestionRoleRefs>;
  /** The Composition role questionnaire_section_ ◊--> (0,N)questionnaire_section_lang_. */
  questionnaire_section_lang_?: InputMaybe<Questionnaire_Section_LangDraftUpdateRoleObjects>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)user_type_. */
  user_type_?: InputMaybe<User_TypeRoleRefs>;
};

/** A data structure for a draft update objects of class Questionnaire_section. */
export type Questionnaire_SectionDraftUpdateBulk = {
  /** The attribute code of Questionnaire_section. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Level_TypeRoleRefs>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  question_?: InputMaybe<QuestionRoleRefs>;
  /** The Composition role questionnaire_section_ ◊--> (0,N)questionnaire_section_lang_. */
  questionnaire_section_lang_?: InputMaybe<Questionnaire_Section_LangDraftUpdateRoleObjects>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)user_type_. */
  user_type_?: InputMaybe<User_TypeRoleRefs>;
};

/** A data structure for the draft part objects of class Questionnaire_section */
export type Questionnaire_SectionDraftUpdateRoleObjects = {
  /** An array of objects of class Questionnaire_section to be created. */
  create?: InputMaybe<Array<InputMaybe<Questionnaire_SectionDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Questionnaire_section to be updated. */
  update?: InputMaybe<Array<InputMaybe<Questionnaire_SectionDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Questionnaire_section.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Questionnaire_SectionFilter = {
  AND?: InputMaybe<Array<Questionnaire_SectionFilter>>;
  NOT?: InputMaybe<Questionnaire_SectionFilter>;
  OR?: InputMaybe<Array<Questionnaire_SectionFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  langs___contains?: InputMaybe<Scalars['String']>;
  langs___ends_with?: InputMaybe<Scalars['String']>;
  langs___eq?: InputMaybe<Scalars['String']>;
  langs___gt?: InputMaybe<Scalars['String']>;
  langs___gte?: InputMaybe<Scalars['String']>;
  langs___in?: InputMaybe<Array<Scalars['String']>>;
  langs___lt?: InputMaybe<Scalars['String']>;
  langs___lte?: InputMaybe<Scalars['String']>;
  langs___ne?: InputMaybe<Scalars['String']>;
  langs___not___contains?: InputMaybe<Scalars['String']>;
  langs___not___ends_with?: InputMaybe<Scalars['String']>;
  langs___not___in?: InputMaybe<Array<Scalars['String']>>;
  langs___not___null?: InputMaybe<Scalars['Boolean']>;
  langs___not___starts_with?: InputMaybe<Scalars['String']>;
  langs___null?: InputMaybe<Scalars['Boolean']>;
  langs___starts_with?: InputMaybe<Scalars['String']>;
  levels___contains?: InputMaybe<Scalars['String']>;
  levels___ends_with?: InputMaybe<Scalars['String']>;
  levels___eq?: InputMaybe<Scalars['String']>;
  levels___gt?: InputMaybe<Scalars['String']>;
  levels___gte?: InputMaybe<Scalars['String']>;
  levels___in?: InputMaybe<Array<Scalars['String']>>;
  levels___lt?: InputMaybe<Scalars['String']>;
  levels___lte?: InputMaybe<Scalars['String']>;
  levels___ne?: InputMaybe<Scalars['String']>;
  levels___not___contains?: InputMaybe<Scalars['String']>;
  levels___not___ends_with?: InputMaybe<Scalars['String']>;
  levels___not___in?: InputMaybe<Array<Scalars['String']>>;
  levels___not___null?: InputMaybe<Scalars['Boolean']>;
  levels___not___starts_with?: InputMaybe<Scalars['String']>;
  levels___null?: InputMaybe<Scalars['Boolean']>;
  levels___starts_with?: InputMaybe<Scalars['String']>;
  n_questions___eq?: InputMaybe<Scalars['Int']>;
  n_questions___gt?: InputMaybe<Scalars['Int']>;
  n_questions___gte?: InputMaybe<Scalars['Int']>;
  n_questions___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  n_questions___lt?: InputMaybe<Scalars['Int']>;
  n_questions___lte?: InputMaybe<Scalars['Int']>;
  n_questions___ne?: InputMaybe<Scalars['Int']>;
  n_questions___not___in?: InputMaybe<Array<Scalars['Int']>>;
  n_questions___not___null?: InputMaybe<Scalars['Boolean']>;
  n_questions___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire____contains?: InputMaybe<Scalars['String']>;
  questionnaire____ends_with?: InputMaybe<Scalars['String']>;
  questionnaire____eq?: InputMaybe<Scalars['String']>;
  questionnaire____gt?: InputMaybe<Scalars['String']>;
  questionnaire____gte?: InputMaybe<Scalars['String']>;
  questionnaire____in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire____lt?: InputMaybe<Scalars['String']>;
  questionnaire____lte?: InputMaybe<Scalars['String']>;
  questionnaire____ne?: InputMaybe<Scalars['String']>;
  questionnaire____not___contains?: InputMaybe<Scalars['String']>;
  questionnaire____not___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire____not___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire____not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire____not___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire____null?: InputMaybe<Scalars['Boolean']>;
  questionnaire____starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_code___contains?: InputMaybe<Scalars['String']>;
  questionnaire_code___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire_code___eq?: InputMaybe<Scalars['String']>;
  questionnaire_code___gt?: InputMaybe<Scalars['String']>;
  questionnaire_code___gte?: InputMaybe<Scalars['String']>;
  questionnaire_code___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire_code___lt?: InputMaybe<Scalars['String']>;
  questionnaire_code___lte?: InputMaybe<Scalars['String']>;
  questionnaire_code___ne?: InputMaybe<Scalars['String']>;
  questionnaire_code___not___contains?: InputMaybe<Scalars['String']>;
  questionnaire_code___not___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire_code___not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_code___not___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_code___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_code___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_section___contains?: InputMaybe<Scalars['String']>;
  questionnaire_section___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire_section___eq?: InputMaybe<Scalars['String']>;
  questionnaire_section___gt?: InputMaybe<Scalars['String']>;
  questionnaire_section___gte?: InputMaybe<Scalars['String']>;
  questionnaire_section___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire_section___lt?: InputMaybe<Scalars['String']>;
  questionnaire_section___lte?: InputMaybe<Scalars['String']>;
  questionnaire_section___ne?: InputMaybe<Scalars['String']>;
  questionnaire_section___not___contains?: InputMaybe<Scalars['String']>;
  questionnaire_section___not___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire_section___not___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire_section___not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_section___not___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_section___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_section___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code___contains?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code___eq?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code___gt?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code___gte?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire_secton_code___lt?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code___lte?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code___ne?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code___not___contains?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code___not___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire_secton_code___not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_secton_code___not___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_secton_code___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_secton_code___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_user_types___contains?: InputMaybe<Scalars['String']>;
  questionnaire_user_types___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire_user_types___eq?: InputMaybe<Scalars['String']>;
  questionnaire_user_types___gt?: InputMaybe<Scalars['String']>;
  questionnaire_user_types___gte?: InputMaybe<Scalars['String']>;
  questionnaire_user_types___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire_user_types___lt?: InputMaybe<Scalars['String']>;
  questionnaire_user_types___lte?: InputMaybe<Scalars['String']>;
  questionnaire_user_types___ne?: InputMaybe<Scalars['String']>;
  questionnaire_user_types___not___contains?: InputMaybe<Scalars['String']>;
  questionnaire_user_types___not___ends_with?: InputMaybe<Scalars['String']>;
  questionnaire_user_types___not___in?: InputMaybe<Array<Scalars['String']>>;
  questionnaire_user_types___not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_user_types___not___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_user_types___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_user_types___starts_with?: InputMaybe<Scalars['String']>;
  section_user_types___contains?: InputMaybe<Scalars['String']>;
  section_user_types___ends_with?: InputMaybe<Scalars['String']>;
  section_user_types___eq?: InputMaybe<Scalars['String']>;
  section_user_types___gt?: InputMaybe<Scalars['String']>;
  section_user_types___gte?: InputMaybe<Scalars['String']>;
  section_user_types___in?: InputMaybe<Array<Scalars['String']>>;
  section_user_types___lt?: InputMaybe<Scalars['String']>;
  section_user_types___lte?: InputMaybe<Scalars['String']>;
  section_user_types___ne?: InputMaybe<Scalars['String']>;
  section_user_types___not___contains?: InputMaybe<Scalars['String']>;
  section_user_types___not___ends_with?: InputMaybe<Scalars['String']>;
  section_user_types___not___in?: InputMaybe<Array<Scalars['String']>>;
  section_user_types___not___null?: InputMaybe<Scalars['Boolean']>;
  section_user_types___not___starts_with?: InputMaybe<Scalars['String']>;
  section_user_types___null?: InputMaybe<Scalars['Boolean']>;
  section_user_types___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Questionnaire_section. */
export type Questionnaire_SectionPage = {
  __typename?: 'Questionnaire_sectionPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Questionnaire_section. */
  items: Array<Questionnaire_Section>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Questionnaire_section present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Questionnaire_sectionPage. */
export type Questionnaire_SectionPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Questionnaire_SectionFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Questionnaire_SectionCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Questionnaire_SectionSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Questionnaire_section */
export type Questionnaire_SectionRoleObjects = {
  /** An array of objects of class Questionnaire_section to be created. */
  create?: InputMaybe<Array<InputMaybe<Questionnaire_SectionCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Questionnaire_section to be updated. */
  update?: InputMaybe<Array<InputMaybe<Questionnaire_SectionUpdate>>>;
};

/** A data structure for the associated object of class Questionnaire_section */
export type Questionnaire_SectionRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Questionnaire_SectionSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  langs___ASC = 'langs___ASC',
  langs___DESC = 'langs___DESC',
  levels___ASC = 'levels___ASC',
  levels___DESC = 'levels___DESC',
  n_questions___ASC = 'n_questions___ASC',
  n_questions___DESC = 'n_questions___DESC',
  questionnaire____ASC = 'questionnaire____ASC',
  questionnaire____DESC = 'questionnaire____DESC',
  questionnaire_code___ASC = 'questionnaire_code___ASC',
  questionnaire_code___DESC = 'questionnaire_code___DESC',
  questionnaire_section___ASC = 'questionnaire_section___ASC',
  questionnaire_section___DESC = 'questionnaire_section___DESC',
  questionnaire_secton_code___ASC = 'questionnaire_secton_code___ASC',
  questionnaire_secton_code___DESC = 'questionnaire_secton_code___DESC',
  questionnaire_user_types___ASC = 'questionnaire_user_types___ASC',
  questionnaire_user_types___DESC = 'questionnaire_user_types___DESC',
  section_user_types___ASC = 'section_user_types___ASC',
  section_user_types___DESC = 'section_user_types___DESC'
}

/** A data structure for a single object of class Questionnaire_section. */
export type Questionnaire_SectionUpdate = {
  _id: Scalars['ID'];
  /** The attribute code of Questionnaire_section. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Level_TypeRoleRefs>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  question_?: InputMaybe<QuestionRoleRefs>;
  /** The Composition role questionnaire_section_ ◊--> (0,N)questionnaire_section_lang_. */
  questionnaire_section_lang_?: InputMaybe<Questionnaire_Section_LangRoleObjects>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)user_type_. */
  user_type_?: InputMaybe<User_TypeRoleRefs>;
};

/** A data structure for objects of class Questionnaire_section. */
export type Questionnaire_SectionUpdateBulk = {
  /** The attribute code of Questionnaire_section. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)level_type_. */
  level_type_?: InputMaybe<Level_TypeRoleRefs>;
  /** The Association role question_(0,N) <--> (0,1)questionnaire_section_. */
  question_?: InputMaybe<QuestionRoleRefs>;
  /** The Composition role questionnaire_section_ ◊--> (0,N)questionnaire_section_lang_. */
  questionnaire_section_lang_?: InputMaybe<Questionnaire_Section_LangRoleObjects>;
  /** The Association role questionnaire_section_(0,N) --> (0,N)user_type_. */
  user_type_?: InputMaybe<User_TypeRoleRefs>;
};

/** Represents an object of class Questionnaire_section_lang, as defined in the model. */
export type Questionnaire_Section_Lang = {
  __typename?: 'Questionnaire_section_lang';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Questionnaire_section_lang. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute lang of Questionnaire_section_lang. It is of type String. Path: lang_ name. Cannot be null. */
  lang?: Maybe<Scalars['String']>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
  /** The attribute questionnaire_section of Questionnaire_section_lang. It is of type Text. Cannot be null. */
  questionnaire_section?: Maybe<Scalars['Text']>;
  /** The associated object for the role questionnaire_section_. */
  questionnaire_section_?: Maybe<Questionnaire_Section>;
};


/** Represents an object of class Questionnaire_section_lang, as defined in the model. */
export type Questionnaire_Section_LangLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};

/** A data structure for objects of Questionnaire_section_lang. */
export type Questionnaire_Section_LangCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_section_lang_(0,N) --> (1)lang_. */
  lang_: Scalars['ID'];
  /** The attribute questionnaire_section of Questionnaire_section_lang. It is of type Text!. Cannot be null. */
  questionnaire_section: Scalars['Text'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Questionnaire_section_lang.
 */
export type Questionnaire_Section_LangCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  lang?: InputMaybe<Scalars['String']>;
  questionnaire_section?: InputMaybe<Scalars['Text']>;
};

/** A data structure for a draft object of class Questionnaire_section_lang. */
export type Questionnaire_Section_LangDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_section_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute questionnaire_section of Questionnaire_section_lang. It is of type Text. Cannot be null. */
  questionnaire_section?: InputMaybe<Scalars['Text']>;
};

/** A data structure for draft create objects of class Questionnaire_section_lang. */
export type Questionnaire_Section_LangDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_section_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute questionnaire_section of Questionnaire_section_lang. It is of type Text. Cannot be null. */
  questionnaire_section?: InputMaybe<Scalars['Text']>;
};

/** A data structure for the draft part objects of class Questionnaire_section_lang */
export type Questionnaire_Section_LangDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Questionnaire_section_lang to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Questionnaire_Section_LangDraft>>>;
};

/** A data structure for a draft update object of class Questionnaire_section_lang. */
export type Questionnaire_Section_LangDraftUpdate = {
  _id: Scalars['ID'];
  /** The Association role questionnaire_section_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute questionnaire_section of Questionnaire_section_lang. It is of type Text. Cannot be null. */
  questionnaire_section?: InputMaybe<Scalars['Text']>;
};

/** A data structure for a draft update objects of class Questionnaire_section_lang. */
export type Questionnaire_Section_LangDraftUpdateBulk = {
  /** The Association role questionnaire_section_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute questionnaire_section of Questionnaire_section_lang. It is of type Text. Cannot be null. */
  questionnaire_section?: InputMaybe<Scalars['Text']>;
};

/** A data structure for the draft part objects of class Questionnaire_section_lang */
export type Questionnaire_Section_LangDraftUpdateRoleObjects = {
  /** An array of objects of class Questionnaire_section_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Questionnaire_Section_LangDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Questionnaire_section_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Questionnaire_Section_LangDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Questionnaire_section_lang.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Questionnaire_Section_LangFilter = {
  AND?: InputMaybe<Array<Questionnaire_Section_LangFilter>>;
  NOT?: InputMaybe<Questionnaire_Section_LangFilter>;
  OR?: InputMaybe<Array<Questionnaire_Section_LangFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  lang___contains?: InputMaybe<Scalars['String']>;
  lang___ends_with?: InputMaybe<Scalars['String']>;
  lang___eq?: InputMaybe<Scalars['String']>;
  lang___gt?: InputMaybe<Scalars['String']>;
  lang___gte?: InputMaybe<Scalars['String']>;
  lang___in?: InputMaybe<Array<Scalars['String']>>;
  lang___lt?: InputMaybe<Scalars['String']>;
  lang___lte?: InputMaybe<Scalars['String']>;
  lang___ne?: InputMaybe<Scalars['String']>;
  lang___not___contains?: InputMaybe<Scalars['String']>;
  lang___not___ends_with?: InputMaybe<Scalars['String']>;
  lang___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang___not___null?: InputMaybe<Scalars['Boolean']>;
  lang___not___starts_with?: InputMaybe<Scalars['String']>;
  lang___null?: InputMaybe<Scalars['Boolean']>;
  lang___starts_with?: InputMaybe<Scalars['String']>;
  questionnaire_section___contains?: InputMaybe<Scalars['Text']>;
  questionnaire_section___ends_with?: InputMaybe<Scalars['Text']>;
  questionnaire_section___eq?: InputMaybe<Scalars['Text']>;
  questionnaire_section___gt?: InputMaybe<Scalars['Text']>;
  questionnaire_section___gte?: InputMaybe<Scalars['Text']>;
  questionnaire_section___in?: InputMaybe<Array<Scalars['Text']>>;
  questionnaire_section___lt?: InputMaybe<Scalars['Text']>;
  questionnaire_section___lte?: InputMaybe<Scalars['Text']>;
  questionnaire_section___ne?: InputMaybe<Scalars['Text']>;
  questionnaire_section___not___contains?: InputMaybe<Scalars['Text']>;
  questionnaire_section___not___ends_with?: InputMaybe<Scalars['Text']>;
  questionnaire_section___not___in?: InputMaybe<Array<Scalars['Text']>>;
  questionnaire_section___not___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_section___not___starts_with?: InputMaybe<Scalars['Text']>;
  questionnaire_section___null?: InputMaybe<Scalars['Boolean']>;
  questionnaire_section___starts_with?: InputMaybe<Scalars['Text']>;
};

/** Represents a collection of objects of class Questionnaire_section_lang. */
export type Questionnaire_Section_LangPage = {
  __typename?: 'Questionnaire_section_langPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Questionnaire_section_lang. */
  items: Array<Questionnaire_Section_Lang>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Questionnaire_section_lang present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Questionnaire_section_langPage. */
export type Questionnaire_Section_LangPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Questionnaire_Section_LangFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Questionnaire_Section_LangCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Questionnaire_Section_LangSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Questionnaire_section_lang */
export type Questionnaire_Section_LangRoleObjects = {
  /** An array of objects of class Questionnaire_section_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Questionnaire_Section_LangCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Questionnaire_section_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Questionnaire_Section_LangUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Questionnaire_Section_LangSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  lang___ASC = 'lang___ASC',
  lang___DESC = 'lang___DESC',
  questionnaire_section___ASC = 'questionnaire_section___ASC',
  questionnaire_section___DESC = 'questionnaire_section___DESC'
}

/** A data structure for a single object of class Questionnaire_section_lang. */
export type Questionnaire_Section_LangUpdate = {
  _id: Scalars['ID'];
  /** The Association role questionnaire_section_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute questionnaire_section of Questionnaire_section_lang. It is of type Text. Cannot be null. */
  questionnaire_section?: InputMaybe<Scalars['Text']>;
};

/** A data structure for objects of class Questionnaire_section_lang. */
export type Questionnaire_Section_LangUpdateBulk = {
  /** The Association role questionnaire_section_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute questionnaire_section of Questionnaire_section_lang. It is of type Text. Cannot be null. */
  questionnaire_section?: InputMaybe<Scalars['Text']>;
};

/** Represents an object of class Response, as defined in the model. */
export type Response = {
  __typename?: 'Response';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Response. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute alert_mandatory of Response. It is of type Boolean. Expression: 'mandatory && response_=concat("")'.  */
  alert_mandatory?: Maybe<Scalars['Boolean']>;
  /** The attribute decimal of Response. It is of type Boolean. Path: question_.response_type_ decimal.  */
  decimal?: Maybe<Scalars['Boolean']>;
  /** The attribute int_range of Response. It is of type Boolean. Path: question_.response_type_ int_range.  */
  int_range?: Maybe<Scalars['Boolean']>;
  /** The attribute integer of Response. It is of type Boolean. Path: question_.response_type_ integer.  */
  integer?: Maybe<Scalars['Boolean']>;
  /** The attribute intro_only of Response. It is of type Boolean. Path: question_.response_type_ intro_only.  */
  intro_only?: Maybe<Scalars['Boolean']>;
  /** The attribute mandatory of Response. It is of type Boolean. Path: question_ mandatory. Cannot be null. */
  mandatory?: Maybe<Scalars['Boolean']>;
  /** The attribute mult_options of Response. It is of type Boolean. Path: question_.response_type_ multiple_choice.  */
  mult_options?: Maybe<Scalars['Boolean']>;
  /** The attribute mult_response of Response. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)possible_multiple_response response_. Cannot be null. */
  mult_response?: Maybe<Scalars['String']>;
  /** The attribute no_filter of Response. It is of type Boolean.  */
  no_filter?: Maybe<Scalars['Boolean']>;
  /** The attribute order of Response. It is of type Int. Path: question_ order.  */
  order?: Maybe<Scalars['Int']>;
  /** The associated objects for the role possible_multiple_response. */
  possible_multiple_response?: Maybe<Possible_ResponsePage>;
  /** The associable objects for the role possible_multiple_response. */
  possible_multiple_response___associables?: Maybe<Possible_ResponsePage>;
  /** The associated object for the role possible_single_response. */
  possible_single_response?: Maybe<Possible_Response>;
  /** The associable objects for the role possible_single_response. */
  possible_single_response___associables?: Maybe<Possible_ResponsePage>;
  /** The attribute question of Response. It is of type String. Path: question_ question_. Cannot be null. */
  question?: Maybe<Scalars['String']>;
  /** The associated object for the role question_. */
  question_?: Maybe<Question>;
  /** The associable objects for the role question_. */
  question____associables?: Maybe<QuestionPage>;
  /** The associated object for the role questionnaire_completion_. */
  questionnaire_completion_?: Maybe<Questionnaire_Completion>;
  /**
   * The attribute response_ of Response. It is of type String. Expression: 'concat(
   * 	ifNull(concat(response_int),""),
   * 	ifNull(concat(response_dec),""),
   * 	ifNull(response_str,""),
   * 	ifNull(mult_response,""),
   * 	ifNull(single_response_,"")
   * )'.
   */
  response_?: Maybe<Scalars['String']>;
  /** The attribute response_dec of Response. It is of type Real @realFormat.  */
  response_dec?: Maybe<Scalars['Real']>;
  /** The attribute response_int of Response. It is of type Int.  */
  response_int?: Maybe<Scalars['Int']>;
  /** The attribute response_order of Response. It is of type Int. Path: MIN - Minpossible_multiple_response order.  */
  response_order?: Maybe<Scalars['Int']>;
  /** The attribute response_score of Response. It is of type Real @realFormat. Path: SUM - Sumpossible_multiple_response score.  */
  response_score?: Maybe<Scalars['Real']>;
  /** The attribute response_str of Response. It is of type Text.  */
  response_str?: Maybe<Scalars['Text']>;
  /** The attribute single_options of Response. It is of type Boolean. Path: question_.response_type_ single_choice.  */
  single_options?: Maybe<Scalars['Boolean']>;
  /** The attribute string of Response. It is of type Boolean. Path: question_.response_type_ string.  */
  string?: Maybe<Scalars['Boolean']>;
};


/** Represents an object of class Response, as defined in the model. */
export type ResponsePossible_Multiple_ResponseArgs = {
  options?: InputMaybe<Possible_ResponsePageOptions>;
};


/** Represents an object of class Response, as defined in the model. */
export type ResponsePossible_Multiple_Response___AssociablesArgs = {
  options?: InputMaybe<Possible_ResponsePageOptions>;
};


/** Represents an object of class Response, as defined in the model. */
export type ResponsePossible_Single_Response___AssociablesArgs = {
  options?: InputMaybe<Possible_ResponsePageOptions>;
};


/** Represents an object of class Response, as defined in the model. */
export type ResponseQuestion____AssociablesArgs = {
  options?: InputMaybe<QuestionPageOptions>;
};


/** Represents an object of class Response, as defined in the model. */
export type ResponseResponse_DecArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Response, as defined in the model. */
export type ResponseResponse_ScoreArgs = {
  format?: InputMaybe<Scalars['String']>;
};

/** A data structure for objects of Response. */
export type ResponseCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute no_filter of Response. It is of type Boolean.  */
  no_filter?: InputMaybe<Scalars['Boolean']>;
  /** The Association role multiple_response(0,N) --> (0,N)possible_multiple_response. */
  possible_multiple_response?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role single_response(0,N) --> (0,1)possible_single_response. */
  possible_single_response?: InputMaybe<Scalars['ID']>;
  /** The Association role response_(0,N) --> (1)question_. */
  question_: Scalars['ID'];
  /** The attribute response_dec of Response. It is of type Real.  */
  response_dec?: InputMaybe<Scalars['Real']>;
  /** The attribute response_int of Response. It is of type Int.  */
  response_int?: InputMaybe<Scalars['Int']>;
  /** The attribute response_str of Response. It is of type Text.  */
  response_str?: InputMaybe<Scalars['Text']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Response.
 */
export type ResponseCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  alert_mandatory?: InputMaybe<Scalars['Boolean']>;
  decimal?: InputMaybe<Scalars['Boolean']>;
  int_range?: InputMaybe<Scalars['Boolean']>;
  integer?: InputMaybe<Scalars['Boolean']>;
  intro_only?: InputMaybe<Scalars['Boolean']>;
  mandatory?: InputMaybe<Scalars['Boolean']>;
  mult_options?: InputMaybe<Scalars['Boolean']>;
  mult_response?: InputMaybe<Scalars['String']>;
  no_filter?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  response_?: InputMaybe<Scalars['String']>;
  response_dec?: InputMaybe<Scalars['Real']>;
  response_int?: InputMaybe<Scalars['Int']>;
  response_order?: InputMaybe<Scalars['Int']>;
  response_score?: InputMaybe<Scalars['Real']>;
  response_str?: InputMaybe<Scalars['Text']>;
  single_options?: InputMaybe<Scalars['Boolean']>;
  string?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for a draft object of class Response. */
export type ResponseDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute no_filter of Response. It is of type Boolean.  */
  no_filter?: InputMaybe<Scalars['Boolean']>;
  /** The Association role multiple_response(0,N) --> (0,N)possible_multiple_response. */
  possible_multiple_response?: InputMaybe<Possible_ResponseDraftRoleRefs>;
  /** The Association role single_response(0,N) --> (0,1)possible_single_response. */
  possible_single_response?: InputMaybe<Scalars['ID']>;
  /** The Association role response_(0,N) --> (1)question_. */
  question_?: InputMaybe<Scalars['ID']>;
  /** The attribute response_dec of Response. It is of type Real.  */
  response_dec?: InputMaybe<Scalars['Real']>;
  /** The attribute response_int of Response. It is of type Int.  */
  response_int?: InputMaybe<Scalars['Int']>;
  /** The attribute response_str of Response. It is of type Text.  */
  response_str?: InputMaybe<Scalars['Text']>;
};

/** A data structure for draft create objects of class Response. */
export type ResponseDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute no_filter of Response. It is of type Boolean.  */
  no_filter?: InputMaybe<Scalars['Boolean']>;
  /** The Association role multiple_response(0,N) --> (0,N)possible_multiple_response. */
  possible_multiple_response?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role single_response(0,N) --> (0,1)possible_single_response. */
  possible_single_response?: InputMaybe<Scalars['ID']>;
  /** The Association role response_(0,N) --> (1)question_. */
  question_?: InputMaybe<Scalars['ID']>;
  /** The attribute response_dec of Response. It is of type Real.  */
  response_dec?: InputMaybe<Scalars['Real']>;
  /** The attribute response_int of Response. It is of type Int.  */
  response_int?: InputMaybe<Scalars['Int']>;
  /** The attribute response_str of Response. It is of type Text.  */
  response_str?: InputMaybe<Scalars['Text']>;
};

/** A data structure for the draft part objects of class Response */
export type ResponseDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Response to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<ResponseDraft>>>;
};

/** A data structure for a draft update object of class Response. */
export type ResponseDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute no_filter of Response. It is of type Boolean.  */
  no_filter?: InputMaybe<Scalars['Boolean']>;
  /** The Association role multiple_response(0,N) --> (0,N)possible_multiple_response. */
  possible_multiple_response?: InputMaybe<Possible_ResponseRoleRefs>;
  /** The Association role single_response(0,N) --> (0,1)possible_single_response. */
  possible_single_response?: InputMaybe<Possible_ResponseRoleRef>;
  /** The Association role response_(0,N) --> (1)question_. */
  question_?: InputMaybe<QuestionRoleRef>;
  /** The attribute response_dec of Response. It is of type Real.  */
  response_dec?: InputMaybe<Scalars['Real']>;
  /** The attribute response_int of Response. It is of type Int.  */
  response_int?: InputMaybe<Scalars['Int']>;
  /** The attribute response_str of Response. It is of type Text.  */
  response_str?: InputMaybe<Scalars['Text']>;
};

/** A data structure for a draft update objects of class Response. */
export type ResponseDraftUpdateBulk = {
  /** The attribute no_filter of Response. It is of type Boolean.  */
  no_filter?: InputMaybe<Scalars['Boolean']>;
  /** The Association role multiple_response(0,N) --> (0,N)possible_multiple_response. */
  possible_multiple_response?: InputMaybe<Possible_ResponseRoleRefs>;
  /** The Association role single_response(0,N) --> (0,1)possible_single_response. */
  possible_single_response?: InputMaybe<Possible_ResponseRoleRef>;
  /** The Association role response_(0,N) --> (1)question_. */
  question_?: InputMaybe<QuestionRoleRef>;
  /** The attribute response_dec of Response. It is of type Real.  */
  response_dec?: InputMaybe<Scalars['Real']>;
  /** The attribute response_int of Response. It is of type Int.  */
  response_int?: InputMaybe<Scalars['Int']>;
  /** The attribute response_str of Response. It is of type Text.  */
  response_str?: InputMaybe<Scalars['Text']>;
};

/** A data structure for the draft part objects of class Response */
export type ResponseDraftUpdateRoleObjects = {
  /** An array of objects of class Response to be created. */
  create?: InputMaybe<Array<InputMaybe<ResponseDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Response to be updated. */
  update?: InputMaybe<Array<InputMaybe<ResponseDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Response.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type ResponseFilter = {
  AND?: InputMaybe<Array<ResponseFilter>>;
  NOT?: InputMaybe<ResponseFilter>;
  OR?: InputMaybe<Array<ResponseFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  alert_mandatory___eq?: InputMaybe<Scalars['Boolean']>;
  alert_mandatory___ne?: InputMaybe<Scalars['Boolean']>;
  alert_mandatory___not___null?: InputMaybe<Scalars['Boolean']>;
  alert_mandatory___null?: InputMaybe<Scalars['Boolean']>;
  decimal___eq?: InputMaybe<Scalars['Boolean']>;
  decimal___ne?: InputMaybe<Scalars['Boolean']>;
  decimal___not___null?: InputMaybe<Scalars['Boolean']>;
  decimal___null?: InputMaybe<Scalars['Boolean']>;
  int_range___eq?: InputMaybe<Scalars['Boolean']>;
  int_range___ne?: InputMaybe<Scalars['Boolean']>;
  int_range___not___null?: InputMaybe<Scalars['Boolean']>;
  int_range___null?: InputMaybe<Scalars['Boolean']>;
  integer___eq?: InputMaybe<Scalars['Boolean']>;
  integer___ne?: InputMaybe<Scalars['Boolean']>;
  integer___not___null?: InputMaybe<Scalars['Boolean']>;
  integer___null?: InputMaybe<Scalars['Boolean']>;
  intro_only___eq?: InputMaybe<Scalars['Boolean']>;
  intro_only___ne?: InputMaybe<Scalars['Boolean']>;
  intro_only___not___null?: InputMaybe<Scalars['Boolean']>;
  intro_only___null?: InputMaybe<Scalars['Boolean']>;
  mandatory___eq?: InputMaybe<Scalars['Boolean']>;
  mandatory___ne?: InputMaybe<Scalars['Boolean']>;
  mandatory___not___null?: InputMaybe<Scalars['Boolean']>;
  mandatory___null?: InputMaybe<Scalars['Boolean']>;
  mult_options___eq?: InputMaybe<Scalars['Boolean']>;
  mult_options___ne?: InputMaybe<Scalars['Boolean']>;
  mult_options___not___null?: InputMaybe<Scalars['Boolean']>;
  mult_options___null?: InputMaybe<Scalars['Boolean']>;
  mult_response___contains?: InputMaybe<Scalars['String']>;
  mult_response___ends_with?: InputMaybe<Scalars['String']>;
  mult_response___eq?: InputMaybe<Scalars['String']>;
  mult_response___gt?: InputMaybe<Scalars['String']>;
  mult_response___gte?: InputMaybe<Scalars['String']>;
  mult_response___in?: InputMaybe<Array<Scalars['String']>>;
  mult_response___lt?: InputMaybe<Scalars['String']>;
  mult_response___lte?: InputMaybe<Scalars['String']>;
  mult_response___ne?: InputMaybe<Scalars['String']>;
  mult_response___not___contains?: InputMaybe<Scalars['String']>;
  mult_response___not___ends_with?: InputMaybe<Scalars['String']>;
  mult_response___not___in?: InputMaybe<Array<Scalars['String']>>;
  mult_response___not___null?: InputMaybe<Scalars['Boolean']>;
  mult_response___not___starts_with?: InputMaybe<Scalars['String']>;
  mult_response___null?: InputMaybe<Scalars['Boolean']>;
  mult_response___starts_with?: InputMaybe<Scalars['String']>;
  no_filter___eq?: InputMaybe<Scalars['Boolean']>;
  no_filter___ne?: InputMaybe<Scalars['Boolean']>;
  no_filter___not___null?: InputMaybe<Scalars['Boolean']>;
  no_filter___null?: InputMaybe<Scalars['Boolean']>;
  order___eq?: InputMaybe<Scalars['Int']>;
  order___gt?: InputMaybe<Scalars['Int']>;
  order___gte?: InputMaybe<Scalars['Int']>;
  order___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  order___lt?: InputMaybe<Scalars['Int']>;
  order___lte?: InputMaybe<Scalars['Int']>;
  order___ne?: InputMaybe<Scalars['Int']>;
  order___not___in?: InputMaybe<Array<Scalars['Int']>>;
  order___not___null?: InputMaybe<Scalars['Boolean']>;
  order___null?: InputMaybe<Scalars['Boolean']>;
  question___contains?: InputMaybe<Scalars['String']>;
  question___ends_with?: InputMaybe<Scalars['String']>;
  question___eq?: InputMaybe<Scalars['String']>;
  question___gt?: InputMaybe<Scalars['String']>;
  question___gte?: InputMaybe<Scalars['String']>;
  question___in?: InputMaybe<Array<Scalars['String']>>;
  question___lt?: InputMaybe<Scalars['String']>;
  question___lte?: InputMaybe<Scalars['String']>;
  question___ne?: InputMaybe<Scalars['String']>;
  question___not___contains?: InputMaybe<Scalars['String']>;
  question___not___ends_with?: InputMaybe<Scalars['String']>;
  question___not___in?: InputMaybe<Array<Scalars['String']>>;
  question___not___null?: InputMaybe<Scalars['Boolean']>;
  question___not___starts_with?: InputMaybe<Scalars['String']>;
  question___null?: InputMaybe<Scalars['Boolean']>;
  question___starts_with?: InputMaybe<Scalars['String']>;
  response____contains?: InputMaybe<Scalars['String']>;
  response____ends_with?: InputMaybe<Scalars['String']>;
  response____eq?: InputMaybe<Scalars['String']>;
  response____gt?: InputMaybe<Scalars['String']>;
  response____gte?: InputMaybe<Scalars['String']>;
  response____in?: InputMaybe<Array<Scalars['String']>>;
  response____lt?: InputMaybe<Scalars['String']>;
  response____lte?: InputMaybe<Scalars['String']>;
  response____ne?: InputMaybe<Scalars['String']>;
  response____not___contains?: InputMaybe<Scalars['String']>;
  response____not___ends_with?: InputMaybe<Scalars['String']>;
  response____not___in?: InputMaybe<Array<Scalars['String']>>;
  response____not___null?: InputMaybe<Scalars['Boolean']>;
  response____not___starts_with?: InputMaybe<Scalars['String']>;
  response____null?: InputMaybe<Scalars['Boolean']>;
  response____starts_with?: InputMaybe<Scalars['String']>;
  response_dec___eq?: InputMaybe<Scalars['Real']>;
  response_dec___gt?: InputMaybe<Scalars['Real']>;
  response_dec___gte?: InputMaybe<Scalars['Real']>;
  response_dec___in?: InputMaybe<Array<InputMaybe<Scalars['Real']>>>;
  response_dec___lt?: InputMaybe<Scalars['Real']>;
  response_dec___lte?: InputMaybe<Scalars['Real']>;
  response_dec___ne?: InputMaybe<Scalars['Real']>;
  response_dec___not___in?: InputMaybe<Array<Scalars['Real']>>;
  response_dec___not___null?: InputMaybe<Scalars['Boolean']>;
  response_dec___null?: InputMaybe<Scalars['Boolean']>;
  response_int___eq?: InputMaybe<Scalars['Int']>;
  response_int___gt?: InputMaybe<Scalars['Int']>;
  response_int___gte?: InputMaybe<Scalars['Int']>;
  response_int___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  response_int___lt?: InputMaybe<Scalars['Int']>;
  response_int___lte?: InputMaybe<Scalars['Int']>;
  response_int___ne?: InputMaybe<Scalars['Int']>;
  response_int___not___in?: InputMaybe<Array<Scalars['Int']>>;
  response_int___not___null?: InputMaybe<Scalars['Boolean']>;
  response_int___null?: InputMaybe<Scalars['Boolean']>;
  response_order___eq?: InputMaybe<Scalars['Int']>;
  response_order___gt?: InputMaybe<Scalars['Int']>;
  response_order___gte?: InputMaybe<Scalars['Int']>;
  response_order___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  response_order___lt?: InputMaybe<Scalars['Int']>;
  response_order___lte?: InputMaybe<Scalars['Int']>;
  response_order___ne?: InputMaybe<Scalars['Int']>;
  response_order___not___in?: InputMaybe<Array<Scalars['Int']>>;
  response_order___not___null?: InputMaybe<Scalars['Boolean']>;
  response_order___null?: InputMaybe<Scalars['Boolean']>;
  response_score___eq?: InputMaybe<Scalars['Real']>;
  response_score___gt?: InputMaybe<Scalars['Real']>;
  response_score___gte?: InputMaybe<Scalars['Real']>;
  response_score___in?: InputMaybe<Array<InputMaybe<Scalars['Real']>>>;
  response_score___lt?: InputMaybe<Scalars['Real']>;
  response_score___lte?: InputMaybe<Scalars['Real']>;
  response_score___ne?: InputMaybe<Scalars['Real']>;
  response_score___not___in?: InputMaybe<Array<Scalars['Real']>>;
  response_score___not___null?: InputMaybe<Scalars['Boolean']>;
  response_score___null?: InputMaybe<Scalars['Boolean']>;
  response_str___contains?: InputMaybe<Scalars['Text']>;
  response_str___ends_with?: InputMaybe<Scalars['Text']>;
  response_str___eq?: InputMaybe<Scalars['Text']>;
  response_str___gt?: InputMaybe<Scalars['Text']>;
  response_str___gte?: InputMaybe<Scalars['Text']>;
  response_str___in?: InputMaybe<Array<Scalars['Text']>>;
  response_str___lt?: InputMaybe<Scalars['Text']>;
  response_str___lte?: InputMaybe<Scalars['Text']>;
  response_str___ne?: InputMaybe<Scalars['Text']>;
  response_str___not___contains?: InputMaybe<Scalars['Text']>;
  response_str___not___ends_with?: InputMaybe<Scalars['Text']>;
  response_str___not___in?: InputMaybe<Array<Scalars['Text']>>;
  response_str___not___null?: InputMaybe<Scalars['Boolean']>;
  response_str___not___starts_with?: InputMaybe<Scalars['Text']>;
  response_str___null?: InputMaybe<Scalars['Boolean']>;
  response_str___starts_with?: InputMaybe<Scalars['Text']>;
  single_options___eq?: InputMaybe<Scalars['Boolean']>;
  single_options___ne?: InputMaybe<Scalars['Boolean']>;
  single_options___not___null?: InputMaybe<Scalars['Boolean']>;
  single_options___null?: InputMaybe<Scalars['Boolean']>;
  string___eq?: InputMaybe<Scalars['Boolean']>;
  string___ne?: InputMaybe<Scalars['Boolean']>;
  string___not___null?: InputMaybe<Scalars['Boolean']>;
  string___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Response. */
export type ResponsePage = {
  __typename?: 'ResponsePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Response. */
  items: Array<Response>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Response present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a ResponsePage. */
export type ResponsePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<ResponseFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<ResponseCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<ResponseSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Response */
export type ResponseRoleObjects = {
  /** An array of objects of class Response to be created. */
  create?: InputMaybe<Array<InputMaybe<ResponseCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Response to be updated. */
  update?: InputMaybe<Array<InputMaybe<ResponseUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum ResponseSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  alert_mandatory___ASC = 'alert_mandatory___ASC',
  alert_mandatory___DESC = 'alert_mandatory___DESC',
  decimal___ASC = 'decimal___ASC',
  decimal___DESC = 'decimal___DESC',
  int_range___ASC = 'int_range___ASC',
  int_range___DESC = 'int_range___DESC',
  integer___ASC = 'integer___ASC',
  integer___DESC = 'integer___DESC',
  intro_only___ASC = 'intro_only___ASC',
  intro_only___DESC = 'intro_only___DESC',
  mandatory___ASC = 'mandatory___ASC',
  mandatory___DESC = 'mandatory___DESC',
  mult_options___ASC = 'mult_options___ASC',
  mult_options___DESC = 'mult_options___DESC',
  mult_response___ASC = 'mult_response___ASC',
  mult_response___DESC = 'mult_response___DESC',
  no_filter___ASC = 'no_filter___ASC',
  no_filter___DESC = 'no_filter___DESC',
  order___ASC = 'order___ASC',
  order___DESC = 'order___DESC',
  question___ASC = 'question___ASC',
  question___DESC = 'question___DESC',
  response____ASC = 'response____ASC',
  response____DESC = 'response____DESC',
  response_dec___ASC = 'response_dec___ASC',
  response_dec___DESC = 'response_dec___DESC',
  response_int___ASC = 'response_int___ASC',
  response_int___DESC = 'response_int___DESC',
  response_order___ASC = 'response_order___ASC',
  response_order___DESC = 'response_order___DESC',
  response_score___ASC = 'response_score___ASC',
  response_score___DESC = 'response_score___DESC',
  response_str___ASC = 'response_str___ASC',
  response_str___DESC = 'response_str___DESC',
  single_options___ASC = 'single_options___ASC',
  single_options___DESC = 'single_options___DESC',
  string___ASC = 'string___ASC',
  string___DESC = 'string___DESC'
}

/** A data structure for a single object of class Response. */
export type ResponseUpdate = {
  _id: Scalars['ID'];
  /** The attribute no_filter of Response. It is of type Boolean.  */
  no_filter?: InputMaybe<Scalars['Boolean']>;
  /** The Association role multiple_response(0,N) --> (0,N)possible_multiple_response. */
  possible_multiple_response?: InputMaybe<Possible_ResponseRoleRefs>;
  /** The Association role single_response(0,N) --> (0,1)possible_single_response. */
  possible_single_response?: InputMaybe<Possible_ResponseRoleRef>;
  /** The Association role response_(0,N) --> (1)question_. */
  question_?: InputMaybe<QuestionRoleRef>;
  /** The attribute response_dec of Response. It is of type Real.  */
  response_dec?: InputMaybe<Scalars['Real']>;
  /** The attribute response_int of Response. It is of type Int.  */
  response_int?: InputMaybe<Scalars['Int']>;
  /** The attribute response_str of Response. It is of type Text.  */
  response_str?: InputMaybe<Scalars['Text']>;
};

/** A data structure for objects of class Response. */
export type ResponseUpdateBulk = {
  /** The attribute no_filter of Response. It is of type Boolean.  */
  no_filter?: InputMaybe<Scalars['Boolean']>;
  /** The Association role multiple_response(0,N) --> (0,N)possible_multiple_response. */
  possible_multiple_response?: InputMaybe<Possible_ResponseRoleRefs>;
  /** The Association role single_response(0,N) --> (0,1)possible_single_response. */
  possible_single_response?: InputMaybe<Possible_ResponseRoleRef>;
  /** The Association role response_(0,N) --> (1)question_. */
  question_?: InputMaybe<QuestionRoleRef>;
  /** The attribute response_dec of Response. It is of type Real.  */
  response_dec?: InputMaybe<Scalars['Real']>;
  /** The attribute response_int of Response. It is of type Int.  */
  response_int?: InputMaybe<Scalars['Int']>;
  /** The attribute response_str of Response. It is of type Text.  */
  response_str?: InputMaybe<Scalars['Text']>;
};

/** Represents an object of class Response_type, as defined in the model. */
export type Response_Type = {
  __typename?: 'Response_type';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Response_type. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute decimal of Response_type. It is of type Boolean.  */
  decimal?: Maybe<Scalars['Boolean']>;
  /** The attribute int_range of Response_type. It is of type Boolean.  */
  int_range?: Maybe<Scalars['Boolean']>;
  /** The attribute integer of Response_type. It is of type Boolean.  */
  integer?: Maybe<Scalars['Boolean']>;
  /** The attribute intro_only of Response_type. It is of type Boolean.  */
  intro_only?: Maybe<Scalars['Boolean']>;
  /** The attribute multiple_choice of Response_type. It is of type Boolean.  */
  multiple_choice?: Maybe<Scalars['Boolean']>;
  /** The attribute name of Response_type. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The attribute single_choice of Response_type. It is of type Boolean.  */
  single_choice?: Maybe<Scalars['Boolean']>;
  /** The attribute string of Response_type. It is of type Boolean.  */
  string?: Maybe<Scalars['Boolean']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Response_type.
 */
export type Response_TypeCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  decimal?: InputMaybe<Scalars['Boolean']>;
  int_range?: InputMaybe<Scalars['Boolean']>;
  integer?: InputMaybe<Scalars['Boolean']>;
  intro_only?: InputMaybe<Scalars['Boolean']>;
  multiple_choice?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  single_choice?: InputMaybe<Scalars['Boolean']>;
  string?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft associated object of class Response_type */
export type Response_TypeDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Response_type.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Response_TypeFilter = {
  AND?: InputMaybe<Array<Response_TypeFilter>>;
  NOT?: InputMaybe<Response_TypeFilter>;
  OR?: InputMaybe<Array<Response_TypeFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  decimal___eq?: InputMaybe<Scalars['Boolean']>;
  decimal___ne?: InputMaybe<Scalars['Boolean']>;
  decimal___not___null?: InputMaybe<Scalars['Boolean']>;
  decimal___null?: InputMaybe<Scalars['Boolean']>;
  int_range___eq?: InputMaybe<Scalars['Boolean']>;
  int_range___ne?: InputMaybe<Scalars['Boolean']>;
  int_range___not___null?: InputMaybe<Scalars['Boolean']>;
  int_range___null?: InputMaybe<Scalars['Boolean']>;
  integer___eq?: InputMaybe<Scalars['Boolean']>;
  integer___ne?: InputMaybe<Scalars['Boolean']>;
  integer___not___null?: InputMaybe<Scalars['Boolean']>;
  integer___null?: InputMaybe<Scalars['Boolean']>;
  intro_only___eq?: InputMaybe<Scalars['Boolean']>;
  intro_only___ne?: InputMaybe<Scalars['Boolean']>;
  intro_only___not___null?: InputMaybe<Scalars['Boolean']>;
  intro_only___null?: InputMaybe<Scalars['Boolean']>;
  multiple_choice___eq?: InputMaybe<Scalars['Boolean']>;
  multiple_choice___ne?: InputMaybe<Scalars['Boolean']>;
  multiple_choice___not___null?: InputMaybe<Scalars['Boolean']>;
  multiple_choice___null?: InputMaybe<Scalars['Boolean']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  single_choice___eq?: InputMaybe<Scalars['Boolean']>;
  single_choice___ne?: InputMaybe<Scalars['Boolean']>;
  single_choice___not___null?: InputMaybe<Scalars['Boolean']>;
  single_choice___null?: InputMaybe<Scalars['Boolean']>;
  string___eq?: InputMaybe<Scalars['Boolean']>;
  string___ne?: InputMaybe<Scalars['Boolean']>;
  string___not___null?: InputMaybe<Scalars['Boolean']>;
  string___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Response_type. */
export type Response_TypePage = {
  __typename?: 'Response_typePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Response_type. */
  items: Array<Response_Type>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Response_type present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Response_typePage. */
export type Response_TypePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Response_TypeFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Response_TypeCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Response_TypeSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Response_type */
export type Response_TypeRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Response_TypeSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  decimal___ASC = 'decimal___ASC',
  decimal___DESC = 'decimal___DESC',
  int_range___ASC = 'int_range___ASC',
  int_range___DESC = 'int_range___DESC',
  integer___ASC = 'integer___ASC',
  integer___DESC = 'integer___DESC',
  intro_only___ASC = 'intro_only___ASC',
  intro_only___DESC = 'intro_only___DESC',
  multiple_choice___ASC = 'multiple_choice___ASC',
  multiple_choice___DESC = 'multiple_choice___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  single_choice___ASC = 'single_choice___ASC',
  single_choice___DESC = 'single_choice___DESC',
  string___ASC = 'string___ASC',
  string___DESC = 'string___DESC'
}

/** Represents an object of form class Responses, as defined in the model. */
export type Responses = {
  __typename?: 'Responses';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Responses. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute alert_mandatory of Responses. It is of type Boolean. Expression: 'mandatory && response_=concat("")'.  */
  alert_mandatory?: Maybe<Scalars['Boolean']>;
  /** The attribute completed of Responses. It is of type Boolean.  */
  completed?: Maybe<Scalars['Boolean']>;
  /** The attribute decimal of Responses. It is of type Boolean. Path: question_.response_type_ decimal.  */
  decimal?: Maybe<Scalars['Boolean']>;
  /** The attribute int_range of Responses. It is of type Boolean. Path: question_.response_type_ int_range.  */
  int_range?: Maybe<Scalars['Boolean']>;
  /** The attribute integer of Responses. It is of type Boolean. Path: question_.response_type_ integer.  */
  integer?: Maybe<Scalars['Boolean']>;
  /** The attribute intro_only of Responses. It is of type Boolean. Path: question_.response_type_ intro_only.  */
  intro_only?: Maybe<Scalars['Boolean']>;
  /** The attribute mandatory of Responses. It is of type Boolean. Path: question_ mandatory. Cannot be null. */
  mandatory?: Maybe<Scalars['Boolean']>;
  /** The attribute mult_options of Responses. It is of type Boolean. Path: question_.response_type_ multiple_choice.  */
  mult_options?: Maybe<Scalars['Boolean']>;
  /** The attribute mult_response of Responses. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)possible_multiple_response response_. Cannot be null. */
  mult_response?: Maybe<Scalars['String']>;
  /** The attribute notes of Responses. It is of type Text.  */
  notes?: Maybe<Scalars['Text']>;
  /** The attribute order of Responses. It is of type Int. Path: question_ order.  */
  order?: Maybe<Scalars['Int']>;
  /** The associated objects for the role possible_multiple_response. */
  possible_multiple_response?: Maybe<Possible_ResponsePage>;
  /** The associable objects for the role possible_multiple_response. */
  possible_multiple_response___associables?: Maybe<Possible_ResponsePage>;
  /** The associated object for the role possible_single_response. */
  possible_single_response?: Maybe<Possible_Response>;
  /** The associable objects for the role possible_single_response. */
  possible_single_response___associables?: Maybe<Possible_ResponsePage>;
  /** The associated object for the role question_. */
  question_?: Maybe<Question>;
  /** The associable objects for the role question_. */
  question____associables?: Maybe<QuestionPage>;
  /** The attribute question_id of Responses. It is of type Int. Path: question_ __id. Cannot be null. */
  question_id?: Maybe<Scalars['Int']>;
  /** The attribute questionnaire_section of Responses. It is of type String. Path: question_ questionnaire_section. Cannot be null. */
  questionnaire_section?: Maybe<Scalars['String']>;
  /**
   * The attribute response_ of Responses. It is of type String. Expression: 'concat(
   * 	ifNull(concat(response_int),""),
   * 	ifNull(concat(response_dec),""),
   * 	ifNull(response_str,""),
   * 	ifNull(mult_response,""),
   * 	ifNull(single_response_,"")
   * )'.
   */
  response_?: Maybe<Scalars['String']>;
  /** The attribute response_dec of Responses. It is of type Real @realFormat.  */
  response_dec?: Maybe<Scalars['Real']>;
  /** The attribute response_int of Responses. It is of type Int.  */
  response_int?: Maybe<Scalars['Int']>;
  /** The attribute response_order of Responses. It is of type Int. Path: MIN - Minpossible_multiple_response order.  */
  response_order?: Maybe<Scalars['Int']>;
  /** The attribute response_score of Responses. It is of type Real @realFormat. Path: SUM - Sumpossible_multiple_response score.  */
  response_score?: Maybe<Scalars['Real']>;
  /** The attribute response_str of Responses. It is of type Text.  */
  response_str?: Maybe<Scalars['Text']>;
  /** The attribute section of Responses. It is of type Text.  */
  section?: Maybe<Scalars['Text']>;
  /** The attribute single_options of Responses. It is of type Boolean. Path: question_.response_type_ single_choice.  */
  single_options?: Maybe<Scalars['Boolean']>;
  /** The attribute single_response_ of Responses. It is of type String. Path: possible_single_response response_. Cannot be null. */
  single_response_?: Maybe<Scalars['String']>;
  /** The attribute string of Responses. It is of type Boolean. Path: question_.response_type_ string.  */
  string?: Maybe<Scalars['Boolean']>;
};


/** Represents an object of form class Responses, as defined in the model. */
export type ResponsesPossible_Multiple_ResponseArgs = {
  options?: InputMaybe<Possible_ResponsePageOptions>;
};


/** Represents an object of form class Responses, as defined in the model. */
export type ResponsesPossible_Multiple_Response___AssociablesArgs = {
  options?: InputMaybe<Possible_ResponsePageOptions>;
};


/** Represents an object of form class Responses, as defined in the model. */
export type ResponsesPossible_Single_Response___AssociablesArgs = {
  options?: InputMaybe<Possible_ResponsePageOptions>;
};


/** Represents an object of form class Responses, as defined in the model. */
export type ResponsesQuestion____AssociablesArgs = {
  options?: InputMaybe<QuestionPageOptions>;
};


/** Represents an object of form class Responses, as defined in the model. */
export type ResponsesResponse_DecArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of form class Responses, as defined in the model. */
export type ResponsesResponse_ScoreArgs = {
  format?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft object of form class Responses. */
export type ResponsesDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute completed of Responses. It is of type Boolean.  */
  completed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute notes of Responses. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The Association role __responses_1(0,N) --> (0,N)possible_multiple_response. */
  possible_multiple_response?: InputMaybe<Possible_ResponseDraftRoleRefs>;
  /** The Association role __responses_(0,N) --> (0,1)possible_single_response. */
  possible_single_response?: InputMaybe<Scalars['ID']>;
  /** The Association role __responses_(0,N) --> (1)question_. */
  question_?: InputMaybe<Scalars['ID']>;
  /** The attribute response_dec of Responses. It is of type Real.  */
  response_dec?: InputMaybe<Scalars['Real']>;
  /** The attribute response_int of Responses. It is of type Int.  */
  response_int?: InputMaybe<Scalars['Int']>;
  /** The attribute response_str of Responses. It is of type Text.  */
  response_str?: InputMaybe<Scalars['Text']>;
  /** The attribute section of Responses. It is of type Text.  */
  section?: InputMaybe<Scalars['Text']>;
};

/** A data structure for draft create objects of form class Responses. */
export type ResponsesDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute completed of Responses. It is of type Boolean.  */
  completed?: InputMaybe<Scalars['Boolean']>;
  /** The attribute notes of Responses. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The Association role __responses_1(0,N) --> (0,N)possible_multiple_response. */
  possible_multiple_response?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role __responses_(0,N) --> (0,1)possible_single_response. */
  possible_single_response?: InputMaybe<Scalars['ID']>;
  /** The Association role __responses_(0,N) --> (1)question_. */
  question_?: InputMaybe<Scalars['ID']>;
  /** The attribute response_dec of Responses. It is of type Real.  */
  response_dec?: InputMaybe<Scalars['Real']>;
  /** The attribute response_int of Responses. It is of type Int.  */
  response_int?: InputMaybe<Scalars['Int']>;
  /** The attribute response_str of Responses. It is of type Text.  */
  response_str?: InputMaybe<Scalars['Text']>;
  /** The attribute section of Responses. It is of type Text.  */
  section?: InputMaybe<Scalars['Text']>;
};

/** The result of the form action invocation on class Responses. */
export type ResponsesFormActionHandlerResult = {
  __typename?: 'ResponsesFormActionHandlerResult';
  data?: Maybe<Responses>;
  result: FormActionHandlerResult;
};

/** Represents an object of class Risk_factor, as defined in the model. */
export type Risk_Factor = {
  __typename?: 'Risk_factor';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Risk_factor. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute name of Risk_factor. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Risk_factor.
 */
export type Risk_FactorCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class Risk_factor */
export type Risk_FactorDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Risk_factor.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Risk_FactorFilter = {
  AND?: InputMaybe<Array<Risk_FactorFilter>>;
  NOT?: InputMaybe<Risk_FactorFilter>;
  OR?: InputMaybe<Array<Risk_FactorFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Risk_factor. */
export type Risk_FactorPage = {
  __typename?: 'Risk_factorPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Risk_factor. */
  items: Array<Risk_Factor>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Risk_factor present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Risk_factorPage. */
export type Risk_FactorPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Risk_FactorFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Risk_FactorCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Risk_FactorSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Risk_factor */
export type Risk_FactorRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Risk_FactorSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC'
}

/** Represents an object of class School, as defined in the model. */
export type School = {
  __typename?: 'School';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _createdby of School. It is of type String. Cannot be null. */
  _createdby?: Maybe<Scalars['String']>;
  /** The attribute _createdon of School. It is of type Datetime @datetimeFormat. Cannot be null. */
  _createdon?: Maybe<Scalars['Datetime']>;
  /** The attribute _id of School. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute _lastmodifiedby of School. It is of type String. Cannot be null. */
  _lastmodifiedby?: Maybe<Scalars['String']>;
  /** The attribute _lastmodifiedon of School. It is of type Datetime @datetimeFormat. Cannot be null. */
  _lastmodifiedon?: Maybe<Scalars['Datetime']>;
  /** The attribute address of School. It is of type String. Path: address_ description. Cannot be null. */
  address?: Maybe<Scalars['String']>;
  /** The attribute center of School. It is of type String. Path: center_ name. Cannot be null. */
  center?: Maybe<Scalars['String']>;
  /** The associated object for the role center_. */
  center_?: Maybe<Center>;
  /** The associable objects for the role center_. */
  center____associables?: Maybe<CenterPage>;
  /** The attribute center_id of School. It is of type Int. Path: center_ __id. Cannot be null. */
  center_id?: Maybe<Scalars['Int']>;
  /** The attribute code of School. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The attribute country of School. It is of type String. Path: center_.country_ name. Cannot be null. */
  country?: Maybe<Scalars['String']>;
  /** The attribute description of School. It is of type String. Expression: 'concat(name," (",code,")")'.  */
  description?: Maybe<Scalars['String']>;
  /** The attribute include_data of School. It is of type Boolean.  */
  include_data?: Maybe<Scalars['Boolean']>;
  /** The attribute laboratory of School. It is of type String. Path: laboratory_ name. Cannot be null. */
  laboratory?: Maybe<Scalars['String']>;
  /** The associated object for the role laboratory_. */
  laboratory_?: Maybe<Laboratory>;
  /** The associable objects for the role laboratory_. */
  laboratory____associables?: Maybe<LaboratoryPage>;
  /** The attribute last_year of School. It is of type Year. Path: MAX - Maxschool_class_ academic_year. Cannot be null. */
  last_year?: Maybe<Scalars['Year']>;
  /** The attribute name of School. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The attribute national_code of School. It is of type String. Max length: 255.  */
  national_code?: Maybe<Scalars['String']>;
  /** The attribute neighbourhood_type of School. It is of type String. Path: neighbourhood_type_ name. Cannot be null. */
  neighbourhood_type?: Maybe<Scalars['String']>;
  /** The associated object for the role neighbourhood_type_. */
  neighbourhood_type_?: Maybe<Neighbourhood_Type>;
  /** The associable objects for the role neighbourhood_type_. */
  neighbourhood_type____associables?: Maybe<Neighbourhood_TypePage>;
  /** The attribute rand_sequence of School. It is of type String. Max length: 255.  */
  rand_sequence?: Maybe<Scalars['String']>;
  /** The associated objects for the role school_people_. */
  school_people_?: Maybe<School_PeoplePage>;
  /** The attribute serial_id of School. It is of type Serial. Cannot be null. */
  serial_id?: Maybe<Scalars['Serial']>;
  /** The attribute switch_lolli of School. It is of type Boolean.  */
  switch_lolli?: Maybe<Scalars['Boolean']>;
  /** The attribute test of School. It is of type Boolean. Path: center_ test.  */
  test?: Maybe<Scalars['Boolean']>;
};


/** Represents an object of class School, as defined in the model. */
export type School_CreatedonArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class School, as defined in the model. */
export type School_LastmodifiedonArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class School, as defined in the model. */
export type SchoolCenter____AssociablesArgs = {
  options?: InputMaybe<CenterPageOptions>;
};


/** Represents an object of class School, as defined in the model. */
export type SchoolLaboratory____AssociablesArgs = {
  options?: InputMaybe<LaboratoryPageOptions>;
};


/** Represents an object of class School, as defined in the model. */
export type SchoolNeighbourhood_Type____AssociablesArgs = {
  options?: InputMaybe<Neighbourhood_TypePageOptions>;
};


/** Represents an object of class School, as defined in the model. */
export type SchoolSchool_People_Args = {
  options?: InputMaybe<School_PeoplePageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * School.
 */
export type SchoolCursor = {
  _createdby?: InputMaybe<Scalars['String']>;
  _createdon?: InputMaybe<Scalars['Datetime']>;
  _id?: InputMaybe<Scalars['ID']>;
  _lastmodifiedby?: InputMaybe<Scalars['String']>;
  _lastmodifiedon?: InputMaybe<Scalars['Datetime']>;
  address?: InputMaybe<Scalars['String']>;
  center?: InputMaybe<Scalars['String']>;
  center_id?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  include_data?: InputMaybe<Scalars['Boolean']>;
  laboratory?: InputMaybe<Scalars['String']>;
  last_year?: InputMaybe<Scalars['Year']>;
  name?: InputMaybe<Scalars['String']>;
  national_code?: InputMaybe<Scalars['String']>;
  neighbourhood_type?: InputMaybe<Scalars['String']>;
  rand_sequence?: InputMaybe<Scalars['String']>;
  serial_id?: InputMaybe<Scalars['Serial']>;
  switch_lolli?: InputMaybe<Scalars['Boolean']>;
  test?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft associated object of class School */
export type SchoolDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft associated objects of class School */
export type SchoolDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type School.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type SchoolFilter = {
  AND?: InputMaybe<Array<SchoolFilter>>;
  NOT?: InputMaybe<SchoolFilter>;
  OR?: InputMaybe<Array<SchoolFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _createdby___contains?: InputMaybe<Scalars['String']>;
  _createdby___ends_with?: InputMaybe<Scalars['String']>;
  _createdby___eq?: InputMaybe<Scalars['String']>;
  _createdby___gt?: InputMaybe<Scalars['String']>;
  _createdby___gte?: InputMaybe<Scalars['String']>;
  _createdby___in?: InputMaybe<Array<Scalars['String']>>;
  _createdby___lt?: InputMaybe<Scalars['String']>;
  _createdby___lte?: InputMaybe<Scalars['String']>;
  _createdby___ne?: InputMaybe<Scalars['String']>;
  _createdby___not___contains?: InputMaybe<Scalars['String']>;
  _createdby___not___ends_with?: InputMaybe<Scalars['String']>;
  _createdby___not___in?: InputMaybe<Array<Scalars['String']>>;
  _createdby___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdby___not___starts_with?: InputMaybe<Scalars['String']>;
  _createdby___null?: InputMaybe<Scalars['Boolean']>;
  _createdby___starts_with?: InputMaybe<Scalars['String']>;
  _createdon___eq?: InputMaybe<Scalars['Datetime']>;
  _createdon___gt?: InputMaybe<Scalars['Datetime']>;
  _createdon___gte?: InputMaybe<Scalars['Datetime']>;
  _createdon___in?: InputMaybe<Array<InputMaybe<Scalars['Datetime']>>>;
  _createdon___lt?: InputMaybe<Scalars['Datetime']>;
  _createdon___lte?: InputMaybe<Scalars['Datetime']>;
  _createdon___ne?: InputMaybe<Scalars['Datetime']>;
  _createdon___not___in?: InputMaybe<Array<Scalars['Datetime']>>;
  _createdon___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdon___null?: InputMaybe<Scalars['Boolean']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedby___contains?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___ends_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___eq?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___gt?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___gte?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___in?: InputMaybe<Array<Scalars['String']>>;
  _lastmodifiedby___lt?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___lte?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___ne?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___not___contains?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___not___ends_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___not___in?: InputMaybe<Array<Scalars['String']>>;
  _lastmodifiedby___not___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedby___not___starts_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedby___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedby___starts_with?: InputMaybe<Scalars['String']>;
  _lastmodifiedon___eq?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___gt?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___gte?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___in?: InputMaybe<Array<InputMaybe<Scalars['Datetime']>>>;
  _lastmodifiedon___lt?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___lte?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___ne?: InputMaybe<Scalars['Datetime']>;
  _lastmodifiedon___not___in?: InputMaybe<Array<Scalars['Datetime']>>;
  _lastmodifiedon___not___null?: InputMaybe<Scalars['Boolean']>;
  _lastmodifiedon___null?: InputMaybe<Scalars['Boolean']>;
  address___contains?: InputMaybe<Scalars['String']>;
  address___ends_with?: InputMaybe<Scalars['String']>;
  address___eq?: InputMaybe<Scalars['String']>;
  address___gt?: InputMaybe<Scalars['String']>;
  address___gte?: InputMaybe<Scalars['String']>;
  address___in?: InputMaybe<Array<Scalars['String']>>;
  address___lt?: InputMaybe<Scalars['String']>;
  address___lte?: InputMaybe<Scalars['String']>;
  address___ne?: InputMaybe<Scalars['String']>;
  address___not___contains?: InputMaybe<Scalars['String']>;
  address___not___ends_with?: InputMaybe<Scalars['String']>;
  address___not___in?: InputMaybe<Array<Scalars['String']>>;
  address___not___null?: InputMaybe<Scalars['Boolean']>;
  address___not___starts_with?: InputMaybe<Scalars['String']>;
  address___null?: InputMaybe<Scalars['Boolean']>;
  address___starts_with?: InputMaybe<Scalars['String']>;
  center___contains?: InputMaybe<Scalars['String']>;
  center___ends_with?: InputMaybe<Scalars['String']>;
  center___eq?: InputMaybe<Scalars['String']>;
  center___gt?: InputMaybe<Scalars['String']>;
  center___gte?: InputMaybe<Scalars['String']>;
  center___in?: InputMaybe<Array<Scalars['String']>>;
  center___lt?: InputMaybe<Scalars['String']>;
  center___lte?: InputMaybe<Scalars['String']>;
  center___ne?: InputMaybe<Scalars['String']>;
  center___not___contains?: InputMaybe<Scalars['String']>;
  center___not___ends_with?: InputMaybe<Scalars['String']>;
  center___not___in?: InputMaybe<Array<Scalars['String']>>;
  center___not___null?: InputMaybe<Scalars['Boolean']>;
  center___not___starts_with?: InputMaybe<Scalars['String']>;
  center___null?: InputMaybe<Scalars['Boolean']>;
  center___starts_with?: InputMaybe<Scalars['String']>;
  center_id___eq?: InputMaybe<Scalars['Int']>;
  center_id___gt?: InputMaybe<Scalars['Int']>;
  center_id___gte?: InputMaybe<Scalars['Int']>;
  center_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  center_id___lt?: InputMaybe<Scalars['Int']>;
  center_id___lte?: InputMaybe<Scalars['Int']>;
  center_id___ne?: InputMaybe<Scalars['Int']>;
  center_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  center_id___not___null?: InputMaybe<Scalars['Boolean']>;
  center_id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  country___contains?: InputMaybe<Scalars['String']>;
  country___ends_with?: InputMaybe<Scalars['String']>;
  country___eq?: InputMaybe<Scalars['String']>;
  country___gt?: InputMaybe<Scalars['String']>;
  country___gte?: InputMaybe<Scalars['String']>;
  country___in?: InputMaybe<Array<Scalars['String']>>;
  country___lt?: InputMaybe<Scalars['String']>;
  country___lte?: InputMaybe<Scalars['String']>;
  country___ne?: InputMaybe<Scalars['String']>;
  country___not___contains?: InputMaybe<Scalars['String']>;
  country___not___ends_with?: InputMaybe<Scalars['String']>;
  country___not___in?: InputMaybe<Array<Scalars['String']>>;
  country___not___null?: InputMaybe<Scalars['Boolean']>;
  country___not___starts_with?: InputMaybe<Scalars['String']>;
  country___null?: InputMaybe<Scalars['Boolean']>;
  country___starts_with?: InputMaybe<Scalars['String']>;
  description___contains?: InputMaybe<Scalars['String']>;
  description___ends_with?: InputMaybe<Scalars['String']>;
  description___eq?: InputMaybe<Scalars['String']>;
  description___gt?: InputMaybe<Scalars['String']>;
  description___gte?: InputMaybe<Scalars['String']>;
  description___in?: InputMaybe<Array<Scalars['String']>>;
  description___lt?: InputMaybe<Scalars['String']>;
  description___lte?: InputMaybe<Scalars['String']>;
  description___ne?: InputMaybe<Scalars['String']>;
  description___not___contains?: InputMaybe<Scalars['String']>;
  description___not___ends_with?: InputMaybe<Scalars['String']>;
  description___not___in?: InputMaybe<Array<Scalars['String']>>;
  description___not___null?: InputMaybe<Scalars['Boolean']>;
  description___not___starts_with?: InputMaybe<Scalars['String']>;
  description___null?: InputMaybe<Scalars['Boolean']>;
  description___starts_with?: InputMaybe<Scalars['String']>;
  include_data___eq?: InputMaybe<Scalars['Boolean']>;
  include_data___ne?: InputMaybe<Scalars['Boolean']>;
  include_data___not___null?: InputMaybe<Scalars['Boolean']>;
  include_data___null?: InputMaybe<Scalars['Boolean']>;
  laboratory___contains?: InputMaybe<Scalars['String']>;
  laboratory___ends_with?: InputMaybe<Scalars['String']>;
  laboratory___eq?: InputMaybe<Scalars['String']>;
  laboratory___gt?: InputMaybe<Scalars['String']>;
  laboratory___gte?: InputMaybe<Scalars['String']>;
  laboratory___in?: InputMaybe<Array<Scalars['String']>>;
  laboratory___lt?: InputMaybe<Scalars['String']>;
  laboratory___lte?: InputMaybe<Scalars['String']>;
  laboratory___ne?: InputMaybe<Scalars['String']>;
  laboratory___not___contains?: InputMaybe<Scalars['String']>;
  laboratory___not___ends_with?: InputMaybe<Scalars['String']>;
  laboratory___not___in?: InputMaybe<Array<Scalars['String']>>;
  laboratory___not___null?: InputMaybe<Scalars['Boolean']>;
  laboratory___not___starts_with?: InputMaybe<Scalars['String']>;
  laboratory___null?: InputMaybe<Scalars['Boolean']>;
  laboratory___starts_with?: InputMaybe<Scalars['String']>;
  last_year___eq?: InputMaybe<Scalars['Year']>;
  last_year___gt?: InputMaybe<Scalars['Year']>;
  last_year___gte?: InputMaybe<Scalars['Year']>;
  last_year___in?: InputMaybe<Array<InputMaybe<Scalars['Year']>>>;
  last_year___lt?: InputMaybe<Scalars['Year']>;
  last_year___lte?: InputMaybe<Scalars['Year']>;
  last_year___ne?: InputMaybe<Scalars['Year']>;
  last_year___not___in?: InputMaybe<Array<Scalars['Year']>>;
  last_year___not___null?: InputMaybe<Scalars['Boolean']>;
  last_year___null?: InputMaybe<Scalars['Boolean']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  national_code___contains?: InputMaybe<Scalars['String']>;
  national_code___ends_with?: InputMaybe<Scalars['String']>;
  national_code___eq?: InputMaybe<Scalars['String']>;
  national_code___gt?: InputMaybe<Scalars['String']>;
  national_code___gte?: InputMaybe<Scalars['String']>;
  national_code___in?: InputMaybe<Array<Scalars['String']>>;
  national_code___lt?: InputMaybe<Scalars['String']>;
  national_code___lte?: InputMaybe<Scalars['String']>;
  national_code___ne?: InputMaybe<Scalars['String']>;
  national_code___not___contains?: InputMaybe<Scalars['String']>;
  national_code___not___ends_with?: InputMaybe<Scalars['String']>;
  national_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  national_code___not___null?: InputMaybe<Scalars['Boolean']>;
  national_code___not___starts_with?: InputMaybe<Scalars['String']>;
  national_code___null?: InputMaybe<Scalars['Boolean']>;
  national_code___starts_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___contains?: InputMaybe<Scalars['String']>;
  neighbourhood_type___ends_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___eq?: InputMaybe<Scalars['String']>;
  neighbourhood_type___gt?: InputMaybe<Scalars['String']>;
  neighbourhood_type___gte?: InputMaybe<Scalars['String']>;
  neighbourhood_type___in?: InputMaybe<Array<Scalars['String']>>;
  neighbourhood_type___lt?: InputMaybe<Scalars['String']>;
  neighbourhood_type___lte?: InputMaybe<Scalars['String']>;
  neighbourhood_type___ne?: InputMaybe<Scalars['String']>;
  neighbourhood_type___not___contains?: InputMaybe<Scalars['String']>;
  neighbourhood_type___not___ends_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___not___in?: InputMaybe<Array<Scalars['String']>>;
  neighbourhood_type___not___null?: InputMaybe<Scalars['Boolean']>;
  neighbourhood_type___not___starts_with?: InputMaybe<Scalars['String']>;
  neighbourhood_type___null?: InputMaybe<Scalars['Boolean']>;
  neighbourhood_type___starts_with?: InputMaybe<Scalars['String']>;
  rand_sequence___contains?: InputMaybe<Scalars['String']>;
  rand_sequence___ends_with?: InputMaybe<Scalars['String']>;
  rand_sequence___eq?: InputMaybe<Scalars['String']>;
  rand_sequence___gt?: InputMaybe<Scalars['String']>;
  rand_sequence___gte?: InputMaybe<Scalars['String']>;
  rand_sequence___in?: InputMaybe<Array<Scalars['String']>>;
  rand_sequence___lt?: InputMaybe<Scalars['String']>;
  rand_sequence___lte?: InputMaybe<Scalars['String']>;
  rand_sequence___ne?: InputMaybe<Scalars['String']>;
  rand_sequence___not___contains?: InputMaybe<Scalars['String']>;
  rand_sequence___not___ends_with?: InputMaybe<Scalars['String']>;
  rand_sequence___not___in?: InputMaybe<Array<Scalars['String']>>;
  rand_sequence___not___null?: InputMaybe<Scalars['Boolean']>;
  rand_sequence___not___starts_with?: InputMaybe<Scalars['String']>;
  rand_sequence___null?: InputMaybe<Scalars['Boolean']>;
  rand_sequence___starts_with?: InputMaybe<Scalars['String']>;
  serial_id___eq?: InputMaybe<Scalars['Serial']>;
  serial_id___gt?: InputMaybe<Scalars['Serial']>;
  serial_id___gte?: InputMaybe<Scalars['Serial']>;
  serial_id___in?: InputMaybe<Array<InputMaybe<Scalars['Serial']>>>;
  serial_id___lt?: InputMaybe<Scalars['Serial']>;
  serial_id___lte?: InputMaybe<Scalars['Serial']>;
  serial_id___ne?: InputMaybe<Scalars['Serial']>;
  serial_id___not___in?: InputMaybe<Array<Scalars['Serial']>>;
  serial_id___not___null?: InputMaybe<Scalars['Boolean']>;
  serial_id___null?: InputMaybe<Scalars['Boolean']>;
  switch_lolli___eq?: InputMaybe<Scalars['Boolean']>;
  switch_lolli___ne?: InputMaybe<Scalars['Boolean']>;
  switch_lolli___not___null?: InputMaybe<Scalars['Boolean']>;
  switch_lolli___null?: InputMaybe<Scalars['Boolean']>;
  test___eq?: InputMaybe<Scalars['Boolean']>;
  test___ne?: InputMaybe<Scalars['Boolean']>;
  test___not___null?: InputMaybe<Scalars['Boolean']>;
  test___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class School. */
export type SchoolPage = {
  __typename?: 'SchoolPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class School. */
  items: Array<School>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class School present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a SchoolPage. */
export type SchoolPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<SchoolFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<SchoolCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<SchoolSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class School */
export type SchoolRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the associated objects of class School */
export type SchoolRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum SchoolSort {
  _createdby___ASC = '_createdby___ASC',
  _createdby___DESC = '_createdby___DESC',
  _createdon___ASC = '_createdon___ASC',
  _createdon___DESC = '_createdon___DESC',
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  _lastmodifiedby___ASC = '_lastmodifiedby___ASC',
  _lastmodifiedby___DESC = '_lastmodifiedby___DESC',
  _lastmodifiedon___ASC = '_lastmodifiedon___ASC',
  _lastmodifiedon___DESC = '_lastmodifiedon___DESC',
  address___ASC = 'address___ASC',
  address___DESC = 'address___DESC',
  center___ASC = 'center___ASC',
  center___DESC = 'center___DESC',
  center_id___ASC = 'center_id___ASC',
  center_id___DESC = 'center_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  country___ASC = 'country___ASC',
  country___DESC = 'country___DESC',
  description___ASC = 'description___ASC',
  description___DESC = 'description___DESC',
  include_data___ASC = 'include_data___ASC',
  include_data___DESC = 'include_data___DESC',
  laboratory___ASC = 'laboratory___ASC',
  laboratory___DESC = 'laboratory___DESC',
  last_year___ASC = 'last_year___ASC',
  last_year___DESC = 'last_year___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  national_code___ASC = 'national_code___ASC',
  national_code___DESC = 'national_code___DESC',
  neighbourhood_type___ASC = 'neighbourhood_type___ASC',
  neighbourhood_type___DESC = 'neighbourhood_type___DESC',
  rand_sequence___ASC = 'rand_sequence___ASC',
  rand_sequence___DESC = 'rand_sequence___DESC',
  serial_id___ASC = 'serial_id___ASC',
  serial_id___DESC = 'serial_id___DESC',
  switch_lolli___ASC = 'switch_lolli___ASC',
  switch_lolli___DESC = 'switch_lolli___DESC',
  test___ASC = 'test___ASC',
  test___DESC = 'test___DESC'
}

/** Represents an object of class School_class, as defined in the model. */
export type School_Class = {
  __typename?: 'School_class';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of School_class. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute center of School_class. It is of type String. Path: school_ center. Cannot be null. */
  center?: Maybe<Scalars['String']>;
  /** The attribute code of School_class. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The attribute crossover of School_class. It is of type Boolean. Path: school_ switch_lolli.  */
  crossover?: Maybe<Scalars['Boolean']>;
  /** The attribute description of School_class. It is of type String. Expression: 'concat(school,", ",level,": ",code," (",academic_year,")")'.  */
  description?: Maybe<Scalars['String']>;
  /** The attribute description_short of School_class. It is of type String. Expression: 'concat((toInt(academic_year)-2000),upperCase(substring(level,1,1)),grade,section)'. Cannot be null. */
  description_short?: Maybe<Scalars['String']>;
  /** The attribute grade of School_class. It is of type Int. Ranges: 1:20. Cannot be null. */
  grade?: Maybe<Scalars['Int']>;
  /** The attribute isLast_year of School_class. It is of type Boolean. Path: school_level_ isLast_year. Cannot be null. */
  isLast_year?: Maybe<Scalars['Boolean']>;
  /** The attribute level of School_class. It is of type String. Path: school_level_ level. Cannot be null. */
  level?: Maybe<Scalars['String']>;
  /** The attribute lolli of School_class. It is of type Boolean.  */
  lolli?: Maybe<Scalars['Boolean']>;
  /** The attribute lolli_fixed of School_class. It is of type Boolean. Expression: 'if(ifNull(School_class.crossover,false),!School_class.lolli,School_class.lolli)'.  */
  lolli_fixed?: Maybe<Scalars['Boolean']>;
  /** The attribute observational of School_class. It is of type Boolean.  */
  observational?: Maybe<Scalars['Boolean']>;
  /** The attribute randomized of School_class. It is of type Boolean. Cannot be null. */
  randomized?: Maybe<Scalars['Boolean']>;
  /** The attribute school of School_class. It is of type String. Path: school_ name. Cannot be null. */
  school?: Maybe<Scalars['String']>;
  /** The attribute school_id of School_class. It is of type Int. Path: school_ __id. Cannot be null. */
  school_id?: Maybe<Scalars['Int']>;
  /** The associated object for the role school_level_. */
  school_level_?: Maybe<School_Level>;
  /** The associable objects for the role school_level_. */
  school_level____associables?: Maybe<School_LevelPage>;
  /** The associated objects for the role school_people_. */
  school_people_?: Maybe<School_PeoplePage>;
  /** The associable objects for the role school_people_. */
  school_people____associables?: Maybe<School_PeoplePage>;
  /**
   * The attribute section of School_class. It is of type String. Domain: [0-z]+.
   * Max length: 255. Cannot be null.
   */
  section?: Maybe<Scalars['String']>;
  /** The attribute serial_id of School_class. It is of type Serial. Cannot be null. */
  serial_id?: Maybe<Scalars['Serial']>;
  /** The attribute square_meters of School_class. It is of type Int.  */
  square_meters?: Maybe<Scalars['Int']>;
  /** The attribute students of School_class. It is of type Int.  */
  students?: Maybe<Scalars['Int']>;
  /** The attribute ventilation_systems of School_class. It is of type Boolean. Cannot be null. */
  ventilation_systems?: Maybe<Scalars['Boolean']>;
};


/** Represents an object of class School_class, as defined in the model. */
export type School_ClassSchool_Level____AssociablesArgs = {
  options?: InputMaybe<School_LevelPageOptions>;
};


/** Represents an object of class School_class, as defined in the model. */
export type School_ClassSchool_People_Args = {
  options?: InputMaybe<School_PeoplePageOptions>;
};


/** Represents an object of class School_class, as defined in the model. */
export type School_ClassSchool_People____AssociablesArgs = {
  options?: InputMaybe<School_PeoplePageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * School_class.
 */
export type School_ClassCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  center?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  crossover?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  description_short?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['Int']>;
  isLast_year?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<Scalars['String']>;
  lolli?: InputMaybe<Scalars['Boolean']>;
  lolli_fixed?: InputMaybe<Scalars['Boolean']>;
  observational?: InputMaybe<Scalars['Boolean']>;
  randomized?: InputMaybe<Scalars['Boolean']>;
  school?: InputMaybe<Scalars['String']>;
  school_id?: InputMaybe<Scalars['Int']>;
  section?: InputMaybe<Scalars['String']>;
  serial_id?: InputMaybe<Scalars['Serial']>;
  square_meters?: InputMaybe<Scalars['Int']>;
  students?: InputMaybe<Scalars['Int']>;
  ventilation_systems?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft associated object of class School_class */
export type School_ClassDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type School_class.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type School_ClassFilter = {
  AND?: InputMaybe<Array<School_ClassFilter>>;
  NOT?: InputMaybe<School_ClassFilter>;
  OR?: InputMaybe<Array<School_ClassFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  center___contains?: InputMaybe<Scalars['String']>;
  center___ends_with?: InputMaybe<Scalars['String']>;
  center___eq?: InputMaybe<Scalars['String']>;
  center___gt?: InputMaybe<Scalars['String']>;
  center___gte?: InputMaybe<Scalars['String']>;
  center___in?: InputMaybe<Array<Scalars['String']>>;
  center___lt?: InputMaybe<Scalars['String']>;
  center___lte?: InputMaybe<Scalars['String']>;
  center___ne?: InputMaybe<Scalars['String']>;
  center___not___contains?: InputMaybe<Scalars['String']>;
  center___not___ends_with?: InputMaybe<Scalars['String']>;
  center___not___in?: InputMaybe<Array<Scalars['String']>>;
  center___not___null?: InputMaybe<Scalars['Boolean']>;
  center___not___starts_with?: InputMaybe<Scalars['String']>;
  center___null?: InputMaybe<Scalars['Boolean']>;
  center___starts_with?: InputMaybe<Scalars['String']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  crossover___eq?: InputMaybe<Scalars['Boolean']>;
  crossover___ne?: InputMaybe<Scalars['Boolean']>;
  crossover___not___null?: InputMaybe<Scalars['Boolean']>;
  crossover___null?: InputMaybe<Scalars['Boolean']>;
  description___contains?: InputMaybe<Scalars['String']>;
  description___ends_with?: InputMaybe<Scalars['String']>;
  description___eq?: InputMaybe<Scalars['String']>;
  description___gt?: InputMaybe<Scalars['String']>;
  description___gte?: InputMaybe<Scalars['String']>;
  description___in?: InputMaybe<Array<Scalars['String']>>;
  description___lt?: InputMaybe<Scalars['String']>;
  description___lte?: InputMaybe<Scalars['String']>;
  description___ne?: InputMaybe<Scalars['String']>;
  description___not___contains?: InputMaybe<Scalars['String']>;
  description___not___ends_with?: InputMaybe<Scalars['String']>;
  description___not___in?: InputMaybe<Array<Scalars['String']>>;
  description___not___null?: InputMaybe<Scalars['Boolean']>;
  description___not___starts_with?: InputMaybe<Scalars['String']>;
  description___null?: InputMaybe<Scalars['Boolean']>;
  description___starts_with?: InputMaybe<Scalars['String']>;
  description_short___contains?: InputMaybe<Scalars['String']>;
  description_short___ends_with?: InputMaybe<Scalars['String']>;
  description_short___eq?: InputMaybe<Scalars['String']>;
  description_short___gt?: InputMaybe<Scalars['String']>;
  description_short___gte?: InputMaybe<Scalars['String']>;
  description_short___in?: InputMaybe<Array<Scalars['String']>>;
  description_short___lt?: InputMaybe<Scalars['String']>;
  description_short___lte?: InputMaybe<Scalars['String']>;
  description_short___ne?: InputMaybe<Scalars['String']>;
  description_short___not___contains?: InputMaybe<Scalars['String']>;
  description_short___not___ends_with?: InputMaybe<Scalars['String']>;
  description_short___not___in?: InputMaybe<Array<Scalars['String']>>;
  description_short___not___null?: InputMaybe<Scalars['Boolean']>;
  description_short___not___starts_with?: InputMaybe<Scalars['String']>;
  description_short___null?: InputMaybe<Scalars['Boolean']>;
  description_short___starts_with?: InputMaybe<Scalars['String']>;
  grade___eq?: InputMaybe<Scalars['Int']>;
  grade___gt?: InputMaybe<Scalars['Int']>;
  grade___gte?: InputMaybe<Scalars['Int']>;
  grade___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  grade___lt?: InputMaybe<Scalars['Int']>;
  grade___lte?: InputMaybe<Scalars['Int']>;
  grade___ne?: InputMaybe<Scalars['Int']>;
  grade___not___in?: InputMaybe<Array<Scalars['Int']>>;
  grade___not___null?: InputMaybe<Scalars['Boolean']>;
  grade___null?: InputMaybe<Scalars['Boolean']>;
  isLast_year___eq?: InputMaybe<Scalars['Boolean']>;
  isLast_year___ne?: InputMaybe<Scalars['Boolean']>;
  isLast_year___not___null?: InputMaybe<Scalars['Boolean']>;
  isLast_year___null?: InputMaybe<Scalars['Boolean']>;
  level___contains?: InputMaybe<Scalars['String']>;
  level___ends_with?: InputMaybe<Scalars['String']>;
  level___eq?: InputMaybe<Scalars['String']>;
  level___gt?: InputMaybe<Scalars['String']>;
  level___gte?: InputMaybe<Scalars['String']>;
  level___in?: InputMaybe<Array<Scalars['String']>>;
  level___lt?: InputMaybe<Scalars['String']>;
  level___lte?: InputMaybe<Scalars['String']>;
  level___ne?: InputMaybe<Scalars['String']>;
  level___not___contains?: InputMaybe<Scalars['String']>;
  level___not___ends_with?: InputMaybe<Scalars['String']>;
  level___not___in?: InputMaybe<Array<Scalars['String']>>;
  level___not___null?: InputMaybe<Scalars['Boolean']>;
  level___not___starts_with?: InputMaybe<Scalars['String']>;
  level___null?: InputMaybe<Scalars['Boolean']>;
  level___starts_with?: InputMaybe<Scalars['String']>;
  lolli___eq?: InputMaybe<Scalars['Boolean']>;
  lolli___ne?: InputMaybe<Scalars['Boolean']>;
  lolli___not___null?: InputMaybe<Scalars['Boolean']>;
  lolli___null?: InputMaybe<Scalars['Boolean']>;
  lolli_fixed___eq?: InputMaybe<Scalars['Boolean']>;
  lolli_fixed___ne?: InputMaybe<Scalars['Boolean']>;
  lolli_fixed___not___null?: InputMaybe<Scalars['Boolean']>;
  lolli_fixed___null?: InputMaybe<Scalars['Boolean']>;
  observational___eq?: InputMaybe<Scalars['Boolean']>;
  observational___ne?: InputMaybe<Scalars['Boolean']>;
  observational___not___null?: InputMaybe<Scalars['Boolean']>;
  observational___null?: InputMaybe<Scalars['Boolean']>;
  randomized___eq?: InputMaybe<Scalars['Boolean']>;
  randomized___ne?: InputMaybe<Scalars['Boolean']>;
  randomized___not___null?: InputMaybe<Scalars['Boolean']>;
  randomized___null?: InputMaybe<Scalars['Boolean']>;
  school___contains?: InputMaybe<Scalars['String']>;
  school___ends_with?: InputMaybe<Scalars['String']>;
  school___eq?: InputMaybe<Scalars['String']>;
  school___gt?: InputMaybe<Scalars['String']>;
  school___gte?: InputMaybe<Scalars['String']>;
  school___in?: InputMaybe<Array<Scalars['String']>>;
  school___lt?: InputMaybe<Scalars['String']>;
  school___lte?: InputMaybe<Scalars['String']>;
  school___ne?: InputMaybe<Scalars['String']>;
  school___not___contains?: InputMaybe<Scalars['String']>;
  school___not___ends_with?: InputMaybe<Scalars['String']>;
  school___not___in?: InputMaybe<Array<Scalars['String']>>;
  school___not___null?: InputMaybe<Scalars['Boolean']>;
  school___not___starts_with?: InputMaybe<Scalars['String']>;
  school___null?: InputMaybe<Scalars['Boolean']>;
  school___starts_with?: InputMaybe<Scalars['String']>;
  school_id___eq?: InputMaybe<Scalars['Int']>;
  school_id___gt?: InputMaybe<Scalars['Int']>;
  school_id___gte?: InputMaybe<Scalars['Int']>;
  school_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  school_id___lt?: InputMaybe<Scalars['Int']>;
  school_id___lte?: InputMaybe<Scalars['Int']>;
  school_id___ne?: InputMaybe<Scalars['Int']>;
  school_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  school_id___not___null?: InputMaybe<Scalars['Boolean']>;
  school_id___null?: InputMaybe<Scalars['Boolean']>;
  section___contains?: InputMaybe<Scalars['String']>;
  section___ends_with?: InputMaybe<Scalars['String']>;
  section___eq?: InputMaybe<Scalars['String']>;
  section___gt?: InputMaybe<Scalars['String']>;
  section___gte?: InputMaybe<Scalars['String']>;
  section___in?: InputMaybe<Array<Scalars['String']>>;
  section___lt?: InputMaybe<Scalars['String']>;
  section___lte?: InputMaybe<Scalars['String']>;
  section___ne?: InputMaybe<Scalars['String']>;
  section___not___contains?: InputMaybe<Scalars['String']>;
  section___not___ends_with?: InputMaybe<Scalars['String']>;
  section___not___in?: InputMaybe<Array<Scalars['String']>>;
  section___not___null?: InputMaybe<Scalars['Boolean']>;
  section___not___starts_with?: InputMaybe<Scalars['String']>;
  section___null?: InputMaybe<Scalars['Boolean']>;
  section___starts_with?: InputMaybe<Scalars['String']>;
  serial_id___eq?: InputMaybe<Scalars['Serial']>;
  serial_id___gt?: InputMaybe<Scalars['Serial']>;
  serial_id___gte?: InputMaybe<Scalars['Serial']>;
  serial_id___in?: InputMaybe<Array<InputMaybe<Scalars['Serial']>>>;
  serial_id___lt?: InputMaybe<Scalars['Serial']>;
  serial_id___lte?: InputMaybe<Scalars['Serial']>;
  serial_id___ne?: InputMaybe<Scalars['Serial']>;
  serial_id___not___in?: InputMaybe<Array<Scalars['Serial']>>;
  serial_id___not___null?: InputMaybe<Scalars['Boolean']>;
  serial_id___null?: InputMaybe<Scalars['Boolean']>;
  square_meters___eq?: InputMaybe<Scalars['Int']>;
  square_meters___gt?: InputMaybe<Scalars['Int']>;
  square_meters___gte?: InputMaybe<Scalars['Int']>;
  square_meters___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  square_meters___lt?: InputMaybe<Scalars['Int']>;
  square_meters___lte?: InputMaybe<Scalars['Int']>;
  square_meters___ne?: InputMaybe<Scalars['Int']>;
  square_meters___not___in?: InputMaybe<Array<Scalars['Int']>>;
  square_meters___not___null?: InputMaybe<Scalars['Boolean']>;
  square_meters___null?: InputMaybe<Scalars['Boolean']>;
  students___eq?: InputMaybe<Scalars['Int']>;
  students___gt?: InputMaybe<Scalars['Int']>;
  students___gte?: InputMaybe<Scalars['Int']>;
  students___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  students___lt?: InputMaybe<Scalars['Int']>;
  students___lte?: InputMaybe<Scalars['Int']>;
  students___ne?: InputMaybe<Scalars['Int']>;
  students___not___in?: InputMaybe<Array<Scalars['Int']>>;
  students___not___null?: InputMaybe<Scalars['Boolean']>;
  students___null?: InputMaybe<Scalars['Boolean']>;
  ventilation_systems___eq?: InputMaybe<Scalars['Boolean']>;
  ventilation_systems___ne?: InputMaybe<Scalars['Boolean']>;
  ventilation_systems___not___null?: InputMaybe<Scalars['Boolean']>;
  ventilation_systems___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class School_class. */
export type School_ClassPage = {
  __typename?: 'School_classPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class School_class. */
  items: Array<School_Class>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class School_class present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a School_classPage. */
export type School_ClassPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<School_ClassFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<School_ClassCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<School_ClassSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class School_class */
export type School_ClassRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum School_ClassSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  center___ASC = 'center___ASC',
  center___DESC = 'center___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  crossover___ASC = 'crossover___ASC',
  crossover___DESC = 'crossover___DESC',
  description___ASC = 'description___ASC',
  description___DESC = 'description___DESC',
  description_short___ASC = 'description_short___ASC',
  description_short___DESC = 'description_short___DESC',
  grade___ASC = 'grade___ASC',
  grade___DESC = 'grade___DESC',
  isLast_year___ASC = 'isLast_year___ASC',
  isLast_year___DESC = 'isLast_year___DESC',
  level___ASC = 'level___ASC',
  level___DESC = 'level___DESC',
  lolli___ASC = 'lolli___ASC',
  lolli___DESC = 'lolli___DESC',
  lolli_fixed___ASC = 'lolli_fixed___ASC',
  lolli_fixed___DESC = 'lolli_fixed___DESC',
  observational___ASC = 'observational___ASC',
  observational___DESC = 'observational___DESC',
  randomized___ASC = 'randomized___ASC',
  randomized___DESC = 'randomized___DESC',
  school___ASC = 'school___ASC',
  school___DESC = 'school___DESC',
  school_id___ASC = 'school_id___ASC',
  school_id___DESC = 'school_id___DESC',
  section___ASC = 'section___ASC',
  section___DESC = 'section___DESC',
  serial_id___ASC = 'serial_id___ASC',
  serial_id___DESC = 'serial_id___DESC',
  square_meters___ASC = 'square_meters___ASC',
  square_meters___DESC = 'square_meters___DESC',
  students___ASC = 'students___ASC',
  students___DESC = 'students___DESC',
  ventilation_systems___ASC = 'ventilation_systems___ASC',
  ventilation_systems___DESC = 'ventilation_systems___DESC'
}

/** Represents an object of class School_level, as defined in the model. */
export type School_Level = {
  __typename?: 'School_level';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of School_level. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute classes_nr of School_level. It is of type Int. Cannot be null. */
  classes_nr?: Maybe<Scalars['Int']>;
  /** The attribute description of School_level. It is of type String. Expression: 'concat(school,", ",level," (",academic_year,")")'. Cannot be null. */
  description?: Maybe<Scalars['String']>;
  /** The attribute isLast_year of School_level. It is of type Boolean. Expression: 'school_last_year=academic_year'. Cannot be null. */
  isLast_year?: Maybe<Scalars['Boolean']>;
  /** The attribute level of School_level. It is of type String. Path: level_type_ level_type. Cannot be null. */
  level?: Maybe<Scalars['String']>;
  /** The associated object for the role level_type_. */
  level_type_?: Maybe<Level_Type>;
  /** The associable objects for the role level_type_. */
  level_type____associables?: Maybe<Level_TypePage>;
  /** The attribute school of School_level. It is of type String. Path: school_ name. Cannot be null. */
  school?: Maybe<Scalars['String']>;
  /** The attribute school_last_year of School_level. It is of type Year. Path: school_ last_year. Cannot be null. */
  school_last_year?: Maybe<Scalars['Year']>;
};


/** Represents an object of class School_level, as defined in the model. */
export type School_LevelLevel_Type____AssociablesArgs = {
  options?: InputMaybe<Level_TypePageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * School_level.
 */
export type School_LevelCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  classes_nr?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  isLast_year?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<Scalars['String']>;
  school_last_year?: InputMaybe<Scalars['Year']>;
};

/** A data structure for the draft associated object of class School_level */
export type School_LevelDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type School_level.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type School_LevelFilter = {
  AND?: InputMaybe<Array<School_LevelFilter>>;
  NOT?: InputMaybe<School_LevelFilter>;
  OR?: InputMaybe<Array<School_LevelFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  classes_nr___eq?: InputMaybe<Scalars['Int']>;
  classes_nr___gt?: InputMaybe<Scalars['Int']>;
  classes_nr___gte?: InputMaybe<Scalars['Int']>;
  classes_nr___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  classes_nr___lt?: InputMaybe<Scalars['Int']>;
  classes_nr___lte?: InputMaybe<Scalars['Int']>;
  classes_nr___ne?: InputMaybe<Scalars['Int']>;
  classes_nr___not___in?: InputMaybe<Array<Scalars['Int']>>;
  classes_nr___not___null?: InputMaybe<Scalars['Boolean']>;
  classes_nr___null?: InputMaybe<Scalars['Boolean']>;
  description___contains?: InputMaybe<Scalars['String']>;
  description___ends_with?: InputMaybe<Scalars['String']>;
  description___eq?: InputMaybe<Scalars['String']>;
  description___gt?: InputMaybe<Scalars['String']>;
  description___gte?: InputMaybe<Scalars['String']>;
  description___in?: InputMaybe<Array<Scalars['String']>>;
  description___lt?: InputMaybe<Scalars['String']>;
  description___lte?: InputMaybe<Scalars['String']>;
  description___ne?: InputMaybe<Scalars['String']>;
  description___not___contains?: InputMaybe<Scalars['String']>;
  description___not___ends_with?: InputMaybe<Scalars['String']>;
  description___not___in?: InputMaybe<Array<Scalars['String']>>;
  description___not___null?: InputMaybe<Scalars['Boolean']>;
  description___not___starts_with?: InputMaybe<Scalars['String']>;
  description___null?: InputMaybe<Scalars['Boolean']>;
  description___starts_with?: InputMaybe<Scalars['String']>;
  isLast_year___eq?: InputMaybe<Scalars['Boolean']>;
  isLast_year___ne?: InputMaybe<Scalars['Boolean']>;
  isLast_year___not___null?: InputMaybe<Scalars['Boolean']>;
  isLast_year___null?: InputMaybe<Scalars['Boolean']>;
  level___contains?: InputMaybe<Scalars['String']>;
  level___ends_with?: InputMaybe<Scalars['String']>;
  level___eq?: InputMaybe<Scalars['String']>;
  level___gt?: InputMaybe<Scalars['String']>;
  level___gte?: InputMaybe<Scalars['String']>;
  level___in?: InputMaybe<Array<Scalars['String']>>;
  level___lt?: InputMaybe<Scalars['String']>;
  level___lte?: InputMaybe<Scalars['String']>;
  level___ne?: InputMaybe<Scalars['String']>;
  level___not___contains?: InputMaybe<Scalars['String']>;
  level___not___ends_with?: InputMaybe<Scalars['String']>;
  level___not___in?: InputMaybe<Array<Scalars['String']>>;
  level___not___null?: InputMaybe<Scalars['Boolean']>;
  level___not___starts_with?: InputMaybe<Scalars['String']>;
  level___null?: InputMaybe<Scalars['Boolean']>;
  level___starts_with?: InputMaybe<Scalars['String']>;
  school___contains?: InputMaybe<Scalars['String']>;
  school___ends_with?: InputMaybe<Scalars['String']>;
  school___eq?: InputMaybe<Scalars['String']>;
  school___gt?: InputMaybe<Scalars['String']>;
  school___gte?: InputMaybe<Scalars['String']>;
  school___in?: InputMaybe<Array<Scalars['String']>>;
  school___lt?: InputMaybe<Scalars['String']>;
  school___lte?: InputMaybe<Scalars['String']>;
  school___ne?: InputMaybe<Scalars['String']>;
  school___not___contains?: InputMaybe<Scalars['String']>;
  school___not___ends_with?: InputMaybe<Scalars['String']>;
  school___not___in?: InputMaybe<Array<Scalars['String']>>;
  school___not___null?: InputMaybe<Scalars['Boolean']>;
  school___not___starts_with?: InputMaybe<Scalars['String']>;
  school___null?: InputMaybe<Scalars['Boolean']>;
  school___starts_with?: InputMaybe<Scalars['String']>;
  school_last_year___eq?: InputMaybe<Scalars['Year']>;
  school_last_year___gt?: InputMaybe<Scalars['Year']>;
  school_last_year___gte?: InputMaybe<Scalars['Year']>;
  school_last_year___in?: InputMaybe<Array<InputMaybe<Scalars['Year']>>>;
  school_last_year___lt?: InputMaybe<Scalars['Year']>;
  school_last_year___lte?: InputMaybe<Scalars['Year']>;
  school_last_year___ne?: InputMaybe<Scalars['Year']>;
  school_last_year___not___in?: InputMaybe<Array<Scalars['Year']>>;
  school_last_year___not___null?: InputMaybe<Scalars['Boolean']>;
  school_last_year___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class School_level. */
export type School_LevelPage = {
  __typename?: 'School_levelPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class School_level. */
  items: Array<School_Level>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class School_level present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a School_levelPage. */
export type School_LevelPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<School_LevelFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<School_LevelCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<School_LevelSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class School_level */
export type School_LevelRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum School_LevelSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  classes_nr___ASC = 'classes_nr___ASC',
  classes_nr___DESC = 'classes_nr___DESC',
  description___ASC = 'description___ASC',
  description___DESC = 'description___DESC',
  isLast_year___ASC = 'isLast_year___ASC',
  isLast_year___DESC = 'isLast_year___DESC',
  level___ASC = 'level___ASC',
  level___DESC = 'level___DESC',
  school___ASC = 'school___ASC',
  school___DESC = 'school___DESC',
  school_last_year___ASC = 'school_last_year___ASC',
  school_last_year___DESC = 'school_last_year___DESC'
}

/** Represents an object of class School_people, as defined in the model. */
export type School_People = {
  __typename?: 'School_people';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of School_people. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute code of School_people. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The attribute consent_withdrawn of School_people. It is of type Boolean. Path: person_ consent_withdrawn.  */
  consent_withdrawn?: Maybe<Scalars['Boolean']>;
  /** The attribute date_last_questionnaire of School_people. It is of type Date @dateFormat. Path: MAX - Maxquestionnaire_completion_ completion_date. Cannot be null. */
  date_last_questionnaire?: Maybe<Scalars['Date']>;
  /** The attribute description of School_people. It is of type String. Expression: 'concat(code," (",year_of_birth,")")'.  */
  description?: Maybe<Scalars['String']>;
  /** The attribute family_email of School_people. It is of type String. Path: person_.family_ email. Cannot be null. */
  family_email?: Maybe<Scalars['String']>;
  /** The attribute family_username of School_people. It is of type String. Path: person_.family_.User_ username. Cannot be null. */
  family_username?: Maybe<Scalars['String']>;
  /** The attribute gender of School_people. It is of type String. Path: person_ gender. Cannot be null. */
  gender?: Maybe<Scalars['String']>;
  /** The attribute isLast_year of School_people. It is of type Boolean. Path: school_class_ isLast_year. Cannot be null. */
  isLast_year?: Maybe<Scalars['Boolean']>;
  /** The attribute last_code of School_people. It is of type String. Expression: 'concat(school_class,"-",if(n_people_class<10,"0",""),n_people_class)'.  */
  last_code?: Maybe<Scalars['String']>;
  /** The attribute level of School_people. It is of type String. Path: school_class_.school_level_ level. Cannot be null. */
  level?: Maybe<Scalars['String']>;
  /** The attribute people_type of School_people. It is of type String. Path: person_ person_type. Cannot be null. */
  people_type?: Maybe<Scalars['String']>;
  /** The associated object for the role person_. */
  person_?: Maybe<Person>;
  /** The associable objects for the role person_. */
  person____associables?: Maybe<PersonPage>;
  /** The attribute person_email of School_people. It is of type String. Path: person_ email.  */
  person_email?: Maybe<Scalars['String']>;
  /** The associated objects for the role questionnaire_completion_. */
  questionnaire_completion_?: Maybe<Questionnaire_CompletionPage>;
  /** The associable objects for the role questionnaire_completion_. */
  questionnaire_completion____associables?: Maybe<Questionnaire_CompletionPage>;
  /** The attribute school of School_people. It is of type String. Path: school_class_ school. Cannot be null. */
  school?: Maybe<Scalars['String']>;
  /** The associated object for the role school_. */
  school_?: Maybe<School>;
  /** The attribute school_class of School_people. It is of type String. Path: school_class_ code.  */
  school_class?: Maybe<Scalars['String']>;
  /** The associated object for the role school_class_. */
  school_class_?: Maybe<School_Class>;
  /** The associable objects for the role school_class_. */
  school_class____associables?: Maybe<School_ClassPage>;
  /** The attribute serial_id of School_people. It is of type Serial. Cannot be null. */
  serial_id?: Maybe<Scalars['Serial']>;
  /** The attribute type_non_teaching_staff of School_people. It is of type Boolean. Path: person_.person_type_ non_teaching_staff. Cannot be null. */
  type_non_teaching_staff?: Maybe<Scalars['Boolean']>;
  /** The attribute type_student of School_people. It is of type Boolean. Path: person_.person_type_ student. Cannot be null. */
  type_student?: Maybe<Scalars['Boolean']>;
  /** The attribute type_teacher of School_people. It is of type Boolean. Path: person_.person_type_ teacher. Cannot be null. */
  type_teacher?: Maybe<Scalars['Boolean']>;
  /** The attribute username of School_people. It is of type String. Path: person_.User_ username. Cannot be null. */
  username?: Maybe<Scalars['String']>;
  /** The attribute year_of_birth of School_people. It is of type Year. Path: person_ year_of_birth. Cannot be null. */
  year_of_birth?: Maybe<Scalars['Year']>;
};


/** Represents an object of class School_people, as defined in the model. */
export type School_PeopleDate_Last_QuestionnaireArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class School_people, as defined in the model. */
export type School_PeoplePerson____AssociablesArgs = {
  options?: InputMaybe<PersonPageOptions>;
};


/** Represents an object of class School_people, as defined in the model. */
export type School_PeopleQuestionnaire_Completion_Args = {
  options?: InputMaybe<Questionnaire_CompletionPageOptions>;
};


/** Represents an object of class School_people, as defined in the model. */
export type School_PeopleQuestionnaire_Completion____AssociablesArgs = {
  options?: InputMaybe<Questionnaire_CompletionPageOptions>;
};


/** Represents an object of class School_people, as defined in the model. */
export type School_PeopleSchool_Class____AssociablesArgs = {
  options?: InputMaybe<School_ClassPageOptions>;
};

/** A data structure for objects of School_people. */
export type School_PeopleCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute code of School_people. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role school_people_(0,N) <--> (1)person_. */
  person_: Scalars['ID'];
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  questionnaire_completion_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role school_class_(1) <--> (0,N)school_people_. */
  school_class_: Scalars['ID'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * School_people.
 */
export type School_PeopleCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  consent_withdrawn?: InputMaybe<Scalars['Boolean']>;
  date_last_questionnaire?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  family_email?: InputMaybe<Scalars['String']>;
  family_username?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  isLast_year?: InputMaybe<Scalars['Boolean']>;
  last_code?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['String']>;
  people_type?: InputMaybe<Scalars['String']>;
  person_email?: InputMaybe<Scalars['String']>;
  school?: InputMaybe<Scalars['String']>;
  school_class?: InputMaybe<Scalars['String']>;
  serial_id?: InputMaybe<Scalars['Serial']>;
  type_non_teaching_staff?: InputMaybe<Scalars['Boolean']>;
  type_student?: InputMaybe<Scalars['Boolean']>;
  type_teacher?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
  year_of_birth?: InputMaybe<Scalars['Year']>;
};

/** A data structure for a draft object of class School_people. */
export type School_PeopleDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute code of School_people. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role school_people_(0,N) <--> (1)person_. */
  person_?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  questionnaire_completion_?: InputMaybe<Questionnaire_CompletionDraftRoleRefs>;
  /** The Association role school_class_(1) <--> (0,N)school_people_. */
  school_class_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for draft create objects of class School_people. */
export type School_PeopleDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute code of School_people. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role school_people_(0,N) <--> (1)person_. */
  person_?: InputMaybe<Scalars['ID']>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  questionnaire_completion_?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The Association role school_class_(1) <--> (0,N)school_people_. */
  school_class_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft part objects of class School_people */
export type School_PeopleDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class School_people to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<School_PeopleDraft>>>;
};

/** A data structure for the draft associated object of class School_people */
export type School_PeopleDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft associated objects of class School_people */
export type School_PeopleDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for a draft update object of class School_people. */
export type School_PeopleDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute code of School_people. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role school_people_(0,N) <--> (1)person_. */
  person_?: InputMaybe<PersonRoleRef>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  questionnaire_completion_?: InputMaybe<Questionnaire_CompletionRoleRefs>;
  /** The Association role school_class_(1) <--> (0,N)school_people_. */
  school_class_?: InputMaybe<School_ClassRoleRef>;
};

/** A data structure for a draft update objects of class School_people. */
export type School_PeopleDraftUpdateBulk = {
  /** The attribute code of School_people. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role school_people_(0,N) <--> (1)person_. */
  person_?: InputMaybe<PersonRoleRef>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  questionnaire_completion_?: InputMaybe<Questionnaire_CompletionRoleRefs>;
  /** The Association role school_class_(1) <--> (0,N)school_people_. */
  school_class_?: InputMaybe<School_ClassRoleRef>;
};

/** A data structure for the draft part objects of class School_people */
export type School_PeopleDraftUpdateRoleObjects = {
  /** An array of objects of class School_people to be created. */
  create?: InputMaybe<Array<InputMaybe<School_PeopleDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class School_people to be updated. */
  update?: InputMaybe<Array<InputMaybe<School_PeopleDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type School_people.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type School_PeopleFilter = {
  AND?: InputMaybe<Array<School_PeopleFilter>>;
  NOT?: InputMaybe<School_PeopleFilter>;
  OR?: InputMaybe<Array<School_PeopleFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  consent_withdrawn___eq?: InputMaybe<Scalars['Boolean']>;
  consent_withdrawn___ne?: InputMaybe<Scalars['Boolean']>;
  consent_withdrawn___not___null?: InputMaybe<Scalars['Boolean']>;
  consent_withdrawn___null?: InputMaybe<Scalars['Boolean']>;
  date_last_questionnaire___eq?: InputMaybe<Scalars['Date']>;
  date_last_questionnaire___gt?: InputMaybe<Scalars['Date']>;
  date_last_questionnaire___gte?: InputMaybe<Scalars['Date']>;
  date_last_questionnaire___in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  date_last_questionnaire___lt?: InputMaybe<Scalars['Date']>;
  date_last_questionnaire___lte?: InputMaybe<Scalars['Date']>;
  date_last_questionnaire___ne?: InputMaybe<Scalars['Date']>;
  date_last_questionnaire___not___in?: InputMaybe<Array<Scalars['Date']>>;
  date_last_questionnaire___not___null?: InputMaybe<Scalars['Boolean']>;
  date_last_questionnaire___null?: InputMaybe<Scalars['Boolean']>;
  description___contains?: InputMaybe<Scalars['String']>;
  description___ends_with?: InputMaybe<Scalars['String']>;
  description___eq?: InputMaybe<Scalars['String']>;
  description___gt?: InputMaybe<Scalars['String']>;
  description___gte?: InputMaybe<Scalars['String']>;
  description___in?: InputMaybe<Array<Scalars['String']>>;
  description___lt?: InputMaybe<Scalars['String']>;
  description___lte?: InputMaybe<Scalars['String']>;
  description___ne?: InputMaybe<Scalars['String']>;
  description___not___contains?: InputMaybe<Scalars['String']>;
  description___not___ends_with?: InputMaybe<Scalars['String']>;
  description___not___in?: InputMaybe<Array<Scalars['String']>>;
  description___not___null?: InputMaybe<Scalars['Boolean']>;
  description___not___starts_with?: InputMaybe<Scalars['String']>;
  description___null?: InputMaybe<Scalars['Boolean']>;
  description___starts_with?: InputMaybe<Scalars['String']>;
  family_email___contains?: InputMaybe<Scalars['String']>;
  family_email___ends_with?: InputMaybe<Scalars['String']>;
  family_email___eq?: InputMaybe<Scalars['String']>;
  family_email___gt?: InputMaybe<Scalars['String']>;
  family_email___gte?: InputMaybe<Scalars['String']>;
  family_email___in?: InputMaybe<Array<Scalars['String']>>;
  family_email___lt?: InputMaybe<Scalars['String']>;
  family_email___lte?: InputMaybe<Scalars['String']>;
  family_email___ne?: InputMaybe<Scalars['String']>;
  family_email___not___contains?: InputMaybe<Scalars['String']>;
  family_email___not___ends_with?: InputMaybe<Scalars['String']>;
  family_email___not___in?: InputMaybe<Array<Scalars['String']>>;
  family_email___not___null?: InputMaybe<Scalars['Boolean']>;
  family_email___not___starts_with?: InputMaybe<Scalars['String']>;
  family_email___null?: InputMaybe<Scalars['Boolean']>;
  family_email___starts_with?: InputMaybe<Scalars['String']>;
  family_username___contains?: InputMaybe<Scalars['String']>;
  family_username___ends_with?: InputMaybe<Scalars['String']>;
  family_username___eq?: InputMaybe<Scalars['String']>;
  family_username___gt?: InputMaybe<Scalars['String']>;
  family_username___gte?: InputMaybe<Scalars['String']>;
  family_username___in?: InputMaybe<Array<Scalars['String']>>;
  family_username___lt?: InputMaybe<Scalars['String']>;
  family_username___lte?: InputMaybe<Scalars['String']>;
  family_username___ne?: InputMaybe<Scalars['String']>;
  family_username___not___contains?: InputMaybe<Scalars['String']>;
  family_username___not___ends_with?: InputMaybe<Scalars['String']>;
  family_username___not___in?: InputMaybe<Array<Scalars['String']>>;
  family_username___not___null?: InputMaybe<Scalars['Boolean']>;
  family_username___not___starts_with?: InputMaybe<Scalars['String']>;
  family_username___null?: InputMaybe<Scalars['Boolean']>;
  family_username___starts_with?: InputMaybe<Scalars['String']>;
  gender___contains?: InputMaybe<Scalars['String']>;
  gender___ends_with?: InputMaybe<Scalars['String']>;
  gender___eq?: InputMaybe<Scalars['String']>;
  gender___gt?: InputMaybe<Scalars['String']>;
  gender___gte?: InputMaybe<Scalars['String']>;
  gender___in?: InputMaybe<Array<Scalars['String']>>;
  gender___lt?: InputMaybe<Scalars['String']>;
  gender___lte?: InputMaybe<Scalars['String']>;
  gender___ne?: InputMaybe<Scalars['String']>;
  gender___not___contains?: InputMaybe<Scalars['String']>;
  gender___not___ends_with?: InputMaybe<Scalars['String']>;
  gender___not___in?: InputMaybe<Array<Scalars['String']>>;
  gender___not___null?: InputMaybe<Scalars['Boolean']>;
  gender___not___starts_with?: InputMaybe<Scalars['String']>;
  gender___null?: InputMaybe<Scalars['Boolean']>;
  gender___starts_with?: InputMaybe<Scalars['String']>;
  isLast_year___eq?: InputMaybe<Scalars['Boolean']>;
  isLast_year___ne?: InputMaybe<Scalars['Boolean']>;
  isLast_year___not___null?: InputMaybe<Scalars['Boolean']>;
  isLast_year___null?: InputMaybe<Scalars['Boolean']>;
  last_code___contains?: InputMaybe<Scalars['String']>;
  last_code___ends_with?: InputMaybe<Scalars['String']>;
  last_code___eq?: InputMaybe<Scalars['String']>;
  last_code___gt?: InputMaybe<Scalars['String']>;
  last_code___gte?: InputMaybe<Scalars['String']>;
  last_code___in?: InputMaybe<Array<Scalars['String']>>;
  last_code___lt?: InputMaybe<Scalars['String']>;
  last_code___lte?: InputMaybe<Scalars['String']>;
  last_code___ne?: InputMaybe<Scalars['String']>;
  last_code___not___contains?: InputMaybe<Scalars['String']>;
  last_code___not___ends_with?: InputMaybe<Scalars['String']>;
  last_code___not___in?: InputMaybe<Array<Scalars['String']>>;
  last_code___not___null?: InputMaybe<Scalars['Boolean']>;
  last_code___not___starts_with?: InputMaybe<Scalars['String']>;
  last_code___null?: InputMaybe<Scalars['Boolean']>;
  last_code___starts_with?: InputMaybe<Scalars['String']>;
  level___contains?: InputMaybe<Scalars['String']>;
  level___ends_with?: InputMaybe<Scalars['String']>;
  level___eq?: InputMaybe<Scalars['String']>;
  level___gt?: InputMaybe<Scalars['String']>;
  level___gte?: InputMaybe<Scalars['String']>;
  level___in?: InputMaybe<Array<Scalars['String']>>;
  level___lt?: InputMaybe<Scalars['String']>;
  level___lte?: InputMaybe<Scalars['String']>;
  level___ne?: InputMaybe<Scalars['String']>;
  level___not___contains?: InputMaybe<Scalars['String']>;
  level___not___ends_with?: InputMaybe<Scalars['String']>;
  level___not___in?: InputMaybe<Array<Scalars['String']>>;
  level___not___null?: InputMaybe<Scalars['Boolean']>;
  level___not___starts_with?: InputMaybe<Scalars['String']>;
  level___null?: InputMaybe<Scalars['Boolean']>;
  level___starts_with?: InputMaybe<Scalars['String']>;
  people_type___contains?: InputMaybe<Scalars['String']>;
  people_type___ends_with?: InputMaybe<Scalars['String']>;
  people_type___eq?: InputMaybe<Scalars['String']>;
  people_type___gt?: InputMaybe<Scalars['String']>;
  people_type___gte?: InputMaybe<Scalars['String']>;
  people_type___in?: InputMaybe<Array<Scalars['String']>>;
  people_type___lt?: InputMaybe<Scalars['String']>;
  people_type___lte?: InputMaybe<Scalars['String']>;
  people_type___ne?: InputMaybe<Scalars['String']>;
  people_type___not___contains?: InputMaybe<Scalars['String']>;
  people_type___not___ends_with?: InputMaybe<Scalars['String']>;
  people_type___not___in?: InputMaybe<Array<Scalars['String']>>;
  people_type___not___null?: InputMaybe<Scalars['Boolean']>;
  people_type___not___starts_with?: InputMaybe<Scalars['String']>;
  people_type___null?: InputMaybe<Scalars['Boolean']>;
  people_type___starts_with?: InputMaybe<Scalars['String']>;
  person_email___contains?: InputMaybe<Scalars['String']>;
  person_email___ends_with?: InputMaybe<Scalars['String']>;
  person_email___eq?: InputMaybe<Scalars['String']>;
  person_email___gt?: InputMaybe<Scalars['String']>;
  person_email___gte?: InputMaybe<Scalars['String']>;
  person_email___in?: InputMaybe<Array<Scalars['String']>>;
  person_email___lt?: InputMaybe<Scalars['String']>;
  person_email___lte?: InputMaybe<Scalars['String']>;
  person_email___ne?: InputMaybe<Scalars['String']>;
  person_email___not___contains?: InputMaybe<Scalars['String']>;
  person_email___not___ends_with?: InputMaybe<Scalars['String']>;
  person_email___not___in?: InputMaybe<Array<Scalars['String']>>;
  person_email___not___null?: InputMaybe<Scalars['Boolean']>;
  person_email___not___starts_with?: InputMaybe<Scalars['String']>;
  person_email___null?: InputMaybe<Scalars['Boolean']>;
  person_email___starts_with?: InputMaybe<Scalars['String']>;
  school___contains?: InputMaybe<Scalars['String']>;
  school___ends_with?: InputMaybe<Scalars['String']>;
  school___eq?: InputMaybe<Scalars['String']>;
  school___gt?: InputMaybe<Scalars['String']>;
  school___gte?: InputMaybe<Scalars['String']>;
  school___in?: InputMaybe<Array<Scalars['String']>>;
  school___lt?: InputMaybe<Scalars['String']>;
  school___lte?: InputMaybe<Scalars['String']>;
  school___ne?: InputMaybe<Scalars['String']>;
  school___not___contains?: InputMaybe<Scalars['String']>;
  school___not___ends_with?: InputMaybe<Scalars['String']>;
  school___not___in?: InputMaybe<Array<Scalars['String']>>;
  school___not___null?: InputMaybe<Scalars['Boolean']>;
  school___not___starts_with?: InputMaybe<Scalars['String']>;
  school___null?: InputMaybe<Scalars['Boolean']>;
  school___starts_with?: InputMaybe<Scalars['String']>;
  school_class___contains?: InputMaybe<Scalars['String']>;
  school_class___ends_with?: InputMaybe<Scalars['String']>;
  school_class___eq?: InputMaybe<Scalars['String']>;
  school_class___gt?: InputMaybe<Scalars['String']>;
  school_class___gte?: InputMaybe<Scalars['String']>;
  school_class___in?: InputMaybe<Array<Scalars['String']>>;
  school_class___lt?: InputMaybe<Scalars['String']>;
  school_class___lte?: InputMaybe<Scalars['String']>;
  school_class___ne?: InputMaybe<Scalars['String']>;
  school_class___not___contains?: InputMaybe<Scalars['String']>;
  school_class___not___ends_with?: InputMaybe<Scalars['String']>;
  school_class___not___in?: InputMaybe<Array<Scalars['String']>>;
  school_class___not___null?: InputMaybe<Scalars['Boolean']>;
  school_class___not___starts_with?: InputMaybe<Scalars['String']>;
  school_class___null?: InputMaybe<Scalars['Boolean']>;
  school_class___starts_with?: InputMaybe<Scalars['String']>;
  serial_id___eq?: InputMaybe<Scalars['Serial']>;
  serial_id___gt?: InputMaybe<Scalars['Serial']>;
  serial_id___gte?: InputMaybe<Scalars['Serial']>;
  serial_id___in?: InputMaybe<Array<InputMaybe<Scalars['Serial']>>>;
  serial_id___lt?: InputMaybe<Scalars['Serial']>;
  serial_id___lte?: InputMaybe<Scalars['Serial']>;
  serial_id___ne?: InputMaybe<Scalars['Serial']>;
  serial_id___not___in?: InputMaybe<Array<Scalars['Serial']>>;
  serial_id___not___null?: InputMaybe<Scalars['Boolean']>;
  serial_id___null?: InputMaybe<Scalars['Boolean']>;
  type_non_teaching_staff___eq?: InputMaybe<Scalars['Boolean']>;
  type_non_teaching_staff___ne?: InputMaybe<Scalars['Boolean']>;
  type_non_teaching_staff___not___null?: InputMaybe<Scalars['Boolean']>;
  type_non_teaching_staff___null?: InputMaybe<Scalars['Boolean']>;
  type_student___eq?: InputMaybe<Scalars['Boolean']>;
  type_student___ne?: InputMaybe<Scalars['Boolean']>;
  type_student___not___null?: InputMaybe<Scalars['Boolean']>;
  type_student___null?: InputMaybe<Scalars['Boolean']>;
  type_teacher___eq?: InputMaybe<Scalars['Boolean']>;
  type_teacher___ne?: InputMaybe<Scalars['Boolean']>;
  type_teacher___not___null?: InputMaybe<Scalars['Boolean']>;
  type_teacher___null?: InputMaybe<Scalars['Boolean']>;
  username___contains?: InputMaybe<Scalars['String']>;
  username___ends_with?: InputMaybe<Scalars['String']>;
  username___eq?: InputMaybe<Scalars['String']>;
  username___gt?: InputMaybe<Scalars['String']>;
  username___gte?: InputMaybe<Scalars['String']>;
  username___in?: InputMaybe<Array<Scalars['String']>>;
  username___lt?: InputMaybe<Scalars['String']>;
  username___lte?: InputMaybe<Scalars['String']>;
  username___ne?: InputMaybe<Scalars['String']>;
  username___not___contains?: InputMaybe<Scalars['String']>;
  username___not___ends_with?: InputMaybe<Scalars['String']>;
  username___not___in?: InputMaybe<Array<Scalars['String']>>;
  username___not___null?: InputMaybe<Scalars['Boolean']>;
  username___not___starts_with?: InputMaybe<Scalars['String']>;
  username___null?: InputMaybe<Scalars['Boolean']>;
  username___starts_with?: InputMaybe<Scalars['String']>;
  year_of_birth___eq?: InputMaybe<Scalars['Year']>;
  year_of_birth___gt?: InputMaybe<Scalars['Year']>;
  year_of_birth___gte?: InputMaybe<Scalars['Year']>;
  year_of_birth___in?: InputMaybe<Array<InputMaybe<Scalars['Year']>>>;
  year_of_birth___lt?: InputMaybe<Scalars['Year']>;
  year_of_birth___lte?: InputMaybe<Scalars['Year']>;
  year_of_birth___ne?: InputMaybe<Scalars['Year']>;
  year_of_birth___not___in?: InputMaybe<Array<Scalars['Year']>>;
  year_of_birth___not___null?: InputMaybe<Scalars['Boolean']>;
  year_of_birth___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class School_people. */
export type School_PeoplePage = {
  __typename?: 'School_peoplePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class School_people. */
  items: Array<School_People>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class School_people present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a School_peoplePage. */
export type School_PeoplePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<School_PeopleFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<School_PeopleCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<School_PeopleSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class School_people */
export type School_PeopleRoleObjects = {
  /** An array of objects of class School_people to be created. */
  create?: InputMaybe<Array<InputMaybe<School_PeopleCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class School_people to be updated. */
  update?: InputMaybe<Array<InputMaybe<School_PeopleUpdate>>>;
};

/** A data structure for the associated object of class School_people */
export type School_PeopleRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the associated objects of class School_people */
export type School_PeopleRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum School_PeopleSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  consent_withdrawn___ASC = 'consent_withdrawn___ASC',
  consent_withdrawn___DESC = 'consent_withdrawn___DESC',
  date_last_questionnaire___ASC = 'date_last_questionnaire___ASC',
  date_last_questionnaire___DESC = 'date_last_questionnaire___DESC',
  description___ASC = 'description___ASC',
  description___DESC = 'description___DESC',
  family_email___ASC = 'family_email___ASC',
  family_email___DESC = 'family_email___DESC',
  family_username___ASC = 'family_username___ASC',
  family_username___DESC = 'family_username___DESC',
  gender___ASC = 'gender___ASC',
  gender___DESC = 'gender___DESC',
  isLast_year___ASC = 'isLast_year___ASC',
  isLast_year___DESC = 'isLast_year___DESC',
  last_code___ASC = 'last_code___ASC',
  last_code___DESC = 'last_code___DESC',
  level___ASC = 'level___ASC',
  level___DESC = 'level___DESC',
  people_type___ASC = 'people_type___ASC',
  people_type___DESC = 'people_type___DESC',
  person_email___ASC = 'person_email___ASC',
  person_email___DESC = 'person_email___DESC',
  school___ASC = 'school___ASC',
  school___DESC = 'school___DESC',
  school_class___ASC = 'school_class___ASC',
  school_class___DESC = 'school_class___DESC',
  serial_id___ASC = 'serial_id___ASC',
  serial_id___DESC = 'serial_id___DESC',
  type_non_teaching_staff___ASC = 'type_non_teaching_staff___ASC',
  type_non_teaching_staff___DESC = 'type_non_teaching_staff___DESC',
  type_student___ASC = 'type_student___ASC',
  type_student___DESC = 'type_student___DESC',
  type_teacher___ASC = 'type_teacher___ASC',
  type_teacher___DESC = 'type_teacher___DESC',
  username___ASC = 'username___ASC',
  username___DESC = 'username___DESC',
  year_of_birth___ASC = 'year_of_birth___ASC',
  year_of_birth___DESC = 'year_of_birth___DESC'
}

/** A data structure for a single object of class School_people. */
export type School_PeopleUpdate = {
  _id: Scalars['ID'];
  /** The attribute code of School_people. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role school_people_(0,N) <--> (1)person_. */
  person_?: InputMaybe<PersonRoleRef>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  questionnaire_completion_?: InputMaybe<Questionnaire_CompletionRoleRefs>;
  /** The Association role school_class_(1) <--> (0,N)school_people_. */
  school_class_?: InputMaybe<School_ClassRoleRef>;
};

/** A data structure for objects of class School_people. */
export type School_PeopleUpdateBulk = {
  /** The attribute code of School_people. It is of type String. Max length: 255.  */
  code?: InputMaybe<Scalars['String']>;
  /** The Association role school_people_(0,N) <--> (1)person_. */
  person_?: InputMaybe<PersonRoleRef>;
  /** The Association role questionnaire_completion_(0,N) <--> (0,1)school_people_. */
  questionnaire_completion_?: InputMaybe<Questionnaire_CompletionRoleRefs>;
  /** The Association role school_class_(1) <--> (0,N)school_people_. */
  school_class_?: InputMaybe<School_ClassRoleRef>;
};

/** Represents an object of form class Set_times, as defined in the model. */
export type Set_Times = {
  __typename?: 'Set_times';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Set_times. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute t0 of Set_times. It is of type Boolean.  */
  t0?: Maybe<Scalars['Boolean']>;
  /** The attribute t1 of Set_times. It is of type Boolean.  */
  t1?: Maybe<Scalars['Boolean']>;
};

/** A data structure for a draft object of form class Set_times. */
export type Set_TimesDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute t0 of Set_times. It is of type Boolean.  */
  t0?: InputMaybe<Scalars['Boolean']>;
  /** The attribute t1 of Set_times. It is of type Boolean.  */
  t1?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for draft create objects of form class Set_times. */
export type Set_TimesDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute t0 of Set_times. It is of type Boolean.  */
  t0?: InputMaybe<Scalars['Boolean']>;
  /** The attribute t1 of Set_times. It is of type Boolean.  */
  t1?: InputMaybe<Scalars['Boolean']>;
};

/** Represents an object of class Study, as defined in the model. */
export type Study = {
  __typename?: 'Study';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Study. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The associated objects for the role center_. */
  center_?: Maybe<CenterPage>;
  /** The associable objects for the role center_. */
  center____associables?: Maybe<CenterPage>;
  /** The attribute code of Study. It is of type String. Max length: 255.  */
  code?: Maybe<Scalars['String']>;
  /** The attribute hcw of Study. It is of type Boolean.  */
  hcw?: Maybe<Scalars['Boolean']>;
  /** The attribute hospitalized of Study. It is of type Boolean.  */
  hospitalized?: Maybe<Scalars['Boolean']>;
  /** The attribute name of Study. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The attribute pasc of Study. It is of type Boolean.  */
  pasc?: Maybe<Scalars['Boolean']>;
  /** The attribute school of Study. It is of type Boolean.  */
  school?: Maybe<Scalars['Boolean']>;
};


/** Represents an object of class Study, as defined in the model. */
export type StudyCenter_Args = {
  options?: InputMaybe<CenterPageOptions>;
};


/** Represents an object of class Study, as defined in the model. */
export type StudyCenter____AssociablesArgs = {
  options?: InputMaybe<CenterPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Study.
 */
export type StudyCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  hcw?: InputMaybe<Scalars['Boolean']>;
  hospitalized?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pasc?: InputMaybe<Scalars['Boolean']>;
  school?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft associated objects of class Study */
export type StudyDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type Study.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type StudyFilter = {
  AND?: InputMaybe<Array<StudyFilter>>;
  NOT?: InputMaybe<StudyFilter>;
  OR?: InputMaybe<Array<StudyFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  code___contains?: InputMaybe<Scalars['String']>;
  code___ends_with?: InputMaybe<Scalars['String']>;
  code___eq?: InputMaybe<Scalars['String']>;
  code___gt?: InputMaybe<Scalars['String']>;
  code___gte?: InputMaybe<Scalars['String']>;
  code___in?: InputMaybe<Array<Scalars['String']>>;
  code___lt?: InputMaybe<Scalars['String']>;
  code___lte?: InputMaybe<Scalars['String']>;
  code___ne?: InputMaybe<Scalars['String']>;
  code___not___contains?: InputMaybe<Scalars['String']>;
  code___not___ends_with?: InputMaybe<Scalars['String']>;
  code___not___in?: InputMaybe<Array<Scalars['String']>>;
  code___not___null?: InputMaybe<Scalars['Boolean']>;
  code___not___starts_with?: InputMaybe<Scalars['String']>;
  code___null?: InputMaybe<Scalars['Boolean']>;
  code___starts_with?: InputMaybe<Scalars['String']>;
  hcw___eq?: InputMaybe<Scalars['Boolean']>;
  hcw___ne?: InputMaybe<Scalars['Boolean']>;
  hcw___not___null?: InputMaybe<Scalars['Boolean']>;
  hcw___null?: InputMaybe<Scalars['Boolean']>;
  hospitalized___eq?: InputMaybe<Scalars['Boolean']>;
  hospitalized___ne?: InputMaybe<Scalars['Boolean']>;
  hospitalized___not___null?: InputMaybe<Scalars['Boolean']>;
  hospitalized___null?: InputMaybe<Scalars['Boolean']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  pasc___eq?: InputMaybe<Scalars['Boolean']>;
  pasc___ne?: InputMaybe<Scalars['Boolean']>;
  pasc___not___null?: InputMaybe<Scalars['Boolean']>;
  pasc___null?: InputMaybe<Scalars['Boolean']>;
  school___eq?: InputMaybe<Scalars['Boolean']>;
  school___ne?: InputMaybe<Scalars['Boolean']>;
  school___not___null?: InputMaybe<Scalars['Boolean']>;
  school___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Study. */
export type StudyPage = {
  __typename?: 'StudyPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Study. */
  items: Array<Study>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Study present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a StudyPage. */
export type StudyPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<StudyFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<StudyCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<StudySort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated objects of class Study */
export type StudyRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum StudySort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  code___ASC = 'code___ASC',
  code___DESC = 'code___DESC',
  hcw___ASC = 'hcw___ASC',
  hcw___DESC = 'hcw___DESC',
  hospitalized___ASC = 'hospitalized___ASC',
  hospitalized___DESC = 'hospitalized___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  pasc___ASC = 'pasc___ASC',
  pasc___DESC = 'pasc___DESC',
  school___ASC = 'school___ASC',
  school___DESC = 'school___DESC'
}

/** Represents an object of class Symptom, as defined in the model. */
export type Symptom = {
  __typename?: 'Symptom';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Symptom. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute icd10 of Symptom. It is of type String. Max length: 255.  */
  icd10?: Maybe<Scalars['String']>;
  /** The attribute langs of Symptom. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)symptom_lang_ lang. Cannot be null. */
  langs?: Maybe<Scalars['String']>;
  /** The attribute name of Symptom. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
  /** The attribute pasc_visit of Symptom. It is of type Boolean.  */
  pasc_visit?: Maybe<Scalars['Boolean']>;
  /** The attribute symptom of Symptom. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated) [if(containsCS(Symptom.langs,ifNull(__CurrentUser.lang,"English")), __CurrentUser.lang= Symptom_lang.lang,Symptom_lang.lang="English")]symptom_lang_ symptom. Cannot be null. */
  symptom?: Maybe<Scalars['String']>;
  /** The associated objects for the role symptom_lang_. */
  symptom_lang_?: Maybe<Symptom_LangPage>;
  /** The attribute symptom_onset of Symptom. It is of type Boolean.  */
  symptom_onset?: Maybe<Scalars['Boolean']>;
  /** The attribute weekly_quest of Symptom. It is of type Boolean.  */
  weekly_quest?: Maybe<Scalars['Boolean']>;
};


/** Represents an object of class Symptom, as defined in the model. */
export type SymptomSymptom_Lang_Args = {
  options?: InputMaybe<Symptom_LangPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Symptom.
 */
export type SymptomCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  icd10?: InputMaybe<Scalars['String']>;
  langs?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pasc_visit?: InputMaybe<Scalars['Boolean']>;
  symptom?: InputMaybe<Scalars['String']>;
  symptom_onset?: InputMaybe<Scalars['Boolean']>;
  weekly_quest?: InputMaybe<Scalars['Boolean']>;
};

/** A data structure for the draft associated objects of class Symptom */
export type SymptomDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type Symptom.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type SymptomFilter = {
  AND?: InputMaybe<Array<SymptomFilter>>;
  NOT?: InputMaybe<SymptomFilter>;
  OR?: InputMaybe<Array<SymptomFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  icd10___contains?: InputMaybe<Scalars['String']>;
  icd10___ends_with?: InputMaybe<Scalars['String']>;
  icd10___eq?: InputMaybe<Scalars['String']>;
  icd10___gt?: InputMaybe<Scalars['String']>;
  icd10___gte?: InputMaybe<Scalars['String']>;
  icd10___in?: InputMaybe<Array<Scalars['String']>>;
  icd10___lt?: InputMaybe<Scalars['String']>;
  icd10___lte?: InputMaybe<Scalars['String']>;
  icd10___ne?: InputMaybe<Scalars['String']>;
  icd10___not___contains?: InputMaybe<Scalars['String']>;
  icd10___not___ends_with?: InputMaybe<Scalars['String']>;
  icd10___not___in?: InputMaybe<Array<Scalars['String']>>;
  icd10___not___null?: InputMaybe<Scalars['Boolean']>;
  icd10___not___starts_with?: InputMaybe<Scalars['String']>;
  icd10___null?: InputMaybe<Scalars['Boolean']>;
  icd10___starts_with?: InputMaybe<Scalars['String']>;
  langs___contains?: InputMaybe<Scalars['String']>;
  langs___ends_with?: InputMaybe<Scalars['String']>;
  langs___eq?: InputMaybe<Scalars['String']>;
  langs___gt?: InputMaybe<Scalars['String']>;
  langs___gte?: InputMaybe<Scalars['String']>;
  langs___in?: InputMaybe<Array<Scalars['String']>>;
  langs___lt?: InputMaybe<Scalars['String']>;
  langs___lte?: InputMaybe<Scalars['String']>;
  langs___ne?: InputMaybe<Scalars['String']>;
  langs___not___contains?: InputMaybe<Scalars['String']>;
  langs___not___ends_with?: InputMaybe<Scalars['String']>;
  langs___not___in?: InputMaybe<Array<Scalars['String']>>;
  langs___not___null?: InputMaybe<Scalars['Boolean']>;
  langs___not___starts_with?: InputMaybe<Scalars['String']>;
  langs___null?: InputMaybe<Scalars['Boolean']>;
  langs___starts_with?: InputMaybe<Scalars['String']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
  pasc_visit___eq?: InputMaybe<Scalars['Boolean']>;
  pasc_visit___ne?: InputMaybe<Scalars['Boolean']>;
  pasc_visit___not___null?: InputMaybe<Scalars['Boolean']>;
  pasc_visit___null?: InputMaybe<Scalars['Boolean']>;
  symptom___contains?: InputMaybe<Scalars['String']>;
  symptom___ends_with?: InputMaybe<Scalars['String']>;
  symptom___eq?: InputMaybe<Scalars['String']>;
  symptom___gt?: InputMaybe<Scalars['String']>;
  symptom___gte?: InputMaybe<Scalars['String']>;
  symptom___in?: InputMaybe<Array<Scalars['String']>>;
  symptom___lt?: InputMaybe<Scalars['String']>;
  symptom___lte?: InputMaybe<Scalars['String']>;
  symptom___ne?: InputMaybe<Scalars['String']>;
  symptom___not___contains?: InputMaybe<Scalars['String']>;
  symptom___not___ends_with?: InputMaybe<Scalars['String']>;
  symptom___not___in?: InputMaybe<Array<Scalars['String']>>;
  symptom___not___null?: InputMaybe<Scalars['Boolean']>;
  symptom___not___starts_with?: InputMaybe<Scalars['String']>;
  symptom___null?: InputMaybe<Scalars['Boolean']>;
  symptom___starts_with?: InputMaybe<Scalars['String']>;
  symptom_onset___eq?: InputMaybe<Scalars['Boolean']>;
  symptom_onset___ne?: InputMaybe<Scalars['Boolean']>;
  symptom_onset___not___null?: InputMaybe<Scalars['Boolean']>;
  symptom_onset___null?: InputMaybe<Scalars['Boolean']>;
  weekly_quest___eq?: InputMaybe<Scalars['Boolean']>;
  weekly_quest___ne?: InputMaybe<Scalars['Boolean']>;
  weekly_quest___not___null?: InputMaybe<Scalars['Boolean']>;
  weekly_quest___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Symptom. */
export type SymptomPage = {
  __typename?: 'SymptomPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Symptom. */
  items: Array<Symptom>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Symptom present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a SymptomPage. */
export type SymptomPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<SymptomFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<SymptomCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<SymptomSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated objects of class Symptom */
export type SymptomRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum SymptomSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  icd10___ASC = 'icd10___ASC',
  icd10___DESC = 'icd10___DESC',
  langs___ASC = 'langs___ASC',
  langs___DESC = 'langs___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC',
  pasc_visit___ASC = 'pasc_visit___ASC',
  pasc_visit___DESC = 'pasc_visit___DESC',
  symptom___ASC = 'symptom___ASC',
  symptom___DESC = 'symptom___DESC',
  symptom_onset___ASC = 'symptom_onset___ASC',
  symptom_onset___DESC = 'symptom_onset___DESC',
  weekly_quest___ASC = 'weekly_quest___ASC',
  weekly_quest___DESC = 'weekly_quest___DESC'
}

/** Represents an object of class Symptom_lang, as defined in the model. */
export type Symptom_Lang = {
  __typename?: 'Symptom_lang';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Symptom_lang. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute lang of Symptom_lang. It is of type String. Path: lang_ name. Cannot be null. */
  lang?: Maybe<Scalars['String']>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
  /** The attribute symptom of Symptom_lang. It is of type String. Max length: 255. Cannot be null. */
  symptom?: Maybe<Scalars['String']>;
  /** The associated object for the role symptom_. */
  symptom_?: Maybe<Symptom>;
};


/** Represents an object of class Symptom_lang, as defined in the model. */
export type Symptom_LangLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};

/** A data structure for objects of Symptom_lang. */
export type Symptom_LangCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role symptom_lang_(0,N) --> (1)lang_. */
  lang_: Scalars['ID'];
  /** The attribute symptom of Symptom_lang. It is of type String!. Max length: 255. Cannot be null. */
  symptom: Scalars['String'];
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Symptom_lang.
 */
export type Symptom_LangCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  lang?: InputMaybe<Scalars['String']>;
  symptom?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft object of class Symptom_lang. */
export type Symptom_LangDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The Association role symptom_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute symptom of Symptom_lang. It is of type String. Max length: 255. Cannot be null. */
  symptom?: InputMaybe<Scalars['String']>;
};

/** A data structure for draft create objects of class Symptom_lang. */
export type Symptom_LangDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The Association role symptom_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<Scalars['ID']>;
  /** The attribute symptom of Symptom_lang. It is of type String. Max length: 255. Cannot be null. */
  symptom?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part objects of class Symptom_lang */
export type Symptom_LangDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Symptom_lang to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Symptom_LangDraft>>>;
};

/** A data structure for a draft update object of class Symptom_lang. */
export type Symptom_LangDraftUpdate = {
  _id: Scalars['ID'];
  /** The Association role symptom_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute symptom of Symptom_lang. It is of type String. Max length: 255. Cannot be null. */
  symptom?: InputMaybe<Scalars['String']>;
};

/** A data structure for a draft update objects of class Symptom_lang. */
export type Symptom_LangDraftUpdateBulk = {
  /** The Association role symptom_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute symptom of Symptom_lang. It is of type String. Max length: 255. Cannot be null. */
  symptom?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft part objects of class Symptom_lang */
export type Symptom_LangDraftUpdateRoleObjects = {
  /** An array of objects of class Symptom_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Symptom_LangDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Symptom_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Symptom_LangDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Symptom_lang.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Symptom_LangFilter = {
  AND?: InputMaybe<Array<Symptom_LangFilter>>;
  NOT?: InputMaybe<Symptom_LangFilter>;
  OR?: InputMaybe<Array<Symptom_LangFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  lang___contains?: InputMaybe<Scalars['String']>;
  lang___ends_with?: InputMaybe<Scalars['String']>;
  lang___eq?: InputMaybe<Scalars['String']>;
  lang___gt?: InputMaybe<Scalars['String']>;
  lang___gte?: InputMaybe<Scalars['String']>;
  lang___in?: InputMaybe<Array<Scalars['String']>>;
  lang___lt?: InputMaybe<Scalars['String']>;
  lang___lte?: InputMaybe<Scalars['String']>;
  lang___ne?: InputMaybe<Scalars['String']>;
  lang___not___contains?: InputMaybe<Scalars['String']>;
  lang___not___ends_with?: InputMaybe<Scalars['String']>;
  lang___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang___not___null?: InputMaybe<Scalars['Boolean']>;
  lang___not___starts_with?: InputMaybe<Scalars['String']>;
  lang___null?: InputMaybe<Scalars['Boolean']>;
  lang___starts_with?: InputMaybe<Scalars['String']>;
  symptom___contains?: InputMaybe<Scalars['String']>;
  symptom___ends_with?: InputMaybe<Scalars['String']>;
  symptom___eq?: InputMaybe<Scalars['String']>;
  symptom___gt?: InputMaybe<Scalars['String']>;
  symptom___gte?: InputMaybe<Scalars['String']>;
  symptom___in?: InputMaybe<Array<Scalars['String']>>;
  symptom___lt?: InputMaybe<Scalars['String']>;
  symptom___lte?: InputMaybe<Scalars['String']>;
  symptom___ne?: InputMaybe<Scalars['String']>;
  symptom___not___contains?: InputMaybe<Scalars['String']>;
  symptom___not___ends_with?: InputMaybe<Scalars['String']>;
  symptom___not___in?: InputMaybe<Array<Scalars['String']>>;
  symptom___not___null?: InputMaybe<Scalars['Boolean']>;
  symptom___not___starts_with?: InputMaybe<Scalars['String']>;
  symptom___null?: InputMaybe<Scalars['Boolean']>;
  symptom___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Symptom_lang. */
export type Symptom_LangPage = {
  __typename?: 'Symptom_langPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Symptom_lang. */
  items: Array<Symptom_Lang>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Symptom_lang present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Symptom_langPage. */
export type Symptom_LangPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Symptom_LangFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Symptom_LangCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Symptom_LangSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Symptom_lang */
export type Symptom_LangRoleObjects = {
  /** An array of objects of class Symptom_lang to be created. */
  create?: InputMaybe<Array<InputMaybe<Symptom_LangCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Symptom_lang to be updated. */
  update?: InputMaybe<Array<InputMaybe<Symptom_LangUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Symptom_LangSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  lang___ASC = 'lang___ASC',
  lang___DESC = 'lang___DESC',
  symptom___ASC = 'symptom___ASC',
  symptom___DESC = 'symptom___DESC'
}

/** A data structure for a single object of class Symptom_lang. */
export type Symptom_LangUpdate = {
  _id: Scalars['ID'];
  /** The Association role symptom_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute symptom of Symptom_lang. It is of type String. Max length: 255. Cannot be null. */
  symptom?: InputMaybe<Scalars['String']>;
};

/** A data structure for objects of class Symptom_lang. */
export type Symptom_LangUpdateBulk = {
  /** The Association role symptom_lang_(0,N) --> (1)lang_. */
  lang_?: InputMaybe<LangRoleRef>;
  /** The attribute symptom of Symptom_lang. It is of type String. Max length: 255. Cannot be null. */
  symptom?: InputMaybe<Scalars['String']>;
};

/** Represents an object of class Template, as defined in the model. */
export type Template = {
  __typename?: 'Template';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _createdon of Template. It is of type Datetime @datetimeFormat. Cannot be null. */
  _createdon?: Maybe<Scalars['Datetime']>;
  /** The attribute _id of Template. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /**
   * The attribute file of Template. It is of type FileInfo. Supported extensions: zip
   * Max file size: 20971520. Cannot be null.
   */
  file?: Maybe<FileInfo>;
  /** The attribute notes of Template. It is of type Text.  */
  notes?: Maybe<Scalars['Text']>;
};


/** Represents an object of class Template, as defined in the model. */
export type Template_CreatedonArgs = {
  format?: InputMaybe<Scalars['String']>;
};

/** The result of the Template___updateBulk mutation on objects of class Template. */
export type TemplateBulkResult = {
  __typename?: 'TemplateBulkResult';
  /** An array of objects of class Template affected by the mutation. */
  items: Array<Template>;
  /** The total count of objects affected by the mutation, */
  totalCount: Scalars['Int'];
};

/** A data structure for objects of Template. */
export type TemplateCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /**
   * The attribute file of Template. It is of type PendingFileReference!. Supported extensions: zip
   * Max file size: 20971520. Cannot be null.
   */
  file: PendingFileReference;
  /** The attribute notes of Template. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Template.
 */
export type TemplateCursor = {
  _createdon?: InputMaybe<Scalars['Datetime']>;
  _id?: InputMaybe<Scalars['ID']>;
  file?: InputMaybe<PendingFileReference>;
  notes?: InputMaybe<Scalars['Text']>;
};

/** A data structure for a draft object of class Template. */
export type TemplateDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /**
   * The attribute file of Template. It is of type PendingFileReference. Supported extensions: zip
   * Max file size: 20971520. Cannot be null.
   */
  file?: InputMaybe<PendingFileReference>;
  /** The attribute notes of Template. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
};

/** A data structure for draft create objects of class Template. */
export type TemplateDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /**
   * The attribute file of Template. It is of type PendingFileReference. Supported extensions: zip
   * Max file size: 20971520. Cannot be null.
   */
  file?: InputMaybe<PendingFileReference>;
  /** The attribute notes of Template. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
};

/** A data structure for a draft update object of class Template. */
export type TemplateDraftUpdate = {
  _id: Scalars['ID'];
  /**
   * The attribute file of Template. It is of type PendingFileReference. Supported extensions: zip
   * Max file size: 20971520. Cannot be null.
   */
  file?: InputMaybe<PendingFileReference>;
  /** The attribute notes of Template. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
};

/** A data structure for a draft update objects of class Template. */
export type TemplateDraftUpdateBulk = {
  /**
   * The attribute file of Template. It is of type PendingFileReference. Supported extensions: zip
   * Max file size: 20971520. Cannot be null.
   */
  file?: InputMaybe<PendingFileReference>;
  /** The attribute notes of Template. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
};

/**
 * Define a specific filter for objects of type Template.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type TemplateFilter = {
  AND?: InputMaybe<Array<TemplateFilter>>;
  NOT?: InputMaybe<TemplateFilter>;
  OR?: InputMaybe<Array<TemplateFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _createdon___eq?: InputMaybe<Scalars['Datetime']>;
  _createdon___gt?: InputMaybe<Scalars['Datetime']>;
  _createdon___gte?: InputMaybe<Scalars['Datetime']>;
  _createdon___in?: InputMaybe<Array<InputMaybe<Scalars['Datetime']>>>;
  _createdon___lt?: InputMaybe<Scalars['Datetime']>;
  _createdon___lte?: InputMaybe<Scalars['Datetime']>;
  _createdon___ne?: InputMaybe<Scalars['Datetime']>;
  _createdon___not___in?: InputMaybe<Array<Scalars['Datetime']>>;
  _createdon___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdon___null?: InputMaybe<Scalars['Boolean']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  file___eq?: InputMaybe<PendingFileReference>;
  file___ne?: InputMaybe<PendingFileReference>;
  file___not___null?: InputMaybe<Scalars['Boolean']>;
  file___null?: InputMaybe<Scalars['Boolean']>;
  notes___contains?: InputMaybe<Scalars['Text']>;
  notes___ends_with?: InputMaybe<Scalars['Text']>;
  notes___eq?: InputMaybe<Scalars['Text']>;
  notes___gt?: InputMaybe<Scalars['Text']>;
  notes___gte?: InputMaybe<Scalars['Text']>;
  notes___in?: InputMaybe<Array<Scalars['Text']>>;
  notes___lt?: InputMaybe<Scalars['Text']>;
  notes___lte?: InputMaybe<Scalars['Text']>;
  notes___ne?: InputMaybe<Scalars['Text']>;
  notes___not___contains?: InputMaybe<Scalars['Text']>;
  notes___not___ends_with?: InputMaybe<Scalars['Text']>;
  notes___not___in?: InputMaybe<Array<Scalars['Text']>>;
  notes___not___null?: InputMaybe<Scalars['Boolean']>;
  notes___not___starts_with?: InputMaybe<Scalars['Text']>;
  notes___null?: InputMaybe<Scalars['Boolean']>;
  notes___starts_with?: InputMaybe<Scalars['Text']>;
};

/** Represents a collection of objects of class Template. */
export type TemplatePage = {
  __typename?: 'TemplatePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Template. */
  items: Array<Template>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Template present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a TemplatePage. */
export type TemplatePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<TemplateFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<TemplateCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<TemplateSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum TemplateSort {
  _createdon___ASC = '_createdon___ASC',
  _createdon___DESC = '_createdon___DESC',
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  file___ASC = 'file___ASC',
  file___DESC = 'file___DESC',
  notes___ASC = 'notes___ASC',
  notes___DESC = 'notes___DESC'
}

/** A data structure for a single object of class Template. */
export type TemplateUpdate = {
  _id: Scalars['ID'];
  /**
   * The attribute file of Template. It is of type PendingFileReference. Supported extensions: zip
   * Max file size: 20971520. Cannot be null.
   */
  file?: InputMaybe<PendingFileReference>;
  /** The attribute notes of Template. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
};

/** A data structure for objects of class Template. */
export type TemplateUpdateBulk = {
  /**
   * The attribute file of Template. It is of type PendingFileReference. Supported extensions: zip
   * Max file size: 20971520. Cannot be null.
   */
  file?: InputMaybe<PendingFileReference>;
  /** The attribute notes of Template. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
};

/** Represents an object of class Treatment, as defined in the model. */
export type Treatment = {
  __typename?: 'Treatment';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Treatment. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute dosage of Treatment. It is of type Real @realFormat.  */
  dosage?: Maybe<Scalars['Real']>;
  /** The attribute drug of Treatment. It is of type String. Path: drug_ name. Cannot be null. */
  drug?: Maybe<Scalars['String']>;
  /** The associated object for the role drug_. */
  drug_?: Maybe<Drug>;
  /** The associable objects for the role drug_. */
  drug____associables?: Maybe<DrugPage>;
  /** The attribute drug_other of Treatment. It is of type Boolean. Path: drug_ other.  */
  drug_other?: Maybe<Scalars['Boolean']>;
  /** The attribute notes of Treatment. It is of type Text.  */
  notes?: Maybe<Scalars['Text']>;
  /** The attribute other_drug of Treatment. It is of type String. Max length: 255.  */
  other_drug?: Maybe<Scalars['String']>;
  /** The associated object for the role patient_. */
  patient_?: Maybe<Patient>;
  /** The attribute start_date of Treatment. It is of type Date @dateFormat.  */
  start_date?: Maybe<Scalars['Date']>;
};


/** Represents an object of class Treatment, as defined in the model. */
export type TreatmentDosageArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Treatment, as defined in the model. */
export type TreatmentDrug____AssociablesArgs = {
  options?: InputMaybe<DrugPageOptions>;
};


/** Represents an object of class Treatment, as defined in the model. */
export type TreatmentStart_DateArgs = {
  format?: InputMaybe<Scalars['String']>;
};

/** A data structure for objects of Treatment. */
export type TreatmentCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute dosage of Treatment. It is of type Real.  */
  dosage?: InputMaybe<Scalars['Real']>;
  /** The Association role treatment_(0,N) --> (1)drug_. */
  drug_: Scalars['ID'];
  /** The attribute notes of Treatment. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute other_drug of Treatment. It is of type String. Max length: 255.  */
  other_drug?: InputMaybe<Scalars['String']>;
  /** The attribute start_date of Treatment. It is of type Date.  */
  start_date?: InputMaybe<Scalars['Date']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Treatment.
 */
export type TreatmentCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  dosage?: InputMaybe<Scalars['Real']>;
  drug?: InputMaybe<Scalars['String']>;
  drug_other?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['Text']>;
  other_drug?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['Date']>;
};

/** A data structure for a draft object of class Treatment. */
export type TreatmentDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute dosage of Treatment. It is of type Real.  */
  dosage?: InputMaybe<Scalars['Real']>;
  /** The Association role treatment_(0,N) --> (1)drug_. */
  drug_?: InputMaybe<Scalars['ID']>;
  /** The attribute notes of Treatment. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute other_drug of Treatment. It is of type String. Max length: 255.  */
  other_drug?: InputMaybe<Scalars['String']>;
  /** The attribute start_date of Treatment. It is of type Date.  */
  start_date?: InputMaybe<Scalars['Date']>;
};

/** A data structure for draft create objects of class Treatment. */
export type TreatmentDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute dosage of Treatment. It is of type Real.  */
  dosage?: InputMaybe<Scalars['Real']>;
  /** The Association role treatment_(0,N) --> (1)drug_. */
  drug_?: InputMaybe<Scalars['ID']>;
  /** The attribute notes of Treatment. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute other_drug of Treatment. It is of type String. Max length: 255.  */
  other_drug?: InputMaybe<Scalars['String']>;
  /** The attribute start_date of Treatment. It is of type Date.  */
  start_date?: InputMaybe<Scalars['Date']>;
};

/** A data structure for the draft part objects of class Treatment */
export type TreatmentDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Treatment to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<TreatmentDraft>>>;
};

/** A data structure for a draft update object of class Treatment. */
export type TreatmentDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute dosage of Treatment. It is of type Real.  */
  dosage?: InputMaybe<Scalars['Real']>;
  /** The Association role treatment_(0,N) --> (1)drug_. */
  drug_?: InputMaybe<DrugRoleRef>;
  /** The attribute notes of Treatment. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute other_drug of Treatment. It is of type String. Max length: 255.  */
  other_drug?: InputMaybe<Scalars['String']>;
  /** The attribute start_date of Treatment. It is of type Date.  */
  start_date?: InputMaybe<Scalars['Date']>;
};

/** A data structure for a draft update objects of class Treatment. */
export type TreatmentDraftUpdateBulk = {
  /** The attribute dosage of Treatment. It is of type Real.  */
  dosage?: InputMaybe<Scalars['Real']>;
  /** The Association role treatment_(0,N) --> (1)drug_. */
  drug_?: InputMaybe<DrugRoleRef>;
  /** The attribute notes of Treatment. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute other_drug of Treatment. It is of type String. Max length: 255.  */
  other_drug?: InputMaybe<Scalars['String']>;
  /** The attribute start_date of Treatment. It is of type Date.  */
  start_date?: InputMaybe<Scalars['Date']>;
};

/** A data structure for the draft part objects of class Treatment */
export type TreatmentDraftUpdateRoleObjects = {
  /** An array of objects of class Treatment to be created. */
  create?: InputMaybe<Array<InputMaybe<TreatmentDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Treatment to be updated. */
  update?: InputMaybe<Array<InputMaybe<TreatmentDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Treatment.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type TreatmentFilter = {
  AND?: InputMaybe<Array<TreatmentFilter>>;
  NOT?: InputMaybe<TreatmentFilter>;
  OR?: InputMaybe<Array<TreatmentFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  dosage___eq?: InputMaybe<Scalars['Real']>;
  dosage___gt?: InputMaybe<Scalars['Real']>;
  dosage___gte?: InputMaybe<Scalars['Real']>;
  dosage___in?: InputMaybe<Array<InputMaybe<Scalars['Real']>>>;
  dosage___lt?: InputMaybe<Scalars['Real']>;
  dosage___lte?: InputMaybe<Scalars['Real']>;
  dosage___ne?: InputMaybe<Scalars['Real']>;
  dosage___not___in?: InputMaybe<Array<Scalars['Real']>>;
  dosage___not___null?: InputMaybe<Scalars['Boolean']>;
  dosage___null?: InputMaybe<Scalars['Boolean']>;
  drug___contains?: InputMaybe<Scalars['String']>;
  drug___ends_with?: InputMaybe<Scalars['String']>;
  drug___eq?: InputMaybe<Scalars['String']>;
  drug___gt?: InputMaybe<Scalars['String']>;
  drug___gte?: InputMaybe<Scalars['String']>;
  drug___in?: InputMaybe<Array<Scalars['String']>>;
  drug___lt?: InputMaybe<Scalars['String']>;
  drug___lte?: InputMaybe<Scalars['String']>;
  drug___ne?: InputMaybe<Scalars['String']>;
  drug___not___contains?: InputMaybe<Scalars['String']>;
  drug___not___ends_with?: InputMaybe<Scalars['String']>;
  drug___not___in?: InputMaybe<Array<Scalars['String']>>;
  drug___not___null?: InputMaybe<Scalars['Boolean']>;
  drug___not___starts_with?: InputMaybe<Scalars['String']>;
  drug___null?: InputMaybe<Scalars['Boolean']>;
  drug___starts_with?: InputMaybe<Scalars['String']>;
  drug_other___eq?: InputMaybe<Scalars['Boolean']>;
  drug_other___ne?: InputMaybe<Scalars['Boolean']>;
  drug_other___not___null?: InputMaybe<Scalars['Boolean']>;
  drug_other___null?: InputMaybe<Scalars['Boolean']>;
  notes___contains?: InputMaybe<Scalars['Text']>;
  notes___ends_with?: InputMaybe<Scalars['Text']>;
  notes___eq?: InputMaybe<Scalars['Text']>;
  notes___gt?: InputMaybe<Scalars['Text']>;
  notes___gte?: InputMaybe<Scalars['Text']>;
  notes___in?: InputMaybe<Array<Scalars['Text']>>;
  notes___lt?: InputMaybe<Scalars['Text']>;
  notes___lte?: InputMaybe<Scalars['Text']>;
  notes___ne?: InputMaybe<Scalars['Text']>;
  notes___not___contains?: InputMaybe<Scalars['Text']>;
  notes___not___ends_with?: InputMaybe<Scalars['Text']>;
  notes___not___in?: InputMaybe<Array<Scalars['Text']>>;
  notes___not___null?: InputMaybe<Scalars['Boolean']>;
  notes___not___starts_with?: InputMaybe<Scalars['Text']>;
  notes___null?: InputMaybe<Scalars['Boolean']>;
  notes___starts_with?: InputMaybe<Scalars['Text']>;
  other_drug___contains?: InputMaybe<Scalars['String']>;
  other_drug___ends_with?: InputMaybe<Scalars['String']>;
  other_drug___eq?: InputMaybe<Scalars['String']>;
  other_drug___gt?: InputMaybe<Scalars['String']>;
  other_drug___gte?: InputMaybe<Scalars['String']>;
  other_drug___in?: InputMaybe<Array<Scalars['String']>>;
  other_drug___lt?: InputMaybe<Scalars['String']>;
  other_drug___lte?: InputMaybe<Scalars['String']>;
  other_drug___ne?: InputMaybe<Scalars['String']>;
  other_drug___not___contains?: InputMaybe<Scalars['String']>;
  other_drug___not___ends_with?: InputMaybe<Scalars['String']>;
  other_drug___not___in?: InputMaybe<Array<Scalars['String']>>;
  other_drug___not___null?: InputMaybe<Scalars['Boolean']>;
  other_drug___not___starts_with?: InputMaybe<Scalars['String']>;
  other_drug___null?: InputMaybe<Scalars['Boolean']>;
  other_drug___starts_with?: InputMaybe<Scalars['String']>;
  start_date___eq?: InputMaybe<Scalars['Date']>;
  start_date___gt?: InputMaybe<Scalars['Date']>;
  start_date___gte?: InputMaybe<Scalars['Date']>;
  start_date___in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  start_date___lt?: InputMaybe<Scalars['Date']>;
  start_date___lte?: InputMaybe<Scalars['Date']>;
  start_date___ne?: InputMaybe<Scalars['Date']>;
  start_date___not___in?: InputMaybe<Array<Scalars['Date']>>;
  start_date___not___null?: InputMaybe<Scalars['Boolean']>;
  start_date___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Treatment. */
export type TreatmentPage = {
  __typename?: 'TreatmentPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Treatment. */
  items: Array<Treatment>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Treatment present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a TreatmentPage. */
export type TreatmentPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<TreatmentFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<TreatmentCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<TreatmentSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Treatment */
export type TreatmentRoleObjects = {
  /** An array of objects of class Treatment to be created. */
  create?: InputMaybe<Array<InputMaybe<TreatmentCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Treatment to be updated. */
  update?: InputMaybe<Array<InputMaybe<TreatmentUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum TreatmentSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  dosage___ASC = 'dosage___ASC',
  dosage___DESC = 'dosage___DESC',
  drug___ASC = 'drug___ASC',
  drug___DESC = 'drug___DESC',
  drug_other___ASC = 'drug_other___ASC',
  drug_other___DESC = 'drug_other___DESC',
  notes___ASC = 'notes___ASC',
  notes___DESC = 'notes___DESC',
  other_drug___ASC = 'other_drug___ASC',
  other_drug___DESC = 'other_drug___DESC',
  start_date___ASC = 'start_date___ASC',
  start_date___DESC = 'start_date___DESC'
}

/** A data structure for a single object of class Treatment. */
export type TreatmentUpdate = {
  _id: Scalars['ID'];
  /** The attribute dosage of Treatment. It is of type Real.  */
  dosage?: InputMaybe<Scalars['Real']>;
  /** The Association role treatment_(0,N) --> (1)drug_. */
  drug_?: InputMaybe<DrugRoleRef>;
  /** The attribute notes of Treatment. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute other_drug of Treatment. It is of type String. Max length: 255.  */
  other_drug?: InputMaybe<Scalars['String']>;
  /** The attribute start_date of Treatment. It is of type Date.  */
  start_date?: InputMaybe<Scalars['Date']>;
};

/** A data structure for objects of class Treatment. */
export type TreatmentUpdateBulk = {
  /** The attribute dosage of Treatment. It is of type Real.  */
  dosage?: InputMaybe<Scalars['Real']>;
  /** The Association role treatment_(0,N) --> (1)drug_. */
  drug_?: InputMaybe<DrugRoleRef>;
  /** The attribute notes of Treatment. It is of type Text.  */
  notes?: InputMaybe<Scalars['Text']>;
  /** The attribute other_drug of Treatment. It is of type String. Max length: 255.  */
  other_drug?: InputMaybe<Scalars['String']>;
  /** The attribute start_date of Treatment. It is of type Date.  */
  start_date?: InputMaybe<Scalars['Date']>;
};

/** Represents an object of class User_type, as defined in the model. */
export type User_Type = {
  __typename?: 'User_type';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of User_type. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /**
   * The attribute name of User_type. It is of type String. Domain: Family OR Principal OR Teacher OR Student OR Patient OR Non_teaching.
   * Max length: 255. Cannot be null.
   */
  name?: Maybe<Scalars['String']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * User_type.
 */
export type User_TypeCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated objects of class User_type */
export type User_TypeDraftRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Define a specific filter for objects of type User_type.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type User_TypeFilter = {
  AND?: InputMaybe<Array<User_TypeFilter>>;
  NOT?: InputMaybe<User_TypeFilter>;
  OR?: InputMaybe<Array<User_TypeFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class User_type. */
export type User_TypePage = {
  __typename?: 'User_typePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class User_type. */
  items: Array<User_Type>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class User_type present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a User_typePage. */
export type User_TypePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<User_TypeFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<User_TypeCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<User_TypeSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated objects of class User_type */
export type User_TypeRoleRefs = {
  /** An array of ID to be set to the association. */
  add?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** An array of ID to be unset from the association. */
  remove?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current associated objects should be deleted. */
  removeAll?: InputMaybe<Scalars['Boolean']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum User_TypeSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC'
}

/** Represents an object of class Vaccine, as defined in the model. */
export type Vaccine = {
  __typename?: 'Vaccine';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Vaccine. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute covid of Vaccine. It is of type Boolean. Cannot be null. */
  covid?: Maybe<Scalars['Boolean']>;
  /** The attribute name of Vaccine. It is of type String. Max length: 255. Cannot be null. */
  name?: Maybe<Scalars['String']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Vaccine.
 */
export type VaccineCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  covid?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class Vaccine */
export type VaccineDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type Vaccine.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type VaccineFilter = {
  AND?: InputMaybe<Array<VaccineFilter>>;
  NOT?: InputMaybe<VaccineFilter>;
  OR?: InputMaybe<Array<VaccineFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  covid___eq?: InputMaybe<Scalars['Boolean']>;
  covid___ne?: InputMaybe<Scalars['Boolean']>;
  covid___not___null?: InputMaybe<Scalars['Boolean']>;
  covid___null?: InputMaybe<Scalars['Boolean']>;
  name___contains?: InputMaybe<Scalars['String']>;
  name___ends_with?: InputMaybe<Scalars['String']>;
  name___eq?: InputMaybe<Scalars['String']>;
  name___gt?: InputMaybe<Scalars['String']>;
  name___gte?: InputMaybe<Scalars['String']>;
  name___in?: InputMaybe<Array<Scalars['String']>>;
  name___lt?: InputMaybe<Scalars['String']>;
  name___lte?: InputMaybe<Scalars['String']>;
  name___ne?: InputMaybe<Scalars['String']>;
  name___not___contains?: InputMaybe<Scalars['String']>;
  name___not___ends_with?: InputMaybe<Scalars['String']>;
  name___not___in?: InputMaybe<Array<Scalars['String']>>;
  name___not___null?: InputMaybe<Scalars['Boolean']>;
  name___not___starts_with?: InputMaybe<Scalars['String']>;
  name___null?: InputMaybe<Scalars['Boolean']>;
  name___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class Vaccine. */
export type VaccinePage = {
  __typename?: 'VaccinePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Vaccine. */
  items: Array<Vaccine>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Vaccine present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a VaccinePage. */
export type VaccinePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<VaccineFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<VaccineCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<VaccineSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class Vaccine */
export type VaccineRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum VaccineSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  covid___ASC = 'covid___ASC',
  covid___DESC = 'covid___DESC',
  name___ASC = 'name___ASC',
  name___DESC = 'name___DESC'
}

/** Represents an object of class Vaccine_dose, as defined in the model. */
export type Vaccine_Dose = {
  __typename?: 'Vaccine_dose';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _id of Vaccine_dose. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute date of Vaccine_dose. It is of type Date @dateFormat.  */
  date?: Maybe<Scalars['Date']>;
  /**
   * The attribute date_res of Vaccine_dose. It is of type String. Domain: YMD OR YM OR Y.
   * Max length: 255.
   */
  date_res?: Maybe<Scalars['String']>;
  /** The attribute dose_number of Vaccine_dose. It is of type Int.  */
  dose_number?: Maybe<Scalars['Int']>;
  /** The attribute patient_vaccine_id of Vaccine_dose. It is of type Int. Path: patient_ __id. Cannot be null. */
  patient_vaccine_id?: Maybe<Scalars['Int']>;
  /** The associated object for the role person_infection. */
  person_infection?: Maybe<Person>;
  /** The attribute person_infection_id of Vaccine_dose. It is of type Int. Path: person_infection __id. Cannot be null. */
  person_infection_id?: Maybe<Scalars['Int']>;
  /** The associated object for the role person_vaccine. */
  person_vaccine?: Maybe<Person>;
  /** The attribute person_vaccine_id of Vaccine_dose. It is of type Int. Path: person_vaccine __id. Cannot be null. */
  person_vaccine_id?: Maybe<Scalars['Int']>;
  /** The attribute vaccine of Vaccine_dose. It is of type String. Path: vaccine_ name. Cannot be null. */
  vaccine?: Maybe<Scalars['String']>;
  /** The associated object for the role vaccine_. */
  vaccine_?: Maybe<Vaccine>;
  /** The associable objects for the role vaccine_. */
  vaccine____associables?: Maybe<VaccinePage>;
  /** The attribute vaccine_doses of Vaccine_dose. It is of type Int. Path: patient_ vaccine_doses.  */
  vaccine_doses?: Maybe<Scalars['Int']>;
};


/** Represents an object of class Vaccine_dose, as defined in the model. */
export type Vaccine_DoseDateArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class Vaccine_dose, as defined in the model. */
export type Vaccine_DoseVaccine____AssociablesArgs = {
  options?: InputMaybe<VaccinePageOptions>;
};

/** A data structure for objects of Vaccine_dose. */
export type Vaccine_DoseCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute date of Vaccine_dose. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /**
   * The attribute date_res of Vaccine_dose. It is of type String. Domain: YMD OR YM OR Y.
   * Max length: 255.
   */
  date_res?: InputMaybe<Scalars['String']>;
  /** The attribute dose_number of Vaccine_dose. It is of type Int.  */
  dose_number?: InputMaybe<Scalars['Int']>;
  /** The Association role vaccine_dose_(0,N) --> (0,1)vaccine_. */
  vaccine_?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * Vaccine_dose.
 */
export type Vaccine_DoseCursor = {
  _id?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
  date_res?: InputMaybe<Scalars['String']>;
  dose_number?: InputMaybe<Scalars['Int']>;
  patient_vaccine_id?: InputMaybe<Scalars['Int']>;
  person_infection_id?: InputMaybe<Scalars['Int']>;
  person_vaccine_id?: InputMaybe<Scalars['Int']>;
  vaccine?: InputMaybe<Scalars['String']>;
  vaccine_doses?: InputMaybe<Scalars['Int']>;
};

/** A data structure for a draft object of class Vaccine_dose. */
export type Vaccine_DoseDraft = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  _id?: InputMaybe<Scalars['ID']>;
  /** The attribute date of Vaccine_dose. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /**
   * The attribute date_res of Vaccine_dose. It is of type String. Domain: YMD OR YM OR Y.
   * Max length: 255.
   */
  date_res?: InputMaybe<Scalars['String']>;
  /** The attribute dose_number of Vaccine_dose. It is of type Int.  */
  dose_number?: InputMaybe<Scalars['Int']>;
  /** The Association role vaccine_dose_(0,N) --> (0,1)vaccine_. */
  vaccine_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for draft create objects of class Vaccine_dose. */
export type Vaccine_DoseDraftCreate = {
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: InputMaybe<Scalars['ID']>;
  /** The attribute date of Vaccine_dose. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /**
   * The attribute date_res of Vaccine_dose. It is of type String. Domain: YMD OR YM OR Y.
   * Max length: 255.
   */
  date_res?: InputMaybe<Scalars['String']>;
  /** The attribute dose_number of Vaccine_dose. It is of type Int.  */
  dose_number?: InputMaybe<Scalars['Int']>;
  /** The Association role vaccine_dose_(0,N) --> (0,1)vaccine_. */
  vaccine_?: InputMaybe<Scalars['ID']>;
};

/** A data structure for the draft part objects of class Vaccine_dose */
export type Vaccine_DoseDraftRoleObjects = {
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of object of class Vaccine_dose to be created or associated. */
  save?: InputMaybe<Array<InputMaybe<Vaccine_DoseDraft>>>;
};

/** A data structure for a draft update object of class Vaccine_dose. */
export type Vaccine_DoseDraftUpdate = {
  _id: Scalars['ID'];
  /** The attribute date of Vaccine_dose. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /**
   * The attribute date_res of Vaccine_dose. It is of type String. Domain: YMD OR YM OR Y.
   * Max length: 255.
   */
  date_res?: InputMaybe<Scalars['String']>;
  /** The attribute dose_number of Vaccine_dose. It is of type Int.  */
  dose_number?: InputMaybe<Scalars['Int']>;
  /** The Association role vaccine_dose_(0,N) --> (0,1)vaccine_. */
  vaccine_?: InputMaybe<VaccineRoleRef>;
};

/** A data structure for a draft update objects of class Vaccine_dose. */
export type Vaccine_DoseDraftUpdateBulk = {
  /** The attribute date of Vaccine_dose. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /**
   * The attribute date_res of Vaccine_dose. It is of type String. Domain: YMD OR YM OR Y.
   * Max length: 255.
   */
  date_res?: InputMaybe<Scalars['String']>;
  /** The attribute dose_number of Vaccine_dose. It is of type Int.  */
  dose_number?: InputMaybe<Scalars['Int']>;
  /** The Association role vaccine_dose_(0,N) --> (0,1)vaccine_. */
  vaccine_?: InputMaybe<VaccineRoleRef>;
};

/** A data structure for the draft part objects of class Vaccine_dose */
export type Vaccine_DoseDraftUpdateRoleObjects = {
  /** An array of objects of class Vaccine_dose to be created. */
  create?: InputMaybe<Array<InputMaybe<Vaccine_DoseDraftCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Vaccine_dose to be updated. */
  update?: InputMaybe<Array<InputMaybe<Vaccine_DoseDraftUpdate>>>;
};

/**
 * Define a specific filter for objects of type Vaccine_dose.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type Vaccine_DoseFilter = {
  AND?: InputMaybe<Array<Vaccine_DoseFilter>>;
  NOT?: InputMaybe<Vaccine_DoseFilter>;
  OR?: InputMaybe<Array<Vaccine_DoseFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  date___eq?: InputMaybe<Scalars['Date']>;
  date___gt?: InputMaybe<Scalars['Date']>;
  date___gte?: InputMaybe<Scalars['Date']>;
  date___in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  date___lt?: InputMaybe<Scalars['Date']>;
  date___lte?: InputMaybe<Scalars['Date']>;
  date___ne?: InputMaybe<Scalars['Date']>;
  date___not___in?: InputMaybe<Array<Scalars['Date']>>;
  date___not___null?: InputMaybe<Scalars['Boolean']>;
  date___null?: InputMaybe<Scalars['Boolean']>;
  date_res___contains?: InputMaybe<Scalars['String']>;
  date_res___ends_with?: InputMaybe<Scalars['String']>;
  date_res___eq?: InputMaybe<Scalars['String']>;
  date_res___gt?: InputMaybe<Scalars['String']>;
  date_res___gte?: InputMaybe<Scalars['String']>;
  date_res___in?: InputMaybe<Array<Scalars['String']>>;
  date_res___lt?: InputMaybe<Scalars['String']>;
  date_res___lte?: InputMaybe<Scalars['String']>;
  date_res___ne?: InputMaybe<Scalars['String']>;
  date_res___not___contains?: InputMaybe<Scalars['String']>;
  date_res___not___ends_with?: InputMaybe<Scalars['String']>;
  date_res___not___in?: InputMaybe<Array<Scalars['String']>>;
  date_res___not___null?: InputMaybe<Scalars['Boolean']>;
  date_res___not___starts_with?: InputMaybe<Scalars['String']>;
  date_res___null?: InputMaybe<Scalars['Boolean']>;
  date_res___starts_with?: InputMaybe<Scalars['String']>;
  dose_number___eq?: InputMaybe<Scalars['Int']>;
  dose_number___gt?: InputMaybe<Scalars['Int']>;
  dose_number___gte?: InputMaybe<Scalars['Int']>;
  dose_number___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  dose_number___lt?: InputMaybe<Scalars['Int']>;
  dose_number___lte?: InputMaybe<Scalars['Int']>;
  dose_number___ne?: InputMaybe<Scalars['Int']>;
  dose_number___not___in?: InputMaybe<Array<Scalars['Int']>>;
  dose_number___not___null?: InputMaybe<Scalars['Boolean']>;
  dose_number___null?: InputMaybe<Scalars['Boolean']>;
  patient_vaccine_id___eq?: InputMaybe<Scalars['Int']>;
  patient_vaccine_id___gt?: InputMaybe<Scalars['Int']>;
  patient_vaccine_id___gte?: InputMaybe<Scalars['Int']>;
  patient_vaccine_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  patient_vaccine_id___lt?: InputMaybe<Scalars['Int']>;
  patient_vaccine_id___lte?: InputMaybe<Scalars['Int']>;
  patient_vaccine_id___ne?: InputMaybe<Scalars['Int']>;
  patient_vaccine_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  patient_vaccine_id___not___null?: InputMaybe<Scalars['Boolean']>;
  patient_vaccine_id___null?: InputMaybe<Scalars['Boolean']>;
  person_infection_id___eq?: InputMaybe<Scalars['Int']>;
  person_infection_id___gt?: InputMaybe<Scalars['Int']>;
  person_infection_id___gte?: InputMaybe<Scalars['Int']>;
  person_infection_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  person_infection_id___lt?: InputMaybe<Scalars['Int']>;
  person_infection_id___lte?: InputMaybe<Scalars['Int']>;
  person_infection_id___ne?: InputMaybe<Scalars['Int']>;
  person_infection_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  person_infection_id___not___null?: InputMaybe<Scalars['Boolean']>;
  person_infection_id___null?: InputMaybe<Scalars['Boolean']>;
  person_vaccine_id___eq?: InputMaybe<Scalars['Int']>;
  person_vaccine_id___gt?: InputMaybe<Scalars['Int']>;
  person_vaccine_id___gte?: InputMaybe<Scalars['Int']>;
  person_vaccine_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  person_vaccine_id___lt?: InputMaybe<Scalars['Int']>;
  person_vaccine_id___lte?: InputMaybe<Scalars['Int']>;
  person_vaccine_id___ne?: InputMaybe<Scalars['Int']>;
  person_vaccine_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  person_vaccine_id___not___null?: InputMaybe<Scalars['Boolean']>;
  person_vaccine_id___null?: InputMaybe<Scalars['Boolean']>;
  vaccine___contains?: InputMaybe<Scalars['String']>;
  vaccine___ends_with?: InputMaybe<Scalars['String']>;
  vaccine___eq?: InputMaybe<Scalars['String']>;
  vaccine___gt?: InputMaybe<Scalars['String']>;
  vaccine___gte?: InputMaybe<Scalars['String']>;
  vaccine___in?: InputMaybe<Array<Scalars['String']>>;
  vaccine___lt?: InputMaybe<Scalars['String']>;
  vaccine___lte?: InputMaybe<Scalars['String']>;
  vaccine___ne?: InputMaybe<Scalars['String']>;
  vaccine___not___contains?: InputMaybe<Scalars['String']>;
  vaccine___not___ends_with?: InputMaybe<Scalars['String']>;
  vaccine___not___in?: InputMaybe<Array<Scalars['String']>>;
  vaccine___not___null?: InputMaybe<Scalars['Boolean']>;
  vaccine___not___starts_with?: InputMaybe<Scalars['String']>;
  vaccine___null?: InputMaybe<Scalars['Boolean']>;
  vaccine___starts_with?: InputMaybe<Scalars['String']>;
  vaccine_doses___eq?: InputMaybe<Scalars['Int']>;
  vaccine_doses___gt?: InputMaybe<Scalars['Int']>;
  vaccine_doses___gte?: InputMaybe<Scalars['Int']>;
  vaccine_doses___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  vaccine_doses___lt?: InputMaybe<Scalars['Int']>;
  vaccine_doses___lte?: InputMaybe<Scalars['Int']>;
  vaccine_doses___ne?: InputMaybe<Scalars['Int']>;
  vaccine_doses___not___in?: InputMaybe<Array<Scalars['Int']>>;
  vaccine_doses___not___null?: InputMaybe<Scalars['Boolean']>;
  vaccine_doses___null?: InputMaybe<Scalars['Boolean']>;
};

/** Represents a collection of objects of class Vaccine_dose. */
export type Vaccine_DosePage = {
  __typename?: 'Vaccine_dosePage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class Vaccine_dose. */
  items: Array<Vaccine_Dose>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class Vaccine_dose present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a Vaccine_dosePage. */
export type Vaccine_DosePageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<Vaccine_DoseFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<Vaccine_DoseCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<Vaccine_DoseSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the part objects of class Vaccine_dose */
export type Vaccine_DoseRoleObjects = {
  /** An array of objects of class Vaccine_dose to be created. */
  create?: InputMaybe<Array<InputMaybe<Vaccine_DoseCreate>>>;
  /** An array of ID to be deleted from the composition. */
  delete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** A boolean flag that specify if all the current part objects of should be deleted. */
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  /** An array of existing objects of class Vaccine_dose to be updated. */
  update?: InputMaybe<Array<InputMaybe<Vaccine_DoseUpdate>>>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum Vaccine_DoseSort {
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  date___ASC = 'date___ASC',
  date___DESC = 'date___DESC',
  date_res___ASC = 'date_res___ASC',
  date_res___DESC = 'date_res___DESC',
  dose_number___ASC = 'dose_number___ASC',
  dose_number___DESC = 'dose_number___DESC',
  patient_vaccine_id___ASC = 'patient_vaccine_id___ASC',
  patient_vaccine_id___DESC = 'patient_vaccine_id___DESC',
  person_infection_id___ASC = 'person_infection_id___ASC',
  person_infection_id___DESC = 'person_infection_id___DESC',
  person_vaccine_id___ASC = 'person_vaccine_id___ASC',
  person_vaccine_id___DESC = 'person_vaccine_id___DESC',
  vaccine___ASC = 'vaccine___ASC',
  vaccine___DESC = 'vaccine___DESC',
  vaccine_doses___ASC = 'vaccine_doses___ASC',
  vaccine_doses___DESC = 'vaccine_doses___DESC'
}

/** A data structure for a single object of class Vaccine_dose. */
export type Vaccine_DoseUpdate = {
  _id: Scalars['ID'];
  /** The attribute date of Vaccine_dose. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /**
   * The attribute date_res of Vaccine_dose. It is of type String. Domain: YMD OR YM OR Y.
   * Max length: 255.
   */
  date_res?: InputMaybe<Scalars['String']>;
  /** The attribute dose_number of Vaccine_dose. It is of type Int.  */
  dose_number?: InputMaybe<Scalars['Int']>;
  /** The Association role vaccine_dose_(0,N) --> (0,1)vaccine_. */
  vaccine_?: InputMaybe<VaccineRoleRef>;
};

/** A data structure for objects of class Vaccine_dose. */
export type Vaccine_DoseUpdateBulk = {
  /** The attribute date of Vaccine_dose. It is of type Date.  */
  date?: InputMaybe<Scalars['Date']>;
  /**
   * The attribute date_res of Vaccine_dose. It is of type String. Domain: YMD OR YM OR Y.
   * Max length: 255.
   */
  date_res?: InputMaybe<Scalars['String']>;
  /** The attribute dose_number of Vaccine_dose. It is of type Int.  */
  dose_number?: InputMaybe<Scalars['Int']>;
  /** The Association role vaccine_dose_(0,N) --> (0,1)vaccine_. */
  vaccine_?: InputMaybe<VaccineRoleRef>;
};

/** The result of the validate services. */
export type ValidationResult = {
  __typename?: 'ValidationResult';
  isValid: Scalars['Boolean'];
  issues: Array<Issue>;
};

/** Represents an object of class _User, as defined in the model. */
export type _User = {
  __typename?: '_User';
  /**
   * A special attribute, choosen by the client, useful to
   * uniquely reference not existing objects.
   */
  _clientId?: Maybe<Scalars['ID']>;
  /** The attribute _createdby of _User. It is of type String. Cannot be null. */
  _createdby?: Maybe<Scalars['String']>;
  /** The attribute _createdon of _User. It is of type Datetime @datetimeFormat. Cannot be null. */
  _createdon?: Maybe<Scalars['Datetime']>;
  /** The attribute _id of _User. It is of type ID. Cannot be null. */
  _id?: Maybe<Scalars['ID']>;
  /** The attribute admin of _User. It is of type Boolean. Cannot be null. */
  admin?: Maybe<Scalars['Boolean']>;
  /** The attribute cache of _User. It is of type Text.  */
  cache?: Maybe<Scalars['Text']>;
  /** The associated object for the role center_. */
  center_?: Maybe<Center>;
  /** The associable objects for the role center_. */
  center____associables?: Maybe<CenterPage>;
  /** The attribute center_name of _User. It is of type String. Path: center_ name. Cannot be null. */
  center_name?: Maybe<Scalars['String']>;
  /** The attribute country of _User. It is of type String. Path: center_ country. Cannot be null. */
  country?: Maybe<Scalars['String']>;
  /**
   * The attribute date_format of _User. It is of type String. Domain: dd/MM/yyyy OR yyyy-MM-dd OR yyyy/MM/dd OR MM/dd/yyyy OR MM-dd-yyyy.
   * Max length: 255.
   */
  date_format?: Maybe<Scalars['String']>;
  /** The attribute description of _User. It is of type Text.  */
  description?: Maybe<Scalars['Text']>;
  /** The attribute email of _User. It is of type String. Max length: 255. Cannot be null. */
  email?: Maybe<Scalars['String']>;
  /** The attribute enabled of _User. It is of type Boolean. Cannot be null. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The attribute family of _User. It is of type Boolean. Expression: 'profile="Family"'. Cannot be null. */
  family?: Maybe<Scalars['Boolean']>;
  /** The associated object for the role family_. */
  family_?: Maybe<Family>;
  /** The associable objects for the role family_. */
  family____associables?: Maybe<FamilyPage>;
  /** The attribute family_id of _User. It is of type Int. Path: family_ __id. Cannot be null. */
  family_id?: Maybe<Scalars['Int']>;
  /** The attribute family_student_level_current of _User. It is of type String. Path: CONCAT_DS_CS - Concat distinct (comma separated)family_.person_ school_people_level_current.  */
  family_student_level_current?: Maybe<Scalars['String']>;
  /** The attribute firstName of _User. It is of type String. Max length: 255.  */
  firstName?: Maybe<Scalars['String']>;
  /** The attribute hospital of _User. It is of type Boolean. Expression: 'profile="Hospital"'. Cannot be null. */
  hospital?: Maybe<Scalars['Boolean']>;
  /** The associated object for the role hospital_. */
  hospital_?: Maybe<Hospital>;
  /** The associable objects for the role hospital_. */
  hospital____associables?: Maybe<HospitalPage>;
  /** The attribute hospital_admin of _User. It is of type Boolean. Expression: 'profile="Hospital_admin"'. Cannot be null. */
  hospital_admin?: Maybe<Scalars['Boolean']>;
  /** The attribute hospital_id of _User. It is of type Int. Path: hospital_ __id. Cannot be null. */
  hospital_id?: Maybe<Scalars['Int']>;
  /** The attribute lab of _User. It is of type Boolean. Expression: 'profile="Lab" || profile="Lab_hosp"'. Cannot be null. */
  lab?: Maybe<Scalars['Boolean']>;
  /** The attribute lab_admin of _User. It is of type Boolean. Expression: 'profile="Lab_admin"'. Cannot be null. */
  lab_admin?: Maybe<Scalars['Boolean']>;
  /** The attribute lab_hospital_ids of _User. It is of type String. Path: laboratory_ hospital_ids. Cannot be null. */
  lab_hospital_ids?: Maybe<Scalars['String']>;
  /** The attribute lab_school_ids of _User. It is of type String. Path: laboratory_ school_ids. Cannot be null. */
  lab_school_ids?: Maybe<Scalars['String']>;
  /** The associated object for the role laboratory_. */
  laboratory_?: Maybe<Laboratory>;
  /** The associable objects for the role laboratory_. */
  laboratory____associables?: Maybe<LaboratoryPage>;
  /** The attribute laboratory_id of _User. It is of type Int. Path: laboratory_ __id. Cannot be null. */
  laboratory_id?: Maybe<Scalars['Int']>;
  /** The attribute lang of _User. It is of type String. Path: lang_ name. Cannot be null. */
  lang?: Maybe<Scalars['String']>;
  /** The associated object for the role lang_. */
  lang_?: Maybe<Lang>;
  /** The associable objects for the role lang_. */
  lang____associables?: Maybe<LangPage>;
  /**
   * The attribute language of _User. It is of type String. Domain: .
   * Max length: 255.
   */
  language?: Maybe<Scalars['String']>;
  /** The attribute lastName of _User. It is of type String. Max length: 255.  */
  lastName?: Maybe<Scalars['String']>;
  /** The attribute last_year of _User. It is of type Year. Path: school_ last_year. Cannot be null. */
  last_year?: Maybe<Scalars['Year']>;
  /** The attribute non_teaching of _User. It is of type Boolean. Expression: 'profile="Non_teaching"'. Cannot be null. */
  non_teaching?: Maybe<Scalars['Boolean']>;
  /** The attribute numberFormat of _User. It is of type Text.  */
  numberFormat?: Maybe<Scalars['Text']>;
  /** The attribute patient of _User. It is of type Boolean. Expression: 'profile="Patient"'. Cannot be null. */
  patient?: Maybe<Scalars['Boolean']>;
  /** The associated object for the role patient_. */
  patient_?: Maybe<Patient>;
  /** The associable objects for the role patient_. */
  patient____associables?: Maybe<PatientPage>;
  /** The attribute patient_id of _User. It is of type Int. Path: patient_ __id. Cannot be null. */
  patient_id?: Maybe<Scalars['Int']>;
  /** The associated object for the role person_. */
  person_?: Maybe<Person>;
  /** The associable objects for the role person_. */
  person____associables?: Maybe<PersonPage>;
  /** The attribute principal of _User. It is of type Boolean. Expression: 'profile="Principal"'. Cannot be null. */
  principal?: Maybe<Scalars['Boolean']>;
  /**
   * The attribute profile of _User. It is of type String. Domain: .
   * Max length: 255. Cannot be null.
   */
  profile?: Maybe<Scalars['String']>;
  /** The associated object for the role school_. */
  school_?: Maybe<School>;
  /** The associable objects for the role school_. */
  school____associables?: Maybe<SchoolPage>;
  /** The attribute school_admin of _User. It is of type Boolean. Expression: 'profile="School_admin"'. Cannot be null. */
  school_admin?: Maybe<Scalars['Boolean']>;
  /** The attribute school_id of _User. It is of type Int. Path: school_ __id. Cannot be null. */
  school_id?: Maybe<Scalars['Int']>;
  /** The attribute school_name of _User. It is of type String. Path: school_ name. Cannot be null. */
  school_name?: Maybe<Scalars['String']>;
  /** The attribute school_people_id_current of _User. It is of type String. Path: person_ school_people_id_current. Cannot be null. */
  school_people_id_current?: Maybe<Scalars['String']>;
  /** The attribute school_study of _User. It is of type Boolean. Path: OR - Logical ORcenter_.study_ school.  */
  school_study?: Maybe<Scalars['Boolean']>;
  /** The attribute student of _User. It is of type Boolean. Expression: 'profile="Student"'. Cannot be null. */
  student?: Maybe<Scalars['Boolean']>;
  /** The attribute teacher of _User. It is of type Boolean. Expression: 'profile="Teacher"'. Cannot be null. */
  teacher?: Maybe<Scalars['Boolean']>;
  /** The attribute username of _User. It is of type String. Max length: 255. Cannot be null. */
  username?: Maybe<Scalars['String']>;
};


/** Represents an object of class _User, as defined in the model. */
export type _User_CreatedonArgs = {
  format?: InputMaybe<Scalars['String']>;
};


/** Represents an object of class _User, as defined in the model. */
export type _UserCenter____AssociablesArgs = {
  options?: InputMaybe<CenterPageOptions>;
};


/** Represents an object of class _User, as defined in the model. */
export type _UserFamily____AssociablesArgs = {
  options?: InputMaybe<FamilyPageOptions>;
};


/** Represents an object of class _User, as defined in the model. */
export type _UserHospital____AssociablesArgs = {
  options?: InputMaybe<HospitalPageOptions>;
};


/** Represents an object of class _User, as defined in the model. */
export type _UserLaboratory____AssociablesArgs = {
  options?: InputMaybe<LaboratoryPageOptions>;
};


/** Represents an object of class _User, as defined in the model. */
export type _UserLang____AssociablesArgs = {
  options?: InputMaybe<LangPageOptions>;
};


/** Represents an object of class _User, as defined in the model. */
export type _UserPatient____AssociablesArgs = {
  options?: InputMaybe<PatientPageOptions>;
};


/** Represents an object of class _User, as defined in the model. */
export type _UserPerson____AssociablesArgs = {
  options?: InputMaybe<PersonPageOptions>;
};


/** Represents an object of class _User, as defined in the model. */
export type _UserSchool____AssociablesArgs = {
  options?: InputMaybe<SchoolPageOptions>;
};

/**
 * Define a specific [Cursor](https://en.wikipedia.org/wiki/Cursor_%28databases%29) for objects of type
 * _User.
 */
export type _UserCursor = {
  _createdby?: InputMaybe<Scalars['String']>;
  _createdon?: InputMaybe<Scalars['Datetime']>;
  _id?: InputMaybe<Scalars['ID']>;
  admin?: InputMaybe<Scalars['Boolean']>;
  cache?: InputMaybe<Scalars['Text']>;
  center_name?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  date_format?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['Text']>;
  email?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  family?: InputMaybe<Scalars['Boolean']>;
  family_id?: InputMaybe<Scalars['Int']>;
  family_student_level_current?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hospital?: InputMaybe<Scalars['Boolean']>;
  hospital_admin?: InputMaybe<Scalars['Boolean']>;
  hospital_id?: InputMaybe<Scalars['Int']>;
  lab?: InputMaybe<Scalars['Boolean']>;
  lab_admin?: InputMaybe<Scalars['Boolean']>;
  lab_hospital_ids?: InputMaybe<Scalars['String']>;
  lab_school_ids?: InputMaybe<Scalars['String']>;
  laboratory_id?: InputMaybe<Scalars['Int']>;
  lang?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  last_year?: InputMaybe<Scalars['Year']>;
  non_teaching?: InputMaybe<Scalars['Boolean']>;
  numberFormat?: InputMaybe<Scalars['Text']>;
  patient?: InputMaybe<Scalars['Boolean']>;
  patient_id?: InputMaybe<Scalars['Int']>;
  principal?: InputMaybe<Scalars['Boolean']>;
  profile?: InputMaybe<Scalars['String']>;
  school_admin?: InputMaybe<Scalars['Boolean']>;
  school_id?: InputMaybe<Scalars['Int']>;
  school_name?: InputMaybe<Scalars['String']>;
  school_people_id_current?: InputMaybe<Scalars['String']>;
  school_study?: InputMaybe<Scalars['Boolean']>;
  student?: InputMaybe<Scalars['Boolean']>;
  teacher?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
};

/** A data structure for the draft associated object of class _User */
export type _UserDraftRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/**
 * Define a specific filter for objects of type _User.
 * Each attribute can be filtered in different ways (depending on the type of the attribute).
 * Filters can be composed with the boolan operators AND, OR and NOT.
 */
export type _UserFilter = {
  AND?: InputMaybe<Array<_UserFilter>>;
  NOT?: InputMaybe<_UserFilter>;
  OR?: InputMaybe<Array<_UserFilter>>;
  _clientId___eq?: InputMaybe<Scalars['ID']>;
  _createdby___contains?: InputMaybe<Scalars['String']>;
  _createdby___ends_with?: InputMaybe<Scalars['String']>;
  _createdby___eq?: InputMaybe<Scalars['String']>;
  _createdby___gt?: InputMaybe<Scalars['String']>;
  _createdby___gte?: InputMaybe<Scalars['String']>;
  _createdby___in?: InputMaybe<Array<Scalars['String']>>;
  _createdby___lt?: InputMaybe<Scalars['String']>;
  _createdby___lte?: InputMaybe<Scalars['String']>;
  _createdby___ne?: InputMaybe<Scalars['String']>;
  _createdby___not___contains?: InputMaybe<Scalars['String']>;
  _createdby___not___ends_with?: InputMaybe<Scalars['String']>;
  _createdby___not___in?: InputMaybe<Array<Scalars['String']>>;
  _createdby___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdby___not___starts_with?: InputMaybe<Scalars['String']>;
  _createdby___null?: InputMaybe<Scalars['Boolean']>;
  _createdby___starts_with?: InputMaybe<Scalars['String']>;
  _createdon___eq?: InputMaybe<Scalars['Datetime']>;
  _createdon___gt?: InputMaybe<Scalars['Datetime']>;
  _createdon___gte?: InputMaybe<Scalars['Datetime']>;
  _createdon___in?: InputMaybe<Array<InputMaybe<Scalars['Datetime']>>>;
  _createdon___lt?: InputMaybe<Scalars['Datetime']>;
  _createdon___lte?: InputMaybe<Scalars['Datetime']>;
  _createdon___ne?: InputMaybe<Scalars['Datetime']>;
  _createdon___not___in?: InputMaybe<Array<Scalars['Datetime']>>;
  _createdon___not___null?: InputMaybe<Scalars['Boolean']>;
  _createdon___null?: InputMaybe<Scalars['Boolean']>;
  _id___eq?: InputMaybe<Scalars['ID']>;
  _id___gt?: InputMaybe<Scalars['ID']>;
  _id___gte?: InputMaybe<Scalars['ID']>;
  _id___in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  _id___lt?: InputMaybe<Scalars['ID']>;
  _id___lte?: InputMaybe<Scalars['ID']>;
  _id___ne?: InputMaybe<Scalars['ID']>;
  _id___not___in?: InputMaybe<Array<Scalars['ID']>>;
  _id___not___null?: InputMaybe<Scalars['Boolean']>;
  _id___null?: InputMaybe<Scalars['Boolean']>;
  admin___eq?: InputMaybe<Scalars['Boolean']>;
  admin___ne?: InputMaybe<Scalars['Boolean']>;
  admin___not___null?: InputMaybe<Scalars['Boolean']>;
  admin___null?: InputMaybe<Scalars['Boolean']>;
  cache___contains?: InputMaybe<Scalars['Text']>;
  cache___ends_with?: InputMaybe<Scalars['Text']>;
  cache___eq?: InputMaybe<Scalars['Text']>;
  cache___gt?: InputMaybe<Scalars['Text']>;
  cache___gte?: InputMaybe<Scalars['Text']>;
  cache___in?: InputMaybe<Array<Scalars['Text']>>;
  cache___lt?: InputMaybe<Scalars['Text']>;
  cache___lte?: InputMaybe<Scalars['Text']>;
  cache___ne?: InputMaybe<Scalars['Text']>;
  cache___not___contains?: InputMaybe<Scalars['Text']>;
  cache___not___ends_with?: InputMaybe<Scalars['Text']>;
  cache___not___in?: InputMaybe<Array<Scalars['Text']>>;
  cache___not___null?: InputMaybe<Scalars['Boolean']>;
  cache___not___starts_with?: InputMaybe<Scalars['Text']>;
  cache___null?: InputMaybe<Scalars['Boolean']>;
  cache___starts_with?: InputMaybe<Scalars['Text']>;
  center_name___contains?: InputMaybe<Scalars['String']>;
  center_name___ends_with?: InputMaybe<Scalars['String']>;
  center_name___eq?: InputMaybe<Scalars['String']>;
  center_name___gt?: InputMaybe<Scalars['String']>;
  center_name___gte?: InputMaybe<Scalars['String']>;
  center_name___in?: InputMaybe<Array<Scalars['String']>>;
  center_name___lt?: InputMaybe<Scalars['String']>;
  center_name___lte?: InputMaybe<Scalars['String']>;
  center_name___ne?: InputMaybe<Scalars['String']>;
  center_name___not___contains?: InputMaybe<Scalars['String']>;
  center_name___not___ends_with?: InputMaybe<Scalars['String']>;
  center_name___not___in?: InputMaybe<Array<Scalars['String']>>;
  center_name___not___null?: InputMaybe<Scalars['Boolean']>;
  center_name___not___starts_with?: InputMaybe<Scalars['String']>;
  center_name___null?: InputMaybe<Scalars['Boolean']>;
  center_name___starts_with?: InputMaybe<Scalars['String']>;
  country___contains?: InputMaybe<Scalars['String']>;
  country___ends_with?: InputMaybe<Scalars['String']>;
  country___eq?: InputMaybe<Scalars['String']>;
  country___gt?: InputMaybe<Scalars['String']>;
  country___gte?: InputMaybe<Scalars['String']>;
  country___in?: InputMaybe<Array<Scalars['String']>>;
  country___lt?: InputMaybe<Scalars['String']>;
  country___lte?: InputMaybe<Scalars['String']>;
  country___ne?: InputMaybe<Scalars['String']>;
  country___not___contains?: InputMaybe<Scalars['String']>;
  country___not___ends_with?: InputMaybe<Scalars['String']>;
  country___not___in?: InputMaybe<Array<Scalars['String']>>;
  country___not___null?: InputMaybe<Scalars['Boolean']>;
  country___not___starts_with?: InputMaybe<Scalars['String']>;
  country___null?: InputMaybe<Scalars['Boolean']>;
  country___starts_with?: InputMaybe<Scalars['String']>;
  date_format___contains?: InputMaybe<Scalars['String']>;
  date_format___ends_with?: InputMaybe<Scalars['String']>;
  date_format___eq?: InputMaybe<Scalars['String']>;
  date_format___gt?: InputMaybe<Scalars['String']>;
  date_format___gte?: InputMaybe<Scalars['String']>;
  date_format___in?: InputMaybe<Array<Scalars['String']>>;
  date_format___lt?: InputMaybe<Scalars['String']>;
  date_format___lte?: InputMaybe<Scalars['String']>;
  date_format___ne?: InputMaybe<Scalars['String']>;
  date_format___not___contains?: InputMaybe<Scalars['String']>;
  date_format___not___ends_with?: InputMaybe<Scalars['String']>;
  date_format___not___in?: InputMaybe<Array<Scalars['String']>>;
  date_format___not___null?: InputMaybe<Scalars['Boolean']>;
  date_format___not___starts_with?: InputMaybe<Scalars['String']>;
  date_format___null?: InputMaybe<Scalars['Boolean']>;
  date_format___starts_with?: InputMaybe<Scalars['String']>;
  description___contains?: InputMaybe<Scalars['Text']>;
  description___ends_with?: InputMaybe<Scalars['Text']>;
  description___eq?: InputMaybe<Scalars['Text']>;
  description___gt?: InputMaybe<Scalars['Text']>;
  description___gte?: InputMaybe<Scalars['Text']>;
  description___in?: InputMaybe<Array<Scalars['Text']>>;
  description___lt?: InputMaybe<Scalars['Text']>;
  description___lte?: InputMaybe<Scalars['Text']>;
  description___ne?: InputMaybe<Scalars['Text']>;
  description___not___contains?: InputMaybe<Scalars['Text']>;
  description___not___ends_with?: InputMaybe<Scalars['Text']>;
  description___not___in?: InputMaybe<Array<Scalars['Text']>>;
  description___not___null?: InputMaybe<Scalars['Boolean']>;
  description___not___starts_with?: InputMaybe<Scalars['Text']>;
  description___null?: InputMaybe<Scalars['Boolean']>;
  description___starts_with?: InputMaybe<Scalars['Text']>;
  email___contains?: InputMaybe<Scalars['String']>;
  email___ends_with?: InputMaybe<Scalars['String']>;
  email___eq?: InputMaybe<Scalars['String']>;
  email___gt?: InputMaybe<Scalars['String']>;
  email___gte?: InputMaybe<Scalars['String']>;
  email___in?: InputMaybe<Array<Scalars['String']>>;
  email___lt?: InputMaybe<Scalars['String']>;
  email___lte?: InputMaybe<Scalars['String']>;
  email___ne?: InputMaybe<Scalars['String']>;
  email___not___contains?: InputMaybe<Scalars['String']>;
  email___not___ends_with?: InputMaybe<Scalars['String']>;
  email___not___in?: InputMaybe<Array<Scalars['String']>>;
  email___not___null?: InputMaybe<Scalars['Boolean']>;
  email___not___starts_with?: InputMaybe<Scalars['String']>;
  email___null?: InputMaybe<Scalars['Boolean']>;
  email___starts_with?: InputMaybe<Scalars['String']>;
  enabled___eq?: InputMaybe<Scalars['Boolean']>;
  enabled___ne?: InputMaybe<Scalars['Boolean']>;
  enabled___not___null?: InputMaybe<Scalars['Boolean']>;
  enabled___null?: InputMaybe<Scalars['Boolean']>;
  family___eq?: InputMaybe<Scalars['Boolean']>;
  family___ne?: InputMaybe<Scalars['Boolean']>;
  family___not___null?: InputMaybe<Scalars['Boolean']>;
  family___null?: InputMaybe<Scalars['Boolean']>;
  family_id___eq?: InputMaybe<Scalars['Int']>;
  family_id___gt?: InputMaybe<Scalars['Int']>;
  family_id___gte?: InputMaybe<Scalars['Int']>;
  family_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  family_id___lt?: InputMaybe<Scalars['Int']>;
  family_id___lte?: InputMaybe<Scalars['Int']>;
  family_id___ne?: InputMaybe<Scalars['Int']>;
  family_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  family_id___not___null?: InputMaybe<Scalars['Boolean']>;
  family_id___null?: InputMaybe<Scalars['Boolean']>;
  family_student_level_current___contains?: InputMaybe<Scalars['String']>;
  family_student_level_current___ends_with?: InputMaybe<Scalars['String']>;
  family_student_level_current___eq?: InputMaybe<Scalars['String']>;
  family_student_level_current___gt?: InputMaybe<Scalars['String']>;
  family_student_level_current___gte?: InputMaybe<Scalars['String']>;
  family_student_level_current___in?: InputMaybe<Array<Scalars['String']>>;
  family_student_level_current___lt?: InputMaybe<Scalars['String']>;
  family_student_level_current___lte?: InputMaybe<Scalars['String']>;
  family_student_level_current___ne?: InputMaybe<Scalars['String']>;
  family_student_level_current___not___contains?: InputMaybe<Scalars['String']>;
  family_student_level_current___not___ends_with?: InputMaybe<Scalars['String']>;
  family_student_level_current___not___in?: InputMaybe<Array<Scalars['String']>>;
  family_student_level_current___not___null?: InputMaybe<Scalars['Boolean']>;
  family_student_level_current___not___starts_with?: InputMaybe<Scalars['String']>;
  family_student_level_current___null?: InputMaybe<Scalars['Boolean']>;
  family_student_level_current___starts_with?: InputMaybe<Scalars['String']>;
  firstName___contains?: InputMaybe<Scalars['String']>;
  firstName___ends_with?: InputMaybe<Scalars['String']>;
  firstName___eq?: InputMaybe<Scalars['String']>;
  firstName___gt?: InputMaybe<Scalars['String']>;
  firstName___gte?: InputMaybe<Scalars['String']>;
  firstName___in?: InputMaybe<Array<Scalars['String']>>;
  firstName___lt?: InputMaybe<Scalars['String']>;
  firstName___lte?: InputMaybe<Scalars['String']>;
  firstName___ne?: InputMaybe<Scalars['String']>;
  firstName___not___contains?: InputMaybe<Scalars['String']>;
  firstName___not___ends_with?: InputMaybe<Scalars['String']>;
  firstName___not___in?: InputMaybe<Array<Scalars['String']>>;
  firstName___not___null?: InputMaybe<Scalars['Boolean']>;
  firstName___not___starts_with?: InputMaybe<Scalars['String']>;
  firstName___null?: InputMaybe<Scalars['Boolean']>;
  firstName___starts_with?: InputMaybe<Scalars['String']>;
  hospital___eq?: InputMaybe<Scalars['Boolean']>;
  hospital___ne?: InputMaybe<Scalars['Boolean']>;
  hospital___not___null?: InputMaybe<Scalars['Boolean']>;
  hospital___null?: InputMaybe<Scalars['Boolean']>;
  hospital_admin___eq?: InputMaybe<Scalars['Boolean']>;
  hospital_admin___ne?: InputMaybe<Scalars['Boolean']>;
  hospital_admin___not___null?: InputMaybe<Scalars['Boolean']>;
  hospital_admin___null?: InputMaybe<Scalars['Boolean']>;
  hospital_id___eq?: InputMaybe<Scalars['Int']>;
  hospital_id___gt?: InputMaybe<Scalars['Int']>;
  hospital_id___gte?: InputMaybe<Scalars['Int']>;
  hospital_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  hospital_id___lt?: InputMaybe<Scalars['Int']>;
  hospital_id___lte?: InputMaybe<Scalars['Int']>;
  hospital_id___ne?: InputMaybe<Scalars['Int']>;
  hospital_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  hospital_id___not___null?: InputMaybe<Scalars['Boolean']>;
  hospital_id___null?: InputMaybe<Scalars['Boolean']>;
  lab___eq?: InputMaybe<Scalars['Boolean']>;
  lab___ne?: InputMaybe<Scalars['Boolean']>;
  lab___not___null?: InputMaybe<Scalars['Boolean']>;
  lab___null?: InputMaybe<Scalars['Boolean']>;
  lab_admin___eq?: InputMaybe<Scalars['Boolean']>;
  lab_admin___ne?: InputMaybe<Scalars['Boolean']>;
  lab_admin___not___null?: InputMaybe<Scalars['Boolean']>;
  lab_admin___null?: InputMaybe<Scalars['Boolean']>;
  lab_hospital_ids___contains?: InputMaybe<Scalars['String']>;
  lab_hospital_ids___ends_with?: InputMaybe<Scalars['String']>;
  lab_hospital_ids___eq?: InputMaybe<Scalars['String']>;
  lab_hospital_ids___gt?: InputMaybe<Scalars['String']>;
  lab_hospital_ids___gte?: InputMaybe<Scalars['String']>;
  lab_hospital_ids___in?: InputMaybe<Array<Scalars['String']>>;
  lab_hospital_ids___lt?: InputMaybe<Scalars['String']>;
  lab_hospital_ids___lte?: InputMaybe<Scalars['String']>;
  lab_hospital_ids___ne?: InputMaybe<Scalars['String']>;
  lab_hospital_ids___not___contains?: InputMaybe<Scalars['String']>;
  lab_hospital_ids___not___ends_with?: InputMaybe<Scalars['String']>;
  lab_hospital_ids___not___in?: InputMaybe<Array<Scalars['String']>>;
  lab_hospital_ids___not___null?: InputMaybe<Scalars['Boolean']>;
  lab_hospital_ids___not___starts_with?: InputMaybe<Scalars['String']>;
  lab_hospital_ids___null?: InputMaybe<Scalars['Boolean']>;
  lab_hospital_ids___starts_with?: InputMaybe<Scalars['String']>;
  lab_school_ids___contains?: InputMaybe<Scalars['String']>;
  lab_school_ids___ends_with?: InputMaybe<Scalars['String']>;
  lab_school_ids___eq?: InputMaybe<Scalars['String']>;
  lab_school_ids___gt?: InputMaybe<Scalars['String']>;
  lab_school_ids___gte?: InputMaybe<Scalars['String']>;
  lab_school_ids___in?: InputMaybe<Array<Scalars['String']>>;
  lab_school_ids___lt?: InputMaybe<Scalars['String']>;
  lab_school_ids___lte?: InputMaybe<Scalars['String']>;
  lab_school_ids___ne?: InputMaybe<Scalars['String']>;
  lab_school_ids___not___contains?: InputMaybe<Scalars['String']>;
  lab_school_ids___not___ends_with?: InputMaybe<Scalars['String']>;
  lab_school_ids___not___in?: InputMaybe<Array<Scalars['String']>>;
  lab_school_ids___not___null?: InputMaybe<Scalars['Boolean']>;
  lab_school_ids___not___starts_with?: InputMaybe<Scalars['String']>;
  lab_school_ids___null?: InputMaybe<Scalars['Boolean']>;
  lab_school_ids___starts_with?: InputMaybe<Scalars['String']>;
  laboratory_id___eq?: InputMaybe<Scalars['Int']>;
  laboratory_id___gt?: InputMaybe<Scalars['Int']>;
  laboratory_id___gte?: InputMaybe<Scalars['Int']>;
  laboratory_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  laboratory_id___lt?: InputMaybe<Scalars['Int']>;
  laboratory_id___lte?: InputMaybe<Scalars['Int']>;
  laboratory_id___ne?: InputMaybe<Scalars['Int']>;
  laboratory_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  laboratory_id___not___null?: InputMaybe<Scalars['Boolean']>;
  laboratory_id___null?: InputMaybe<Scalars['Boolean']>;
  lang___contains?: InputMaybe<Scalars['String']>;
  lang___ends_with?: InputMaybe<Scalars['String']>;
  lang___eq?: InputMaybe<Scalars['String']>;
  lang___gt?: InputMaybe<Scalars['String']>;
  lang___gte?: InputMaybe<Scalars['String']>;
  lang___in?: InputMaybe<Array<Scalars['String']>>;
  lang___lt?: InputMaybe<Scalars['String']>;
  lang___lte?: InputMaybe<Scalars['String']>;
  lang___ne?: InputMaybe<Scalars['String']>;
  lang___not___contains?: InputMaybe<Scalars['String']>;
  lang___not___ends_with?: InputMaybe<Scalars['String']>;
  lang___not___in?: InputMaybe<Array<Scalars['String']>>;
  lang___not___null?: InputMaybe<Scalars['Boolean']>;
  lang___not___starts_with?: InputMaybe<Scalars['String']>;
  lang___null?: InputMaybe<Scalars['Boolean']>;
  lang___starts_with?: InputMaybe<Scalars['String']>;
  language___contains?: InputMaybe<Scalars['String']>;
  language___ends_with?: InputMaybe<Scalars['String']>;
  language___eq?: InputMaybe<Scalars['String']>;
  language___gt?: InputMaybe<Scalars['String']>;
  language___gte?: InputMaybe<Scalars['String']>;
  language___in?: InputMaybe<Array<Scalars['String']>>;
  language___lt?: InputMaybe<Scalars['String']>;
  language___lte?: InputMaybe<Scalars['String']>;
  language___ne?: InputMaybe<Scalars['String']>;
  language___not___contains?: InputMaybe<Scalars['String']>;
  language___not___ends_with?: InputMaybe<Scalars['String']>;
  language___not___in?: InputMaybe<Array<Scalars['String']>>;
  language___not___null?: InputMaybe<Scalars['Boolean']>;
  language___not___starts_with?: InputMaybe<Scalars['String']>;
  language___null?: InputMaybe<Scalars['Boolean']>;
  language___starts_with?: InputMaybe<Scalars['String']>;
  lastName___contains?: InputMaybe<Scalars['String']>;
  lastName___ends_with?: InputMaybe<Scalars['String']>;
  lastName___eq?: InputMaybe<Scalars['String']>;
  lastName___gt?: InputMaybe<Scalars['String']>;
  lastName___gte?: InputMaybe<Scalars['String']>;
  lastName___in?: InputMaybe<Array<Scalars['String']>>;
  lastName___lt?: InputMaybe<Scalars['String']>;
  lastName___lte?: InputMaybe<Scalars['String']>;
  lastName___ne?: InputMaybe<Scalars['String']>;
  lastName___not___contains?: InputMaybe<Scalars['String']>;
  lastName___not___ends_with?: InputMaybe<Scalars['String']>;
  lastName___not___in?: InputMaybe<Array<Scalars['String']>>;
  lastName___not___null?: InputMaybe<Scalars['Boolean']>;
  lastName___not___starts_with?: InputMaybe<Scalars['String']>;
  lastName___null?: InputMaybe<Scalars['Boolean']>;
  lastName___starts_with?: InputMaybe<Scalars['String']>;
  last_year___eq?: InputMaybe<Scalars['Year']>;
  last_year___gt?: InputMaybe<Scalars['Year']>;
  last_year___gte?: InputMaybe<Scalars['Year']>;
  last_year___in?: InputMaybe<Array<InputMaybe<Scalars['Year']>>>;
  last_year___lt?: InputMaybe<Scalars['Year']>;
  last_year___lte?: InputMaybe<Scalars['Year']>;
  last_year___ne?: InputMaybe<Scalars['Year']>;
  last_year___not___in?: InputMaybe<Array<Scalars['Year']>>;
  last_year___not___null?: InputMaybe<Scalars['Boolean']>;
  last_year___null?: InputMaybe<Scalars['Boolean']>;
  non_teaching___eq?: InputMaybe<Scalars['Boolean']>;
  non_teaching___ne?: InputMaybe<Scalars['Boolean']>;
  non_teaching___not___null?: InputMaybe<Scalars['Boolean']>;
  non_teaching___null?: InputMaybe<Scalars['Boolean']>;
  numberFormat___contains?: InputMaybe<Scalars['Text']>;
  numberFormat___ends_with?: InputMaybe<Scalars['Text']>;
  numberFormat___eq?: InputMaybe<Scalars['Text']>;
  numberFormat___gt?: InputMaybe<Scalars['Text']>;
  numberFormat___gte?: InputMaybe<Scalars['Text']>;
  numberFormat___in?: InputMaybe<Array<Scalars['Text']>>;
  numberFormat___lt?: InputMaybe<Scalars['Text']>;
  numberFormat___lte?: InputMaybe<Scalars['Text']>;
  numberFormat___ne?: InputMaybe<Scalars['Text']>;
  numberFormat___not___contains?: InputMaybe<Scalars['Text']>;
  numberFormat___not___ends_with?: InputMaybe<Scalars['Text']>;
  numberFormat___not___in?: InputMaybe<Array<Scalars['Text']>>;
  numberFormat___not___null?: InputMaybe<Scalars['Boolean']>;
  numberFormat___not___starts_with?: InputMaybe<Scalars['Text']>;
  numberFormat___null?: InputMaybe<Scalars['Boolean']>;
  numberFormat___starts_with?: InputMaybe<Scalars['Text']>;
  patient___eq?: InputMaybe<Scalars['Boolean']>;
  patient___ne?: InputMaybe<Scalars['Boolean']>;
  patient___not___null?: InputMaybe<Scalars['Boolean']>;
  patient___null?: InputMaybe<Scalars['Boolean']>;
  patient_id___eq?: InputMaybe<Scalars['Int']>;
  patient_id___gt?: InputMaybe<Scalars['Int']>;
  patient_id___gte?: InputMaybe<Scalars['Int']>;
  patient_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  patient_id___lt?: InputMaybe<Scalars['Int']>;
  patient_id___lte?: InputMaybe<Scalars['Int']>;
  patient_id___ne?: InputMaybe<Scalars['Int']>;
  patient_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  patient_id___not___null?: InputMaybe<Scalars['Boolean']>;
  patient_id___null?: InputMaybe<Scalars['Boolean']>;
  principal___eq?: InputMaybe<Scalars['Boolean']>;
  principal___ne?: InputMaybe<Scalars['Boolean']>;
  principal___not___null?: InputMaybe<Scalars['Boolean']>;
  principal___null?: InputMaybe<Scalars['Boolean']>;
  profile___contains?: InputMaybe<Scalars['String']>;
  profile___ends_with?: InputMaybe<Scalars['String']>;
  profile___eq?: InputMaybe<Scalars['String']>;
  profile___gt?: InputMaybe<Scalars['String']>;
  profile___gte?: InputMaybe<Scalars['String']>;
  profile___in?: InputMaybe<Array<Scalars['String']>>;
  profile___lt?: InputMaybe<Scalars['String']>;
  profile___lte?: InputMaybe<Scalars['String']>;
  profile___ne?: InputMaybe<Scalars['String']>;
  profile___not___contains?: InputMaybe<Scalars['String']>;
  profile___not___ends_with?: InputMaybe<Scalars['String']>;
  profile___not___in?: InputMaybe<Array<Scalars['String']>>;
  profile___not___null?: InputMaybe<Scalars['Boolean']>;
  profile___not___starts_with?: InputMaybe<Scalars['String']>;
  profile___null?: InputMaybe<Scalars['Boolean']>;
  profile___starts_with?: InputMaybe<Scalars['String']>;
  school_admin___eq?: InputMaybe<Scalars['Boolean']>;
  school_admin___ne?: InputMaybe<Scalars['Boolean']>;
  school_admin___not___null?: InputMaybe<Scalars['Boolean']>;
  school_admin___null?: InputMaybe<Scalars['Boolean']>;
  school_id___eq?: InputMaybe<Scalars['Int']>;
  school_id___gt?: InputMaybe<Scalars['Int']>;
  school_id___gte?: InputMaybe<Scalars['Int']>;
  school_id___in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  school_id___lt?: InputMaybe<Scalars['Int']>;
  school_id___lte?: InputMaybe<Scalars['Int']>;
  school_id___ne?: InputMaybe<Scalars['Int']>;
  school_id___not___in?: InputMaybe<Array<Scalars['Int']>>;
  school_id___not___null?: InputMaybe<Scalars['Boolean']>;
  school_id___null?: InputMaybe<Scalars['Boolean']>;
  school_name___contains?: InputMaybe<Scalars['String']>;
  school_name___ends_with?: InputMaybe<Scalars['String']>;
  school_name___eq?: InputMaybe<Scalars['String']>;
  school_name___gt?: InputMaybe<Scalars['String']>;
  school_name___gte?: InputMaybe<Scalars['String']>;
  school_name___in?: InputMaybe<Array<Scalars['String']>>;
  school_name___lt?: InputMaybe<Scalars['String']>;
  school_name___lte?: InputMaybe<Scalars['String']>;
  school_name___ne?: InputMaybe<Scalars['String']>;
  school_name___not___contains?: InputMaybe<Scalars['String']>;
  school_name___not___ends_with?: InputMaybe<Scalars['String']>;
  school_name___not___in?: InputMaybe<Array<Scalars['String']>>;
  school_name___not___null?: InputMaybe<Scalars['Boolean']>;
  school_name___not___starts_with?: InputMaybe<Scalars['String']>;
  school_name___null?: InputMaybe<Scalars['Boolean']>;
  school_name___starts_with?: InputMaybe<Scalars['String']>;
  school_people_id_current___contains?: InputMaybe<Scalars['String']>;
  school_people_id_current___ends_with?: InputMaybe<Scalars['String']>;
  school_people_id_current___eq?: InputMaybe<Scalars['String']>;
  school_people_id_current___gt?: InputMaybe<Scalars['String']>;
  school_people_id_current___gte?: InputMaybe<Scalars['String']>;
  school_people_id_current___in?: InputMaybe<Array<Scalars['String']>>;
  school_people_id_current___lt?: InputMaybe<Scalars['String']>;
  school_people_id_current___lte?: InputMaybe<Scalars['String']>;
  school_people_id_current___ne?: InputMaybe<Scalars['String']>;
  school_people_id_current___not___contains?: InputMaybe<Scalars['String']>;
  school_people_id_current___not___ends_with?: InputMaybe<Scalars['String']>;
  school_people_id_current___not___in?: InputMaybe<Array<Scalars['String']>>;
  school_people_id_current___not___null?: InputMaybe<Scalars['Boolean']>;
  school_people_id_current___not___starts_with?: InputMaybe<Scalars['String']>;
  school_people_id_current___null?: InputMaybe<Scalars['Boolean']>;
  school_people_id_current___starts_with?: InputMaybe<Scalars['String']>;
  school_study___eq?: InputMaybe<Scalars['Boolean']>;
  school_study___ne?: InputMaybe<Scalars['Boolean']>;
  school_study___not___null?: InputMaybe<Scalars['Boolean']>;
  school_study___null?: InputMaybe<Scalars['Boolean']>;
  student___eq?: InputMaybe<Scalars['Boolean']>;
  student___ne?: InputMaybe<Scalars['Boolean']>;
  student___not___null?: InputMaybe<Scalars['Boolean']>;
  student___null?: InputMaybe<Scalars['Boolean']>;
  teacher___eq?: InputMaybe<Scalars['Boolean']>;
  teacher___ne?: InputMaybe<Scalars['Boolean']>;
  teacher___not___null?: InputMaybe<Scalars['Boolean']>;
  teacher___null?: InputMaybe<Scalars['Boolean']>;
  username___contains?: InputMaybe<Scalars['String']>;
  username___ends_with?: InputMaybe<Scalars['String']>;
  username___eq?: InputMaybe<Scalars['String']>;
  username___gt?: InputMaybe<Scalars['String']>;
  username___gte?: InputMaybe<Scalars['String']>;
  username___in?: InputMaybe<Array<Scalars['String']>>;
  username___lt?: InputMaybe<Scalars['String']>;
  username___lte?: InputMaybe<Scalars['String']>;
  username___ne?: InputMaybe<Scalars['String']>;
  username___not___contains?: InputMaybe<Scalars['String']>;
  username___not___ends_with?: InputMaybe<Scalars['String']>;
  username___not___in?: InputMaybe<Array<Scalars['String']>>;
  username___not___null?: InputMaybe<Scalars['Boolean']>;
  username___not___starts_with?: InputMaybe<Scalars['String']>;
  username___null?: InputMaybe<Scalars['Boolean']>;
  username___starts_with?: InputMaybe<Scalars['String']>;
};

/** Represents a collection of objects of class _User. */
export type _UserPage = {
  __typename?: '_UserPage';
  /** A boolan flag that indicates if there are more elements following the ones retrieved. */
  hasNext: Scalars['Boolean'];
  /** A boolan flag that indicates if there are elements preceding the ones retrieved. */
  hasPrev: Scalars['Boolean'];
  /** An array containing the actual objects of class _User. */
  items: Array<_User>;
  /** A cursor to the next object, used to iterate over the elements. */
  nextCursor?: Maybe<Scalars['Cursor']>;
  /** A cursor to the previous object, used to iterate over the elements in reverse order. */
  prevCursor?: Maybe<Scalars['Cursor']>;
  /** The total number of objects of class _User present in the database. */
  totalCount: Scalars['Int'];
};

/** Options to configure the list of elements to be returned by a _UserPage. */
export type _UserPageOptions = {
  /** Used to retrieve the object following an existing Cursor. */
  cursor?: InputMaybe<Scalars['Cursor']>;
  /** Used to filter the data to retrieve. */
  filter?: InputMaybe<_UserFilter>;
  /** Used to filter the data to retrieve. The filter is expressed using the LiveBase language. */
  filter_exp?: InputMaybe<Scalars['String']>;
  /** Used to retrieve objects from a specific cursor. */
  fromCursor?: InputMaybe<_UserCursor>;
  /** Used to set the number of items returned by the page. */
  next?: InputMaybe<Scalars['Int']>;
  /** Used to skip the first objects. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Used to set the ordering of the items returned by the page. */
  orderBy?: InputMaybe<Array<_UserSort>>;
  /** Used to set the number of items returned by the page. */
  prev?: InputMaybe<Scalars['Int']>;
};

/** A data structure for the associated object of class _User */
export type _UserRoleRef = {
  /** The ID of the object to remove from the association. */
  remove?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the object to associate. */
  set?: InputMaybe<Scalars['ID']>;
};

/** Specify if an ordering should be applied ascending or descending. */
export enum _UserSort {
  _createdby___ASC = '_createdby___ASC',
  _createdby___DESC = '_createdby___DESC',
  _createdon___ASC = '_createdon___ASC',
  _createdon___DESC = '_createdon___DESC',
  _id___ASC = '_id___ASC',
  _id___DESC = '_id___DESC',
  admin___ASC = 'admin___ASC',
  admin___DESC = 'admin___DESC',
  cache___ASC = 'cache___ASC',
  cache___DESC = 'cache___DESC',
  center_name___ASC = 'center_name___ASC',
  center_name___DESC = 'center_name___DESC',
  country___ASC = 'country___ASC',
  country___DESC = 'country___DESC',
  date_format___ASC = 'date_format___ASC',
  date_format___DESC = 'date_format___DESC',
  description___ASC = 'description___ASC',
  description___DESC = 'description___DESC',
  email___ASC = 'email___ASC',
  email___DESC = 'email___DESC',
  enabled___ASC = 'enabled___ASC',
  enabled___DESC = 'enabled___DESC',
  family___ASC = 'family___ASC',
  family___DESC = 'family___DESC',
  family_id___ASC = 'family_id___ASC',
  family_id___DESC = 'family_id___DESC',
  family_student_level_current___ASC = 'family_student_level_current___ASC',
  family_student_level_current___DESC = 'family_student_level_current___DESC',
  firstName___ASC = 'firstName___ASC',
  firstName___DESC = 'firstName___DESC',
  hospital___ASC = 'hospital___ASC',
  hospital___DESC = 'hospital___DESC',
  hospital_admin___ASC = 'hospital_admin___ASC',
  hospital_admin___DESC = 'hospital_admin___DESC',
  hospital_id___ASC = 'hospital_id___ASC',
  hospital_id___DESC = 'hospital_id___DESC',
  lab___ASC = 'lab___ASC',
  lab___DESC = 'lab___DESC',
  lab_admin___ASC = 'lab_admin___ASC',
  lab_admin___DESC = 'lab_admin___DESC',
  lab_hospital_ids___ASC = 'lab_hospital_ids___ASC',
  lab_hospital_ids___DESC = 'lab_hospital_ids___DESC',
  lab_school_ids___ASC = 'lab_school_ids___ASC',
  lab_school_ids___DESC = 'lab_school_ids___DESC',
  laboratory_id___ASC = 'laboratory_id___ASC',
  laboratory_id___DESC = 'laboratory_id___DESC',
  lang___ASC = 'lang___ASC',
  lang___DESC = 'lang___DESC',
  language___ASC = 'language___ASC',
  language___DESC = 'language___DESC',
  lastName___ASC = 'lastName___ASC',
  lastName___DESC = 'lastName___DESC',
  last_year___ASC = 'last_year___ASC',
  last_year___DESC = 'last_year___DESC',
  non_teaching___ASC = 'non_teaching___ASC',
  non_teaching___DESC = 'non_teaching___DESC',
  numberFormat___ASC = 'numberFormat___ASC',
  numberFormat___DESC = 'numberFormat___DESC',
  patient___ASC = 'patient___ASC',
  patient___DESC = 'patient___DESC',
  patient_id___ASC = 'patient_id___ASC',
  patient_id___DESC = 'patient_id___DESC',
  principal___ASC = 'principal___ASC',
  principal___DESC = 'principal___DESC',
  profile___ASC = 'profile___ASC',
  profile___DESC = 'profile___DESC',
  school_admin___ASC = 'school_admin___ASC',
  school_admin___DESC = 'school_admin___DESC',
  school_id___ASC = 'school_id___ASC',
  school_id___DESC = 'school_id___DESC',
  school_name___ASC = 'school_name___ASC',
  school_name___DESC = 'school_name___DESC',
  school_people_id_current___ASC = 'school_people_id_current___ASC',
  school_people_id_current___DESC = 'school_people_id_current___DESC',
  school_study___ASC = 'school_study___ASC',
  school_study___DESC = 'school_study___DESC',
  student___ASC = 'student___ASC',
  student___DESC = 'student___DESC',
  teacher___ASC = 'teacher___ASC',
  teacher___DESC = 'teacher___DESC',
  username___ASC = 'username___ASC',
  username___DESC = 'username___DESC'
}

export type GetUserFilteredQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserFilteredQuery = { __typename?: 'Query', Questionnaire_schema___getPage?: { __typename?: 'Questionnaire_schemaPage', totalCount: number } | null };

export type GetQuestionnaireQuestionsListQueryVariables = Exact<{
  input: Scalars['ID'];
  questionInput: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
}>;


export type GetQuestionnaireQuestionsListQuery = { __typename?: 'Query', Questionnaire_schema___get?: { __typename?: 'Questionnaire_schema', _id?: string | null, question_?: { __typename?: 'QuestionPage', items: Array<{ __typename?: 'Question', _id?: string | null, response_type?: string | null, code?: string | null, order?: number | null, questionnaire_section?: string | null, question_?: string | null, question_fixed?: string | null, enabled_by?: string | null, disabled_by?: string | null, possibile_response_?: { __typename?: 'Possible_responsePage', items: Array<{ __typename?: 'Possible_response', _id?: string | null, response_?: string | null }> } | null, question_enabling_condition_?: { __typename?: 'Question_enabling_conditionPage', items: Array<{ __typename?: 'Question_enabling_condition', _id?: string | null, possible_response_?: { __typename?: 'Possible_response', _id?: string | null } | null, questions_disabled?: { __typename?: 'QuestionPage', items: Array<{ __typename?: 'Question', _id?: string | null }> } | null, questions_enabled?: { __typename?: 'QuestionPage', items: Array<{ __typename?: 'Question', _id?: string | null }> } | null }> } | null }> } | null } | null };

export type GetQuestionnairePreviousAnswersQueryVariables = Exact<{
  input: Scalars['ID'];
}>;


export type GetQuestionnairePreviousAnswersQuery = { __typename?: 'Query', Questionnaire_completion___get?: { __typename?: 'Questionnaire_completion', _id?: string | null, response_?: { __typename?: 'ResponsePage', items: Array<{ __typename?: 'Response', response_str?: any | null, question_?: { __typename?: 'Question', _id?: string | null, question_?: string | null, questionnaire_section?: string | null, possibile_response_?: { __typename?: 'Possible_responsePage', items: Array<{ __typename?: 'Possible_response', _id?: string | null, response_?: string | null }> } | null } | null, possible_single_response?: { __typename?: 'Possible_response', _id?: string | null, response_?: string | null } | null }> } | null } | null };


export const GetUserFilteredDocument = gql`
    query GetUserFiltered {
  Questionnaire_schema___getPage {
    totalCount
  }
}
    `;

/**
 * __useGetUserFilteredQuery__
 *
 * To run a query within a React component, call `useGetUserFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFilteredQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserFilteredQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFilteredQuery, GetUserFilteredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFilteredQuery, GetUserFilteredQueryVariables>(GetUserFilteredDocument, options);
      }
export function useGetUserFilteredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFilteredQuery, GetUserFilteredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFilteredQuery, GetUserFilteredQueryVariables>(GetUserFilteredDocument, options);
        }
export type GetUserFilteredQueryHookResult = ReturnType<typeof useGetUserFilteredQuery>;
export type GetUserFilteredLazyQueryHookResult = ReturnType<typeof useGetUserFilteredLazyQuery>;
export type GetUserFilteredQueryResult = Apollo.QueryResult<GetUserFilteredQuery, GetUserFilteredQueryVariables>;
export const GetQuestionnaireQuestionsListDocument = gql`
    query GetQuestionnaireQuestionsList($input: ID!, $questionInput: [ID]!) {
  Questionnaire_schema___get(_id: $input) {
    _id
    question_(options: {filter: {_id___in: $questionInput}, next: 9999}) {
      items {
        _id
        response_type
        code
        order
        questionnaire_section
        question_
        question_fixed
        possibile_response_ {
          items {
            _id
            response_
          }
        }
        enabled_by
        disabled_by
        question_enabling_condition_(options: {offset: 0, next: 9999}) {
          items {
            possible_response_ {
              _id
            }
            questions_disabled(options: {offset: 0, next: 9999}) {
              items {
                _id
              }
            }
            questions_enabled(options: {offset: 0, next: 9999}) {
              items {
                _id
              }
            }
            _id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetQuestionnaireQuestionsListQuery__
 *
 * To run a query within a React component, call `useGetQuestionnaireQuestionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnaireQuestionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnaireQuestionsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *      questionInput: // value for 'questionInput'
 *   },
 * });
 */
export function useGetQuestionnaireQuestionsListQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionnaireQuestionsListQuery, GetQuestionnaireQuestionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnaireQuestionsListQuery, GetQuestionnaireQuestionsListQueryVariables>(GetQuestionnaireQuestionsListDocument, options);
      }
export function useGetQuestionnaireQuestionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnaireQuestionsListQuery, GetQuestionnaireQuestionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnaireQuestionsListQuery, GetQuestionnaireQuestionsListQueryVariables>(GetQuestionnaireQuestionsListDocument, options);
        }
export type GetQuestionnaireQuestionsListQueryHookResult = ReturnType<typeof useGetQuestionnaireQuestionsListQuery>;
export type GetQuestionnaireQuestionsListLazyQueryHookResult = ReturnType<typeof useGetQuestionnaireQuestionsListLazyQuery>;
export type GetQuestionnaireQuestionsListQueryResult = Apollo.QueryResult<GetQuestionnaireQuestionsListQuery, GetQuestionnaireQuestionsListQueryVariables>;
export const GetQuestionnairePreviousAnswersDocument = gql`
    query GetQuestionnairePreviousAnswers($input: ID!) {
  Questionnaire_completion___get(_id: $input) {
    _id
    response_(options: {offset: 0, next: 1000}) {
      items {
        response_str
        question_ {
          _id
          question_
          questionnaire_section
          possibile_response_ {
            items {
              _id
              response_
            }
          }
        }
        possible_single_response {
          _id
          response_
        }
      }
    }
  }
}
    `;

/**
 * __useGetQuestionnairePreviousAnswersQuery__
 *
 * To run a query within a React component, call `useGetQuestionnairePreviousAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnairePreviousAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnairePreviousAnswersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetQuestionnairePreviousAnswersQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionnairePreviousAnswersQuery, GetQuestionnairePreviousAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnairePreviousAnswersQuery, GetQuestionnairePreviousAnswersQueryVariables>(GetQuestionnairePreviousAnswersDocument, options);
      }
export function useGetQuestionnairePreviousAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnairePreviousAnswersQuery, GetQuestionnairePreviousAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnairePreviousAnswersQuery, GetQuestionnairePreviousAnswersQueryVariables>(GetQuestionnairePreviousAnswersDocument, options);
        }
export type GetQuestionnairePreviousAnswersQueryHookResult = ReturnType<typeof useGetQuestionnairePreviousAnswersQuery>;
export type GetQuestionnairePreviousAnswersLazyQueryHookResult = ReturnType<typeof useGetQuestionnairePreviousAnswersLazyQuery>;
export type GetQuestionnairePreviousAnswersQueryResult = Apollo.QueryResult<GetQuestionnairePreviousAnswersQuery, GetQuestionnairePreviousAnswersQueryVariables>;