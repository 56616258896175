import { Capacitor } from "@capacitor/core"
import { StatusBar, Style } from "@capacitor/status-bar"
import { useCallback } from "react"
import useMediaQuery from "./useMediaQuery"

const useSystemUi = () => {
	const updateStatusBar = useCallback((style: "light" | "dark") => {
		if (Capacitor.getPlatform() !== "ios") {
			return
		}

		StatusBar.setStyle({ style: style === "light" ? Style.Light : Style.Dark })
	}, [])

	const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")

	return {
		updateStatusBar,
		prefersDarkMode
	}
}

export default useSystemUi
