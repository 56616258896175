import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCheckbox,
	IonContent,
	IonDatetime,
	IonDatetimeButton,
	IonHeader,
	IonItem,
	IonLabel,
	IonList,
	IonModal,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonSkeletonText,
	IonToolbar,
	useIonAlert
} from "@ionic/react"
import { useSafeArea } from "@reactivers/use-safe-area"
import { useAtom } from "jotai"
import { useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router"
import { formCompilationDataAtom } from "../../atoms/form"
import { useAlertMessageResult } from "../../hooks/useAlertMessageResult"
import useT from "../../hooks/useT"
import { eucareApolloClient } from "../../services/api-eucare/client/apollo"
import {
	GetFormSymptomsListQueryResult,
	GetSavedFamilyFormQueryResult,
	useConfirmFormMutation,
	useGetFamilyMemberFormMutation,
	useGetFormSymptomsListLazyQuery,
	useGetSavedFamilyFormLazyQuery
} from "../../services/api-eucare/generated"
import { isoStringToServerDateConverter, serverDateToIsoStringConverter } from "../../utils/dateFormatConverter"
import FormItem, { IFormItemProps } from "./FormItem"

export default function Form() {
	const history = useHistory()
	const [alert] = useIonAlert()
	const [alertMessage] = useAlertMessageResult()
	const params = useParams<{ id: string; formCompletedId?: string }>()

	const [formCompilationData, setFormCompilationData] = useAtom(formCompilationDataAtom)

	const t = useT()

	const [getFamilyMemberFormMutation, { loading: loadingMemberData }] = useGetFamilyMemberFormMutation({
		client: eucareApolloClient
	})
	const [getCompletedForm, { loading: loadingComplete }] = useGetSavedFamilyFormLazyQuery({
		client: eucareApolloClient
	})
	const [confirmForm, { loading: loadingConfirm }] = useConfirmFormMutation({ client: eucareApolloClient })
	const [getSymptoms, { loading: loadingSymptoms }] = useGetFormSymptomsListLazyQuery({ client: eucareApolloClient })

	const [checked, setChecked] = useState<boolean>(false)
	const [areThereAnySymptoms, setAreThereAnySymptoms] = useState<boolean>(false)
	const [selectedSymptoms, setSelectedSymptoms] =
		useState<
			NonNullable<
				NonNullable<
					NonNullable<
						NonNullable<
							NonNullable<GetSavedFamilyFormQueryResult>["data"]
						>["Family_weekly_questionnaire___get"]
					>["symptom_"]
				>["items"]
			>
		>()

	const [symptomsList, setSymptomsList] =
		useState<
			NonNullable<
				NonNullable<
					NonNullable<
						NonNullable<
							NonNullable<
								NonNullable<GetFormSymptomsListQueryResult>["data"]
							>["Weekly_questionnaire___preview"]
						>["symptom____associables"]
					>["items"]
				>
			>
		>()
	const [formCompilationDate, setFormCompilationDate] = useState<string | undefined>(
		isoStringToServerDateConverter(new Date().toISOString())
	)

	const { bottom: safeAreaBottom } = useSafeArea()

	const datetime = useRef<null | HTMLIonDatetimeElement>(null)

	useEffect(() => {
		if (params.id) {
			getFamilyMemberFormMutation({
				variables: {
					input: {
						family_: params.id,
						family_weekly_questionnaire_: params.formCompletedId ?? undefined
					}
				},
				fetchPolicy: "no-cache"
			}).then(({ data: newFormData }) => {
				if (alertMessage(newFormData?.Weekly_questionnaire___formAction___initData?.result.message?.message)) {
					return
				}

				let newFormCompilationData: typeof formCompilationData = []

				if (!params.formCompletedId) {
					newFormData?.Weekly_questionnaire___formAction___initData?.data?.covid_test_?.items.forEach(
						(formItem) => {
							let newFormItem: IFormItemProps = {
								_id: formItem?.person_?._id,
								name: formItem.name,
								surname: formItem.surname,
								antigen_tests: 0,
								molecular_tests: 0,
								any_positive_antigen_test: null,
								confirmed_by_molecular: null
							}

							newFormCompilationData.push(newFormItem)
						}
					)
				} else {
					getCompletedForm({
						variables: {
							input: params.formCompletedId
						},
						fetchPolicy: "no-cache"
					}).then(({ data: formData }) => {
						const newFormCompilationData: IFormItemProps[] = []
						formData?.Family_weekly_questionnaire___get?.family_covid_test_?.items.forEach((member) => {
							newFormCompilationData.push({
								_id: member.person_?._id,
								name: newFormData?.Weekly_questionnaire___formAction___initData?.data?.covid_test_?.items.find(
									(person) => {
										return person.person_?._id === member.person_?._id
									}
								)?.name,
								surname:
									newFormData?.Weekly_questionnaire___formAction___initData?.data?.covid_test_?.items.find(
										(person) => {
											return person.person_?._id === member.person_?._id
										}
									)?.surname,
								antigen_tests: member.antigen_tests,
								molecular_tests: member.molecular_tests,
								any_positive_antigen_test: member.any_positive_antigen_test,
								confirmed_by_molecular: member.confirmed_by_molecular
							})
						})

						setSelectedSymptoms(formData?.Family_weekly_questionnaire___get?.symptom_?.items)

						setAreThereAnySymptoms(formData?.Family_weekly_questionnaire___get?.symptoms ?? false)

						setChecked(formData?.Family_weekly_questionnaire___get?.covid_tests ?? false)

						setFormCompilationDate(formData?.Family_weekly_questionnaire___get?.date)

						setFormCompilationData(newFormCompilationData)
					})
				}

				setFormCompilationData(newFormCompilationData)
			})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id, params.formCompletedId])

	useEffect(() => {
		getSymptoms().then(({ data: symptoms }) => {
			setSymptomsList(symptoms?.Weekly_questionnaire___preview?.symptom____associables?.items)
		})
	}, [getSymptoms])

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/home" />
					</IonButtons>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				{loadingComplete || loadingMemberData ? (
					<IonItem>
						<IonSkeletonText className="w-1/2 md:w-1/3 h-4" animated />
					</IonItem>
				) : (
					<IonItem>
						<IonLabel className="ion-text-wrap">{t("tab_forms_completion_date")}</IonLabel>

						<IonDatetimeButton datetime="datetime" />

						{/* @ts-ignore */}
						<IonModal keepContentsMounted={true}>
							<IonDatetime
								ref={datetime}
								placeholder={t("tab_forms_completion_date")}
								value={serverDateToIsoStringConverter(formCompilationDate)}
								onIonChange={(e) => {
									if (e.detail.value) {
										const newFormCompilationDate = isoStringToServerDateConverter(
											e.detail.value.toString()
										)
										if (newFormCompilationDate) {
											setFormCompilationDate(newFormCompilationDate)
										}
									}
								}}
								presentation="date"
								id="datetime"
							>
								<IonButtons slot="buttons" className="justify-center">
									<IonButton color="primary" onClick={() => datetime.current?.cancel(true)}>
										{t("cancel")}
									</IonButton>

									<IonButton color="primary" onClick={() => datetime.current?.confirm(true)}>
										{t("confirm")}
									</IonButton>
								</IonButtons>
							</IonDatetime>
						</IonModal>
					</IonItem>
				)}

				{loadingComplete || loadingMemberData ? (
					Array.from({ length: 5 }).map((_, index) => (
						<div key={`form-skeleton-${index}`} className="mb-8">
							<IonItem lines="none">
								<IonSkeletonText className="w-1/2 md:w-1/3 h-4" animated />
							</IonItem>
							<IonItem>
								<IonSkeletonText className="w-full h-3" animated />
							</IonItem>
							<IonItem>
								<IonSkeletonText className="w-full h-3" animated />
							</IonItem>
						</div>
					))
				) : (
					<IonItem>
						<IonLabel className="ion-text-wrap">{t("tab_forms_family_member_symptoms")}</IonLabel>
						<IonRadioGroup
							className="flex flex-col md:flex-row"
							value={areThereAnySymptoms}
							onIonChange={(e) => setAreThereAnySymptoms(e.detail.value)}
						>
							<IonItem lines="none">
								<IonLabel className="mr-2">{t("yes")}</IonLabel>
								<IonRadio value={true} />
							</IonItem>

							<IonItem lines="none">
								<IonLabel className="mr-2">{t("no")}</IonLabel>
								<IonRadio value={false} />
							</IonItem>
						</IonRadioGroup>
					</IonItem>
				)}

				{loadingSymptoms || loadingComplete || loadingMemberData
					? Array.from({ length: 5 }).map((_, index) => (
							<div key={`form-skeleton-${index}`} className="mb-8">
								<IonItem lines="none">
									<IonSkeletonText className="w-1/2 md:w-1/3 h-4" animated />
								</IonItem>
								<IonItem>
									<IonSkeletonText className="w-full h-3" animated />
								</IonItem>
								<IonItem>
									<IonSkeletonText className="w-full h-3" animated />
								</IonItem>
							</div>
					  ))
					: symptomsList && (
							<IonList>
								{symptomsList.map((symptom) => {
									// - Dalla nuova chiamata mi faccio dare anche gli attributi 'name' e '_id'
									// - Qui mi creo un oggetto con gli attributi di 'symptom' aggiungendoci 'name' e '_id'
									// - Uso l'oggetto per settare lo state facendo combaciare i due tipi
									return (
										<IonItem key={`symptom-${symptom.symptom_lang_?.items[0].symptom}`}>
											<IonLabel className="mr-2">
												{symptom.symptom_lang_?.items[0].symptom}
											</IonLabel>
											<IonCheckbox
												key={`symptom-symptomname-${selectedSymptoms?.some(
													(_symptom) =>
														_symptom.symptom === symptom.symptom_lang_?.items[0].symptom
												)}`}
												checked={selectedSymptoms?.some(
													(_symptom) =>
														_symptom.symptom === symptom.symptom_lang_?.items[0].symptom
												)}
												slot="end"
												disabled={!areThereAnySymptoms}
												onIonChange={(e) => {
													let selectedSymptom: {
														__typename?: "Symptom" | undefined
														_id?: string | null | undefined
														symptom?: string | null | undefined
													} = {
														__typename: "Symptom",
														_id: symptom._id,
														symptom: symptom.symptom_lang_?.items[0].symptom
													}
													setSelectedSymptoms(
														e.detail.checked
															? [...(selectedSymptoms || []), selectedSymptom]
															: selectedSymptoms?.filter(
																	(_symptom) =>
																		_symptom.symptom !== selectedSymptom.symptom
															  )
													)
												}}
											></IonCheckbox>
										</IonItem>
									)
								})}
							</IonList>
					  )}

				{loadingComplete || loadingMemberData ? (
					<IonItem>
						<IonSkeletonText className="w-1/2 md:w-1/3 h-4" animated />
					</IonItem>
				) : (
					<IonItem lines={loadingMemberData && checked ? undefined : "none"}>
						<IonLabel className="ion-text-wrap">{t("form_switch_label")}</IonLabel>
						<IonRadioGroup
							className="flex flex-col md:flex-row"
							value={checked}
							onIonChange={(e) => setChecked(e.detail.value)}
						>
							<IonItem lines="none">
								<IonLabel className="mr-2">{t("yes")}</IonLabel>
								<IonRadio value={true} />
							</IonItem>

							<IonItem lines="none">
								<IonLabel className="mr-2">{t("no")}</IonLabel>
								<IonRadio value={false} />
							</IonItem>
						</IonRadioGroup>
					</IonItem>
				)}

				{checked && (
					<IonList>
						{loadingMemberData
							? Array.from({ length: 5 }).map((_, index) => (
									<div key={`form-skeleton-${index}`} className="mb-8">
										<IonItem lines="none">
											<IonSkeletonText className="w-1/2 md:w-1/3 h-4" animated />
										</IonItem>
										<IonItem>
											<IonSkeletonText className="w-full h-3" animated />
										</IonItem>
										<IonItem>
											<IonSkeletonText className="w-full h-3" animated />
										</IonItem>
									</div>
							  ))
							: formCompilationData?.map((member, index) => {
									return (
										<FormItem
											key={`family-member-item-${index}`}
											_id={member?._id}
											antigen_tests={member?.antigen_tests}
											molecular_tests={member?.molecular_tests}
											name={member?.name}
											surname={member?.surname}
											any_positive_antigen_test={member.any_positive_antigen_test}
											confirmed_by_molecular={member.confirmed_by_molecular}
										/>
									)
							  })}
					</IonList>
				)}

				<IonItem lines="none">
					<IonButton
						className="w-full h-12"
						style={{ marginBottom: safeAreaBottom + 16 }}
						onClick={() => {
							if (formCompilationData) {
								confirmForm({
									variables: {
										input: {
											family_weekly_questionnaire_: params.formCompletedId,
											family_: params.id,
											date: formCompilationDate,
											covid_test_: checked
												? {
														save: formCompilationData.map((member, index) => {
															return {
																person_: member._id,
																antigen_tests: member.antigen_tests,
																molecular_tests: member.molecular_tests,
																any_positive_antigen_test:
																	member.any_positive_antigen_test,
																confirmed_by_molecular: member.confirmed_by_molecular
															}
														})
												  }
												: {
														save: formCompilationData.map((member, index) => {
															return {
																person_: member._id,
																antigen_tests: 0,
																molecular_tests: 0,
																any_positive_antigen_test: null,
																confirmed_by_molecular: null
															}
														})
												  },
											positive_test: checked,
											symptom_: {
												add: selectedSymptoms?.map((s) => s._id as string) || []
											},
											symptoms: areThereAnySymptoms
										}
									}
								})
									.then(({ data: confirmFormData }) => {
										if (
											alertMessage(
												confirmFormData?.Weekly_questionnaire___formAction___confirm?.result
													.message?.message,
												undefined,
												() => {}
											)
										) {
											return
										}
										eucareApolloClient.refetchQueries({
											include: ["GetFormHistory", "GetUserFiltered"]
										})
										history.push("/home")
									})
									.catch((err) => {
										alert(`${err}`, [
											{
												text: "OK",
												handler: () => {
													if (history.length > 1) {
														eucareApolloClient.refetchQueries({
															include: ["GetFormHistory", "GetUserFiltered"]
														})
														history.goBack()
													} else {
														eucareApolloClient.refetchQueries({
															include: ["GetFormHistory", "GetUserFiltered"]
														})
														history.push("/home")
													}
												}
											}
										])
									})
							}
						}}
					>
						{loadingConfirm ? t("loading") : t("submit")}
					</IonButton>
				</IonItem>
			</IonContent>
		</IonPage>
	)
}
